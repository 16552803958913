import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import * as routes from '../../routes';
import { StackNavigationProp } from '../../types/navigation';
import Screen from '../../components/Screen';
import { getNavigate } from '../../utils/navigation';
import { PruText } from '@ebsme/pulse-rn-components';
import ActionableText from '../../components/ActionableText';
import ConfirmationView from '../../components/ConfirmationView';

type Props = {
  navigation: StackNavigationProp;
};

const RequestLogSuccessScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();
  const ref = 'GB/MHC/TES82520/PA100015';
  const onBack = useCallback(() => getNavigate(navigation.navigate, routes.RequestLog, { success: true })(), [
    navigation
  ]);

  return (
    <Screen onPressBack={onBack}>
      <ConfirmationView onConfirm={onBack}>
        <PruText align="center">{t('LETTER_OF_GUARANTEE__REQUEST_LOG_SUCCESS__MESSAGE')}</PruText>
        <PruText marginTop={12} weight="bold">{`${t('LETTER_OF_GUARANTEE__REQUEST_LOG_SUCCESS__REF')}${ref}`}</PruText>
        <PruText align="center" marginTop={48}>
          {t('LETTER_OF_GUARANTEE__REQUEST_LOG_SUCCESS__SURVEY_MESSAGE')}
        </PruText>
        <ActionableText style={{ marginVertical: 12 }} onPress={(): void => {}}>
          {t('LETTER_OF_GUARANTEE__REQUEST_LOG_SUCCESS__TAKE_SURVEY')}
        </ActionableText>
      </ConfirmationView>
    </Screen>
  );
};

export default RequestLogSuccessScreen;
