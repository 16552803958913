const currencies = [
    {
        "value": "SGD",
        "label": "SGD"
    },
    {
        "value": "AUD",
        "label": "AUD"
    },
    {
        "value": "CNY",
        "label": "CNY"
    },
    {
        "value": "EUR",
        "label": "EUR"
    },
    {
        "value": "HKD",
        "label": "HKD"
    },
    {
        "value": "IDR",
        "label": "IDR"
    },
    {
        "value": "JPY",
        "label": "JPY"
    },
    {
        "value": "KHR",
        "label": "KHR"
    },
    {
        "value": "KRW",
        "label": "KRW"
    },
    {
        "value": "LAK",
        "label": "LAK"
    },
    {
        "value": "MMK",
        "label": "MMK"
    },
    {
        "value": "MYR",
        "label": "MYR"
    },
    {
        "value": "NZD",
        "label": "NZD"
    },
    {
        "value": "PHP",
        "label": "PHP"
    },
    {
        "value": "THB",
        "label": "THB"
    },
    {
        "value": "USD",
        "label": "USD"
    },
    {
        "value": "VND",
        "label": "VND"
    }
];
export default currencies;