import { Action, Dispatch } from 'redux';

import { fetchClinicsApi, ClinicsRequest } from './api';
import { Clinic, ClinicNearBy } from '../../types/clinic';
import { SDKAction, setSDKLoading, setSDKException } from '../SDKReducer/actions';
import { getUserCountryCode } from '../PulseUserReducer/selectors';
import { RootState } from '../../store';

export const SET_CLINICS = 'Clinics/SET_CLINICS';
export const FETCH_CLINICS_START = 'Clinics/FETCH_CLINICS_START';
export const FETCH_CLINICS_SUCCESS = 'Clinics/FETCH_CLINICS_SUCCESS';
export const FETCH_CLINICS_FAILED = 'Clinics/FETCH_CLINICS_FAILED';
export const CLEAR_CLINICS = 'Clinics/CLEAR_CLINICS';
interface ClearClinics extends Action {
  type: typeof CLEAR_CLINICS;
}

export const clearClinics = (): ClearClinics => ({
  type: CLEAR_CLINICS
});

export interface FetchClinicsStartAction extends Action {
  type: typeof FETCH_CLINICS_START;
}

export const fetchClinicsStart = (): FetchClinicsStartAction => ({
  type: FETCH_CLINICS_START
});

export interface FetchClinicsSuccessAction extends Action {
  type: typeof FETCH_CLINICS_SUCCESS;
  payload: ClinicNearBy;
}

export const fetchClinicsSuccess = (clinicNearBy: ClinicNearBy): FetchClinicsSuccessAction => ({
  type: FETCH_CLINICS_SUCCESS,
  payload: clinicNearBy
});

export interface FetchClinicsFailedAction extends Action {
  type: typeof FETCH_CLINICS_FAILED;
  payload: string;
}

export const fetchClinicsFailed = (fetchError: string): FetchClinicsFailedAction => ({
  type: FETCH_CLINICS_FAILED,
  payload: fetchError
});

export const fetchClinics =
  (requestBody: ClinicsRequest) =>
  async (dispatch: Dispatch<ClinicsAction>, getState: () => RootState): Promise<void> => {
    if (requestBody.pageNumber == '0') {
      dispatch(setSDKLoading(true));
    }
    dispatch(fetchClinicsStart());
    try {
      const countryCode = getUserCountryCode(getState()) ?? 'PH';

      const response = await fetchClinicsApi(requestBody, countryCode);
      dispatch(fetchClinicsSuccess(response.data.getClinicNearBy));
      dispatch(setSDKLoading(false));
    } catch (error: any) {
      dispatch(setSDKLoading(false));

      dispatch(fetchClinicsFailed(error.message));
    }
  };

export type ClinicsAction =
  | FetchClinicsStartAction
  | FetchClinicsSuccessAction
  | FetchClinicsFailedAction
  | SDKAction
  | ClearClinics;
