import { Credential } from '../types/user';
import SimpleCrypto from 'simple-crypto-js';

const secretKey = 'encryption-pulse-key';
const simpleCrypto = new SimpleCrypto(secretKey);

export const encryptUser = (credential: Credential) => {
  return {
    ...credential,
    nationalId: encryptString(credential.nationalId),
    email: encryptString(credential.email),
    phone: encryptString(credential.phone)
  };
};

export const decryptUser = (credential: Credential) => {
  return {
    ...credential,
    nationalId: decryptString(credential.nationalId),
    email: decryptString(credential.email),
    phone: decryptString(credential.phone)
  };
};

export const encryptString = (s: string) => {
  if (s == '') return '';
  return simpleCrypto.encrypt(s);
};

export const decryptString = (s: string) => {
  if (s === '' || s === null || s === undefined) return '';
  return simpleCrypto.decrypt(s) ? simpleCrypto.decrypt(s).toString() : '';
};
