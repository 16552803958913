import { connect } from 'react-redux';
import { RootState } from '../../store';
import DocumentImageView from './DocumentImageView';

import { getPolicyId } from '../../reducers/MyPolicyReducer/selectors';

const DocumentImageViewContainer = connect((state: RootState) => ({
  policyId: getPolicyId(state)
}))(DocumentImageView);

export default DocumentImageViewContainer;
