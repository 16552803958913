import { setClaimFilter } from './../../../reducers/ReviewClaimReducer/actions';
import { getClaimFilter } from './../../../reducers/ReviewClaimReducer/selectors';
import { RootState } from '../../../store';
import { fetchClaims, setClaimDetail } from '../../../reducers/ReviewClaimReducer/actions';
import { resetClaim, deleteClaimForProcess } from '../../../reducers/MakeClaimReducer/actions';
import { updateClaim, setInitialClaim } from './../../../reducers/MakeClaimReducer/actions';
import { getLoading, getClaims } from '../../../reducers/ReviewClaimReducer/selectors';
import { getPolicyId, getPolicy } from '../../../reducers/MyPolicyReducer/selectors';
import withLBUSwitcher from '../../../hocs/withLBUSwitcher';
import ClaimListScreen from './ClaimListScreen';
import withSDKLoader from '../../../hocs/withSDKLoader';

const LBU_CONFIG = {
  SG: ClaimListScreen,
  TH: ClaimListScreen,
  PH: ClaimListScreen,
  MY: null,
  MM: ClaimListScreen
};

export default withSDKLoader(
  withLBUSwitcher(
    LBU_CONFIG,
    (state: RootState) => {
      return {
        loading: getLoading(state),
        claims: getClaims(state),
        claimFilter: getClaimFilter(state),
        policyId: getPolicyId(state),
        policy: getPolicy(state),
      };
    },
    { resetClaim, fetchClaims, setClaimDetail, setInitialClaim, updateClaim, deleteClaimForProcess, setClaimFilter }
  )
);
