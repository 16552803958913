import React from 'react';
import { Text, View } from 'react-native';

export interface Props {
  label: string | undefined;
  value: string | undefined;
}

const ECardLineItem: React.FC<Props> = ({ label, value }) => {
  return (
    <View
      testID="ECardLineItem"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'space-between',
        marginBottom: 2,
        marginTop: 2
      }}
    >
      <View
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'flex-start'
        }}
      >
        <Text
          style={{
            fontSize: 14
          }}
        >
          {label}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'flex-end',
          alignSelf: 'flex-end'
        }}
      >
        <Text
          style={{
            flex: 2,
            fontSize: 14,
            fontWeight: 'bold',
            textAlign: 'right'
          }}
        >
          {value}
        </Text>
      </View>
    </View>
  );
};

export default ECardLineItem;
