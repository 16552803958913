import { black } from './colors';
import { openSans } from './fonts';

type ShadowStyleOptions = {
  offset?: string;
  elevation?: number;
};

export const getShadowStyles = ({ offset = '2px 8px', elevation = 6 }: ShadowStyleOptions = {}): string => `
  elevation: ${elevation};
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  shadow-color: ${black};
  ${offset ? `shadow-offset: ${offset};` : ''}
`;

export const fontWeightFamilies = {
  thin: openSans.light,
  normal: openSans.regular,
  semiBold: openSans.semiBold,
  bold: openSans.bold
};

type FontFamilyOptions = { weight?: keyof typeof fontWeightFamilies };

export const getFontFamilyStyle = ({ weight }: FontFamilyOptions): string =>
  weight ? `font-family: "${fontWeightFamilies[weight]}";` : '';
