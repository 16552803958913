import React from 'react';
import ChevronWhiteLeft from '../../assets/svgs/icons/ChevronWhiteLeft.svg';
import { BackIconContainer } from './BackIconStyles';

const BackIcon: React.FC = () => {
  return (
    <BackIconContainer>
      <ChevronWhiteLeft width={10} height={10} />
    </BackIconContainer>
  );
};

export default BackIcon;
