import React from 'react'
import Warning from './warning.svg'
import { ErrorContainer, ErrorMessageContainer } from './otp-input-styles'
import { OTPInputView } from './otp-input-view'
import { OTPInputProps } from '../OTPInputProps'
import { PruText } from '@ebsme/pulse-rn-components'

const OTPInput = ({ otp, label, errorMessage, onChangeText }: OTPInputProps) => {
  return (
    <>
      {label ? (
        <PruText size={14} weight="bold" color="black" marginBottom={8}>
          {label}
        </PruText>
      ) : null}
      <OTPInputView value={otp} onChange={onChangeText} isError={!!errorMessage} />
      {errorMessage ? (
        <ErrorContainer>
          <Warning width="16" height="16" />
          <ErrorMessageContainer>
            <PruText size={12} weight="semiBold" color="darkRed">
              {errorMessage}
            </PruText>
          </ErrorMessageContainer>
        </ErrorContainer>
      ) : null}
    </>
  )
}

export default OTPInput
