import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { StackNavigationProp } from '../../types/navigation';
import * as routes from '../../routes';
import { PruText } from '@ebsme/pulse-rn-components';
import Screen from '../../components/Screen';
import LongTile from '../../components/LongTile';
import { GetHelp } from '../../assets/svgs/banners';
import { FileWarning, Buoy, FolderCogwheel, Phone, Email } from '../../assets/svgs/icons';
import { Container } from './SupportStyles';
import { arcticBlast } from '../../assets/colors';
import ActionableTile from '../../components/ActionableTile';
import { SupportInfo } from '../../types/supportInfo';
import { callPhoneNumber, openMail, openURL } from '../../utils/platform';
import LinkText from '../../components/LinkText';
import { logFirebaseEventScreen, logFirebaseEventClick } from '../../utils/analytics';

export type SupportScreenProps = {
  navigation: StackNavigationProp;
  supportInfo: SupportInfo;
  countryCode: string;
};

const SupportScreen: React.FC<SupportScreenProps> = ({ navigation, supportInfo, countryCode }) => {
  const { t } = useTranslation();
  const [tGet, tHelp] = t('SUPPORT__GET_HELP');

  useEffect(() => {
    logFirebaseEventScreen('eb_help', {
      feature: 'GetHelp',
      journey: 'get_help',
      stage: 'help_detail',
      screen_id: 'SCR_EB_HELP_SPS',
      screen_name: 'SupportScreen'
    });
  }, []);

  return (
    <Screen
      headingPosition="none"
      onPressBack={(): void => {
        navigation.navigate(routes.Landing);
      }}
    >
      <View style={{ backgroundColor: arcticBlast, flexDirection: 'row' }}>
        <View style={{ margin: 24, justifyContent: 'center', flex: 1 }}>
          <PruText size={20} color="black" weight="thin">
            <PruText weight="bold">{tGet}</PruText> {tHelp}
          </PruText>
          <PruText weight="thin">{t('SUPPORT__GET_HELP__DESCRIPTION')}</PruText>
        </View>
        <GetHelp style={{ maxWidth: 200 }} />
      </View>
      <Container>
        <PruText marginTop={24} weight="bold">
          {t('SUPPORT__INTRO_QUESTION')}
        </PruText>
        {!!supportInfo.submitTicketUrl && (
          <LongTile
            testID="SUPPORT__SUBMIT_TICKET"
            style={{ marginTop: 32 }}
            SvgImage={FileWarning}
            title={t('SUPPORT__SUBMIT_TICKET')}
            description={t('SUPPORT__SUBMIT_TICKET__DESCRIPTION')}
            onPress={(): void => {
              openURL(supportInfo.submitTicketUrl);
            }}
          />
        )}
        {!!supportInfo.serviceStatusUrl && (
          <LongTile
            testID="SUPPORT__SERVICE_STATUS"
            style={{ marginTop: 32 }}
            SvgImage={FolderCogwheel}
            title={t('SUPPORT__SERVICE_STATUS')}
            description={t('SUPPORT__SERVICE_STATUS__DESCRIPTION')}
            onPress={(): void => {
              logFirebaseEventClick('eb_help', {
                feature: 'GetHelp',
                journey: 'get_help',
                stage: 'help_detail',
                screen_id: 'SCR_EB_HELP_SPS',
                screen_name: 'SupportScreen',
                object_id: 'LNK_EB_HELP_SERVICE'
              });
              openURL(supportInfo.serviceStatusUrl);
            }}
          />
        )}
        {!!supportInfo.applicationHelpUrl && (
          <LongTile
            testID="SUPPORT__APPLICATION_HELP"
            style={{ marginTop: 32 }}
            SvgImage={Buoy}
            title={t('SUPPORT__APPLICATION_HELP')}
            description={t('SUPPORT__APPLICATION_HELP__DESCRIPTION')}
            onPress={(): void => {
              logFirebaseEventClick('eb_help', {
                feature: 'GetHelp',
                journey: 'get_help',
                stage: 'help_detail',
                screen_id: 'SCR_EB_HELP_SPS',
                screen_name: 'SupportScreen',
                object_id: 'LNK_EB_HELP_APP'
              });
              openURL(supportInfo.applicationHelpUrl);
            }}
          />
        )}
        {(!!supportInfo.emails.length || !!supportInfo.phones.length) && (
          <PruText marginTop={24} weight="bold">
            {t('SUPPORT__DIRECT_SUPPORT')}
          </PruText>
        )}
        {!!supportInfo.emails.length && (
          <ActionableTile
            style={{ marginTop: 32 }}
            SvgImage={Email}
            EndComponent={
              <View>
                {supportInfo.emails.map((email, index) => (
                  <LinkText
                    testID="SUPPORT__EMAIL"
                    key={email}
                    onPress={(): void => {
                      logFirebaseEventClick('eb_help', {
                        feature: 'GetHelp',
                        journey: 'get_help',
                        stage: 'help_detail',
                        screen_id: 'SCR_EB_HELP_SPS',
                        screen_name: 'SupportScreen',
                        object_id: 'BTN_EB_HELP_EMAIL'
                      });
                      openMail(email, {
                        subject: 'Customer Support Enquiry'
                      });
                    }}
                    style={{ marginTop: index === 0 ? 0 : 16 }}
                  >
                    {t('SUPPORT__EMAIL_US')}
                  </LinkText>
                ))}
              </View>
            }
            title={
              countryCode === 'TH'
                ? t('SUPPORT__EMAIL_CLAIM')
                : countryCode === 'PH'
                ? t('SUPPORT__EMAIL_PH')
                : t('SUPPORT__EMAIL')
            }
          />
        )}
        {!!supportInfo.phones.length && (
          <ActionableTile
            testID="SUPPORT__PHONE__TILE"
            style={{ marginTop: 32, marginBottom: 48 }}
            SvgImage={Phone}
            EndComponent={
              <View>
                {supportInfo.phones.map((phone, index) => (
                  <LinkText
                    testID="SUPPORT__PHONE"
                    key={phone}
                    onPress={(): void => {
                      logFirebaseEventClick('eb_help', {
                        feature: 'GetHelp',
                        journey: 'get_help',
                        stage: 'help_detail',
                        screen_id: 'SCR_EB_HELP_SPS',
                        screen_name: 'SupportScreen',
                        object_id: 'BTN_EB_HELP_PHONE'
                      });
                      callPhoneNumber(phone.replace(/[^+\d]/g, ''));
                    }}
                    style={{ marginTop: index === 0 ? 0 : 16 }}
                  >
                    {phone}
                  </LinkText>
                ))}
              </View>
            }
            title={t('SUPPORT__PHONE')}
            description={supportInfo.phoneHours?.join('\n')}
          />
        )}
      </Container>
    </Screen>
  );
};

export default SupportScreen;
