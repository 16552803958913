import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import { colors, PruButton, PruCheckBox, PruText } from '@ebsme/pulse-rn-components';

import { ButtonContainer } from './OnboardingEmailScreenStyles';
import { openURL } from '../../../utils/platform';
import { UserCountryCode } from '../../../types/user';
import { getValue } from '../../../utils/object';
import { LBU } from '../../../config';
import TermsAndConditions from '../../Support/TermsAndConditions';
import Icon from 'react-native-vector-icons/Ionicons';

interface Props {
  userCountryCode: UserCountryCode;
  onPress: () => void;
  showScanButton?: boolean;
  onPressScan?: () => void;
}

const OnboardingAgreement: React.FC<Props> = ({ userCountryCode, onPress, showScanButton, onPressScan }) => {
  const { t } = useTranslation();
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [termVisible, setTermVisible] = useState(false);
  const privacyUrl = getValue(LBU, [userCountryCode, 'GENERAL', 'privacyUrl']);

  useEffect(() => {
    if (showScanButton) {
      setTermsAgreed(showScanButton);
    }
  }, [showScanButton]);

  return (
    <>
      <ButtonContainer>
        <PruCheckBox
          testID="OnboardingAgreement_CheckBox"
          text="By proceeding, you agree to our"
          checkedIcon="checkbox"
          uncheckedIcon="square-outline"
          value={termsAgreed}
          verticalAlign="top"
          textStyle={{ marginTop: -4 }}
          textProps={{ weight: 'normal', children: null }}
          onValueChanged={(value): void => setTermsAgreed(value)}
        />
        <PruText
          testID="OnboardingAgreement_AgreementText"
          onPress={(): void => {}}
          color="black"
          marginTop={-8}
          marginLeft={32}
          marginBottom={24}
        >
          <PruText
            testID="OnboardingAgreement_TermsOfUse"
            onPress={(): void => {
              setTermVisible(true);
            }}
            color="red"
            weight="bold"
          >
            {t('ONBOARDING__TERMS')}
          </PruText>
          {(userCountryCode === 'SG' || userCountryCode === 'PH') && ` and `}
          {(userCountryCode === 'SG' || userCountryCode === 'PH') && (
            <PruText
              testID="OnboardingAgreement_Privacy"
              onPress={(): void => {
                openURL(privacyUrl);
              }}
              color="red"
              weight="bold"
            >
              {t('ONBOARDING__PRIVACY')}
            </PruText>
          )}
        </PruText>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <PruButton
            testID="OnboardingAgreement_SubmitButton"
            disabled={!termsAgreed}
            style={!termsAgreed ? { backgroundColor: colors.grey, borderColor: colors.grey } : {}}
            onPress={onPress}
          >
            {t('ONBOARDING__EMAIL__SUBMIT')}
          </PruButton>
          {showScanButton && (
            <TouchableOpacity
              style={{ marginLeft: 10 }}
              testID="OnboardingAgreement_ScanButton"
              disabled={!termsAgreed}
              onPress={onPressScan}
            >
              <Icon name="finger-print" size={40} color={!termsAgreed ? colors.grey : '#ED1B2E'} />
            </TouchableOpacity>
          )}
        </View>
      </ButtonContainer>
      {termVisible && (
        <TermsAndConditions
          testID="TermsAndConditionsModal"
          onClose={(): void => setTermVisible(false)}
          userCountryCode={userCountryCode}
        />
      )}
    </>
  );
};

export default OnboardingAgreement;
