// @ts-ignore
import { ProductCataloguePLT, Benefit, ListStyle } from '@ebsme/product-catalogue';
import { ProductBenefit } from '../../../types/product';
import { CellType, MyPolicyRenderHelperBase, MyPolicyRenderObject, MyPolicyRenderHelper } from './MyPolicyRenderHelper';
import { Policy } from '../../../types/policy';

export class MyPolicyRenderHelperTH extends MyPolicyRenderHelperBase implements MyPolicyRenderHelper {
  constructor(policy: Policy) {
    super(new ProductCataloguePLT(), policy);
  }

  recursivelyMapBenefit(
    benefit: ProductBenefit,
    parentIndentStr: string,
    listStyle = ListStyle.UNORDERED
  ): MyPolicyRenderObject[] {
    let rows: MyPolicyRenderObject[] = [];
    let indentIndex = 0;
    benefit.children
      .map((e) => new Benefit(e))
      .map((benefitChild) => {
        const benefitValue = this.productCatalogue.getBenefitValue(benefitChild.id, this.policy.tierId);
        let indentStr = '';
        if (listStyle === ListStyle.ORDERED) {
          indentIndex++;
          indentStr = parentIndentStr.length > 0 ? `${parentIndentStr}${indentIndex}` : `${indentIndex}.`;
        }
        rows.push({
          type: CellType.ItemRow,
          key: benefitChild.id,
          title: '',
          label: benefitChild.getTitle(),
          value: this.productCatalogue.getBenefitValueString(benefitValue),
          highlighted: benefitChild.level === 2,
          leadingNumber: indentStr
        });

        if (!!benefitChild.children) {
          const childRows = this.recursivelyMapBenefit(benefitChild, indentStr, listStyle);
          rows = [...rows, ...childRows];
        }
      });
    return rows;
  }

  mapAllRows(): MyPolicyRenderObject[] {
    return this.productCatalogue.product.plans
      .filter((plan) => this.policy.planIds.some((planId) => plan.id === planId))
      .reduce((rows: MyPolicyRenderObject[], plan) => {
        return [
          ...rows,
          ...plan.benefits
            .map((e) => new Benefit(e))
            .map((benefit) => ({
              type: CellType.ExpandableCard,
              key: benefit.id,
              title: benefit.getName(),
              subtitle: benefit.getCode(),
              label: '',
              children: this.recursivelyMapBenefit(benefit, '', benefit.listStyle)
            }))
        ];
      }, []);
  }
}
