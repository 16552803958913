import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../store';
import { EntitlementsMap } from '../../types/entitlements';
import {
  getFetchingCorporateUser,
  getRegisteringCorporateUser,
  getUserEntitlementsMap
} from '../../reducers/CorporateUserReducer/selectors';

interface InjectedProps {
  loadingEntitlements: boolean;
  entitlements: EntitlementsMap;
}

// Props that the child component needs to have passed by the parent
type OuterProps<P> = Omit<P, keyof InjectedProps>;

function withUserEntitlements<P>(Component: React.ComponentType<P>): React.ComponentType<OuterProps<P>> {
  // @ts-ignore
  const WrappedComponent = connect(
    (state: RootState): InjectedProps => ({
      loadingEntitlements: getFetchingCorporateUser(state) || getRegisteringCorporateUser(state),
      entitlements: getUserEntitlementsMap(state)
    })
  )(
    // @ts-ignore
    Component
  ) as React.ComponentType<OuterProps<P>>;

  WrappedComponent.displayName = `withUserEntitlements(${Component.displayName || Component.name})`;

  return WrappedComponent;
}

export default withUserEntitlements;
