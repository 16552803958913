import gql from 'graphql-tag';
import { Claim, ClaimDocument, UploadClaimDocumentResponse, Consent } from '../../types/claim';
import apiClient from '../../utils/apiClient';
import { pick, path } from 'ramda';
import { LBU } from '../../config';
import { UserCountryCode } from '../../types/user';
import { getValue } from '../../utils/object';

export const uploadClaimDocument = (document: ClaimDocument): Promise<UploadClaimDocumentResponse> =>
  apiClient.mutate({
    mutation: gql`
    mutation UploadGroupInsuranceFile($uploadFilePayload: UploadClaimFileInput) {
      uploadClaimFile(uploadFilePayload: $uploadFilePayload) {
        path
      }
    }
  `,
    variables: {
      uploadFilePayload: {
        policyId: document.policyId,
        fileName: document.fileName,
        fileContent: document.fileContent
      }
    }
  });

export const submitClaim = async (partialClaim: Claim, countryCode: UserCountryCode): Promise<Claim> => {
  if (partialClaim.bankAccount) {
    delete partialClaim.bankAccount.isNew;
  }
  const submitClaim = pick(getValue(LBU, [countryCode, 'REQUEST_PICKER', 'saveClaim']), partialClaim);
  console.log("DATA SUBMIT CLAIM", JSON.stringify(submitClaim))
  const res = await apiClient.mutate({
    mutation: getValue(LBU, [countryCode, 'GRAPHQL', 'submitClaimMutation']),
    variables: {
      claim: { ...submitClaim }
    }
  });

  const claim = path<Claim>(['data', 'submitClaim'], res);

  if (!claim) {
    throw new Error('No claim found in API response');
  }

  return claim;
};

export const saveClaim = async (partialClaim: Claim, countryCode: UserCountryCode): Promise<Claim> => {
  const saveClaim = pick(getValue(LBU, [countryCode, 'REQUEST_PICKER', 'saveClaim']), partialClaim);
  const res = await apiClient.mutate({
    mutation: getValue(LBU, [countryCode, 'GRAPHQL', 'saveClaimMutation']),
    variables: {
      claim: saveClaim
    }
  });

  const claim = path<Claim>(['data', 'saveClaim'], res);

  if (!claim) {
    throw new Error('No claim found in API response');
  }

  return claim;
};

export const deleteClaim = async (partialClaim: Claim): Promise<Claim> => {
  const deleteClaim = pick(['claimNumber', 'policyId'], partialClaim);
  const res = await apiClient.mutate({
    mutation: gql`
      mutation deleteClaim($claim: ClaimInput) {
        deleteClaim(claim: $claim) {
          claimType
          claimSubType
          originalRequired
          claimState
          claimNumber
          policyId
          lbuClaimReference
        }
      }
    `,
    variables: {
      claim: deleteClaim
    }
  });

  const claim = path<Claim>(['data', 'deleteClaim'], res);

  if (!claim) {
    throw new Error('No claim found in API response');
  }

  return claim;
};

interface ConsentWrap {
  content: Consent
}

const CONSENTS: Consent[] = [
  {
    en: 'I and/or the legal representative consent to the disclosure of my health data, disability, sexual behavior, biometric data, genetic data, and race held or will be held in the future by doctors, insurance companies, medical facilities or any other persons to the Company or the representatives of the Company for insurance application, insurance underwriting or benefit payments in pursuant to the policy',
    th: 'ข้าพเจ้า และ/หรือผู้แทนโดยชอบธรรม ยินยอมให้แพทย์ หรือบริษัทประกันภัย หรือสถานพยาบาล หรือบุคคลอื่นใด ซึ่งมีข้อมูลสุขภาพ ความพิการ พฤติกรรมทางเพศ ข้อมูลชีวภาพ ข้อมูลพันธุกรรม เชื้อชาติ ของข้าพเจ้า และ/หรือผู้เยาว์ ที่ผ่านมา หรือจะมีขึ้นต่อไปในอนาคต สามารถเปิดเผยข้อมูลดังกล่าว ให้แก่บริษัทหรือผู้แทนของบริษัท เพื่อการขอเอาประกันภัย การพิจารณารับประกันภัย หรือ การจ่ายเงินตามกรมธรรม์ประกันภัยได้'
  },
  {
    en: 'I and/or the legal representative consent to the collection, use or disclosure of my health data, disability, sexual behavior, biometrics data, genetic data, and race to other insurance companies, reinsurance brokers, reinsurance companies, government authorities, medical facilities, doctors, medical professionals, life insurance agents or life insurance brokers for the purpose of insurance application, insurance underwriting or benefit payments in pursuant to the policy.',
    th: 'ข้าพเจ้า และ/หรือผู้แทนโดยชอบธรรม ยินยอมให้บริษัทเก็บรวบรวม ใช้ หรือเปิดเผย ข้อมูลสุขภาพ ความพิการ พฤติกรรมทางเพศ ข้อมูลชีวภาพ ข้อมูลพันธุกรรม เชื้อชาติ ของข้าพเจ้า และ/หรือผู้เยาว์ต่อบริษัทประกันภัยอื่น บริษัทนายหน้าประกันภัยต่อ บริษัทประกันภัยต่อหน่วยงานที่มีอานาจตามกฎหมาย สถานพยาบาล แพทย์ บุคลากรทางการแพทย์ ตัวแทนประกันชีวิต หรือนายหน้าประกันชีวิต เพื่อการขอเอาประกันภัย การพิจารณารับประกันภัย หรือการจ่ายเงินตามกรมธรรม์ประกันภัย'
  }
]

export const fetchConsent = async (claimType: string): Promise<Consent[]> => {
  const res = await apiClient.query({
    query: gql`
    query getPrivacyByType($type:String!){
      getPrivacyByType(type: $type){
        name
        title
        content
        htmlContent
      }
    }
    `,
    variables: {
      type: claimType
    }
  })
  const data = path<ConsentWrap[]>(['data', 'getPrivacyByType'], res);
  if (!data) {
    return CONSENTS;
  }
  return data.map(item => item.content);
}
