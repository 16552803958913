import gql from 'graphql-tag';
export * from '../shared/graphql';

export const fetchPolicyQuery = gql`
  {
    getPolicyDetailsForEmployee {
      policyId
      policyNumber
      certificateNo
      referenceNumber
      coverageDate
      dobEmployee
      employees {
        givenName
        middleName
        familyName
        active
        companyEmail
        id
        startDate
        category
      }
      categories {
        allowDependants
        name
        tierId
        plans {
          id
        }
      }
    }
  }
`;

export const getAllEmployeeDetails = gql`
  {
    getAllEmployeeDetails {
      policyId
      policyNumber
      referenceNumber
      industryType
      premiumAmount
      modalPremium
      paymentFrequency
      state
      coverageDate
      pdpaConsent
      isArchived
      isDeleted
      created
      updated
      policyStatus
      policyStatusDate
      certificateNo
      dobEmployee
      employees {
        givenName
        middleName
        familyName
        policyGroup
        policyNumber
        companyEmail
        dateOfBirth
        gender
        maritalStatus
        nationality
        occupationalClass
        idNo
        startDate
        employeeRelation
        id
      }
    }
  }
`;

export const submitClaimMutation = gql`
  mutation SubmitClaim ($claim: ClaimInput) {
    submitClaim (claim: $claim) {
        errorCode
        claimType
        originalRequired
        claimDate
        claimNumber
        claimantName
        policyId
        employeeId
        lbuClaimReference
        claimState
        claimSubType
        employeePolicyNumber
    }
  }
`;

export const saveClaimMutation = gql`
  mutation SaveClaim($claim: ClaimInput) {
    saveClaim(claim: $claim) {
      claimType
      claimSubType
      originalRequired
      claimState
      claimDate
      claimNumber
      policyId
      lbuClaimReference
      documents {
        docType
        fileName
        fileBlobURL
        fileType
      }
      coveragePerson
      incurredDate
      amount
      reason
      bankName
      accountNumber
      accountHolderName
      receiptsCurrency
      uiState
      remarks
    }
  }
`;
export const registerPulseUserMutation = gql`
  mutation registerPulseUser($input: PulseRegistrationPayload!) {
    registerPulseUser(input: $input) {
      authCodeRef
      token
      otpSkip
    }
  }
`;

export const getRefreshTokenForAuthCodeMutation = gql`
  mutation getRefreshTokenForAuthCode($input: AuthCodePayload!) {
    getRefreshTokenForAuthCode(input: $input) {
      token
      softToken
    }
  }
`;
export const fetchPolicyAndProductsQuerry = gql`
  query GetPolicyAndProducts($policyNumber: String) {
    getPolicyAndProducts(certificate: $policyNumber) {
      disclaimerFlag
      policyId
      planCode
      remainingBenefits {
        name {
          EN
          TH
        }
        stackValue
        benefitType
        coverageValue
        valueUnit
        leftStr {
          EN
          TH
        }
        remainingValue
        remainingUnit {
          EN
          TH
        }
      }
      products {
        name {
          EN
          TH
        }
        effectiveDate
        entryId
        benefitsList {
          id
          name {
            EN
            TH
          }
          entryId
          type
          value
          children {
            id
            name {
              EN
              TH
            }
            entryId
            type
            value
          }
        }
      }
    }
  }
`;

export const getClinicNearBy = gql`
  query GetClinicNearBy($getClinicNearByPayload: GetClinicNearByInput) {
    getClinicNearBy(getClinicNearByPayload: $getClinicNearByPayload) {
      totalPage
      total
      currentPage
      pageSize
      clinics {
        clinicName {
          en
          th
        }
        clinicType {
          en
          th
        }
        clinicAddress {
          en
          th
        }
        geoLocation {
          lat
          lng
        }
        recommended
        distance
        imageUrl
        serviceType {
          en
          th
        }
        contact {
          phone {
            en
            th
          }
          phone1 {
            en
            th
          }
          email {
            en
            th
          }
          others {
            en
            th
          }
        }
        doctors
        clinicHours {
          displayName {
            en
            th
          }
          operationHours {
            en
            th
          }
        }
        description {
          en
          th
        }
        remarks
        clinicProperties {
          code
          value
        }
      }
    }
  }
`;

export const getClaimsForEmployeePolicyQuery = gql`
  query getClaimsHistory($certificateNo: String!) {
    getClaimsHistory(certificateNo: $certificateNo) {
      claimStatus
      lbuClaimReference
      claimDate
      claimType
      policyNumber
      incidentContent
      insuredName
      paidAmount
    }
  }
`;
