import { Action, Dispatch } from 'redux';

import { fetchBanksApi, fetchBankAccountsApi, addBankAccountApi, removeBankAccountApi } from './api';
import { Bank } from '../../types/bank';
import { BankAccount } from '../../types/bankAccount';
import { SDKAction, setSDKLoading, setSDKException } from '../SDKReducer/actions';
import { RootState } from '../../store';
import { getValue } from '../../utils/object';
import { getUserCountryCode } from '../PulseUserReducer/selectors';
import { logFirebaseEventBackend } from '../../utils/analytics';

export const SET_BANK_ACCOUNTS = 'BankAccount/SET_BANK_ACCOUNTS';
export const FETCH_BANK_ACCOUNTS_START = 'BankAccount/FETCH_BANK_ACCOUNTS_START';
export const FETCH_BANK_ACCOUNTS_SUCCESS = 'BankAccount/FETCH_BANK_ACCOUNTS_SUCCESS';
export const FETCH_BANK_ACCOUNTS_FAILED = 'BankAccount/FETCH_BANK_ACCOUNTS_FAILED';

export const SET_BANKS = 'BankAccount/SET_BANKS';
export const FETCH_BANKS_START = 'BankAccount/FETCH_BANKS_START';
export const FETCH_BANKS_SUCCESS = 'BankAccount/FETCH_BANKS_SUCCESS';
export const FETCH_BANKS_FAILED = 'BankAccount/FETCH_BANKS_FAILED';

export const ADD_BANK_ACCOUNT_START = 'BankAccount/ADD_BANK_ACCOUNT_START';
export const ADD_BANK_ACCOUNT_SUCCESS = 'BankAccount/ADD_BANK_ACCOUNT_SUCCESS';
export const ADD_BANK_ACCOUNT_FAILED = 'BankAccount/ADD_BANK_ACCOUNT_FAILED';

export const REMOVE_BANK_ACCOUNT_START = 'BankAccount/REMOVE_BANK_ACCOUNT_START';
export const REMOVE_BANK_ACCOUNT_SUCCESS = 'BankAccount/REMOVE_BANK_ACCOUNT_SUCCESS';
export const REMOVE_BANK_ACCOUNT_FAILED = 'BankAccount/REMOVE_BANK_ACCOUNT_FAILED';

export interface AddBankAccountStartAction extends Action {
  type: typeof ADD_BANK_ACCOUNT_START;
}
export const addBankAccountStart = (): AddBankAccountStartAction => ({
  type: ADD_BANK_ACCOUNT_START
});

export interface AddBankAccountSuccessAction extends Action {
  type: typeof ADD_BANK_ACCOUNT_SUCCESS;
  payload: BankAccount;
}

export const addBankAccountSuccess = (bankAccount: BankAccount): AddBankAccountSuccessAction => ({
  type: ADD_BANK_ACCOUNT_SUCCESS,
  payload: bankAccount
});

export interface AddBankAccountFailedAction extends Action {
  type: typeof ADD_BANK_ACCOUNT_FAILED;
  payload: string;
}

export const addBankAccountFailed = (fetchError: string): AddBankAccountFailedAction => ({
  type: ADD_BANK_ACCOUNT_FAILED,
  payload: fetchError
});

export interface RemoveBankAccountStartAction extends Action {
  type: typeof REMOVE_BANK_ACCOUNT_START;
}
export const removeBankAccountStart = (): RemoveBankAccountStartAction => ({
  type: REMOVE_BANK_ACCOUNT_START
});

export interface RemoveBankAccountSuccessAction extends Action {
  type: typeof REMOVE_BANK_ACCOUNT_SUCCESS;
  payload: BankAccount;
}

export const removeBankAccountSuccess = (bankAccount: BankAccount): RemoveBankAccountSuccessAction => ({
  type: REMOVE_BANK_ACCOUNT_SUCCESS,
  payload: bankAccount
});

export interface RemoveBankAccountFailedAction extends Action {
  type: typeof REMOVE_BANK_ACCOUNT_FAILED;
  payload: string;
}

export const removeBankAccountFailed = (fetchError: string): RemoveBankAccountFailedAction => ({
  type: REMOVE_BANK_ACCOUNT_FAILED,
  payload: fetchError
});

export interface FetchBankAccountsStartAction extends Action {
  type: typeof FETCH_BANK_ACCOUNTS_START;
}

export const fetchBankAccountsStart = (): FetchBankAccountsStartAction => ({
  type: FETCH_BANK_ACCOUNTS_START
});

export interface FetchBankAccountsSuccessAction extends Action {
  type: typeof FETCH_BANK_ACCOUNTS_SUCCESS;
  payload: BankAccount[];
}

export const fetchBankAccountsSuccess = (bankAccounts: BankAccount[]): FetchBankAccountsSuccessAction => ({
  type: FETCH_BANK_ACCOUNTS_SUCCESS,
  payload: bankAccounts
});

export interface FetchBankAccountsFailedAction extends Action {
  type: typeof FETCH_BANK_ACCOUNTS_FAILED;
  payload: string;
}

export const fetchBankAccountsFailed = (fetchError: string): FetchBankAccountsFailedAction => ({
  type: FETCH_BANK_ACCOUNTS_FAILED,
  payload: fetchError
});

export interface FetchBanksStartAction extends Action {
  type: typeof FETCH_BANKS_START;
}

export const fetchBanksStart = (): FetchBanksStartAction => ({
  type: FETCH_BANKS_START
});

export interface FetchBanksSuccessAction extends Action {
  type: typeof FETCH_BANKS_SUCCESS;
  payload: Bank[];
}

export const fetchBanksSuccess = (banks: Bank[]): FetchBanksSuccessAction => ({
  type: FETCH_BANKS_SUCCESS,
  payload: banks
});

export interface FetchBanksFailedAction extends Action {
  type: typeof FETCH_BANKS_FAILED;
  payload: string;
}

export const fetchBanksFailed = (fetchError: string): FetchBanksFailedAction => ({
  type: FETCH_BANKS_FAILED,
  payload: fetchError
});

export type BankAccountAction =
  | FetchBankAccountsStartAction
  | FetchBankAccountsSuccessAction
  | FetchBankAccountsFailedAction
  | FetchBanksStartAction
  | FetchBanksSuccessAction
  | FetchBanksFailedAction
  | AddBankAccountStartAction
  | AddBankAccountSuccessAction
  | AddBankAccountFailedAction
  | RemoveBankAccountStartAction
  | RemoveBankAccountSuccessAction
  | RemoveBankAccountFailedAction;

export const fetchBankAccounts =
  (policyNumber: string) =>
  async (dispatch: Dispatch<BankAccountAction>): Promise<void> => {
    dispatch(fetchBankAccountsStart());
    try {
      const response = await fetchBankAccountsApi(policyNumber);
      dispatch(fetchBankAccountsSuccess(response.data.bankAccounts));
    } catch (error) {
      dispatch(fetchBankAccountsFailed(error.message));
    }
  };

export const fetchBanks =
  () =>
  async (dispatch: Dispatch<BankAccountAction>): Promise<void> => {
    dispatch(fetchBanksStart());
    try {
      const response = await fetchBanksApi();
      dispatch(fetchBanksSuccess(response.data.banks));
    } catch (error) {
      dispatch(fetchBanksFailed(error.message));
    }
  };

export const addBankAccountProcess =
  (partialBankAccount: BankAccount) =>
  async (dispatch: Dispatch<BankAccountAction | SDKAction>, getState: () => RootState): Promise<void> => {
    dispatch(setSDKLoading(true));
    dispatch(addBankAccountStart());
    const countryCode = getUserCountryCode(getState()) ?? 'TH';
    try {
      const bank = await addBankAccountApi(partialBankAccount, countryCode);

      logFirebaseEventBackend('eb_make_claim', {
        feature: 'MakeClaim',
        journey: 'make_claim',
        stage: 'claim_type',
        screen_id: 'SCR_EB_CLAIM_CTS',
        screen_name: 'ClaimTypeScreen',
        status: 'success'
      });
      dispatch(addBankAccountSuccess({ ...bank, isNew: true }));
    } catch (e) {
      dispatch(setSDKException(e));
      const errorMessage = getValue(e, 'networkError.result.errors.0.message', e.message);
      dispatch(addBankAccountFailed(errorMessage));
      logFirebaseEventBackend('eb_make_claim', {
        feature: 'MakeClaim',
        journey: 'make_claim',
        stage: 'claim_type',
        screen_id: 'SCR_EB_CLAIM_CTS',
        screen_name: 'ClaimTypeScreen',
        status: 'fail'
      });
    }
    dispatch(setSDKLoading(false));
  };

export const removeBankAccountProcess =
  (partialBankAccount: BankAccount) =>
  async (dispatch: Dispatch<BankAccountAction | SDKAction>): Promise<void> => {
    dispatch(setSDKLoading(true));
    dispatch(removeBankAccountStart());
    try {
      await removeBankAccountApi(partialBankAccount.id);
      dispatch(removeBankAccountSuccess(partialBankAccount));
    } catch (e) {
      dispatch(setSDKException(e));
      const errorMessage = getValue(e, 'networkError.result.errors.0.message', e.message);

      dispatch(removeBankAccountFailed(errorMessage));
    }
    dispatch(setSDKLoading(false));
  };
