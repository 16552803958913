import gql from 'graphql-tag';
import { LogRequest } from '../../types/logRequest';

import apiClient from '../../utils/apiClient';

interface LogRequestsResponse {
  data: {
    logRequests: LogRequest[];
  };
}

export const fetchLogRequestsApi = (): Promise<LogRequestsResponse> =>
  apiClient.query({
    query: gql`
      {
        logRequests {
          ref
          status
          statusText
          downloadUrl
          hospital {
            id
            name
          }
          patient {
            id
            name
          }
          diagnosis {
            id
            name
          }
          symptomDate
          symptomDescription
          admissionDate
          submissionDate
          questions {
            key
            answer
          }
        }
      }
    `
  });
