import { Platform, Linking, Alert } from 'react-native';
import { getUrlParams } from './url';

export const isIOS = (): boolean => Platform.OS === 'ios';

interface OpenMailOptions {
  subject?: string;
  body?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OpenURLReturn = Promise<any>;

export const openMail = async (email: string, options: OpenMailOptions = {}): OpenURLReturn => {
  const { subject, body } = options;

  const params = getUrlParams(
    {
      subject,
      body
    },
    { includePrefix: true }
  );

  const url = `mailto:${email}${params}`;

  const supported = await Linking.canOpenURL(url);

  if (supported) {
    return await Linking.openURL(url);
  } else {
    return Alert.alert(
      'An error occurred attempting to open device e-mail client. Please open your e-mail client manually.'
    );
  }
};

export const callPhoneNumber = async (phoneNumber: string): Promise<void> => {
  const url = Platform.select({ ios: `tel://${phoneNumber}`, android: `tel:${phoneNumber}` });
  if (url) {
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      return Linking.openURL(url);
    } else {
      Alert.alert('Call phone number is not supported.'); // Not supported on iOS Simulator
    }
  }
  return Promise.resolve();
};

export const openURL = async (url: string): Promise<void> => {
  if (url) {
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      return Linking.openURL(url);
    } else {
      Alert.alert('URL is not valid.');
    }
  }
  return Promise.resolve();
};

export const openWebURL = async (url: string): Promise<void> => {
  if (url && (url.indexOf('http://') == 0 || url.indexOf('https://') == 0)) {
    try {
      await Linking.openURL(url);
    } catch (err) {
      alert(url);
      // Alert.alert(url);
    }
  } else {
    alert(url);
    // Alert.alert(url);
  }
};
