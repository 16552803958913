const clinic = [
    {
        "value": "50000017",
        "label": "WEST POINT HOSPITAL PTE LTD"
    },
    {
        "value": "50000006",
        "label": "NATIONAL UNIVERSITY HOSPITAL (S) PTE LTD"
    },
    {
        "value": "50001047",
        "label": "OTHER HOSPITAL"
    },
    {
        "value": "50000019",
        "label": "NATIONAL DENTAL CENTRE"
    },
    {
        "value": "50000020",
        "label": "JURONG MEDICAL CENTRE"
    },
    {
        "value": "50000013",
        "label": "NATIONAL HEART CENTRE OF SINGAPORE PTE LTD"
    },
    {
        "value": "50000008",
        "label": "SINGAPORE GENERAL HOSPITAL"
    },
    {
        "value": "50000015",
        "label": "SINGAPORE NATIONAL EYE CENTRE PTE LTD"
    },
    {
        "value": "50142629",
        "label": "CHILDREN & BABIES SURGERY"
    },
    {
        "value": "50146160",
        "label": "PACIFIC SURGICAL AND ENDOSCOPY CENTRE PTE LTD"
    },
    {
        "value": "50104236",
        "label": "NOVENA SURGERY PTE LTD"
    },
    {
        "value": "50123002",
        "label": "PARKWAY CANCER CENTRE"
    },
    {
        "value": "50365880",
        "label": "THE ENDOSCOPY & PILES CENTRE (HOUGANG)"
    },
    {
        "value": "50467456",
        "label": "SHALOM MEDICAL DENTAL GROUP PTE LTD"
    },
    {
        "value": "50390546",
        "label": "SENGKANG GENERAL HOSPITAL"
    },
    {
        "value": "50114870",
        "label": "CONCORD CANCER HOSPITAL"
    },
    {
        "value": "50106224",
        "label": "ISLAND GROUP CLINIC"
    },
    {
        "value": "50263439",
        "label": "NG TENG FONG GENERAL HOSPITAL"
    },
    {
        "value": "50305764",
        "label": "SENGKANG HEALTH PTE LTD"
    },
    {
        "value": "50267105",
        "label": "JURONG HEALTH SERVICES PTE LTD"
    },
    {
        "value": "50313701",
        "label": "APTUS SURGERY CENTRE PTE LTD"
    },
    {
        "value": "50333327",
        "label": "DERMATOLOGY & SURGERY CLINIC (ORCHARD) PTE LTD"
    },
    {
        "value": "50366350",
        "label": "APPLE ENT CENTRE PTE LTD"
    },
    {
        "value": "50000001",
        "label": "ALEXANDRA HOSPITAL"
    },
    {
        "value": "50000010",
        "label": "KKH PTE LTD"
    },
    {
        "value": "50000012",
        "label": "NATIONAL CANCER CENTRE"
    },
    {
        "value": "50000014",
        "label": "NATIONAL SKIN CENTRE (S) PTE LTD"
    },
    {
        "value": "50001048",
        "label": "OTHER CLINIC"
    },
    {
        "value": "50050923",
        "label": "KHOO TECK PUAT HOSPITAL"
    },
    {
        "value": "50051087",
        "label": "FAMILY MEDICARE CLINIC & SURGERY"
    },
    {
        "value": "50142565",
        "label": "ASIA HEALTHPARTNERS PTE LTD"
    },
    {
        "value": "50467457",
        "label": "SHALOM CLINIC & SURGERY"
    },
    {
        "value": "50000021",
        "label": "MHC MEDICAL NETWORK PTE LTD"
    },
    {
        "value": "50000004",
        "label": "GLENEAGLES HOSPITAL"
    },
    {
        "value": "50000011",
        "label": "MOUNT ELIZABETH HOSPITAL"
    },
    {
        "value": "50114865",
        "label": "MOUNT ELIZABETH NOVENA HOSPITAL"
    },
    {
        "value": "50000007",
        "label": "RAFFLES HOSPITAL PTE LTD"
    },
    {
        "value": "50000002",
        "label": "CHANGI GENERAL HOSPITAL"
    },
    {
        "value": "50000005",
        "label": "MOUNT ALVERNIA HOSPITAL"
    },
    {
        "value": "50263421",
        "label": "FARRER PARK HOSPITAL PTE LTD"
    },
    {
        "value": "50362103",
        "label": "ADMIRALTY MEDICAL CENTRE"
    },
    {
        "value": "50000009",
        "label": "TAN TOCK SENG HOSPITAL PTE LTD"
    },
    {
        "value": "50000018",
        "label": "INSTITUTE OF MENTAL HEALTH"
    },
    {
        "value": "50000003",
        "label": "PARKWAY EAST HOSPITAL"
    },
    {
        "value": "50560913",
        "label": "ATLAS EYE SPECIALIST CENTRE PTE LTD"
    },
    {
        "value": "50000016",
        "label": "THOMSON MEDICAL PTE LTD"
    },
    {
        "value": "50573110",
        "label": "STARMED SPECIALIST CENTRE PTE LTD"
    }
]
export default clinic;