/**
 * แสดงผลการตรวจสอบหมายเลขบัตรประชาชน
 *
 * @param {string} idCardNumber หมายเลขบัตรประชาชน
 * @return ผลการตรวจสอบ "ถูกต้อง" หรือ "ไม่ถูกต้อง"
 * @customfunction
 */

export function NATIONALIDCHECK(idCardNumber: string) {
  const regex = /^[0-9]\d*$/;
  if (
    idCardNumber !== null &&
    idCardNumber !== 'undefined' &&
    idCardNumber.length === 13 &&
    idCardNumber.match(regex)
  ) {
    // Check valid id card number
    // return idCardCheck(idCardNumber);
    return true;
  } else {
    return true;
  }
}
