import { ClaimDocumentConfig } from './../types/claim';

export const DOCUMENTS: ClaimDocumentConfig = {
  doc1: {
    docType: 'medicalReceipt',
    title: 'CLAIMS__DOCUMENTS__1',
    fileName: 'Original receipt and Billing statement',
    helpText: '',
    isOptional: false,
    originalRequired: false
  },
  doc2: {
    docType: 'medicalCertificate',
    title: 'CLAIMS__DOCUMENTS__2',
    fileName: 'Medical certificate',
    helpText: '',
    isOptional: false,
    originalRequired: false
  },
  doc3: {
    docType: 'medicalResult',
    title: 'CLAIMS__DOCUMENTS__3',
    fileName: 'Lab X-ray',
    helpText: '',
    isOptional: true,
    originalRequired: false
  },
  doc4: {
    docType: 'otherDocuments',
    title: 'CLAIMS__DOCUMENTS__4',
    fileName: 'Other supporting documents',
    helpText: '',
    isOptional: true,
    originalRequired: false
  },
  doc5: {
    docType: 'bankTransfer',
    title: 'CLAIMS__DOCUMENTS__5',
    fileName: 'Bank pass book',
    helpText: '',
    isOptional: true,
    originalRequired: false
  },
  doc6: {
    docType: 'otherDocuments',
    title: 'CLAIMS__DOCUMENTS__PACS__1',
    fileName: 'document',
    helpText: '',
    isOptional: false,
    originalRequired: false
  },
  doc7: {
    docType: 'bankTransfer',
    title: 'CLAIMS__DOCUMENTS__5',
    fileName: 'Bank pass book',
    helpText: '',
    isOptional: false,
    originalRequired: false
  },
  medicalCertificate: {
    docType: 'medicalCertificate',
    title: 'Inpatient Discharge Summary if your admission is in a Singapore Government Restructured Hospital',
    fileName: 'Medical Certificate',
    helpText: '',
    originalRequired: false
  },
  medicalExpense: {
    docType: 'medicalExpense',
    title: 'Original final hospital bills, tax invoices and receipts',
    fileName: 'Medical Expense',
    helpText: '',
    originalRequired: true
  },
  medicalReceipt: {
    docType: 'medicalReceipt',
    title:
      'Part C of the GHS Claim Form if your admission is in a Private Hospital or Overseas Hospital (found in the Documents page)',
    fileName: 'Medical Receipt',
    helpText: '',
    originalRequired: true
  },
  medicalResult: {
    docType: 'medicalResult',
    title: 'Settlement letter if there is any payment from your integrated Shield Plan or 3rd party’s insurance plan',
    fileName: 'Medical Result',
    helpText: '',
    originalRequired: false
  },
  bankTransfer: {
    docType: 'bankTransfer',
    title: 'Proof of payment by you e.g. copy of payslip (if you co-pay part of the bill)',
    fileName: 'Book Bank',
    helpText: '',
    originalRequired: false
  },
  dischargeSummary: {
    docType: 'dischargeSummary',
    title: 'Inpatient Discharge Summary if your admission is in a Singapore Government Restructured Hospital',
    fileName: 'Discharge Summary',
    helpText: '',
    originalRequired: false
  },
  claimForm: {
    docType: 'claimForm',
    title:
      'Part C of the GHS Claim Form if your admission is in a Private Hospital or Overseas Hospital (found in the Documents page)',
    fileName: 'Claim Form',
    helpText: '',
    originalRequired: false
  },
  settlementLetter: {
    docType: 'settlementLetter',
    title: 'Settlement letter if there is any payment from your integrated Shield Plan or 3rd party’s insurance plan',
    fileName: 'Settlement Letter',
    helpText: '',
    originalRequired: false
  },
  medisave: {
    docType: 'medisave',
    title:
      'A copy of CPF Medisave Transaction for Medical Expenses’ showing the HRN code for bills with medisave payment',
    fileName: 'Medisave Payment',
    helpText: '',
    originalRequired: false
  },
  otherDocuments: {
    docType: 'otherDocuments',
    title: 'Other Documents (Affidavit of Discrepancy, police report, incident report, letter from client)',
    fileName: 'Claim Document',
    helpText: '',
    originalRequired: false,
    isOptional: false
  },
  claimantStatement: {
    docType: 'claimantStatement',
    title: 'Claimant Statement',
    fileName: 'Claimant Statement',
    helpText: '',
    originalRequired: false,
    isOptional: false
  },
  physicianStatement: {
    docType: 'physicianStatement',
    title: `Attending Physician's Statement`,
    fileName: 'Attending Physician Statement',
    helpText: '',
    originalRequired: false,
    isOptional: false
  },
  deathCertificate: {
    docType: 'deathCertificate',
    title: 'Duly Certified Death Certificate with Registry Number',
    fileName: 'Death Certificate',
    helpText: '',
    originalRequired: false
  },
  birthCertificate: {
    docType: 'birthCertificate',
    title: 'Birth Certificate of Insured',
    fileName: 'Birth Certificate',
    helpText: '',
    originalRequired: false
  },
  birthCertificateBeneficiary: {
    docType: 'birthCertificateBeneficiary',
    title: 'Birth Certificate of Beneficiary/ies',
    fileName: 'Birth Certificate',
    helpText: '',
    originalRequired: false
  },
  governmentId: {
    docType: 'governmentId',
    title: '1 Valid Government ID Only',
    fileName: 'Government Id',
    helpText: '',
    originalRequired: false,
    isOptional: false
  },
  governmentIssued: {
    docType: 'governmentIssued',
    title: 'Government Issued Certificates(Marriage, death, birth certificate)',
    fileName: 'Government Issued',
    helpText: '',
    originalRequired: false,
    isOptional: false
  },
  marriageContract: {
    docType: 'marriageContract',
    title: 'Marriage Contract for Spouse',
    fileName: 'Marriage Contract',
    helpText: 'If claimant is married, Marriage Contract is mandatory.',
    isOptional: true,
    originalRequired: false
  },
  medicalRecords: {
    docType: 'medicalRecords',
    title:
      'Medical Records from Hospital or Clinic(Hospital statement of account, admission summary, medical certificate, lab results)',
    fileName: 'Medical Records',
    helpText: '',
    originalRequired: false,
    isOptional: false
  },
  hospitalStatement: {
    docType: 'hospitalStatement',
    title: 'Duly Certified Hospital Statement of Account',
    fileName: 'Hospital Statement',
    helpText: '',
    originalRequired: false
  },
  policeReport: {
    docType: 'policeReport',
    title: 'Police Report (if Death by Accident cause and Report to police)',
    fileName: 'Police Report',
    helpText: '',
    isOptional: true,
    originalRequired: false
  },
  accidentReport: {
    docType: 'accidentReport',
    title: 'Duly Certified Traffic accident report',
    fileName: 'Accident Report',
    helpText:
      'If claim being filed is due to traffic or vehicular accident, Duly Certified Traffic accident report is mandatory.',
    isOptional: true,
    originalRequired: false
  },
  witnessStatement: {
    docType: 'witnessStatement',
    title: 'Sworn statement form of at least 2 witness',
    fileName: 'Witness Statement',
    helpText: '',
    originalRequired: false
  },
  incidentReport: {
    docType: 'incidentReport',
    title: 'Duly signed incident report, if any',
    fileName: 'Incident Report',
    helpText: 'If claim being filed is due to incident, Duly signed incident report is mandatory.',
    isOptional: true,
    originalRequired: false
  },
  medicoLegalReport: {
    docType: 'medicoLegalReport',
    title: 'Duly Certified Medico Legal Report',
    fileName: 'Medico Legal Report',
    helpText: 'If claim being filed requires an autopsy, Duly Certified Medico Legal Report is mandatory.',
    isOptional: true,
    originalRequired: false
  },
  drivingLicense: {
    docType: 'drivingLicense',
    title: "Driver's License of Insured",
    fileName: 'Driver License',
    helpText: "If claim being filed is due to traffic or vehicular accident, Driver's License of Insured is mandatory.",
    isOptional: true,
    originalRequired: false
  },
  vehicleCertificate: {
    docType: 'vehicleCertificate',
    title: 'Vehicle OR (Official Receipt) CR (Certificate of Registration)',
    fileName: 'Vehicle Certificate',
    helpText: 'If claim being filed is due to traffic or vehicular accident, Vehicle or CR is mandatory.',
    isOptional: true,
    originalRequired: false
  },
  employmentCertificate: {
    docType: 'employmentCertificate',
    title: 'Employment Certificate - if Insured is working',
    fileName: 'Employment Certificate',
    helpText: '',
    originalRequired: false
  },
  notarizedStatement: {
    docType: 'notarizedStatement',
    title:
      'Notarized Statement/Affidavit from Insured of Tasks performed before and after the disability/Activities of Daily Living before and after the occurance of accident/illness',
    fileName: 'Notarized Statement',
    helpText: '',
    originalRequired: false
  },
  accidentIncidentReport: {
    docType: 'accidentIncidentReport',
    title: 'Incident Report if due to accident',
    fileName: 'Incident Report',
    helpText: 'If claim being filed is due to traffic or vehicular accident, Incident Report is mandatory.',
    isOptional: true,
    originalRequired: false
  },
  trafficAccidentReport: {
    docType: 'trafficAccidentReport',
    title: 'Traffic Accident or Police Report if due to accident',
    fileName: 'Traffic Accident Report',
    helpText:
      'If claim being filed is due to traffic or vehicular accident, Traffic Accident or Police Report is mandatory.',
    isOptional: true,
    originalRequired: false
  },
  expenseReceipt: {
    docType: 'expenseReceipt',
    title: 'Original Official Receipt of expense related to the treatment of injury',
    fileName: 'Receipt of expense',
    helpText: '',
    originalRequired: true
  },
  originalOfficialReceipt: {
    docType: 'originalOfficialReceipt',
    title: 'Original Official Receipts',
    fileName: 'Original Official Receipts',
    helpText: '',
    originalRequired: true,
    isOptional: false
  },
  cardiologistStatement: {
    docType: 'cardiologistStatement',
    title: "Attending Physician's Statement Accomplished by Cardiologist",
    fileName: 'Cardiologist Statement',
    helpText: '',
    originalRequired: false
  },
  ecgResult: {
    docType: 'ecgResult',
    title: 'All ECG results and interpretation - Series',
    fileName: 'ECG results',
    helpText: '',
    originalRequired: false
  },
  cardiacEnzyme: {
    docType: 'cardiacEnzyme',
    title: 'Cardiac Enzyme - Troponin / CK-MB',
    fileName: 'Cardiac Enzyme',
    helpText: '',
    originalRequired: false
  },
  angiogramResult: {
    docType: 'angiogramResult',
    title: 'Coronary Angiogram Result',
    fileName: 'Coronary Angiogram Result',
    helpText: '',
    originalRequired: false
  },
  echocardiogram: {
    docType: 'echocardiogram',
    title: 'Echocardiogram',
    fileName: 'Echocardiogram',
    helpText: '',
    originalRequired: false
  },
  echocardiogramOptional: {
    docType: 'echocardiogramOptional',
    title: 'Echocardiogram, if any',
    fileName: 'Echocardiogram Optional',
    isOptional: true,
    helpText: 'If an Echocardiogram was performed, Echocardiogram is mandatory.',
    originalRequired: false
  },
  neurologistStatement: {
    docType: 'neurologistStatement',
    title: "Attending Physician's Statement Accomplished by Neurologist",
    fileName: 'Neurologist Statement',
    helpText: '',
    originalRequired: false
  },
  infectiousStatement: {
    docType: 'infectiousStatement',
    title: "Attending Physician's Statement Accomplished by Neurologist/Infectious Disease Specialist",
    fileName: 'Neurologist and Infectious Disease Specialist Statement',
    helpText: '',
    originalRequired: false
  },
  cardiovascularStatement: {
    docType: 'cardiovascularStatement',
    title: "Attending Physician's Statement Accomplished by Cardiologist and Cardiovascular Surgeon",
    fileName: 'Cardiologist and Cardiovascular Statement',
    helpText: '',
    originalRequired: false
  },
  ctScan: {
    docType: 'ctScan',
    title: 'All CT scan/s',
    fileName: 'CT scan',
    helpText: '',
    originalRequired: false
  },
  mriResult: {
    docType: 'mriResult',
    title: 'MRI result/s',
    fileName: 'MRI result',
    helpText: '',
    originalRequired: false
  },
  oncologistStatement: {
    docType: 'oncologistStatement',
    title: "Attending Physician's Statement Accomplished by Oncologist",
    fileName: 'Oncologist Statement',
    helpText: '',
    originalRequired: false
  },
  biopsyResult: {
    docType: 'biopsyResult',
    title: 'Biopsy and/or Histopathology Result',
    fileName: 'Medical Result',
    helpText: '',
    originalRequired: false
  },
  biopsyResultOptional: {
    docType: 'biopsyResultOptional',
    title: 'Biopsy Report, if any',
    fileName: 'Biopsy Report',
    isOptional: true,
    helpText: 'If a Biopsy was performed, Biopsy Report is mandatory.',
    originalRequired: false
  },
  diagnosticResult: {
    docType: 'diagnosticResult',
    title: 'If Leukemia, Bone Marrow Diagnostic result',
    fileName: 'Diagnostic Result',
    isOptional: true,
    helpText: 'If Leukemia, Bone Marrow Diagnostic result is mandatory',
    originalRequired: false
  },
  hematologistStatement: {
    docType: 'hematologistStatement',
    title: [
      /**
       * The colon (:) in the line below is a special symbol.
       * Normal colon will be treated as nsSeparator of i18n.
       * Namespace separator is not rendered when used with t(title).
       */
      "Attending Physician's Statement Accomplished by꞉",
      ' • Bone Marrow – Hematologist',
      ' • Kidney – Nephrologist',
      ' • Liver – Hepatologist',
      ' • Heart – Cardiologist',
      ' • Lungs – Cardiovascular surgeon or Pulmonologist'
    ].join('\n'),
    fileName: 'Hematologist Statement',
    helpText: '',
    originalRequired: false
  },
  operationRecord: {
    docType: 'operationRecord',
    title: 'Record of Operation',
    fileName: 'Record of Operation',
    helpText: '',
    originalRequired: false
  },
  nephrologistStatement: {
    docType: 'nephrologistStatement',
    title: "Attending Physician's Statement Accomplished by Nephrologist",
    fileName: 'Nephrologist Statement',
    helpText: '',
    originalRequired: false
  },
  urineCollection: {
    docType: 'urineCollection',
    title: '24-hour urine collection result',
    fileName: 'Urine collection Result',
    helpText: '',
    originalRequired: false
  },
  bunReport: {
    docType: 'bunReport',
    title: 'BUN (Blood Ura Nitrogen)',
    fileName: 'BUN',
    helpText: '',
    originalRequired: false
  },
  creatinine: {
    docType: 'creatinine',
    title: 'Creatinine',
    fileName: 'Creatinine',
    helpText: '',
    originalRequired: false
  },
  dialysisRecord: {
    docType: 'dialysisRecord',
    title: 'Dialysis Record (Certified True Copy)',
    fileName: 'Dialysis Record',
    helpText: '',
    originalRequired: false
  },
  gfrReport: {
    docType: 'gfrReport',
    title: 'Glomerular Filtration Rate(GFR)',
    fileName: 'GFR',
    helpText: '',
    originalRequired: false
  },
  renalFunctionTest: {
    docType: 'renalFunctionTest',
    title: 'Renal Function Test',
    fileName: 'Renal Function Test',
    helpText: '',
    originalRequired: false
  },
  dailyLiving: {
    docType: 'dailyLiving',
    title:
      'Notarized Statement/Affidavit from Insured of Tasks performed before and after the disability/Activities of Daily Living before and after the occurrence of accident/illness',
    fileName: 'Activities of Daily Living',
    helpText: '',
    originalRequired: false
  },
  sclerosisResult: {
    docType: 'sclerosisResult',
    title: 'All Laboratory results for Multiple Sclerosis',
    fileName: 'Laboratory results',
    helpText: '',
    originalRequired: false
  },
  neuroneDisease: {
    docType: 'neuroneDisease',
    title: 'All Laboratory results for Motor Neurone Disease',
    fileName: 'Laboratory results',
    helpText: '',
    originalRequired: false
  },
  cardiacCatheterization: {
    docType: 'cardiacCatheterization',
    title: 'Cardiac Catheterization',
    fileName: 'Cardiac Catheterization',
    helpText: '',
    originalRequired: false
  },
  neurologicalResult: {
    docType: 'neurologicalResult',
    title: 'Neurologic Exam Result',
    fileName: 'Neurologic Exam Result',
    helpText: '',
    originalRequired: false
  },
  cerebrospinalResult: {
    docType: 'cerebrospinalResult',
    title: 'Cerebrospinal Fluid (CSF) Exam Result',
    fileName: 'Cerebrospinal Fluid (CSF) Exam',
    helpText: '',
    originalRequired: false
  },
  cerebrospinalCulture: {
    docType: 'cerebrospinalCulture',
    title: 'Cerebrospinal Fluid (CSF) Culture',
    fileName: 'Cerebrospinal Fluid (CSF) Cult',
    helpText: '',
    originalRequired: false
  },
  medicalCertificateResults: {
    docType: 'medicalCertificateResults',
    title: 'Medical Certificate and Results',
    fileName: 'Medical Certificate and Results',
    helpText: '',
    isOptional: false,
    originalRequired: false
  },
  policyCertificate: {
    docType: 'policyCertificate',
    title: 'Policy Certificate',
    fileName: 'Policy Certificate',
    helpText: '',
    isOptional: false,
    originalRequired: false
  },
  beneficiaryCard: {
    docType: 'beneficiaryCard',
    title: `Beneficiary's Personal Identity Card (Id Card / Driver License / Passport / Birth Certificate)`,
    fileName: `Beneficiary's Personal Identity Card`,
    helpText: '',
    isOptional: false,
    originalRequired: false
  },
  policyHolderCard: {
    docType: 'policyHolderCard',
    title: `Policy Holder's Personal Identity Card (Id Card / Driver License / Passport / Birth Certificate)`,
    fileName: `Policy Holder's Personal Identity Card`,
    helpText: '',
    isOptional: true,
    originalRequired: false
  },
  deceasedNRIC: {
    docType: 'deceasedNRIC',
    title: ` Deceased NRIC`,
    fileName: ` Deceased NRIC`,
    helpText: '',
    isOptional: true,
    originalRequired: false
  }
};

export default {
  DOCUMENTS
};
