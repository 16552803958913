import React, { useRef, useEffect } from 'react';
import { ScrollView, View, FlatList } from 'react-native';

import { StackNavigationProp } from '../../../types/navigation';
import { useTranslation } from 'react-i18next';
import { UserCountryCode } from '../../../types/user';
import { PruText } from '@ebsme/pulse-rn-components';
import Screen from '../../../components/Screen';
import { Locale } from '../../../types/sdk';
import { Consent } from '../../../types/claim';

export interface Props {
  lbu: UserCountryCode;
  locale: Locale;
  consents: Consent[];
  fetchConsentProcess: (claimType: string) => void;
  navigation: StackNavigationProp<{}, {
    fromFinal: boolean,
    claimType: string
  }>;
}


const ConsentScreen: React.FC<Props> = ({ navigation, locale, consents, fetchConsentProcess }) => {
  const { t } = useTranslation();

  const isFromFinal = navigation.getParam('fromFinal');
  const claimType = navigation.getParam('claimType');

  const [header1, header2] = t('CLAIMS__CONSENT_HEADER');

  const scrollRefObj = useRef<ScrollView>();

  useEffect(() => {
    if (isFromFinal) {
      // call api get consents
      fetchConsentProcess(claimType || "");
    }
  }, [])

  const renderItemConsent = (item: Consent) => (
    <View style={{ flexDirection: 'row', marginBottom: 10 }}>
      <View style={{ backgroundColor: 'black', height: 6, width: 6, borderRadius: 3, marginRight: 10, marginTop: 7 }} />
      <PruText style={{ paddingRight: 10 }} color="black">{locale === 'th' ? item.th : item.en}</PruText>
    </View>
  )

  return (
    <Screen
      scrollRef={scrollRefObj}
      headerPaddingHorizontal={24}
      headingPosition={'none'}
      containerType="KeyboardAwareScrollView"
      containerProps={{ enableOnAndroid: true, extraScrollHeight: 100, keyboardShouldPersistTaps: 'handled' }}
      EndComponent={null}
      onPressBack={(): boolean => navigation.goBack()}
      onPressCancel={(): boolean => navigation.goBack()}
    >
      <View style={{ height: 100, backgroundColor: 'red', justifyContent: 'center', alignItems: 'center', paddingHorizontal: 20 }}>
        <PruText color="white" weight="bold" style={{ fontSize: 20, textAlign: 'center' }}>
          {isFromFinal ? (header2 !== ' ' ? `${header1} \n ${header2}` : header1) : t('CLAIMS__CONSENT_TITLE')}
        </PruText>
      </View>
      {
        !isFromFinal ?
          <View style={{ margin: 20 }}>
            <View style={{ flexDirection: 'row', marginBottom: 10 }}>
              <View style={{ backgroundColor: 'black', height: 6, width: 6, borderRadius: 3, marginRight: 10, marginTop: 7 }} />
              <PruText color="black">{t('CLAIMS__CONSENT_1')}</PruText>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ backgroundColor: 'black', height: 6, width: 6, borderRadius: 3, marginRight: 10, marginTop: 7 }} />
              <PruText color="black">{t('CLAIMS__CONSENT_2')}</PruText>
            </View>
          </View> :
          <FlatList
            style={{ padding: 20 }}
            data={consents || []}
            renderItem={({ item }) => renderItemConsent(item)}
            keyExtractor={(item, index) => `${index}`}
          />
      }
    </Screen>
  );
};

export default ConsentScreen;
