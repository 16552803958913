import { FeatureToggle } from '../../types/featureToggle';
import { GeneralConfig } from '../../types/generalConfig';
import { LBUConfig } from '../../types/lbuConfig';
import { SupportInfo } from '../../types/supportInfo';
import {
  ClaimTypeOption,
  ClaimDocumentConfig,
  ClaimViewConfig,
  PaymentOption,
  ClaimDateConfig
} from './../../types/claim';
import * as GRAPHQL from './sg/graphql';
import * as REQUEST_PICKER from './sg/requestPicker';
import { NATIONALIDCHECK } from './sg/nationalId';
import dataDiagnosis from './sg/diagnosis';

const CLAIM_TYPES: ClaimTypeOption[] = [
  {
    id: 'inpatient',
    label: 'Inpatient',
    originalRequired: false,
    documents: ['doc6'],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    reasons: [
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'accident',
        label: 'Accident'
      }
    ],
    isDefault: true,
    groupPlans: ['GEMM', 'GHSB', 'GMAT', 'GSMM', 'PEHS', 'P3HS', 'GHPS']
  },
  {
    id: 'outpatient-gp',
    label: 'General Practitioners (GP)',
    originalRequired: false,
    documents: ['doc6'],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: ['GOPB', 'GPRU', 'PEGP', 'PGAH', 'PGFH', 'PGIH', 'PGPW', 'P3GP']
  },
  {
    id: 'outpatient-specialist',
    label: 'Specialist',
    originalRequired: false,
    documents: ['doc6'],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: ['GOSP', 'PESP', 'PSAH', 'PSFH', 'PSIH', 'PSMH', 'PSPW', 'P3SP']
  },
  {
    id: 'outpatient-dental',
    label: 'Dental',
    originalRequired: false,
    documents: ['doc6'],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: ['GDEN']
  },
  {
    id: 'health-screening',
    label: 'Health-Screen/Vaccinations',
    originalRequired: false,
    documents: ['doc6'],
    isDefault: false,
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    groupPlans: ['HSVC']
  },
  {
    id: 'maternity',
    label: 'Maternity',
    originalRequired: false,
    documents: ['doc6'],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    reasons: [
      {
        id: 'normal-childbirth',
        label: 'Normal Childbirth'
      },
      {
        id: 'childbirth-involving-caesarean-section',
        label: 'Childbirth involving Caesarean Section'
      },
      {
        id: 'miscarriage',
        label: 'Miscarriage'
      }
    ],
    isDefault: false,
    groupPlans: ['GOSP', 'PESP', 'PSAH', 'PSFH', 'PSIH', 'PSMH', 'PSPW', 'P3SP']
  },
  {
    id: 'outpatient',
    label: 'Outpatient',
    originalRequired: false,
    documents: ['doc6'],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: ['GOPB', 'GPRU', 'PEGP', 'PGAH', 'PGFH', 'PGIH', 'PGPW', 'P3GP']
  }
];

const PAYMENT_OPTIONS: PaymentOption[] = [
  {
    id: 'giro',
    label: 'Giro',
    isRequiredBankAccount: true
  },
  {
    id: 'cheque',
    label: 'Cheque',
    isRequiredBankAccount: false
  }
];

const DATES: ClaimDateConfig = {
  claimDate: {
    dateType: 'claimDate',
    title: 'CLAIMS__CLAIM_DETAILS_CLAIM_DATE',
    isOptional: false,
    readOnly: true
  },
  accidentDate: {
    dateType: 'accidentDate',
    title: 'CLAIMS__CLAIM_DETAILS_DATE_OF_VISIT',
    isOptional: true,
    readOnly: false
  },
  admissionDate: {
    dateType: 'admissionDate',
    title: 'CLAIMS__CLAIM_DETAILS_DATE_OF_ADMISSION',
    isOptional: true,
    readOnly: false
  },
  dischargeDate: {
    dateType: 'dischargeDate',
    title: 'CLAIMS__CLAIM_DETAILS_DATE_OF_DISCHARGE',
    isOptional: true,
    readOnly: false
  }
};

const CLAIM_VIEW_CONFIG: ClaimViewConfig = {
  showOriginalReceiptRequired: true
  // showStartNewClaimBtn: true,
  // showAdditionalClaimInformation: true,
  // showAdditionalDocumentsContactUs: true
};

const DOCUMENTS: ClaimDocumentConfig = {};

const SUPPORT_INFO: SupportInfo = {
  emails: ['pulse.ecosystem@prudential.com.sg'],
  phones: ['1800 835 9733'],
  phoneHours: ['Mondays to Fridays,\n8.30am to 5.30pm'],
  submitTicketUrl: '',
  serviceStatusUrl: '',
  applicationHelpUrl: 'https://www.prudential.com.sg/wedopulse/faqs#section_businessatpulse',
  contactSupport: 'https://www.prudential.com.sg/contact-us'
};

const API_URL = {
  dev: 'https://plt-api-nprd.prudential.co.th/pacsebsme/dev/graphql/pulse-app',
  // dev: 'https://uat-api.eb.prudential.com.sg/graphql/pulse-app',
  uat: 'https://uat-api.eb.prudential.com.sg/graphql/pulse-app',
  prod: 'https://api.eb.prudential.com.sg/graphql/pulse-app'
};

const FEATURE_TOGGLE: FeatureToggle = {
  enabledSaveDraft: false
};

const MY_POLICY = {
  dateFormat: 'DD/MM/YYYY',
  lBUCode: 'SG',
  isShowDependantView: true
};

const GENERAL: GeneralConfig = {
  globalNamespace: 'global_pacs', //TODO: doesn't exist yet
  privacyUrl: 'https://www.prudential.com.sg/Privacy-Notice',
  termsOfUseUrl: 'https://www.prudential.com.sg/disclaimer-and-intellectual-rights'
};

export const SG: LBUConfig = {
  API_URL,
  CLAIM_TYPES,
  CLAIM_VIEW_CONFIG,
  DOCUMENTS,
  SUPPORT_INFO,
  FEATURE_TOGGLE,
  MY_POLICY,
  GRAPHQL,
  REQUEST_PICKER,
  GENERAL,
  NATIONALIDCHECK,
  PAYMENT_OPTIONS,
  DATES,
  DIAGNOSES: dataDiagnosis
};
