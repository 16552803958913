import { RootState } from '../../store';
import { CorporateUser, PulseConfig } from '../../types/user';
import { Entitlement, EntitlementsMap } from '../../types/entitlements';
import { MaritalStatus } from '../../types/employee';

export const getCorporateUserEmail = (state: RootState): string | undefined => state.CorporateUser.email;

export const getCorporateUserPhone = (state: RootState): string | undefined => state.CorporateUser.phone;

export const getCorporateUserNationalId = (state: RootState): string | undefined => state.CorporateUser.nationalId;

export const getCorporateUser = (state: RootState): CorporateUser | undefined => state.CorporateUser.corporateUser;

export const getFetchingCorporateUser = (state: RootState): boolean => state.CorporateUser.fetchingCorporateUser;

export const getRequestingForgetCredentials = (state: RootState): boolean =>
  state.CorporateUser.requestingForgetCredentials;

export const getFetchCorporateUserError = (state: RootState): string | undefined =>
  state.CorporateUser.fetchCorporateUserError;

export const getCorporateUserNotFound = (state: RootState): boolean => state.CorporateUser.corporateUserNotFound;

export const getRegisteringCorporateUser = (state: RootState): boolean => state.CorporateUser.registeringCorporateUser;

export const getRegisterCorporateUserError = (state: RootState): string | undefined =>
  state.CorporateUser.registerCorporateUserError;

export const getRequestForgetError = (state: RootState): boolean | undefined =>
  state.CorporateUser.requestForgetCredentailsError;

export const getUserEntitlementsMap = (state: RootState): EntitlementsMap => {
  const { entitlements = [] } = getCorporateUser(state) || {};

  return entitlements.reduce((acc: EntitlementsMap, { id, scope }: Entitlement) => {
    return {
      ...acc,
      [id]: scope
    };
  }, {} as EntitlementsMap);
};

export const getAuthCodeRef = (state: RootState): string | undefined => state.CorporateUser.authCodeRef;
export const isMarriedEmployee = (state: RootState): boolean =>
  state.CorporateUser.maritalStatus === MaritalStatus.MARRIED;

export const getMandatoryDocument = (
  state: RootState
): {
  [key: string]: boolean;
} => ({
  marriageContract: isMarriedEmployee(state)
});

export const getPulseConfig = (state: RootState): PulseConfig | undefined => state.CorporateUser.pulseConfig;

export const getFetchingPulseConfig = (state: RootState): boolean => state.CorporateUser.loadingPulseConfig;

export const hasDependantRole = (state: RootState): boolean | undefined => {
  const hasDependantRole =
    state.CorporateUser.pulseConfig?.ACCESS_CONTROL_LIST &&
    state.CorporateUser.pulseConfig.ACCESS_CONTROL_LIST.Roles &&
    state.CorporateUser.pulseConfig.ACCESS_CONTROL_LIST.Roles.includes('Dependant');
  return hasDependantRole;
};
