import { getObjValue } from '../../utils/object';
import { UserCountryCode } from '../../types/user';
import {
  ConfirmationScreen,
  DocumentScreen,
  FinalSummaryScreen,
  NextStepScreen,
  Step1Screen,
  ConsentScreen
} from './routes';

// Declarations
interface ClaimStep {
  totalSteps: number;
  currentStep: number;
  nextRoute?: string;
}

interface ClaimSkippingCondition {
  documentsRequired?: boolean;
}

type ClaimSkippingConditionBlock = (condition: ClaimSkippingCondition) => boolean;

interface RouteConfig {
  route: string;
  hideNext?: boolean; // eg: Final Summary has custom Submit button
  skipping?: ClaimSkippingConditionBlock; // to skip certain screen with condition.
}

const skipNextStep: ClaimSkippingConditionBlock = (c) => {
  return !c.documentsRequired;
};

const skipConfirmationStep: ClaimSkippingConditionBlock = (c) => {
  return !skipNextStep(c);
};

const skipConsent: ClaimSkippingConditionBlock = () => {
  return true;
};

// Configurations

const allSequences: {
  [key in UserCountryCode]: RouteConfig[];
} = {
  PH: [
    { route: Step1Screen },
    { route: DocumentScreen },
    { route: ConsentScreen, hideNext: true, skipping: skipConsent },
    { route: FinalSummaryScreen, hideNext: true }
  ],
  SG: [
    { route: Step1Screen },
    { route: DocumentScreen },
    { route: ConsentScreen, hideNext: true, skipping: skipConsent },
    { route: FinalSummaryScreen, hideNext: true },
    { route: NextStepScreen, skipping: skipNextStep },
    { route: ConfirmationScreen, skipping: skipConfirmationStep }
  ],
  TH: [
    { route: Step1Screen },
    { route: DocumentScreen },
    { route: ConsentScreen, hideNext: true, skipping: skipConsent },
    { route: FinalSummaryScreen, hideNext: true },
    { route: NextStepScreen, skipping: skipNextStep },
    { route: ConfirmationScreen, skipping: skipConfirmationStep }
  ],
  MY: [
    { route: Step1Screen },
    { route: DocumentScreen },
    { route: ConsentScreen, hideNext: true, skipping: skipConsent },
    { route: FinalSummaryScreen, hideNext: true },
    { route: NextStepScreen, skipping: skipNextStep },
    { route: ConfirmationScreen, skipping: skipConfirmationStep }
  ],
  MM: [
    { route: Step1Screen },
    { route: DocumentScreen },
    { route: ConsentScreen, hideNext: true, skipping: skipConsent },
    { route: FinalSummaryScreen, hideNext: true },
    { route: NextStepScreen, skipping: skipNextStep },
    { route: ConfirmationScreen, skipping: skipConfirmationStep }
  ]
};

// Private Functions

const getSequences = (lbu: UserCountryCode, options: ClaimSkippingCondition = {}): RouteConfig[] => {
  return (getObjValue(allSequences, lbu, [{ route: '' }]) as RouteConfig[]).filter(
    (e) => !e.skipping || !e.skipping(options)
  );
};

// Public Functions

/**
 * To get initial route based on current user's country code
 * @param lbu {UserCountryCode}
 * @returns initial route
 */
export const getInitialRoute = (lbu: UserCountryCode = 'SG'): string => {
  const lbuSequence = getSequences(lbu);
  return lbuSequence[0].route;
};

/**
 * To get stack sequences for draft view, to restore claim's ui state
 * @param lbu {UserCountryCode}
 * @param fromStep default first stack of drafted claim
 * @param targetStep claim's ui state
 * @returns routes stack
 */
export const getStackSequences = (lbu: UserCountryCode, targetStep: string, fromStep = DocumentScreen): string[] => {
  if (fromStep === targetStep) {
    return [];
  }
  const lbuSequence = getSequences(lbu).map((e) => e.route);
  const fromStepIndex = lbuSequence.indexOf(fromStep);
  if (fromStepIndex < 0) {
    return [];
  }
  const targetStepIndex = lbuSequence.indexOf(targetStep);
  if (targetStepIndex < 0 || fromStepIndex >= targetStepIndex) {
    return [];
  }
  return lbuSequence.slice(fromStepIndex + 1, targetStepIndex + 1);
};

/**
 * To get claim step info from configurations
 * @param lbu
 * @param currentRoute current MakeClaimView's route
 * @param options optional values to skip certain ui based on user's variables
 * @returns routes config including currentStep, nextRoute, and totalSteps
 */
export const getClaimStep = (
  lbu: UserCountryCode,
  currentRoute: string,
  options?: ClaimSkippingCondition
): ClaimStep => {
  const lbuSequence = getSequences(lbu, options);

  const currentRouteIndex = lbuSequence.findIndex((e) => e.route === currentRoute);
  const claimStep: ClaimStep = {
    totalSteps: lbuSequence.length,
    currentStep: currentRouteIndex + 1
  };
  if (
    currentRouteIndex < 0 ||
    !!lbuSequence[currentRouteIndex + 0].hideNext ||
    currentRouteIndex >= lbuSequence.length - 1
  ) {
    return claimStep;
  }
  return {
    ...claimStep,
    nextRoute: lbuSequence[currentRouteIndex + 1].route
  };
};
