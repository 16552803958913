import styled from 'styled-components/native';

import { red, white } from '../../assets/colors';
import { getShadowStyles } from '../../assets/mixins';

const cardShadow = getShadowStyles();

export const Container = styled.TouchableHighlight`
  width: 50%;
`;

export const Card = styled.View`
  margin: 12px;
  min-height: 200px;
  padding: 18px;
  border-radius: 10px;
  background-color: ${white};
  ${cardShadow}
`;

export const SvgContainer = styled.View`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const ArrowIcon = styled.View`
  position: absolute;
  bottom: -14px;
  right: 18px;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: ${red};
  align-items: center;
  justify-content: center;
`;

export const ArrowIconChevron = styled.Image`
  width: 5px;
  height: 10px;
  resize-mode: cover;
`;
