import Config from '../components/Config';
import { getUserCountryCode } from '../reducers/PulseUserReducer/selectors';

import store, { RootState } from '../store';
import { FeatureToggle } from '../types/featureToggle';
import { getObjValueByPath } from '../utils/object';
import { LBU } from './lbu';

export const isProd = (): boolean => Config.BUILD_ENV === 'prod';

export const isUat = (): boolean => Config.BUILD_ENV === 'uat';

export const getApiUrl = (): string => {
  //@ts-ignore
  const countryCode = getUserCountryCode(store.getState());
  const BUILD_ENV = Config.BUILD_ENV || 'uat'; // default for uat
  return getObjValueByPath(LBU, `${countryCode}.API_URL.${BUILD_ENV.toLowerCase()}`, Config.EB_SME_SDK_API_URL);
};

export const getFeatureToggle = (state: RootState): FeatureToggle => {
  const countryCode = getUserCountryCode(state);
  return getObjValueByPath(LBU, `${countryCode}.FEATURE_TOGGLE`, {});
};
