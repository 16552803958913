export const Root = 'EbSmeApp';

export const Landing = `${Root}/Landing`;
export const ECard = `${Root}/ECard`;

export const MyPolicy = `${Root}/MyPolicy`;
export const PolicyDependant = `${MyPolicy}/PolicyDependant`;

export * from './screens/MakeClaimScreens/routes';
export * from './screens/ReviewClaimScreens/routes';

export const Onboarding = `${Root}/Onboarding`;
export const OnboardingEmail = `${Onboarding}/OnboardingEmail`;
export const OnboardingVerification = `${Onboarding}/OnboardingVerification`;
export const OnboardingECard = `${Onboarding}/OnboardingECard`;
export const OnboardingForget = `${Onboarding}/OnboardingForget`;
export const OnboardingForgetConfirm = `${Onboarding}/OnboardingForgetConfirm`;

export const ClaimsSupport = `${Root}/ClaimsSupport`;
export const ClaimsSupportOverview = `${ClaimsSupport}/ClaimsSupportOverview`;
export const ClaimsSupportGuide = `${ClaimsSupport}/ClaimsSupportGuide`;
export const ClaimsSupportFAQ = `${ClaimsSupport}/ClaimsSupportFAQ`;
export const ClaimsSupportContact = `${ClaimsSupport}/ClaimsSupportContact`;

export const Support = `${Root}/Support`;

export const SeeDoctor = `${Root}/SeeDoctor`;
export const FindClinic = `${SeeDoctor}/FindClinic`;
export const ClinicDetails = `${SeeDoctor}/ClinicDetails`;
export const SeeDoctorMenu = `${SeeDoctor}/SeeDoctorMenu`;

export const LetterOfGuarantee = `${Root}/LetterOfGuarantee`;
export const RequestLog = `${LetterOfGuarantee}/RequestLog`;
export const RequestLogSuccess = `${LetterOfGuarantee}/RequestLogSuccess`;
export const RequestLogDraftSuccess = `${LetterOfGuarantee}/RequestLogDraftSuccess`;
export const LogHistory = `${LetterOfGuarantee}/LogHistory`;
export const LogDetails = `${LetterOfGuarantee}/LogDetails`;
export const ViewPdfLog = `${LetterOfGuarantee}/ViewPdfLog`;

export const BankAccount = `${Root}/BankAccount`;
export const BankAccountList = `${BankAccount}/BankAccountList`;
export const BankAccountCreate = `${BankAccount}/BankAccountCreate`;
export const BankAccountView = `${BankAccount}/BankAccountView`;

export const FindHospital = `${Root}/FindHospital`;
export const ListHospital = `${FindHospital}/ListHospital`;
export const DetailHospital = `${FindHospital}/DetailHospital`;
export const ListMapHospital = `${FindHospital}/ListMapHospital`;

export * from './screens/Rewards/routes';
