import React, { ReactElement, useCallback, useMemo, VoidFunctionComponent } from 'react';
import { View, Image, TouchableOpacity, FlatList } from 'react-native';

import { Clinic } from '../../types/clinic';
import ItemFindHospital from './ItemFindHospital';
import FlatListLoadMore from '../../components/FlatlistLoadMore';
import { StackNavigationProp } from '../../types/navigation';
import { Locale } from '../../types/sdk';
import { getTransactionLabel } from '../../utils/claim';

export interface ClinicItemProps {
  clinics: Clinic[];
  clinicType: string;
  onEndReachedCustom?: () => void;
  navigation: StackNavigationProp;
  searchTerm: string;
  currentLocation: { lat: string; lng: string };
  locale: Locale;
}

const FindHospitalList: React.FC<ClinicItemProps> = ({
  searchTerm,
  clinics,
  clinicType,
  onEndReachedCustom,
  navigation,
  currentLocation,
  locale
}) => {
  const searchData = useCallback(
    (searchTerm: String, clinic: Clinic) => {
      return (
        getTransactionLabel(clinic.clinicName, locale).toUpperCase().includes(searchTerm.toUpperCase()) ||
        getTransactionLabel(clinic.clinicAddress, locale).toUpperCase().includes(searchTerm.toUpperCase())
      );
    },
    [locale, clinics, searchTerm]
  );
  const data = useMemo(() => clinics.filter((p) => searchData(searchTerm, p)), [clinics, searchTerm]);

  if (clinicType === 'All') {
    return (
      <FlatListLoadMore
        onEndReachedCustom={searchTerm == '' ? onEndReachedCustom : undefined}
        style={{ marginTop: 10, flex: 1 }}
        data={data}
        extraData={data}
        ItemSeparatorComponent={() => {
          return <View style={{ height: 1, backgroundColor: '#E6EBF0' }} />;
        }}
        keyExtractor={(item: any) => {
          return item.key;
        }}
        renderItem={({ item }: any): ReactElement => (
          <ItemFindHospital item={item} navigation={navigation} currentLocation={currentLocation} locale={locale} />
        )}
      />
    );
  }
  return (
    <FlatList
      style={{ marginTop: 10, flex: 1 }}
      data={data}
      extraData={data}
      ItemSeparatorComponent={() => {
        return <View style={{ height: 1, backgroundColor: '#E6EBF0' }} />;
      }}
      keyExtractor={(item: any) => {
        return item.key;
      }}
      renderItem={({ item }: any): ReactElement => (
        <ItemFindHospital item={item} navigation={navigation} currentLocation={currentLocation} locale={locale} />
      )}
    />
  );
};

export default React.memo(FindHospitalList);
