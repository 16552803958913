import React, { useCallback, useEffect } from 'react';

import { ActionCallback } from '../../types/utils';

import HomeStackNavigator from '../../screens/HomeStackNavigator';
import OnboardingNavigator from '../../screens/OnboardingNavigator';

const SESSION_RENEWAL_DELAY = 90000;
let SESSION_INTERVAL: ReturnType<typeof setInterval>;

interface Props {
  isValidJwt?: boolean;
  isValidUser?: boolean;
  clearUserState: () => void;
  fetchRefreshToken: (callback: ActionCallback) => void;
  back2Pulse?: () => void;
  back2PulseValue?: boolean;
  back2PulseAction: (value: boolean) => void;
}

const AuthenticationView: React.FC<Props> = ({ isValidJwt, isValidUser, clearUserState, fetchRefreshToken, back2Pulse, back2PulseValue, back2PulseAction }) => {
  const isValidSession = isValidJwt && isValidUser;

  const invalidateSession = useCallback(() => {
    clearUserState();
  }, [clearUserState]);

  const renewToken = useCallback(() => {
    fetchRefreshToken((success) => {
      if (!success) {
        invalidateSession();
      }
    });
  }, [fetchRefreshToken, invalidateSession]);

  useEffect(() => {
    if (back2PulseValue && back2PulseValue === true) {
      back2PulseAction(false);
      back2Pulse && back2Pulse();
    }
  }, [back2PulseValue]);

  // Auto-renew the refresh token
  useEffect(() => {
    clearInterval(SESSION_INTERVAL);

    if (isValidSession) {
      renewToken();

      SESSION_INTERVAL = setInterval(() => {
        renewToken();
      }, SESSION_RENEWAL_DELAY);
    } else {
      invalidateSession();
    }
  }, [invalidateSession, isValidSession, renewToken]);

  return isValidSession ? <HomeStackNavigator /> : <OnboardingNavigator />;
};

export default AuthenticationView;
