import styled from 'styled-components/native';

export const Banner = styled.ImageBackground`
  justify-content: flex-end;
  height: 300px;
  width: 100%;
  padding: 24px;
  resize-mode: stretch;
`;
export const BannerTH = styled.ImageBackground`
  justify-content: flex-end;
  height: 178px;
  width: 100%;
  resize-mode: cover;
`;

export const BannerFooter = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const BannerFooterRight = styled.View`
  align-items: flex-end;
`;
