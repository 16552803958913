import gql from 'graphql-tag';

import { Bank } from './../../types/bank';
import { BankAccount } from './../../types/bankAccount';
import apiClient from '../../utils/apiClient';
import { path, pick } from 'ramda';
import { LBU } from '../../config';
import { UserCountryCode } from '../../types/user';
import { getValue } from '../../utils/object';


export const addBankAccountApi = async (partialBankAccount: BankAccount, countryCode: UserCountryCode): Promise<BankAccount> => {
  const bankAccount = pick(getValue(LBU, [countryCode, 'REQUEST_PICKER', 'addBankAccount']), partialBankAccount);
  const res = await apiClient.mutate({
    mutation: gql`
      mutation addBankAccount($bankAccount: BankAccountInput) {
        addBankAccount(bankAccount: $bankAccount) {
          id
          bank {
            id
            code
            name
            fullName
            logo
          }
          accountNumber
          accountHolderName
          branchName
          branchAddress
        }
      }
    `,
    variables: {
      bankAccount
    }
  });
  const bankAccountRes = path<BankAccount>(['data', 'addBankAccount'], res);

  if (!bankAccountRes) {
    throw new Error('No Bank Account found in API response');
  }

  return bankAccountRes;
};

// Api remove bank account
interface DeleteBankAccountResponse {
  data: {
    deleteBankAccount: boolean;
  }
}
export const removeBankAccountApi = async (bankAccountId: String): Promise<DeleteBankAccountResponse> => {
  const res = await apiClient.mutate({
    mutation: gql`
      mutation DeleteBankAccount($bankAccountId : String!) {

        deleteBankAccount(bankAccountId: $bankAccountId)
        
      }
    `,
    variables: {
      bankAccountId
    }
  });
  const removeBankAccountRes = path<DeleteBankAccountResponse>(['data', 'deleteBankAccount'], res);

  if (!removeBankAccountRes) {
    throw new Error('Failed to remove Bank account');
  }

  return removeBankAccountRes;
};

interface BankAccountsResponse {
  data: {
    bankAccounts: BankAccount[];
  };
}

export const fetchBankAccountsApi = (policyNumber: string): Promise<BankAccountsResponse> =>
  apiClient.query({
    query: gql`
      query bankAccounts($policyNumber : String) {
        bankAccounts(policyNumber: $policyNumber) {
            id
              bank {
                id
                code
                name
                fullName
                logo
              }
              accountNumber
              accountHolderName
              default
        }
    }
    `,
    variables: {
      policyNumber
    }
  });

interface BanksResponse {
  data: {
    banks: Bank[];
  };
}

export const fetchBanksApi = (): Promise<BanksResponse> =>
  apiClient.query({
    query: gql`
      {
        banks {
          id
          code
          name
          fullName
          logo
        }
      }
    `
  });
