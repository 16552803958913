import ClaimDetailScreen from './ClaimDetailScreen';
import { RootState } from '../../../store';

import { getClaimDetail } from '../../../reducers/ReviewClaimReducer/selectors';
import { getPolicyId } from '../../../reducers/MyPolicyReducer/selectors';
import withLBUSwitcher from '../../../hocs/withLBUSwitcher';
import ClaimDetailTHScreen from './ClaimDetailTHScreen';
import { getPulseConfig } from '../../../reducers/CorporateUserReducer/selectors';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  claimDetail: getClaimDetail(state),
  policyId: getPolicyId(state),
  pulseConfig: getPulseConfig(state)
});
const LBU_CONFIG = {
  SG: ClaimDetailScreen,
  TH: ClaimDetailTHScreen,
  PH: ClaimDetailScreen,
  MY: ClaimDetailScreen,
  MM: ClaimDetailScreen
};
export default withLBUSwitcher(LBU_CONFIG, mapStateToProps);
