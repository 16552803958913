import { connect } from 'react-redux';

import { fetchLogRequests } from './../../reducers/LetterOfGuaranteeReducer/actions';
import { getLogRequestsLoading, getLogRequestsFetchError } from './../../reducers/LetterOfGuaranteeReducer/selectors';
import LogHistoryScreen from './LogHistoryScreen';
import { getLogRequests } from '../../reducers/LetterOfGuaranteeReducer/selectors';
import { RootState } from '../../store';

export default connect(
  (state: RootState) => ({
    logRequests: getLogRequests(state),
    loading: getLogRequestsLoading(state),
    fetchError: getLogRequestsFetchError(state)
  }),
  {
    fetchLogRequests
  }
)(LogHistoryScreen);
