import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Text, View } from 'react-native';
import { Card, Button, Icon } from 'react-native-elements';
import { UserCountryCode } from '../../../types/user';
import { useLBUTranslation } from '../../../hooks/useLBUTranslation';

export interface Props {
  onClose?: () => void;
  claimNumber: string;
  documentsRequired: string[];
  lbu?: UserCountryCode
}

const ADDRESS_DATA = [
  'PRU Life U.K',
  'Claims Department',
  'Head Office 6F Uptown Place Tower',
  '1, 1 East 11th Drive,',
  'Uptown Bonifacio,',
  '1634 Taguig City',
  'Philippines'
];

export const renderOfficeAddress = (data: string[]): ReactElement[] => {
  return data.map((text: string) => (
    <Text
      key={text}
      style={{
        fontSize: 16,
        marginBottom: 5
      }}
    >
      {text}
    </Text>
  ));
};

const ClaimNextStepView: React.FC<Props> = ({ onClose, claimNumber, documentsRequired = [], lbu}) => {
  const { t } = useTranslation();
  const { tLBU } = useLBUTranslation(lbu);
  return (
    <Card
      containerStyle={{
        margin: 0,
        marginBottom: 20,
        marginTop: 10,
        borderWidth: 0,
        elevation: 0
      }}
    >
      <View testID="ClaimNextStepView">
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 10,
            paddingLeft: 5,
            marginBottom: 10,
            marginTop: 10
          }}
        >
          <Icon
            raised
            name="file-o"
            type="font-awesome"
            size={20}
            color="#D3DADD"
            containerStyle={{
              borderWidth: 1,
              // borderRadius: 50,
              borderColor: '#E6EAEE',
              marginRight: 5
            }}
          />
          <Text
            style={{
              flexShrink: 1,
              fontSize: 16,
              marginBottom: 20,
              marginLeft: 30,
              color: '#68737A'
            }}
          >
            {t('CLAIMS__SUBMITTED__ORIGINAL_DOCS_PROVIDE')}
          </Text>
        </View>
        <View
          style={{
            paddingLeft: 50,
            marginLeft: 30,
            marginBottom: 10,
            marginTop: 10,
            borderLeftColor: '#ccc',
            borderLeftWidth: 1
          }}
        >
          {documentsRequired.map((docTitle) => (
            <Text
              key={docTitle}
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                marginBottom: 10,
                color: '#3F9AB9'
              }}
            >
              {t(docTitle)}
            </Text>
          ))}
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 10,
            paddingLeft: 5,
            marginBottom: 10,
            marginTop: 10
          }}
        >
          <Icon
            raised
            name="envelope-o"
            type="font-awesome"
            size={20}
            color="#D3DADD"
            containerStyle={{
              borderWidth: 1,
              // borderRadius: 50,
              borderColor: '#E6EAEE',
              marginRight: 5
            }}
          />
          <Text
            style={{
              flexShrink: 1,
              fontSize: 16,
              fontWeight: 'bold',
              marginBottom: 20,
              marginLeft: 30,
              color: '#68737A'
            }}
          >
            {tLBU('CLAIMS__SUBMITTED__ORIGINAL_DOCS_MAILTO', {fallbackValue: 'Original documents should be mailed to:'})}
          </Text>
        </View>
        <View
          style={{
            paddingLeft: 50,
            marginLeft: 30,
            marginBottom: 10,
            marginTop: 10,
            borderLeftColor: '#ccc',
            borderLeftWidth: 1
          }}
        >
          {renderOfficeAddress(ADDRESS_DATA)}
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 10,
            marginBottom: 10,
            marginTop: 10
          }}
        >
          <Text
            style={{
              flexShrink: 1,
              fontSize: 18,
              fontWeight: 'bold',
              marginBottom: 5,
              marginLeft: 10,
              color: '#D3DADD'
            }}
          >
            {t('CLAIMS__SUBMITTED__ORIGINAL_DOCS_OR')}
          </Text>
        </View>
        <View
          style={{
            paddingLeft: 50,
            marginLeft: 30,
            marginBottom: 5,
            marginTop: 5,
            borderLeftColor: '#ccc',
            borderLeftWidth: 1
          }}
        >
          <Text
            style={{
              fontSize: 16,
              marginBottom: 5,
              color: '#ffffff'
            }}
          >
            _
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 10,
            paddingLeft: 5,
            marginBottom: 10,
            marginTop: 10
          }}
        >
          <Icon
            raised
            name="user-o"
            type="font-awesome"
            size={20}
            color="#D3DADD"
            containerStyle={{
              borderWidth: 1,
              // borderRadius: 50,
              borderColor: '#E6EAEE',
              marginRight: 5
            }}
          />
          <Text
            style={{
              flexShrink: 1,
              fontSize: 16,
              marginBottom: 20,
              marginLeft: 30,
              color: '#68737A'
            }}
          >
            {t('CLAIMS__SUBMITTED__ORIGINAL_DOCS_HR')}
          </Text>
        </View>
        <View>
          <Text
            style={{
              fontSize: 14,
              marginTop: 20,
              marginBottom: 20,
              textAlign: 'center',
              color: '#3F9AB9'
            }}
          >
            {t('CLAIMS__SUBMITTED__REFERENCE_NO')}
          </Text>
          <View
            style={{
              elevation: 4,
              backgroundColor: '#3F9AB9',
              borderRadius: 5,
              paddingVertical: 10,
              paddingHorizontal: 12
            }}
          >
            <Text
              style={{
                fontSize: 18,
                color: '#fff',
                fontWeight: 'bold',
                alignSelf: 'center',
                textTransform: 'uppercase'
              }}
            >
              {claimNumber}
            </Text>
          </View>
        </View>
        {onClose ? (
          <View
            style={{
              marginTop: 100,
              marginBottom: 50,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Button
              testID="ClaimNextStepViewCloseBtn"
              title={t('BUTTON__CLOSE')}
              containerStyle={{
                margin: 0,
                marginStart: 0,
                marginEnd: 0,
                marginBottom: 10
              }}
              titleStyle={{
                color: '#FFFFFF',
                fontSize: 15
              }}
              buttonStyle={{
                margin: 5,
                backgroundColor: '#ED1B2E',
                borderRadius: 50,
                minWidth: 150
              }}
              onPress={onClose}
            />
          </View>
        ) : null}
      </View>
    </Card>
  );
};

export default ClaimNextStepView;
