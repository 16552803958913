import { Diagnosis } from './../types/diagnosis';

export const diagnosis: Diagnosis[] = [
  {
    id: '1',
    name: 'Breathing problem'
  },
  {
    id: '2',
    name: 'Chest pain'
  }
];
