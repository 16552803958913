import { black, red, white } from '@ebsme/pulse-rn-components/assets/colors'
import { fontWeightFamilies } from '@ebsme/pulse-rn-components/assets/mixins'
import { StyleSheet, TextStyle } from 'react-native'
import styled from 'styled-components/native'

const codeInputFieldStyle: TextStyle = {
  backgroundColor: white,
  borderRadius: 10,
  elevation: 10,
  shadowOpacity: 0.1,
  shadowRadius: 5,
  shadowColor: black,
  shadowOffset: { width: 2, height: 8 },
  fontFamily: fontWeightFamilies.normal,
  fontSize: 18,
  color: black,
  width: 40,
  height: 55,
  textAlign: 'center'
}

export const styles = StyleSheet.create({
  inputViewStyles: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  codeInputFieldStylesDefault: codeInputFieldStyle,
  codeInputFieldStylesError: {
    ...codeInputFieldStyle,
    borderColor: red,
    borderWidth: 1
  } as TextStyle
})

export const ErrorContainer = styled.View`
  flex-direction: row;
  margin-top: 16px;
`

export const ErrorMessageContainer = styled.View`
  flex: 1 1;
  margin-left: 8px;
`
