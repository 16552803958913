import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ViewProps, StyleSheet } from 'react-native';
import { Icon } from 'react-native-elements';
import { PruText, colors } from '@ebsme/pulse-rn-components';
import Pdf from '../../components/Pdf';

import { fetchDocBase64 } from '../../reducers/ReviewClaimReducer/api';
import { getFormattedBase64FileSize } from '../../utils/file';
import { ClaimDocument } from '../../types/claim';
import { readFileFromCacheDir, writeFileToCacheDir } from '../../utils/cacheImageFiles';
import { red } from '../../assets/colors';
import FileViewer from 'react-file-viewer';
import { getDocumentType } from '../../utils/claim';
interface Props extends ViewProps {
  doc: ClaimDocument;
  policyId: string;
  showThumbnail?: boolean;
  onSizeLoaded?: (size: string) => void;
  onPress?: () => void;
}

const DocumentPdfView: React.FC<Props> = ({
  doc: { fileBlobURL: filePath, fileType, fileName },
  policyId,
  onSizeLoaded,
  showThumbnail,
  onPress,
  ...props
}) => {
  const docType = getDocumentType(fileType || '');
  const [fileContent, setFileContent] = useState('');
  const [error, setError] = useState('');
  const [fetchError, setFetchError] = useState('');
  const [uri, seturi] = useState('');
  const styles = StyleSheet.create({
    container: {
      width: 38,
      height: 42
    },
    pdf: {
      flex: 1,
      width: '100%'
    }
  });

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (!filePath) {
        setFileContent('');
        return;
      }

      try {
        let base64Data = await readFileFromCacheDir(filePath);

        if (!base64Data) {
          base64Data = await fetchDocBase64({ policyId, filePath });
          await writeFileToCacheDir({ base64Data, filePath });
        }
        // const blob = await (await fetch(`data:${fileType};base64,${base64Data}`)).blob();
        // const uri = URL.createObjectURL(blob);
        // seturi(uri);
        setFileContent(base64Data);
        if (onSizeLoaded) {
          onSizeLoaded(getFormattedBase64FileSize(base64Data));
        }
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [filePath, onSizeLoaded, policyId]);

  if (error) {
    return (
      <Icon
        name={docType === 'pdf' ? 'file-pdf-o' : 'file-word-o'}
        type="font-awesome"
        size={20}
        containerStyle={styles.container}
        color={colors.grey}
        {...props}
      />
    );
  }

  if (!fileContent) {
    return <ActivityIndicator style={styles.container} size="small" color={red} {...props} />;
  }

  if (showThumbnail) {
    return (
      <Icon
        name={docType === 'pdf' ? 'file-pdf-o' : 'file-word-o'}
        type="font-awesome"
        size={20}
        containerStyle={styles.container}
        color={colors.black}
        onPress={onPress}
        {...props}
      />
    );
  }

  const source = { uri: `data:${fileType};base64,${fileContent}`, cache: false };
  return (
    <>
      {!!fetchError && (
        <PruText testID="FetchError" style={{ margin: 24 }} size={12} weight="semiBold" color="lightRed">
          {fetchError}
        </PruText>
      )}
      {/* <Pdf
        source={source}
        onError={(error): void => {
          setFetchError((error as Error).message);
        }}
        style={styles.pdf}
      /> */}
      {docType === 'doc' ? (
        <iframe name={fileName} src={source.uri} height="100%" width="100%"></iframe>
      ) : (
        <FileViewer fileType={docType} filePath={source.uri} />
      )}
    </>
  );
};

export default DocumentPdfView;
