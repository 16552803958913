import gql from 'graphql-tag';
export * from '../shared/graphql';

export const fetchPolicyQuery = gql`
  {
    getPolicyDetailsForEmployee {
      policyId
      policyNumber
      certificateNo
      referenceNumber
      coverageDate
      dobEmployee
      employees {
        givenName
        middleName
        familyName
        active
        companyEmail
        id
        startDate
        category
      }
      categories {
        allowDependants
        name
        tierId
        plans {
          id
        }
      }
    }
  }
`;

export const getAllEmployeeDetails = gql`
  {
    getAllEmployeeDetails {
      policyId
      policyNumber
      certificateNo
      referenceNumber
      coverageDate
      dobEmployee
      employees {
        givenName
        middleName
        familyName
        active
        companyEmail
        id
        startDate
        category
        idNo
        employeeRelation
        address {
          address1
          address2
          city
          postCode
          region
          country
        }
      }
      categories {
        allowDependants
        name
        tierId
        plans {
          id
        }
      }
    }
  }
`;

export const registerPulseUserMutation = gql`
  mutation registerPulseUser($input: PulseRegistrationPayload!) {
    registerPulseUser(input: $input) {
      authCodeRef
      token
      otpSkip
    }
  }
`;

export const getRefreshTokenForAuthCodeMutation = gql`
  mutation getRefreshTokenForAuthCode($input: AuthCodePayload!) {
    getRefreshTokenForAuthCode(input: $input) {
      token
      softToken
    }
  }
`;
