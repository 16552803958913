import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from 'react-native';

import { red } from '../../assets/colors';
import { isProd, isUat } from '../../config/selector';
import { PruConfirmationModal } from '@ebsme/pulse-rn-components';
import { BeneficiarySelectModal } from '../../goToWithParams';

import { Container } from './LoadingOverlayStyles';

interface Props {
  sdkLoading: boolean;
  children: React.ReactNode;
  sdkError: string;
  setSDKError: (error: string) => void;
  sdkException?: Error | null;
  onException?: (e: Error) => void;
}

const LoadingOverlay: React.FC<Props> = ({
  sdkLoading,
  children,
  sdkError,
  setSDKError,
  onException,
  sdkException
}) => {
  useEffect(() => {
    if (onException && sdkException) {
      onException(sdkException);
    }
  }, [sdkException, onException]);
  const { t } = useTranslation();

  return (
    <>
      {children}
      {sdkLoading && (
        <Container testID="LoadingOverlay">
          <ActivityIndicator size="large" color={red} />
        </Container>
      )}
      {!!sdkError && (
        <PruConfirmationModal
          isVisible={!!sdkError}
          title={t('ERROR__REQUEST_ERROR')}
          message={(isProd() || isUat()) ? '' : (sdkError as string)}
          confirmText={t('BUTTON__OK')}
          onConfirm={(): void => {
            setSDKError('');
          }}
        />
      )}
      <BeneficiarySelectModal />
    </>
  );
};

export default LoadingOverlay;
