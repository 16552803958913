import React from 'react';
import { View } from 'react-native';

import ClinicListItemLoader from './ClinicListItemLoader';

interface Props {
  testID?: string;
}

const ClinicListLoader: React.FC<Props> = ({ testID }) => {
  return (
    <View testID={testID}>
      <ClinicListItemLoader />
      <ClinicListItemLoader />
      <ClinicListItemLoader />
    </View>
  );
};

export default ClinicListLoader;
