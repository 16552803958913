import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Icon } from 'react-native-elements';
import { CheckBox } from 'react-native-elements';
import { assoc } from 'ramda';
import { format, isAfter, isBefore, startOfDay, endOfDay } from 'date-fns';

import { Container, RowContainer, CenterView } from './LetterOfGuaranteeStyles';
import * as routes from '../../routes';
import { StackNavigationProp } from '../../types/navigation';
import Screen from '../../components/Screen';
import { getNavigate } from '../../utils/navigation';
import { red, white } from '../../assets/colors';
import { PruButton, PruInput, PruText } from '@ebsme/pulse-rn-components';
import PickerField from '../../components/PickerField';
import { getObjValue } from '../../utils/object';
import { patients } from '../../__mocks__/patientsMocks';
import { hospitals } from '../../__mocks__/hospitalsMocks';
import { diagnosis } from '../../__mocks__/diagnosisMocks';
import ActionableText from '../../components/ActionableText';
import FieldWrapper from '../../components/FieldWrapper';
import { PruDatePickerModal } from '../../components/PruDatePickerModal'

interface NavigationProp extends StackNavigationProp {
  state: {
    params: {
      success?: boolean;
    };
  };
}

interface FieldProps {
  y: number;
  errorMessage?: string;
}

type Props = {
  navigation: NavigationProp;
  isFocused: boolean;
};

const RequestLogScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();

  const [patientID, setPatientID] = useState('');
  const [patientErrorMessage, setPatientErrorMessage] = useState<string>();
  const [patientY, setPatientY] = useState<number>(0);

  const [hospitalID, setHospitalID] = useState('');
  const [hospitalErrorMessage, setHospitalErrorMessage] = useState<string>();
  const [hospitalY, setHospitalY] = useState<number>(0);

  const [diagnosisID, setDiagnosisID] = useState('');
  const [diagnosisErrorMessage, setDiagnosisErrorMessage] = useState<string>();
  const [diagnosisY, setDiagnosisY] = useState<number>(0);

  const [descriptionOfSymptoms, setDescriptionOfSymptoms] = useState('');
  const [descriptionOfSymptomsErrorMessage, setDescriptionOfSymptomsErrorMessage] = useState<string>();
  const [descriptionOfSymptomsY, setDescriptionOfSymptomsY] = useState<number>(0);

  const [symptomDate, setSymptomDate] = useState(new Date());
  const [showSymptomDatePicker, setShowSymptomDatePicker] = useState(false);
  const [symptomDateErrorMessage, setSymptomDateErrorMessage] = useState<string>();
  const [symptomDateY, setSymptomDateY] = useState<number>(0);

  const [admissionDate, setAdmissionDate] = useState(new Date());
  const [showAdmissionDatePicker, setShowAdmissionDatePicker] = useState(false);
  const [admissionDateErrorMessage, setAdmissionDateErrorMessage] = useState<string>();
  const [admissionDateY, setAdmissionDateY] = useState<number>(0);

  const [questionnaireValues, setQuestionnaireValues] = useState<{ [key: string]: boolean | undefined }>({});
  const [questionnaireErrorMessages, setQuestionnaireErrorMessages] = useState<{ [key: string]: string }>({});
  const [questionnaireYs, setQuestionnaireYs] = useState<{ [key: string]: number }>({});

  const questionKeys = useMemo(
    () => [
      'CONGENITAL',
      'INFERTILITY_PREGNANCY',
      'ACCIDENT_INJURIES',
      'SELF_INJURIES',
      'WORK_INJURIES',
      'ALCOHOL_DRUG',
      'ROUTINE_CHECKUP'
    ],
    []
  );

  const success = navigation.state?.params?.success;
  useEffect(() => {
    if (success) {
      setDescriptionOfSymptoms('');
      setPatientID('');
      setHospitalID('');
      setDiagnosisID('');
      setSymptomDate(new Date());
      setQuestionnaireValues({});
      getNavigate(navigation.navigate, routes.LogHistory)();
    }
  }, [success, navigation]);

  const styles = StyleSheet.create({
    radio: { backgroundColor: undefined, borderWidth: 0, paddingStart: 0, flex: 1 },
    icon: { marginStart: 6, width: 36, height: 36, borderRadius: 18, backgroundColor: white }
  });

  const patientsOptions = patients.map(({ id, name }) => ({ value: id, label: name }));
  const hospitalsOptions = hospitals.map(({ id, name }) => ({ value: id, label: name }));
  const diagnosisOptions = diagnosis.map(({ id, name }) => ({ value: id, label: name }));

  const scrollRef = useRef<ScrollView>();
  const scrollTo = useCallback((y: number) => {
    scrollRef.current?.scrollTo({ x: 0, y });
  }, []);

  const onSubmit = useCallback(() => {
    if (!patientID) {
      setPatientErrorMessage(t('ERROR__REQUIRED_FIELD'));
      scrollTo(patientY);
      return;
    }
    if (!hospitalID) {
      setHospitalErrorMessage(t('ERROR__REQUIRED_FIELD'));
      scrollTo(hospitalY);
      return;
    }

    if (!diagnosisID) {
      setDiagnosisErrorMessage(t('ERROR__REQUIRED_FIELD'));
      scrollTo(diagnosisY);
      return;
    }

    if (!descriptionOfSymptoms) {
      setDescriptionOfSymptomsErrorMessage(t('ERROR__REQUIRED_FIELD'));
      scrollTo(descriptionOfSymptomsY);
      return;
    }

    if (isAfter(symptomDate, endOfDay(new Date()))) {
      setSymptomDateErrorMessage(t('ERROR__FUTURE_DATE'));
      scrollTo(symptomDateY);
      return;
    }

    if (isBefore(admissionDate, startOfDay(new Date()))) {
      setAdmissionDateErrorMessage(t('ERROR__PAST_DATE'));
      scrollTo(admissionDateY);
      return;
    }

    for (const key of questionKeys) {
      if (getObjValue(questionnaireValues, key) == null) {
        setQuestionnaireErrorMessages(assoc(key, t('ERROR__REQUIRED_FIELD'), questionnaireErrorMessages));
        scrollTo(getObjValue(questionnaireYs, key));
        return;
      }
    }
    getNavigate(navigation.navigate, routes.RequestLogSuccess)();
  }, [
    navigation,
    t,
    scrollTo,
    patientID,
    patientY,
    setPatientErrorMessage,
    hospitalID,
    hospitalY,
    setHospitalErrorMessage,
    diagnosisID,
    diagnosisY,
    setDiagnosisErrorMessage,
    descriptionOfSymptoms,
    descriptionOfSymptomsY,
    setDescriptionOfSymptomsErrorMessage,
    symptomDate,
    symptomDateY,
    setSymptomDateErrorMessage,
    admissionDate,
    admissionDateY,
    setAdmissionDateErrorMessage,
    questionKeys,
    questionnaireValues,
    questionnaireErrorMessages,
    questionnaireYs,
    setQuestionnaireErrorMessages
  ]);

  return (
    <Screen
      scrollRef={scrollRef}
      heading={t('LETTER_OF_GUARANTEE__REQUEST_LOG__TITLE')}
      subHeading={t('LETTER_OF_GUARANTEE__REQUEST_LOG__DESCRIPTION')}
      headerPaddingHorizontal={24}
      EndComponent={
        <TouchableOpacity testID="HistoryButton" onPress={getNavigate(navigation.navigate, routes.LogHistory)}>
          <RowContainer>
            <PruText weight="bold" color="black">
              History
            </PruText>
            <Icon name="ios-arrow-forward" type="ionicon" size={24} color="black" containerStyle={styles.icon} />
          </RowContainer>
        </TouchableOpacity>
      }
      onPressBack={getNavigate(navigation.navigate, routes.Landing)}
    >
      <Container>
        <PickerField
          label={t('LETTER_OF_GUARANTEE__REQUEST_LOG__SELECT_PATIENT')}
          placeholder={t('LETTER_OF_GUARANTEE__REQUEST_LOG__SELECT_PATIENT')}
          value={patientID}
          items={patientsOptions}
          onValueChange={(value): void => {
            setPatientID(value as string);
            setPatientErrorMessage(undefined);
          }}
          errorMessage={patientErrorMessage}
          onLayout={(event): void => setPatientY(event.nativeEvent.layout.y)}
        />
        <PickerField
          label={t('LETTER_OF_GUARANTEE__REQUEST_LOG__SELECT_HOSPITAL')}
          placeholder={t('LETTER_OF_GUARANTEE__REQUEST_LOG__SELECT_HOSPITAL')}
          style={{ marginTop: 24 }}
          value={hospitalID}
          items={hospitalsOptions}
          onValueChange={(value): void => {
            setHospitalID(value as string);
            setHospitalErrorMessage(undefined);
          }}
          errorMessage={hospitalErrorMessage}
          onLayout={(event): void => setHospitalY(event.nativeEvent.layout.y)}
        />
        <PickerField
          label={t('LETTER_OF_GUARANTEE__REQUEST_LOG__DIAGNOSIS')}
          placeholder={t('LETTER_OF_GUARANTEE__REQUEST_LOG__DIAGNOSIS')}
          style={{ marginTop: 24 }}
          value={diagnosisID}
          items={diagnosisOptions}
          onValueChange={(value): void => {
            setDiagnosisID(value as string);
            setDiagnosisErrorMessage(undefined);
          }}
          errorMessage={diagnosisErrorMessage}
          onLayout={(event): void => {
            setDiagnosisY(event.nativeEvent.layout.y);
          }}
        />
        <PruInput
          value={descriptionOfSymptoms}
          onChangeText={(value: string): void => {
            setDescriptionOfSymptoms(value);
            setDescriptionOfSymptomsErrorMessage(undefined);
          }}
          label={t('LETTER_OF_GUARANTEE__REQUEST_LOG__SYMPTOMS_DESCRIPTION')}
          placeholder={t('LETTER_OF_GUARANTEE__REQUEST_LOG__SYMPTOMS_DESCRIPTION')}
          containerProps={{
            style: { marginTop: 24 },
            onLayout: (event): void => setDescriptionOfSymptomsY(event.nativeEvent.layout.y)
          }}
          maxLength={500}
          errorMessage={descriptionOfSymptomsErrorMessage}
        />
        <TouchableWithoutFeedback onPress={(): void => setShowSymptomDatePicker(true)}>
          <PruInput
            value={format(symptomDate, 'dd/MM/yyyy')}
            label={t('LETTER_OF_GUARANTEE__REQUEST_LOG__SYMPTOMS_STARTED_DATE')}
            placeholder={t('LETTER_OF_GUARANTEE__REQUEST_LOG__SYMPTOMS_STARTED_DATE')}
            containerProps={{
              style: { marginTop: 24 },
              onLayout: (event): void => setSymptomDateY(event.nativeEvent.layout.y)
            }}
            editable={false}
            pointerEvents="none"
            maxLength={10}
            errorMessage={symptomDateErrorMessage}
          />
        </TouchableWithoutFeedback>

        <TouchableWithoutFeedback onPress={(): void => setShowAdmissionDatePicker(true)}>
          <PruInput
            value={format(admissionDate, 'dd/MM/yyyy')}
            label={t('LETTER_OF_GUARANTEE__REQUEST_LOG__ADMISSION_DATE')}
            placeholder={t('LETTER_OF_GUARANTEE__REQUEST_LOG__ADMISSION_DATE')}
            containerProps={{
              style: { marginTop: 24 },
              onLayout: (event): void => setAdmissionDateY(event.nativeEvent.layout.y)
            }}
            editable={false}
            pointerEvents="none"
            maxLength={10}
            errorMessage={admissionDateErrorMessage}
          />
        </TouchableWithoutFeedback>

        <PruDatePickerModal
          isVisible={showSymptomDatePicker}
          selectedDate={symptomDate}
          onDateChange={(date: Date): void => {
            setSymptomDate(date);
            if (isAfter(date, endOfDay(new Date()))) {
              setSymptomDateErrorMessage(t('ERROR__FUTURE_DATE'));
            } else {
              setSymptomDateErrorMessage(undefined);
            }
          }}
          confirmButtonText={t('BUTTON__DONE')}
          onConfirm={(): void => setShowSymptomDatePicker(false)}
        />
        <PruDatePickerModal
          isVisible={showAdmissionDatePicker}
          selectedDate={admissionDate}
          onDateChange={(date: Date): void => {
            setAdmissionDate(date);
            if (isBefore(date, startOfDay(new Date()))) {
              setAdmissionDateErrorMessage(t('ERROR__PAST_DATE'));
            } else {
              setAdmissionDateErrorMessage(undefined);
            }
          }}
          confirmButtonText={t('BUTTON__DONE')}
          onConfirm={(): void => setShowAdmissionDatePicker(false)}
        />
        {questionKeys.map((key) => (
          <FieldWrapper
            key={key}
            style={{ marginTop: 24 }}
            errorMessage={getObjValue(questionnaireErrorMessages, key)}
            onLayout={(event): void => {
              setQuestionnaireYs(assoc(key, event.nativeEvent.layout.y, questionnaireYs));
            }}
          >
            <PruText size={14} weight="bold" color="black">
              {t(`LETTER_OF_GUARANTEE__REQUEST_LOG__${key}`)}
            </PruText>
            <RowContainer>
              <CheckBox
                title={t('OPTION__YES')}
                checkedColor={red}
                checkedIcon="dot-circle-o"
                uncheckedIcon="circle-o"
                checked={getObjValue(questionnaireValues, key, false)}
                onPress={(): void => {
                  setQuestionnaireValues(assoc(key, true, questionnaireValues));
                  setQuestionnaireErrorMessages(assoc(key, undefined, questionnaireErrorMessages));
                }}
                containerStyle={styles.radio}
              />
              <CheckBox
                title={t('OPTION__NO')}
                checkedColor={red}
                checkedIcon="dot-circle-o"
                uncheckedIcon="circle-o"
                checked={getObjValue(questionnaireValues, key) === false}
                onPress={(): void => {
                  setQuestionnaireValues(assoc(key, false, questionnaireValues));
                  setQuestionnaireErrorMessages(assoc(key, undefined, questionnaireErrorMessages));
                }}
                containerStyle={styles.radio}
              />
            </RowContainer>
          </FieldWrapper>
        ))}

        <PruButton style={{ marginVertical: 24 }} onPress={onSubmit}>
          {t('BUTTON__SUBMIT')}
        </PruButton>
        <CenterView>
          <ActionableText onPress={getNavigate(navigation.navigate, routes.RequestLogDraftSuccess)}>
            Save Draft
          </ActionableText>
        </CenterView>
      </Container>
    </Screen>
  );
};

export default RequestLogScreen;
