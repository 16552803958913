import { connect } from 'react-redux';

import { RootState } from '../../../store';
import {
  getAuthCodeRef,
  getRegisterCorporateUserError,
  getRegisteringCorporateUser
} from '../../../reducers/CorporateUserReducer/selectors';
import { getValidateOTPError, getValidatingOTP } from '../../../reducers/SessionReducer/selectors';
import { getPulseUser } from '../../../reducers/PulseUserReducer/selectors';
import { registerCorpUser, clearRegisterError } from '../../../reducers/CorporateUserReducer/actions';
import { validateOTP, clearValidateError } from '../../../reducers/SessionReducer/actions';
import { setLastPulseUser } from '../../../reducers/PulseUserReducer/actions';

import OnboardingVerificationScreen from './OnboardingVerificationScreen';

const OnboardingVerificationScreenContainer = connect(
  (state: RootState) => ({
    validatingOTP: getValidatingOTP(state),
    validateOTPError: getValidateOTPError(state),
    authCodeRef: getAuthCodeRef(state),
    registeringCorporateUser: getRegisteringCorporateUser(state),
    registerCorporateUserError: getRegisterCorporateUserError(state),
    pulseUser: getPulseUser(state)
  }),
  { validateOTP, registerCorpUser, setLastPulseUser, clearRegisterError, clearValidateError }
)(OnboardingVerificationScreen);

export default OnboardingVerificationScreenContainer;
