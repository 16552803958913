import {
  SET_REFRESH_TOKEN,
  CLEAR_REFRESH_TOKEN,
  VALIDATE_OTP_FAILED,
  VALIDATE_OTP_START,
  VALIDATE_OTP_SUCCESS,
  FETCH_REFRESH_TOKEN_FAILED,
  FETCH_REFRESH_TOKEN_START,
  FETCH_REFRESH_TOKEN_SUCCESS,
  CLEAR_VALIDATE_EEROR,
  BACK_TO_PULSE,
  SessionAction
} from './types';

export type { SessionAction };

interface SessionState {
  refreshToken?: string;
  tokenTimestamp?: Date;
  validatingOTP: boolean;
  validateOTPError?: string;
  fetchingRefreshToken: boolean;
  fetchRefreshTokenError?: string;
  back2Pulse?: boolean;
}

export const initialState: SessionState = {
  validatingOTP: false,
  fetchingRefreshToken: false,
  back2Pulse: false
};

const SessionReducer = (state = initialState, action: SessionAction): SessionState => {
  switch (action.type) {
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
        tokenTimestamp: new Date()
      };

    case CLEAR_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: undefined,
        tokenTimestamp: undefined
      };

    case VALIDATE_OTP_START:
      return {
        ...state,
        validatingOTP: true,
        validateOTPError: undefined
      };

    case VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        validatingOTP: false
      };

    case VALIDATE_OTP_FAILED:
      return {
        ...state,
        validatingOTP: false,
        validateOTPError: action.payload
      };

    case FETCH_REFRESH_TOKEN_START:
      return {
        ...state,
        fetchingRefreshToken: true
      };

    case FETCH_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        fetchingRefreshToken: false
      };

    case FETCH_REFRESH_TOKEN_FAILED:
      return {
        ...state,
        fetchingRefreshToken: false,
        fetchRefreshTokenError: action.payload
      };
    case CLEAR_VALIDATE_EEROR:
      return {
        ...state,
        validateOTPError: undefined
      };
    case BACK_TO_PULSE:
      return {
        ...state,
        back2Pulse: action.payload
      };
    default:
      return state;
  }
};

export default SessionReducer;
