import { ReviewClaimState } from '../../types/claim';

import {
  CLEAR_CLAIMS,
  FETCH_CLAIMS_FAILED,
  FETCH_CLAIMS_START,
  FETCH_CLAIMS_SUCCESS,
  ReviewClaimAction,
  SET_CLAIM_CURRENT_INDEX,
  SET_CLAIM_FILTER
} from './actions';

export type { ReviewClaimAction };

export const initialState: ReviewClaimState = {
  claims: {},
  currentSelectedClaimIndex: '',
  error: '',
  loading: false
};

const ReviewClaimReducer = (state: ReviewClaimState = initialState, action: ReviewClaimAction): ReviewClaimState => {
  switch (action.type) {
    case SET_CLAIM_CURRENT_INDEX:
      return {
        ...state,
        currentSelectedClaimIndex: action.payload
      };

    case FETCH_CLAIMS_START:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case FETCH_CLAIMS_SUCCESS:
      return {
        ...state,
        claims: action.payload.reduce((ac, claim) => ({ ...ac, [claim.claimNumber]: claim }), {}),
        loading: false
      };

    case FETCH_CLAIMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SET_CLAIM_FILTER:
      return {
        ...state,
        claimFilter: action.payload
      };

    case CLEAR_CLAIMS:
      return initialState;

    default:
      return state;
  }
};

export default ReviewClaimReducer;
