import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from '../../routes';

import ClaimantScreen from './ClaimantScreen';
import Step1Screen from './Step1Screen';
import ConsentScreen from './ConsentScreen';
import ClaimTypeScreen from './ClaimTypeScreen';
import ClaimInfoScreen from './ClaimInfoScreen';
import ClaimPaymentScreen from './ClaimPaymentScreen';
import DocumentScreen from './DocumentScreen';
import FinalSummaryScreen from './FinalSummaryScreen';
import ConfirmationScreen from './ConfirmationScreen';
import NextStepScreen from './NextStepScreen';

const routeConfigMap = {
  [routes.Step1Screen]: {
    screen: Step1Screen
  },
  [routes.ClaimTypeScreen]: {
    screen: ClaimTypeScreen
  },
  [routes.ClaimInfoScreen]: {
    screen: ClaimInfoScreen
  },
  [routes.ClaimPaymentScreen]: {
    screen: ClaimPaymentScreen
  },
  [routes.DocumentScreen]: {
    screen: DocumentScreen
  },
  [routes.ConsentScreen]: {
    screen: ConsentScreen
  },
  [routes.FinalSummaryScreen]: {
    screen: FinalSummaryScreen
  },
  [routes.NextStepScreen]: {
    screen: NextStepScreen
  },
  [routes.ConfirmationScreen]: {
    screen: ConfirmationScreen
  }
};

const MakeClaimNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.Step1Screen,
    headerMode: 'none'
  })
);

export default MakeClaimNavigator;
