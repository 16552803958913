import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Claim, ClaimState, FilterOption } from '../../../types/claim';
import ClaimListItem from '../ClaimListItem';
import ClaimListFilter from '../ClaimListFilter';
import { Container } from './ClaimListViewStyles';
import NoClaimsView from '../NoClaimsView';
import { PruButton, PruText } from '@ebsme/pulse-rn-components';
import { StackNavigationProp } from '../../../types/navigation';
import { LBU } from '../../../config';
import { UserCountryCode } from '../../../types/user';
import { getObjValueByPath } from '../../../utils/object';
import { getInitialRoute } from '../../../screens/MakeClaimScreens/routesUtility';
import { filterClaim } from '../../../utils/claim';
import { FlatList, View, ListRenderItemInfo } from 'react-native';

export interface Props {
  navigation: StackNavigationProp;
  lbu?: UserCountryCode;
  filterOptions: FilterOption[];
  claims: Claim[];
  claimFilter?: ClaimState;
  setClaimFilter: (claimFilter?: ClaimState) => void;
  resetClaim: (claimState?: ClaimState) => void;
  onClaimSelected: (claim: Claim) => void;
  onClaimDeleted: (claim: Claim) => void;
}

const ClaimListView: React.FC<Props> = ({
  navigation,
  lbu,
  filterOptions,
  claims,
  claimFilter,
  setClaimFilter,
  resetClaim,
  onClaimSelected,
  onClaimDeleted
}) => {
  const { t } = useTranslation();
  const filteredClaims = filterClaim(claims, claimFilter);
  const { showStartNewClaimBtn } = getObjValueByPath(LBU, `${lbu}.CLAIM_VIEW_CONFIG`, {
    showStartNewClaimBtn: false
  });
  const [MY, CLAIMS] = t('REVIEW_CLAIMS__TITLE').split(' ');
  const SUB_TITLE = t('REVIEW_CLAIMS__SUB_TITLE');

  useEffect(() => {
    if (!claimFilter && filterOptions.length > 0) {
      setClaimFilter('SUBMITTED');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderItem = ({ item }: ListRenderItemInfo<Claim>): React.ReactElement => (
    <ClaimListItem lbu={lbu} claim={item} onClaimSelected={onClaimSelected} onClaimDeleted={onClaimDeleted} />
  );
  const keyExtractor = (_: Claim, i: number): string => i.toString();
  return (
    <Container testID="ClaimListView">
      <FlatList
        showsVerticalScrollIndicator={true}
        style={{ marginBottom: 40 }}
        ListHeaderComponent={
          <View>
            <PruText size={28} color="black" marginTop={20} marginBottom={5} weight="thin" marginLeft={20}>
              <PruText weight="bold">{MY}</PruText> {CLAIMS}
            </PruText>
            {/* <PruText size={16} color="black" marginTop={5} marginBottom={20} weight="normal" marginLeft={20}>
              {SUB_TITLE}
            </PruText> */}
            {claimFilter ? (
              <ClaimListFilter
                selectedFilter={claimFilter}
                filterOptions={filterOptions}
                onFilterSelection={setClaimFilter}
              />
            ) : null}
            {filteredClaims.length === 0 && <NoClaimsView message={t('REVIEW_CLAIMS__NO_CLAIMS')} />}
          </View>
        }
        data={filteredClaims}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListFooterComponent={
          showStartNewClaimBtn && (
            <PruButton
              testID="REVIEW_CLAIMS__START_A_NEW_CLAIM"
              style={{ minWidth: 100 }}
              marginVertical={24}
              onPress={(): void => {
                resetClaim('DRAFT');
                navigation.navigate(getInitialRoute(lbu));
              }}
            >
              {t('REVIEW_CLAIMS__START_A_NEW_CLAIM')}
            </PruButton>
          )
        }
      />
    </Container>
  );
};

export default ClaimListView;
