import { RootState } from '../../../store';
import ClaimPaymentScreen from './ClaimPaymentScreen';
import { updateClaim } from '../../../reducers/MakeClaimReducer/actions';
import { getLoading, getClaim } from '../../../reducers/MakeClaimReducer/selectors';
import withLBUSwitcher from '../../../hocs/withLBUSwitcher';

const LBU_CONFIG = {
  SG: ClaimPaymentScreen,
  TH: ClaimPaymentScreen,
  PH: ClaimPaymentScreen,
  MY: null,
  MM: null
};

export default withLBUSwitcher(
  LBU_CONFIG,
  (state: RootState) => {
    return {
      loading: getLoading(state),
      claim: getClaim(state)
    };
  },
  { updateClaim }
);
