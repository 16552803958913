import React from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';

import * as routes from '../../../routes';
import { Card, LinkView } from './ClaimsSupportGuideStyles';
import { darkGrey, grey } from '../../../assets/colors';
import { StackNavigationProp } from '../../../types/navigation';
import { PruButton, PruText } from '@ebsme/pulse-rn-components';
import Screen from '../../../components/Screen';
import TouchableText from '../../../components/TouchableText/TouchableText';

type Props = {
  navigation: StackNavigationProp;
};

const ClaimSupportLearnScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();
  return (
    <Screen
      heading={t('CLAIMSSUPPORT__GUIDE__TITLE')}
      paddingHorizontal={24}
      onPressBack={(): void => {
        navigation.goBack();
      }}
    >
      <Card>
        <PruText marginTop={5}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua.
        </PruText>
        <View style={{ height: 1, backgroundColor: grey, marginVertical: 20 }} />
        <View
          style={{
            height: 250,
            borderWidth: 1,
            borderColor: darkGrey,
            backgroundColor: grey,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <PruText size={40}>▶</PruText>
        </View>
        <PruButton
          style={{ margin: 20 }}
          onPress={(): void => {
            navigation.goBack();
          }}
        >
          {t('CLAIMSSUPPORT__GUIDE__GOT_IT')}
        </PruButton>
        <LinkView>
          <PruText>{t('CLAIMSSUPPORT__GUIDE__STILL_HAVING_TROUBLE')}</PruText>
          <TouchableText
            onPress={(): void => {
              navigation.navigate(routes.ClaimsSupportFAQ);
            }}
          >
            {t('CLAIMSSUPPORT__GUIDE__CHECK_OUT_FAQ')}
          </TouchableText>
        </LinkView>
      </Card>
      <View style={{ height: 20 }} />
    </Screen>
  );
};

export default ClaimSupportLearnScreen;
