export default {
  setItem: (
    key: string,
    value: string,
    options: any
  ): Promise<null> => {
    localStorage.setItem(key, value)
    return Promise.resolve(null)
  },
  deleteItem: (
    key: string,
    options: any
  ): Promise<null> => {
    localStorage.removeItem(key)
    return Promise.resolve(null)
  },
  getItem: (
    key: string,
    options: any
  ): Promise<string> => {
    return Promise.resolve(localStorage.getItem(key) || '')
  }
}