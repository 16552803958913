import { RootState } from '../../../store';
import ConsentScreen from './ConsentScreen';
import { updateClaim, fetchConsentProcess } from '../../../reducers/MakeClaimReducer/actions';
import { getLoading, getClaim, getConsents } from '../../../reducers/MakeClaimReducer/selectors';
import { getPolicy } from '../../../reducers/MyPolicyReducer/selectors';
import withLBUSwitcher from '../../../hocs/withLBUSwitcher';
import { getSDKLocale } from '../../../reducers/SDKReducer/selectors';

const LBU_CONFIG = {
  SG: ConsentScreen,
  TH: ConsentScreen,
  PH: ConsentScreen,
  MY: null,
  MM: null
};

export default withLBUSwitcher(
  LBU_CONFIG,
  (state: RootState) => {
    return {
      loading: getLoading(state),
      policy: getPolicy(state),
      claim: getClaim(state),
      locale: getSDKLocale(state),
      consents: getConsents(state),
    };
  },
  { updateClaim, fetchConsentProcess }
);
