export * from '../shared/requestPicker';

import { saveClaim as saveClaimTmp, registerPulseUser as registerPulseUserTmp } from '../shared/requestPicker';
export const saveClaim = [...saveClaimTmp, 'uiState'];
export const registerPulseUser =[
    ...registerPulseUserTmp,
    'appId',
    'pulseLoginId',
    'softToken'
]
