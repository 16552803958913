import styled from 'styled-components/native';

import { grey, red, green } from '../../assets/colors';

interface StyledProps {
  steps: number;
  currentStep: number;
}

export const Background = styled.View`
  height: 4px;
  margin: 30px 20px;
  background-color: ${grey};
`;

const getBarWidth = ({ steps, currentStep }: StyledProps): string => `${(currentStep / steps) * 100}%`;
const getColor = ({ steps, currentStep }: StyledProps): string => (steps === currentStep ? green : red);
export const Bar = styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 4px;
  width: ${getBarWidth};
  background-color: ${getColor};
`;
