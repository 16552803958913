import React from 'react';
import { View } from 'react-native';

import BankAccountListItemLoader from './BankAccountListItemLoader';

interface Props {
  testID?: string;
}

const BankAccountListLoader: React.FC<Props> = ({ testID }) => {
  return (
    <View testID={testID}>
      <BankAccountListItemLoader />
      <BankAccountListItemLoader />
    </View>
  );
};

export default BankAccountListLoader;
