import { propOr, pipe, path, split, pathOr } from 'ramda';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ObjValue = any;

interface Obj {
  [key: string]: ObjValue;
}

export const getObjValue = (obj: Obj = {}, key = '', defaultValue?: ObjValue): ObjValue => {
  return propOr(defaultValue, key, obj);
};

export const getObjValueByPath = (obj: Obj = {}, key = '', defaultValue?: ObjValue): ObjValue => {
  const pathString = pipe(split(/[[\].]/), path);
  const value = pathString(key)(obj);
  return value === undefined && defaultValue !== undefined ? defaultValue : value;
};

export const getValue = (obj: Obj = {}, key: string | string[] = '', defaultValue?: ObjValue): ObjValue => {
  return pathOr(defaultValue, Array.isArray(key) ? key : key.split('.'), obj);
};
