import styled from 'styled-components/native';

import { lightGrey } from '../../../assets/colors';

export const ScreenContainer = styled.KeyboardAvoidingView`
  flex-grow: 1;
  flex-basis: 100%;
  height: 100%;
  background-color: ${lightGrey};
`;

export const BackgroundContainer = styled.View`
  width: 100%;
`;

export const LogoContainer = styled.View`
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
`;

export const ContentContainer = styled.ScrollView`
  flex-grow: 1;
`;

export const Content = styled.View`
  padding-horizontal: 40px;
  margin-bottom: 16px;
`;

export const LanguageContainer = styled.View`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  elevation: 6;
  shadow-opacity: 0.1;
  shadow-radius: 5px;
  shadow-color: #000000;
  shadow-offset: 0px 0px;
  z-index: 100;
`;
export const LanguageButton = styled.TouchableOpacity`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99;
`;

export const LocaleDropdownItem = styled.TouchableOpacity`
  padding: 10px 20px;
`;
