import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StackNavigationProp } from '../../types/navigation';
import { PruInput, PruText } from '@ebsme/pulse-rn-components';
import Screen from '../../components/Screen';

import { Dimensions, FlatList, Image, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { openMapDirection, openPhone } from '../../utils/hospitalUtil';
import MapView, { PROVIDER_GOOGLE, Marker } from '../../components/MapView';
import { Clinic } from '../../types/clinic';
import { StyleSheet } from 'react-native';
import MapLoader from '../../components/MapLoader';
import { openWebURL } from '../../utils/platform';
import { Locale } from '../../types/sdk';
import { getTransactionLabel } from '../../utils/claim';
import withLBUSwitcher from '../../hocs/withLBUSwitcher';
import { RootState } from '../../store';
import { getSDKLocale } from '../../reducers/SDKReducer/selectors';
import HospitalIcon1 from '../../components/Hospital/HospitalIcon1';
import { useLBUTranslation } from '../../hooks/useLBUTranslation';
import { EVENT_ANALYTICS, logFirebaseEventScreen } from '../../utils/analytics';
const { height, width } = Dimensions.get('window');

type Props = {
  locale: Locale;
  lbu: string;
  navigation: StackNavigationProp<
    {},
    {
      item: Clinic;
      currentLocation: any;
    }
  >;
};

const HEIGHT_MAP = 200;
const LATITUDE_DELTA = 0.015;
const LONGITUDE_DELTA = LATITUDE_DELTA * (width / height);
const convertFloat = (value: any) => {
  if (typeof value === 'string') {
    return parseFloat(value) || 0;
  } else {
    return value;
  }
};

const DetailHospitalScreen: React.FC<Props> = ({ navigation, locale, lbu }) => {
  const { t, i18n } = useTranslation();
  const { tLBU } = useLBUTranslation(lbu);
  const item = navigation.getParam('item');
  const currentLocation = navigation.getParam('currentLocation');
  const [showMap, setShowMap] = useState(true);

  const { imageUrl, clinicHours, distance, contact, remarks, geoLocation, serviceType, recommended } = item;
  const clinicType = getTransactionLabel(item.clinicType, locale);
  const clinicName = getTransactionLabel(item.clinicName, locale);
  const clinicAddress = getTransactionLabel(item.clinicAddress, locale);
  const description = getTransactionLabel(item.description, locale);

  const phone = getTransactionLabel(contact?.phone, locale);
  const phone1 = getTransactionLabel(contact?.phone1, locale);
  const email = getTransactionLabel(contact?.email, locale);
  const others = getTransactionLabel(contact?.others, locale);
  const onPressBack = (): void => {
    navigation.goBack();
  };
  useEffect(() => {
    logFirebaseEventScreen(EVENT_ANALYTICS.clinic_locator, {
      feature: 'ClinicLocator',
      journey: 'clinic_locator',
      stage: 'clinic_detail',
      screen_id: 'SCR_EB_CL_DHS',
      screen_name: 'DetailHospital',
      clinic_type: clinicType
    });
  }, []);
  // useEffect(() => {
  //   const id = setTimeout(() => setShowMap(true), 800);
  //   return (): void => {
  //     clearTimeout(id);
  //   };
  // }, [showMap]);

  const renderClinicHours = () => {
    if (clinicHours && clinicHours.length > 0) {
      return (
        <FlatList
          data={clinicHours}
          numColumns={2}
          renderItem={({ item, index }) => {
            const displayName = getTransactionLabel(item.displayName, locale);
            const operationHours = getTransactionLabel(item.operationHours, locale);
            return (
              <View style={{ marginLeft: 10, flex: 1, marginTop: 10 }}>
                {displayName != '' && displayName != null && displayName != undefined && (
                  <PruText weight="bold" size={15} numberOfLines={1}>
                    {displayName}
                  </PruText>
                )}
                <PruText size={15} weight="thin">
                  {operationHours}
                </PruText>
              </View>
            );
          }}
        />
      );
    } else {
      return (
        <PruText marginTop={10} size={15} weight="thin" style={{ lineHeight: 24 }}>
          {t('CLINIC__NOT_AVAILABEL')}
        </PruText>
      );
    }
  };
  const renderMap = () => (
    <>
      {showMap ? (
        <MapView
          // provider={PROVIDER_GOOGLE} // remove if not using Google Maps
          style={{ width: '100%', height: HEIGHT_MAP }}
          region={{
            latitude: convertFloat(geoLocation.lat),
            longitude: convertFloat(geoLocation.lng),
            latitudeDelta: LATITUDE_DELTA,
            longitudeDelta: LONGITUDE_DELTA
          }}
        >
          <MapView.Marker
            tracksViewChanges={false}
            coordinate={{ latitude: convertFloat(geoLocation.lat), longitude: convertFloat(geoLocation.lng) }}
            // anchor={{ x: 0.5, y: 0.5 }}
          />
        </MapView>
      ) : (
        <MapLoader height={HEIGHT_MAP} />
      )}
    </>
  );
  const renderTags = () => {
    if (serviceType && serviceType.length > 0) {
      return (
        <>
          {serviceType.map((item) => {
            const tag = getTransactionLabel(item, locale);
            return (
              <View style={[styles.tag, { backgroundColor: '#EBF3FF' }]}>
                <PruText color="white" style={{ color: '#4386E5', fontSize: 11 }}>
                  {tag}
                </PruText>
              </View>
            );
          })}
        </>
      );
    }
  };
  return (
    <Screen headingPosition="header" containerType="View" headerPaddingHorizontal={100} onPressBack={onPressBack}>
      {geoLocation && renderMap()}
      <ScrollView contentContainerStyle={{ backgroundColor: 'white', padding: 25 }}>
        <View
          style={{
            flexDirection: 'row'
          }}
        >
          {imageUrl ? (
            <Image style={styles.image} source={{ uri: imageUrl }} />
          ) : (
            <HospitalIcon1 type={item.clinicType} />
          )}
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <View style={{ flex: 1, marginRight: 10 }}>
                <PruText color="darkBlue" weight="bold" size={13} numberOfLines={1}>
                  {clinicName.toUpperCase()}
                </PruText>
              </View>
              <Text style={{ color: '#4386E5', fontSize: 12, lineHeight: 15 }}>{distance}</Text>
            </View>
            <PruText marginTop={4} weight="thin">
              {`${clinicType} • ${clinicAddress}`}
            </PruText>

            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              {recommended && (
                <TouchableOpacity style={styles.buttonRecommend}>
                  <PruText style={styles.textRecommend}>{t('CLINIC__RECOMMENDED')}</PruText>
                </TouchableOpacity>
              )}
              {renderTags()}
            </View>
          </View>
        </View>
        <View>
          <PruText weight="bold" size={15} numberOfLines={1} marginTop={20}>
            {tLBU('CLINIC__DETAILS', { fallbackValue: 'Detail' })}
          </PruText>
          <PruText marginTop={10} size={15} weight="thin" style={{ lineHeight: 24 }}>
            {lbu === 'SG' ? remarks : description}
          </PruText>

          <PruText weight="bold" size={15} numberOfLines={1} marginTop={20}>
            {tLBU('CLINIC__CLINIC_HOURS', { fallbackValue: 'Clinic Hours' })}
          </PruText>
          {renderClinicHours()}

          <PruText weight="bold" size={15} numberOfLines={1} marginTop={32}>
            {tLBU('CLINIC__LOCATION', { fallbackValue: t('CLINIC__LOCATION') })}
          </PruText>
          <PruText marginTop={10} size={15} weight="thin" style={{ lineHeight: 24 }}>
            {clinicAddress}
          </PruText>
          {geoLocation && (
            <TouchableOpacity
              onPress={() => openMapDirection(currentLocation, geoLocation)}
              style={styles.buttonDirections}
            >
              <PruText style={{ color: '#ED1B2E', fontSize: 13, fontWeight: 'bold' }}>
                {t('CLINIC__DIRECTIONS')}
              </PruText>
            </TouchableOpacity>
          )}
          <PruText weight="bold" size={15} numberOfLines={1} marginTop={32}>
            {t('CLINIC__CONTACT')}
          </PruText>
          <View style={{ marginTop: 10, flexDirection: 'row' }}>
            <PruText weight="bold" size={15} style={{ lineHeight: 24 }}>
              {`${t('CLINIC__TEL')}: `}
            </PruText>
            <View>
              <TouchableOpacity hitSlop={{ top: 3, right: 3, bottom: 3, left: 3 }} onPress={() => openPhone(phone)}>
                <PruText style={{ color: '#007bFF', fontSize: 15, lineHeight: 24 }}>{`${phone}`}</PruText>
              </TouchableOpacity>

              {phone1 != '' && phone1 != null && phone1 != undefined && (
                <TouchableOpacity hitSlop={{ top: 3, right: 3, bottom: 3, left: 3 }} onPress={() => openPhone(phone1)}>
                  <PruText style={{ color: '#007bFF', fontSize: 15, lineHeight: 24 }}>{`${phone1}`}</PruText>
                </TouchableOpacity>
              )}
            </View>
          </View>
          {email != '' && email != null && email != undefined && (
            <PruText weight="bold" size={15} marginTop={5} style={{ lineHeight: 24 }}>
              {`${t('CLINIC__EMAIL')}: `}
              <PruText weight="thin" size={15} style={{ lineHeight: 24 }}>
                {email}
              </PruText>
            </PruText>
          )}
          {others != '' && others != null && others != undefined && (
            <TouchableOpacity onPress={() => openWebURL(others)} style={styles.buttonDirections}>
              <PruText style={{ color: '#ED1B2E', fontSize: 13, fontWeight: 'bold' }}>{t('CLINIC__WEBSITE')}</PruText>
            </TouchableOpacity>
          )}
        </View>
      </ScrollView>
    </Screen>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 48,
    height: 48,
    borderRadius: 48
  },
  buttonRecommend: {
    height: 20,
    borderRadius: 20,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FCF1D9',
    flexDirection: 'row',
    alignSelf: 'baseline',
    marginRight: 8,
    marginTop: 8
  },
  textRecommend: { color: '#F4BD43', fontSize: 11 },
  tag: {
    height: 20,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1B365D',
    borderRadius: 20,
    marginRight: 8,
    marginTop: 8
  },
  buttonDirections: {
    height: 36,
    borderRadius: 20,
    paddingHorizontal: 16,
    maxWidth: 124,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#ED1B2E',
    marginTop: 8
  }
});
export default withLBUSwitcher(
  DetailHospitalScreen,
  (state: RootState) => ({
    locale: getSDKLocale(state)
  }),
  {}
);
