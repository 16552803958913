import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from '../../routes';

import BankAccountListScreen from './BankAccountsScreenContainer';
import BankAccountCreateScreen from './BankAccountCreateScreenContainer';
import BankAccountViewScreen from './BankAccountViewScreenContainer';

const routeConfigMap = {
  [routes.BankAccountList]: BankAccountListScreen,
  [routes.BankAccountCreate]: BankAccountCreateScreen,
  [routes.BankAccountView]: BankAccountViewScreen
};

const BankAccountsNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.BankAccountList,
    headerMode: 'none'
  })
);

export default BankAccountsNavigator;
