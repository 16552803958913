import { Bank } from '../types/bank';

export const bankDisplayName = (bank: Bank): string => {
  return `${bank.fullName} (${bank.name})`;
};

/**
 * Insert space after the first 3 characters and after the next 3 characters
 * e.g. xxx xxxx 1234
 */
export const BANK_ACCOUNT_NUMBER_SPACES = [3, 3];

export const addSpaces = (text: string, spaces: number[]): string => {
  if (!text || !spaces.length) {
    return text;
  }
  let result = text;
  let index = 0;
  for (const count of spaces) {
    result = result.substring(0, index + count) + ' ' + result.slice(index + count);
    index += count + 1;
  }
  return result;
};

interface MaskingOptions {
  totalCount?: number;
  visibleCount?: number;
  maskingCharacter?: string;
  spaces?: number[];
}

export const mask = (text: string, options: MaskingOptions = {}): string => {
  if (!text) {
    return text;
  }
  const { totalCount = 10, visibleCount = 4, maskingCharacter = '*', spaces } = options;
  const maskingChars = Array(totalCount).fill(maskingCharacter).join('');
  const maskedText = (maskingChars + text.slice(-visibleCount)).slice(-totalCount);
  if (spaces) {
    return addSpaces(maskedText, spaces);
  }
  return maskedText;
};

export const formatBankAccountNumber = (text: string, masking = false): string => {
  if (masking) {
    return mask(text, { spaces: BANK_ACCOUNT_NUMBER_SPACES });
  }
  return addSpaces(text, BANK_ACCOUNT_NUMBER_SPACES);
};
