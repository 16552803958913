import { connect } from 'react-redux';

import { ActionCallback } from '../../types/utils';
import { RootState } from '../../store';
import { isValidToken } from '../../utils/jwt';
import { isValidPulseUser } from '../../reducers/PulseUserReducer/selectors';
import { back2PulseValue } from '../../reducers/SessionReducer/selectors';
import { clearRefreshToken, fetchRefreshToken, back2PulseAction } from '../../reducers/SessionReducer/actions';
import { clearLastPulseUser } from '../../reducers/PulseUserReducer/actions';
import { clearPolicy } from '../../reducers/MyPolicyReducer/actions';
import { clearCorporateUser } from '../../reducers/CorporateUserReducer/actions';

import AuthenticationView from './AuthenticationView';
import { clearCacheDir } from '../../utils/cacheImageFiles';
import { resetClaim } from '../../reducers/MakeClaimReducer/actions';
import { clearClinics } from '../../reducers/ClinicsReducer/actions';

const AuthenticationViewContainer = connect(
  (state: RootState) => ({
    isValidJwt: isValidToken(state.Session.refreshToken || ''),
    isValidUser: isValidPulseUser(state),
    back2PulseValue: back2PulseValue(state)
  }),
  (dispatch) => ({
    fetchRefreshToken: (callback: ActionCallback): void => {
      dispatch(
        // @ts-ignore
        fetchRefreshToken(callback)
      );
    },
    clearUserState: (): void => {
      dispatch(clearRefreshToken());
      dispatch(clearLastPulseUser());
      dispatch(clearPolicy());
      dispatch(clearClinics());

      dispatch(clearCorporateUser());
      dispatch(
        // @ts-ignore
        resetClaim()
      );
      clearCacheDir();
    },
    back2PulseAction: (value: boolean): void => {
      dispatch(back2PulseAction(value));
    }
  })
)(AuthenticationView);

export default AuthenticationViewContainer;
