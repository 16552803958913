import { connect } from 'react-redux';

import { RootState } from '../../store';
import FindClinicScreen from './FindClinicScreen';
import { fetchClinics } from './../../reducers/ClinicsReducer/actions';
import { setCurrentLocation } from './../../reducers/PreferencesReducer/actions';
import { getBookmarkedClinics, getCurrentLocation } from '../../reducers/PreferencesReducer/selectors';
import { getClinicNearBy, getFetchError, getLoading } from '../../reducers/ClinicsReducer/selectors';

export default connect(
  (state: RootState) => ({
    bookmarkedClinics: getBookmarkedClinics(state),
    foundClinics: getClinicNearBy(state),
    fetchError: getFetchError(state),
    loading: getLoading(state),
    currentLocation: getCurrentLocation(state)
  }),
  { setCurrentLocation, fetchClinics }
)(FindClinicScreen);
