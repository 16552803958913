import gql from 'graphql-tag';

import { Reward } from './type';
import apiClient from '../../utils/apiClient';

interface RewardsResponse {
  data: {
    getCampaignStartedEvents: Reward[];
  };
}

export const fetchRewards = (userId: string): Promise<RewardsResponse> =>
  apiClient.query({
    query: gql`
      {
        getCampaignStartedEvents(identityId: "${userId}") {
          redemptionUrl
        }
      }
    `
  });
