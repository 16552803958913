import styled from 'styled-components/native';

export const Container = styled.View`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  flex: 1;
`;
