import gql from 'graphql-tag';
export * from '../shared/graphql';

export const fetchPolicyQuery = gql`
  {
    getPolicyDetailsForEmployee {
      policyId
      policyNumber
      certificateNo
      referenceNumber
      coverageDate
      dobEmployee
      employees {
        givenName
        middleName
        familyName
        active
        companyEmail
        id
        startDate
        category
        idNo
        address {
          address1
          address2
          city
          postCode
          region
          country
        }
      }
      categories {
        allowDependants
        name
        tierId
        plans {
          id
        }
      }
    }
  }
`;

export const getAllEmployeeDetails = gql`
  {
    getAllEmployeeDetails {
      policyId
      policyNumber
      certificateNo
      referenceNumber
      coverageDate
      dobEmployee
      employees {
        givenName
        middleName
        familyName
        active
        companyEmail
        id
        startDate
        category
        idNo
        employeeRelation
        address {
          address1
          address2
          city
          postCode
          region
          country
        }
      }
      categories {
        allowDependants
        name
        tierId
        plans {
          id
        }
      }
    }
  }
`;

// export const getClaimsForEmployeePolicyQuery = gql`
//   {
//     getClaimsForEmployeePolicy {
//       claimType
//       originalRequired
//       claimDate
//       claimNumber
//       claimantName
//       policyId
//       employeeId
//       documents {
//         docType
//         fileName
//         fileBlobURL
//         fileType
//       }
//       lbuClaimReference
//       claimState
//       claimSubType
//       employeePolicyNumber
//       coveragePerson
//       incurredDate
//       amount
//       reason
//       bankName
//       accountNumber
//       accountHolderName
//       receiptsCurrency
//       uiState
//       remarks
//     }
//   }
// `;

export const submitClaimMutation = gql`
  mutation SubmitClaim ($claim: ClaimInput) {
    submitClaim (claim: $claim) {
        claimType
        originalRequired
        claimDate
        claimNumber
        claimantName
        policyId
        employeeId
        lbuClaimReference
        claimState
        claimSubType
        employeePolicyNumber
    }
  }
`;

export const saveClaimMutation = gql`
  mutation SaveClaim($claim: ClaimInput) {
    saveClaim(claim: $claim) {
      claimType
      claimSubType
      originalRequired
      claimState
      claimDate
      claimNumber
      policyId
      lbuClaimReference
      documents {
        docType
        fileName
        fileBlobURL
        fileType
      }
      coveragePerson
      incurredDate
      amount
      reason
      bankName
      accountNumber
      accountHolderName
      receiptsCurrency
      uiState
      remarks
    }
  }
`;

export const registerPulseUserMutation = gql`
  mutation registerPulseUser($input: PulseRegistrationPayload!) {
    registerPulseUser(input: $input) {
      authCodeRef
      token
      otpSkip
    }
  }
`;

export const getRefreshTokenForAuthCodeMutation = gql`
  mutation getRefreshTokenForAuthCode($input: AuthCodePayload!) {
    getRefreshTokenForAuthCode(input: $input) {
      token
      softToken
    }
  }
`;
export const getClinicNearBy = gql`
  query GetClinicNearBy($getClinicNearByPayload: GetClinicNearByInput) {
    getClinicNearBy(getClinicNearByPayload: $getClinicNearByPayload) {
      totalPage
      total
      currentPage
      pageSize
      clinics {
        clinicName
        clinicType
        clinicAddress
        geoLocation {
          lat
          lng
        }
        distance
        imageUrl
        tpaTag
        contact {
          phone
          phone1
          email
          others
        }
        doctors
        clinicHours {
          displayName
          operationHours
        }
        description
        remarks
      }
    }
  }
`;
