import React from 'react';
import { ClaimState, FilterOption } from '../../../types/claim';

import { Container, FilterButton, Text } from './ClaimListFilterStyles';
export interface Props {
  selectedFilter: ClaimState | null;
  filterOptions: FilterOption[];
  onFilterSelection: (filter: ClaimState) => void;
}

const ClaimListFilter: React.FC<Props> = ({ selectedFilter, filterOptions, onFilterSelection }) => {
  return (
    <Container testID="ClaimListFilter">
      {filterOptions.map((filter) => (
        <FilterButton
          key={filter.id}
          active={selectedFilter === filter.id}
          onPress={(): void => onFilterSelection(filter.id)}
        >
          <Text active={selectedFilter === filter.id}>{filter.label}</Text>
        </FilterButton>
      ))}
    </Container>
  );
};

export default ClaimListFilter;
