import React, { FunctionComponent, ReactElement } from 'react';
import { FlatList } from 'react-native';

import ClinicListItem from './ClinicListItem';
import { Clinic } from '../../types/clinic';

interface Props {
  data: Clinic[];
  ListHeaderComponent?: FunctionComponent;
}

const ClinicList: React.FC<Props> = ({ ListHeaderComponent, data }) => {
  return (
    <FlatList
      data={data}
      ListHeaderComponent={ListHeaderComponent}
      keyExtractor={(item: Clinic): string => item.code}
      renderItem={({ item }): ReactElement => <ClinicListItem item={item} />}
    />
  );
};

export default ClinicList;
