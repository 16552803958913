import * as React from 'react';
import { Subject } from "rxjs";

export namespace FilePickerService {
  export const onClick = new Subject()
  export const onChange = new Subject<{ data: string, mime: string } | null>()

  export const upload = () => {
    onClick.next()
  }
}

export default () => {
  const inputFileRef = React.useRef<HTMLInputElement | null>( null )

  React.useEffect(() => {
    const subscription = FilePickerService.onClick.subscribe(() => {
      inputFileRef.current?.click();
    });
    return () => {
      subscription.unsubscribe()
    };
  }, [])

  const handleChange = (event: any) => {
    const files: FileList = event.target.files;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const data = (e.target?.result as string)?.replace('data:', '').replace(/^.+,/, '');
        const mime = file.type
        FilePickerService.onChange.next({ data, mime });
        if (inputFileRef?.current?.value) {
          inputFileRef.current.value = null
        }
      }
    } else {
      FilePickerService.onChange.next(null);
    }


  };
  return (
    <input
      type="file"
      ref={inputFileRef}
      onChange={handleChange}
      style={{
        visibility: 'hidden'
      }}
    />
  )
};
