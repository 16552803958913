import React, { useState } from 'react';
import moment from 'moment';
import { TouchableOpacity, Text } from 'react-native';
import { Icon } from 'react-native-elements';

import { chevronRight } from '../../../assets/images/icons';
import { Claim } from '../../../types/claim';
import {
  getClaimStateColor,
  getClaimTypes,
  getDateFormat,
  getKeyForClaimType,
  getKeyForClaimState
} from '../../../utils/claim';
import { ArrowIcon, ArrowIconChevron, Card, RightContent, LeftContent, Row, StatusRow } from './ClaimListItemStyles';
import { PruConfirmationModal, PruText } from '@ebsme/pulse-rn-components';
import { useLBUTranslation } from '../../../hooks/useLBUTranslation';
import { useTranslation } from 'react-i18next';
import IconArr from 'react-native-vector-icons/MaterialIcons';

export interface Props {
  lbu?: string;
  claim: Claim;
  onClaimSelected: (claim: Claim) => void;
  onClaimDeleted: (claim: Claim) => void;
}

const ClaimListItem: React.FC<Props> = ({ lbu, claim, onClaimSelected, onClaimDeleted }) => {
  const { tLBU } = useLBUTranslation(lbu);
  const { t } = useTranslation();
  const { claimType, claimDate, claimState } = claim;
  const { id, label } = getClaimTypes(claimType);
  const statusColor = getClaimStateColor(claimState);
  const deleteIcon = claimState === 'DRAFT';
  let claimStateDetail = claimState || 'DRAFT';

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const dateFormat = getDateFormat(lbu);
  return (
    <Card
      onPress={(): void => onClaimSelected(claim)}
      testID={claimType}
      accessibilityLabel={claimType}
      accessible={false}
    >
      <StatusRow>
        <LeftContent>
          {lbu != 'TH' ? (
            <Text style={{ textTransform: 'capitalize', fontSize: 12, color: statusColor, fontWeight: 'bold' }}>
              {tLBU(claimStateDetail, { fallbackValue: claimStateDetail, keyExtractor: getKeyForClaimState })}
            </Text>
          ) : (
            <Text style={{ fontSize: 12, color: statusColor, fontWeight: 'bold' }}>
              {tLBU(claimStateDetail, { fallbackValue: claimStateDetail, keyExtractor: getKeyForClaimState })}
            </Text>
          )}
        </LeftContent>
        {deleteIcon ? (
          <RightContent>
            <TouchableOpacity testID="deleteTrigger" onPress={(): void => setDeleteModal(true)}>
              <Icon size={18} color="#68737A" name="trash-o" type="font-awesome" />
            </TouchableOpacity>
          </RightContent>
        ) : null}
      </StatusRow>
      <Row>
        <LeftContent>
          <PruText size={14} color="black" weight="bold">
            {claim.lbuClaimReference}
          </PruText>
        </LeftContent>
      </Row>
      <Row>
        <LeftContent>
          <PruText size={14} color="black">
            {t('CLAIMS__CLAIM_DETAILS_REQUEST_DATE')}
          </PruText>
        </LeftContent>
        <RightContent>
          <PruText size={14} color="black">
            {moment(claimDate).format(dateFormat)}
          </PruText>
        </RightContent>
      </Row>
      <ArrowIcon>
        <IconArr name="navigate-next" size={20} color="white" />
      </ArrowIcon>

      <PruConfirmationModal
        isVisible={deleteModal}
        title={t('CLAIMS__DOCUMENTS__DELETE_CONFIRMATION')}
        confirmText={t('CLAIMS__DOCUMENTS__CONFIRM_BUTTON')}
        cancelText={t('BUTTON__CANCEL')}
        onConfirm={(): void => {
          onClaimDeleted(claim);
          setDeleteModal(false);
        }}
        onCancel={(): void => {
          setDeleteModal(false);
        }}
      />
    </Card>
  );
};

export default ClaimListItem;
