import React from 'react';
import { View, TouchableOpacity, ViewStyle } from 'react-native';
import PruText, { TextColor } from '@ebsme/pulse-rn-components/PruText';
import ForwardArrow from '../ForwardArrow';

interface ActionableTextProps {
  onPress?: () => void;
  style?: ViewStyle;
  color?: TextColor;
  children: React.ReactNode;
  showArrow?: boolean;
  testID?: string;
}

const ActionableText: React.FC<ActionableTextProps> = ({
  onPress,
  showArrow = true,
  color = 'black',
  children,
  style,
  testID
}) => {
  return (
    <TouchableOpacity onPress={onPress} disabled={!onPress}>
      <View testID={testID} style={[{ flexDirection: 'row' }, style]}>
        <PruText weight="bold" color={color}>
          {children}
        </PruText>
        {showArrow && <ForwardArrow style={{ marginStart: 6 }} />}
      </View>
    </TouchableOpacity>
  );
};

export default ActionableText;
