import { RootState } from '../../store';
import { Policy, ECareCard } from '../../types/policy';
import { Dependant, ClaimTypeOption, HospitalAndReason } from '../../types/claim';
import { getObjValue } from '../../utils/object';
import { Hospital } from 'app/types/hospital';
import { Diagnosis } from 'app/types/diagnosis';

export const getLoading = (state: RootState): boolean => state.MyPolicy.loading;

export const getPolicy = (state: RootState): Policy | undefined => state.MyPolicy.policy;

export const getPolicyDetail = (state: RootState): any | undefined => state.MyPolicy.policyDetail;

export const getPolicyDependant = (state: RootState): any | undefined => state.MyPolicy.policyDependant;

export const getPolicies = (state: RootState): Policy[] | undefined => state.MyPolicy.policies;

export const getECareCards = (state: RootState): ECareCard[] | undefined => state.MyPolicy.eCareCard;

export const getECareCardLoading = (state: RootState): boolean => state.MyPolicy.eCareCardLoading;

export const getCalledEcareCard = (state: RootState): boolean => state.MyPolicy.calledEcareCard;

export const getPolicyId = (state: RootState): string => getObjValue(getPolicy(state), 'policyId', '');

export const getPolicyNo = (state: RootState): string => getObjValue(getPolicy(state), 'referenceNumber', '');

export const getFetchError = (state: RootState): string | undefined => state.MyPolicy.fetchError;

export const getFetchErrorDependant = (state: RootState): string | undefined => state.MyPolicy.fetchErrorDependant;

export const getDependants = (state: RootState): Dependant[] => state.MyPolicy.dependants;

export const getClaimTypes = (state: RootState): ClaimTypeOption[] | undefined =>
  state.MyPolicy.pulseUserConfig?.CLAIM_TYPES;

export const getHospitals = (state: RootState): Hospital[] | undefined => state.MyPolicy.pulseUserConfig?.HOSPITAL;

export const getDiagnoses = (state: RootState): Diagnosis[] | undefined => state.MyPolicy.pulseUserConfig?.DIAGNOSIS;

export const getHospitalAndReasons = (state: RootState): HospitalAndReason | undefined =>
  state.MyPolicy.hospitalAndReasons;
