import { black, red, white } from '@ebsme/pulse-rn-components/assets/colors';
import { fontWeightFamilies } from '@ebsme/pulse-rn-components/assets/mixins';
import React from 'react';
import OtpInputViewWeb from 'react-otp-input';
import { OtpInputViewProps } from './otp-input-view-props';

const inputStyles = {
  backgroundColor: white,
  borderRadius: 10,
  border: 'none',
  boxShadow: `5px 5px 30px -15px ${black}`,
  fontFamily: fontWeightFamilies.normal,
  fontSize: 18,
  color: black,
  width: 40,
  height: 55,
  textAlign: 'center'
};

export const OTPInputView = ({ value, onChange, isError }: OtpInputViewProps) => {
  return (
    <OtpInputViewWeb
      value={value}
      onChange={onChange}
      numInputs={6}
      isInputNum={true}
      containerStyle={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
      }}
      inputStyle={
        isError
          ? {
              ...inputStyles,
              border: 'solid',
              borderColor: red,
              borderWidth: 1
            }
          : inputStyles
      }
      separator={<span style={{ width: 15 }}></span>}
    />
  );
};
