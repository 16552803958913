import React, { ReactElement } from 'react';
import { ViewProps, View } from 'react-native';
import RNPickerSelect, { Item, PickerStyle } from 'react-native-picker-select';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import Warning from '../../assets/svgs/icons/Warning.svg';
import { PruText } from '@ebsme/pulse-rn-components';

import { Container, ErrorContainer, ErrorMessageContainer } from './PickerFieldStyles';
import { black, grey } from '../../assets/colors';

interface Props extends ViewProps {
  value?: string;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  containerTestID?: string;
  items: Item[];
  onValueChange: (value: unknown) => void;
  onDonePress?: () => void;
  onOpen?: () => void;
}

const PickerField: React.FC<Props> = ({
  value,
  label,
  placeholder,
  errorMessage,
  containerTestID,
  onValueChange,
  onDonePress,
  items,
  onOpen,
  ...props
}) => {
  const pickerSelectStyles = {
    inputIOS: {
      fontSize: 18,
      paddingVertical: 12,
      color: black,
      paddingRight: 30 // to ensure the text is never behind the icon
    },
    inputAndroid: {
      fontSize: 18,
      paddingVertical: 8,
      color: black,
      paddingRight: 30 // to ensure the text is never behind the icon
    }
  } as PickerStyle;
  return (
    <>
      <Container filled={Boolean(value)} error={Boolean(errorMessage)} testID={containerTestID} {...props}>
        {label && (
          <PruText testID="PickerField__Label" size={14} weight="bold" color="black">
            {label}
          </PruText>
        )}
        <RNPickerSelect
          Icon={(): ReactElement => <Icon name="chevron-down" style={{ marginTop: 12 }} size={24} color="black" />}
          style={pickerSelectStyles}
          placeholder={{ label: placeholder, value: null, color: grey }}
          onValueChange={onValueChange}
          onDonePress={onDonePress}
          value={value}
          items={items}
          onOpen={onOpen}
        />
      </Container>
      {errorMessage && (
        <ErrorContainer>
          <View>
            <Warning width="16" height="16" />
          </View>
          <ErrorMessageContainer>
            <PruText testID="PickerField__Error" size={12} weight="semiBold" color="darkRed">
              {errorMessage}
            </PruText>
          </ErrorMessageContainer>
        </ErrorContainer>
      )}
    </>
  );
};

export default PickerField;
