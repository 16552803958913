import DocumentImageView from '../../../components/DocumentImageView';
import React, { useCallback, useState } from 'react';
import { Text, View, TouchableOpacity, Modal, SafeAreaView, useWindowDimensions } from 'react-native';
import { Icon } from 'react-native-elements';

import { ClaimDocument } from '../../../types/claim';
import { isClaimDocumentPdf } from '../../../utils/claim';
import DocumentPdfView from '../../DocumentPdfView';
interface Props {
  document: ClaimDocument;
  onClose?: () => void;
}

const DocumentView: React.FC<Props> = ({ document, onClose }) => {
  const width = useWindowDimensions().width;
  const WIDTH_PDF = Math.min(480 - 20, width - 20);
  const { fileName } = document;
  const [imageSize, setImageSize] = useState('');
  const onLoadedSizeImage = useCallback((size: string): void => setImageSize(size), [setImageSize]);
  return (
    <Modal animationType="fade">
      <SafeAreaView
        testID={fileName}
        style={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          padding: 20,
          width: '100%',
          backgroundColor: '#68737A'
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginHorizontal: 20
          }}
        >
          <View
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column'
            }}
          >
            <Text
              style={{
                fontSize: 15,
                fontWeight: 'bold',
                color: '#FFFFFF',
                marginBottom: 5
              }}
            >
              {fileName}
            </Text>
            <Text
              style={{
                fontSize: 12,
                color: '#FFFFFF'
              }}
            >
              {imageSize}
            </Text>
          </View>
          <TouchableOpacity onPress={onClose}>
            <Icon size={24} color="#FFFFFF" name="ios-close-circle" type="ionicon" />
          </TouchableOpacity>
        </View>
        {isClaimDocumentPdf(document) ? (
          <View
            style={{
              width: '100%',
              height: '100%'
            }}
          >
            <DocumentPdfView testID="ThumbnailDocumentView" onSizeLoaded={onLoadedSizeImage} doc={document} />
          </View>
        ) : (
          <DocumentImageView
            testID="imageBackground"
            resizeMode="contain"
            doc={document}
            onLoadedSizeImage={onLoadedSizeImage}
            style={{
              flex: 1,
              justifyContent: 'center',
              width: '100%',
              margin: 10
            }}
          />
        )}
      </SafeAreaView>
    </Modal>
  );
};

export default DocumentView;
