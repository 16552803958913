import { connect } from 'react-redux';
import { RootState } from '../../store';
import DocumentPdfView from './DocumentPdfView';

import { getPolicyId } from '../../reducers/MyPolicyReducer/selectors';

const DocumentPdfViewContainer = connect((state: RootState) => ({
  policyId: getPolicyId(state)
}))(DocumentPdfView);

export default DocumentPdfViewContainer;
