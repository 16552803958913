import {
  FilterOption,
  ClaimTypeOption,
  ClaimSubTypeOption,
  ClaimDocumentType,
  ClaimDocument,
  ClaimState,
  Claim,
  ClaimDateType,
  PaymentOption
} from './../types/claim';
import { Policy } from './../types/policy';
import { LBU, DOCUMENTS } from './../config';
import { propEq, pluck, values, flatten, filter, path } from 'ramda';
import { getObjValue, getObjValueByPath, ObjValue } from './object';
import { PulseConfig, UserCountryCode } from '../types/user';
import { colors } from '@ebsme/pulse-rn-components';
import store, { RootState } from '../store';
import { getClaimTypes as gct, getDiagnoses as gdn, getHospitals as ghp } from '../reducers/MyPolicyReducer/selectors';
import { Hospital } from 'app/types/hospital';
import { Diagnosis } from 'app/types/diagnosis';
import { Trans } from 'app/types/trans';
import { getPulseConfig } from '../reducers/CorporateUserReducer/selectors';

export const formatAmountValue = (value: string): string => {
  if (!value || value.length <= 0) {
    return '0.00';
  }
  value = value.replace(/,/g, '');
  const [digit = '0', decimal = '00'] = value.split('.');
  // add commas to digits
  const newDigit = digit.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // cut decimals
  return `${newDigit.padStart(1, '0')}.${decimal.padEnd(2, '0').substring(0, 2)}`;
};

export const getDateFormat = (lbu?: string): string => {
  const MY_POLICY = getObjValueByPath(LBU, `${lbu}.MY_POLICY`);
  return getObjValue(MY_POLICY, 'dateFormat', 'DD/MM/YYYY');
};

export const isShowDetailReason = (incidentContent: string | undefined) => {
  return ['Accident', 'Dental', 'Illness', 'Maternity'].includes(incidentContent || '');
};
export const getDocumentList = (docType: string): ClaimDocumentType => getObjValue(DOCUMENTS, docType, {});

export const getDateList = (dateType: string, detailReason: any, claimType: string, lbu: string): ClaimDateType => {
  const LBU_CONFIG = getObjValue(LBU, lbu, {});
  const DATES = getObjValue(LBU_CONFIG, 'DATES', {});
  const date = getObjValue(DATES, dateType, {});
  if (dateType === 'accidentDate') {
    if (lbu === 'TH') {
      if (detailReason && detailReason.id.toLowerCase() === 'accident' && claimType === 'opd') {
        date.isOptional = false;
      } else {
        date.isOptional = true;
      }
    }
    if (lbu === 'SG') {
      if (claimType === 'maternity' || claimType === 'inpatient') {
        date.readOnly = true;
        date.isOptional = true;
      } else {
        date.readOnly = false;
        date.isOptional = false;
      }
    }
  }
  if (lbu === 'SG') {
    if (dateType === 'admissionDate' || dateType === 'dischargeDate') {
      if (claimType === 'maternity' || claimType === 'inpatient') {
        date.isOptional = false;
      } else {
        date.isOptional = true;
      }
    }
  }
  return date;
};

export const getGroupPlanIds = (policy?: Policy): string[] => {
  const groupIds = [];
  if (policy) {
    groupIds.push(policy?.plan?.id || '');
    const optionalPlans = policy?.plan?.optionalPlans || [];
    const employeePolicy = policy?.employees && policy?.employees[0];
    const employeePlanIds = employeePolicy?.tier?.plans.map((p) => p?.planId) || [];
    const optionalPlanIds = optionalPlans.map((p) => p?.id);
    groupIds.push(...optionalPlanIds, ...employeePlanIds);
  }
  return groupIds.filter((v) => v);
};

export const getClaimTypeOptions = (lbuCode: UserCountryCode, policy?: Policy): ClaimTypeOption[] => {
  const rootState: RootState = store.getState() as unknown as RootState;
  const claimtypes = gct(rootState);
  const LBU_CONFIG = getObjValue(LBU, lbuCode, {});
  const CLAIM_TYPES: ClaimTypeOption[] = claimtypes ? claimtypes : getObjValue(LBU_CONFIG, 'CLAIM_TYPES', []);
  const planIds = getGroupPlanIds(policy);
  const CLAIM_TYPES_FILTERED: ClaimTypeOption[] = CLAIM_TYPES.filter(({ groupPlans }) => {
    return planIds.length > 0 && groupPlans ? groupPlans.some((gp) => planIds.includes(gp)) : true;
  });
  return CLAIM_TYPES_FILTERED;
};
export const getTransactionLabel = (label: Trans | string, locale: string): string => {
  if (typeof label === 'string') {
    return label;
  }

  let s: string | undefined = '';
  if (locale === 'en') {
    s = label?.en || label?.th || '';
  } else if (locale === 'th') {
    s = label?.th || label?.en || '';
  }

  return s || label?.en || '';
};
export const getDiagnoses = (lbuCode: UserCountryCode): Diagnosis[] => {
  const rootState: RootState = store.getState() as unknown as RootState;
  const diagnosisSync = gdn(rootState);

  const LBU_CONFIG = getObjValue(LBU, lbuCode, {});
  const DIAGNOSES: Diagnosis[] = diagnosisSync ? diagnosisSync : getObjValue(LBU_CONFIG, 'DIAGNOSES', []);

  return JSON.parse(JSON.stringify(DIAGNOSES));
};
export const getHospitals = (lbuCode: UserCountryCode): Hospital[] => {
  const rootState: RootState = store.getState() as unknown as RootState;
  const hospitalsSync = ghp(rootState);

  const LBU_CONFIG = getObjValue(LBU, lbuCode, {});
  const HOSPITALS: Hospital[] = hospitalsSync ? hospitalsSync : getObjValue(LBU_CONFIG, 'HOSPITALS', []);
  return JSON.parse(JSON.stringify(HOSPITALS));
};
export const getPaymentOptions = (lbuCode: UserCountryCode): PaymentOption[] => {
  const LBU_CONFIG = getObjValue(LBU, lbuCode, {});
  const PAYMENT_OPTIONS: PaymentOption[] = getObjValue(LBU_CONFIG, 'PAYMENT_OPTIONS', []);
  return PAYMENT_OPTIONS;
};

export const getClaimTypeOption = (lbu: UserCountryCode, claimType: string): ClaimTypeOption | undefined => {
  return getClaimTypeOptions(lbu).find((option) => option.id === claimType);
};

export const getClaimSubTypeOption = (
  claimType: ClaimTypeOption | undefined,
  claimSubType: string
): ClaimSubTypeOption | undefined => {
  const CLAIM_SUB_TYPES = getObjValue(claimType, 'subTypes', {});
  const CLAIM_SUB_TYPE_OPTIONS = getObjValue(CLAIM_SUB_TYPES, 'options', []);

  return CLAIM_SUB_TYPE_OPTIONS.find((option: ClaimSubTypeOption) => option.id === claimSubType);
};

export const getClaimDocuments = (
  lbu: UserCountryCode,
  claimType = '',
  claimSubType = '',
  bankAccount: any
): string[] => {
  const CLAIM_TYPE = getClaimTypeOption(lbu, claimType);
  const CLAIM_SUB_TYPE = getClaimSubTypeOption(CLAIM_TYPE, claimSubType);
  const claimDocs = getObjValue(CLAIM_TYPE, 'documents', []);
  const claimSubDocs = getObjValue(CLAIM_SUB_TYPE, 'documents', []);
  if (lbu === 'PH' || lbu === 'MM') {
    return [...claimDocs, ...claimSubDocs];
  }
  // For PACS, bank book account requied once add new bank account
  if (lbu === 'SG' && !bankAccount) {
    return [...claimDocs, ...claimSubDocs];
  }
  if (bankAccount != null && bankAccount.isNew) {
    return [...claimDocs, ...claimSubDocs, 'doc7'];
  } else {
    if (lbu === 'TH') return [...claimDocs, ...claimSubDocs];
    else return [...claimDocs, ...claimSubDocs, 'doc5'];
  }
};

export const getClaimDates = (lbu: UserCountryCode, claimType = 'null'): string[] => {
  const CLAIM_TYPE = getClaimTypeOption(lbu, claimType);
  const claimDates = getObjValue(CLAIM_TYPE, 'dates', []);
  return [...claimDates];
};

export const getOriginalRequired = (lbu: UserCountryCode, claimType: string, claimSubType: string): string[] => {
  const claimDocuments: string[] = getClaimDocuments(lbu, claimType, claimSubType, undefined);
  const originalRequired = claimDocuments.filter((doc) => getObjValue(DOCUMENTS, doc, {})['originalRequired']);
  return originalRequired;
};

export const getOriginalDocuments = (lbu: UserCountryCode, claimType: string, claimSubType: string): string[] => {
  const originalRequired: string[] = getOriginalRequired(lbu, claimType, claimSubType);
  return originalRequired.map((doc) => getObjValue(getDocumentList(doc), 'title', ''));
};

export const getClaimTypes = (claimType: string | undefined): ClaimTypeOption => {
  const CLAIM_TYPES = flatten(pluck('CLAIM_TYPES')(values(LBU)));
  return filter(propEq('id', claimType))(CLAIM_TYPES)[0] || {};
};

export const getClaimSubTypes = (
  claimType: string | undefined,
  claimSubType: string | undefined
): ClaimSubTypeOption => {
  const claimTypeOption = getClaimTypes(claimType);
  const CLAIM_SUB_TYPES: ClaimSubTypeOption[] = path(['subTypes', 'options'], claimTypeOption) || [];
  return filter(propEq('id', claimSubType))(CLAIM_SUB_TYPES)[0] || {};
};

export const getClaimFilterOptions = (lbu: UserCountryCode | undefined): FilterOption[] => {
  const FILTER_OPTIONS = {
    TH: [],
    SG: [],
    PH: [
      // {
      //   id: 'SUBMITTED',
      //   label: 'My Claims'
      // },
      // {
      //   id: 'DRAFT',
      //   label: 'Drafts'
      // }
    ]
  };
  return getObjValue(FILTER_OPTIONS, lbu, []);
};

interface PickerItem {
  value: string;
  label: string;
  key?: string | number;
  color?: string;
  inputLabel?: string;
}

const constructPickerItems = (list: string[]): PickerItem[] => {
  return list.map((e) => ({ value: e, label: e, color: colors.black }));
};

export const BANK_LIST = constructPickerItems([
  'ABN AMRO BANK',
  'BANK OF CHINA',
  'BANK OF TOKYO MITSUBISHI LTD',
  'CIMB',
  'CITIBANK',
  'DBS',
  'HSBC',
  'MAYBANK',
  'OCBC',
  'POSB',
  'SCB',
  'UOB'
]);

export const CURRENCY_LIST = constructPickerItems([
  'SGD',
  'AUD',
  'CNY',
  'EUR',
  'HKD',
  'IDR',
  'JPY',
  'KHR',
  'KRW',
  'LAK',
  'MMK',
  'MYR',
  'NZD',
  'PHP',
  'THB',
  'USD',
  'VND'
]);

export const DIAGNOSIS_LIST = constructPickerItems(
  Array(5)
    .fill(null)
    .map((_, i) => `Diagnosis ${i + 1}`)
);

export const CLAIMANT_LIST: { id: string; label: string }[] = [
  {
    id: 'Lewis Liang',
    label: 'Lewis Liang (self)'
  },
  {
    id: 'Rose Liang',
    label: 'Rose Liang (dependent)'
  }
];

export const CLAIM_CAUSE_LIST: { id: string; label: string }[] = [
  {
    id: 'Illness',
    label: 'Illness'
  },
  {
    id: 'Accident',
    label: 'Accident'
  }
];

export const getSupportInfo = (lbu: UserCountryCode): ObjValue => {
  const rootState: RootState = store.getState() as unknown as RootState;
  const pulsConfig: PulseConfig | undefined = getPulseConfig(rootState);
  return pulsConfig?.SUPPORT_INFO || getObjValueByPath(LBU, `${lbu}.SUPPORT_INFO`);
};

export const getKeyForClaimState = (claimState = ''): string => {
  return `CLAIM_STATE_LABEL.${claimState.toUpperCase().replace(/-/g, '_')}`;
};

export const getKeyForClaimType = (claimTypeId = ''): string => {
  return `CLAIM_TYPE_LABEL.${claimTypeId.toUpperCase().replace(/-/g, '_')}`;
};
export const getKeyForReasonHospitalization = (reasonId = ''): string => {
  return `CLAIM_REASON_HOSPITALIZATION.${reasonId.toUpperCase().replace(/-/g, '_')}`;
};

export const getKeyForOTPType = (type = ''): string => {
  return `OTP_TYPE.${type.toUpperCase().replace(/-/g, '_')}`;
};

export const getKeyForClaimSubType = (claimSubTypeId = ''): string => {
  return `CLAIM_SUB_TYPE_LABEL.${claimSubTypeId.toUpperCase().replace(/-/g, '_')}`;
};

export const getString = (value?: string, formatter?: (value: string) => string, defaultValue = '-'): string => {
  if (!value) {
    // to check empty, null, and undefined
    return defaultValue;
  }
  if (formatter) {
    return formatter(value);
  }
  return value;
};

export const isClaimDocumentPdf = (document: ClaimDocument): boolean => {
  return (
    document.fileType === 'application/pdf' ||
    document.fileType === 'application/msword' ||
    document.fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    !!document.fileContent?.startsWith('J')
  );
};

export const getDocumentType = (fileType: string): string => {
  let docType = 'pdf';
  if (fileType === 'application/msword') {
    docType = 'doc';
  } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    docType = 'docx';
  }
  return docType;
};

export const getClaimStateColor = (state?: ClaimState | string): string => {
  switch (state) {
    case 'APPROVED':
      return '#36B37E';
    case 'SUBMITTED':
      return '#3F9AB9';
    case 'REJECTED':
    case 'DELETED':
      return '#CA1626';
    case 'PENDING':
      return '#FFCC00';
    case 'DRAFT':
      return '#D3DADD';
    case 'APPLICATION_IN_PROGRESS':
    case 'WAITING_FOR_REGISTRATION':
    case 'REGISTRATION_IN_PROGRESS':
    case 'REGISTRATION_COMPLETE':
      return '#FFCC00';
    case 'APPLICATION_WITHDRAW':
    case 'REGISTRATION_WITHDRAW':
      return '#CA1626';
    case 'WAITING_FOR_ACCEPTANCE':
    case 'ACCEPTANCE_IN_PROGRESS':
    case 'WAITING_FOR_ASSESSMENT':
    case 'ASSESSMENT_IN_PROGRESS':
    case 'WAITING_FOR_VERIFICATION':
    case 'VERIFICATION_IN_PROGRESS':
    case 'WAITING_FOR_EVALUATION':
    case 'EVALUATION_IN_PROGRESS':
    case 'WAITING_FOR_APPROVAL':
    case 'APPROVAL_IN_PROGRESS':
      return '#FFCC00';
    case 'ACCEPT_SETTLED':
    case 'EX_GRATIA_SETTLED':
      return '#36B37E';
    case 'ACCEPTANCE_REJECTED':
    case 'EVALUATION_REJECTED':
    case 'ACCEPTANCE_WITHDRAW':
    case 'EVALUATION_WITHDRAW':
    case 'APPROVAL_WITHDRAW':
      return '#CA1626';
    default:
      return '#D3DADD';
  }
};

export const filterClaim = (claims: Claim[], claimFilter?: ClaimState): Claim[] => {
  if (!claimFilter) {
    return claims;
  }
  const { d, r } = claims.reduce(
    (ret, e) => {
      ret[e.claimState === 'DRAFT' ? 'd' : 'r'].push(e);
      return ret;
    },
    { d: [] as Claim[], r: [] as Claim[] }
  );
  return claimFilter === 'DRAFT' ? d : r;
};

export default {};
