import { TextField } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePickerProps } from 'react-native-date-picker';
import * as React from 'react';

export default (props: DatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        openTo="day"
        value={props.date}
        onChange={(newValue) => {
          if (newValue) {
            props.onDateChange(newValue);
          }
        }}
        maxDate={props.maximumDate}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};
