import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from '../../routes';

import FindHospitalContainer from './FindHospitalContainer';
import DetailHospitalScreen from '../DetailHospital/DetailHospitalScreen';
import ListMapHospitalScreen from '../ListMapHospital/ListMapHospitalScreen';

const routeConfigMap = {
  [routes.ListHospital]: {
    screen: FindHospitalContainer
  },
  [routes.DetailHospital]: {
    screen: DetailHospitalScreen
  },
  [routes.ListMapHospital]: {
    screen: ListMapHospitalScreen
  }
};

const FindHospitalNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.ListHospital,
    headerMode: 'none'
  })
);

export default FindHospitalNavigator;
