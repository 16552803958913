import { PruText, PruRadioGroup, PruButton } from '@ebsme/pulse-rn-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import Modal from 'react-native-modal';
import { BehaviorSubject } from 'rxjs';
import store from './store';
import { Dependant } from './types/claim';
import { Policy } from './types/policy';
import { PulseUser } from './types/user';

export type GoToWithParams = (page: string, params: object) => void;

let _goToWithParams: GoToWithParams | null = null;

export const registerGoToWithParams = (goToWithParams: GoToWithParams) => {
  _goToWithParams = goToWithParams
};

export const goToWithParams = (page: string, params: object) => {
  if (_goToWithParams) {
    _goToWithParams(page, params);
  }
};

export const goToTelemedicine = () => {
  console.log(store.getState())
  const state: any = store.getState()
  const pulseUser: PulseUser = state.PulseUser.pulseUser
  const policy: Policy = state.MyPolicy.policy
  const employee = policy.employees && policy.employees[0]
  const dependants: Dependant[] = state.MyPolicy.dependants

  console.log('pulseUser', pulseUser)
  console.log('policy', policy)
  console.log('dependants', dependants)
  if (employee) {
    const employeePayload: TelemedicinePayload = {
      uid: employee.id,
      email: pulseUser.email,
      dob: formatDob(policy.dobEmployee),
      sex: pulseUser.gender,
      nationalId: employee.idNo
    }
    if (dependants.length > 0) {
      openModal([
        {
          key: employeePayload.uid,
          label: `${employee.givenName || ''} ${employee.middleName || ''} ${employee.familyName || ''} `,
          payload: employeePayload
        },
        ...dependants.map((dependant) => ({
          key: dependant.id,
          label: `${dependant.givenName || ''} ${dependant.middleName || ''} ${dependant.familyName || ''} `,
          payload: {
            uid: dependant.id,
            dob: dependant.dateOfBirth && formatDob(dependant.dateOfBirth),
            sex: dependant.gender,
            nationalId: dependant.nationalId
          }
        }))
      ])
    } else {
      navigateTelemedicine(employeePayload)
    }
  }
}

type TelemedicinePayload = {
  uid: string;
  email?: string;
  dob?: string;
  sex?: string;
  nationalId?: string;
}

const formatDob = (dob: string) => {
  const [year, month, day] = dob.split('-')
  return [day, month, year].join('-')
}

const formatGender = (gender?: string) => {
  switch (gender) {
    case 'F':
      return 'FEMALE'
    case 'M':
      return 'MALE'
    default:
      return gender
  }
}

const navigateTelemedicine = (payload: TelemedicinePayload) => {
  const initPayload = {
    origin: 'eBiz',
    // contactDetails: {
    //   value: '676587899',
    //   countryCode: '66'
    // },
    // addressDetails: {
    //   line1: 'Hyderabad, Telangana, India',
    //   line2: 'Hyd'
    // },
    ...payload,
    sex: formatGender(payload.sex),
    backToScreen: 'BusinessSDKLandingScreen'
  }
  console.log('initPayload', initPayload)
  goToWithParams('genericJSXScreen', {
    screenId: 'pruwizard/eBiz-landing-screen',
    origin: 'eBiz',
    initPayload
  })
}
type BeneficiarySelectState = {
  isVisible: boolean;
  items: BeneficiarySelectItem[];
};

const BeneficiarySelectState = new BehaviorSubject<BeneficiarySelectState>({
  isVisible: false,
  items: []
})

const closeModal = () => {
  BeneficiarySelectState.next({
    ...BeneficiarySelectState.value,
    isVisible: false
  })
}

const openModal = (items: BeneficiarySelectItem[]) => {
  BeneficiarySelectState.next({
    items,
    isVisible: true
  })
}

type BeneficiarySelectItem = {
  key: string;
  label: string;
  payload: TelemedicinePayload;
}

export const BeneficiarySelectModal = () => {
  const [state, setState] = React.useState<BeneficiarySelectState | null>(null)
  React.useEffect(() => {
    const sub = BeneficiarySelectState.subscribe((state) => {
      setState(state)
    })
    return (): void => {
      sub.unsubscribe()
    }
  }, []);

  const { t } = useTranslation();

  const [selected, setSelected] = React.useState<BeneficiarySelectItem | null>(null)
  if (!state) {
    return null
  }
  return (
    <Modal isVisible={state.isVisible}>
      <View style={{ justifyContent: 'center', backgroundColor: 'white', padding: 16 }}>
        <PruText weight="bold" marginBottom={8}>{t('CLAIMS__CLAIM_DETAILS_LIFE_ASSURED')}</PruText>
        <PruRadioGroup
          options={state.items.map((item) => ({ label: item.label, value: item.key }))}
          value={selected?.key || null}
          onValueChanged={(value): void => {
            const selected = state.items.find((item) => item.key === (value as string));
            setSelected(selected || null);
          }}
        />

        <View style={{ flexDirection: 'row', justifyContent: 'center', margin: 16 }}>
          <PruButton
            onPress={closeModal}
            type='outline'
            marginHorizontal={4}
          >{t('ALERT__CANCEL')}</PruButton>
          <PruButton
            disabled={!selected}
            onPress={(): void => {
            if (selected) {
              closeModal();
              navigateTelemedicine(selected.payload);
            }
          }}
            marginHorizontal={4}
          >{t('ALERT__CONFIRM')}</PruButton>
        </View>

      </View>
    </Modal>
  )
}
