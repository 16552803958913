import React from 'react';
import SvgAnimatedLinearGradient from 'react-native-svg-animated-linear-gradient';
import { Rect } from 'react-native-svg';
import { Dimensions } from 'react-native';

interface Props {
  height: number;
}

const width = Dimensions.get('screen').width;

const MapLoader: React.FC<Props> = ({ height }) => (
  <SvgAnimatedLinearGradient width={width} height={height} duration={1000}>
    <Rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
  </SvgAnimatedLinearGradient>
);

export default MapLoader;
