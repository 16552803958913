import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../store';
import { getSDKLoading } from '../../reducers/SDKReducer/selectors';
import { setSDKLoading } from '../../reducers/SDKReducer/actions';

interface WrappedComponentProps {
  setSDKLoading: (loading: boolean) => void;
  sdkLoading: boolean;
}

export default function withSDKLoader<P>(Component: React.ComponentType<P>): React.ComponentType<P> {
  const WrappedComponent: React.FC<WrappedComponentProps & P> = (props) => {
    return <Component {...props} />;
  };

  WrappedComponent.displayName = `withAppLoader(${Component.displayName || Component.name})`;
  //@ts-ignore
  return connect(
    (state: RootState) => ({
      sdkLoading: getSDKLoading(state)
    }),
    {
      setSDKLoading
    }
  )(
    // @ts-ignore
    WrappedComponent
  );
}
