import { RootState } from '../../store';
import { getLoading, getPolicy, getECareCards, getECareCardLoading, getCalledEcareCard } from '../../reducers/MyPolicyReducer/selectors';
import { fetchMyPolicy, fetchECareCard, fetchDependants, getPulseUserConfig } from '../../reducers/MyPolicyReducer/actions';
import withSDKLoader from '../../hocs/withSDKLoader/withSDKLoader';
import withLBUSwitcher from '../../hocs/withLBUSwitcher';

import LandingScreen from './LandingScreen';
import { getPulseUser } from '../../reducers/PulseUserReducer/selectors';
import { getSDKLocale } from '../../reducers/SDKReducer/selectors';

const LandingScreenContainer = withLBUSwitcher(
  withSDKLoader(LandingScreen),
  (state: RootState) => ({
    fetchingPolicy: getLoading(state),
    policy: getPolicy(state),
    eCareCards: getECareCards(state),
    pulseUser: getPulseUser(state),
    locale: getSDKLocale(state),
    eCareCardLoading: getECareCardLoading(state),
    calledEcareCard: getCalledEcareCard(state),
  }),
  { fetchMyPolicy, fetchECareCard, fetchDependants, getPulseUserConfig }
);

export default LandingScreenContainer;
