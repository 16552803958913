import { Action, Dispatch } from 'redux';
import { LogRequest } from '../../types/logRequest';

import { fetchLogRequestsApi } from './api';

export const SET_LOG_REQUESTS = 'LetterOfGuarantee/SET_LOG_REQUESTS';
export const FETCH_LOG_REQUESTS_START = 'LetterOfGuarantee/FETCH_LOG_REQUESTS_START';
export const FETCH_LOG_REQUESTS_SUCCESS = 'LetterOfGuarantee/FETCH_LOG_REQUESTS_SUCCESS';
export const FETCH_LOG_REQUESTS_FAILED = 'LetterOfGuarantee/FETCH_LOG_REQUESTS_FAILED';

export interface FetchLogRequestsStartAction extends Action {
  type: typeof FETCH_LOG_REQUESTS_START;
}

export const fetchLogRequestsStart = (): FetchLogRequestsStartAction => ({
  type: FETCH_LOG_REQUESTS_START
});

export interface FetchLogRequestsSuccessAction extends Action {
  type: typeof FETCH_LOG_REQUESTS_SUCCESS;
  payload: LogRequest[];
}

export const fetchLogRequestsSuccess = (logRequests: LogRequest[]): FetchLogRequestsSuccessAction => ({
  type: FETCH_LOG_REQUESTS_SUCCESS,
  payload: logRequests
});

export interface FetchLogRequestsFailedAction extends Action {
  type: typeof FETCH_LOG_REQUESTS_FAILED;
  payload: string;
}

export const fetchLogRequestsFailed = (fetchError: string): FetchLogRequestsFailedAction => ({
  type: FETCH_LOG_REQUESTS_FAILED,
  payload: fetchError
});

export type LetterOfGuaranteeAction =
  | FetchLogRequestsStartAction
  | FetchLogRequestsSuccessAction
  | FetchLogRequestsFailedAction;

export const fetchLogRequests = () => async (dispatch: Dispatch<LetterOfGuaranteeAction>): Promise<void> => {
  dispatch(fetchLogRequestsStart());
  try {
    const response = await fetchLogRequestsApi();

    dispatch(fetchLogRequestsSuccess(response.data.logRequests));
  } catch (error) {
    dispatch(fetchLogRequestsFailed(error.message));
  }
};
