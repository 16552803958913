import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from '../../routes';

import ECardScreenContainer from './ECardScreenContainer';

const routeConfigMap = {
  [routes.ECard]: {
    screen: ECardScreenContainer
  }
};

const ECardNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.ECard,
    headerMode: 'none'
  })
);

export default ECardNavigator;
