import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../store';
import { getI18nReady } from '../../reducers/SDKReducer/selectors';

import { PruText } from '@ebsme/pulse-rn-components';

import { LoadingContainer } from './withI18nStyles';

interface WrappedComponentProps {
  i18nReady: boolean;
}

/**
 * Wraps a component and only renders it if i18n is fully initialised, or a loading component if not.
 * @param Component
 */
export default function withI18n<P>(Component: React.ComponentType<P>): React.ComponentType<P> {
  const WrappedComponent: React.FC<WrappedComponentProps & P> = ({ i18nReady, ...props }) => {
    return i18nReady ? (
      // @ts-ignore
      <Component {...props} />
    ) : (
      <LoadingContainer {...props} testID="withI18n__Loading">
        <PruText color="darkGrey" size={20} weight="thin">
          Loading...
        </PruText>
      </LoadingContainer>
    );
  };

  WrappedComponent.displayName = `withI18n(${Component.displayName || Component.name})`;

  // @ts-ignore
  return connect((state: RootState) => ({
    i18nReady: getI18nReady(state)
  }))(
    // @ts-ignore
    WrappedComponent
  );
}
