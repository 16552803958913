import { FetchResult } from 'apollo-link';
import gql from 'graphql-tag';

import apiClient from '../../utils/apiClient';
import { getValue } from '../../utils/object';
import { UserCountryCode } from '../../types/user';
import { LBU } from '../../config';

type ValidateOTPResponse = FetchResult<{
  getRefreshTokenForAuthCode: { token: string };
}>;

export const validateOTP = (authCodeRef: string, otp: string, countryCode: UserCountryCode): Promise<ValidateOTPResponse> =>
  apiClient.mutate({
    mutation: getValue(LBU, [countryCode, 'GRAPHQL', 'getRefreshTokenForAuthCodeMutation']),
    variables: {
      input: {
        authCodeRef: authCodeRef,
        authCode: otp
      }
    }
  });

type RenewRefreshTokenResponse = FetchResult<{
  renewRefreshToken: { token: string };
}>;

export const renewRefreshToken = (): Promise<RenewRefreshTokenResponse> =>
  apiClient.mutate({
    mutation: gql`
      mutation {
        renewRefreshToken {
          token
        }
      }
    `
  });
