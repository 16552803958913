import React, { useState, ReactElement } from 'react';
import { View, TouchableOpacity, ViewStyle, Text, Dimensions, FlatList, TextInput } from 'react-native';
import PruText, { TextColor } from '@ebsme/pulse-rn-components/PruText';
import { PruErrorMessage } from '@ebsme/pulse-rn-components';
import Icon from 'react-native-vector-icons/Entypo';
import Modal from 'react-native-modal';
import { useTranslation } from 'react-i18next';
import EvilIcon from 'react-native-vector-icons/EvilIcons';

const windowHeight = Dimensions.get('window').height;

interface DropdownWithLableProps {
  testID?: string;
  onPressItem: (item: any) => void;
  onTextSearch?: (text: string) => void;
  items: any[];
  title: string;
  style?: ViewStyle;
  color?: TextColor;
  selectedItem?: any | undefined | null;
  errorMessage?: string | undefined;
  required?: boolean;
  textSearch?: string;
  showSearchBox?: boolean;
  disable?: boolean;
  header?: string;
}

const DropdownWithLable: React.FC<DropdownWithLableProps> = ({
  testID,
  onPressItem,
  onTextSearch,
  items = [],
  title = '',
  style,
  disable = false,
  color = 'black',
  selectedItem,
  required = false,
  errorMessage,
  textSearch,
  showSearchBox = false,
  header = null
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  return (
    <View style={[style]}>
      <PruText testID={`${testID}_Title`} color={color}>
        {title}
        {required && ' *'}
      </PruText>
      <TouchableOpacity
        testID={testID}
        onPress={() => {
          setVisible(!visible);
        }}
        disabled={disable}
        style={{
          borderColor: disable ? 'grey' : '#68737A',
          borderWidth: 0.5,
          padding: 10,
          borderRadius: 5,
          marginTop: 10
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ flex: 1 }}>
            {selectedItem?.label ||
              selectedItem?.fullName ||
              selectedItem?.name ||
              `--${t('CLAIMS__CLAIM_BANK_SELECT_LABEL')}--`}
          </Text>
          <Icon name="chevron-small-down" size={20} />
        </View>
      </TouchableOpacity>
      <PruErrorMessage errorMessage={errorMessage} />
      <Modal
        isVisible={visible}
        backdropTransitionOutTiming={0}
        style={{ margin: 0 }}
        useNativeDriver
        onBackdropPress={() => setVisible(false)}
      >
        <View style={{ height: windowHeight * 0.3, marginHorizontal: 20, backgroundColor: 'white' }}>
          {header && <PruText style={{ padding: 10 }}>{header}</PruText>}
          {showSearchBox && (
            <View
              style={{
                flexDirection: 'row',
                padding: 10,
                borderColor: '#BDBDBD',
                borderWidth: 1,
                borderRadius: 5,
                margin: 5,
                alignItems: 'center'
              }}
            >
              <EvilIcon name="search" size={20} />
              <TextInput
                placeholder="Search"
                style={{ marginLeft: 10, flex: 1 }}
                value={textSearch}
                onChangeText={(text) => onTextSearch && onTextSearch(text)}
              />
            </View>
          )}
          <FlatList
            data={items}
            keyExtractor={(item: any): string => item.id || item.value || item.code}
            persistentScrollbar={true}
            keyboardShouldPersistTaps={'handled'}
            renderItem={({ item }): ReactElement => (
              <TouchableOpacity
                testID={item.id || item.value || item.code}
                key={item.id || item.value || item.code}
                style={{ paddingHorizontal: 10, paddingVertical: 15 }}
                onPress={() => {
                  onPressItem(item);
                  setVisible(!visible);
                }}
              >
                <PruText>{item.label || item.fullName || item.name}</PruText>
              </TouchableOpacity>
            )}
          />
        </View>
      </Modal>
    </View>
  );
};

export default DropdownWithLable;
