import React from 'react';
import { ViewStyle } from 'react-native';
import PruText, { TextColor } from '@ebsme/pulse-rn-components/PruText';
import { LogRequestStatus } from '../../types/logRequest';

interface ActionableTextProps {
  onPress?: () => void;
  style?: ViewStyle;
  status: LogRequestStatus;
  statusText: string;
  testID?: string;
}

const StatusColorMap = new Map<LogRequestStatus, TextColor>([
  [LogRequestStatus.PENDING_REVIEW, 'red'],
  [LogRequestStatus.REJECTED, 'red'],
  [LogRequestStatus.APPROVED, 'green'],
  [LogRequestStatus.DRAFT, 'green']
]);

const StatusSymbolMap = new Map<LogRequestStatus, string>([
  [LogRequestStatus.PENDING_REVIEW, '!'],
  [LogRequestStatus.REJECTED, '⊙'],
  [LogRequestStatus.APPROVED, '✓'],
  [LogRequestStatus.DRAFT, '']
]);

const StatusText: React.FC<ActionableTextProps> = ({ status, statusText, style, testID }) => {
  return (
    <PruText testID={testID} weight="bold" color={StatusColorMap.get(status)} style={style}>
      {StatusSymbolMap.get(status)} {statusText}
    </PruText>
  );
};

export default StatusText;
