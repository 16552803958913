import { RootState } from '../../store';
import withLBUSwitcher from '../../hocs/withLBUSwitcher';
import { getUserCountryCode } from '../../reducers/PulseUserReducer/selectors';
import SupportScreen from './SupportScreen';
import { getObjValueByPath } from '../../utils/object';
import { LBU } from '../../config';
import { getSupportInfo } from '../../utils/claim';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  supportInfo: getSupportInfo(getUserCountryCode(state) || 'TH'),
  countryCode: getUserCountryCode(state)
});

export default withLBUSwitcher(SupportScreen, mapStateToProps);
