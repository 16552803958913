import styled from 'styled-components/native';

import { red, white } from '../../assets/colors';
import { getShadowStyles } from '../../assets/mixins';

interface StyledProps {
  error?: boolean;
}

const shadow = getShadowStyles({
  elevation: 10
});

const getContainerBorderStyle = ({ error }: StyledProps): string => (error ? `border: solid 1px ${red}` : '');

export const Container = styled.View`
  width: 100%;
  border-radius: 10px;
  padding: 8px 12px;
  background-color: ${white};
  ${shadow}
  ${getContainerBorderStyle}
`;

export const ErrorContainer = styled.View`
  flex-direction: row;
  margin-top: 16px;
`;

export const ErrorMessageContainer = styled.View`
  flex: 1 1;
  margin-left: 8px;
`;
