import styled from 'styled-components/native';

export const Container = styled.View`
  margin: 0 20px 24px;
`;

export const SearchContainer = styled.View`
  margin-top: 20px;
`;

export const CenterView = styled.View`
  align-items: center;
  justify-content: center;
  margin-vertical: 10px;
`;
