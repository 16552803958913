import { Platform, InteractionManager } from 'react-native';

const _setTimeout = global.setTimeout;
const _clearTimeout = global.clearTimeout;
const MAX_TIMER_DURATION_MS = 60 * 1000;

if (Platform.OS === 'android') {
  const timerFix: { [key: string]: NodeJS.Timeout } = {};
  const runTask = (id: string, fn: (...args: unknown[]) => void, ttl: number, args: unknown[]): void => {
    const waitingTime = ttl - Date.now();
    if (waitingTime <= 1) {
      InteractionManager.runAfterInteractions(() => {
        if (!timerFix[id]) {
          return;
        }
        delete timerFix[id];
        fn(...args);
      });
      return;
    }
    const afterTime = Math.min(waitingTime, MAX_TIMER_DURATION_MS);
    timerFix[id] = _setTimeout(() => runTask(id, fn, ttl, args), afterTime);
  };

  //@ts-ignore
  global.setTimeout = (fn: (...args: unknown[]) => void, time: number, ...args: unknown[]): NodeJS.Timeout => {
    if (MAX_TIMER_DURATION_MS < time) {
      const ttl = Date.now() + time;
      const id = '_lt_' + Object.keys(timerFix).length;
      runTask(id, fn, ttl, args);
      return (id as unknown) as NodeJS.Timeout;
    }
    return _setTimeout(fn, time, ...args);
  };

  //@ts-ignore
  global.clearTimeout = (id: number | string | NodeJS.Timeout): void => {
    if (typeof id === 'string' && id.startsWith('_lt_')) {
      _clearTimeout(timerFix[id]);
      delete timerFix[id];
      return;
    }
    _clearTimeout(id as NodeJS.Timeout);
  };
}
