import React from 'react';
import { useTranslation } from 'react-i18next';

import * as routes from '../../routes';
import { StackNavigationProp } from '../../types/navigation';

import Header from '../../components/Header';
import { PruText } from '@ebsme/pulse-rn-components';

import { Container, ContentScroll } from './ReviewClaimScreensStyles';
interface Props {
  children: React.ReactNode;
  backVisible?: boolean;
  cancelVisible?: boolean;
  navigation: StackNavigationProp;
  scrollView?: boolean;
}

const ReviewClaimView: React.FC<Props> = ({ children, backVisible, cancelVisible, navigation, scrollView }) => {
  const { t } = useTranslation();
  const [MY, CLAIMS] = t('REVIEW_CLAIMS__TITLE').split(' ');
  const SUB_TITLE = t('REVIEW_CLAIMS__SUB_TITLE');

  const onPressBack = (): void => {
    //@ts-ignore
    if (navigation.dangerouslyGetParent()?.state.index) {
      navigation.goBack();
    } else {
      navigation.navigate(routes.Landing);
    }
  };

  const onPressCancel = (): void => {
    navigation.navigate(routes.Landing);
  };

  return (
    <Container behavior="height">
      <Header
        onPressBack={backVisible ? onPressBack : undefined}
        onPressCancel={cancelVisible ? onPressCancel : undefined}
      />
      {scrollView ? (
        <ContentScroll>
          <PruText size={28} color="black" marginTop={20} marginBottom={5} weight="thin" marginLeft={20}>
            <PruText weight="bold">{MY}</PruText> {CLAIMS}
          </PruText>
          <PruText size={16} color="black" marginTop={5} marginBottom={20} weight="normal" marginLeft={20}>
            {SUB_TITLE}
          </PruText>
          {children}
        </ContentScroll>
      ) : (
        children
      )}
    </Container>
  );
};
export default ReviewClaimView;
