import styled from 'styled-components/native';

import * as colors from '../../assets/colors';

export type BackgroundColor = 'white' | 'lightGrey' | 'transparent';

interface StyledProps {
  backgroundColor: BackgroundColor;
}

const getBackgroundColor = ({ backgroundColor }: StyledProps): string =>
  backgroundColor === 'transparent' ? backgroundColor : colors[backgroundColor];

export const StyledHeader = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 26px;
  min-height: 64px;
  background-color: ${getBackgroundColor};
`;

const sectionJustifyContent = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

interface HeaderSectionProps {
  align: keyof typeof sectionJustifyContent;
}

const getSectionJustifyContentStyle = ({ align }: HeaderSectionProps): string =>
  `justify-content: ${sectionJustifyContent[align]}`;

export const HeaderSection = styled.View<HeaderSectionProps>`
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  flex-basis: auto;
  ${getSectionJustifyContentStyle}
`;

export const HeaderButton = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

export const BackIconContainer = styled.View`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin-right: 8px;
  background-color: ${colors.red};
`;

export const CancelIconContainer = styled.View`
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: ${colors.white};
`;
