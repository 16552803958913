import React from 'react';
import { View, ViewProps } from 'react-native';

import ListTile from '../ListTile';

type Item = {
  id: string;
  label: string;
};

interface Props extends ViewProps {
  items: Item[];
  selectedItem?: string;
  onPress: (item: Item) => void;
}

const SwitchList: React.FC<Props> = ({ items, selectedItem, onPress, ...viewProps }) => {
  return (
    <View
      style={{
        margin: 20,
        marginBottom: 100
      }}
      {...viewProps}
    >
      {items.map((item) => {
        const selected = item.id === selectedItem;

        return <ListTile key={item.id} title={item.label} selected={selected} onPress={(): void => onPress(item)} />;
      })}
    </View>
  );
};

export default SwitchList;
