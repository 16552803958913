import { RootState } from '../../store';
import { PulseUser, UserCountryCode } from '../../types/user';
import { getObjValue } from '../../utils/object';

export const getPulseUser = (state: RootState): PulseUser | undefined => state.PulseUser.pulseUser;

export const getLastPulseUser = (state: RootState): PulseUser | undefined => state.PulseUser.lastPulseUser;

export const getUserCountryCode = (state: RootState): UserCountryCode | undefined => {
  return getObjValue(getPulseUser(state), 'countryCode') ?? 'PH';
};

export const getPulseUserId = (state: RootState): string | undefined => {
  return getObjValue(getPulseUser(state), 'id');
};

export const getLastPulseUserId = (state: RootState): string | undefined => {
  return getObjValue(getLastPulseUser(state), 'id');
};

export const isValidPulseUser = (state: RootState): boolean => {
  const pulseUserId = getPulseUserId(state);
  const lastPulseUserId = getLastPulseUserId(state);

  return !lastPulseUserId || lastPulseUserId === pulseUserId;
};
