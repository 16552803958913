import React, { useState, useEffect } from 'react';
import { Image, ViewProps, View } from 'react-native';

import { tick } from '../../../assets/images/icons';
import { useTranslation } from 'react-i18next';

import { PruText } from '@ebsme/pulse-rn-components';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { StyledCheckedItem, Checkbox } from './MyPolicyCheckedItemStyles';
import DropdownWithLable from '../../../components/DropdownWithLable';
import { Dependant } from '../../../types/claim';

interface Props extends ViewProps {
  label?: string;
  checked?: boolean;
  dependants?: Dependant[];
  selectDependant: (policyNumber: string) => void;
  selected: string;
  mainName?: string;
}

const MyPolicyCheckedItem: React.FC<Props> = ({
  label = '',
  checked,
  dependants,
  selectDependant,
  selected,
  mainName = '',
  ...viewProps
}) => {
  const { t } = useTranslation();

  const [dependant, setDependant] = useState<any>();

  const onSelectDependant = (item: any): void => {
    setDependant(item);
    selectDependant(item.id);
  };

  useEffect(() => {
    if (selected !== '') {
      if (selected === 'select') {
        setDependant({
          id: 'select',
          label: mainName
        });
      } else {
        const dependant = dependants?.filter((item) => item.dependantPolicyNumber === selected)[0];
        setDependant({
          id: dependant?.dependantPolicyNumber,
          label: `${dependant?.givenName ? dependant.givenName : ''} ${
            dependant?.middleName ? dependant.middleName : ''
          } ${dependant?.familyName ? dependant.familyName : ''}`
            .trim()
            .replace(/  +/g, ' ')
        });
      }
    } else {
      setDependant({
        id: 'select',
        label: mainName
      });
    }
  }, []);

  const filterData = () => {
    const data = [
      {
        id: 'select',
        label: mainName
      }
    ];
    dependants &&
      dependants
        .sort(function (a, b) {
          return new Date(a.dateOfBirth!).valueOf() - new Date(b.dateOfBirth!).valueOf();
        })
        .map((item) => {
          data.push({
            id: item.dependantPolicyNumber || '',
            label: `${item.givenName ? item.givenName : ''} ${item.middleName ? item.middleName : ''} ${
              item.familyName ? item.familyName : ''
            }`
              .trim()
              .replace(/  +/g, ' ')
          });
        });
    return data;
  };
  return (
    <View style={{ backgroundColor: '#D3DADD', borderRadius: 5, marginVertical: 7 }}>
      <StyledCheckedItem checked={checked} {...viewProps}>
        <PruText size={14} weight="bold" color={checked ? 'darkBlue' : 'disabled'}>
          {label}
        </PruText>
        <Checkbox checked={checked}>
          {checked ? (
            <Image source={tick} style={{ height: 12, width: 12 }} />
          ) : (
            <Icon style={{ color: 'red' }} name="close" size={17} />
          )}
        </Checkbox>
      </StyledCheckedItem>
      <DropdownWithLable
        disable={!checked}
        selectedItem={dependant}
        items={filterData()}
        onPressItem={onSelectDependant}
        title={''}
        header={`--${t('MYPOLICY__DEPENDANTS_SELECT')}--`}
        style={{
          marginHorizontal: 10,
          marginBottom: 10,
          marginTop: -20
        }}
      />
    </View>
  );
};

export default MyPolicyCheckedItem;
