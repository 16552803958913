import Screen from '../../components/Screen';
import React from 'react';
import { FlatList, ListRenderItemInfo, Modal, SafeAreaView, View } from 'react-native';
import { PruText } from '@ebsme/pulse-rn-components';
import HTML from 'react-native-render-html';
import { getValue } from '../../utils/object';
import { LBU } from '../../config';
import store, { RootState } from '../../store';
import { getPulseConfig } from '../../reducers/CorporateUserReducer/selectors';

interface Props {
  testID?: string;
  onClose?: () => void;
  userCountryCode: string;
}

const TermsAndConditionsData = [
  `You ("you," "your," etc. as used herein includes all persons named on the account and all others properly authorized by such persons to use the account) understand and agree that this Agreement ("Agreement") between you and Prudential Assurance Company Singapore (Pte) Limited ("Prudential", "Pru") states the terms and conditions of your use and access of Business@Pulse within the Pulse app (“Business@Pulse”). You understand that Business@Pulse is designed to be used by yourself only. You represent that by using Business@Pulse, you are qualified and have the capacity to enter into this Agreement.
  \nIn offering Business@Pulse, Prudential is making available to you services through the facilities of the Internet which allow you to access and view your Prudential account information and to gain the benefit of other services and transactional capabilities which Prudential may make available online from time to time. You agree to use Business@Pulse in accordance with this Agreement.
  \nIf you need further assistance, please contact the Pru Customer Line for Pulse at +65 6714 3939.\n`,
  `You acknowledge that (i) all products and services offered through Business@Pulse are made by Prudential, and not by Pulse Ecosystems Pte Ltd (“PulseCo”) which provides the Pulse app; (ii) you have entered into the modules presented by Prudential; and (iii) PulseCo is an introducer and not a distributor of Prudential products. PulseCo cannot give advice or provide any recommendation on any Prudential Products or arrange any contract of insurance in respect of life policies, other than to the extent of carrying out introducing activities. PulseCo does not receive any fee from any sale of Prudential products through the introduction.\n`,
  `You shall be responsible for the confidentiality and use of your Prudential account information in Business@Pulse and any other security data, methods and devices. You agree not to use Business@Pulse except as authorized by Prudential, and in any event, not to make it available to any unauthorized third parties.\n`,
  `Any products and services offered through Business@Pulse are made by Prudential in Singapore to Singapore citizens, Singapore Permanent Residents or foreign nationals residing in Singapore on valid passes issued by Singapore authorities. Nothing herein shall be construed as an offer to sell or solicitation to buy or provision of any product or service outside of Singapore. In the event that the jurisdiction you are in impose restrictions on or do not permit access to the contents of Business@Pulse, you shall forthwith comply with such restrictions or discontinue access (as the case may be) hereto.
  \nBy accessing Business@Pulse and/or using the services offered through Business@Pulse, you agree that the laws of Singapore, including without limitation the provisions of the Evidence Act (Cap. 97) and the Electronic Transactions Act (Cap. 88), shall govern such access and the provision of such services.\n`,
  `You further understand and agree to the following relating to Business@Pulse and any information furnished to you by use of Business@Pulse:
  \nIf you suspect a security breach may have occurred and/or online transactions may have been fraudulently accessed, you shall immediately notify the Pru Customer Line for Pulse at +65 6714 3939 or by e-mail at pulse.ecosystem@prudential.com.sg
  \nYou shall immediately notify us if there are errors or discrepancies in your account values, statements or transaction history.
  \nWhen notifying us of possible security breaches, please include your
  - Name and Pulse app user ID
  - Date and time of unauthorized access
  - Description of security breach or fraud
  - Description of error that has occurred.
  \nIf you fail to immediately notify us when any of the above conditions occurs, neither Prudential, nor any of its employees, affiliates, or subsidiaries, will have responsibility or liability to you arising from such failure to notify in a timely manner. You accept full responsibility for the monitoring of your account.\n`,
  `You agree that the information displayed on Business@Pulse is either the property of Prudential or used by Prudential with permission. You agree not to use Business@Pulse except as authorized by this Agreement.\n`,
  `Prudential is entitled to, but not obliged to, rely on all instructions and transactions carried out under Business@Pulse. Instructions to Prudential given under Business@Pulse are irrevocable and binding upon transmission notwithstanding that they may be unauthorized, given in error, forged or are fraudulent. You agree that Prudential shall not be under a duty to inquire as to the authority or propriety of any instructions given to Prudential by you, and shall be entitled to act upon any such instructions. You understand that telephone calls to Prudential may be recorded and hereby consent to such recording.\n`,
  `You agree that you shall not use or disclose any material and/or information on Business@Pulse, other than to access and use the products and services provided through Business@Pulse. You must not reproduce, sell, license, transmit, disseminate, publish, broadcast, circulate, assign, rent, decompile, reverse engineer, modify, translate, compile, commercially exploit, or in any way whatsoever, allow any third party access to Business@Pulse or the aforesaid material and information provided to you by Prudential or via Business@Pulse, or use the same for any unauthorized or unlawful purpose.\n`,
  `You agree that Prudential may, in whole or in part, at any time whether with or without notice, amend, modify or change the terms of this Agreement, or terminate this Agreement. You agree that Prudential may immediately terminate its services in relation to Business@Pulse if you breach this Agreement or if you have jeopardized the proper and efficient operation of its said services. Any unauthorized use of Business@Pulse by you, whatsoever, shall result in automatic termination of this Agreement.\n`,
  `You agree that Prudential and its employees, affiliates, and subsidiaries shall not be responsible for any damage caused by communications line failure, unauthorized access, theft, systems failure, and any other occurrences beyond their control. You agree to provide all equipment and facilities necessary to access Business@Pulse and will be solely responsible for paying all charges related thereto.\n`,
  `You agree to be liable for any and all charges and expenses incurred in connection with your use of Business@Pulse. Prudential will not be liable for any loss incurred as a result of its termination of this Agreement, or your right to access Business@Pulse at any time, whether with or without cause or notice. Further, Prudential will not be liable for any loss incurred as a result of any limits on access to features or other restrictions on your access to parts or all of Business@Pulse whether with or without notice.\n`,
  `You expressly acknowledge and agree that the use and storage of any information, including without limitation, account information transaction activity, account balances, and any other information available through the use of Business@Pulse is at your sole risk and responsibility. The materials contained herein, including services, products, information, data, text, graphics, audio, video, links or other items are provided "as is" and "as available". PRUDENTIAL DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES EXPRESS OR IMPLIED INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE IN RESPECT OF BUSINESS@PULSE.\n`,
  `You further acknowledge that Prudential does not warrant the accuracy, adequacy or completeness of Business@Pulse and its contents and expressly disclaims liability for any errors or omissions. In addition, Prudential may, from time to time, at its absolute discretion change, amend, delete or replace Business@Pulse and its contents or any part thereof. No warranty is given that access to Business@Pulse will be uninterrupted or free from errors or that any identified defect will be corrected. Further, no warranty is given that Business@Pulse is free from any viruses or other malicious, destructive or corrupting code, agent, program or macros or that viruses or other harmful components will not be transmitted in connection with or arising from the use of Business@Pulse.\n`,
  `IN NO EVENT WILL PRUDENTIAL BE LIABLE FOR ANY LOSS OR DAMAGE WHATSOEVER AND HOWSOEVER OCCASIONED WHETHER OR NOT ARISING FROM OR IN CONNECTION WITH YOUR ACCESS AND/OR USE OF BUSINESS@PULSE INCLUDING ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR SPECIAL LOSS OR DAMAGES (INCLUDING WITHOUT LIMITATION, LOSS OF PROFIT OR INTEREST) RESULTING FROM ANY DEFECT IN OR USE OF BUSINESS@PULSE. This limitation of liability will apply to the fullest extent permitted by applicable law.\n`,
  `Business@Pulse utilizes Internet services to transport data and/or communications and Prudential will take reasonable security precautions in connection therewith, but Prudential disclaims any liability for interception of any such data or communications. Prudential shall not be responsible for, and makes no warranties regarding, the access, speed or availability of Internet or network services. Prudential will not be liable for any loss or damage whatsoever and howsoever occasioned whether or not arising from or in connection with your access and/or use of Business@Pulse including any direct, indirect, consequential or special loss (including without limitation, loss of profit or interest), and including (but not limited to) the following:\n
  (a) Any loss arising from Prudential acting on instructions you have issued, but which, in fact, was an unauthorized use of Business@Pulse;
  (b) Your reliance on the information provided as part, or by means, of Business@Pulse;
  (c) Any failure by you to follow the most updated instructions and procedures for using the services offered on Business@Pulse;
  (d) Any loss associated with system failures, processing errors, software defects, operating mistakes, hardware breakdowns, capacity inadequacies, network vulnerabilities, control weaknesses, security shortcomings, malicious attacks, hacking incidents, viruses, Trojan horses, time bombs, trapdoors or other fraudulent actions and inadequate recovery capabilities which arise despite Prudential's best efforts;
  (e) Any disclosure of any information to third parties arising as a result of your negligence or fault;
  (f) Any cessation or interruption of the availability or operation of Business@Pulse; and
  (g) Any breach of Prudential's obligations or duties to you caused by or arising from any one or more of the events or matters set out in any or more of the foregoing subparagraphs of this clause 15.\n`,
  `Prudential and/or others own the trade and service marks displayed on Business@Pulse. These trade and service marks may only be used or reproduced with prior written consent from Prudential and/or the relevant third party owner(s) and may not be used in connection with any product or service that is not Prudential’s in any manner or that disparages or discredits Prudential. The contents of Business@Pulse are only for your personal, non-commercial internal use. All materials contained in Business@Pulse (such as text, graphics, logos, buttons, icons, images, audio clips, digital downloads, data compilations and software) are protected by copyright, and are owned or controlled by Prudential or the party credited as the provider of such materials. You will abide by any and all additional copyright notices, information, or restrictions contained in any such materials on Business@Pulse.\n`,
  `You recognize that your use of Business@Pulse may involve transmission of information to or from you that may be considered personal financial information. You consent to the transmission by electronic means of such information through Business@Pulse such consent shall be effective at all times that you use Business@Pulse.\n`,
  `By your (i) use and/or continued use of Business@Pulse, or (ii) access and use of the products and services provided through Business@Pulse, you signify your agreement to indemnify and to keep Prudential, its directors, employees, nominees, agents and independent contractors fully and effectively indemnified against all actions, liabilities, costs, claims, losses, damages, proceedings and/or expenses (including all legal costs on an indemnity basis) suffered or incurred by Prudential including, but not limited to, in connection with or arising from:\n
  (a) Your use of Business@Pulse;
  (b) Any unauthorized instructions that might be transmitted through Business@Pulse or any instructions, which are incomplete, inaccurate, or garbled;
  (c) Any breach or non-observance of any of these terms and conditions by you or any other unauthorized person; and/or
  (d) The use of the devices (including use of the devices by third parties) and/or any digital certificate whether authorized or not or arising from your negligence, misconduct or breach of any terms of this Agreement.\n`,
  `If any provision of this Agreement is declared invalid by any tribunal or competent authority, then such provision shall be deemed automatically adjusted to conform to the requirements for validity as declared at such time and as so adjusted shall be deemed a provision of this Agreement as though originally included. If the provision invalidated is of such nature that it cannot be adjusted, the provision shall be deemed deleted upon this Agreement as though the provision had never been included, in either case, the remaining provisions of this Agreement shall remain in full force and effect.\n`,
  `The text of this Agreement herein written in the English language is the authentic text and any difficulties and uncertainties in interpretation arising shall be solved by reference to this text and shall prevail over any translation made hereof.\n`,
  `This Agreement shall be interpreted and governed by the laws of the Republic of Singapore ("Singapore") and you and Prudential agree to submit to the non-exclusive jurisdiction of the Singapore courts with respect to any legal proceedings, which may be initiated in connection with this Agreement.\n`,
  `Business@Pulse may provide links to third-party websites. You agree that your use of any third-party websites is at your sole risk. Prudential is not responsible for examining or evaluating the content or accuracy of any third-party websites and have no liability for any such third-party websites.\n`
];
const TermsAndConditions: React.FC<Props> = ({ testID, onClose, userCountryCode }) => {
  const terms = getPulseConfig(store.getState() as unknown as RootState)?.TERMS_OF_USE;
  const html = terms ? terms : getValue(LBU, [userCountryCode, 'TERMS_OF_USE']);
  const renderTermsAndConditions = ({ item, index }: ListRenderItemInfo<string>): React.ReactElement => (
    <PruText>
      {index + 1}. {item}
    </PruText>
  );
  const keyExtractor = (e: string, i: number): string => i.toString();
  if (userCountryCode === 'SG') {
    return (
      <Modal animationType="fade">
        <SafeAreaView style={{ flex: 1 }} testID={testID}>
          <Screen headerPaddingHorizontal={24} headingPosition={'none'} onPressCancel={onClose}>
            <PruText color="black" size={18} weight="bold" marginLeft={20}>
              Terms of Use of Business@Pulse
            </PruText>
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
              <PruText color="black" size={28} weight="bold" marginLeft={20}>
                AGREEMENT
              </PruText>
            </View>
            <PruText marginLeft={20}>
              Please read the following terms and conditions carefully. IF YOU DO NOT AGREE TO THESE TERMS & CONDITIONS,
              PLEASE DO NOT USE OR ACCESS BUSINESS@PULSE. By accessing Business@Pulse, you agree to be bound by these
              terms and conditions:
            </PruText>
            <FlatList
              style={{ margin: 20, marginBottom: 50 }}
              data={TermsAndConditionsData}
              renderItem={renderTermsAndConditions}
              keyExtractor={keyExtractor}
            />
          </Screen>
        </SafeAreaView>
      </Modal>
    );
  } else {
    return (
      <Modal animationType="fade">
        <SafeAreaView style={{ flex: 1 }} testID={testID}>
          <Screen headerPaddingHorizontal={24} headingPosition={'none'} onPressCancel={onClose}>
            <View style={{ marginLeft: 20, marginRight: 15 }}>
              <HTML html={html} baseFontStyle={{ color: 'black' }} />
            </View>
          </Screen>
        </SafeAreaView>
      </Modal>
    );
  }
};

export default TermsAndConditions;
