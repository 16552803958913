import app_ebsme_pulse from './app_ebsme_pulse.json';
import global_plt from './global_plt.json';
import global_pmli from './global_pmli.json';
import module_product_catalogue_thailand from './module_product_catalogue_thailand.json';

const thResource = {
  app_ebsme_pulse,
  global_plt,
  global_pmli,
  module_product_catalogue_thailand
};

export default thResource;
