import React, { MutableRefObject } from 'react';
import { KeyboardAvoidingView, KeyboardAvoidingViewProps, ScrollView, ScrollViewProps, View } from 'react-native';
import { KeyboardAwareScrollView, KeyboardAwareScrollViewProps } from 'react-native-keyboard-aware-scroll-view';

import * as colors from '../../assets/colors';

const containerComponents = {
  View,
  ScrollView,
  KeyboardAvoidingView,
  KeyboardAwareScrollView
};

export type LayoutBoxType = keyof typeof containerComponents;

export interface LayoutBoxProps extends ScrollViewProps, KeyboardAvoidingViewProps, KeyboardAwareScrollViewProps {
  type?: keyof typeof containerComponents;
  marginVertical?: number;
  marginHorizontal?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  paddingVertical?: number;
  paddingHorizontal?: number;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  flex?: number;
  backgroundColor?: keyof typeof colors;
  scrollRef?: MutableRefObject<ScrollView | undefined>;
}

const LayoutBox: React.FC<LayoutBoxProps> = ({
  type = 'View',
  marginVertical,
  marginHorizontal,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  paddingVertical,
  paddingHorizontal,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  backgroundColor,
  flex = 1,
  scrollRef,
  ...containerProps
}) => {
  const ContainerComponent = containerComponents[type];

  return (
    // @ts-ignore
    <ContainerComponent
      ref={scrollRef}
      style={[
        {
          marginVertical,
          marginHorizontal,
          marginTop,
          marginBottom,
          marginLeft,
          marginRight,
          paddingVertical,
          paddingHorizontal,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          flex,
          backgroundColor: backgroundColor && colors[backgroundColor]
        },
        containerProps.style
      ]}
      {...containerProps}
    />
  );
};

export default LayoutBox;
