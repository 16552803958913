import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from '../routes';

import OnboardingEmailScreen from './Onboarding/OnboardingEmailScreen';
import OnboardingVerificationScreen from './Onboarding/OnboardingVerificationScreen';
import OnboardingECardScreen from './Onboarding/OnboardingECardScreen';
import OnboardingForgetScreen from './Onboarding/OnboardingForgetScreen/OnboardingForgetScreenContainer';
import OnboardingForgetScreenConfirm from './Onboarding/OnboardingForgetScreen/OnboardingForgetScreenConfirmContainer';

const routeConfigMap = {
  [routes.OnboardingEmail]: { screen: OnboardingEmailScreen },
  [routes.OnboardingVerification]: { screen: OnboardingVerificationScreen },
  [routes.OnboardingECard]: { screen: OnboardingECardScreen },
  [routes.OnboardingForget]: { screen: OnboardingForgetScreen },
  [routes.OnboardingForgetConfirm]: { screen: OnboardingForgetScreenConfirm }
};

const OnboardingNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.OnboardingEmail,
    headerMode: 'none'
  })
);

export default OnboardingNavigator;
