import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import * as routes from '../../routes';
import { StackNavigationProp } from '../../types/navigation';
import Screen from '../../components/Screen';
import { PruText } from '@ebsme/pulse-rn-components';
import ConfirmationView from '../../components/ConfirmationView';
import { getNavigate } from '../../utils/navigation';

type Props = {
  navigation: StackNavigationProp;
};

const RequestLogSuccessScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();
  const onBack = useCallback(() => getNavigate(navigation.navigate, routes.RequestLog, { success: true })(), [
    navigation
  ]);

  return (
    <Screen onPressBack={onBack}>
      <ConfirmationView onConfirm={onBack}>
        <PruText color="black">{t('LETTER_OF_GUARANTEE__REQUEST_LOG_SUCCESS__DRAFT_SUCCESS')}</PruText>
      </ConfirmationView>
    </Screen>
  );
};

export default RequestLogSuccessScreen;
