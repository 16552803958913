import { FaceId, TouchId } from '../../assets/svgs/icons';
import React, { useState } from 'react';
import { TouchableOpacity, View, Text, Platform } from 'react-native';

import Modal from 'react-native-modal';
import { useLBUTranslation } from '../../hooks/useLBUTranslation';
interface Props {
  onActive: () => void;
  closeModal: () => void;
  isShowModal: boolean;
  type: string;
  lbu?: string;
}
const BiometricModal = React.forwardRef(({ onActive, closeModal, isShowModal, type, lbu }: Props, ref) => {
  const [isActive, setActive] = useState(false);
  const { tLBU } = useLBUTranslation(lbu);

  return (
    <Modal
      avoidKeyboard={Platform.OS === 'ios'}
      useNativeDriver
      hideModalContentWhileAnimating
      animationIn="zoomIn"
      animationOut="zoomOut"
      animationInTiming={500}
      backdropTransitionOutTiming={0}
      isVisible={isShowModal}
      onBackdropPress={closeModal}
      style={{ marginHorizontal: 20, minWidth: 300 }}
    >
      <View
        style={{
          paddingHorizontal: 20,
          backgroundColor: 'white',
          paddingBottom: 25,
          paddingTop: 42,
          alignItems: 'center'
        }}
      >
        {type === 'FaceID' ? <FaceId style={{ width: 70, height: 70 }} /> : <TouchId />}
        <Text style={{ color: 'black', fontSize: 18, marginVertical: 33, textAlign: 'center', marginHorizontal: 10 }}>
          {isActive
            ? tLBU('BIOMETRICS__ACTIVE_SUCCESS', { fallbackValue: 'Activation Successful' })
            : `${tLBU('BIOMETRICS__CONFIRMATION_MESSAGE', { fallbackValue: 'Would you like to activate' })} ${type}?`}
        </Text>
        {isActive ? (
          <View style={{ flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
            <TouchableOpacity
              onPress={closeModal}
              style={{
                height: 30,
                borderRadius: 15,
                backgroundColor: '#ED1B2E',
                justifyContent: 'center',
                width: 120,
                alignItems: 'center'
              }}
            >
              <Text style={{ color: 'white', fontSize: 17 }}>{tLBU('BIOMETRICS__OK', { fallbackValue: 'OK' })}</Text>
            </TouchableOpacity>
          </View>
        ) : (
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <TouchableOpacity
              onPress={closeModal}
              style={{
                height: 30,
                borderRadius: 15,
                borderWidth: 1,
                borderColor: '#ED1B2E',
                justifyContent: 'center',
                width: 120,
                alignItems: 'center'
              }}
            >
              <Text style={{ color: '#ED1B2E', fontSize: 15 }}>
                {tLBU('BIOMETRICS__NOT_NOW', { fallbackValue: 'Not now' })}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                onActive();
                setActive(true);
              }}
              style={{
                height: 30,
                borderRadius: 15,
                backgroundColor: '#ED1B2E',
                justifyContent: 'center',
                width: 120,
                alignItems: 'center'
              }}
            >
              <Text style={{ color: 'white', fontSize: 17 }}>
                {tLBU('BIOMETRICS__ACTIVE', { fallbackValue: 'Activate' })}
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </Modal>
  );
});

export default BiometricModal;
