import { RootState } from '../../store';

export const getI18nReady = (state: RootState): boolean => state.SDK.i18nReady;

export const getSDKLoading = (state: RootState): boolean => state.SDK.sdkLoading;

export const getSDKError = (state: RootState): string => state.SDK.sdkError;

export const getSDKLocale = (state: RootState): string => state.SDK.locale;

export const getSDKException = (state: RootState): Error | null => state.SDK.sdkException;
