import React from 'react';
import { PruButton, PruText } from '@ebsme/pulse-rn-components';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { openMail } from '../../../../utils/platform';
import { LBU } from '../../../../config';
import { UserCountryCode } from '../../../../types/user';
import { getObjValueByPath } from '../../../../utils/object';
import { getSupportInfo } from '../../../../utils/claim';

interface Props {
  lbu: UserCountryCode;
  claimId: string;
}

const ClaimDetailSubmitAdditional: React.FC<Props> = ({ lbu, claimId }) => {
  const { t } = useTranslation();
  const emails = getSupportInfo(lbu)?.SUPPORT_INFO?.emails || ['sgp.es.claims@prudential.com.sg'];
  return (
    <View style={{ paddingTop: 20, paddingBottom: 30 }}>
      <PruText align="center" color="black" weight="bold" size={18} marginLeft={30} marginRight={30} marginBottom={10}>
        {t('REVIEW_CLAIMS__SUBMIT_ADDITIONAL_DOCS')}
      </PruText>
      <PruButton
        onPress={(): void => {
          openMail(emails[0], {
            subject: `Submit additional documents for claim ${claimId}`
          });
        }}
      >
        {t('REVIEW_CLAIMS__CONTACT_US')}
      </PruButton>
    </View>
  );
};

export default ClaimDetailSubmitAdditional;
