import React, { useState, useEffect, ReactElement } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import Geolocation from '@react-native-community/geolocation';
import Icon from 'react-native-vector-icons/Ionicons';
import { PruCard, PruChip, PruInput, PruSlider, PruText } from '@ebsme/pulse-rn-components';

import { Container, SearchContainer } from './SeeDoctorStyles';
import { StackNavigationProp } from '../../types/navigation';
import Screen from '../../components/Screen';
import ClinicList from '../../components/ClinicList';
import { Clinic } from '../../types/clinic';
import { checkPermission, PERMISSION_TYPE } from '../../utils/permissions';
import { Coordinate } from '../../types/coordinate';
import { ClinicsRequest } from '../../reducers/ClinicsReducer/api';
import ClinicListLoader from '../../components/ClinicList/ClinicListLoader';
import ShadedContainer from '../../components/ShadedContainer';
import { red } from '../../assets/colors';

interface NavigationProps extends StackNavigationProp {
  state: {
    params: {
      showBookmarked?: boolean;
    };
  };
}

type FindClinicScreenProps = {
  navigation: NavigationProps;
  bookmarkedClinics: Clinic[];
  foundClinics: Clinic[];
  loading?: boolean;
  fetchError?: string;
  currentLocation?: Coordinate;
  setCurrentLocation: (coordinate: Coordinate) => void;
  fetchClinics: (requestBody?: ClinicsRequest) => void;
};

const FILTER_TYPES = {
  All: 'SEE_DOCTOR__FIND_CLINIC__FILTER_ALL',
  Nearby: 'SEE_DOCTOR__FIND_CLINIC__FILTER_NEARBY',
  Bookmarked: 'SEE_DOCTOR__FIND_CLINIC__FILTER_BOOKMARKED'
};

const FindClinicScreen: React.FC<FindClinicScreenProps> = ({
  navigation,
  bookmarkedClinics,
  foundClinics,
  loading,
  fetchError,
  setCurrentLocation,
  fetchClinics
}) => {
  const { t } = useTranslation();
  const showBookmarked = navigation.state?.params?.showBookmarked;
  const [searchTerm, setSearchTerm] = useState('');
  const bookmarkedClinicCodes = new Set(bookmarkedClinics.map((item): string => item.code));

  const [filterType, setFilterType] = useState(showBookmarked ? FILTER_TYPES.Bookmarked : FILTER_TYPES.All);

  const foundClinicsWithBookmarkedFlag = foundClinics.map((item) => {
    if (bookmarkedClinicCodes.has(item.code)) {
      return { ...item, bookmarked: true };
    }
    return item;
  });
  const isBookmarkedSelected = filterType === FILTER_TYPES.Bookmarked;

  useEffect((): void => {
    checkPermission(PERMISSION_TYPE.location).then((granted) => {
      if (granted) {
        Geolocation.getCurrentPosition((info) => {
          const { latitude, longitude } = info.coords;
          setCurrentLocation({ latitude, longitude });
        });
      }
    });
  }, [setCurrentLocation]);

  useEffect((): void => {
    fetchClinics();
  }, [fetchClinics]);

  const getFilterComponent = (): ReactElement => {
    switch (filterType) {
      case FILTER_TYPES.All: {
        return (
          <SearchContainer testID="View__Filter_Type__All">
            <PruInput
              icon={<Icon name="ios-search" size={24} color={red} />}
              value={searchTerm}
              placeholder={t('SEE_DOCTOR__FIND_CLINIC__SEARCH')}
              onChangeText={setSearchTerm}
            />
          </SearchContainer>
        );
      }
      case FILTER_TYPES.Nearby: {
        return (
          <View style={{ marginVertical: 12 }} testID="View__Filter_Type__Nearby">
            <PruText color="black" marginTop={12}>
              {t('SEE_DOCTOR__FIND_CLINIC__NEARBY')}
            </PruText>
            <PruText color="darkGrey" marginTop={12}>
              {t('SEE_DOCTOR__FIND_CLINIC__NEARBY_HINT')}
            </PruText>
            <PruCard style={{ marginTop: 12 }}>
              <PruSlider
                minimumValue={0}
                maximumValue={20}
                thumbValueFormatter={(value): string => `${Math.round(value)}km`}
                boundValueFormatter={(value): string => (value === 0 ? `${value} ` : `${value}km`)}
              />
            </PruCard>
          </View>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  const getResultComponent = (): ReactElement => {
    switch (filterType) {
      case FILTER_TYPES.Bookmarked:
        return (
          <ShadedContainer>
            {isBookmarkedSelected && !bookmarkedClinics.length && (
              <Container>
                <PruText testID="FindClinicScreen__EmptyBookmark">
                  {t('SEE_DOCTOR__FIND_CLINIC__BOOKMARKED_EMPTY')}
                </PruText>
              </Container>
            )}
            <ClinicList data={bookmarkedClinics} />
          </ShadedContainer>
        );
      default:
        return (
          <>
            {fetchError && (
              <PruText
                testID="FindClinicScreen__Error"
                style={{ margin: 24 }}
                size={12}
                weight="semiBold"
                color="darkRed"
              >
                {fetchError}
              </PruText>
            )}
            <ShadedContainer>
              {loading ? (
                <ClinicListLoader testID="FindClinicScreen__ListLoader" />
              ) : (
                <ClinicList data={foundClinicsWithBookmarkedFlag} />
              )}
            </ShadedContainer>
          </>
        );
    }
  };

  return (
    <Screen
      heading={t(filterType)}
      subHeading={
        isBookmarkedSelected
          ? t('SEE_DOCTOR__BOOKMARKED_CLINICS_DESCRIPTION')
          : t('SEE_DOCTOR__FIND_CLINIC__DESCRIPTION')
      }
      headerPaddingHorizontal={24}
      onPressBack={(): void => {
        navigation.goBack();
      }}
    >
      <Container>
        <View style={{ flexDirection: 'row', marginTop: 12 }}>
          {Object.values(FILTER_TYPES).map((value) => (
            <PruChip
              key={value}
              style={{ marginEnd: 12 }}
              selected={filterType === value}
              onPress={(): void => {
                setFilterType(value);
              }}
            >
              {t(value)}
            </PruChip>
          ))}
        </View>

        {getFilterComponent()}
      </Container>
      {getResultComponent()}
    </Screen>
  );
};

export default FindClinicScreen;
