import { getObjValue } from './object';

interface UrlParamsObj {
  [key: string]: string | number | undefined;
}

interface UrlParamsOptions {
  includePrefix?: boolean;
  includeEmpty?: boolean;
}

export const getUrlParams = (obj: UrlParamsObj = {}, options: UrlParamsOptions = {}): string => {
  const { includePrefix, includeEmpty } = options;

  const initialStr = includePrefix ? '?' : '';

  const params = Object.keys(obj).reduce((acc, key) => {
    const prefix = acc === '' ? '' : '&';
    const value = getObjValue(obj, key, '');

    if (!includeEmpty && value === '') {
      return acc;
    }

    const param = key + '=' + encodeURIComponent(value);

    return `${acc}${prefix}${param}`;
  }, '');

  if (!params) {
    return params;
  }

  return `${initialStr}${params}`;
};
