import React from 'react';
import { SwiperProps } from './SwiperProps';

// import Swiper styles
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { View } from 'react-native';
import { EVENT_ANALYTICS, OBJECT_ID_CLICK, logFirebaseEventClick } from '../../utils/analytics';

const Swiper = ({ items, renderItem }: SwiperProps) => {
  // return (
  //   <SwiperReact
  //   >
  //         {
  //     !!items && !!renderItem && items.map((item, index) => {
  //       return (
  //         <SwiperSlide>
  //           {renderItem(item, index)}
  //         </SwiperSlide>
  //       )
  //     })
  //   }
  //   </SwiperReact>
  // )

  return (
    <Carousel
      onChange={(index) => {
        logFirebaseEventClick(EVENT_ANALYTICS.card, {
          feature: 'EcareCard',
          journey: 'ecare_card',
          stage: 'card_view',
          screen_id: 'SCR_EB_LANDING_LANDS',
          screen_name: 'LandingScreen',
          object_id: OBJECT_ID_CLICK.tiles_card_swipe
        });
      }}
    >
      {!!items &&
        !!renderItem &&
        items.map((item, index) => {
          return <View style={{ alignSelf: 'center' }}>{renderItem(item, index)}</View>;
        })}
    </Carousel>
  );
};

export default Swiper;
