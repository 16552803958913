import { RootState } from '../../../store';
import ClaimDetailScreen from './ClaimInfoScreen';
// import ClaimDetailScreenSG from './SG/ClaimDetailScreen';
import { updateClaim } from '../../../reducers/MakeClaimReducer/actions';
import { getLoading, getClaim } from '../../../reducers/MakeClaimReducer/selectors';
import withLBUSwitcher from '../../../hocs/withLBUSwitcher';

const LBU_CONFIG = {
  SG: ClaimDetailScreen,
  TH: ClaimDetailScreen,
  PH: ClaimDetailScreen,
  MY: null,
  MM: null
};

export default withLBUSwitcher(
  LBU_CONFIG,
  (state: RootState) => {
    return {
      loading: getLoading(state),
      claim: getClaim(state)
    };
  },
  { updateClaim }
);
