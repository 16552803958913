import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StackNavigationProp } from '../../types/navigation';
import * as routes from '../../routes';
import { PruInput, PruText } from '@ebsme/pulse-rn-components';
import Screen from '../../components/Screen';
import Icon from 'react-native-vector-icons/Ionicons';
import { FlatList, Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { Container, styles } from './FindHospitalStyle';
import Geolocation from '@react-native-community/geolocation';
import { TabView, TabBar } from 'react-native-tab-view';
import FindHospitalList from './FindHospitalList';
import { check, PERMISSIONS, request, RESULTS } from '../../utils/permissions';
import * as Location from 'expo-location';

import { ClinicNearBy } from 'app/types/clinic';
import { Policy } from '../../types/policy';
import { ClinicsRequest } from '../../reducers/ClinicsReducer/api';
import { useLBUTranslation } from '../../hooks/useLBUTranslation';
import { Locale } from '../../types/sdk';
import { getTransactionLabel } from '../../utils/claim';
import { compareType } from '../../utils/hospitalUtil';
import { EVENT_ANALYTICS, logFirebaseEventScreen } from '../../utils/analytics';

const pageSize = '12';
const clinicProviders = [
  { en: 'General hospital', th: 'โรงพยาบาลทั่วไป' },
  { en: 'Specialist hospital', th: 'โรงพยาบาลเฉพาะทาง' },
  { en: 'General clinic', th: 'คลินิกอายุรกรรมทั่วไป' },
  { en: 'Specialist clinic', th: 'คลินิกเฉพาะทาง' },
  { en: 'Dental hospital', th: 'โรงพยาบาลทันตกรรม' },
  { en: 'Dental clinic', th: 'คลินิกทันตกรรม' }
];
type Props = {
  navigation: StackNavigationProp;
  setSDKLoading: (loading: boolean) => void;
  clinicNearBy: ClinicNearBy;
  policy?: Policy;
  fetchClinics: (request: ClinicsRequest) => void;
  loading: boolean;
  lbu?: string;
  locale: Locale;
};
const FindHospitalScreen: React.FC<Props> = ({
  navigation,
  setSDKLoading,
  fetchClinics,
  clinicNearBy,
  policy,
  loading,
  lbu,
  locale
}) => {
  const { t, i18n } = useTranslation();
  const { tLBU } = useLBUTranslation(lbu);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loadingPermission, setLoadingPermission] = useState(true);
  const [indexTab, setIndex] = React.useState(0);
  const currentLocation = useRef({ lat: '', lng: '' });
  const { clinics, currentPage, totalPage } = clinicNearBy;
  useEffect((): void => {
    logFirebaseEventScreen(EVENT_ANALYTICS.clinic_locator, {
      feature: 'ClinicLocator',
      journey: 'clinic_locator',
      stage: 'clinic_list',
      screen_id: 'SCR_EB_CL_FHL',
      screen_name: 'FindHospitalList'
    });
    locationPermission();
  }, []);
  const routesTab = useMemo(() => {
    if (lbu === 'TH') {
      if (!clinics || clinics.length == 0) {
        return [];
      }
      const listRoute = [
        { key: 'All', title: t('SEE_DOCTOR__FIND_CLINIC__FILTER_ALL'), clinics: clinics },
        { key: 'Recommended', title: t('CLINIC__RECOMMENDED'), clinics: [] },
        {
          key: clinicProviders[0].en,
          title: getTransactionLabel(clinicProviders[0], locale),
          clinics: []
        },
        {
          key: clinicProviders[1].en,
          title: getTransactionLabel(clinicProviders[1], locale),
          clinics: []
        },
        {
          key: clinicProviders[2].en,
          title: getTransactionLabel(clinicProviders[2], locale),
          clinics: []
        },
        {
          key: clinicProviders[3].en,
          title: getTransactionLabel(clinicProviders[3], locale),
          clinics: []
        },
        {
          key: clinicProviders[4].en,
          title: getTransactionLabel(clinicProviders[4], locale),
          clinics: []
        },
        {
          key: clinicProviders[5].en,
          title: getTransactionLabel(clinicProviders[5], locale),
          clinics: []
        }
      ];

      clinics.map((clinic) => {
        if (clinic.recommended) {
          listRoute[1].clinics.push(clinic);
        }
        const indexRoute = listRoute.findIndex((p) => compareType(p.key, clinic.clinicType?.en || ''));
        if (indexRoute > -1) {
          listRoute[indexRoute].clinics.push(clinic);
        } else {
          // listRoute.push({ key: clinicType, title: clinicType, clinics: [clinic] });
        }
      });
      if (listRoute[1].clinics.length == 0) {
        listRoute.splice(1, 1);
      }
      return listRoute;
    } else {
      if (!clinics || clinics.length == 0) {
        return [];
      }
      const listRoute = [
        { key: 'All', title: t('SEE_DOCTOR__FIND_CLINIC__FILTER_ALL'), clinics: clinics },
        { key: 'Recommended', title: t('CLINIC__RECOMMENDED'), clinics: [] }
      ];

      clinics.map((clinic) => {
        const clinicType = getTransactionLabel(clinic.clinicType, locale);
        if (clinic.recommended) {
          listRoute[1].clinics.push(clinic);
        }
        const indexRoute = listRoute.findIndex((p) => p.key == clinicType);
        if (indexRoute > -1) {
          listRoute[indexRoute].clinics.push(clinic);
        } else {
          listRoute.push({ key: clinicType, title: clinicType, clinics: [clinic] });
        }
      });
      if (listRoute[1].clinics.length == 0) {
        listRoute.splice(1, 1);
      }
      return listRoute;
    }
  }, [clinics, locale]);
  const onPressBack = (): void => {
    navigation.navigate(routes.Landing);
  };
  const locationPermission = () => {
    if (Platform.OS === 'web') {
      Location.requestForegroundPermissionsAsync()
        .then((result) => {
          if (result.status === 'granted') {
            getLocation();
          } else {
            getDataClinics();
          }
        })
        .catch((err) => {
          getDataClinics();
        })
        .finally(() => {
          setLoadingPermission(false);
        });
    } else if (Platform.OS === 'ios') {
      check(PERMISSIONS.IOS.LOCATION_ALWAYS)
        .then((result) => {
          if (result !== RESULTS.GRANTED) {
            check(PERMISSIONS.IOS.LOCATION_WHEN_IN_USE)
              .then((result) => {
                if (result === RESULTS.GRANTED) {
                  getLocation();
                } else if (result === RESULTS.BLOCKED) {
                  getDataClinics();
                } else {
                  request(PERMISSIONS.IOS.LOCATION_ALWAYS)
                    .then((result) => {
                      if (result === RESULTS.GRANTED) {
                        getLocation();
                      } else if (result === RESULTS.BLOCKED) {
                        check(PERMISSIONS.IOS.LOCATION_WHEN_IN_USE).then((result) => {
                          if (result === RESULTS.GRANTED) {
                            getLocation();
                          } else if (result === RESULTS.BLOCKED) {
                            getDataClinics();
                          }
                        });
                      }
                    })
                    .catch((error) => {});
                }
              })
              .catch((error) => {});
          } else {
            getLocation();
          }
        })
        .catch((error) => {});
    } else {
      check(PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION)
        .then((result) => {
          if (result === RESULTS.GRANTED || result === true) {
            getLocation();
          } else {
            request(PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION)
              .then((result) => {
                if (result === RESULTS.GRANTED) {
                  getLocation();
                } else {
                  getDataClinics();
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {});
    }
  };

  const getLocation = () => {
    if (Platform.OS === 'web') {
      Location.getCurrentPositionAsync({})
        .then((location) => {
          const { latitude, longitude } = location.coords;
          currentLocation.current = { lat: latitude.toString(), lng: longitude.toString() };
          getDataClinics();
        })
        .catch((err) => {
          getDataClinics();
        });
    } else {
      Geolocation.getCurrentPosition((info) => {
        const { latitude, longitude } = info.coords;
        currentLocation.current = { lat: latitude.toString(), lng: longitude.toString() };
        getDataClinics();
      });
    }
  };

  const getDataClinics = (pageNumber = '0') => {
    fetchClinics({
      location: currentLocation.current,
      certificateNo: policy?.certificateNo,
      pageNumber,
      pageSize
    });
  };
  const onLoadMore = () => {
    const intCurrentPage = parseInt(currentPage);
    const intTotalPage = parseInt(totalPage);
    if (intCurrentPage + 1 < intTotalPage) {
      getDataClinics((intCurrentPage + 1).toString());
    }
  };
  const renderTabBar = () => {
    return (
      <View>
        <ScrollView style={styles.tabContainer} horizontal showsHorizontalScrollIndicator={false}>
          {routesTab.map((item: any, index: number) => {
            const selected = index == indexTab;
            return (
              <TouchableOpacity
                key={item.title}
                style={[
                  styles.buttonTab,
                  { backgroundColor: selected ? '#FEEFF0' : 'white', borderColor: selected ? '#ED1B2E' : '#B4C0CC' }
                ]}
                onPress={() => {
                  setIndex(index);
                }}
              >
                <Text
                  style={{ fontSize: 13, fontWeight: '500', lineHeight: 24, color: selected ? '#ED1B2E' : '#8EA0B2' }}
                >
                  {item.title}
                </Text>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>
    );
  };

  return (
    <Screen headingPosition="header" containerType="View" headerPaddingHorizontal={100} onPressBack={onPressBack}>
      {routesTab.length == 0 ? (
        <PruText weight="bold" color="black" size={16} marginTop={80} style={{ textAlign: 'center' }}>
          {loadingPermission
            ? tLBU('SEE_DOCTOR__FIND_CLINIC__WAITING_LOCATION', {
                fallbackValue: 'Turn on the location for your best experience'
              })
            : tLBU('SEE_DOCTOR__FIND_CLINIC__NO_LIST', { fallbackValue: 'No Clinic list available' })}
        </PruText>
      ) : (
        <>
          <Container>
            <PruInput
              style={styles.input}
              containerStyle={styles.containerInput}
              icon={<Icon name="ios-search" size={24} color={'#8EA0B2'} />}
              value={searchTerm}
              placeholder={t('SEE_DOCTOR__FIND_CLINIC__SEARCH')}
              onChangeText={setSearchTerm}
              placeholderTextColor={'#B4C0CC'}
            />
          </Container>
          <View style={{ backgroundColor: 'white', flex: 1 }}>
            <TabView
              lazy={false}
              navigationState={{ index: indexTab, routes: routesTab }}
              renderScene={({ route }: any) => (
                <FindHospitalList
                  clinics={route.clinics}
                  clinicType={route.key}
                  onEndReachedCustom={onLoadMore}
                  navigation={navigation}
                  searchTerm={searchTerm}
                  currentLocation={currentLocation.current}
                  locale={locale}
                />
              )}
              onIndexChange={setIndex}
              renderTabBar={renderTabBar}
            />
          </View>
        </>
      )}
    </Screen>
  );
};

export default FindHospitalScreen;
