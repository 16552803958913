import { PruText } from '@ebsme/pulse-rn-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BookmarksOutline from '../../assets/svgs/icons/bookmarks-outline.svg';
import DocumentsOutline from '../../assets/svgs/icons/documents-outline.svg';
import MedkitOutline from '../../assets/svgs/icons/medkit-outline.svg';
import VideoCamOutline from '../../assets/svgs/icons/videocam-outline.svg';
import IconListTile from '../../components/IconListTile';
import Screen from '../../components/Screen';
import ShadedContainer from '../../components/ShadedContainer';
import TouchableText from '../../components/TouchableText';
import { goToWithParams } from '../../goToWithParams';

import * as routes from '../../routes';
import store from '../../store';
import { StackNavigationProp } from '../../types/navigation';
import { Policy } from '../../types/policy';
import { PulseUser } from '../../types/user';
import { getNavigate } from '../../utils/navigation';
import { CenterView } from './SeeDoctorStyles';

type Props = {
  navigation: StackNavigationProp;
};

const SeeDoctorMenuScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();

  return (
    <Screen
      heading={t('SEE_DOCTOR__TITLE')}
      subHeading={t('SEE_DOCTOR__TYPE_OF_CARE')}
      headerPaddingHorizontal={24}
      onPressBack={(): void => {
        navigation.navigate(routes.Landing);
      }}
    >
      <ShadedContainer inverse style={{ padding: 24 }}>
        <IconListTile
          title={t('SEE_DOCTOR__FIND_CLINIC')}
          icon={<MedkitOutline width='30' height='30'
                               style={{ marginRight: 24 }} />}
          onPress={getNavigate(navigation.navigate, routes.FindClinic)}
        />
        <IconListTile
          title={t('SEE_DOCTOR__BOOKMARKED_CLINICS')}
          icon={<BookmarksOutline width='30' height='30'
                                  style={{ marginRight: 24 }} />}
          onPress={getNavigate(navigation.navigate, routes.FindClinic, { showBookmarked: true })}
        />
      </ShadedContainer>
      <CenterView style={{ marginVertical: 24 }}>
        <PruText>{t('SEE_DOCTOR__CHECK_ENTITLEMENT')}</PruText>
        <TouchableText
          onPress={(): void => {
            //TODO: navigate to My Benefits
            getNavigate(navigation.navigate, '');
          }}
        >
          {t('SEE_DOCTOR__MY_BENEFITS')}
        </TouchableText>
      </CenterView>
      <ShadedContainer inverse style={{ padding: 24 }}>
        <IconListTile
          title={t('SEE_DOCTOR__BOOK_VIDEO_CONSULTATION')}
          icon={<VideoCamOutline width='30' height='30'
                                 style={{ marginRight: 24 }} />}
          onPress={getNavigate(navigation.navigate, '')}
        />
        <IconListTile
          title={t('SEE_DOCTOR__CONSULTATION_SUMMARY')}
          icon={<DocumentsOutline width='30' height='30'
                                  style={{ marginRight: 24 }} />}
          onPress={getNavigate(navigation.navigate, '')}
        />
      </ShadedContainer>
    </Screen>
  );
};

export default SeeDoctorMenuScreen;
