const Root = 'EbSmeApp';
export const MakeClaim = `${Root}/MakeClaim`;
export const ClaimantScreen = `${Root}/MakeClaim/ClaimantScreen`;
export const ClaimTypeScreen = `${Root}/MakeClaim/ClaimTypeScreen`;
export const Step1Screen = `${Root}/MakeClaim/Step1Screen`;
export const ConsentScreen = `${Root}/MakeClaim/ConsentScreen`;
export const ClaimInfoScreen = `${Root}/MakeClaim/ClaimInfoScreen`;
export const ClaimPaymentScreen = `${Root}/MakeClaim/ClaimPaymentScreen`;
export const DocumentScreen = `${Root}/MakeClaim/DocumentScreen`;
export const FinalSummaryScreen = `${Root}/MakeClaim/FinalSummaryScreen`;
export const NextStepScreen = `${Root}/MakeClaim/NextStepScreen`;
export const ConfirmationScreen = `${Root}/MakeClaim/ConfirmationScreen`;
