import React from 'react';
import HTML from 'react-native-render-html';
import { colors, PruText } from '@ebsme/pulse-rn-components';

import { BoldColorOptions } from './MyPolicyItemStyles';
import { getAlphabetFromNumber, getRomanFromNumber } from '../MyPolicyUtils';

export interface Props {
  label: string;
  value?: React.ReactText;
  highlighted?: boolean;
  leadingNumber?: string;
  boldColor?: BoldColorOptions;
  indentStr?: string;
  flex?: boolean;
}

const HtmlText: React.FC<Props> = ({ label, value, leadingNumber, boldColor }) => {
  const hasValue = value !== null && typeof value !== 'undefined';
  const isBoldRow = !hasValue && !!boldColor;
  const labelPrefix = leadingNumber ? leadingNumber + ' ' : '';

  return (
    <HTML
      html={`${labelPrefix}${label}`}
      containerStyle={{
        marginBottom: hasValue ? 5 : 0
      }}
      tagsStyles={{
        ol: {
          marginBottom: 0,
          paddingBottom: 0
        },
        li: {
          marginBottom: 0,
          paddingBottom: 0
        },
        p: {
          marginBottom: 0,
          paddingBottom: 0
        }
      }}
      baseFontStyle={{
        color: colors['darkBlue'],
        fontSize: 13,
        fontFamily: 'OpenSans-Regular',
        fontWeight: '700',
        lineHeight: 25
      }}
      listsPrefixesRenderers={{
        // eslint-disable-next-line react/display-name
        ol: (htmlAttribs, _children, _convertedCSSStyles, passProps): React.ReactNode => {
          //@ts-ignore
          const { index } = passProps;

          let bulletNumber = htmlAttribs.start ? htmlAttribs.start : index + 1;
          switch (htmlAttribs.type) {
            case 'a':
              bulletNumber = `(${getAlphabetFromNumber(bulletNumber)})`;
              break;
            case 'A':
              bulletNumber = `(${getAlphabetFromNumber(bulletNumber, true)})`;
              break;
            case 'i':
              bulletNumber = `(${getRomanFromNumber(bulletNumber)})`;
              break;
            case 'I':
              bulletNumber = `(${getRomanFromNumber(bulletNumber, true)})`;
              break;
            default:
              break;
          }
          if (htmlAttribs.style === 'list-style-type: none') {
            bulletNumber = '';
          }
          return (
            <PruText
              testID={`HtmlText_Bullet_${index}`}
              color={'darkBlue'}
              size={13}
              style={{ marginEnd: 5 }}
              weight={'bold'}
            >
              {bulletNumber}
            </PruText>
          );
        }
      }}
    />
  );
};

export default HtmlText;
