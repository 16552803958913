import React from 'react';
import { View } from 'react-native';
import { PruText } from '@ebsme/pulse-rn-components';

interface Props {
  message?: string;
}

const NoClaimsView: React.FC<Props> = ({ message = 'You currently have no claims' }) => {
  return (
    <View
      testID="NoClaimsView"
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        marginTop: 80
      }}
    >
      <PruText weight="bold" color="black" size={16}>
        {message}
      </PruText>
    </View>
  );
};

export default NoClaimsView;
