import {
  FETCH_BANK_ACCOUNTS_SUCCESS,
  FETCH_BANK_ACCOUNTS_FAILED,
  FETCH_BANK_ACCOUNTS_START,
  FETCH_BANKS_SUCCESS,
  FETCH_BANKS_FAILED,
  FETCH_BANKS_START,
  ADD_BANK_ACCOUNT_START,
  ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_FAILED,
  REMOVE_BANK_ACCOUNT_START,
  REMOVE_BANK_ACCOUNT_SUCCESS,
  REMOVE_BANK_ACCOUNT_FAILED,
  BankAccountAction
} from './actions';
import { RESET_CLAIM, MakeClaimAction } from '../MakeClaimReducer/actions';
import { Bank } from '../../types/bank';
import { BankAccount } from '../../types/bankAccount';

export type { BankAccountAction };

type BankAccountState = {
  bankAccounts: BankAccount[];
  bankAccountsFetchError?: string;
  bankAccountsLoading: boolean;
  banks: Bank[];
  banksFetchError?: string;
  banksLoading: boolean;
};

export const initialState: BankAccountState = {
  bankAccounts: [],
  bankAccountsFetchError: undefined,
  bankAccountsLoading: false,
  banks: [],
  banksFetchError: undefined,
  banksLoading: false
};

const BankAccountReducer = (
  state: BankAccountState = initialState,
  action: BankAccountAction | MakeClaimAction
): BankAccountState => {
  switch (action.type) {
    case FETCH_BANK_ACCOUNTS_START:
      return {
        ...state,
        bankAccountsLoading: true,
        bankAccountsFetchError: undefined
      };

    case FETCH_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        bankAccounts: action.payload || [],
        bankAccountsLoading: false
      };
    case FETCH_BANK_ACCOUNTS_FAILED:
      return {
        ...state,
        bankAccountsLoading: false,
        bankAccountsFetchError: action.payload
      };

    case ADD_BANK_ACCOUNT_START:
      return {
        ...state,
        bankAccountsLoading: true,
        bankAccountsFetchError: undefined
      };

    case ADD_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        bankAccounts: [...state.bankAccounts, action.payload],
        bankAccountsLoading: false
      };
    case ADD_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        bankAccountsLoading: false,
        bankAccountsFetchError: action.payload
      };
    case REMOVE_BANK_ACCOUNT_START:
      return {
        ...state,
        bankAccountsLoading: true,
        bankAccountsFetchError: undefined
      };

    case REMOVE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        bankAccounts: state.bankAccounts.filter((item) => item.id !== action.payload.id),
        bankAccountsLoading: false
      };
    case REMOVE_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        bankAccountsLoading: false,
        bankAccountsFetchError: action.payload
      };

    case FETCH_BANKS_START:
      return {
        ...state,
        banksLoading: true,
        banksFetchError: undefined
      };

    case FETCH_BANKS_SUCCESS:
      return {
        ...state,
        banks: action.payload,
        banksLoading: false
      };
    case FETCH_BANKS_FAILED:
      return {
        ...state,
        banksLoading: false,
        banksFetchError: action.payload
      };
    case RESET_CLAIM:
      return {
        ...state,
        bankAccounts: state.bankAccounts.filter((item) => !item.isNew)
      };

    default:
      return state;
  }
};

export default BankAccountReducer;
