import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoToWithParams, registerGoToWithParams } from './goToWithParams';

import { Locale } from './types/sdk';
import { PulseUser } from './types/user';

import StoreHydratorContainer from './components/StoreHydrator';
import LoadingOverlayContainer from './components/LoadingOverlay';
import store, { persistor } from './store';
import AuthenticationViewContainer from './components/AuthenticationView';
import './utils/timeout';

interface Props {
  locale?: Locale;
  userProfile?: PulseUser;
  onException?: (e: Error) => void;
  back2Pulse?: () => void;
  goToWithParams: GoToWithParams;
}

const EbSmeApp: React.FC<Props> = ({ locale, userProfile, onException, back2Pulse, goToWithParams }) => {
  React.useEffect(() => {
    registerGoToWithParams(goToWithParams);
  }, [goToWithParams]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StoreHydratorContainer locale={locale} pulseUser={userProfile} setSDKLoading={(): void => undefined}>
          <LoadingOverlayContainer onException={onException}>
            <AuthenticationViewContainer back2Pulse={back2Pulse}/>
          </LoadingOverlayContainer>
        </StoreHydratorContainer>
      </PersistGate>
    </Provider>
  );
};

export default EbSmeApp;
