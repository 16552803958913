import React from 'react';
import { View } from 'react-native';
import { colors, PruSpace, PruText } from '@ebsme/pulse-rn-components';
import { Claim } from '../../../types/claim';
import { useTranslation } from 'react-i18next';
import { formatAmountValue, getString } from '../../../utils/claim';
import { TextWeight } from '@ebsme/pulse-rn-components/PruText';

export interface Props {
  claim: Claim;
}

interface ClaimDetailRow {
  label: string;
  value: string;
  valueSize?: number;
  valueWeight?: TextWeight;
}

const mapClaimDetails = (claimDetail: Claim): ClaimDetailRow[] => [
  {
    label: 'REVIEW_CLAIMS__CLAIM_DIAGNOSIS',
    value: getString(claimDetail.reason)
  },
  {
    label: 'REVIEW_CLAIMS__CLAIM_REMARKS',
    value: getString(claimDetail.remarks),
    valueSize: 14,
    valueWeight: 'semiBold'
  }
];

const mapPaymentDetails = (claimDetail: Claim): ClaimDetailRow[] => [
  {
    label: 'REVIEW_CLAIMS__CLAIM_INCURRED_DATE',
    value: getString(claimDetail.incurredDate)
  },
  {
    label: 'REVIEW_CLAIMS__AMOUNT',
    value: getString(claimDetail.amount, formatAmountValue)
  },
  {
    label: 'REVIEW_CLAIMS__BANK_NAME',
    value: getString(claimDetail.bankName)
  },
  {
    label: 'REVIEW_CLAIMS__ACCOUNT_NUMBER',
    value: getString(claimDetail.accountNumber)
  },
  {
    label: 'REVIEW_CLAIMS__ACCOUNT_HOLDER_NAME',
    value: getString(claimDetail.accountHolderName)
  },
  {
    label: 'REVIEW_CLAIMS__RECEIPTS_CURRENCY',
    value: getString(claimDetail.receiptsCurrency)
  }
];

const ClaimPaymentInfoView: React.FC<Props> = ({ claim }) => {
  const { t } = useTranslation();
  const claimData = mapClaimDetails(claim);
  const paymentData = mapPaymentDetails(claim);

  const renderRow = ({ label, value, valueSize, valueWeight }: ClaimDetailRow): React.ReactElement => (
    <View
      key={label}
      style={{
        marginTop: 20,
        marginBottom: 10
      }}
    >
      <PruText size={15} weight="bold">
        {t(label).toUpperCase()}
      </PruText>
      <PruSpace height={5} />
      <PruText size={valueSize ?? 18} weight={valueWeight ?? 'normal'}>
        {value}
      </PruText>
    </View>
  );

  return (
    <View
      testID="ClaimPaymentInfoView"
      style={{
        margin: 10
      }}
    >
      {/* Claim Information */}
      <View
        style={{
          borderBottomColor: colors.grey,
          borderBottomWidth: 1,
          marginVertical: 20
        }}
      />
      <PruText size={20} weight="semiBold">
        {t('REVIEW_CLAIMS__CLAIM_INFORMATION')}
      </PruText>
      <PruSpace height={12} />
      {claimData.map(renderRow)}

      {/* Payment Information */}
      <View
        style={{
          borderBottomColor: colors.grey,
          borderBottomWidth: 1,
          marginVertical: 20
        }}
      />
      <PruText size={20} weight="semiBold">
        {t('REVIEW_CLAIMS__PAYMENT_INFORMATION')}
      </PruText>
      <PruSpace height={12} />
      {paymentData.map(renderRow)}
      <View
        style={{
          borderBottomColor: colors.grey,
          borderBottomWidth: 1,
          marginVertical: 20
        }}
      />
    </View>
  );
};

export default ClaimPaymentInfoView;
