import {
  getFetchErrorDependant,
  getLoading,
  getPolicy,
  getPolicyDependant,
  getPolicyDetail
} from '../../reducers/MyPolicyReducer/selectors';
import { fetchPolicyDependant as fetchPolicyDetail } from '../../reducers/MyPolicyReducer/actions';
import withSDKLoader from '../../hocs/withSDKLoader/withSDKLoader';
import { getSDKLocale } from '../../reducers/SDKReducer/selectors';
import { getDependants } from '../../reducers/MyPolicyReducer/selectors';

import { RootState } from '../../store';
import withLBUSwitcher from '../../hocs/withLBUSwitcher';

import MyPolicyScreen from '../MyPolicy/MyPolicyScreen';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  fetchingPolicy: getLoading(state),
  policy: getPolicy(state),
  policyDetail: getPolicyDependant(state),
  fetchError: getFetchErrorDependant(state),
  locale: getSDKLocale(state),
  dependants: getDependants(state)
});

export default withLBUSwitcher(withSDKLoader(MyPolicyScreen), mapStateToProps, { fetchPolicyDetail });
