import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'react-native-vector-icons/Ionicons';

import { StackNavigationProp } from '../../../types/navigation';
import { getNavigate } from '../../../utils/navigation';
import * as routes from '../../../routes';
import Screen from '../../../components/Screen';
import ShadedContainer from '../../../components/ShadedContainer';
import { PruCard, PruInput, PruText } from '@ebsme/pulse-rn-components';
import FAQList from '../../../components/FAQList';
import HorizontalSeparator from '../../../components/HorizontalSeparator';
import { SearchContainer } from './ClaimsSupportOverviewStyles';
import { faqs } from '../ClaimsSupportFAQ/ClaimsSupportFAQData';
import { red } from '../../../assets/colors';
import { openMail, callPhoneNumber } from '../../../utils/platform';
import StaticField from '../../../components/StaticField';
import { TouchableOpacity } from 'react-native';

interface Props {
  navigation: StackNavigationProp;
}

const tel = '1800 333 0 333';
const email = 'sgp.es.claims@prudential.com.sg';

const ClaimsSupportOverviewScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Screen
      heading={t('HELP_CENTER__TITLE')}
      headerPaddingHorizontal={24}
      onPressBack={getNavigate(navigation.navigate, routes.Landing)}
    >
      <SearchContainer>
        <PruInput
          icon={<Icon name="ios-search" size={24} color={red} />}
          value={searchTerm}
          placeholder={t('CLAIMSSUPPORT__FAQ__SEARCH')}
          onChangeText={setSearchTerm}
        />
      </SearchContainer>
      <ShadedContainer style={{ padding: 24 }}>
        <FAQList faqs={faqs} searchTerm={searchTerm} />
      </ShadedContainer>
      <PruText size={18} style={{ margin: 24 }} color="black" weight="bold">
        {t('HELP_CENTER__PREFER_TO_SPEAK')}
      </PruText>
      <ShadedContainer style={{ padding: 24 }}>
        <PruCard style={{ padding: 0 }}>
          <TouchableOpacity
            onPress={(): void => {
              callPhoneNumber(tel.replace(/ /g, ''));
            }}
          >
            <StaticField testID="ClaimSupport_Call" label={t('CLAIMSSUPPORT__CONTACT__TEL')} value={tel} />
          </TouchableOpacity>
          <HorizontalSeparator />
          <TouchableOpacity
            onPress={(): void => {
              openMail(email, {
                subject: 'Customer Support Enquiry'
              });
            }}
          >
            <StaticField testID="ClaimSupport_Email" label={t('CLAIMSSUPPORT__CONTACT__EMAIL')} value={email} />
          </TouchableOpacity>
        </PruCard>
      </ShadedContainer>
    </Screen>
  );
};

export default ClaimsSupportOverviewScreen;
