import { connect } from 'react-redux';

import { RootState } from '../../store';
import { fetchClinics } from './../../reducers/ClinicsReducer/actions';
import { setCurrentLocation } from './../../reducers/PreferencesReducer/actions';
import { getBookmarkedClinics, getCurrentLocation } from '../../reducers/PreferencesReducer/selectors';
import { getClinicNearBy, getFetchError, getLoading } from '../../reducers/ClinicsReducer/selectors';
import FindHospitalScreen from './FindHospitalScreen';
import { getPolicy } from '../../reducers/MyPolicyReducer/selectors';
import withSDKLoader from '../../hocs/withSDKLoader';
import withLBUSwitcher from '../../hocs/withLBUSwitcher';
import { getSDKLocale } from '../../reducers/SDKReducer/selectors';

export default withLBUSwitcher(
  FindHospitalScreen,
  (state: RootState) => ({
    bookmarkedClinics: getBookmarkedClinics(state),
    clinicNearBy: getClinicNearBy(state),
    fetchError: getFetchError(state),
    loading: getLoading(state),
    currentLocation: getCurrentLocation(state),
    policy: getPolicy(state),
    locale: getSDKLocale(state)
  }),
  { setCurrentLocation, fetchClinics }
);
