import React from 'react';
import { View, TouchableWithoutFeedback } from 'react-native';
import { withNavigation, NavigationInjectedProps } from 'react-navigation';
import { useTranslation } from 'react-i18next';

import { Clinic } from '../../types/clinic';
import { PruText, PruCard } from '@ebsme/pulse-rn-components';
import * as routes from '../../routes';
import { getNavigate } from '../../utils/navigation';
import ActionableText from '../ActionableText';

export interface ClinicItemProps extends NavigationInjectedProps {
  item: Clinic;
}

const ClinicListItem: React.FC<ClinicItemProps> = ({ item, navigation }) => {
  const { name, address, operationHours, distanceKm } = item;
  const { t } = useTranslation();

  return (
    <TouchableWithoutFeedback onPress={getNavigate(navigation.navigate, routes.ClinicDetails, { item })}>
      <PruCard style={{ marginHorizontal: 18, marginBottom: 18, marginTop: 6 }}>
        <PruText weight="bold" size={18} color="black">
          {name}
        </PruText>
        <PruText marginTop={12} color="black">
          {address}
        </PruText>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <PruText marginTop={12} color="darkGrey">
              {operationHours?.join('\n')}
            </PruText>
            <PruText marginTop={12} color="darkGrey">{`${distanceKm} km away`}</PruText>
          </View>
          <View style={{ justifyContent: 'flex-end' }}>
            <ActionableText>{t('SEE_MORE')}</ActionableText>
          </View>
        </View>
      </PruCard>
    </TouchableWithoutFeedback>
  );
};

export default withNavigation(ClinicListItem);
