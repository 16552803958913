import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from '../../routes';

import SupportScreenContainer from './SupportScreenContainer';

const routeConfigMap = {
  [routes.Support]: SupportScreenContainer
};

const SupportNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.Support,
    headerMode: 'none'
  })
);

export default SupportNavigator;
