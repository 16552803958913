import withLBUSwitcher from '../../hocs/withLBUSwitcher';
import MyPolicy from './MyPolicy';
// import MyPolicySG from './MyPolicySG';
const LBU_CONFIG = {
  // SG: MyPolicySG,
  SG: MyPolicy,
  TH: MyPolicy,
  PH: MyPolicy,
  MY: MyPolicy,
  MM: MyPolicy
};

export default withLBUSwitcher(LBU_CONFIG);
