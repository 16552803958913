import { ApolloClient, DefaultOptions } from 'apollo-client';
import { InMemoryCache, InMemoryCacheConfig } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Operation } from 'apollo-link';
import { Subscription, Observable } from 'apollo-client/util/Observable';

import { getRefreshToken } from '../reducers/SessionReducer/selectors';
import store, { RootState } from '../store';
import { getApiUrl } from '../config/selector';

export const requestInterceptor = (operation: Operation): void => {
  const rootState: RootState = (store.getState() as unknown) as RootState;

  const refreshToken = getRefreshToken(rootState);
  if (refreshToken){
    operation.setContext({
      headers: {
        authorization: `Bearer ${refreshToken}`
      }
    });
  }
};

export const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: Subscription;
      Promise.resolve(operation)
        .then((oper) => requestInterceptor(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));
      return (): void => {
        if (handle) handle.unsubscribe();
      };
    })
);

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});


const responseLogger = new ApolloLink((operation, forward) => {
  console.log(`--- starting request ---`);
  return forward(operation).map((result) => {
    console.info(`headers: ${JSON.stringify(operation.getContext().headers)}`);
    console.info(`query body: ${operation.query.loc?.source.body}`);
    console.info(`response: ${JSON.stringify(operation.getContext().response)}`);
    console.log(`--- ending request ---`);
    return result;
  });
});

const customFetch = (uri: RequestInfo, options: RequestInit): Promise<Response> => {
  return fetch(getApiUrl() || uri, options);
};

const httpLink: ApolloLink = new HttpLink({
  credentials: 'same-origin',
  fetch: customFetch
});



//Disable cache
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

const options: InMemoryCacheConfig = {
  addTypename: false
};

const client = new ApolloClient({
  link: ApolloLink.from([responseLogger, onErrorLink, requestLink, httpLink]),
  cache: new InMemoryCache(options),
  defaultOptions: defaultOptions
});

export default client;
