import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from '../../routes';

import ClaimsSupportOverviewScreen from './ClaimsSupportOverview/ClaimsSupportOverviewScreen';
import ClaimsSupportGuideScreen from './ClaimsSupportGuide/ClaimsSupportGuideScreen';
import ClaimsSupportFAQScreen from './ClaimsSupportFAQ/ClaimsSupportFAQScreen';
import ClaimsSupportContactScreen from './ClaimsSupportContact/ClaimsSupportContactScreen';

const routeConfigMap = {
  [routes.ClaimsSupportOverview]: ClaimsSupportOverviewScreen,
  [routes.ClaimsSupportGuide]: ClaimsSupportGuideScreen,
  [routes.ClaimsSupportFAQ]: ClaimsSupportFAQScreen,
  [routes.ClaimsSupportContact]: ClaimsSupportContactScreen
};

const ClaimsSupportNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.ClaimsSupportOverview,
    headerMode: 'none'
  })
);

export default ClaimsSupportNavigator;
