import React from 'react';
import { useTranslation } from 'react-i18next';

import { PruText } from '@ebsme/pulse-rn-components';
import { View } from 'react-native';
import { FilterClinic, FilterDentalClinic, FilterHospital } from '../../assets/svgs/icons';
import { Trans } from '../../types/trans';
import { getClinicIcon } from '../../utils/hospitalUtil';

interface Props {
  type: string | Trans;
}

const HospitalIcon: React.FC<Props> = ({ type }) => {
  const clinicIcon = getClinicIcon(type?.en || type || '');
  const { t } = useTranslation();
  const SvgHospital = () => {
    if (clinicIcon == 'hospital') {
      return <FilterHospital />;
    } else if (clinicIcon == 'clinic') {
      return <FilterClinic />;
    } else if (clinicIcon == 'dclinic') {
      return <FilterDentalClinic />;
    } else {
      return <FilterHospital />;
    }
  };
  return (
    <View
      style={{
        width: 48,
        height: 48,
        backgroundColor: '#FEEFF0',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 24,
        marginRight: 10
      }}
    >
      {SvgHospital()}
    </View>
  );
};

export default HospitalIcon;
