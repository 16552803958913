import React from 'react';
import { ViewProps } from 'react-native';

import { PruText } from '@ebsme/pulse-rn-components';

import { FieldContainer } from './StaticFieldStyles';

interface Props extends ViewProps {
  label: string;
  value: string;
  bottomSeparator?: boolean;
  onPressValue?: () => void;
}

const fieldPadding = 20;

const StaticField: React.FC<Props> = ({ label, value, bottomSeparator, onPressValue, ...viewProps }) => {
  return (
    <FieldContainer bottomSeparator={bottomSeparator} {...viewProps}>
      <PruText color="red" weight="bold" marginTop={fieldPadding}>
        {label}
      </PruText>
      <PruText
        color="black"
        size={18}
        weight="bold"
        underline={Boolean(onPressValue)}
        marginTop={10}
        marginBottom={fieldPadding}
        onPress={onPressValue}
      >
        {value}
      </PruText>
    </FieldContainer>
  );
};

export default StaticField;
