import { getBankAccountsLoading, getBankAccountsFetchError } from './../../reducers/BankAccountReducer/selectors';
import { fetchBankAccounts } from './../../reducers/BankAccountReducer/actions';
import { connect } from 'react-redux';

import BankAccountsScreen from './BankAccountsScreen';
import { getBankAccounts } from '../../reducers/BankAccountReducer/selectors';
import { RootState } from '../../store';

export default connect(
  (state: RootState) => ({
    bankAccounts: getBankAccounts(state),
    loading: getBankAccountsLoading(state),
    fetchError: getBankAccountsFetchError(state)
  }),
  {
    fetchBankAccounts
  }
)(BankAccountsScreen);
