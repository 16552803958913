import styled from 'styled-components/native';

import { red, white } from '../../../assets/colors';
import { getShadowStyles } from '../../../assets/mixins';

const cardShadow = getShadowStyles({ offset: '0px 5px', elevation: 5 });

export const Card = styled.TouchableOpacity`
  display: flex;
  min-height: 120px;
	margin-top: 20px;
	margin-bottom: 20px;
  padding: 10px 20px;
	border-radius: 8px;
	border-color: ${white}
  background-color: ${white};
  ${cardShadow}
`;
export const StatusRow = styled.View`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  margin-top: 2px;
`;

export const Row = styled.View`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const LeftContent = styled.View`
  flex: 2;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
`;

export const RightContent = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
`;

export const ArrowIcon = styled.View`
  position: absolute;
  bottom: -14px;
  right: 18px;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: ${red};
  align-items: center;
  justify-content: center;
`;

export const ArrowIconChevron = styled.Image`
  width: 5px;
  height: 10px;
  resize-mode: cover;
`;
