import { connect } from 'react-redux';

import { RootState } from '../../store';
import withSDKLoader from '../../hocs/withSDKLoader';
import { getI18nReady, getSDKLocale } from '../../reducers/SDKReducer/selectors';
import { setPulseUser } from '../../reducers/PulseUserReducer/actions';
import { setI18nReady, setSDKLocale } from '../../reducers/SDKReducer/actions';
import { getPulseUser } from '../../reducers/PulseUserReducer/selectors';
import StoreHydrator from './StoreHydrator';
import { clearRefreshToken } from '../../reducers/SessionReducer/actions';

export default connect(
  (state: RootState) => ({
    i18nReady: getI18nReady(state),
    storedPulseUser: getPulseUser(state),
    locale: getSDKLocale(state)
  }),
  {
    setPulseUser,
    setI18nReady,
    clearRefreshToken,
    setSDKLocale
  }
)(withSDKLoader(StoreHydrator));
