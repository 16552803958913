import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from '../routes';

import LandingScreenContainer from './Landing/LandingScreenContainer';
import MakeClaimNavigator from './MakeClaimScreens/MakeClaimNavigator';
import ReviewClaimNavigator from './ReviewClaimScreens/ReviewClaimNavigator';
import MyPolicyNavigator from './MyPolicy/MyPolicyNavigator';
import ECardNavigator from './ECard/ECardNavigator';
import RewardsNavigator from './Rewards/RewardsNavigator';
import SupportNavigator from './Support/SupportNavigator';
import ClaimsSupportNavigator from './ClaimsSupport/ClaimsSupportNavigator';
import SeeDoctorNavigator from './SeeDoctor/SeeDoctorNavigator';
import LetterOfGuaranteeNavigator from './LetterOfGuarantee/LetterOfGuaranteeNavigator';
import BankAccountNavigator from './BankAccount/BankAccountNavigator';
import FindHospitalNavigator from './FindHospital/FindHospitalNavigator';

const routeConfigMap = {
  [routes.Landing]: LandingScreenContainer,
  [routes.MakeClaim]: MakeClaimNavigator,
  [routes.ReviewClaim]: ReviewClaimNavigator,
  [routes.MyPolicy]: MyPolicyNavigator,
  [routes.ECard]: ECardNavigator,
  [routes.Rewards]: RewardsNavigator,
  [routes.SeeDoctor]: SeeDoctorNavigator,
  [routes.LetterOfGuarantee]: LetterOfGuaranteeNavigator,
  [routes.BankAccount]: BankAccountNavigator,
  [routes.Support]: SupportNavigator,
  [routes.ClaimsSupport]: ClaimsSupportNavigator,
  [routes.FindHospital]: FindHospitalNavigator
};

const HomeStackNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.Landing,
    headerMode: 'none'
  })
);

export default HomeStackNavigator;
