import { connect } from 'react-redux';

import { fetchBanks } from './../../reducers/BankAccountReducer/actions';
import BankAccountCreateScreen from './BankAccountCreateScreen';
import { getBanks, getBanksLoading, getBanksFetchError } from '../../reducers/BankAccountReducer/selectors';
import { RootState } from '../../store';

export default connect(
  (state: RootState) => ({
    banks: getBanks(state),
    loading: getBanksLoading(state),
    fetchError: getBanksFetchError(state)
  }),
  { fetchBanks }
)(BankAccountCreateScreen);
