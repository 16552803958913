import { ECard } from '../types/ecard';

const MOCK_E_CARD: {
  self: ECard;
  dependents: ECard[];
} = {
  self: {
    company: 'ABC Pte Ltd',
    policy: 'PRU00001',
    name: 'John Peter',
    effectiveDate: '1 Jan 2020',
    benefit: 'GP(Panel) - Copayment'
  },
  dependents: [
    {
      company: 'ABC Pte Ltd',
      policy: 'PRU00034',
      name: 'Paul Peter',
      effectiveDate: '1 Jan 2020',
      benefit: 'GP(Panel) - Copayment'
    },
    {
      company: 'ABC Pte Ltd',
      policy: 'PRU00039',
      name: 'Isabella Rose',
      effectiveDate: '1 Jan 2020',
      benefit: 'GP(Panel) - Copayment'
    },
    {
      company: 'ABC Pte Ltd',
      policy: 'PRU00042',
      name: 'Sophia Peter',
      effectiveDate: '1 Jan 2020',
      benefit: 'GP(Panel) - Copayment'
    }
  ]
};

export const getSelfECard = (data = MOCK_E_CARD): ECard => data.self;
export const getDependentsECard = (data = MOCK_E_CARD): ECard[] => data.dependents;
