import React, { useEffect, useRef, useState } from 'react';
import { ScrollView } from 'react-native';

import * as routes from '../../../routes';
import { StackNavigationProp } from '../../../types/navigation';

import SwitchList from '../../../components/SwitchList';
import MakeClaimView from '../MakeClaimView';
import { useTranslation } from 'react-i18next';
import { ClaimTypeOption, ClaimSubTypeOption, Claim, ClaimSubTypes } from '../../../types/claim';
import { Policy } from '../../../types/policy';
import {
  getClaimTypeOptions,
  getKeyForClaimType,
  getKeyForClaimSubType,
  getOriginalRequired
} from '../../../utils/claim';
import { useLBUTranslation } from '../../../hooks/useLBUTranslation';
import { UserCountryCode } from '../../../types/user';

export interface Props {
  lbu: UserCountryCode;
  navigation: StackNavigationProp;
  policy?: Policy;
  claim: Claim;
  claimTypes: ClaimTypeOption[];
  updateClaim?: (claim: Claim) => void;
}

const ClaimTypeScreen: React.FC<Props> = ({
  lbu,
  navigation,
  policy,
  claim,
  claimTypes,
  updateClaim = (): void => {}
}) => {
  const { t } = useTranslation();
  const { tLBU } = useLBUTranslation(lbu);
  const { claimType, claimSubType } = claim;
  const [subTypes, setSubTypes] = useState<ClaimSubTypes | undefined>();
  const [hasUnselectedSubTypes, setHasUnselectedSubTypes] = useState<boolean>(false);

  const scrollRef = useRef<ScrollView>(null);
  const CLAIM_TYPES: ClaimTypeOption[] = claimTypes ? claimTypes : getClaimTypeOptions(lbu, policy);

  const localizeOptionLabel = (options: ClaimTypeOption[], keyExtractor: (id: string) => string): ClaimTypeOption[] => {
    return options.map((option) => {
      option.label = tLBU(option.id, { fallbackValue: option.label, keyExtractor });
      return option;
    });
  };

  const items: ClaimTypeOption[] = localizeOptionLabel(CLAIM_TYPES, getKeyForClaimType);
  const subItems: ClaimTypeOption[] = subTypes ? localizeOptionLabel(subTypes.options, getKeyForClaimSubType) : [];

  useEffect(() => {
    if (!claimType) {
      setSubTypes(undefined);
      setHasUnselectedSubTypes(false);
    }
  }, [claimType]);

  const onClaimTypeSelect = (claimTypeOption: ClaimTypeOption): void => {
    const { id, subTypes } = claimTypeOption;
    const originalRequired = getOriginalRequired(lbu, id).length > 0;
    if (subTypes) {
      setTimeout(() => {
        setSubTypes(subTypes);
        scrollRef.current?.scrollTo({ x: 0, y: 0 });
      }, 200);
      setHasUnselectedSubTypes(true);
    }

    updateClaim({
      ...claim,
      claimType: id,
      claimSubType: '',
      originalRequired
    });
  };

  const onClaimSubTypeSelect = (claimSubTypeOption: ClaimSubTypeOption): void => {
    const { id } = claimSubTypeOption;
    const originalRequired = getOriginalRequired(lbu, claimType, id).length > 0;
    updateClaim({
      ...claim,
      claimSubType: id,
      originalRequired
    });
    setHasUnselectedSubTypes(false);
  };

  const onSubStepBack =
    subTypes &&
    ((): void => {
      updateClaim({
        ...claim,
        claimType: '',
        claimSubType: ''
      });
    });

  return (
    <MakeClaimView
      title={subTypes ? t(subTypes.label) : t('CLAIMS__CLAIM_TYPE__TITLE')}
      currentRoute={routes.ClaimTypeScreen}
      nextDisabled={hasUnselectedSubTypes || !claimType}
      navigation={navigation}
      scrollRef={scrollRef}
      onSubStepBack={onSubStepBack}
    >
      {subItems.length ? (
        <SwitchList items={subItems} selectedItem={claimSubType} onPress={onClaimSubTypeSelect} />
      ) : (
        <SwitchList items={items} selectedItem={claimType} onPress={onClaimTypeSelect} />
      )}
    </MakeClaimView>
  );
};

export default ClaimTypeScreen;
