import { Platform, View, TouchableOpacity } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CorporateUser, PulseUser, UserCountryCode, OTPTYPE, Credential, PulseConfig } from '../../../types/user';
import { ActionCallback } from '../../../types/utils';
import { StackNavigationProp } from '../../../types/navigation';
import * as routes from '../../../routes';
import Onboarding from '../../../assets/svgs/banners/Onboarding.svg';
import { PruButton, PruInput, PruText, PruCheckBox, PruErrorMessage } from '@ebsme/pulse-rn-components';
import RNSInfo from '../../../components/SensitiveInfo';

import OnboardingView from '../OnboardingView';
import { ButtonContainer, RememberContainer } from './OnboardingEmailScreenStyles';
import OnboardingAgreement from './OnboardingAgreement';

import { getObjValueByPath } from '../../../utils/object';
import { LBU } from '../../../config';
import { useLBUTranslation } from '../../../hooks/useLBUTranslation';

import ReactNativeBiometrics from 'react-native-biometrics';
import { getItem, setItem } from '../../../utils/storage';
import { decryptString, decryptUser, encryptString } from '../../../utils/encrypt';
import Icon from 'react-native-vector-icons/Ionicons';
import {
  EVENT_ANALYTICS,
  OBJECT_ID_CLICK,
  logFirebaseEventClick,
  logFirebaseEventScreen
} from '../../../utils/analytics';

type Item = {
  id: string;
  label?: string;
};

const listOtpType: Item[] = [
  {
    id: 'email',
    label: OTPTYPE.EMAIL
  },
  {
    id: 'phone',
    label: OTPTYPE.PHONE
  }
];

interface Props {
  navigation: StackNavigationProp;
  pulseUser?: PulseUser;
  corporateUser?: CorporateUser;
  email?: string;
  phone?: string;
  nationalId?: string;
  userCountryCode: UserCountryCode;
  fetchingCorporateUser: boolean;
  corporateUserNotFound: boolean;
  fetchCorporateUserError?: string;
  registeringCorporateUser: boolean;
  registerCorporateUserError?: string;
  pulseConfig?: PulseConfig;
  fetchingPulseConfig: boolean;
  registerCorpUser: (
    pulseUser: PulseUser,
    email: string,
    nationalId: string,
    phone: string,
    softToken: string | undefined,
    callback: ActionCallback,
    callbackOtpSkip?: ActionCallback
  ) => void;
  setSDKLoading: (loading: boolean) => void;
  back2PulseAction: (value: boolean) => void;
  fetchPulseConfig: () => void;
  setSDKLocale: (locale: Locale) => void;
}

const OnboardingEmailScreen: React.FC<Props> = ({
  navigation,
  pulseUser,
  userCountryCode,
  fetchingCorporateUser,
  fetchCorporateUserError,
  registeringCorporateUser,
  registerCorporateUserError,
  pulseConfig,
  registerCorpUser,
  setSDKLoading,
  back2PulseAction,
  fetchPulseConfig,
  fetchingPulseConfig,
  setSDKLocale
}) => {
  const { t } = useTranslation();
  const { tLBU } = useLBUTranslation(userCountryCode);
  const [emailState, setEmailState] = useState('');
  const [phoneState, setPhoneState] = useState('');
  const [nationalIdState, setNationalIdState] = useState('');
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [phoneValidationError, setPhoneValidationError] = useState(false);
  const [nationalIdValidationError, setNationalIdValidationError] = useState(false);
  const [selectedOtpType, setSelectedOtpType] = useState<Item>(listOtpType[0]);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showRememberMe, setShowRememberMe] = useState<boolean>(false);
  const [showForget, setShowForget] = useState<boolean>(false);
  const [showScanButton, setShowScanButton] = useState<boolean>(false);
  const showRememberMeRef = useRef(false);
  // Toggle app loader
  useEffect(() => {
    setSDKLoading(fetchingCorporateUser || registeringCorporateUser || fetchingPulseConfig);
  }, [setSDKLoading, fetchingCorporateUser, registeringCorporateUser, fetchingPulseConfig]);

  useEffect(() => {
    logFirebaseEventScreen(EVENT_ANALYTICS.login, {
      stage: 'login_info',
      journey: 'login',
      feature: 'Login',
      screen_id: 'SCR_EB_LOGIN_OES',
      screen_name: 'OnboardingEmailScreen'
    });
    // call api get pulse config
    fetchPulseConfig();
    retrieveUserCredentials();
  }, []);

  useEffect(() => {
    if (pulseConfig) {
      setShowRememberMe(pulseConfig.FEATURE_TOGGLE?.showRememberMe);
      setShowForget(pulseConfig.FEATURE_TOGGLE?.showForget || false);
      showRememberMeRef.current = pulseConfig.FEATURE_TOGGLE?.showRememberMe;
    }
  }, [pulseConfig]);

  const checkSoftToken = async (data: Credential | null) => {
    const rememberPrev = await getItem('REMEMBER_ME');
    const activated = await getItem('ACTIVATED_BIOMETRIC');
    let softToken = await RNSInfo.getItem('softToken', {
      sharedPreferencesName: 'sdkSharedPrefs',
      keychainService: 'sdkKeychain'
    });
    if (rememberPrev && softToken && !!activated) {
      softToken = decryptString(softToken);
      try {
        ReactNativeBiometrics.isSensorAvailable().then((resultObject) => {
          const { available, biometryType } = resultObject;
  
          if (available && biometryType === ReactNativeBiometrics.TouchID) {
            console.log('TouchID is supported');
            scanBiometric('TouchID', softToken, data);
            setShowScanButton(true);
          } else if (available && biometryType === ReactNativeBiometrics.FaceID) {
            console.log('FaceID is supported');
            scanBiometric('FaceID', softToken, data);
            setShowScanButton(true);
          } else if (available && biometryType === ReactNativeBiometrics.Biometrics) {
            console.log('Biometrics is supported');
            scanBiometric('Fingerprint', softToken, data);
            setShowScanButton(true);
          } else {
            console.log('Biometrics not supported');
            setShowScanButton(false);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const scanBiometric = (type: string, softToken: string, data: Credential | null) => {
    ReactNativeBiometrics.simplePrompt({
      promptMessage: `${tLBU('BIOMETRICS__PROMT_MESSAGE', { fallbackValue: 'Confirm' })} ${type}`,
      cancelButtonText: tLBU('BIOMETRICS__CANCEL', { fallbackValue: 'Cancel' })
    })
      .then((resultObject) => {
        const { success } = resultObject;

        if (success) {
          if (pulseUser) {
            registerCorpUser(
              pulseUser,
              data ? data.email : emailState,
              data ? data.nationalId : nationalIdState,
              data ? data.phone : phoneState,
              softToken,
              (successRegister) => {
                if (successRegister && !rememberMe) {
                  // remove credentials
                  removeCredentials();
                }
              },
              (callbackOtpSkip) => {
                if (!callbackOtpSkip) {
                  navigation.navigate(routes.OnboardingVerification, {
                    email: data ? data.email : emailState,
                    nationalId: data ? data.nationalId : nationalIdState,
                    phone: data ? data.phone : phoneState,
                    otpType: selectedOtpType.label,
                    rememberMe: showRememberMeRef.current && rememberMe
                  });
                }
              }
            );
          }
        } else {
          console.log('user cancelled biometric prompt');
        }
      })
      .catch(() => {
        console.log('biometrics failed');
      });
  };

  const removeCredentials = async () => {
    try {
      RNSInfo.deleteItem('user_credentials', {
        sharedPreferencesName: 'sdkSharedPrefs',
        keychainService: 'sdkKeychain'
      });
      await setItem('REMEMBER_ME', false);
    } catch (error) {}
  };

  const retrieveUserCredentials = async () => {
    try {
      const data = await RNSInfo.getItem('user_credentials', {
        sharedPreferencesName: 'sdkSharedPrefs',
        keychainService: 'sdkKeychain'
      });

      if (data !== undefined && data !== null) {
        const res: Credential = decryptUser(JSON.parse(data));
        // remove credentials after 90 days
        const dateExpire = moment(res.timestamp).add(90, 'days').valueOf();
        if (moment(new Date()).valueOf() > dateExpire) {
          await removeCredentials();
        } else {
          checkSoftToken(res);
          setEmailState(res.email);
          setPhoneState(res.phone);
          if (res.email && res.email.length > 0) {
            setSelectedOtpType(listOtpType[0]);
          } else if (res.phone && res.phone.length > 0) {
            setSelectedOtpType(listOtpType[1]);
          }
          setNationalIdState(res.nationalId);
          setRememberMe(res.rememberMe);
        }
      }
    } catch (error) {}
  };

  const handleSubmit = (): void => {
    logFirebaseEventClick(EVENT_ANALYTICS.login, {
      stage: 'login_info',
      journey: 'login',
      feature: 'Login',
      screen_id: 'SCR_EB_LOGIN_OES',
      screen_name: 'OnboardingEmailScreen',
      object_id: OBJECT_ID_CLICK.login_submit,
      method: emailState ? 'email' : 'phone'
    });
    const nATIONALIDCHECK = getObjValueByPath(LBU, `${userCountryCode}.NATIONALIDCHECK`);
    const validNationalId = nationalIdState && nATIONALIDCHECK(nationalIdState);
    setNationalIdValidationError(!validNationalId);
    const validEmail = emailState && /^\S+@\S+\.\S+$/.test(emailState);
    setEmailValidationError(selectedOtpType.id === 'email' && !validEmail);

    const validPhone = phoneState && /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phoneState);
    setPhoneValidationError(selectedOtpType.id === 'phone' && !validPhone);

    if (
      pulseUser &&
      validNationalId &&
      !registeringCorporateUser &&
      (selectedOtpType.id === 'email' ? validEmail : validPhone)
    ) {
      registerCorpUser(pulseUser, emailState, nationalIdState, phoneState, undefined, (success) => {
        if (success) {
          navigation.navigate(routes.OnboardingVerification, {
            email: emailState,
            nationalId: nationalIdState,
            phone: phoneState,
            otpType: selectedOtpType.label,
            rememberMe: showRememberMe && rememberMe
          });
        }
      });
    }
  };

  const errorMessage: string | undefined = emailValidationError ? t('ONBOARDING__EMAIL__ERROR_INVALID') : undefined;

  const errorNationalIdMessage: string | undefined = nationalIdValidationError
    ? t('ONBOARDING__NATIONAL_ID__ERROR_INVALID')
    : undefined;

  const errorPhoneMessage: string | undefined = phoneValidationError
    ? t('ONBOARDING__PHONE__ERROR_INVALID')
    : undefined;

  const showGlobalErr =
    (fetchCorporateUserError || registerCorporateUserError) &&
    !emailValidationError &&
    !phoneValidationError &&
    !nationalIdValidationError;
  return (
    <OnboardingView
      headerVisible={Platform.OS !== 'web'}
      back2Home
      background={<Onboarding />}
      onPressBack={(): void => {
        back2PulseAction(true);
        logFirebaseEventClick(EVENT_ANALYTICS.landing, {
          stage: 'N/A',
          journey: 'back_pulse',
          feature: 'Home',
          screen_id: 'SCR_EB_LANDING_LANDS',
          screen_name: 'LandingScreen',
          object_id: OBJECT_ID_CLICK.back2pule
        });
      }}
      showLanguge
      updateLocale={(locale: Locale): void => {
        setSDKLocale(locale);
      }}
    >
      <PruInput
        testID="nationalId"
        value={nationalIdState}
        onChangeText={setNationalIdState}
        label={tLBU('ONBOARDING__NATIONAL_ID__INPUT_LABEL', { fallbackValue: 'Login ID *' })}
        errorMessage={errorNationalIdMessage}
        autoCapitalize="none"
        secureTextEntry={userCountryCode === 'SG' ? true : false}
      />
      {(userCountryCode === 'SG' || userCountryCode === 'PH') && (
        <PruText testID="OnboardingEmailScreen_nationalIdHint" style={{ margin: 5 }} size={11}>
          {tLBU('ONBOARDING__NATIONAL_ID__HINT', { fallbackValue: t('ONBOARDING__NATIONAL_ID__HINT') })}
        </PruText>
      )}
      <PruInput
        testID="email"
        value={emailState}
        onChangeText={(text) => {
          setEmailState(text);
          setPhoneState('');
          setSelectedOtpType(listOtpType[0]);
        }}
        label={`${t('ONBOARDING__EMAIL__INPUT_LABEL')}`}
        errorMessage={errorMessage}
        autoCompleteType="email"
        keyboardType="email-address"
        textContentType="emailAddress"
        autoCapitalize="none"
        containerStyle={{ marginTop: 20 }}
      />
      {userCountryCode !== 'SG' && (
        <View>
          <PruText style={{ margin: 20 }}>{t('ONBOARDING__OR_LABEL')}</PruText>
          <PruInput
            testID="phone"
            value={phoneState}
            onChangeText={(text) => {
              setPhoneState(text);
              setEmailState('');
              setSelectedOtpType(listOtpType[1]);
            }}
            label={`${t('ONBOARDING__PHONE__INPUT_LABEL')}`}
            errorMessage={errorPhoneMessage}
          />
        </View>
      )}
      <RememberContainer>
        {showRememberMe && (
          <PruCheckBox
            testID="OnboardingRemeberMe_CheckBox"
            text={t('ONBOARDING__REMEBERME')}
            checkedIcon="checkbox"
            uncheckedIcon="square-outline"
            value={rememberMe}
            verticalAlign="top"
            style={{ marginTop: 10, alignItems: 'center' }}
            textProps={{ weight: 'normal', children: null, size: 12 }}
            onValueChanged={(value): void => {
              logFirebaseEventClick(EVENT_ANALYTICS.login, {
                feature: 'Login',
                journey: 'login',
                stage: 'login_info',
                screen_id: 'SCR_EB_LOGIN_OES',
                screen_name: 'OnboardingEmailScreen',
                object_id: OBJECT_ID_CLICK.rememberme,
                status: value ? 'checked' : 'unchecked'
              });
              setRememberMe(value);
            }}
          />
        )}
        {userCountryCode === 'PH' && showForget && (
          <TouchableOpacity testID="OnboardingForgetCredentialsButton" style={{ marginTop: 10 }}>
            <PruText
              size={12}
              style={{ color: '#CA1626' }}
              onPress={() => {
                navigation.navigate(routes.OnboardingForget);
              }}
            >
              {`${t('FORGOT_CREDENTIALS')}?`}
            </PruText>
          </TouchableOpacity>
        )}
      </RememberContainer>
      {showGlobalErr && (
        <PruErrorMessage
          errorMessage={tLBU('ONBOARDING__ERROR_ACCOUNT', {
            fallbackValue: 'You are not in Pulse system. Please contact our Call Center 1621.'
          })}
        />
      )}
      {userCountryCode === 'SG' || userCountryCode === 'PH' ? (
        <OnboardingAgreement
          userCountryCode={userCountryCode}
          onPress={handleSubmit}
          showScanButton={showScanButton}
          onPressScan={() => checkSoftToken(null)}
        />
      ) : (
        <ButtonContainer style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <PruButton testID="OnboardingEmailScreen_SubmitButton" onPress={handleSubmit}>
            {t('ONBOARDING__EMAIL__SUBMIT')}
          </PruButton>
          {showScanButton && (
            <TouchableOpacity
              style={{ marginLeft: 10 }}
              testID="OnboardingAgreement_ScanButton"
              onPress={() => checkSoftToken(null)}
            >
              <Icon name="finger-print" size={40} color={'#ED1B2E'} />
            </TouchableOpacity>
          )}
        </ButtonContainer>
      )}
    </OnboardingView>
  );
};

export default OnboardingEmailScreen;
