import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WebView, WebViewMessageEvent } from 'react-native-webview';

import { StackNavigationProp } from '../../types/navigation';
import * as routes from '../../routes';
import { PruText } from '@ebsme/pulse-rn-components';
import Screen from '../../components/Screen';
import { Reward } from '../../reducers/RewardReducer/type';

import { getNavigate } from '../../utils/navigation';

type Props = {
  navigation: StackNavigationProp;
  fetchingReward?: boolean;
  reward?: Reward;
  fetchError?: string;
  fetchReward: () => void;
};

const RewardsScreen: React.FC<Props> = ({ navigation, fetchReward, fetchingReward, fetchError, reward }) => {
  const { t, i18n } = useTranslation();
  const webViewRef = useRef<WebView>(null);
  const [canGoBack, setCanGoBack] = useState<boolean>(false);
  useEffect(() => {
    fetchReward();
  }, [fetchReward]);

  const onPressBack = (): void => {
    const webView = webViewRef.current;
    if (webView && canGoBack) {
      webViewRef.current?.goBack();
      return;
    }
    // if webview can't go back so navigate to home page
    navigation.navigate(routes.Landing);
  };

  const onNavigationStateChange = (navState: { canGoBack: React.SetStateAction<boolean> }): void => {
    setCanGoBack(navState.canGoBack);
  };

  const onWebViewMessage = (event: WebViewMessageEvent): void => {
    const nativeEvent = event.nativeEvent;
    try {
      const nativeEventData = JSON.parse(nativeEvent.data);
      if (nativeEventData.action === 'ROUTE_CHANGED') {
        setCanGoBack(nativeEvent.canGoBack);
      } else if (nativeEventData.action === 'DOWNLOAD_VOUCHER') {
        getNavigate(navigation.navigate, routes.PDFViewScreen, {
          uri: JSON.stringify(nativeEventData.payload),
          enableShare: true
        })();
      }
    } catch (e) {
      return;
    }
  };

  return (
    <Screen
      heading={t('LANDING__REWARDS')}
      headingPosition="header"
      headingColor="white"
      containerType="View"
      onPressBack={onPressBack}
    >
      {fetchingReward ? (
        <PruText size={24} marginTop={40} marginBottom={40} align="center" testID="RewardScreen__Loading">
          {t('REWARDS__FETCHING')}
        </PruText>
      ) : fetchError ? (
        <PruText size={20} marginTop={40} marginBottom={40} testID="RewardScreen__Error">
          Error: {fetchError}
        </PruText>
      ) : reward && reward.redemptionUrl ? (
        <WebView
          ref={webViewRef}
          source={{
            uri: `${reward.redemptionUrl}?lng=${i18n?.language === 'th' ? 'th' : 'en'}`
          }}
          onMessage={onWebViewMessage}
          style={{ marginTop: 2, flex: 1 }}
          onNavigationStateChange={onNavigationStateChange}
        />
      ) : (
        <PruText
          style={{ alignSelf: 'stretch', textAlign: 'center' }}
          size={20}
          marginTop={40}
          marginBottom={40}
          testID="RewardScreen__Error"
        >
          {t('REWARDS__ERROR__MESSAGE')}
        </PruText>
      )}
    </Screen>
  );
};

export default RewardsScreen;
