import React, { ReactNode } from 'react';
import { TouchableWithoutFeedback, ViewProps, ViewStyle } from 'react-native';

import Warning from '../../assets/svgs/icons/Warning.svg';

import { PruText } from '@ebsme/pulse-rn-components';

import { Container, ErrorContainer, ErrorMessageContainer } from './FieldStyles';

interface Props extends ViewProps {
  children: React.ReactNode;
  errorMessage?: string;
  containerTestID?: string;
  style?: ViewStyle;
  icon?: ReactNode;
  onPress?: () => void;
}

const FieldWrapper: React.FC<Props> = ({ children, errorMessage, containerTestID, style, onPress, ...props }) => {
  return (
    <>
      <TouchableWithoutFeedback disabled={!onPress} onPress={onPress}>
        <Container error={Boolean(errorMessage)} style={style} testID={containerTestID} {...props}>
          {children}
        </Container>
      </TouchableWithoutFeedback>
      {errorMessage && (
        <ErrorContainer>
          <Warning width="16" height="16" />
          <ErrorMessageContainer>
            <PruText size={12} weight="semiBold" color="darkRed">
              {errorMessage}
            </PruText>
          </ErrorMessageContainer>
        </ErrorContainer>
      )}
    </>
  );
};

export default FieldWrapper;
