import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';

import { PruText } from '@ebsme/pulse-rn-components';

import { HeaderButtonContainer } from './MakeClaimScreensStyles';

export interface MakeClaimViewSaveButtonProps {
  onPress: () => void;
  disabled?: boolean;
}

const MakeClaimViewSaveButton: React.FC<MakeClaimViewSaveButtonProps> = ({ onPress, disabled }) => {
  const { t } = useTranslation();

  return (
    <TouchableOpacity
      disabled={disabled}
      testID="MakeClaimViewSaveButton"
      style={{ marginEnd: 8 }}
      onPress={onPress}
      accessible
      accessibilityRole="button"
      accessibilityLabel={t('BUTTON__SAVE')}
      accessibilityHint="Cancel this process and go back"
    >
      <HeaderButtonContainer>
        <PruText weight="bold" color="black">
          {t('BUTTON__SAVE')}
        </PruText>
      </HeaderButtonContainer>
    </TouchableOpacity>
  );
};

export default MakeClaimViewSaveButton;
