import styled from 'styled-components/native';

export const TilesContainer = styled.View`
  padding-horizontal: 12px;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const LongTilesContainer = styled.View`
  min-height: 300px;
  margin-horizontal: 24px;
  margin-bottom: 16px;
`;
