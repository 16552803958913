import React, { useState, ReactElement, useEffect } from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { Card } from 'react-native-elements';
import { useTranslation } from 'react-i18next';

import * as routes from '../../../routes';
import { StackNavigationProp } from '../../../types/navigation';
import { Claim, ClaimDocumentGroup as ClaimDocumentGroupType, ClaimDocument, ClaimState } from '../../../types/claim';
import {
  getClaimTypes,
  getClaimSubTypes,
  getClaimDocuments,
  getDocumentList,
  getOriginalDocuments,
  getKeyForClaimType,
  getKeyForClaimSubType,
  getClaimTypeOption
} from '../../../utils/claim';
import { UserCountryCode } from '../../../types/user';

import ClaimDocumentGroup from '../../../components/Claim/ClaimDocumentGroup';
import DocumentView from '../../../components/Claim/DocumentView';
import { PruButton, PruText, PruCheckBox } from '@ebsme/pulse-rn-components';
import MakeClaimView from '../MakeClaimView';
import { isProd, isUat } from '../../../config/selector';
import { useLBUTranslation } from '../../../hooks/useLBUTranslation';
import FinalSummaryScreenDeclaration from './FinalSummaryScreenDeclaration';
import { grey } from '../../../assets/colors';
import Modal from 'react-native-modal';
import { BehaviorSubject } from 'rxjs';

import { logFirebaseEventScreen, logFirebaseEventBackend } from '../../../utils/analytics';

export interface Props {
  lbu: UserCountryCode;
  navigation: StackNavigationProp;
  loading: boolean;
  claim: Claim;
  policyId: string;
  locale: string;
  error?: string;
  submitClaimForProcess: (partialClaim: Claim, callback: (claimNumber?: string) => void) => void;
  resetClaim: (claimState?: ClaimState) => void;
}

export const renderClaimDocumentGroups = (
  claimDocuments: ClaimDocumentGroupType[],
  setSelectedItem?: (document: ClaimDocument) => void
): ReactElement[] => {
  return claimDocuments.map((document) => (
    <ClaimDocumentGroup key={document.docType} document={document} onDocumentView={setSelectedItem} />
  ));
};

type NavigationPayload = {
  nextRoute: string;
  claimNumber: string;
  documentsRequired: string[];
  lbu: UserCountryCode;
};

type ModalState = {
  isVisible: boolean;
  payload?: NavigationPayload;
};

const ModalState = new BehaviorSubject<ModalState>({
  isVisible: false
});

const closeModal = () => {
  ModalState.next({
    ...ModalState.value,
    isVisible: false
  });
};

const openModal = (payload: NavigationPayload) => {
  ModalState.next({
    payload,
    isVisible: true
  });
};

const FinalSummaryScreen: React.FC<Props> = ({
  lbu,
  navigation,
  claim,
  policyId,
  resetClaim,
  loading,
  error,
  submitClaimForProcess
}) => {
  const { t } = useTranslation();
  const { tLBU } = useLBUTranslation(lbu);
  const [selectedItem, setSelectedItem] = useState<ClaimDocument | undefined | null>(null);
  const { claimantName, claimType, claimSubType, originalRequired, documents: uploadedDocuments, bankAccount } = claim;
  const { id: claimTypeId, label: claimTypeLabel } =
    getClaimTypeOption(lbu, claimType || '') || getClaimTypes(claimType);
  const { id: claimSubTypeId, label: claimSubTypeLabel } = getClaimSubTypes(claimType, claimSubType);
  const [declarationVisible, setDeclarationVisible] = useState(false);
  const [payloadModal, setPayloadModal] = useState<ModalState>({ isVisible: false });

  const [termsAgreed, setTermsAgreed] = useState(lbu !== 'SG' && lbu !== 'TH');
  const [disableClick, setDisableClick] = useState(false);

  useEffect(() => {
    logFirebaseEventScreen('eb_make_claim', {
      feature: 'MakeClaim',
      journey: 'make_claim',
      stage: 'claim_summary',
      screen_id: 'SCR_EB_CLAIM_FSC',
      screen_name: 'FinalSummaryScreen'
    });
    const sub = ModalState.subscribe((state) => {
      setPayloadModal(state);
    });
    return (): void => {
      sub.unsubscribe();
    };
  }, []);

  const claimDocuments: ClaimDocumentGroupType[] = getClaimDocuments(lbu, claimType, claimSubType, bankAccount)
    .map((dType) => {
      const { docType, title, fileName, helpText } = getDocumentList(dType);
      const filteredDocuments = uploadedDocuments.filter(({ docType: dcType }) => dcType === docType);
      return {
        docType,
        title,
        fileName,
        helpText,
        documents: filteredDocuments
      };
    })
    .filter((document) => document.documents.length > 0);

  const onSubmitClaim = (): void => {
    setDisableClick(true);
    const updatedDocuments = claimDocuments.reduce((result: ClaimDocument[], { docType, documents }) => {
      return [
        ...result,
        ...documents.map((d) => ({
          docType,
          fileName: d.fileName,
          fileType: d.fileType,
          fileBlobURL: d.fileBlobURL
        }))
      ];
    }, []);

    const partialClaim: Claim = {
      ...claim,
      policyId,
      claimType,
      claimSubType,
      originalRequired,
      documents: updatedDocuments
    };
    submitClaimForProcess(partialClaim, (claimNumber) => {
      if (claimNumber) {
        logFirebaseEventBackend('eb_make_claim', {
          feature: 'MakeClaim',
          journey: 'make_claim',
          stage: 'claim_summary',
          screen_id: 'SCR_EB_CLAIM_FSC',
          screen_name: 'FinalSummaryScreen',
          status: 'success',
          claim_type: claimType
        });

        const documentsRequired: string[] = getOriginalDocuments(lbu, claimType || '', claimSubType || '');
        const nextRoute = originalRequired ? routes.NextStepScreen : routes.ConfirmationScreen;
        if (lbu === 'PH') {
          openModal({
            nextRoute: routes.Landing,
            claimNumber: claimNumber,
            documentsRequired: documentsRequired,
            lbu: lbu
          });
        } else {
          navigation.navigate(nextRoute, {
            claimNumber,
            documentsRequired,
            lbu
          });
          resetClaim();
        }
      } else {
        logFirebaseEventBackend('eb_make_claim', {
          feature: 'MakeClaim',
          journey: 'make_claim',
          stage: 'claim_summary',
          screen_id: 'SCR_EB_CLAIM_FSC',
          screen_name: 'FinalSummaryScreen',
          status: 'fail',
          claim_type: claimType
        });
        setDisableClick(false);
      }
    });
  };

  const onDocumentClose = (): void => setSelectedItem(null);

  return (
    <MakeClaimView
      title={t('CLAIMS__SUMMARY__TITLE_NEW')}
      currentRoute={routes.FinalSummaryScreen}
      navigation={navigation}
      enableSave
      backVisible
    >
      {selectedItem ? <DocumentView document={selectedItem} onClose={onDocumentClose} /> : null}
      <Card
        containerStyle={{
          margin: 0,
          marginBottom: 20,
          marginTop: 10,
          elevation: 15,
          shadowOpacity: 0.1,
          shadowRadius: 15,
          shadowColor: '#ccc',
          shadowOffset: {
            width: 0,
            height: 9
          }
        }}
      >
        <View
          style={{
            margin: 10
          }}
        >
          <PruText size={20} weight="semiBold" marginBottom={10}>
            {t('CLAIMS__CLAIM_TYPE__TITLE_SINGLE')}
          </PruText>
          {!!claimTypeId && (
            <PruText size={14} weight="semiBold" marginBottom={10}>
              {tLBU(claimTypeId, { fallbackValue: claimTypeLabel, keyExtractor: getKeyForClaimType })}
            </PruText>
          )}
          {!!claimSubTypeId && (
            <PruText size={14} weight="semiBold" marginBottom={10}>
              {tLBU(claimSubTypeId, { fallbackValue: claimSubTypeLabel, keyExtractor: getKeyForClaimSubType })}
            </PruText>
          )}

          <PruText size={20} weight="semiBold" marginTop={12} marginBottom={10}>
            {t('CLAIMS__CLAIMANT_VIEW__TITLE')}
          </PruText>
          <PruText size={14} weight="semiBold" marginBottom={32}>
            {claimantName ?? 'You'}
          </PruText>

          <PruText size={20} weight="semiBold" marginBottom={10}>
            {t('CLAIMS__POLICY_NUMBER__TITLE')}
          </PruText>
          <PruText size={14} weight="semiBold" marginBottom={10}>
            {claim.lifeAssuredCertificateNo ?? 'Coming Soon'}
          </PruText>

          {lbu === 'TH' && (
            <>
              <PruText size={20} weight="semiBold" marginBottom={10} marginTop={22}>
                {t('CLAIMS__CLAIM_DETAILS_HOSPITAL')}
              </PruText>
              <PruText size={14} weight="semiBold" marginBottom={32}>
                {claim.hospitalName}
              </PruText>
              <PruText size={20} weight="semiBold" marginBottom={10}>
                {t('CLAIMS__CLAIM_DETAILS_DIAGNOSIS')}
              </PruText>
              <PruText size={14} weight="semiBold" marginBottom={32}>
                {claim.diagnosisDescription}
              </PruText>
              <PruText size={20} weight="semiBold" marginBottom={10}>
                {t('CLAIMS__CLAIM_TOTAL_AMOUNT')}
              </PruText>
              <PruText size={14} weight="semiBold" marginBottom={10}>
                {`${claim.claimAmount} ${t('CLAIMS__CLAIM_CURRENCY_BAHT')}`}
              </PruText>
            </>
          )}

          <View
            style={{
              borderBottomColor: '#D3DADD',
              borderBottomWidth: 1,
              marginBottom: 20,
              marginTop: 20
            }}
          />
        </View>
        <View
          style={{
            margin: 10
          }}
        >
          <PruText size={20} marginBottom={10}>
            {t('CLAIMS__SUMMARY__DOCUMENTS_NEW')}
          </PruText>
          {renderClaimDocumentGroups(claimDocuments, setSelectedItem)}
        </View>
        {lbu === 'PH' && (
          <Modal
            isVisible={payloadModal.isVisible}
            backdropTransitionOutTiming={0}
            style={{ marginHorizontal: 20, minWidth: 300 }}
            useNativeDriver
          >
            <View
              style={{
                paddingHorizontal: 20,
                backgroundColor: 'white',
                paddingBottom: 25,
                paddingTop: 42,
                alignItems: 'center'
              }}
            >
              <PruText>
                {`${tLBU('CLAIMS__SUBMITTED__SUCCESS_MESSAGE', {
                  fallbackValue: t('CLAIMS__SUBMITTED__SUCCESS_MESSAGE')
                })} ${payloadModal.payload?.claimNumber}.`}
              </PruText>
              <TouchableOpacity
                onPress={(): void => {
                  closeModal();
                  if (payloadModal.payload) {
                    navigation.navigate(payloadModal.payload.nextRoute);
                  }
                  resetClaim();
                }}
                style={{
                  marginTop: 10,
                  height: 30,
                  borderRadius: 15,
                  backgroundColor: '#ED1B2E',
                  justifyContent: 'center',
                  width: 120,
                  alignItems: 'center'
                }}
              >
                <Text style={{ color: 'white', fontSize: 17 }}>{t('BUTTON__OK')}</Text>
              </TouchableOpacity>
            </View>
          </Modal>
        )}
        {lbu === 'SG' && (
          <>
            {declarationVisible && (
              <FinalSummaryScreenDeclaration
                testID="DeclarationModal"
                onClose={(): void => setDeclarationVisible(false)}
                lbu={lbu}
              />
            )}
            {/* <ClaimPaymentInfoView claim={claim} /> */}
            <View
              style={{
                margin: 20
              }}
            >
              <PruCheckBox
                testID="TermsAgreedCheckBox"
                text="By proceeding, you agree to our"
                checkedIcon="checkbox"
                uncheckedIcon="square-outline"
                value={termsAgreed}
                verticalAlign="top"
                textStyle={{ marginBottom: -8 }}
                onValueChanged={(value): void => setTermsAgreed(value)}
              />
              <TouchableOpacity
                testID="DeclarationModalTouchable"
                onPress={(): void => setDeclarationVisible(true)}
                style={{ marginLeft: 34 }}
              >
                <PruText color="red" weight="bold" marginTop={-8}>
                  Declaration, Authorisation and Consent
                </PruText>
              </TouchableOpacity>
            </View>
          </>
        )}

        {lbu === 'TH' && (
          <View
            style={{
              margin: 20
            }}
          >
            <PruCheckBox
              testID="TermsAgreedCheckBox"
              text={t('CLAIMS__CONSENT_AGREE_1')}
              checkedIcon="checkbox"
              uncheckedIcon="square-outline"
              value={termsAgreed}
              verticalAlign="top"
              textStyle={{ marginBottom: -8 }}
              onValueChanged={(value): void => setTermsAgreed(value)}
            />
            <TouchableOpacity
              testID="ConsentModalTouchable"
              onPress={() => navigation.navigate(routes.ConsentScreen, { fromFinal: true, claimType: claimTypeId })}
              style={{ marginLeft: 34 }}
            >
              <PruText color="red" weight="bold" marginTop={-8}>
                {t('CLAIMS__CONSENT_AGREE_2')}
              </PruText>
            </TouchableOpacity>
          </View>
        )}

        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {Boolean(error) && (
            <PruText testID="Error" size={12} weight="semiBold" color="darkRed" marginBottom={12}>
              {!isProd() && !isUat() && error}
            </PruText>
          )}
          <PruButton
            disabled={!termsAgreed || disableClick}
            style={!termsAgreed ? { backgroundColor: grey, borderColor: grey } : {}}
            loading={loading}
            paddingVertical={16}
            paddingHorizontal={64}
            onPress={onSubmitClaim}
            testID="SubmitClaimTrigger"
          >
            {t('BUTTON__SUBMIT_NEW')}
          </PruButton>
        </View>
      </Card>
    </MakeClaimView>
  );
};

export default FinalSummaryScreen;
