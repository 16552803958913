import styled from 'styled-components/native';

import { disableCheckBoxColor, green, disableBGColor, white, grey } from '../../../assets/colors';

interface StyledProps {
  checked?: boolean;
}

const getBackgroundColor = ({ checked }: StyledProps): string => grey;
const getCheckboxColor = ({ checked }: StyledProps): string => (checked ? green : disableCheckBoxColor);

export const StyledCheckedItem = styled.View`
  padding: 16px;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${getBackgroundColor};
`;

export const Checkbox = styled.View`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  background-color: ${getCheckboxColor};
`;
