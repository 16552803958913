export const red = '#ED1B2E';
export const green = '#36B37E';
export const darkRed = '#CA1626';
export const lightRed = '#ffabab';
export const blue = '#3F9AB9';
export const lightBlue = 'rgba(63, 154, 185, 0.05)';
export const darkBlue = '#20365a';

export const white = '#FFFFFF';
export const lightGrey = '#F7F7F7';
export const grey = '#D3DADD';
export const disableBGColor = '#E0E0E0';
export const disableCheckBoxColor = '#BDBDBD';
export const darkGrey = '#68737A';
export const black = '#333333';
export const athensGrey = '#e6eaee';
export const arcticBlast = '#E5EAEF';

export const transGrey = 'rgba(230, 234, 238, 0.4)';
