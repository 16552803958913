import { Action, Dispatch } from 'redux';
import { Claim, ClaimDocument, ClaimState, Consent } from '../../types/claim';
import { uploadClaimDocument, saveClaim, submitClaim, deleteClaim, fetchConsent } from './api';
import { path } from 'ramda';
import { SDKAction, SetSDKException, setSDKException, setSDKLoading } from '../SDKReducer/actions';
import { getValue } from '../../utils/object';
import { getUserCountryCode } from '../PulseUserReducer/selectors';
import { RootState } from '../../store';

import { logFirebaseEventBackend } from '../../utils/analytics';

export const SET_LOADING = 'MakeClaim/SET_LOADING';
export const DELETE_DOCUMENT_ACTION = 'MakeClaim/DELETE_DOCUMENT_ACTION';
export const SET_ERROR = 'MakeClaim/SET_ERROR';
export const SET_INITIAL_CLAIM = 'MakeClaim/SET_INITIAL_CLAIM';
export const SET_CLAIM_VIEW = 'MakeClaim/SET_CLAIM_VIEW';
export const UPDATE_CLAIM = 'MakeClaim/UPDATE_CLAIM';
export const RESET_CLAIM = 'MakeClaim/RESET_CLAIM';
export const UPDATE_CLAIM_DOCUMENT = 'MakeClaim/UPDATE_CLAIM_DOCUMENT';
export const UPLOAD_CLAIM_DOCUMENT_START = 'MakeClaim/UPLOAD_CLAIM_DOCUMENT_START';
export const UPLOAD_CLAIM_DOCUMENT_SUCCESS = 'MakeClaim/UPLOAD_CLAIM_DOCUMENT_SUCCESS';
export const UPLOAD_CLAIM_DOCUMENT_FAILED = 'MakeClaim/UPLOAD_CLAIM_DOCUMENT_FAILED';
export const SAVE_CLAIM_START = 'MakeClaim/SAVE_CLAIM_START';
export const SAVE_CLAIM_SUCCESS = 'MakeClaim/SAVE_CLAIM_SUCCESS';
export const SAVE_CLAIM_FAILED = 'MakeClaim/SAVE_CLAIM_FAILED';
export const SUBMIT_CLAIM_START = 'MakeClaim/SUBMIT_CLAIM_START';
export const SUBMIT_CLAIM_SUCCESS = 'MakeClaim/SUBMIT_CLAIM_SUCCESS';
export const SUBMIT_CLAIM_FAILED = 'MakeClaim/SUBMIT_CLAIM_FAILED';
export const DELETE_CLAIM_START = 'MakeClaim/DELETE_CLAIM_START';
export const DELETE_CLAIM_SUCCESS = 'MakeClaim/DELETE_CLAIM_SUCCESS';
export const DELETE_CLAIM_FAILED = 'MakeClaim/DELETE_CLAIM_FAILED';
export const FETCH_CONSENT_START = 'MakeClaim/FETCH_CONSENT_START';
export const FETCH_CONSENT_SUCCESS = 'MakeClaim/FETCH_CONSENT_SUCCESS';
export const FETCH_CONSENT_FAILED = 'MakeClaim/FETCH_CONSENT_FAILED';

interface SetLoadingAction extends Action {
  type: typeof SET_LOADING;
  payload: boolean;
}
interface DeleteDocumentAction extends Action {
  type: typeof DELETE_DOCUMENT_ACTION;
  payload: ClaimDocument;
}

interface SetErrorAction extends Action {
  type: typeof SET_ERROR;
  payload: string;
}

interface SetInitialClaimAction extends Action {
  type: typeof SET_INITIAL_CLAIM;
  payload: Claim | null;
}

interface UpdateClaimAction extends Action {
  type: typeof UPDATE_CLAIM;
  payload: Claim;
}

interface ResetClaimAction extends Action {
  type: typeof RESET_CLAIM;
  claimState?: ClaimState;
}

interface UploadClaimDocumentStartAction extends Action {
  type: typeof UPLOAD_CLAIM_DOCUMENT_START;
  payload: ClaimDocument;
}

interface UploadClaimDocumentSuccessAction extends Action {
  type: typeof UPLOAD_CLAIM_DOCUMENT_SUCCESS;
  payload: ClaimDocument;
}

interface UpdateClaimDocumentAction extends Action {
  type: typeof UPDATE_CLAIM_DOCUMENT;
  payload: ClaimDocument;
}

interface UploadClaimDocumentFailedAction extends Action {
  type: typeof UPLOAD_CLAIM_DOCUMENT_FAILED;
  payload: { docType: string; message: string };
}

interface SaveClaimStartAction extends Action {
  type: typeof SAVE_CLAIM_START;
}

interface SaveClaimSuccessAction extends Action {
  type: typeof SAVE_CLAIM_SUCCESS;
  payload: Claim | undefined | null;
}

interface SaveClaimFailedAction extends Action {
  type: typeof SAVE_CLAIM_FAILED;
  payload: string;
}

interface SubmitClaimStartAction extends Action {
  type: typeof SUBMIT_CLAIM_START;
}

interface SubmitClaimSuccessAction extends Action {
  type: typeof SUBMIT_CLAIM_SUCCESS;
  payload: Claim | undefined | null;
}

interface SubmitClaimFailedAction extends Action {
  type: typeof SUBMIT_CLAIM_FAILED;
  payload: string;
}

interface DeleteClaimStartAction extends Action {
  type: typeof DELETE_CLAIM_START;
}

interface DeleteClaimSuccessAction extends Action {
  type: typeof DELETE_CLAIM_SUCCESS;
  payload: Claim | undefined | null;
}

interface DeleteClaimFailedAction extends Action {
  type: typeof DELETE_CLAIM_FAILED;
  payload: string;
}

interface FetchConsentStartAction extends Action {
  type: typeof FETCH_CONSENT_START;
}

interface FetchConsentSuccessAction extends Action {
  type: typeof FETCH_CONSENT_SUCCESS;
  payload: Consent[];
}

interface FetchConsentFailedAction extends Action {
  type: typeof FETCH_CONSENT_FAILED;
  payload: string;
}

export type MakeClaimAction =
  | SetSDKException
  | SetLoadingAction
  | SetErrorAction
  | SetInitialClaimAction
  | UpdateClaimAction
  | ResetClaimAction
  | UpdateClaimDocumentAction
  | UploadClaimDocumentStartAction
  | UploadClaimDocumentSuccessAction
  | UploadClaimDocumentFailedAction
  | SaveClaimStartAction
  | SaveClaimSuccessAction
  | SaveClaimFailedAction
  | SubmitClaimStartAction
  | SubmitClaimSuccessAction
  | SubmitClaimFailedAction
  | DeleteClaimStartAction
  | DeleteClaimSuccessAction
  | DeleteClaimFailedAction
  | DeleteDocumentAction
  | FetchConsentStartAction
  | FetchConsentSuccessAction
  | FetchConsentFailedAction;

//dispatchers
export const setLoading =
  (payload: boolean) =>
  (dispatch: Dispatch<SetLoadingAction>): void => {
    dispatch({
      type: SET_LOADING,
      payload
    });
  };

export const setError =
  (payload: string) =>
  (dispatch: Dispatch<SetErrorAction>): void => {
    dispatch({
      type: SET_ERROR,
      payload
    });
  };

export const setInitialClaim =
  (payload: Claim | null) =>
  (dispatch: Dispatch<SetInitialClaimAction>): void => {
    dispatch({
      type: SET_INITIAL_CLAIM,
      payload
    });
  };

export const updateClaim =
  (payload: Claim) =>
  (dispatch: Dispatch<UpdateClaimAction>): void => {
    dispatch({
      type: UPDATE_CLAIM,
      payload
    });
  };

export const deleteDocumentAction = (payload: ClaimDocument): DeleteDocumentAction => ({
  type: DELETE_DOCUMENT_ACTION,
  payload
});

export const resetClaim =
  (claimState?: ClaimState) =>
  (dispatch: Dispatch<ResetClaimAction>): void => {
    dispatch({
      type: RESET_CLAIM,
      claimState
    });
  };

export const updateClaimDocument = (payload: ClaimDocument): UpdateClaimDocumentAction => ({
  type: UPDATE_CLAIM_DOCUMENT,
  payload
});

export const uploadClaimDocumentStart = (payload: ClaimDocument): UploadClaimDocumentStartAction => ({
  type: UPLOAD_CLAIM_DOCUMENT_START,
  payload
});

export const uploadClaimDocumentSuccess = (payload: ClaimDocument): UploadClaimDocumentSuccessAction => ({
  type: UPLOAD_CLAIM_DOCUMENT_SUCCESS,
  payload
});

export const uploadClaimDocumentFailed = (docType: string, message: string): UploadClaimDocumentFailedAction => ({
  type: UPLOAD_CLAIM_DOCUMENT_FAILED,
  payload: {
    docType,
    message
  }
});

export const uploadDocument =
  (document: ClaimDocument, previousDocument?: ClaimDocument) =>
  async (dispatch: Dispatch<MakeClaimAction>): Promise<void> => {
    dispatch(uploadClaimDocumentStart(document));
    dispatch(updateClaimDocument({ ...document, fileContent: undefined, fileBlobURL: undefined }));
    try {
      const res = await uploadClaimDocument(document);

      const filePath = path<string>(['data', 'uploadClaimFile', 'path'], res);

      if (!filePath) {
        logFirebaseEventBackend('eb_make_claim', {
          feature: 'MakeClaim',
          journey: 'make_claim',
          stage: 'upload_doc',
          screen_id: 'SCR_EB_CLAIM_DOCS',
          screen_name: 'DocumentScreen',
          status: 'fail',
          doc_type: document.docType
        });
        throw new Error('No file found in upload document response');
      }

      const uploadedDocument: ClaimDocument = {
        ...document,
        fileBlobURL: filePath
      };

      dispatch(uploadClaimDocumentSuccess(uploadedDocument));
      logFirebaseEventBackend('eb_make_claim', {
        feature: 'MakeClaim',
        journey: 'make_claim',
        stage: 'upload_doc',
        screen_id: 'SCR_EB_CLAIM_DOCS',
        screen_name: 'DocumentScreen',
        status: 'success',
        doc_type: document.docType
      });
    } catch (e) {
      logFirebaseEventBackend('eb_make_claim', {
        feature: 'MakeClaim',
        journey: 'make_claim',
        stage: 'upload_doc',
        screen_id: 'SCR_EB_CLAIM_DOCS',
        screen_name: 'DocumentScreen',
        status: 'fail',
        doc_type: document.docType
      });
      dispatch(setSDKException(e));
      if (document.docType) {
        const errorMessage = getValue(e, 'networkError.result.errors.0.message', e.message);
        dispatch(uploadClaimDocumentFailed(document.docType, errorMessage));
      }
      /**
       * Put back the previous document if exist
       * Otherwise, delete the document placeholder
       */
      if (previousDocument) {
        dispatch(updateClaimDocument(previousDocument));
      } else {
        dispatch(deleteDocumentAction(document));
      }
    }
  };

export const saveClaimStart = (): SaveClaimStartAction => ({ type: SAVE_CLAIM_START });

export const saveClaimSuccess = (payload: Claim): SaveClaimSuccessAction => ({
  type: SAVE_CLAIM_SUCCESS,
  payload
});

export const saveClaimFailed = (payload: string): SaveClaimFailedAction => ({
  type: SAVE_CLAIM_FAILED,
  payload
});

export const submitClaimStart = (): SubmitClaimStartAction => ({ type: SUBMIT_CLAIM_START });

export const submitClaimSuccess = (payload: Claim): SubmitClaimSuccessAction => ({
  type: SUBMIT_CLAIM_SUCCESS,
  payload
});

export const submitClaimFailed = (payload: string): SubmitClaimFailedAction => ({
  type: SUBMIT_CLAIM_FAILED,
  payload
});

export const deleteClaimStart = (): DeleteClaimStartAction => ({ type: DELETE_CLAIM_START });

export const deleteClaimSuccess = (payload: Claim): DeleteClaimSuccessAction => ({
  type: DELETE_CLAIM_SUCCESS,
  payload
});

export const deleteClaimFailed = (payload: string): DeleteClaimFailedAction => ({
  type: DELETE_CLAIM_FAILED,
  payload
});

type SaveClaimCallback = (claim?: Claim) => void;
type SubmitClaimCallback = (claimNumber?: string) => void;
type DeleteClaimCallback = (claimNumber?: string) => void;

export const saveClaimForProcess =
  (partialClaim: Claim, callback?: SaveClaimCallback) =>
  async (dispatch: Dispatch<MakeClaimAction | SDKAction>, getState: () => RootState): Promise<void> => {
    dispatch(setSDKLoading(true));
    dispatch(saveClaimStart());

    const countryCode = getUserCountryCode(getState()) ?? 'PH';
    try {
      const claim = await saveClaim(partialClaim, countryCode);

      dispatch(saveClaimSuccess(claim));

      callback && callback(claim);
    } catch (e) {
      dispatch(setSDKException(e));
      const errorMessage = getValue(e, 'networkError.result.errors.0.message', e.message);

      dispatch(saveClaimFailed(errorMessage));

      callback && callback(undefined);
    }
    dispatch(setSDKLoading(false));
  };

export const submitClaimForProcess =
  (partialClaim: Claim, callback?: SubmitClaimCallback) =>
  async (dispatch: Dispatch<MakeClaimAction>, getState: () => RootState): Promise<void> => {
    dispatch(submitClaimStart());

    const countryCode = getUserCountryCode(getState()) ?? 'PH';
    try {
      const claim = await submitClaim(partialClaim, countryCode);
      if (claim.errorCode && claim.errorCode === 'EE0001'){
        dispatch(setSDKException(new Error('MANDATORY_CLAIMS_DATA_VALIDATION_IS_FAILED')));
        dispatch(submitClaimFailed('MANDATORY_CLAIMS_DATA_VALIDATION_IS_FAILED'));
        callback && callback(undefined);
      }else{
        dispatch(submitClaimSuccess(claim));
        callback && callback(claim.lbuClaimReference || claim.claimNumber);
      }
    } catch (e) {
      dispatch(setSDKException(e));
      dispatch(submitClaimFailed('ERROR_REQUEST'));
      callback && callback(undefined);
    }
  };

export const deleteClaimForProcess =
  (partialClaim: Claim, callback?: DeleteClaimCallback) =>
  async (dispatch: Dispatch<MakeClaimAction>): Promise<void> => {
    dispatch(deleteClaimStart());

    try {
      const claim = await deleteClaim(partialClaim);

      dispatch(deleteClaimSuccess(claim));

      callback && callback(claim.claimNumber);
    } catch (e) {
      dispatch(setSDKException(e));
      dispatch(deleteClaimFailed(e.message));

      callback && callback(undefined);
    }
  };

export const fetchConsentStart = (): FetchConsentStartAction => ({
  type: FETCH_CONSENT_START
});

export const fetchConsentSuccess = (payload: Consent[]): FetchConsentSuccessAction => ({
  type: FETCH_CONSENT_SUCCESS,
  payload
});

export const fetchConsentFailed = (payload: string): FetchConsentFailedAction => ({
  type: FETCH_CONSENT_FAILED,
  payload: payload
});

export const fetchConsentProcess =
  (claimType: string) =>
  async (dispatch: Dispatch<MakeClaimAction>): Promise<void> => {
    dispatch(fetchConsentStart());

    try {
      const response = await fetchConsent(claimType);
      dispatch(fetchConsentSuccess(response));
    } catch (e) {
      dispatch(fetchConsentFailed(e.message));
    }
  };
