import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from '../../routes';

import FindClinicScreen from './FindClinicScreenContainer';
import ClinicDetailsScreen from './ClinicDetailsScreenContainer';
import SeeDoctorMenuScreen from './SeeDoctorMenuScreen';

const routeConfigMap = {
  [routes.FindClinic]: FindClinicScreen,
  [routes.ClinicDetails]: ClinicDetailsScreen,
  [routes.SeeDoctorMenu]: SeeDoctorMenuScreen
};

const SeeDoctorNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.SeeDoctorMenu,
    headerMode: 'none'
  })
);

export default SeeDoctorNavigator;
