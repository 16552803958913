import Screen from '../../../components/Screen';
import React from 'react';
import { FlatList, ListRenderItemInfo, Modal, SafeAreaView } from 'react-native';
import { PruText } from '@ebsme/pulse-rn-components';

interface Props {
  testID?: string;
  onClose?: () => void;
  lbu?: string;
}

const Declarations = [
  `I hereby declare that the information that is disclosed on this form is to the best of my knowledge and belief, true, complete and accurate, and that no material information has been withheld or is any relevant circumstances omitted. I further acknowledge and accept that Prudential Assurance Company Singapore (Pte) Limited ("Prudential") shall be at liberty to deny liability or recover amounts paid, whether wholly or partially, if any of the information disclosed on this form is incomplete, untrue or incorrect in any respect or if the Policy does not provide cover on which such claim is made.`,
  `I hereby warrant and represent that I have been properly authorised by the policyholder and the applicable insured(s) to submit information pertaining to such insured's claims.`,
  `I acknowledge and accept that the furnishing of this form, or any other forms supplemental thereto, by Prudential, is neither an admission that there was any insurance in force on the life in question, nor an admission of liability nor a waiver of any of its rights and defenses.`,
  `I confirm that I have paid in full all the bill(s)/invoice(s)/receipt(s) that I have submitted to Prudential for reimbursement and have not claimed and do not intend to claim from other company(ies)/person(s).`,
  `I agree to produce all original bill(s)/invoice(s)/receipt(s) that were submitted for reimbursement to Prudential for verification as it deems necessary.`,
  `I acknowledge and accept that Prudential expressly reserves its rights to require or obtain further information and documentation as it deems necessary.`,
  `For the purposes as set out in Prudential's Privacy Notice (""Purpose""), including without limitation, the purpose of assessing, processing and investigating my claim(s) arising under the Policy and such other purposes ancillary or related to the assessing, processing and investigating my claim(s) and administering of the Policy, I authorise, agree and consent to:
a. Any person(s) or organisation(s) that has relevant information concerning the policyowner and the insured person(s) (including any medical practitioner, medical/healthcare provider, financial service providers, insurance offices, government authorities/regulators, statutory boards, employer, or investigative agencies) (""Person(s)/Organisation(s)"") pertaining to this claim, to disclose any information to Prudential, its officers, employees, representatives or distribution partners, including without limitation, all personal data, medical information, employment and financial information, including the taking of copies of such records; and
b. Prudential, its officers, employees, representatives or distribution partners collecting, using, and disclosing, personal data about me, the policyowner and the insured person(s), with any Person(s) / Organisation(s) listed in above, Prudential's related group of companies, third party service providers, insurers, reinsurers, suppliers, intermediaries, lawyers/law firms, other financial institutions, law enforcement authorities, dispute resolution centres, debt collection agencies, loss adjustors or other third parties assisting with my claim for the Purpose.`,
  `Where any personal data ("3rd Party Personal Data") relating to another person ("Individual") is disclosed by me, I represent and warrant that I have obtained the consent of the Individual for Prudential, its officers, employees, representatives or distribution partners to collect and use the 3rd Party Personal Data and to disclose the 3rd Party Personal Data to the persons enumerated above, whether in Singapore or elsewhere, for the Purpose stated above and in Prudential's Privacy Notice.`,
  `I agree to indemnify Prudential for all losses and damages that Prudential, its officers, employees, representatives or distribution partners may suffer in the event that I am in breach of any representation and warranty provided to me herein.`,
  `I agree that this (i) Prudential shall have full access to the information stated in this form, and (ii) this authorisation and declaration shall form part of my proposed application for the relevant insurance benefits, and a photocopy of this form shall be treated as valid and binding as if it were the original.`
];

const DeclarationPluk = `
I agree that the written statements and affidavits of the physicians who attended to or treated the Life Assured,and  all  other supporting documents  required by  Pru  Life  UK in  relation  to thisclaim,  shall constitute and be considered as proofof deathor medical condition, as may be applicable. I acknowledgethat, by making this claims facility available, Pru Life UK is not 1) admittingthat there is any insurance in force on the Life Assured or that it is liable forthe payment of any benefit provided in any insurance policy issued by it, and 2) waivingany of its rights or defenses.

I understand and acknowledge that the insurance policy is subject to all laws, regulations, resolutions and guidelines on financial underwriting, anti-money laundering, counter terrorist financing and financial and economic sanctions regimes (“Issuances”). In the event that Pru Life UK is unable to comply with such Issuances,  including  the  relevant  Customer  Due  Diligence("CDD")  measures  required  under  the  Anti-Money Laundering Act, as amended, due to any act or omission on my part, or if required by any of the Issuances, Pru Life UK may deny this claim. I am bound by obligations set out in relevant United Nations Security  Council  Resolutions  relating  to  the  prevention  and  suppression  of  proliferation  financing  of weapons of mass destruction, including the freezing and unfreezing actions as well as prohibitions from conducting transactions with designated persons and entities.

CLAIMANT CERTIFICATE OF AUTHORIZATION

This is to authorize Pru Life UK and/or its duly authorized representatives to secure any and all information or records in relation to the Life Assured that are available from any physician or medical practitioner, or government or private hospitals, clinics, medical facilities or offices. This authorization is being made in connection with  a  claim on  the  insurance  policy or  policies  issued  by Pru  Life  UK  on  the  life  of  the  Life Assured.

It is understood that by virtue of this authorization, any such physician, medical practitioner, government or  private  hospital,  clinic, medical  facility  or office or  any  and  all members of  its  staff  shall  be  released from any responsibility or liability in connection with the release of such records or information.

A facsimile or reproduction of this authorization shall be as effective, valid and binding as the original.

DATA PRIVACY

We will process the information you have provided in this form for the purpose of handling your request in  accordance  with  applicable  privacy  laws  and  regulations.  During  processing,  we  may  share  the information you provided to our authorized data processors, including couriers and contractors for anti-money laundering systems, photocopying, scanning, indexing and printing services. We may share your information  with  governmental  and  other  regulatory  authorities,  or  self-regulatory  bodies  in  various jurisdictions as required or allowed by applicable laws and regulations. Any information collected may be retained  by  Pru  Life  UK  and  our  authorized  data  processors  until  ten  (10)  years  from  the  date  of termination of the policy.

You may revisit our privacy policy through our website at(https://www.prulifeuk.com.ph/en/footer/privacy-policy/). For data privacy concerns, please contact our Data Privacy Officer at:
Telephone:(632) 8887 5433 for Metro Manila, 1 800 10 7785465 via PLDT landline for domestic toll-free
Email:dpo@prulifeuk.com.ph

DATA PRIVACY STATEMENT

For purposes of this Section:

a.“Pru Life UK” shall refer to Pru Life Insurance  Corporation  of  U.K.,  its  directors,  officers, employees,  insurance  agents,  insurance  brokers,  other  agents  and  representatives,  reinsurers, contractors, legal advisers, and Pru Life UK’s subsidiaries, affiliates and other related entities, and their  directors,  officers,  employees,  insurance  agents,  insurance  brokers,  other  agents  and representatives, contractors, and legal advisers.
b.“Data Subject” shall mean the Policyowner, the Life Insured, the Beneficial Owner, Beneficiaries, and all other individuals whose personal information or sensitive personal information is or will be disclosed to Pru Life UK.

Purpose Statement

The information to be provided by you in your application, as indicated in your Policy Info form, will be used  for  data processing  to  be  done  by  Pru  Life  UK  for  theadministration  of  your  insurance  policy, including issuance, handling of insurance coverage and claims, data analytics, any legitimate interest of Pru Life UK, or any purpose permitted or required by applicable law. This processing may be either manual or automated and within or outside of the Philippines.

To  enable  Pru  Life  UK  to  effectively  address  insurance  requirements  and  provide  better  service,  your personal  information  may  also,  upon  your  explicit  consent  (where  required),  be  used  for  processing sensitive  personal  information,  profiling,  automated  decision-making,  and  direct  marketing,  which includes products and other offers.

During  processing,  we  may  share  the  information  you  provided  to  our  authorized  data  processors  to whom  we  outsource  the  processing  of  your  information  for  your  Policy,  including  contractors  for  anti-money laundering systems, claims investigations and processing, and other value-added services.

Our collection and processing of your personal data, including any sensitive personal information, is based on your purchase for insurance and other related services, any contract we may enter into with you, our legitimate interests, or a requirement under applicable law. Any information collected may be retained 
by  Pru  Life  UK  and  our  authorized  data  processors  until  ten  (10)  years  from  the  date  of  maturity  or termination of the Policy or date of denial of this Application, whichever comes earlier.

We may  share  your information with governmental and other regulatory authorities, or self-regulatory bodies  in  various  jurisdictions  as  required  or  allowed  by  applicable  laws  and  regulations,  including  the Medical   Information  Database   administered   by  the   Philippine   Life   Insurance   Association,   Inc.   In accordance with the Insurance Commission’s Circular Letter No. 2016-54, your medical information will be uploaded to a Medical Information Database accessible to life insurance companies for the purpose of enhancing  risk  assessment  and  preventing  fraud.  Once  uploaded,  all  life  insurance  companies will  only have limited access to your information in order to protect your right to privacy in accordance with law. A  copy  of  Circular  Letter  No.  2016-54 may be accessed at the Insurance Commission’s website at http://www.insurance.gov.ph/.

You are entitled to the following rights: Inform, rectify, object, access, erasure or blocking, damages and complaints.  For  more  information  about  your  rights  as  a  data  subject  and  how  we  protect  your information, you may access our privacy policy through our website at https://www.prulifeuk.com.ph/en/footer/privacy-policy/. Should you have any questions or requests in relation  to  the  processing  of  your  personal  or  sensitive  personal  information,  or  your  rights  as  a  data subject you may get in touch with our Data Protection Officer through the following:

•Postal address: 9F Uptown Place Tower 1, 1 East 11th Drive, Uptown Bonifacio, 1634 Taguig City, Metro Manila
•Telephone: (632) 8887 5433 for Metro Manila, 1 800 10 7785465 via PLDT landline for domestic toll-free
•Email: dpo@prulifeuk.com.ph

By proceeding with the application:

•You allow Pru Life UK to use, collect and process your personal information and sensitive personal information as specified in the Purpose Statement above, and in accordance with applicable data privacy regulations.
•You specifically consent to the activities you have checked below:
□ Be informed of Pru Life UK’s products and services via calls, emails or SMS.
□ Use  your  profile  so  that  we  can  geta  deeper  understanding  of  your  preferences  and suggest relevant products and services.
□ Sharing  by  Pru  Life  UK  of  your  personal  information  under  the  Details  of  Policyowner section  of  this  Application  and  your  policy  effective  date  and  premium  amount  with Robinsons Bank to avail of the credit card payment facility for your insurance premiums.

•You warrant  that  the  consent  of  the  Beneficial  Owner  (if  any),  Beneficiaries,  and  all  other  data subjects have been obtained for the use, storage and processing of their personal information for purposes of compliance with regulatory requirements and applicable laws, the processing of this insurance  coverage,  and the  administration of the Policy  issued. You also undertake  to provide Pru Life UK with proof of your authority to give the required consents of the other data subjects with  respect  to  the  disclosure  and  processing  of  their  personal  information  and/or  sensitive personal information for the legitimate purposes set out in this Application or in the Policy issued by Pru Life UK.
•You, acting as legal guardian of your child (under 18 years old), give consent on your child’s behalf to the use, storage and processing of their personal information for purposes of compliance with regulatory requirements and applicable laws, the processing of this insurance coverage, and the administration of the Policy issued.
•You agree to indemnify Pru Life UK and hold it free and harmless from any damages incurred by Pru Life UK as a result of any claim filed by any of the data subjects in relation to a breach of any of  the  warranties  above,  or  for  any  damages  arising  from  any  misrepresentation  made  in  this Application or from any material breach of its provisions.
`

const FinalSummaryScreenDeclaration: React.FC<Props> = ({ testID, onClose, lbu }) => {
  const renderDeclaration = ({ item, index }: ListRenderItemInfo<string>): React.ReactElement => (
    <PruText>
      {index + 1}. {item}
    </PruText>
  );
  const keyExtractor = (e: string, i: number): string => i.toString();
  return (
    <Modal animationType="fade">
      <SafeAreaView style={{ flex: 1 }} testID={testID}>
        <Screen headerPaddingHorizontal={24} headingPosition={'none'} onPressCancel={onClose}>
          {
            lbu === 'SG' ?
              <>
                <PruText color="black" size={28} weight="bold" marginLeft={20}>
                  Declaration,
                </PruText>
                <PruText color="black" size={28} weight="thin" marginLeft={20}>
                  Authorisation and Consent
                </PruText>
                <FlatList
                  style={{ margin: 20, marginBottom: 50 }}
                  data={Declarations}
                  renderItem={renderDeclaration}
                  keyExtractor={keyExtractor}
                />
              </>
              :
              <PruText marginLeft={20}>
                {DeclarationPluk}
              </PruText>
          }
        </Screen>
      </SafeAreaView>
    </Modal>
  );
};

export default FinalSummaryScreenDeclaration;
