import { Action } from 'redux';

import { Clinic } from '../../types/clinic';
import { Coordinate } from '../../types/coordinate';

export const BOOKMARK_CLINIC = 'Preferences/BOOKMARK_CLINIC';
export const UNBOOKMARK_CLINIC = 'Preferences/UNBOOKMARK_CLINIC';
export const SET_CURRENT_LOCATION = 'Preferences/SET_CURRENT_LOCATION';

export interface BookmarkClinicAction extends Action {
  type: typeof BOOKMARK_CLINIC;
  payload: Clinic;
}

export const bookmarkClinic = (clinic: Clinic): BookmarkClinicAction => ({
  type: BOOKMARK_CLINIC,
  payload: clinic
});

export interface UnbookmarkClinicAction extends Action {
  type: typeof UNBOOKMARK_CLINIC;
  payload: Clinic;
}

export const unbookmarkClinic = (clinic: Clinic): UnbookmarkClinicAction => ({
  type: UNBOOKMARK_CLINIC,
  payload: clinic
});

export interface SetCurrentLocationAction extends Action {
  type: typeof SET_CURRENT_LOCATION;
  payload: Coordinate;
}

export const setCurrentLocation = (coordinate: Coordinate): SetCurrentLocationAction => ({
  type: SET_CURRENT_LOCATION,
  payload: coordinate
});

export type PreferencesAction = BookmarkClinicAction | UnbookmarkClinicAction | SetCurrentLocationAction;
