export const chevronLeft = require('./icon-chevronLeft.png');
export const chevronRight = require('./icon-chevronRight.png');
export const chevronRightLarge = require('./icon-chevronRightLarge.png');
export const tickSmall = require('./icon-tickSmall.png');
export const expand = require('./icon-expand.png');
export const collapse = require('./icon-collapse.png');
export const close = require('./icon-close.png');
export const tick = require('./icon-tick.png');
export const tele = require('./icon-tele.png');
export const ic_more_detail = require('./ic_more_detail.png');
export const ic_recommend = require('./ic_recommend.png');
export const ic_directions = require('./ic_directions.png');
export const ic_expand = require('./ic_expand.png');
