import React from 'react';
import { useTranslation } from 'react-i18next';

import { StackNavigationProp } from '../../../types/navigation';
import { openMail, callPhoneNumber } from '../../../utils/platform';
import * as routes from '../../../routes';

import Screen from '../../../components/Screen';
import StaticField from '../../../components/StaticField';

interface Props {
  navigation: StackNavigationProp;
}

const tel = '1800 333 0 333';
const email = 'sgp.es.claims@prudential.com.sg';

const ClaimsSupportContactScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();

  return (
    <Screen
      heading={t('CLAIMSSUPPORT__CONTACT__TITLE')}
      paddingHorizontal={24}
      onPressBack={(): void => {
        navigation.goBack();
      }}
    >
      <StaticField
        label={t('CLAIMSSUPPORT__CONTACT__TEL')}
        value={tel}
        bottomSeparator
        onPressValue={(): void => {
          callPhoneNumber(tel.replace(/ /g, ''));
        }}
      />
      <StaticField
        label={t('CLAIMSSUPPORT__CONTACT__EMAIL')}
        value={email}
        bottomSeparator
        onPressValue={(): void => {
          openMail(email, {
            subject: 'Customer Support Enquiry'
          });
        }}
      />
      <StaticField
        label={t('CLAIMSSUPPORT__CONTACT__OR')}
        value={t('CLAIMSSUPPORT__CONTACT__CHECKOUT_FAQ')}
        onPressValue={(): void => {
          navigation.navigate(routes.ClaimsSupportFAQ);
        }}
      />
    </Screen>
  );
};

export default ClaimsSupportContactScreen;
