import React from 'react';
import { TouchableHighlightProps } from 'react-native';

import { lightGrey } from '../../assets/colors';

import { PruText } from '@ebsme/pulse-rn-components';

import { StyledTile } from './ListTileStyles';

interface Props extends TouchableHighlightProps {
  title: string;
  selected?: boolean;
  onPress: () => void;
}

const ListTile: React.FC<Props> = ({ title, selected = false, onPress, ...touchableProps }) => {
  return (
    <StyledTile
      accessible
      accessibilityLabel={title}
      accessibilityRole="button"
      underlayColor={lightGrey}
      selected={selected}
      onPress={onPress}
      {...touchableProps}
    >
      <PruText color="darkGrey" size={15} weight="semiBold">
        {title}
      </PruText>
    </StyledTile>
  );
};

export default ListTile;
