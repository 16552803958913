import styled from 'styled-components/native';

export const ButtonContainer = styled.View`
  margin-top: 60px;
  align-items: center;
`;
export const RememberContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
