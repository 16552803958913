import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RefreshControl, Alert, Platform } from 'react-native';
import { StackNavigationProp } from '../../types/navigation';
import { Policy, ECareCard } from '../../types/policy';
import withI18n from '../../hocs/withI18n';

import LayoutBox from '../../components/LayoutBox';

import LandingScreenBanner from './LandingScreenBanner';
import LandingScreenTilesContainer from './LandingScreenTiles';
import ShadedContainer from '../../components/ShadedContainer';
import { PulseUser } from '../../types/user';
import ReactNativeBiometrics from 'react-native-biometrics';
import { getItem, setItem } from '../../utils/storage';
import BiometricModal from '../../components/BiometricModal';
import { check, PERMISSIONS, request } from '../../utils/permissions';

interface Props {
  lbu?: string;
  navigation: StackNavigationProp;
  fetchingPolicy?: boolean;
  policy?: Policy;
  eCareCards?: ECareCard[];
  fetchError?: string;
  fetchMyPolicy: () => void;
  fetchDependants: (id: string) => void;
  fetchECareCard: (certificate: string, language: string) => void;
  getPulseUserConfig: (certificate: string) => void;
  setSDKLoading: (loading: boolean) => void;
  pulseUser?: PulseUser;
  locale: string;
  eCareCardLoading: boolean;
  calledEcareCard: boolean;
}

const LandingScreen: React.FC<Props> = ({
  lbu,
  navigation,
  fetchingPolicy = false,
  policy,
  locale,
  eCareCards,
  eCareCardLoading,
  calledEcareCard,
  fetchMyPolicy,
  fetchECareCard,
  setSDKLoading,
  fetchDependants,
  getPulseUserConfig
}) => {
  const [isShowModal, setShowModal] = useState(false);
  const [typeBioMetrics, setTypeBioMetrics] = useState('FaceID');
  useEffect(() => {
    fetchMyPolicy();
  }, [fetchMyPolicy]);

  useEffect(() => {
    setSDKLoading(fetchingPolicy);
  }, [setSDKLoading, fetchingPolicy]);

  useEffect(() => {
    if (policy) {
      fetchDependants(policy.certificateNo || '');
      getPulseUserConfig(policy.certificateNo || '');
    }
  }, [policy]);

  useEffect(() => {
    if (policy) {
      fetchECareCard(policy.certificateNo || '', locale === 'th' ? 'THAI' : locale === 'my' ? 'MMR' : 'ENG');
    }
  }, [policy, locale]);

  useEffect(() => {
    // check biometric and show popup get confirmation
    if (lbu === 'SG' || lbu === 'TH' || lbu === 'PH' || lbu === 'MM') chectBiometric();
  }, []);

  const chectBiometric = async () => {
    const activated = await getItem('ACTIVATED_BIOMETRIC');
    const rememberPrev = await getItem('REMEMBER_ME');
    if (rememberPrev && !activated) {
      try {
        ReactNativeBiometrics.isSensorAvailable().then((resultObject) => {
          const { available, biometryType } = resultObject;
          if (available && biometryType === ReactNativeBiometrics.TouchID) {
            console.log('TouchID is supported');
            showAlert('TouchID');
          } else if (available && biometryType === ReactNativeBiometrics.FaceID) {
            console.log('FaceID is supported');
            showAlert('FaceID');
          } else if (available && biometryType === ReactNativeBiometrics.Biometrics) {
            console.log('Biometrics is supported');
            showAlert('Fingerprint');
          } else {
            console.log('Biometrics not supported');
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const showAlert = (type: string) => {
    if (Platform.OS === 'ios' && type == 'FaceID') {
      check(PERMISSIONS.IOS.FACE_ID)
        .then((response) => {
          if (response !== 'granted') {
            request(PERMISSIONS.IOS.FACE_ID)
              .then((response) => {
                if (response !== 'granted') {
                } else {
                  setShowModal(true);
                  setTypeBioMetrics(type);
                }
              })
              .catch((error) => {});
          } else {
            setShowModal(true);
            setTypeBioMetrics(type);
          }
        })
        .catch((error) => {});
    } else {
      setShowModal(true);
      setTypeBioMetrics(type);
    }
  };

  const { certificateNo, startDate, policyNumber } = policy || {};

  return (
    <LayoutBox
      type="ScrollView"
      refreshControl={
        <RefreshControl
          tintColor="transparent"
          refreshing={fetchingPolicy}
          onRefresh={(): void => {
            fetchMyPolicy();
          }}
        />
      }
      backgroundColor="lightGrey"
    >
      <LandingScreenBanner
        navigation={navigation}
        lbu={lbu}
        eCareCards={eCareCards}
        policyNo={lbu === 'PH' ? policyNumber : certificateNo}
        name={`${policy?.employees && policy?.employees[0].givenName ? policy?.employees[0].givenName : ''} ${
          policy?.employees && policy?.employees[0].middleName ? policy?.employees[0].middleName : ''
        } ${policy?.employees && policy?.employees[0].familyName ? policy?.employees[0].familyName : ''}`}
        policyStartDate={startDate}
        eCareCardLoading={eCareCardLoading}
        calledEcareCard={calledEcareCard}
      />
      <ShadedContainer inverse top={160}>
        <LandingScreenTilesContainer navigation={navigation} />
      </ShadedContainer>
      <BiometricModal
        closeModal={() => setShowModal(false)}
        type={typeBioMetrics}
        isShowModal={isShowModal}
        onActive={async () => {
          await setItem('ACTIVATED_BIOMETRIC', true);
        }}
        lbu={lbu}
      />
    </LayoutBox>
  );
};

export default withI18n(LandingScreen);
