import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.View`
  margin: 0 20px 24px;
`;

export const SearchContainer = styled.View`
  margin-top: 20px;
`;

export const styles = StyleSheet.create({
  containerInput: {
    elevation: 0,
    shadowOpacity: 0,
    shadowRadius: 0,
    shadowOffset: {
      width: 0,
      height: 0
    },
    borderColor: '#E6EBF0',
    borderWidth: 1,
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 0,
    borderRadius: 12
  },
  input: { fontSize: 15, color: '#000000' },
  tabContainer: { paddingVertical: 12, marginHorizontal: 20 },
  buttonTab: {
    height: 36,
    borderRadius: 35,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    minWidth: 50,
    marginRight: 8
  },
  itemContainer: { flexDirection: 'row', marginVertical: 16, paddingHorizontal: 26 },
  image: {
    width: 48,
    height: 48,
    borderRadius: 48
  },
  buttonRecommend: {
    height: 20,
    borderRadius: 20,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FCF1D9',
    flexDirection: 'row',
    marginTop: 8,
    alignSelf: 'baseline'
  },
  textRecommend: { color: '#F4BD43', fontSize: 11 },
  buttonMore: {
    height: 36,
    borderRadius: 20,
    paddingHorizontal: 16,
    maxWidth: 124,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ED1B2E',
    borderColor: '#D8D8D8',
    flex: 1
  },
  buttonDirections: {
    height: 36,
    borderRadius: 20,
    paddingHorizontal: 16,
    maxWidth: 124,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#ED1B2E',
    marginLeft: 12,
    flex: 1
  }
});
