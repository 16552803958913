import { Patient } from './../types/patient';

export const patients: Patient[] = [
  {
    id: '1',
    name: 'John Smith'
  },
  {
    id: '2',
    name: 'Jane Smith'
  }
];
