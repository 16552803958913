/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { ViewProps } from 'react-native';

import { expand, collapse } from '../../assets/images/icons';

import {
  Card,
  CardHeader,
  CardHeaderLeft,
  CardHeaderRight,
  CardBody,
  CardHeaderTitle,
  Separator,
  CollapseIcon,
  CollapseIconImage
} from './ExpandableCardStyles';
import { logFirebaseEventClick } from '../../utils/analytics';

interface Props extends ViewProps {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  defaultExpanded?: boolean;
}

const ExpandableCard: React.FC<Props> = ({ title = '', children, defaultExpanded = false, ...cardProps }) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <Card {...cardProps}>
      <CardHeader
        accessible
        accessibilityLabel={expanded ? 'Collapse' : 'Expand'}
        accessibilityHint={expanded ? 'Collapse content' : 'Expand content'}
        onPress={(): void => {
          logFirebaseEventClick('eb_policy', {
            feature: 'Policy',
            journey: 'policy',
            stage: 'policy_list',
            screen_id: 'SCR_EB_POLICY_MPS',
            screen_name: 'MyPolicyScreen',
            object_id: 'BTN_EB_POLICY_EXPAND',
            status: expanded ? 'collapse' : 'expand'
          });
          setExpanded(!expanded);
        }}
      >
        <CardHeaderLeft>
          <CardHeaderTitle>{title}</CardHeaderTitle>
        </CardHeaderLeft>
        <CardHeaderRight>
          <CollapseIcon>
            <CollapseIconImage source={expanded ? collapse : expand} />
          </CollapseIcon>
        </CardHeaderRight>
      </CardHeader>
      {expanded && (
        <CardBody>
          <Separator />
          {children}
        </CardBody>
      )}
    </Card>
  );
};

export default ExpandableCard;
