import { FeatureToggle } from '../../types/featureToggle';
import { GeneralConfig } from '../../types/generalConfig';
import { LBUConfig } from '../../types/lbuConfig';
import { SupportInfo } from '../../types/supportInfo';
import {
  ClaimTypeOption,
  ClaimDocumentConfig,
  ClaimViewConfig,
  PaymentOption,
  ClaimDateConfig
} from './../../types/claim';
import * as GRAPHQL from './ph/graphql';
import * as REQUEST_PICKER from './ph/requestPicker';
import { NATIONALIDCHECK } from './ph/nationalId';
import { TERMS_OF_USE } from './ph/terms';
const CLAIM_TYPES: ClaimTypeOption[] = [
  {
    id: 'living-benefit',
    label: 'Living Benefit',
    originalRequired: false,
    documents: [
      'claimantStatement',
      'physicianStatement',
      'medicalRecords',
      'governmentIssued',
      'governmentId',
      'originalOfficialReceipt',
      'otherDocuments'
    ],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: true,
    groupPlans: []
  },
  {
    id: 'permanent-disability',
    label: 'Total and Permanent Disablility Benefit',
    originalRequired: false,
    documents: [
      'claimantStatement',
      'physicianStatement',
      'medicalRecords',
      'governmentIssued',
      'governmentId',
      'originalOfficialReceipt',
      'otherDocuments'
    ],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: []
  },
  {
    id: 'atpd',
    label: 'ATPD',
    originalRequired: false,
    documents: [
      'claimantStatement',
      'physicianStatement',
      'medicalRecords',
      'governmentIssued',
      'governmentId',
      'originalOfficialReceipt',
      'otherDocuments'
    ],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: []
  },
  {
    id: 'add-short',
    label: 'ADD Short',
    originalRequired: false,
    documents: [
      'claimantStatement',
      'physicianStatement',
      'medicalRecords',
      'governmentIssued',
      'governmentId',
      'originalOfficialReceipt',
      'otherDocuments'
    ],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: []
  },
  {
    id: 'add-long',
    label: 'ADD Long',
    originalRequired: false,
    documents: [
      'claimantStatement',
      'physicianStatement',
      'medicalRecords',
      'governmentIssued',
      'governmentId',
      'originalOfficialReceipt',
      'otherDocuments'
    ],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: []
  },
  {
    id: 'crisis-cover',
    label: 'Crisis Cover Benefit',
    originalRequired: false,
    documents: [
      'claimantStatement',
      'physicianStatement',
      'medicalRecords',
      'governmentIssued',
      'governmentId',
      'originalOfficialReceipt',
      'otherDocuments'
    ],
    reasons: [
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: []
  },
  {
    id: 'murder-assault',
    label: 'Murder & Assault',
    originalRequired: false,
    documents: [
      'claimantStatement',
      'physicianStatement',
      'medicalRecords',
      'governmentIssued',
      'governmentId',
      'originalOfficialReceipt',
      'otherDocuments'
    ],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: []
  },
  {
    id: 'burial-benefit',
    label: 'Burial Benefit',
    originalRequired: false,
    documents: [
      'claimantStatement',
      'physicianStatement',
      'medicalRecords',
      'governmentIssued',
      'governmentId',
      'originalOfficialReceipt',
      'otherDocuments'
    ],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: []
  },
  {
    id: 'accidental-medical-reimbursement',
    label: 'Accidental Medical Reimbursement',
    originalRequired: false,
    documents: [
      'claimantStatement',
      'physicianStatement',
      'medicalRecords',
      'governmentIssued',
      'governmentId',
      'originalOfficialReceipt',
      'otherDocuments'
    ],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: []
  },
  {
    id: 'accidental-hospital-income',
    label: 'Accidental Hospital Income Benefit',
    originalRequired: false,
    documents: [
      'claimantStatement',
      'physicianStatement',
      'medicalRecords',
      'governmentIssued',
      'governmentId',
      'originalOfficialReceipt',
      'otherDocuments'
    ],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    isDefault: false,
    groupPlans: []
  }
];

const PAYMENT_OPTIONS: PaymentOption[] = [
  {
    id: 'fund-transfer',
    label: 'Fund Transfer',
    isRequiredBankAccount: true
  },
  {
    id: 'cheque',
    label: 'Cheque',
    isRequiredBankAccount: false
  }
];

const DATES: ClaimDateConfig = {
  claimDate: {
    dateType: 'claimDate',
    title: 'CLAIMS__CLAIM_DETAILS_REQUEST_DATE',
    isOptional: false,
    readOnly: true
  },
  accidentDate: {
    dateType: 'accidentDate',
    title: 'CLAIMS__CLAIM_DETAILS_DATE_OF_ACCIDENT',
    isOptional: false,
    readOnly: false
  },
  admissionDate: {
    dateType: 'admissionDate',
    title: 'CLAIMS__CLAIM_DETAILS_DATE_OF_ADMISSION',
    isOptional: true,
    readOnly: false
  },
  dischargeDate: {
    dateType: 'dischargeDate',
    title: 'CLAIMS__CLAIM_DETAILS_DATE_OF_DISCHARGE',
    isOptional: true,
    readOnly: false
  }
};

const CLAIM_VIEW_CONFIG: ClaimViewConfig = {
  showOriginalReceiptRequired: true
};

const DOCUMENTS: ClaimDocumentConfig = {};

const SUPPORT_INFO: SupportInfo = {
  emails: ['contact.us@prulifeuk.com.ph'],
  phones: [`Metro Manila:\n (+632) 88875433`, `Domestic Toll-free:\n 1 800 10 7785465`],
  submitTicketUrl: '',
  serviceStatusUrl: '',
  applicationHelpUrl: 'https://www.prulifeuk.com.ph/en/contact-us',
  contactSupport: 'https://www.prulifeuk.com.ph/en/contact-us'
};

const API_URL = {
  dev: 'https://uat-api.eb.prulifeuk.com.ph/graphql/pulse-app',
  uat: 'https://uat-api.eb.prulifeuk.com.ph/graphql/pulse-app',
  prod: 'https://api.eb.prulifeuk.com.ph/graphql/pulse-app'
};

const FEATURE_TOGGLE: FeatureToggle = {
  enabledSaveDraft: false
};

const MY_POLICY = {
  dateFormat: 'MM/DD/YYYY',
  lBUCode: 'PLUK',
  isShowDependantView: true
};

const GENERAL: GeneralConfig = {
  globalNamespace: 'global_pluk',
  privacyUrl: 'https://www.prulifeuk.com.ph/en/privacy-policy/'
};

export const PH: LBUConfig = {
  API_URL,
  CLAIM_TYPES,
  CLAIM_VIEW_CONFIG,
  DOCUMENTS,
  SUPPORT_INFO,
  FEATURE_TOGGLE,
  MY_POLICY,
  GRAPHQL,
  REQUEST_PICKER,
  GENERAL,
  NATIONALIDCHECK,
  PAYMENT_OPTIONS,
  DATES,
  TERMS_OF_USE
};
