import { Policy } from '../../../types/policy';
import { CellType, MyPolicyRenderHelper, MyPolicyRenderObject, BenefitItem } from './MyPolicyRenderHelper';
import { MyPolicyRenderHelperTH } from './MyPolicyRenderHelperTH';
import { MyPolicyRenderHelperPH } from './MyPolicyRenderHelperPH';
import { MyPolicyRenderHelperMY } from './MyPolicyRenderHelperMY';

export { CellType };
export type { MyPolicyRenderObject, BenefitItem };

export default class MyPolicyRenderHelperFactory {
  static get(lBUCode: string, policy: Policy): MyPolicyRenderHelper {
    switch (lBUCode) {
      case 'PLT':
        return new MyPolicyRenderHelperTH(policy);
      case 'PAMB':
        return new MyPolicyRenderHelperMY(policy);
      case 'PLUK':
      default:
        return new MyPolicyRenderHelperPH(policy);
    }
  }
}
