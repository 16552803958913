import moment, { Moment } from 'moment';

import { APIPolicy, Policy } from '../../types/policy';

export const getPolicyStartDate = (policyStartDate: string, employeeStartDate: string): Moment => {
  const startDate =
    moment(policyStartDate).isAfter(employeeStartDate) || !moment(employeeStartDate).isValid()
      ? policyStartDate
      : employeeStartDate;
  return moment(startDate);
};

export const apiPolicyToPolicy = ({
  apiPolicy,
  countryCode
}: {
  apiPolicy: APIPolicy[];
  countryCode: string;
}): Policy[] => {
  const result: Policy[] = [];
  apiPolicy.map((policy) => {
    const employee = policy?.employees?.length > 0 ? policy?.employees[0] : null;

    if (['PH', 'SG', 'MY', 'TH', 'MM'].includes(countryCode)) {
      result.push({
        ...policy,
        policyId: policy?.policyId,
        policyNumber: policy?.policyNumber || '',
        referenceNumber: policy?.referenceNumber,
        startDate: employee
          ? getPolicyStartDate(policy?.coverageDate, employee.startDate)
          : moment(policy?.coverageDate),
        dobEmployee: policy?.dobEmployee,
        categoryName: '',
        allowDependants: true,
        employees: employee ? [employee] : [],
        tierId: '1',
        planIds: ['1']
      });
    }
  });
  return result;
};
