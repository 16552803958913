import { Claim, ClaimDocument, MakeClaimState } from '../../types/claim';
import {
  SET_ERROR,
  SET_LOADING,
  SET_INITIAL_CLAIM,
  UPDATE_CLAIM,
  RESET_CLAIM,
  UPDATE_CLAIM_DOCUMENT,
  UPLOAD_CLAIM_DOCUMENT_START,
  UPLOAD_CLAIM_DOCUMENT_SUCCESS,
  UPLOAD_CLAIM_DOCUMENT_FAILED,
  SAVE_CLAIM_START,
  SAVE_CLAIM_SUCCESS,
  SAVE_CLAIM_FAILED,
  SUBMIT_CLAIM_START,
  SUBMIT_CLAIM_SUCCESS,
  SUBMIT_CLAIM_FAILED,
  DELETE_CLAIM_START,
  DELETE_CLAIM_SUCCESS,
  DELETE_CLAIM_FAILED,
  DELETE_DOCUMENT_ACTION,
  FETCH_CONSENT_START,
  FETCH_CONSENT_SUCCESS,
  FETCH_CONSENT_FAILED,
  MakeClaimAction
} from './actions';

export type { MakeClaimAction, Claim, MakeClaimState };

export const initialState: MakeClaimState = {
  loading: false,
  error: undefined,
  documentError: {},
  initialClaim: null,
  claim: {
    claimNumber: '',
    claimType: undefined,
    certificateNo: "",
    originalRequired: false,
    documents: [],
    lifeAssuredCertificateNo: undefined
  },
  consents: null
};

const MakeClaimReducer = (state: MakeClaimState = initialState, action: MakeClaimAction): MakeClaimState => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case UPDATE_CLAIM:
      return {
        ...state,
        claim: {
          ...action.payload
        }
      };
    case SET_INITIAL_CLAIM:
      return {
        ...state,
        initialClaim: action.payload
      };
    case RESET_CLAIM:
      return {
        ...initialState,
        claimView: action.claimState,
        claim: {
          ...initialState.claim,
          claimState: action.claimState
        }
      };
    case UPDATE_CLAIM_DOCUMENT:
      return {
        ...state,
        claim: {
          ...state.claim,
          documents: [
            ...state.claim.documents.filter((doc): boolean => {
              const { docType, fileName } = action.payload as ClaimDocument;
              return !(doc.docType === docType && doc.fileName === fileName);
            }),
            action.payload
          ]
        }
      };
    case UPLOAD_CLAIM_DOCUMENT_START:
      return {
        ...state,
        loading: true,
        documentError: {
          ...state.documentError,
          [(action.payload as ClaimDocument).docType || '']: ''
        }
      };

    case UPLOAD_CLAIM_DOCUMENT_SUCCESS:
      return {
        ...state,
        claim: {
          ...state.claim,
          documents: [
            ...state.claim.documents.filter((doc): boolean => {
              const { docType, fileName } = action.payload as ClaimDocument;

              // remove if it's a file being replaced
              return !(doc.docType === docType && doc.fileName === fileName);
            }),
            action.payload
          ]
        },
        loading: false
      };
    case DELETE_DOCUMENT_ACTION:
      return {
        ...state,
        claim: {
          ...state.claim,
          documents: [
            ...state.claim.documents.filter((doc): boolean => {
              const { docType, fileName } = action.payload as ClaimDocument;

              // remove if it's a file being replaced
              return !(doc.docType === docType && doc.fileName === fileName);
            })
          ]
        },
        loading: false
      };

    case UPLOAD_CLAIM_DOCUMENT_FAILED:
      return {
        ...state,
        loading: false,
        documentError: {
          ...state.documentError,
          [action.payload.docType]: action.payload.message
        }
      };

    case SAVE_CLAIM_START:
      return {
        ...state,
        loading: true,
        error: null
      };

    case SAVE_CLAIM_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case SAVE_CLAIM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SUBMIT_CLAIM_START:
      return {
        ...state,
        loading: true,
        error: null
      };

    case SUBMIT_CLAIM_SUCCESS:
      return {
        ...initialState,
        loading: false
      };

    case SUBMIT_CLAIM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case DELETE_CLAIM_START:
      return {
        ...state,
        loading: true
      };

    case DELETE_CLAIM_SUCCESS:
      return {
        ...initialState,
        loading: false
      };

    case DELETE_CLAIM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_CONSENT_START:
      return {
        ...state,
        loading: true
      };
    case FETCH_CONSENT_SUCCESS:
      return {
        ...state,
        loading: false,
        consents: action.payload
      };
    case FETCH_CONSENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default MakeClaimReducer;
