import React from 'react';
import { View, Alert } from 'react-native';
import { withNavigation, NavigationInjectedProps } from 'react-navigation';
import { Icon } from 'react-native-elements';
import { useTranslation } from 'react-i18next';

import { PruText, PruCard } from '@ebsme/pulse-rn-components';
import ActionableText from '../ActionableText';
import StatusText from '../StatusText';
import HorizontalSeparator from '../HorizontalSeparator';
import * as routes from '../../routes';
import { getNavigate } from '../../utils/navigation';
import { LogRequest, LogRequestStatus } from '../../types/logRequest';
import { useConfirmAlert } from '../../hooks/useConfirmAlert';
import { sharePdf } from '../../utils/shareUtil';

export interface LogRequestListItemProps extends NavigationInjectedProps {
  item: LogRequest;
}

const LogRequestListItem: React.FC<LogRequestListItemProps> = ({ navigation, item }) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmAlert();
  const { ref, status, statusText, hospital, downloadUrl } = item;

  return (
    <PruCard style={{ marginHorizontal: 18, marginBottom: 18, marginTop: 6, padding: 0 }}>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 24 }}>
        <View style={{ flex: 1, marginEnd: 40 }}>
          <PruText weight="bold" size={18} color="black" marginBottom={12}>
            {hospital.name}
          </PruText>
          <PruText color="black">{ref}</PruText>
        </View>
        <View style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <StatusText status={status} statusText={statusText} style={{ flex: 1 }} />
          <Icon
            onPress={(): void => {
              if (downloadUrl) {
                sharePdf(downloadUrl, `LetterOfGuarantee_${ref}`);
              } else {
                Alert.alert('Letter of Guarantee is not available.');
              }
            }}
            name="md-share"
            type="ionicon"
            size={24}
            color="black"
          />
        </View>
      </View>
      <HorizontalSeparator />
      <View style={{ padding: 24, flexDirection: 'row', justifyContent: 'flex-end' }}>
        {status === LogRequestStatus.DRAFT && (
          <ActionableText
            onPress={(): void => {
              confirm({
                title: t('LETTER_OF_GUARANTEE__LOG_HISTORY__CONFIRM_REMOVE_DRAFT'),
                buttonText: t('BUTTON__DELETE_DRAFT'),
                destructive: true,
                onConfirm: (): void => {}
              });
            }}
            style={{ marginEnd: 16 }}
          >
            {t('BUTTON__DELETE_DRAFT')}
          </ActionableText>
        )}
        <ActionableText onPress={getNavigate(navigation.navigate, routes.LogDetails, { item })}>
          {t('BUTTON__VIEW_MORE')}
        </ActionableText>
      </View>
    </PruCard>
  );
};

export default withNavigation(LogRequestListItem);
