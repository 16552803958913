import React, { useCallback, useEffect, useState } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';

import { ClaimDocument as ClaimDocumentType } from '../../../types/claim';
import { useTranslation } from 'react-i18next';
import { getDocumentType, isClaimDocumentPdf } from '../../../utils/claim';
import DocumentPdfView from '../../DocumentPdfView';
import DocumentImageView from '../../DocumentImageView';
import { logFirebaseEventClick } from '../../../utils/analytics';

interface Props {
  document: ClaimDocumentType;
  onDocumentView?: (document: ClaimDocumentType) => void;
  onEditFile?: (document: ClaimDocumentType) => void;
}

const ClaimDocument: React.FC<Props> = ({ document, onDocumentView = (): void => undefined, onEditFile }) => {
  const { fileName } = document;
  const { t } = useTranslation();
  const [imageSize, setImageSize] = useState<string>(t('MESSAGE__LOADING'));

  useEffect(() => {
    if (!document.fileBlobURL) {
      setImageSize(t('MESSAGE__LOADING'));
    }
  }, [document.fileBlobURL, t]);

  const onLoadedSizeImage = useCallback((size: string): void => setImageSize(size), [setImageSize]);
  const onPressDocument = (document: ClaimDocumentType) => {
    logFirebaseEventClick('eb_make_claim', {
      feature: 'MakeClaim',
      journey: 'make_claim',
      stage: 'view_doc',
      screen_id: 'SCR_EB_CLAIM_DOCS',
      screen_name: 'DocumentScreen',
      object_id: 'BTN_EB_CLAIM_VIEW_DOCS',
      doc_type: isClaimDocumentPdf(document) ? getDocumentType(document.fileType || '') : 'image'
    });
    onDocumentView(document);
  };
  return (
    <View
      testID={`ClaimDocument-${fileName}`}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        padding: 10,
        marginBottom: 10,
        marginTop: 10,
        backgroundColor: '#fff',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#E6EAEE'
      }}
    >
      {isClaimDocumentPdf(document) ? (
        <DocumentPdfView
          testID="ThumbnailDocumentView"
          onSizeLoaded={onLoadedSizeImage}
          doc={document}
          showThumbnail
          onPress={(): void => onPressDocument(document)}
        />
      ) : (
        <TouchableOpacity testID="DocumentViewTrigger" onPress={(): void => onPressDocument(document)}>
          <DocumentImageView
            testID="ThumbnailDocumentView"
            compressed
            style={{
              width: 38,
              height: 42
            }}
            onLoadedSizeImage={onLoadedSizeImage}
            doc={document}
          />
        </TouchableOpacity>
      )}

      <View
        style={{
          display: 'flex',
          flex: 3,
          flexDirection: 'column',
          marginLeft: 10
        }}
      >
        <Text
          style={{
            fontSize: 15,
            fontWeight: 'bold',
            marginBottom: 5
          }}
        >
          {fileName}
        </Text>
        <Text
          style={{
            fontSize: 12,
            color: '#D3DADD'
          }}
        >
          {imageSize}
        </Text>
      </View>
      {onEditFile ? (
        <TouchableOpacity testID="EditActionTrigger" onPress={(): void => onEditFile(document)}>
          <Icon name="more-horiz" size={34} color="#68737A" />
        </TouchableOpacity>
      ) : (
        <TouchableOpacity testID="ClaimDocumentViewTrigger" onPress={(): void => onPressDocument(document)}>
          <Icon size={24} color="#68737A" name="ios-eye" type="ionicon" />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default ClaimDocument;
