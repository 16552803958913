import React from 'react';

import { View } from 'react-native';
import { PruBaseModal, PruButton } from '@ebsme/pulse-rn-components';

export interface PruDatePickerModalProps {
  isVisible: boolean;
  onDateChange: (date: Date) => void;
  onConfirm: () => void;
  confirmButtonText: string;
  selectedDate?: Date;
  onCancel?: () => void;
  cancelButtonText?: string;
  testID?: string;
}

const PruDatePickerModal: React.FC<PruDatePickerModalProps> = ({
  isVisible,
  selectedDate,
  onDateChange,
  onConfirm,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  testID = 'PruDatePickerModal'
}) => {
  return (
    <PruBaseModal isVisible={isVisible}>
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}
      >
        {!!cancelButtonText && !!onCancel && (
          <PruButton
            testID={`${testID}_CancelButton`}
            style={{ minWidth: 120, marginTop: 24 }}
            type="outline"
            marginHorizontal={5}
            onPress={onCancel}
          >
            {cancelButtonText}
          </PruButton>
        )}
        <PruButton
          testID={`${testID}_ConfirmButton`}
          style={{ minWidth: 120, marginTop: 24 }}
          marginHorizontal={5}
          onPress={onConfirm}
        >
          {confirmButtonText}
        </PruButton>
      </View>
    </PruBaseModal>
  );
};

export default PruDatePickerModal;
