import React from 'react';
import { ViewStyle } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { red } from '../../assets/colors';

interface Props {
  style?: ViewStyle;
}

const ForwardArrow: React.FC<Props> = ({ style }) => {
  return <Icon size={20} style={style} color={red} name="arrow-right" />;
};

export default ForwardArrow;
