import React from 'react';
import { View, Image, TouchableOpacity, Text } from 'react-native';
import { useTranslation } from 'react-i18next';

import { Clinic } from '../../types/clinic';
import { PruText, PruCard } from '@ebsme/pulse-rn-components';
import * as routes from '../../routes';
import HospitalIcon from '../../components/Hospital/HospitalIcon';
import { openMapDirection } from '../../utils/hospitalUtil';
import FastImage from '../../components/FastImage';
import { StackNavigationProp } from '../../types/navigation';
import { styles } from './FindHospitalStyle';
import { EVENT_ANALYTICS, logFirebaseEvent } from '../../utils/analytics';
import { getTransactionLabel } from '../../utils/claim';
import { Locale } from '../../types/sdk';

export interface ClinicItemProps {
  item: Clinic;
  navigation: StackNavigationProp;
  currentLocation: any;
  locale: Locale;
}

const ItemFindHospital: React.FC<ClinicItemProps> = ({ item, navigation, currentLocation, locale }) => {
  const { imageUrl, distance, contact, geoLocation, recommended } = item;
  const clinicType = getTransactionLabel(item.clinicType, locale);
  const clinicName = getTransactionLabel(item.clinicName, locale);
  const clinicAddress = getTransactionLabel(item.clinicAddress, locale);
  const phone = getTransactionLabel(contact?.phone, locale);
  const { t } = useTranslation();

  const onPressMorDetail = () => {
    navigation.navigate(routes.DetailHospital, { item, currentLocation, locale });
  };
  return (
    <View style={styles.itemContainer}>
      {imageUrl ? (
        <FastImage style={styles.image} source={{ uri: imageUrl }} />
      ) : (
        <HospitalIcon type={item.clinicType} />
      )}
      <View style={{ flex: 1 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <View style={{ flex: 1, marginRight: 10 }}>
            <PruText color="darkBlue" weight="bold" size={13} numberOfLines={1}>
              {clinicName.toUpperCase()}
            </PruText>
          </View>
          <Text style={{ color: '#4386E5', fontSize: 12, lineHeight: 15 }}>{distance}</Text>
        </View>
        <PruText marginTop={4} weight="thin">
          {`${clinicType} • ${clinicAddress}`}
        </PruText>
        <PruText marginTop={4}>
          <PruText weight="thin">{`${phone}`}</PruText>
        </PruText>
        {recommended && (
          <TouchableOpacity style={styles.buttonRecommend}>
            <PruText style={styles.textRecommend}>{t('CLINIC__RECOMMENDED')}</PruText>
          </TouchableOpacity>
        )}
        <View style={{ flexDirection: 'row', marginTop: 12 }}>
          <TouchableOpacity onPress={onPressMorDetail} style={styles.buttonMore}>
            <PruText style={{ color: 'white', fontSize: 13, fontWeight: 'bold' }}>{t('CLINIC__MORE_DETAIL')}</PruText>
          </TouchableOpacity>
          {geoLocation && (
            <TouchableOpacity
              onPress={() => openMapDirection(currentLocation, geoLocation)}
              style={styles.buttonDirections}
            >
              <PruText style={{ color: '#ED1B2E', fontSize: 13, fontWeight: 'bold' }}>
                {t('CLINIC__DIRECTIONS')}
              </PruText>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
};

export default ItemFindHospital;
