import React from 'react';
import { View, Dimensions, ViewProps } from 'react-native';
import { athensGrey } from '../../assets/colors';

interface Props extends ViewProps {
  children: React.ReactNode;
  inverse?: boolean;
  top?: number;
}

const screenWidth = Dimensions.get('screen').width;

const ShadedContainer: React.FC<Props> = ({ children, top, inverse, ...props }) => {
  return (
    <View style={{ paddingVertical: 24 }} {...props}>
      <View
        testID="ShadedContainer_ShadeView"
        style={{
          backgroundColor: athensGrey,
          right: inverse ? 0 : screenWidth / 5,
          top: top || 0,
          bottom: 0,
          left: inverse ? screenWidth / 5 : 0,
          position: 'absolute'
        }}
      />
      {children}
    </View>
  );
};

export default ShadedContainer;
