import React from 'react';
import SvgAnimatedLinearGradient from 'react-native-svg-animated-linear-gradient';
import { Rect } from 'react-native-svg';

import { PruCard } from '@ebsme/pulse-rn-components';
import FloatingArrow from '../FloatingArrow';

const BankAccountListItemLoader: React.FC = () => (
  <PruCard style={{ marginHorizontal: 18, marginBottom: 18, marginTop: 6 }}>
    <SvgAnimatedLinearGradient width={400} height={80} duration={1000}>
      <Rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      <Rect x="95" y="20" rx="3" ry="3" width="160" height="9" />
      <Rect x="95" y="45" rx="5" ry="5" width="200" height="12" />
    </SvgAnimatedLinearGradient>
    <FloatingArrow />
  </PruCard>
);

export default BankAccountListItemLoader;
