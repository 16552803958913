import { Action } from 'redux';

export const SET_REFRESH_TOKEN = 'Session/SET_REFRESH_TOKEN';
export const CLEAR_REFRESH_TOKEN = 'Session/CLEAR_REFRESH_TOKEN';
export const CLEAR_VALIDATE_EEROR = 'Session/CLEAR_VALIDATE_EEROR';
export const BACK_TO_PULSE = 'Session/BACK_TO_PULSE';

export const FETCH_REFRESH_TOKEN_START = 'Session/FETCH_REFRESH_TOKEN_START';
export const FETCH_REFRESH_TOKEN_SUCCESS = 'Session/FETCH_REFRESH_TOKEN_SUCCESS';
export const FETCH_REFRESH_TOKEN_FAILED = 'Session/FETCH_REFRESH_TOKEN_FAILED';

export const VALIDATE_OTP_START = 'Session/VALIDATE_OTP_START';
export const VALIDATE_OTP_SUCCESS = 'Session/VALIDATE_OTP_SUCCESS';
export const VALIDATE_OTP_FAILED = 'Session/VALIDATE_OTP_FAILED';

export interface SetRefreshToken extends Action {
  type: typeof SET_REFRESH_TOKEN;
  payload: string;
}

export interface ClearRefreshToken extends Action {
  type: typeof CLEAR_REFRESH_TOKEN;
}

export interface ClearValidateError extends Action {
  type: typeof CLEAR_VALIDATE_EEROR;
}

export interface BackToPulse extends Action {
  type: typeof BACK_TO_PULSE;
  payload: boolean;
}

export type RefreshTokenAction = SetRefreshToken | ClearRefreshToken | ClearValidateError | BackToPulse;

export interface FetchRefreshTokenStart extends Action {
  type: typeof FETCH_REFRESH_TOKEN_START;
}

export interface FetchRefreshTokenSuccess extends Action {
  type: typeof FETCH_REFRESH_TOKEN_SUCCESS;
}

export interface FetchRefreshTokenFailed extends Action {
  type: typeof FETCH_REFRESH_TOKEN_FAILED;
  payload: string;
}

export type FetchRefreshTokenAction = FetchRefreshTokenStart | FetchRefreshTokenSuccess | FetchRefreshTokenFailed;

export interface ValidateOTPStart {
  type: typeof VALIDATE_OTP_START;
}

export interface ValidateOTPSuccess {
  type: typeof VALIDATE_OTP_SUCCESS;
}

export interface ValidateOTPFailed {
  type: typeof VALIDATE_OTP_FAILED;
  payload: string;
}

export type ValidateOTPAction = ValidateOTPStart | ValidateOTPSuccess | ValidateOTPFailed;

export type SessionAction = RefreshTokenAction | FetchRefreshTokenAction | ValidateOTPAction;
