import { Hospital } from './../types/hospital';

export const hospitals: Hospital[] = [
  {
    id: '1',
    name: "KK Women's and Children's Hospital"
  },
  {
    id: '2',
    name: 'Changi General Hospital'
  }
];
