import { Platform } from 'react-native';
import { clearPolicy, selectPolicy } from './../../../reducers/MyPolicyReducer/actions';
import { setClaimFilter } from './../../../reducers/ReviewClaimReducer/actions';
import { back2PulseAction, clearRefreshToken } from './../../../reducers/SessionReducer/actions';
import { UserCountryCode } from '../../../types/user';
import { LandingFeatures } from '../../../types/entitlements';
import { RootState } from '../../../store';
import withLBUSwitcher from '../../../hocs/withLBUSwitcher';
import withUserEntitlements from '../../../hocs/withUserEntitlements';
import { getUserCountryCode } from '../../../reducers/PulseUserReducer/selectors';
import { getPolicies, getPolicy } from '../../../reducers/MyPolicyReducer/selectors';
import { hasDependantRole, getPulseConfig } from '../../../reducers/CorporateUserReducer/selectors';

import LandingScreenTiles from './LandingScreenTiles';
import { clearLastPulseUser } from './../../../reducers/PulseUserReducer/actions';
import { clearCorporateUser } from './../../../reducers/CorporateUserReducer/actions';

type LBUTilesMap = {
  [key in UserCountryCode]: LandingFeatures[];
};

const lbuTilesMap: LBUTilesMap = {
  TH: ['MAKE_CLAIM', 'REVIEW_CLAIM', 'MY_POLICY', 'SUPPORT', 'HOSPITAL'].concat(
    Platform.OS === 'web' ? [] : ['SEE_DOCTOR']
  ) as LandingFeatures[],
  PH: ['MAKE_CLAIM', 'REVIEW_CLAIM', 'MY_POLICY', 'SUPPORT', 'TERMS_OF_USE'],
  SG: ['MAKE_CLAIM', 'REVIEW_CLAIM', 'MY_POLICY', 'SUPPORT', 'AGREEMENT', 'HOSPITAL'],
  MY: ['FEATURE_TILES_MY', 'SUPPORT'],
  MM: ['MAKE_CLAIM', 'REVIEW_CLAIM', 'MY_POLICY', 'SUPPORT']
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  lbuTiles: lbuTilesMap[getUserCountryCode(state) || 'TH'],
  policies: getPolicies(state),
  hasDependantRole: hasDependantRole(state),
  pulseConfig: getPulseConfig(state),
  policy: getPolicy(state)
});

const LBULandingScreenTiles = withLBUSwitcher(LandingScreenTiles, mapStateToProps, {
  setClaimFilter,
  selectPolicy,
  back2PulseAction,
  clearRefreshToken,
  clearLastPulseUser,
  clearPolicy,
  clearCorporateUser
});
const EntitledLandingScreenTiles = withUserEntitlements(LBULandingScreenTiles);

export default EntitledLandingScreenTiles;
