import React from 'react';
import { Text, View, Image } from 'react-native';

export interface Props {
  contacts?: {
    [key: string]: string;
  };
}

const ECardBack: React.FC<Props> = () => {
  return (
    <View
      testID="ECardBack"
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: 12,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#b9b9b9'
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Image
          style={{
            width: 150,
            height: 24
          }}
          source={require('../../../assets/images/pru_logo.png')}
        />
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 10,
          marginTop: 10
        }}
      >
        <Text
          style={{
            fontSize: 14,
            fontWeight: 'bold'
          }}
        >
          Terms and Conditions
        </Text>
        <Text
          style={{
            fontSize: 12
          }}
        >
          1. This must be presented to the clinic at the point of registration.
        </Text>
        <Text
          style={{
            fontSize: 12
          }}
        >
          2. Use of this card constitutes acknowledgment of the current terms and conditions.
        </Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 10
        }}
      >
        <Text
          style={{
            fontSize: 14,
            fontWeight: 'bold'
          }}
        >
          For Enquiries
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'space-between',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Text>Singapore</Text>
            <Text style={{ textAlign: 'left', alignSelf: 'flex-start' }}>gp@mhcasia.com</Text>
            <Text>+65 99999999</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Text>Malaysia</Text>
            <Text style={{ textAlign: 'left', alignSelf: 'flex-start' }}>mygp@mhcasia.com</Text>
            <Text>+60 99999999</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default ECardBack;
