import React from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-elements';
import { StackActions, NavigationActions } from 'react-navigation';
import { PruText } from '@ebsme/pulse-rn-components';
import { Content, ContentContainer, ScreenContainer } from '../OnboardingView/OnboardingViewStyles';
import Header from '../../../components/Header';
import ECardView from '../../../components/ECard/ECardView';

import * as routes from '../../../routes';
import { StackNavigationProp } from '../../../types/navigation';

//mock data
import { getSelfECard } from '../../../utils/ecard';

interface Props {
  navigation: StackNavigationProp<
    {},
    {
      email: string;
    }
  >;
}

const OnboardingECardScreen: React.FC<Props> = ({ navigation }) => {
  const cardDetail = getSelfECard();

  const onNextNavigation = (): void => {
    // navigation.navigate(routes.Landing);
    const actionToDispatch = StackActions.reset({
      index: 0,
      key: null,
      actions: [NavigationActions.navigate({ routeName: routes.Landing })]
    });

    navigation.dispatch(actionToDispatch);
  };

  return (
    <ScreenContainer>
      <ContentContainer>
        <Header />
        <Content>
          <PruText
            size={34}
            color="black"
            align="center"
            marginBottom={10}
            weight="bold"
            marginLeft={20}
            marginRight={20}
          >
            Here's your e-card
          </PruText>
          <PruText
            size={18}
            color="black"
            align="center"
            marginBottom={10}
            marginTop={5}
            marginLeft={20}
            marginRight={20}
          >
            Present this card when you visit your panel clinic
          </PruText>
          <ECardView data={cardDetail} />
          <View
            testID="OnboardingECardScreen"
            style={{
              margin: 10
            }}
          >
            <Button
              testID="nextScreen"
              title="Next"
              containerStyle={{
                margin: 0,
                marginStart: 0,
                marginEnd: 0,
                marginBottom: 10
              }}
              titleStyle={{
                color: '#FFFFFF',
                fontSize: 15
              }}
              buttonStyle={{
                margin: 5,
                backgroundColor: '#ED1B2E',
                borderRadius: 50
              }}
              onPress={onNextNavigation}
            />
          </View>
        </Content>
      </ContentContainer>
    </ScreenContainer>
  );
};

export default OnboardingECardScreen;
