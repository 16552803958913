import React from 'react';

import { PolicyComponentBenefit } from '../../types/policy';

interface DisplayBenefit extends PolicyComponentBenefit {
  value: React.ReactText;
}

export const getBenefit = (benefits: PolicyComponentBenefit[] = [], benefitType: string): DisplayBenefit => {
  const benefit = benefits.find((benefit) => benefit.type === benefitType);

  const { type, value: rawValue, primary } = benefit || { type: '' };

  const value: React.ReactText = (rawValue as React.ReactText) || '-';

  return {
    type,
    value,
    primary
  };
};

export const getBooleanBenefitValue = (benefits: PolicyComponentBenefit[] = [], type: string): boolean => {
  const benefit = benefits.find((benefit) => benefit.type === type);

  const value: boolean = benefit ? (benefit.value as boolean) : false;

  return Boolean(value);
};

export const getAlphabetFromNumber = (n: number, uppercase?: boolean): string => {
  const alphabets = 'abcdefghijklmnopqrstuvwxyz';
  let m = 0;
  const result = [];
  while (n > 0) {
    m = (n - 1) % 26;
    n = (n - 1 - m) / 26;
    result.unshift(alphabets[m]);
  }
  return uppercase ? result.join('').toUpperCase() : result.join('');
};

export const getRomanFromNumber = (n: number, uppercase?: boolean): string => {
  const hash = [
    {
      roman: 'C',
      val: 100
    },
    {
      roman: 'XC',
      val: 90
    },
    {
      roman: 'L',
      val: 50
    },
    {
      roman: 'XL',
      val: 40
    },
    {
      roman: 'X',
      val: 10
    },
    {
      roman: 'IX',
      val: 9
    },
    {
      roman: 'V',
      val: 5
    },
    {
      roman: 'IV',
      val: 4
    },
    {
      roman: 'I',
      val: 1
    }
  ];
  let res = '';

  let i = 0;

  while (n > 0) {
    if (n >= hash[i].val) {
      res += hash[i].roman;
      n -= hash[i].val;
    } else i++;
  }

  return uppercase ? res : res.toLowerCase();
};

export const formatBenefitValueMY = (value: string): string => {
  return value ? value.replace('MYR', 'RM').replace(/RM\s+/g, 'RM') : value;
};

export const formatBenefitValuePH = (value: string): string => {
  if (value && value.match(/PHP\S+/)) {
    return value.replace('PHP', 'PHP ');
  }
  return value;
};
