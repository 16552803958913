import styled from 'styled-components/native';

import { grey } from '../../assets/colors';

interface StyledProps {
  bottomSeparator?: boolean;
}

const getBorderBottomStyle = ({ bottomSeparator }: StyledProps): string => {
  if (bottomSeparator) {
    return `
      border-bottom-width: 1px;
      border-bottom-color: ${grey};
    `;
  }

  return '';
};

export const FieldContainer = styled.View`
  ${getBorderBottomStyle}
`;
