import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from '../../routes';

import MyPolicyScreenContainer from './MyPolicyScreenContainer';
import PolicyDependantContainer from '../PolicyDependant/PolicyDependantContainer';

const routeConfigMap = {
  [routes.MyPolicy]: {
    screen: MyPolicyScreenContainer
  },
  [routes.PolicyDependant]: {
    screen: PolicyDependantContainer
  }
};

const MyPolicyNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.MyPolicy,
    headerMode: 'none'
  })
);

export default MyPolicyNavigator;
