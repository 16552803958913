import { Reward } from './type';
import { FETCH_REWARD_START, FETCH_REWARD_FAILED, FETCH_REWARD_SUCCESS, RewardAction } from './actions';

export type { RewardAction };

type RewardState = {
  reward?: Reward;
  fetchError?: string;
  loading: boolean;
};

export const initialState: RewardState = {
  reward: undefined,
  fetchError: undefined,
  loading: false
};

const RewardReducer = (state: RewardState = initialState, action: RewardAction): RewardState => {
  switch (action.type) {
    case FETCH_REWARD_START:
      return {
        ...state,
        loading: true,
        fetchError: undefined
      };

    case FETCH_REWARD_SUCCESS:
      return {
        ...state,
        reward: action.payload,
        loading: false
      };

    case FETCH_REWARD_FAILED:
      return {
        ...state,
        loading: false,
        fetchError: action.payload
      };

    default:
      return state;
  }
};

export default RewardReducer;
