import React from 'react';
import { View, ViewStyle, TextInput, KeyboardTypeOptions } from 'react-native';
import PruText, { TextColor } from '@ebsme/pulse-rn-components/PruText';
import { PruErrorMessage } from '@ebsme/pulse-rn-components';

interface TextInputWithLabelProps {
  onTextChange: (text: string, origin: string) => void;
  origin: string;
  label: string;
  value: string;
  required?: boolean;
  style?: ViewStyle;
  color?: TextColor;
  errorMessage?: string | undefined;
  maxLength?: number;
  numberLine?: number;
  multipleLine?: boolean;
  keyboardType?: KeyboardTypeOptions;
  hint?: string | undefined;
  testID?: string;
}

const TextInputWithLabel: React.FC<TextInputWithLabelProps> = ({
  onTextChange,
  origin = '',
  label = '',
  value = '',
  style,
  required = false,
  errorMessage,
  maxLength,
  numberLine,
  keyboardType,
  multipleLine = false,
  hint = '',
  testID
}) => {
  return (
    <View style={[style, { marginVertical: 10 }]}>
      <PruText testID={`${testID}_Title`}>
        {label} {required && '*'}
      </PruText>
      <TextInput
        testID={testID}
        value={value}
        key={hint}
        numberOfLines={numberLine || 1}
        placeholder={hint}
        multiline={multipleLine}
        maxLength={maxLength}
        keyboardType={keyboardType}
        onChangeText={(text) => onTextChange(text, origin)}
        style={{ borderColor: '#68737A', borderWidth: 0.5, paddingVertical: 10, paddingHorizontal: 10, borderRadius: 5, marginTop: 10, textAlignVertical: 'center' }}
      />
      <PruErrorMessage errorMessage={errorMessage} />
    </View>
  );
};

export default TextInputWithLabel;
