import React, { useEffect } from 'react';

import * as routes from '../../../routes';
import { StackNavigationProp } from '../../../types/navigation';
import { Claim, ClaimState } from '../../../types/claim';
import { getClaimFilterOptions } from './../../../utils/claim';
import ClaimListView from '../../../components/Claim/ClaimListView';
import ReviewClaimView from '../ReviewClaimView';
import { UserCountryCode } from '../../../types/user';
import { getStackSequences } from '../../MakeClaimScreens/routesUtility';
import { Policy } from '../../../types/policy';
import { EVENT_ANALYTICS, logFirebaseEventScreen } from '../../../utils/analytics';

export interface Props {
  lbu: UserCountryCode;
  navigation: StackNavigationProp;
  claims: Claim[];
  loading?: boolean;
  policyId: string;
  policy?: Policy;
  claimFilter?: ClaimState;
  setClaimFilter: (claimFilter?: ClaimState) => void;
  resetClaim: (claimState?: ClaimState) => void;
  setClaimDetail: (claim: Claim) => void;
  setInitialClaim: (claim: Claim | null) => void;
  updateClaim: (claim: Claim) => void;
  deleteClaimForProcess: (claim: Claim, callback?: (claimNumber?: string) => void) => void;
  fetchClaims: (certificateNo: string) => void;
  setSDKLoading: (loading: boolean) => void;
}

const ClaimListScreen: React.FC<Props> = ({
  lbu,
  navigation,
  claims,
  policyId,
  policy,
  claimFilter,
  setClaimFilter,
  resetClaim,
  setClaimDetail,
  setInitialClaim,
  updateClaim,
  deleteClaimForProcess,
  fetchClaims,
  loading,
  setSDKLoading
}) => {
  useEffect(() => {
    logFirebaseEventScreen(EVENT_ANALYTICS.review_claim, {
      feature: 'ReviewClaim',
      journey: 'review_claim',
      stage: 'review_list',
      screen_id: 'SCR_EB_REVIEW_CLS',
      screen_name: 'ClaimListScreen'
    });
  }, []);
  useEffect(() => {
    fetchClaims(policy?.certificateNo || '');
  }, [policyId, fetchClaims]);

  useEffect(() => {
    setSDKLoading(Boolean(loading));
  }, [setSDKLoading, loading]);

  const fClaims = claims.filter(({ claimType }) => claimType);
  const onClaimSelected = (claim: Claim): void => {
    if (claim.claimState === 'DRAFT') {
      updateClaim(claim);
      setInitialClaim(claim);
      const defaultInitialRoute = routes.DocumentScreen;
      navigation.navigate(defaultInitialRoute);
      if (claim.uiState) {
        const routeStack = getStackSequences(lbu, claim.uiState, defaultInitialRoute);
        routeStack.forEach((e) => navigation.push(e));
      }
    } else {
      setClaimDetail(claim);
      navigation.navigate(routes.ClaimDetailScreen);
    }
  };

  const filterOptions = getClaimFilterOptions(lbu);

  const onClaimDeleted = (claim: Claim): void => {
    deleteClaimForProcess(claim, (): void => {
      fetchClaims(policyId);
    });
  };

  return (
    <ReviewClaimView cancelVisible={false} navigation={navigation} backVisible>
      {!loading ? (
        <ClaimListView
          lbu={lbu}
          claims={fClaims}
          resetClaim={resetClaim}
          onClaimSelected={onClaimSelected}
          onClaimDeleted={onClaimDeleted}
          filterOptions={filterOptions}
          claimFilter={claimFilter}
          setClaimFilter={setClaimFilter}
          navigation={navigation}
        />
      ) : null}
    </ReviewClaimView>
  );
};

export default ClaimListScreen;
