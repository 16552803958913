import React from 'react';
import { View, Text, FlatList, TouchableOpacity, SafeAreaView, TouchableWithoutFeedback } from 'react-native';
import Modal from 'react-native-modal';
import { Icon } from 'react-native-elements';
import { useTranslation } from 'react-i18next';

import { black, red } from '../../../assets/colors';

export type MenuItem = {
  id: string;
  icon: string;
  title: string;
  color: string;
};
interface MenuItemProps {
  item: MenuItem;
}

interface Props {
  items: MenuItem[];
  visible: boolean;
  onModalHide?: () => void;
  onCancel: () => void;
  onMenuAction?: (item: MenuItem) => void;
}

export const ADD_MENU_ITEMS = [
  {
    title: 'CLAIMS__DOCUMENTS__TAKE_PHOTO',
    id: 'takePhoto',
    icon: 'photo-camera',
    color: black
  },
  {
    title: 'CLAIMS__DOCUMENTS__UPLOAD_PHOTO',
    id: 'uploadPhoto',
    icon: 'photo',
    color: black
  },
  {
    title: 'CLAIMS__DOCUMENTS__UPLOAD_PDF',
    id: 'uploadPdf',
    icon: 'file-upload',
    color: black
  }
];

export const EDIT_MENU_ITEMS = [
  {
    title: 'CLAIMS__DOCUMENTS__RETAKE_PHOTO',
    id: 'reTake',
    icon: 'photo-camera',
    color: black
  },
  {
    title: 'CLAIMS__DOCUMENTS__REUPLOAD_PHOTO',
    id: 'reUpload',
    icon: 'publish',
    color: black
  },
  {
    title: 'CLAIMS__DOCUMENTS__REUPLOAD_PDF',
    id: 'reUploadPdf',
    icon: 'file-upload',
    color: black
  },
  {
    title: 'CLAIMS__DOCUMENTS__DELETE_FILE',
    id: 'deleteFile',
    icon: 'delete',
    color: red
  }
];

const ClaimBottomMenu: React.FC<Props> = ({
  items,
  visible,
  onMenuAction = (): void => undefined,
  onCancel,
  onModalHide
}) => {
  const { t } = useTranslation();

  const renderDocMenus: React.FC<MenuItemProps> = ({ item }) => (
    <TouchableOpacity testID={item.title} onPress={(): void => onMenuAction(item)}>
      <View
        testID={item.id}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignContent: 'center',
          alignItems: 'center',
          padding: 5,
          marginBottom: 4,
          marginTop: 4,
          backgroundColor: '#fff'
        }}
      >
        <Icon
          name={item.icon}
          size={32}
          color={item.color}
          containerStyle={{
            marginRight: 20
          }}
        />
        <Text style={{ marginTop: 2, color: item.color }}>{t(item.title)}</Text>
      </View>
    </TouchableOpacity>
  );

  return (
    <Modal
      testID='ModalDocumentUpload'
      onModalHide={(): void => {
        setTimeout(onModalHide, 0);
      }}
      isVisible={visible}
      backdropTransitionOutTiming={0}
      style={{ margin: 0 }}
      useNativeDriver
    >
      <TouchableWithoutFeedback testID="ClaimBottomMenu" accessible={false} onPress={onCancel}>
        <View style={{ flex: 1, justifyContent: 'flex-end' }}>
          <SafeAreaView style={{ width: '100%', backgroundColor: '#fff' }}>
            <FlatList data={items} renderItem={renderDocMenus} keyExtractor={(item): string => item.title} />
          </SafeAreaView>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

export default ClaimBottomMenu;
