import { MaritalStatus } from './employee';
import { Entitlement, LandingFeatures } from './entitlements';

export enum OTPTYPE {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE'
}

export interface Credential {
  nationalId: string;
  email: string;
  phone: string;
  rememberMe: boolean;
  timestamp: number;
}

export type UserCountryCode = 'SG' | 'TH' | 'PH' | 'MY' | 'MM';

export interface PulseUser {
  id: string;
  device: string;
  phone: string;
  nationalId: string;
  email: string;
  firstName: string;
  surName: string;
  countryCode: UserCountryCode;
  dob: string;
  gender: string;
  otpType: OTPTYPE;
  loginTimestamp: number;
  appBundle: string;
  deviceId: string;
}

export interface CorporateUser {
  email: string;
  phone: string;
  nationalId: string;
  maritalStatus?: MaritalStatus;
  entitlements: Entitlement[];
}

export type Role = 'MainApplicant' | 'Dependant';

export interface RoleList {
  Role: Role;
  Features: LandingFeatures[];
}

export interface PulseConfig {
  FEATURE_TOGGLE: {
    showRememberMe: boolean;
    enabledSaveDraft: boolean;
    showForget?: boolean;
    showInsuredName?: boolean;
    showPolicyNo?: boolean;
  };
  TERMS_OF_USE?: string;
  ACCESS_CONTROL_LIST?: {
    Roles: Role[];
    Role_list: RoleList[];
  };
  SUPPORT_INFO?: {
    emails: string[];
    phones: string[];
    submitTicketUrl: string;
    serviceStatusUrl: string;
    applicationHelpUrl: string;
  };
}
