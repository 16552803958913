import { RootState } from '../../store';
import { Bank } from '../../types/bank';
import { BankAccount } from '../../types/bankAccount';

export const getBankAccounts = (state: RootState): BankAccount[] => state.BankAccount.bankAccounts;
export const getBankAccountsLoading = (state: RootState): boolean => state.BankAccount.bankAccountsLoading;
export const getBankAccountsFetchError = (state: RootState): string | undefined =>
  state.BankAccount.bankAccountsFetchError;

export const getBanks = (state: RootState): Bank[] => state.BankAccount.banks;
export const getBanksLoading = (state: RootState): boolean => state.BankAccount.banksLoading;
export const getBanksFetchError = (state: RootState): string | undefined => state.BankAccount.banksFetchError;
