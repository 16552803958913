import React from 'react';
import { TouchableOpacityProps, TouchableOpacity } from 'react-native';
import { PruText } from '@ebsme/pulse-rn-components';

const TouchableText: React.FC<TouchableOpacityProps> = ({ onPress, children, ...textProps }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <PruText underline color="darkBlue" weight="bold" {...textProps}>
        {children}
      </PruText>
    </TouchableOpacity>
  );
};

export default TouchableText;
