import gql from 'graphql-tag';

import { Claim } from '../../types/claim';
import apiClient from '../../utils/apiClient';
import { path } from 'ramda';
import { getValue } from '../../utils/object';
import { LBU } from '../../config';
import { UserCountryCode } from '../../types/user';

interface ClaimsResponse {
  data: {
    getClaimsForEmployeePolicy: Claim[];
  };
}

export const fetchAllClaims = (lbu: UserCountryCode, certificateNo: string): Promise<ClaimsResponse> =>
  apiClient.query({
    query: getValue(LBU, [lbu, 'GRAPHQL', 'getClaimsForEmployeePolicyQuery']),
    variables: {
      certificateNo
    }
  });

export const fetchDocBase64 = async ({
  policyId,
  filePath
}: {
  policyId: string;
  filePath: string;
}): Promise<string> => {
  const res = await apiClient.query({
    query: gql`
      {
        downloadClaimFile(
          downloadClaimFilePayload: {
            policyId: "${policyId}"
            path: "${filePath}"
          }
        ) {
          fileContent
        }
      }
    `
  });

  const base64Content = path<string>(['data', 'downloadClaimFile', 'fileContent'], res);

  if (!base64Content) {
    throw new Error('No fileContent in claim file response');
  }

  return base64Content;
};
