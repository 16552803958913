// @ts-ignore
import ProductCataloguePAMB, { Benefit } from '@ebsme-pamb/product-catalogue';
import { ProductBenefit, ProductPlan } from '../../../types/product';
import { CellType, MyPolicyRenderHelperBase, MyPolicyRenderObject, MyPolicyRenderHelper } from './MyPolicyRenderHelper';
import { Policy } from '../../../types/policy';
import { formatBenefitValueMY } from './../MyPolicyUtils';

export class MyPolicyRenderHelperMY extends MyPolicyRenderHelperBase implements MyPolicyRenderHelper {
  constructor(policy: Policy) {
    super(new ProductCataloguePAMB(), policy);
  }

  private static planNameLocalizeMap: Record<string, string> = {
    GTL: 'MYPOLICY__GTL__WITH_ACRONYM'
  };

  private getBenefitValueString(benefit: ProductBenefit): string {
    const value = this.productCatalogue.getBenefitValueString(
      this.productCatalogue.getBenefitValue(benefit.id, this.policy.tierId) ?? benefit.value,
      {
        decimalPlaces: 0
      }
    );
    return formatBenefitValueMY(value);
  }

  private recursivelyMapBenefit(benefit: ProductBenefit): MyPolicyRenderObject[] {
    if (!benefit.children || benefit.children.length <= 0) {
      return [];
    }
    return benefit.children
      .map((e) => new Benefit(e))
      .reduce((rows, benefitChild, i) => {
        const hasChild = benefitChild.children && benefitChild.children.length > 0;
        const subTitleRow = benefitChild.level == 3;
        let indentStr = '';
        if (benefitChild.level > 4) {
          indentStr = '-';
        }
        rows.push({
          type: CellType.ItemRow,
          key: benefitChild.id,
          title: '',
          label: benefitChild.getTitle(),
          value: this.getBenefitValueString(benefitChild),
          leadingNumber: indentStr,
          highlighted: subTitleRow && i % 2 == 0,
          boldColor: subTitleRow && hasChild ? 'black' : undefined,
          flex: true
        });

        if (hasChild) {
          let childRows = this.recursivelyMapBenefit(benefitChild);
          if (subTitleRow) {
            childRows = childRows.map((e, i) => ({ ...e, highlighted: i % 2 == 0 }));
          }
          return [...rows, ...childRows];
        }
        return rows;
      }, []);
  }

  private mapOptionalBenefit(plan: ProductPlan): MyPolicyRenderObject {
    const mainBenefit = new Benefit({ id: `GTL.${plan.name}.TITLE` });
    const mainRow: MyPolicyRenderObject = {
      type: CellType.ExpandableCard,
      key: plan.id,
      label: '',
      title: mainBenefit.getTitle()
    };

    // add all child
    const children = plan.benefits
      .map((e) => new Benefit(e))
      .reduce((children: MyPolicyRenderObject[], benefit) => {
        const lastOverallRow = !benefit.children || benefit.children.length <= 0;
        if (lastOverallRow) {
          children.push({
            type: CellType.ItemRow,
            key: benefit.id,
            title: '',
            label: '',
            value: `${benefit.getTitle()} of ${this.getBenefitValueString(benefit)}`,
            flex: true
          });
          return children;
        }

        benefit.id = `${benefit.id}.TITLE`;
        children.push({
          type: CellType.ItemRow,
          key: benefit.id,
          title: '',
          label: benefit.getTitle(),
          boldColor: 'black',
          flex: true
        });
        const subChildren = benefit.children
          .map((e: ProductBenefit) => new Benefit(e))
          .map((benefitChild: ProductBenefit, i: number) => {
            return {
              type: CellType.ItemRow,
              key: benefitChild.id,
              title: '',
              label: benefitChild.getTitle(),
              value: this.getBenefitValueString(benefitChild),
              highlighted: i % 2 == 0,
              flex: true
            };
          });
        return [...children, ...subChildren];
      }, []);
    return {
      ...mainRow,
      children
    };
  }

  private mapTextView = (text: string): MyPolicyRenderObject => ({
    type: CellType.PlanTitle,
    key: text,
    title: '',
    label: text
  });

  mapAllRows(): MyPolicyRenderObject[] {
    return this.productCatalogue.product.plans
      .filter((plan) => this.policy.plans?.some(({ id }) => plan.id === id))
      .reduce((rows: MyPolicyRenderObject[], plan) => {
        MyPolicyRenderHelperMY.planNameLocalizeMap[plan.name] &&
          rows.push(this.mapTextView(MyPolicyRenderHelperMY.planNameLocalizeMap[plan.name]));

        const newRows = plan.benefits
          .map((e) => new Benefit(e))
          .reduce((newRows: MyPolicyRenderObject[], benefit) => {
            const mainRow: MyPolicyRenderObject = {
              type: CellType.ExpandableCard,
              key: benefit.id,
              label: '',
              title: benefit.getTitle()
            };
            const label =
              benefit.id === 'GTL.TPD'
                ? benefit.getSummary().replace('<li>\n        (b)', '<li>(b)')
                : benefit.getSummary();
            const children: MyPolicyRenderObject[] = [
              {
                type: CellType.ItemRow,
                key: benefit.id,
                title: '',
                label,
                value: this.getBenefitValueString(benefit),
                highlighted: true
              },
              ...this.recursivelyMapBenefit(benefit)
            ];
            newRows.push({
              ...mainRow,
              children
            });
            return newRows;
          }, rows);
        plan.optionalPlans
          ?.filter((catalogOptionalPlan) =>
            this.policy.plans?.some(({ optionalPlans }) =>
              optionalPlans.some(({ id }) => id === catalogOptionalPlan.id)
            )
          )
          .reduce((newRows, plan) => {
            newRows.push(this.mapOptionalBenefit(plan));
            return newRows;
          }, newRows);
        return newRows;
      }, []);
  }
}
