import styled from 'styled-components/native';

import { grey, red } from '../../assets/colors';
import { getShadowStyles } from '../../assets/mixins';

interface StyledProps {
  disabled?: boolean;
}

const buttonShadow = getShadowStyles();

const getBackgroundColor = ({ disabled }: StyledProps): string => (disabled ? grey : red);

export const Button = styled.TouchableOpacity`
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: 30px;
  right: 30px;
  width: 54px;
  height: 54px;
  border-radius: 54px;
  background-color: ${getBackgroundColor};
  ${buttonShadow}
`;

export const Arrow = styled.Image`
  width: 12px;
  height: 23px;
  resize-mode: contain;
`;
