import React, { useState, ReactElement, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import MapView, { PROVIDER_GOOGLE, Marker } from '../../components/MapView';
import { useTranslation } from 'react-i18next';

import { Container } from './SeeDoctorStyles';
import { StackNavigationProp } from '../../types/navigation';
import Screen from '../../components/Screen';
import { PruText } from '@ebsme/pulse-rn-components';
import { Clinic } from '../../types/clinic';
import { callPhoneNumber } from '../../utils/platform';
import { openMap } from '../../utils/mapUtil';
import { Coordinate } from '../../types/coordinate';
import ActionableText from '../../components/ActionableText';
import Whitespace from '../../components/Whitespace';
import HorizontalSeparator from '../../components/HorizontalSeparator';
import MapLoader from '../../components/MapLoader';

// Prudential Marina One Tower
const DEFAULT_LOCATION = {
  latitude: 1.2954152,
  longitude: 103.8275735
};

interface NavigationProps extends StackNavigationProp {
  state: {
    params: {
      item: Clinic;
    };
  };
}

type Props = {
  navigation: NavigationProps;
  bookmarkClinic: (clinic: Clinic) => void;
  unbookmarkClinic: (clinic: Clinic) => void;
};

const ClinicDetails: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();
  const clinic = navigation.state?.params?.item || {};
  const { name, address, operationHours, phoneNo, latitude, longitude } = clinic;
  const [showMap, setShowMap] = useState(false);

  const styles = StyleSheet.create({
    container: {
      height: 400,
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: 24
    },
    map: {
      ...StyleSheet.absoluteFillObject
    }
  });

  const mapMarkers = (): ReactElement => {
    const coordinate = latitude && longitude ? { latitude, longitude } : DEFAULT_LOCATION;
    return <Marker coordinate={coordinate} title={name} description={address} tracksViewChanges={false} />;
  };

  useEffect(() => {
    const id = setTimeout(() => setShowMap(true), 800);
    return (): void => {
      clearTimeout(id);
    };
  }, [showMap]);

  return (
    <Screen
      heading={name}
      headerPaddingHorizontal={24}
      onPressBack={(): void => {
        navigation.goBack();
      }}
    >
      <View style={styles.container}>
        {showMap ? (
          <MapView
            provider={PROVIDER_GOOGLE} // remove if not using Google Maps
            style={styles.map}
            showsUserLocation={true}
            region={{
              latitude: latitude || DEFAULT_LOCATION.latitude,
              longitude: longitude || DEFAULT_LOCATION.longitude,
              latitudeDelta: 0.015,
              longitudeDelta: 0.0121
            }}
          >
            {mapMarkers()}
          </MapView>
        ) : (
          <MapLoader height={400} />
        )}
      </View>

      <Container>
        <PruText marginTop={12} color="black" weight="bold">
          {t('SEE_DOCTOR__CLINIC_DETAILS__ADDRESS')}
        </PruText>
        <PruText marginTop={12} color="black">
          {address}
        </PruText>
        <ActionableText
          style={{ marginTop: 12, alignSelf: 'flex-start' }}
          onPress={(): void => {
            openMap(t, { latitude, longitude } as Coordinate, name);
          }}
        >
          {t('SEE_DOCTOR__CLINIC_DETAILS__GET_DIRECTION')}
        </ActionableText>
        <HorizontalSeparator style={{ marginVertical: 12 }} />

        <PruText weight="bold" color="black">
          Contact
        </PruText>
        <PruText marginTop={12} color="black">
          {phoneNo}
        </PruText>
        <ActionableText
          style={{ marginTop: 12, alignSelf: 'flex-start' }}
          onPress={(): void => {
            if (phoneNo) {
              callPhoneNumber(phoneNo);
            }
          }}
        >
          {t('SEE_DOCTOR__CLINIC_DETAILS__CALL')}
        </ActionableText>

        <HorizontalSeparator style={{ marginVertical: 12 }} />
        <PruText weight="bold" color="black">
          {t('SEE_DOCTOR__CLINIC_DETAILS__OPERATING_HOURS')}
        </PruText>
        <PruText marginTop={12} color="black">
          {operationHours?.join('\n')}
        </PruText>

        <Whitespace height={24} />
      </Container>
    </Screen>
  );
};

export default ClinicDetails;
