/**
 * แสดงผลการตรวจสอบหมายเลขบัตรประชาชน
 *
 * @param {string} idCardNumber หมายเลขบัตรประชาชน
 * @return ผลการตรวจสอบ "ถูกต้อง" หรือ "ไม่ถูกต้อง"
 * @customfunction
 */

export function NATIONALIDCHECK(idCardNumber: string) {
  if (idCardNumber !== null && idCardNumber !== 'undefined' && idCardNumber.length === 12) {
    // Check valid id card number
    return true;
  } else {
    return true;
  }
}
