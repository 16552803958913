import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { StackNavigationProp } from '../../../types/navigation';
import { ActionCallback } from '../../../types/utils';
import { PulseUser, OTPTYPE, Credential } from '../../../types/user';
import ValidationOTP from '../../../assets/svgs/banners/ValidationOTP.svg';
import Resend from '../../../assets/svgs/icons/Resend.svg';
import { PruButton, PruText } from '@ebsme/pulse-rn-components';
import OTPInput from '../../../components/OTPInput';
import OnboardingView from '../OnboardingView';

import { ButtonContainer } from './OnboardingVerificationScreenStyles';
import RNSInfo from '../../../components/SensitiveInfo';
import { encryptUser } from '../../../utils/encrypt';
import {
  EVENT_ANALYTICS,
  OBJECT_ID_CLICK,
  logFirebaseEventClick,
  logFirebaseEventScreen
} from '../../../utils/analytics';

interface Props {
  navigation: StackNavigationProp<
    {},
    {
      email: string;
      nationalId: string;
      phone: string;
      otpType: OTPTYPE;
      rememberMe: boolean;
    }
  >;
  validatingOTP?: boolean;
  validateOTPSuccess?: boolean;
  validateOTPError?: string;
  authCodeRef?: string;
  validateOTP: (
    authCodeRef: string,
    otp: string,
    currentLoginId: string,
    rememberMe: boolean,
    callback: ActionCallback
  ) => void;
  registeringCorporateUser?: boolean;
  registerCorporateUserError?: string;
  pulseUser?: PulseUser;
  registerCorpUser: (
    ppulseUser: PulseUser,
    email: string,
    nationalId: string,
    phone: string,
    softToken: string | undefined,
    callback: ActionCallback
  ) => void;
  setLastPulseUser: (pulseUser: PulseUser) => void;
  clearRegisterError: () => void;
  clearValidateError: () => void;
}

let countdownTimeout: ReturnType<typeof setTimeout>;

const OnboardingVerificationScreen: React.FC<Props> = ({
  navigation,
  validatingOTP,
  validateOTPError,
  authCodeRef,
  validateOTP,
  registeringCorporateUser,
  registerCorporateUserError,
  pulseUser,
  registerCorpUser,
  setLastPulseUser,
  clearRegisterError,
  clearValidateError
}) => {
  const otpType = navigation.getParam('otpType');

  const { t } = useTranslation();
  const [otp, setOTP] = useState('');
  const [countDown, setCountDown] = useState(otpType == OTPTYPE.EMAIL ? 180 : 60);
  const [retryError, setRetryError] = useState(false);

  const email = navigation.getParam('email');
  const nationalId = navigation.getParam('nationalId');
  const phone = navigation.getParam('phone');
  const rememberMe = navigation.getParam('rememberMe');

  const handleChangeOTPText = (value: string): void => {
    setOTP(value);
  };

  const handleResend = (): void => {
    logFirebaseEventClick(EVENT_ANALYTICS.login, {
      feature: 'Login',
      journey: 'login',
      stage: 'login_otp',
      screen_id: 'SCR_EB_LOGIN_OVS',
      screen_name: 'OnboardingVerificationScreen',
      object_id: OBJECT_ID_CLICK.resend
    });
    clearTimeout(countdownTimeout);
    setCountDown(otpType == OTPTYPE.EMAIL ? 180 : 60);
    if (!validatingOTP && !registeringCorporateUser && countDown === 0) {
      setOTP('');
      clearDataScreen();

      if (!pulseUser) {
        setRetryError(true);
        return;
      }

      registerCorpUser(pulseUser, email, nationalId, phone, undefined, (success) => {
        if (success) {
          setCountDown(otpType == OTPTYPE.EMAIL ? 180 : 60);
        } else {
          setRetryError(true);
        }
      });
    }
  };

  const clearDataScreen = () => {
    clearRegisterError();
    clearValidateError();
    setRetryError(false);
  };
  const storeUserCredentials = async () => {
    try {
      const credential: Credential = {
        nationalId: nationalId,
        email: email,
        phone: phone,
        rememberMe: rememberMe,
        timestamp: moment(new Date()).valueOf()
      };
      await removeCredentials();
      await RNSInfo.setItem('user_credentials', JSON.stringify(encryptUser(credential)), {
        sharedPreferencesName: 'sdkSharedPrefs',
        keychainService: 'sdkKeychain'
      });
    } catch (error) {}
  };

  const removeCredentials = async () => {
    try {
      RNSInfo.deleteItem('user_credentials', {
        sharedPreferencesName: 'sdkSharedPrefs',
        keychainService: 'sdkKeychain'
      });
    } catch (error) {}
  };

  // Clean up timeouts when the screen is unmounted
  useEffect(() => {
    logFirebaseEventScreen(EVENT_ANALYTICS.login, {
      feature: 'Login',
      journey: 'login',
      stage: 'login_otp',
      screen_id: 'SCR_EB_LOGIN_OVS',
      screen_name: 'OnboardingVerificationScreen'
    });
    clearDataScreen();
    return (): void => {
      clearTimeout(countdownTimeout);
      clearDataScreen();
    };
  }, []);

  useEffect(() => {
    if (authCodeRef && otp.length === 6) {
      validateOTP(authCodeRef, otp, nationalId, rememberMe, (success) => {
        if (success && pulseUser) {
          // logFirebaseEvent(EVENT_ANALYTICS.validate_otp_success, {});
          setLastPulseUser(pulseUser);
          if (rememberMe) {
            storeUserCredentials();
          } else {
            removeCredentials();
          }
        } else {
          setOTP('');
        }
      });
    }
  }, [navigation, otp, validateOTP, authCodeRef, setLastPulseUser, pulseUser]);

  // useEffect(() => {
  //   if (!validatingOTP) {
  //     clearTimeout(countdownTimeout);
  //     setCountDown(otpType == OTPTYPE.EMAIL ? 180 : 60);
  //   }
  // }, [validatingOTP]);

  useEffect(() => {
    if (countDown !== 0) {
      countdownTimeout = setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    }
  }, [countDown]);

  const errorMessage =
    retryError || registerCorporateUserError
      ? t('ONBOARDING__VERIFICATION__RETRY_ERROR')
      : validateOTPError
      ? t('ONBOARDING__VERIFICATION__INCORRECT')
      : undefined;

  return (
    <OnboardingView
      background={<ValidationOTP />}
      onPressBack={(): void => {
        navigation.goBack();
      }}
    >
      <PruText size={16} color="black" align="center">
        {t('ONBOARDING__VERIFICATION__SENT_MESSAGE')}
      </PruText>
      <PruText size={16} weight="semiBold" color="black" align="center" marginBottom={20}>
        {otpType == OTPTYPE.EMAIL ? email : phone}
      </PruText>
      <OTPInput
        otp={otp}
        label={t('ONBOARDING__VERIFICATION__INPUT_LABEL')}
        errorMessage={errorMessage}
        onChangeText={handleChangeOTPText}
      />
      <ButtonContainer>
        {!validatingOTP && (
          <PruText size={12} color="darkBlue" weight="semiBold" align="center" marginBottom={8}>
            {t('ONBOARDING__VERIFICATION__NO_CODE')}
          </PruText>
        )}

        <PruButton
          type="secondary"
          onPress={handleResend}
          disabled={validatingOTP || countDown !== 0}
          rightIcon={
            !validatingOTP && countDown === 0 ? <Resend style={{ marginLeft: 6 }} height={13} width={13} /> : undefined
          }
        >
          {validatingOTP
            ? t('ONBOARDING__VERIFICATION__CHECKING')
            : `${t('ONBOARDING__VERIFICATION__RESEND')}${countDown === 0 ? '' : ` (${countDown}s)`}`}
        </PruButton>
      </ButtonContainer>
      <PruText size={12} color="darkGrey" align="center" marginBottom={32}>
        {t('ONBOARDING__VERIFICATION__CHECK_SPAM')}
      </PruText>
    </OnboardingView>
  );
};

export default OnboardingVerificationScreen;
