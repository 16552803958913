import styled from 'styled-components/native';

import * as Colours from '../../../assets/colors';

export const Item = styled.View<{ highlighted?: boolean }>`
  margin: 10px 0px 0px 7px;
  width: 100%;
  ${({ highlighted }): string => (highlighted ? `background-color: ${Colours.transGrey};` : '')}
`;
export type BoldColorOptions = 'black' | 'blue';
