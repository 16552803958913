import React, { useMemo } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Policy } from '../../types/policy';
import ExpandableCard from '../ExpandableCard';
import MyPolicyItem from './MyPolicyItem';
import { TFunction } from 'i18next';
import { PruText } from '@ebsme/pulse-rn-components';
import MyPolicyCheckedItem from './MyPolicyCheckedItem';
import { CellType, MyPolicyRenderObject, BenefitItem } from './MyPolicyRenderHelper';
import { Locale } from '../../types/sdk';
import { Dependant } from '../../types/claim';
import ItemRemainingBenefit from './ItemRemainingBenefit/ItemRemainingBenefit';
interface Props {
  policy: Policy;
  policyDetail: any;
  dependants: Dependant[];
  isShowDependantView?: boolean;
  lBUCode: string;
  locale: Locale;
  selectDependant: (policyNumber: string) => void;
  selected: string;
}

function renderRow(
  item: MyPolicyRenderObject,
  sdkTranslation: TFunction,
  locale: Locale,
  dependants: Dependant[],
  selectDependant: (policyNumber: string) => void,
  selected: string,
  mainName?: string
): React.ReactElement | undefined {
  switch (item.type) {
    case CellType.DependantView:
      return (
        <MyPolicyCheckedItem
          key={item.key}
          label={locale === 'en' ? item.label?.EN : item.label?.TH}
          checked={dependants && dependants.length > 0}
          dependants={dependants}
          selectDependant={selectDependant}
          selected={selected}
          mainName={mainName}
        />
      );
    case CellType.Benefit:
      if (item.remainingItem) {
        return <ItemRemainingBenefit key={`${item.key}Benefit`} item={item.remainingItem} locale={locale} />;
      }
      break;
    case CellType.ExpandableCard:
      return (
        <ExpandableCard
          key={item.key}
          title={locale === 'en' ? item.title?.EN : item.title?.TH}
          subtitle={item.subtitle}
        >
          {(!!item.children &&
            item.children.map((e) => renderRow(e, sdkTranslation, locale, dependants, selectDependant, selected))) ||
            renderRow(
              { ...item, type: CellType.ItemRow },
              sdkTranslation,
              locale,
              dependants,
              selectDependant,
              selected
            )}
        </ExpandableCard>
      );
    case CellType.ItemRow:
      return (
        <MyPolicyItem
          key={item.key}
          value={item.value}
          label={locale === 'en' ? item.label?.EN : item.label?.TH}
          flex={item.flex}
          highlighted={item.highlighted}
          boldColor={item.boldColor}
          leadingNumber={item.leadingNumber}
          currency={item.currency || ''}
        />
      );
    // case CellType.PlanTitle:
    //   return (
    //     <Text key={`${item.key}_plan_title`} style={{ color: 'black', fontSize: 14, fontWeight: '700' }}>
    //       {sdkTranslation(item.label)}
    //     </Text>
    //   );
    // case CellType.SectionTitle:
    //   return (
    //     <Text key={`${item.key}_section_title`} style={{ color: 'black', fontSize: 12 }}>
    //       {item.label}
    //     </Text>
    //   );
  }
}

const MyPolicy: React.FC<Props> = ({
  policy,
  policyDetail,
  isShowDependantView,
  lBUCode,
  locale,
  dependants,
  selectDependant,
  selected
}) => {
  const { t: sdkTranslation } = useTranslation();
  const mainName = `${policy.employees && policy.employees[0].givenName ? policy.employees[0].givenName : ''} ${
    policy.employees && policy.employees[0].middleName ? policy.employees[0].middleName : ''
  } ${policy.employees && policy.employees[0].familyName ? policy.employees[0].familyName : ''}`
    .trim()
    .replace(/  +/g, ' ');
  const filterBenefits = (item: any) => {
    const benefits: any[] = [];
    item.benefitsList.map((benefit: any, indexBen: any) => {
      benefits.push({
        type: CellType.ItemRow,
        key: `${indexBen}`,
        label: benefit.name,
        value: benefit.value,
        highlighted: false,
        currency: benefit.type,
        leadingNumber: `${indexBen + 1}.`
      });
      if (benefit.children) {
        benefit.children.map((benChild: any, indexBenChild: any) => {
          benefits.push({
            type: CellType.ItemRow,
            key: `${indexBen}${indexBenChild}`,
            label: benChild.name,
            value: benChild.value,
            highlighted: false,
            leadingNumber: `${indexBen + 1}.${indexBenChild + 1}`,
            currency: benChild.type
          });
        });
      }
    });
    return benefits;
  };

  const cards: MyPolicyRenderObject[] = useMemo(() => {
    const dependantView: MyPolicyRenderObject[] = [];
    isShowDependantView &&
      dependantView.push({
        type: CellType.DependantView,
        key: 'MyPolicyCheckedItemKey',
        label: {
          EN: 'Dependants Included',
          TH: 'รวมผู้อยู่ในอุปการะ'
        },
        highlighted: policy.allowDependants
      });
    if (
      policyDetail?.data?.getPolicyAndProducts?.remainingBenefits &&
      policyDetail?.data?.getPolicyAndProducts?.remainingBenefits.length > 0
    ) {
      policyDetail?.data?.getPolicyAndProducts?.remainingBenefits.map((item: BenefitItem, index: any) => {
        dependantView.push({ type: CellType.Benefit, key: index, title: item.name, subtitle: '', remainingItem: item });
      });
    }
    policyDetail?.data?.getPolicyAndProducts?.products.map((item: any, index: any) => {
      dependantView.push({
        type: CellType.ExpandableCard,
        key: index,
        title: item.name,
        subtitle: '',
        children: filterBenefits(item)
      });
    });
    return dependantView;
  }, [isShowDependantView, policyDetail]);

  return (
    <View testID="MyPolicy">
      <>{cards.map((e) => renderRow(e, sdkTranslation, locale, dependants, selectDependant, selected, mainName))}</>
      {lBUCode === 'PH' && (
        <PruText marginBottom={20}>
          Disclaimer: In the event of any subsequent claim, the amount payable shall be net of all previous claims
          already paid.
        </PruText>
      )}
      {lBUCode === 'TH' && policyDetail?.data?.getPolicyAndProducts?.disclaimerFlag && (
        <PruText testID="DisclaimerPLT" color="red" marginBottom={20}>
          {sdkTranslation('MYPOLICY__DISCLAIMER')}
        </PruText>
      )}
    </View>
  );
};

export default MyPolicy;
