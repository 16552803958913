import React from 'react';
import { Text, View } from 'react-native';

interface Props {
  label: string;
  value: string;
  secondaryValue?: string;
  textSize?: number;
}

const ClaimMetaItem: React.FC<Props> = ({ label, value, secondaryValue, textSize = 20 }) => {
  return (
    <View
      testID={`${label}__${value}`}
      style={{
        padding: 20
      }}
    >
      <Text
        style={{
          color: '#20365A',
          fontSize: 14,
          fontWeight: 'bold',
          marginBottom: 10
        }}
      >
        {label}
      </Text>
      <Text
        style={{
          color: '#20365A',
          fontSize: textSize,
          marginBottom: secondaryValue ? 4 : 10
        }}
      >
        {value}
      </Text>
      {Boolean(secondaryValue) && (
        <Text
          style={{
            color: '#20365A',
            fontSize: textSize * 0.75,
            marginBottom: 10
          }}
        >
          {secondaryValue}
        </Text>
      )}
    </View>
  );
};

export default ClaimMetaItem;
