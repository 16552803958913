import React, { useState, useEffect } from 'react';
import { isAfter, endOfDay, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';

import * as routes from '../../../routes';
import { StackNavigationProp } from '../../../types/navigation';

import { PruInput, PruPickerField, PruText, PruSpace } from '@ebsme/pulse-rn-components';
import MakeClaimView from '../MakeClaimView';
import { Claim } from '../../../types/claim';
import { CURRENCY_LIST, BANK_LIST, formatAmountValue, DIAGNOSIS_LIST } from '../../../utils/claim';
import { View } from 'react-native';
import { PruInputDate } from '../../../components/PruInputDate'

export interface Props {
  navigation: StackNavigationProp;
  claim: Claim;
  updateClaim?: (claim: Claim) => void;
}

const ClaimPaymentScreen: React.FC<Props> = ({ navigation, claim, updateClaim = (): void => {} }) => {
  const { t } = useTranslation();
  const DATE_FORMAT = 'dd/MM/yyyy';

  const [nextDisabled, setNextDisabled] = useState(true);

  const [incurredDate, setIncurredDate] = useState(claim.incurredDate ?? '');
  const [incurredDateErrorMessage, setIncurredDateErrorMessage] = useState('');

  const [amount, setAmount] = useState(claim.amount ?? '');
  const [amountErrorMessage, setAmountErrorMessage] = useState('');

  const [reason, setReason] = useState(claim.reason ?? '');
  const [remark, setRemark] = useState(claim.remarks ?? '');

  const [bankName, setBankName] = useState(claim.bankName ?? '');

  const [accountNumber, setAccountNumber] = useState(claim.accountNumber ?? '');

  const [accountHolderName, setAccountHolderName] = useState(claim.accountHolderName ?? '');

  const [receiptsCurrency, setReceiptsCurrency] = useState(claim.receiptsCurrency ?? CURRENCY_LIST[0].value);

  useEffect(() => {
    // refresh default value if empty.
    updateClaim({
      ...claim,
      incurredDate,
      bankName,
      receiptsCurrency,
      coveragePerson: 'E'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountHolderName && accountNumber && amount && bankName && incurredDate && reason && receiptsCurrency) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  }, [accountHolderName, accountNumber, amount, bankName, incurredDate, reason, receiptsCurrency]);

  const onDiagnosisChange = (value: string): void => {
    setReason(value);
    updateClaim({
      ...claim,
      reason: value
    });
  };

  const onRemarksChange = (value: string): void => {
    setRemark(value);
    updateClaim({
      ...claim,
      remarks: value
    });
  };

  const onIncurredDateChange = (date: string): void => {
    if (isAfter(parse(date, DATE_FORMAT, new Date()), endOfDay(new Date()))) {
      setIncurredDateErrorMessage(t('ERROR__FUTURE_DATE'));
    } else {
      setIncurredDateErrorMessage('');
      setIncurredDate(date);
      updateClaim({
        ...claim,
        incurredDate: date
      });
    }
  };

  const onAmountValueChange = (value: string): void => {
    if (!value.includes(',')) {
      // when typing
      if (value.includes('.')) {
        const [digit, dec] = value.split('.');
        value = digit.substring(0, 20) + '.' + dec.substring(0, Math.min(2, dec.length));
      } else {
        value = value.substring(0, 20);
      }
    }
    setAmount(value);
    setAmountErrorMessage('');
    updateClaim({
      ...claim,
      amount: value
    });
  };

  const onBankNameChange = (value: string): void => {
    setBankName(value as string);
    updateClaim({
      ...claim,
      bankName: value
    });
  };

  const onAccountNumberChange = (value: string): void => {
    setAccountNumber(value);
    updateClaim({
      ...claim,
      accountNumber: value
    });
  };

  const onAccountHolderNameChange = (value: string): void => {
    setAccountHolderName(value);
    updateClaim({
      ...claim,
      accountHolderName: value
    });
  };

  const onReceiptsCurrencyChange = (value: string): void => {
    setReceiptsCurrency(value);
    updateClaim({
      ...claim,
      receiptsCurrency: value
    });
  };

  return (
    <MakeClaimView
      title={t('REVIEW_CLAIMS__CLAIM_INFORMATION')}
      currentRoute={routes.ClaimPaymentScreen}
      nextDisabled={nextDisabled}
      navigation={navigation}
      enableSave
      backVisible
    >
      <View style={{ margin: 20, marginBottom: 50 }}>
        <PruPickerField
          label={'Diagnosis'}
          placeholder={'Select'}
          value={reason}
          items={DIAGNOSIS_LIST}
          onValueChange={onDiagnosisChange}
        />
        <PruSpace />
        <PruInput
          value={remark}
          onChangeText={onRemarksChange}
          label={'Remarks'}
          placeholder={''}
          maxLength={200}
          multiline
          numberOfLines={2}
          errorMessage={''}
        />
      </View>
      <PruText color="black" size={18} weight="bold" marginLeft={20} marginBottom={20}>
        {t('REVIEW_CLAIMS__PAYMENT_INFORMATION')}
      </PruText>
      <View style={{ margin: 20, marginBottom: 100 }}>
        <PruInputDate
          value={incurredDate}
          label={`${t('REVIEW_CLAIMS__CLAIM_INCURRED_DATE')} (${DATE_FORMAT.toUpperCase()})`}
          dateFormat={DATE_FORMAT}
          onDateSelected={onIncurredDateChange}
          placeholder={''}
          errorMessage={incurredDateErrorMessage}
        />
        <PruSpace />
        <PruInput
          value={amount}
          onChangeText={onAmountValueChange}
          onFocus={(): void => onAmountValueChange(amount.replace(/,/g, ''))}
          onBlur={(): void => onAmountValueChange(formatAmountValue(amount))}
          label={t('REVIEW_CLAIMS__AMOUNT')}
          placeholder={''}
          keyboardType="numeric"
          errorMessage={amountErrorMessage}
        />
        <PruSpace />
        <PruPickerField
          label={t('REVIEW_CLAIMS__BANK_NAME')}
          placeholder={'Select Bank'}
          value={bankName}
          items={BANK_LIST}
          onValueChange={onBankNameChange}
        />
        <PruSpace />
        <PruInput
          value={accountNumber}
          onChangeText={onAccountNumberChange}
          label={t('REVIEW_CLAIMS__ACCOUNT_NUMBER')}
          placeholder={''}
          keyboardType="numeric"
          autoCorrect={false}
          maxLength={20}
          errorMessage={''}
        />
        <PruSpace />
        <PruInput
          value={accountHolderName}
          onChangeText={onAccountHolderNameChange}
          label={t('REVIEW_CLAIMS__ACCOUNT_HOLDER_NAME')}
          placeholder={''}
          maxLength={30}
          errorMessage={''}
        />
        <PruSpace />
        <PruPickerField
          label={t('REVIEW_CLAIMS__RECEIPTS_CURRENCY')}
          value={receiptsCurrency}
          items={CURRENCY_LIST}
          onValueChange={onReceiptsCurrencyChange}
        />
      </View>
    </MakeClaimView>
  );
};

export default ClaimPaymentScreen;
