import styled from 'styled-components/native';

interface StyledProps {
  height: number;
}

const getHeight = ({ height }: StyledProps): string => `${height}px`;

export const StyledWhitespace = styled.View`
  height: ${getHeight};
`;
