import React, { useRef, useEffect, useState } from 'react';
import { Image, TouchableOpacity, View, Dimensions, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import * as bannerImages from '../../../assets/images/banners';

import { PruText } from '@ebsme/pulse-rn-components';
import { ECareCard } from '../../../types/policy';

import { BannerFooter, BannerFooterRight, BannerTH } from './LandingScreenBannerStyles';
import { getDateFormat } from '../../../utils/claim';

import { getNavigate } from '../../../utils/navigation';
import * as routes from '../../../routes';
import { StackNavigationProp } from '../../../types/navigation';
import Swiper from '../../../components/Swiper';
import { EVENT_ANALYTICS, OBJECT_ID_CLICK, logFirebaseEventClick } from '../../../utils/analytics';

const screenWidth = Dimensions.get('screen').width;

const imageWidth = (Platform.OS === 'web' ? Math.min(480, screenWidth) : screenWidth) - 20;

interface Props {
  navigation: StackNavigationProp;
  lbu?: string;
  eCareCards?: ECareCard[];
  policyStartDate?: Moment;
  policyNo?: string;
  name?: string;
  eCareCardLoading: boolean;
  calledEcareCard: boolean;
}

const LandingScreenBanner: React.FC<Props> = ({
  navigation,
  lbu,
  eCareCards,
  policyStartDate,
  policyNo,
  name,
  eCareCardLoading,
  calledEcareCard
}) => {
  const { t } = useTranslation();
  const dateFormat = getDateFormat(lbu);
  const prevLoadingRef = useRef<boolean>();

  useEffect(() => {
    prevLoadingRef.current = eCareCardLoading;
  }, [eCareCardLoading]);

  if (!calledEcareCard) {
    return null;
  }

  if (!eCareCards || eCareCards?.length === 0)
    return (
      <BannerTH testID="ECardBanner" source={bannerImages.landing}>
        <View style={{ padding: 24 }}>
          <View style={{ marginBottom: 20 }}>
            <PruText color="white" size={18} weight="bold" testID="LandingScreenBanner_PolicyStartDate">
              {name ? name.trim().replace(/  +/g, ' ') : '-'}
            </PruText>
          </View>
          <BannerFooter>
            <View>
              <PruText color="white" size={12} weight="semiBold">
                {t('LANDING__COVERAGE_START_DATE')}
              </PruText>
              <PruText color="white" size={14} weight="bold" testID="LandingScreenBanner_PolicyStartDate">
                {policyStartDate?.isValid() ? policyStartDate?.format(dateFormat) : '-'}
              </PruText>
            </View>
            <BannerFooterRight>
              <PruText color="white" size={12} weight="semiBold">
                {t('LANDING__POLICY_NUMBER')}
              </PruText>
              <PruText color="white" size={14} weight="bold" testID="LandingScreenBanner_PolicyNo">
                {policyNo || '-'}
              </PruText>
            </BannerFooterRight>
          </BannerFooter>
        </View>
      </BannerTH>
    );
  return (
    <Swiper
      items={eCareCards}
      renderItem={(item, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => {
            logFirebaseEventClick(EVENT_ANALYTICS.card, {
              feature: 'EcareCard',
              journey: 'ecare_card',
              stage: 'card_start',
              screen_id: 'SCR_EB_LANDING_LANDS',
              screen_name: 'LandingScreen',
              object_id: OBJECT_ID_CLICK.tiles_card
            });
            getNavigate(navigation.navigate, routes.ECard)();
          }}
          style={{ alignSelf: 'center' }}
        >
          <Image
            resizeMode="stretch"
            key={item.seq}
            style={{ width: imageWidth, height: 280 }}
            source={{ uri: `data:image/png;base64,${item?.cardImagesBase64}` }}
          />
        </TouchableOpacity>
      )}
    />
  );
};

export default LandingScreenBanner;
