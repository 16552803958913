import React, { useState } from 'react';
import { PruInput, PruInputProps } from '@ebsme/pulse-rn-components';
import { parseDate, sanitizeDateFormat } from '@ebsme/pulse-rn-components/utils/DateUtil';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export interface PruInputDateProps extends PruInputProps {
  onDateSelected: (date: string) => void;
  dateFormat?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

const DEFAULT_VALUE = {
  dateFormat: 'yyyy-MM-dd',
  confirmButtonText: 'Select',
  cancelButtonText: 'Dismiss'
};

const PruInputDate: React.FC<PruInputDateProps> = ({
  dateFormat = DEFAULT_VALUE.dateFormat,
  value,
  onDateSelected,
  endIcon,
  confirmButtonText = DEFAULT_VALUE.confirmButtonText,
  cancelButtonText = DEFAULT_VALUE.cancelButtonText,
  testID = 'PruInputDate',
  ...props
}) => {
  const dateFnsDateFormat = sanitizeDateFormat(dateFormat);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [selectingDate, setSelectingDate] = useState<Date>(parseDate(value, dateFnsDateFormat));
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <PruInput
        testID={testID}
        pointerEvents="none"
        endIcon={endIcon || <PruIcon name="ios-calendar-outline" size={24} />}
        onPress={(): void => setDatePickerVisible(true)}
        value={value}
        {...props}
      />
      {/*<PruDatePickerModal*/}
      {/*  testID={`${testID}_PruDatePickerModal`}*/}
      {/*  isVisible={datePickerVisible}*/}
      {/*  selectedDate={selectingDate}*/}
      {/*  onConfirm={(): void => {*/}
      {/*    onDateSelected(formatDate(selectingDate as Date, dateFnsDateFormat));*/}
      {/*    setDatePickerVisible(false);*/}
      {/*  }}*/}
      {/*  onDateChange={(date: Date): void => setSelectingDate(date)}*/}
      {/*  onCancel={(): void => {*/}
      {/*    setDatePickerVisible(false);*/}
      {/*  }}*/}
      {/*  confirmButtonText={confirmButtonText}*/}
      {/*  cancelButtonText={cancelButtonText}*/}
      {/*/>*/}
    </LocalizationProvider>
  );
};

export default PruInputDate;
