import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import Icon from 'react-native-vector-icons/Ionicons';
import FastImage from '../../components/FastImage';

import { StackNavigationProp } from '../../types/navigation';
import Screen from '../../components/Screen';
import { PruButton, PruCard, PruText } from '@ebsme/pulse-rn-components';
import ShadedContainer from '../../components/ShadedContainer';
import { BankAccount } from '../../types/bankAccount';
import { Container, FullMarginContainer } from './BankAccountStyles';
import { bankDisplayName } from '../../utils/bankUtil';
import { formatBankAccountNumber } from '../../utils/bankUtil';
import HorizontalSeparator from '../../components/HorizontalSeparator';
import { grey } from '../../assets/colors';
import { useConfirmAlert } from '../../hooks/useConfirmAlert';

interface NavigationProps extends StackNavigationProp {
  state: {
    params: {
      item: BankAccount;
    };
  };
}

type Props = {
  navigation: NavigationProps;
};

const BankAccountViewScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmAlert();
  const bankAccount = navigation.state?.params?.item || {};
  const { accountNumber, accountHolderName, bank } = bankAccount;

  const [masking, setMasking] = useState(true);
  return (
    <Screen
      heading={t('BANK_ACCOUNT__ACCOUNT_INFORMATION')}
      headerPaddingHorizontal={24}
      onPressBack={(): void => {
        navigation.goBack();
      }}
    >
      <ShadedContainer>
        <Container>
          <PruCard style={{ padding: 0 }}>
            <FullMarginContainer>
              <PruText color="black" style={{ textTransform: 'uppercase', marginBottom: 12 }}>
                {t('BANK_ACCOUNT__ACCOUNT_HOLDER')}
              </PruText>
              <PruText testID="Screen__AccountHolderName" size={15} weight="bold" color="black">
                {accountHolderName}
              </PruText>
            </FullMarginContainer>
            <HorizontalSeparator />
            <FullMarginContainer>
              <PruText color="black" style={{ textTransform: 'uppercase', marginBottom: 12 }}>
                {t('BANK_ACCOUNT__BANK_NAME')}
              </PruText>
              <PruText testID="Screen__BankName" size={15} weight="bold" color="black">
                {bankDisplayName(bank)}
              </PruText>
              <FastImage
                testID="BankAccount_BankLogo"
                style={{
                  width: 28,
                  height: 28,
                  borderRadius: 4,
                  position: 'absolute',
                  top: 14,
                  right: 14
                }}
                source={{ uri: bank.logo }}
                resizeMode={FastImage.resizeMode.contain}
              />
            </FullMarginContainer>

            <HorizontalSeparator />
            <FullMarginContainer>
              <PruText color="black" style={{ textTransform: 'uppercase', marginBottom: 12 }}>
                {t('BANK_ACCOUNT__ACCOUNT_NUMBER')}
              </PruText>
              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                {accountNumber && (
                  <PruText testID="Screen__AccountNumber" size={15} weight="bold" color="black">
                    {formatBankAccountNumber(accountNumber, masking)}
                  </PruText>
                )}
                <TouchableOpacity
                  testID="Screen__EyeButton"
                  onPress={(): void => {
                    setMasking(!masking);
                  }}
                >
                  <Icon name={masking ? 'ios-eye-off' : 'ios-eye'} size={24} color={grey} />
                </TouchableOpacity>
              </View>
            </FullMarginContainer>
          </PruCard>
        </Container>
      </ShadedContainer>
      <PruButton
        testID="Screen__RemoveButton"
        style={{ margin: 24 }}
        onPress={(): void => {
          confirm({
            title: t('BANK_ACCOUNT__CONFIRM_REMOVE__TITLE'),
            buttonText: t('BANK_ACCOUNT__CONFIRM_REMOVE__BUTTON'),
            destructive: true,
            onConfirm: (): void => {}
          });
        }}
      >
        {t('BANK_ACCOUNT__REMOVE')}
      </PruButton>
    </Screen>
  );
};

export default BankAccountViewScreen;
