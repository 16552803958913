import React from 'react';
import { View } from 'react-native';
import { PruText } from '@ebsme/pulse-rn-components';

import { Item, BoldColorOptions } from './MyPolicyItemStyles';
import LayoutBox from '../../LayoutBox';
import HtmlText from './HtmlText';

export interface Props {
  label?: string;
  value?: React.ReactText;
  highlighted?: boolean;
  leadingNumber?: string;
  boldColor?: BoldColorOptions;
  indentStr?: string;
  flex?: boolean;
  currency: string;
}

const MyPolicyItem: React.FC<Props> = ({
  label = '',
  value,
  highlighted,
  leadingNumber,
  boldColor,
  flex,
  currency
}) => {
  const hasLabel = !!label;
  const hasValue = value !== null && typeof value !== 'undefined';
  const isBoldRow = !hasValue && !!boldColor;
  const indentedLevel = leadingNumber ? leadingNumber.split('.').filter((str) => str.length > 0).length : 0;
  const isColumn = !flex || !!boldColor;

  return (
    <Item
      testID={highlighted ? 'MyPolicyItem__Highlighted' : 'MyPolicyItem'}
      highlighted={highlighted}
      style={{ flex: 1 }}
    >
      <LayoutBox
        paddingLeft={indentedLevel > 0 ? (indentedLevel - 1) * 20 : 0}
        style={{
          flexDirection: isColumn ? 'column' : 'row',
          flex: 1,
          alignSelf: 'stretch',
          alignItems: 'flex-start'
        }}
      >
        {hasLabel && (
          <View style={!isBoldRow ? { flex: 1 } : {}}>
            <HtmlText label={label} boldColor={boldColor} leadingNumber={leadingNumber} value={value} />
          </View>
        )}
        {hasValue && (
          <View style={!hasLabel ? { flex: 1 } : {}}>
            <PruText testID="MyPolicyItem__Value" color="blue" size={13} weight="semiBold" align="center">
              {`   ${value} ${currency}`}
            </PruText>
          </View>
        )}
      </LayoutBox>
    </Item>
  );
};

export default MyPolicyItem;
