import React from 'react';
import { useTranslation } from 'react-i18next';
import HTML from 'react-native-render-html';

import { FAQItem } from '../../types/faqs';
import { searchArray } from '../../utils/search';

import { PruText } from '@ebsme/pulse-rn-components';
import ExpandableCard from '../ExpandableCard';
import { black } from '../../assets/colors';

interface Props {
  faqs: FAQItem[];
  searchTerm?: string;
}

const FAQList: React.FC<Props> = ({ faqs, searchTerm = '' }) => {
  const { t } = useTranslation();

  const filteredFaqs = searchArray(faqs, {
    question: searchTerm,
    answer: searchTerm
  });

  return (
    <>
      {filteredFaqs.length === 0 && <PruText color="black">{t('CLAIMSSUPPORT__FAQ__NO_RESULTS')}</PruText>}
      {filteredFaqs.map(({ question, answer }, i) => (
        <ExpandableCard
          testID={`FAQList__Card${i + 1}`}
          key={question}
          title={`${i + 1}. ${question}`}
          defaultExpanded={false}
        >
          <HTML html={answer} baseFontStyle={{ color: black }} />
        </ExpandableCard>
      ))}
    </>
  );
};

export default FAQList;
