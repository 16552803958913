import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, View } from 'react-native';
import { Card, Button } from 'react-native-elements';

interface Props {
  onClose?: () => void;
  claimNumber: string;
}

const ClaimConfirmationView: React.FC<Props> = ({ onClose, claimNumber }) => {
  const { t } = useTranslation();

  return (
    <Card
      containerStyle={{
        margin: 0,
        marginTop: 20,
        borderWidth: 0,
        elevation: 0
      }}
    >
      <View testID="ClaimConfirmationView">
        <Text
          style={{
            fontSize: 14,
            marginTop: 20,
            marginBottom: 20,
            textAlign: 'center',
            color: '#000'
          }}
        >
          {t('CLAIMS__SUBMITTED__REFERENCE_NO')}
        </Text>
        <View
          style={{
            paddingVertical: 10,
            paddingHorizontal: 12
          }}
        >
          <Text
            style={{
              fontSize: 24,
              color: '#3F9AB9',
              alignSelf: 'center',
              textTransform: 'uppercase'
            }}
          >
            {claimNumber}
          </Text>
        </View>
      </View>
      {onClose ? (
        <View
          style={{
            marginTop: 100,
            marginBottom: 50
          }}
        >
          <Button
            testID="ConfirmationViewCloseBtn"
            title="Close"
            containerStyle={{
              margin: 0,
              marginStart: 0,
              marginEnd: 0,
              marginBottom: 10
            }}
            titleStyle={{
              color: '#FFFFFF',
              fontSize: 15
            }}
            buttonStyle={{
              margin: 5,
              backgroundColor: '#ED1B2E',
              borderRadius: 50
            }}
            onPress={onClose}
          />
        </View>
      ) : null}
    </Card>
  );
};

export default ClaimConfirmationView;
