import { Linking } from 'react-native';
import { FilterClinic, FilterDentalClinic, FilterHospital } from '../assets/svgs/icons';
import { EVENT_ANALYTICS, logFirebaseEvent } from './analytics';
import { openURL } from './platform';

export const openMapDirection = (currentLocation: any, destinationItem: any) => {
  if (!destinationItem || !currentLocation) {
    return;
  }
  let url = '';
  if (currentLocation.lat && currentLocation.lng) {
    url = `https://www.google.com/maps/dir/?api=1&origin=${currentLocation.lat},${currentLocation.lng}&destination=${destinationItem.lat},${destinationItem.lng}&travelmode=driving`;
  } else {
    url = `https://www.google.com/maps/search/?api=1&query=${destinationItem.lat},${destinationItem.lng}`;
  }
  openURL(url);
};

export const openPhone = (phone_number: string) => {
  const phone = `tel:${phone_number}`;
  Linking.canOpenURL(phone)
    .then((supported) => {
      if (!supported) {
      } else {
        return Linking.openURL(phone);
      }
    })
    .catch((err) => console.log(err));
};

export const compareType = (k1: string, k2: string) => {
  return getLetterOnly(k1).toLowerCase() == getLetterOnly(k2).toLowerCase();
};

export const getLetterOnly = (t: string) => {
  const a = t.replace(/[^a-zA-Z]+/g, '');
  return a;
};

export const getClinicIcon = (type: string) => {
  if (compareType(type, 'General hospital') || compareType(type, 'Specialist hospital')) {
    return 'hospital';
  } else if (compareType(type, 'General clinic') || compareType(type, 'Specialist clinic')) {
    return 'clinic';
  } else if (compareType(type, 'Dental hospital') || compareType(type, 'Dental clinic')) {
    return 'dclinic';
  } else {
    return 'hospital';
  }
};
