import Big from 'big.js';
Big.DP = 2;

const KB_BYTES = 1024;

export const getBase64FileSize = (base64Str = ''): Big => {
  const stringLength = Big(base64Str.length);
  const y = base64Str.endsWith('==') ? 2 : 1;
  return stringLength.div(4).mul(3).minus(y).div(KB_BYTES);
};

export const formatNumber = (num = Big(0), precision = 2): string => {
  const strNum = num.toString();

  if (!strNum.includes('.')) {
    return strNum;
  }

  let trimmedNumStr = num.toFixed(precision);

  while ((trimmedNumStr.endsWith('0') && trimmedNumStr.includes('.')) || trimmedNumStr.endsWith('.')) {
    trimmedNumStr = trimmedNumStr.slice(0, -1);
  }

  return trimmedNumStr;
};

export const getFormattedFileSize = (sizeKb: Big): string => {
  if (sizeKb.gte(1000)) {
    return `${formatNumber(sizeKb.div(KB_BYTES))}mb`;
  }

  return `${formatNumber(sizeKb)}kb`;
};

export const getFormattedBase64FileSize = (input: string): string => getFormattedFileSize(getBase64FileSize(input));
