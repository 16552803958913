import app_ebsme_pulse from './app_ebsme_pulse.json';
import global_plt from './global_plt.json';
import global_pmli from './global_pmli.json';

const myResource = {
  app_ebsme_pulse,
  global_plt,
  global_pmli
};

export default myResource;
