import { setSDKException } from './../SDKReducer/actions';
import { Dispatch } from 'redux';
import { path } from 'ramda';

import { ActionCallback } from '../../types/utils';
import { CorporateUser, PulseUser, PulseConfig } from '../../types/user';
import { getUserCountryCode } from '../PulseUserReducer/selectors';
import * as api from './api';
import { RootState } from '../../store';
import {
  CLEAR_CORPORATE_USER,
  ClearCorporateUser,
  FETCH_CORP_USER_FAILED,
  FETCH_CORP_USER_NOT_FOUND,
  FETCH_CORP_USER_START,
  FETCH_CORP_USER_SUCCESS,
  FetchCorpUserAction,
  FetchCorpUserFailed,
  FetchCorpUserNotFound,
  FetchCorpUserStart,
  FetchCorpUserSuccess,
  REGISTER_CORP_USER_FAILED,
  REGISTER_CORP_USER_START,
  REGISTER_CORP_USER_SUCCESS,
  RegisterCorpUserAction,
  RegisterCorpUserFailed,
  RegisterCorpUserStart,
  RegisterCorpUserSuccess,
  FORGET_CREDENTIALS_START,
  FORGET_CREDENTIALS_SUCCESS,
  FORGET_CREDENTIALS_FAILED,
  ForgetCredentialsStart,
  ForgetCredentialsSuccess,
  ForgetCredentialsFailed,
  UpdateCorpUser,
  UPDATE_CORP_USER,
  ClearRegisterError,
  CLEAR_REGISTER_ERROR,
  FetchPulseConfigAction,
  FETCH_PULSE_CONFIG_START,
  FETCH_PULSE_CONFIG_SUCCESS,
  FETCH_PULSE_CONFIG_FAILED,
  FetchPulseConfigStart,
  FetchPulseConfigSuccess,
  FetchPulseConfigFailed,
  ForgetCredentialsAction
} from './types';
import { setRefreshToken } from '../SessionReducer/actions';
import { SetRefreshToken } from '../SessionReducer/types';
import { EVENT_ANALYTICS, logFirebaseEvent, logFirebaseEventBackend } from '../../utils/analytics';

export const fetchCorpUserStart = (): FetchCorpUserStart => ({ type: FETCH_CORP_USER_START });

export const fetchCorpUserSuccess = (corporateUser: CorporateUser): FetchCorpUserSuccess => ({
  type: FETCH_CORP_USER_SUCCESS,
  payload: corporateUser
});

export const fetchCorpUserFailed = (fetchError: string): FetchCorpUserFailed => ({
  type: FETCH_CORP_USER_FAILED,
  payload: fetchError
});

export const fetchCorpUserNotFound = (): FetchCorpUserNotFound => ({
  type: FETCH_CORP_USER_NOT_FOUND
});

export const fetchCorpUser =
  (pulseUser: PulseUser) =>
  async (dispatch: Dispatch<FetchCorpUserAction>): Promise<void> => {
    dispatch(fetchCorpUserStart());

    try {
      const { data } = await api.getCorporateUser(pulseUser);

      if (data.getCorporateUser && data.getCorporateUser.email) {
        dispatch(fetchCorpUserSuccess(data.getCorporateUser));
      } else {
        dispatch(fetchCorpUserNotFound());
      }
    } catch (err) {
      dispatch(fetchCorpUserFailed(err.message));
    }
  };

export const registerCorpUserStart = (): RegisterCorpUserStart => ({ type: REGISTER_CORP_USER_START });

export const registerCorpUserSuccess = (authCodeRef: string): RegisterCorpUserSuccess => ({
  type: REGISTER_CORP_USER_SUCCESS,
  payload: authCodeRef
});

export const registerCorpUserFailed = (fetchError: string): RegisterCorpUserFailed => ({
  type: REGISTER_CORP_USER_FAILED,
  payload: fetchError
});

export const updateCorpUser = (user: Partial<CorporateUser>): UpdateCorpUser => ({
  type: UPDATE_CORP_USER,
  payload: user
});

export const registerCorpUser =
  (
    pulseUser: PulseUser,
    email: string,
    nationalId: string,
    phone: string,
    softToken: string | undefined,
    callback: ActionCallback,
    callbackOtpSkip?: ActionCallback
  ) =>
  async (dispatch: Dispatch<RegisterCorpUserAction | SetRefreshToken>, getState: () => RootState): Promise<void> => {
    dispatch(registerCorpUserStart());
    const countryCode = getUserCountryCode(getState()) ?? 'PH';
    try {
      logFirebaseEventBackend(EVENT_ANALYTICS.login, {
        feature: 'Login',
        journey: 'login',
        stage: 'login_end',
        status: 'success'
      });
      const { data } = await api.registerCorporateUser(pulseUser, email, nationalId, phone, countryCode, softToken);
      console.log('DATA Register', data);
      const authCodeRef: string = path(['registerPulseUser', 'authCodeRef'], data) || '';
      const otpSkip: boolean = path(['registerPulseUser', 'otpSkip'], data) || false;
      const token: string | undefined = path(['registerPulseUser', 'token'], data);
      if (otpSkip && token) {
        dispatch(setRefreshToken(token));
        callback(true);
      } else {
        if (!authCodeRef) {
          throw new Error('Corporate user registration failed');
        }
        dispatch(registerCorpUserSuccess(authCodeRef));
        dispatch(updateCorpUser({ email }));
        callback(true);
      }
      callbackOtpSkip && callbackOtpSkip(otpSkip);
    } catch (err: any) {
      logFirebaseEventBackend(EVENT_ANALYTICS.login, {
        feature: 'Login',
        journey: 'login',
        stage: 'login_end',
        status: 'fail'
      });
      if (err.message && !err.message.includes('PULSE_USER_NOT_FOUND_IN_EBSME')) {
        dispatch(setSDKException(err));
      }
      dispatch(registerCorpUserFailed(err.message));

      callback(false);
    }
  };
export const forgetCredentialsStart = (): ForgetCredentialsStart => ({ type: FORGET_CREDENTIALS_START });

export const forgetCredentialsSuccess = (result: boolean): ForgetCredentialsSuccess => ({
  type: FORGET_CREDENTIALS_SUCCESS,
  payload: result
});

export const forgetCredentialsFailed = (fetchError: boolean): ForgetCredentialsFailed => ({
  type: FORGET_CREDENTIALS_FAILED,
  payload: fetchError
});

export const forgetCredentials =
  (input: string, type: string, callback: ActionCallback) =>
  async (dispatch: Dispatch<ForgetCredentialsAction>): Promise<void> => {
    dispatch(forgetCredentialsStart());
    try {
      const { data } = await api.requestForgetCredentials(input, type);
      if (data) {
        dispatch(forgetCredentialsSuccess(data.requestForgotCredential.result));
        callback(data.requestForgotCredential.result);
      } else {
        callback(false);
      }
    } catch (err: any) {
      dispatch(forgetCredentialsFailed(true));
      callback(false);
    }
  };

export const clearCorporateUser = (): ClearCorporateUser => ({
  type: CLEAR_CORPORATE_USER
});

export const clearRegisterError = (): ClearRegisterError => ({
  type: CLEAR_REGISTER_ERROR
});

export const fetchPulseConfigStart = (): FetchPulseConfigStart => ({
  type: FETCH_PULSE_CONFIG_START
});

export const fetchPulseConfigSuccess = (pulseConfig: PulseConfig): FetchPulseConfigSuccess => ({
  type: FETCH_PULSE_CONFIG_SUCCESS,
  payload: pulseConfig
});

export const fetchPulseConfigFailed = (fetchError: string): FetchPulseConfigFailed => ({
  type: FETCH_PULSE_CONFIG_FAILED,
  payload: fetchError
});

export const fetchPulseConfig =
  () =>
  async (dispatch: Dispatch<FetchPulseConfigAction>): Promise<void> => {
    dispatch(fetchPulseConfigStart());
    try {
      const { data } = await api.fetchPulseConfig();
      if (data) {
        dispatch(fetchPulseConfigSuccess(data.getPulseConfig));
      }
    } catch (error) {
      dispatch(fetchPulseConfigFailed(error.message));
    }
  };
