import React from 'react';
import { useTranslation } from 'react-i18next';

import ChevronWhiteLeft from '../../assets/svgs/icons/ChevronWhiteLeft.svg';
import CrossBlack from '../../assets/svgs/icons/CrossBlack.svg';

import { PruText } from '@ebsme/pulse-rn-components';

import {
  StyledHeader,
  HeaderSection,
  BackIconContainer,
  CancelIconContainer,
  HeaderButton,
  BackgroundColor
} from './HeaderStyles';

export type { BackgroundColor };

interface Props {
  backgroundColor?: BackgroundColor;
  title?: string;
  backText?: string;
  onPressBack?: () => void;
  onPressCancel?: () => void;
  EndComponent?: React.ReactNode;
}

const Header: React.FC<Props> = ({
  backgroundColor = 'transparent',
  title,
  onPressBack,
  onPressCancel,
  EndComponent,
  backText
}) => {
  const { t } = useTranslation();

  return (
    <StyledHeader backgroundColor={backgroundColor} testID="Header__Container">
      <HeaderSection align="left">
        {onPressBack ? (
          <HeaderButton
            onPress={onPressBack}
            accessible
            accessibilityRole="button"
            accessibilityLabel={t('BUTTON__BACK')}
            accessibilityHint="Go back to the previous screen"
          >
            <BackIconContainer>
              <ChevronWhiteLeft width={6} height={10} />
            </BackIconContainer>
            <PruText color="black" size={16} weight="semiBold">
              {backText ? backText : t('BUTTON__BACK')}
            </PruText>
          </HeaderButton>
        ) : null}
      </HeaderSection>
      <HeaderSection align="center">
        {title && (
          <PruText testID="Header__Title" size={20} color="black" weight="bold" numberOfLines={1}>
            {title}
          </PruText>
        )}
      </HeaderSection>
      <HeaderSection align="right">
        {EndComponent}
        {onPressCancel ? (
          <HeaderButton
            onPress={onPressCancel}
            accessible
            accessibilityRole="button"
            accessibilityLabel={t('BUTTON__CANCEL')}
            accessibilityHint="Cancel this process and go back"
          >
            <CancelIconContainer>
              <CrossBlack width={16} height={16} />
            </CancelIconContainer>
          </HeaderButton>
        ) : null}
      </HeaderSection>
    </StyledHeader>
  );
};

export default Header;
