import DocumentScreen from './DocumentScreen';
import { RootState } from '../../../store';
import { updateClaim, uploadDocument, deleteDocumentAction } from '../../../reducers/MakeClaimReducer/actions';

import { getLoading, getClaim, getDocumentError } from '../../../reducers/MakeClaimReducer/selectors';
import { getPolicyId } from '../../../reducers/MyPolicyReducer/selectors';
import withLBUSwitcher from '../../../hocs/withLBUSwitcher';
import { getMandatoryDocument } from '../../../reducers/CorporateUserReducer/selectors';
import { setSDKError } from '../../../reducers/SDKReducer/actions';

const LBU_CONFIG = {
  SG: DocumentScreen,
  TH: DocumentScreen,
  PH: DocumentScreen,
  MY: DocumentScreen,
  MM: DocumentScreen
};

export default withLBUSwitcher(
  LBU_CONFIG,
  (state: RootState) => {
    return {
      loading: getLoading(state),
      claim: getClaim(state),
      documentError: getDocumentError(state),
      policyId: getPolicyId(state),
      mandatoryDocument: getMandatoryDocument(state)
    };
  },
  { updateClaim, uploadDocument, deleteDocumentAction, setSDKError }
);
