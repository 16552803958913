import { FAQItem } from '../../../types/faqs';

export const faqs: FAQItem[] = [
  {
    question: 'What is covered under Group Hospital & Surgical?',
    answer: `<p>Group Hospital &amp; Surgical covers reimbursement of hospital expenses due to illness or injury.</p>
    <p>Depending on your coverage, you may also be covered for the following:</p>
    <ul>
    <li>Group Outpatient Specialist: Reimbursement for outpatient specialist medical expenses.</li>
    <li>Group Outpatient General Practitioner (GP): Cashless (or with co-payment, if applicable) for GP Panel Clinics.</li>
    <li>Group Dental: Reimbursement for eligible dental expenses</li>
    </ul>
    <p>Please check with your company's Human Resource (HR) department on your coverage. All reimbursement is subject to the terms and conditions and benefit limits, as specified in the Benefit Schedule and contracts.</p>`
  },
  {
    question: 'What should I do if I need to be hospitalised?',
    answer: `<p>Please check with your company's HR department on whether your plan offers a Letter of Guarantee (LOG) facility.</p>
     <p>If your plan provides LOG</p>
     <ul>
     <li>Complete the LOG and bring it along to the hospital on the day of admission.</li>
     </ul>
     <p>If you have a CPF Medisave account</p>
     <ul>
     <li>Sign the Medisave Authorisation Form for the Medisave deduction.</li>
     </ul>
     <p>If you are covered under CPF MediShield Life or a Medisave-Approved Integrated Shield Plan</p>
     <ul>
     <li>Inform the hospital and sign the forms for filing under your plan</li>
     </ul>
     <p>If required, pay the deposit for the admission. For more details, please consult the Hospital's Financial Counsellor.</p>`
  },
  {
    question: 'What should I do upon discharge from the hospital?',
    answer: `<p>Settle payment with the hospital, and obtain the Original Final Hospital Bill</p>
      <p>If you are hospitalized in a Government / Restructured Hospital</p>
      <ul>
      <li>Request for the Inpatient Discharge Summary</li>
      </ul>
      <p>If you are hospitalized in an overseas / Private Hospital</p>
      <ul>
      <li>Obtain the Group Hospital and Surgical Claim Form and request your doctor to complete Part C of the Group Hospital and Surgical Claim Form</li>
      </ul>`
  },
  {
    question: 'Can I claim for the medical report fee?',
    answer: `<p>Provided your claim is admissible under the Policy, we shall reimburse up to S$75 (subject to benefit limits) for medical reports from Singapore Government Restructured Hospitals.</p>
      <p>We will not reimburse medical report fees incurred for medical reports obtain from overseas / Private Hospitals, Private Specialists or General Practitioner's clinics.</p>`
  },
  {
    question: 'What is a Letter of Guarantee (LOG)?',
    answer: `<p>A Letter of Guarantee (LOG) will guarantee eligible charges incurred during your hospitalisation, up to a maximum amount of S$10,000. Please check with your company's Human Resource department on whether your plan offers a Letter of Guarantee (LOG) facility.</p>`
  },
  {
    question: 'What documents do I need to submit my claim?',
    answer: `<p>For ease of submission, please prepare the following documents before you submit your claim.</p>
      <h4>Hospital &amp; Surgical</h4>
      <p>1. Original final hospital bills, tax invoices and receipts</p>
      <p>2. Inpatient Discharge Summary (if your admission is in a Singapore Government Restructured Hospital)</p>
      <p>3. Part C of the GHS Claim Form (if your admission is in a Private Hospital or Overseas Hospital)</p>
      <p>4. CPFB &lsquo;Medisave Transaction for Medical Expenses' showing the HRN code (if there are bills with medisave payment)</p>
      <p>5. Settlement letter (if there is any payment from your Integrated Shield Plan or 3rd party's insurance plan)</p>
      <p>6. Proof of payment by you e.g. copy of payslip (if you co-pay part of the bill)</p>
      <h4>Specialist</h4>
      <p>1. Original tax invoices and receipts</p>
      <p>2. Referral letter to the Specialist</p>
      <p>3. CPFB &lsquo;Medisave Transaction for Medical Expenses' showing the HRN code (if there are bills with medisave payment)</p>
      <p>4. Proof of payment by you e.g. copy of payslip (if you co-pay part of the bill)</p>
      <h4>General Practitioner</h4>
      <p>1. Original tax invoices and receipts</p>
      <p>2. CPFB &lsquo;Medisave Transaction for Medical Expenses' showing the HRN code (if there are bills with medisave payment)</p>
      <p>3. Proof of payment by you e.g. copy of payslip (if you co-pay part of the bill)</p>
      <h4>Dental</h4>
      <p>1. Original tax invoices and receipts</p>
      <p>2. CPFB &lsquo;Medisave Transaction for Medical Expenses' showing the HRN code (if there are bills with medisave payment)</p>`
  }
];
