import React, { ReactNode } from 'react';
import { TouchableHighlightProps } from 'react-native';

import { lightGrey } from '../../assets/colors';
import { StyledTile, StyledContainer } from './IconListTileStyles';
import ActionableText from '../ActionableText';

interface Props extends TouchableHighlightProps {
  title: string;
  selected?: boolean;
  icon?: ReactNode;
  showArrow?: boolean;
  onPress: () => void;
}

const ListTile: React.FC<Props> = ({ title, icon, showArrow = true, selected = false, onPress, ...touchableProps }) => {
  return (
    <StyledTile
      accessible
      accessibilityLabel={title}
      accessibilityRole="button"
      underlayColor={lightGrey}
      selected={selected}
      onPress={onPress}
      {...touchableProps}
    >
      <StyledContainer>
        {icon}
        <ActionableText showArrow={showArrow}>{title}</ActionableText>
      </StyledContainer>
    </StyledTile>
  );
};

export default ListTile;
