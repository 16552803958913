import gql from 'graphql-tag';
import { FetchResult } from 'apollo-link';

import { CorporateUser, PulseUser, PulseConfig } from '../../types/user';
import apiClient from '../../utils/apiClient';
import { pick } from 'ramda';
import { getValue } from '../../utils/object';
import { UserCountryCode } from '../../types/user';
import { LBU } from '../../config';
import { Platform } from 'react-native';
interface GetCorporateUserResponse {
  data: {
    getCorporateUser?: CorporateUser;
  };
}

export const getCorporateUser = (pulseUser: PulseUser): Promise<GetCorporateUserResponse> =>
  apiClient.query({
    query: gql`
      {
        getCorporateUser(profileId: "${pulseUser.id}", deviceId: "${pulseUser.device}") {
          email,
          entitlements {
            id,
            scope
          }
        }
      }
    `
  });

type RegisterCorporateUserResponse = FetchResult<{
  registerPulseUser: { authCodeRef: string };
}>;

export const registerCorporateUser = (
  pulseUser: PulseUser,
  email: string,
  nationalId: string,
  phone: string,
  countryCode: UserCountryCode,
  softToken: string | undefined
): Promise<RegisterCorporateUserResponse> => {
  const partialPayload = {
    profileId: pulseUser.id,
    deviceId: Platform.OS === 'web' ? pulseUser.device : pulseUser.deviceId,
    email: email,
    phone: phone,
    nationalId: nationalId,
    acceptConsent: true,
    appId: pulseUser.appBundle,
    pulseLoginId: pulseUser.id,
    softToken: softToken
  };
  const pulseRegistrationPayload = pick(
    getValue(LBU, [countryCode, 'REQUEST_PICKER', 'registerPulseUser']),
    partialPayload
  );
  console.log('pulseRegistrationPayload', pulseRegistrationPayload);
  return apiClient.mutate({
    mutation: getValue(LBU, [countryCode, 'GRAPHQL', 'registerPulseUserMutation']),
    variables: {
      input: pulseRegistrationPayload
    }
  });
};

interface PulseConfigResponse {
  data: {
    getPulseConfig: PulseConfig;
  };
}

export const fetchPulseConfig = (): Promise<PulseConfigResponse> => {
  return apiClient.query({
    query: gql`
      query {
        getPulseConfig
      }
    `
  });
};

interface ForgetCredentialsResponse {
  data: {
    requestForgotCredential: {
      result: boolean;
    };
  };
}

export const requestForgetCredentials = (input: string, type: string): Promise<ForgetCredentialsResponse> => {
  return apiClient.query({
    query: gql`
      query requestForgotCredential($forgotCredential: ForgotCredentialInput) {
        requestForgotCredential(forgotCredential: $forgotCredential) {
          result
        }
      }
    `,
    variables: {
      forgotCredential: {
        input,
        type
      }
    }
  });
};
