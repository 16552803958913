import React, { useEffect, useState, ReactElement, useMemo } from 'react';
import { View, TouchableOpacity, FlatList, Platform } from 'react-native';
import moment from 'moment';
import * as routes from '../../../routes';
import { StackNavigationProp } from '../../../types/navigation';
import DropdownWithLable from '../../../components/DropdownWithLable';
import TextInputWithLabel from '../../../components/TextInputWithLabel';
import SwitchRadio from '../../../components/SwitchRadio';
import DatePicker from '../../../components/DatePicker';
import MakeClaimView from '../MakeClaimView';
import { useTranslation } from 'react-i18next';
import {
  ClaimTypeOption,
  Claim,
  ClaimSubTypes,
  ClaimDateType,
  Dependant,
  Reason,
  PaymentOption,
  LifeAssuredOption,
  HospitalAndReason
} from '../../../types/claim';
import { Policy } from '../../../types/policy';
import {
  getClaimTypeOptions,
  getKeyForClaimType,
  getOriginalRequired,
  getClaimDates,
  getDateList,
  getKeyForReasonHospitalization,
  getPaymentOptions,
  getHospitals,
  getDiagnoses,
  getTransactionLabel
} from '../../../utils/claim';
import { useLBUTranslation } from '../../../hooks/useLBUTranslation';
import { UserCountryCode, PulseUser } from '../../../types/user';
import { PruText, PruButton } from '@ebsme/pulse-rn-components';
import Icon from 'react-native-vector-icons/Entypo';
import { BankAccount } from '../../../types/bankAccount';
import { Bank } from '../../../types/bank';
import BankAccountList from '../../../components/BankAccountList';
import BankAccountListLoader from '../../../components/BankAccountList/BankAccountListLoader';
import clinics from '../../../config/lbu/sg/clinic';
import currencies from '../../../config/lbu/sg/currency';
import { useConfirmAlert } from '../../../hooks/useConfirmAlert';
import { Hospital } from 'app/types/hospital';
import { Diagnosis } from 'app/types/diagnosis';

import { logFirebaseEventScreen, logFirebaseEventClick } from '../../../utils/analytics';

export interface Props {
  lbu: UserCountryCode;
  navigation: StackNavigationProp;
  policy?: Policy;
  policyId: string;
  claim: Claim;
  claimTypes: ClaimTypeOption[];
  updateClaim: (claim: Claim) => void;
  banks: Bank[];
  hospitalAndReasonsList?: HospitalAndReason;
  pulseUser?: PulseUser;
  bankAccounts: BankAccount[];
  dependants: Dependant[];
  fetchBankAccounts: (policyNumber: string) => void;
  fetchBanks: () => void;
  fetchDependants: (id: string) => void;
  addBankAccountProcess: (bankAccount: any) => void;
  removeBankAccountProcess: (bankAccount: any) => void;
  loadingBank: boolean;
  getPulseUserConfig: (certificate: string) => void;
  getHospitalOrReason: (certificate: string, reason: string) => void;
  locale: string;
  addBankAccountSuccess: (bankAccount: BankAccount) => void;
}

const initBankAccount = {
  id: '',
  bank: {
    id: '',
    logo: ''
  }
};

const Step1Screen: React.FC<Props> = ({
  lbu,
  navigation,
  policy,
  policyId,
  claim,
  claimTypes,
  bankAccounts,
  banks,
  dependants,
  hospitalAndReasonsList,
  loadingBank,
  locale,
  updateClaim,
  fetchBankAccounts,
  fetchBanks,
  addBankAccountProcess,
  removeBankAccountProcess,
  getPulseUserConfig,
  addBankAccountSuccess,
  getHospitalOrReason
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmAlert();
  const { tLBU } = useLBUTranslation(lbu);
  const [subTypes] = useState<ClaimSubTypes | undefined>();
  const [claimSelected, setClaimSelected] = useState<ClaimTypeOption | undefined>();
  const [listReason, setListReason] = useState<Reason[]>([]);
  const [detailReason, setDetailReason] = useState<Reason | undefined | null>();
  const [disableDiagnosis, setDisableDiagnosis] = useState<boolean>(false);
  const [diagnosis, setDiagnosis] = useState<any>();
  const [clinic, setClinic] = useState<any>();
  const [hospital, setHospital] = useState<any>();

  const [bankDetailSelected, setBankDetailSelected] = useState<Bank | undefined | null>();
  const [selectedDependant, setSelectedDependant] = useState<LifeAssuredOption>();
  const [dateState, setDateState] = useState({
    claimDate: new Date(),
    accidentDate: null,
    admissionDate: lbu === 'SG' || lbu === 'PH' || lbu === 'MM' ? null : new Date(),
    dischargeDate: lbu === 'SG' || lbu === 'PH' || lbu === 'MM' ? null : new Date()
  });
  const [datesRequired, setDatesRequired] = useState<ClaimDateType[]>();
  const [bank, setBank] = useState<BankAccount>(initBankAccount);
  const [paymentOption, setPaymentOption] = useState<PaymentOption>();
  const [detailOfReasonText, setDetailOfReasonText] = useState('');
  const [claimAmount, setClaimAmount] = useState<any>('');
  const [currency, setCurrency] = useState(currencies[0]);

  const [showAddBank, setShowAddBank] = useState<boolean>(false);
  const [allowAddBank] = useState<boolean>(true);
  const [bankSelected, setBankSelected] = useState<string>('');
  const [accountNamelValidationError, setAccountNameValidationError] = useState(false);
  const [bankValidationError, setBankValidationError] = useState(false);
  const [accountNumberValidationError, setAccountNumberValidationError] = useState(false);
  const [branchNameValidationError, setBranchNameValidationError] = useState(false);
  const [branchAddressValidationError, setBranchAddressValidationError] = useState(false);

  const [accidentDateValidationError, setAccidentDateValidationError] = useState(false);
  const [admissionDateValidationError, setAdmissionDateValidationError] = useState(false);
  const [dischargeDateValidationError, setDischargeDateValidationError] = useState(false);

  const [currentDateError, setCurrentDateError] = useState('');
  const [invalidDate, setInvalidDate] = useState(false);

  const [textSearch, setTextSearch] = useState('');
  const [textClinicSearch, setTextClinicSearch] = useState('');
  const [textHospitalSearch, setTextHospitalSearch] = useState('');
  const [requiredRemark, setRequiredRemark] = useState<boolean>(false);

  const CLAIM_TYPES: ClaimTypeOption[] = claimTypes ? claimTypes : getClaimTypeOptions(lbu, policy);

  const PAYMENT_OPTIONS: PaymentOption[] = getPaymentOptions(lbu);
  const HOSPITALS: unknown = useMemo(() => {
    return hospitalAndReasonsList?.HOSPITAL?.map((item: Hospital) => {
      const newItem = {
        ...item,
        label: typeof item.label === 'string' ? item.label : getTransactionLabel(item.label, locale)
      };
      if (newItem.code === hospital?.code) {
        setHospital(newItem);
      }
      return newItem;
    });
  }, [hospitalAndReasonsList?.HOSPITAL, locale, hospital?.code]);

  const DIAGNOSES: unknown = useMemo(() => {
    return hospitalAndReasonsList?.DIAGNOSIS?.map((item: Diagnosis) => {
      const newItem = {
        ...item,
        label: typeof item.label === 'string' ? item.label : getTransactionLabel(item.label, locale)
      };
      if (newItem.code === diagnosis?.code) {
        setDiagnosis(newItem);
      }
      return newItem;
    });
  }, [lbu, locale, hospitalAndReasonsList]);

  const localizeOptionLabel = (options: ClaimTypeOption[], keyExtractor: (id: string) => string): ClaimTypeOption[] => {
    return options.map((option) => {
      option.label = tLBU(option.id, { fallbackValue: option.label, keyExtractor });
      return option;
    });
  };

  const updateClaimDates = (claimType: string) => {
    const claimDates: ClaimDateType[] = getClaimDates(lbu, claimType).map((dType) => {
      const { dateType, title, isOptional, readOnly } = getDateList(dType, detailReason, claimType, lbu);
      return {
        dateType,
        title,
        isOptional,
        readOnly
      };
    });
    // For PACS: Show Admission and Discharge Date when claim type inpatient or maternity
    if (claimType !== 'inpatient' && claimType !== 'maternity' && lbu === 'SG') {
      return claimDates.filter((item) => item.dateType !== 'admissionDate' && item.dateType !== 'dischargeDate');
    }
    return claimDates;
  };

  const items: ClaimTypeOption[] = localizeOptionLabel(CLAIM_TYPES, getKeyForClaimType);
  const itemsReason: Reason[] = localizeOptionLabel(listReason, getKeyForReasonHospitalization);

  useEffect(() => {
    fetchBankAccounts(policy?.certificateNo || '');
  }, [fetchBankAccounts]);

  useEffect(() => {
    if (claimSelected && updateClaimDates) {
      setDatesRequired(updateClaimDates(claimSelected?.id));
      if (lbu === 'PH') {
        if (detailReason && detailReason.id === 'others') {
          setRequiredRemark(true);
        } else {
          setRequiredRemark(false);
        }
      }
    }
  }, [detailReason]);

  useEffect(() => {
    if (
      datesRequired?.some((item) => {
        return !item.isOptional && switchValueDate(item.dateType) == null;
      })
    ) {
      setInvalidDate(true);
    } else {
      setInvalidDate(false);
    }
  }, [datesRequired, dateState]);

  useEffect(() => {
    fetchBanks();
  }, [fetchBanks]);

  useEffect(() => {
    const originalRequired = items && items.length > 0 && getOriginalRequired(lbu, items[0].id, '').length > 0;
    if (items && items.length > 0) {
      setDatesRequired(
        updateClaimDates(
          items.filter((item) => item.isDefault).length > 0 ? items.filter((item) => item.isDefault)[0].id : items[0].id
        )
      );
      setClaimSelected(
        items.filter((item) => item.isDefault).length > 0 ? items.filter((item) => item.isDefault)[0] : items[0]
      );
    }
    setPaymentOption(PAYMENT_OPTIONS && PAYMENT_OPTIONS[0]);
    // For PACS, remark field is required
    if (lbu === 'SG') {
      setRequiredRemark(true);
    }
    // Set default Life Assured is main applicant
    setSelectedDependant({
      id: 'you',
      name: `${policy?.employees && policy?.employees[0].givenName ? policy?.employees[0].givenName : ''} ${
        policy?.employees && policy?.employees[0].middleName ? policy?.employees[0].middleName : ''
      } ${policy?.employees && policy?.employees[0].familyName ? policy?.employees[0].familyName : ''}`
        .trim()
        .replace(/  +/g, ' '),
      policyNumber: policy?.certificateNo
    });
    updateClaim({
      ...claim,
      claimType: !(items && items.length > 0)
        ? undefined
        : items.filter((item) => item.isDefault).length > 0
        ? items.filter((item) => item.isDefault)[0].id
        : items[0].id,
      hospitalizationReason: detailReason?.id,
      originalRequired,
      certificateNo: policy?.certificateNo || '',
      lifeAssuredCertificateNo: policy?.certificateNo,
      dependant: null,
      policyId,
      claimantName: `${policy?.employees && policy?.employees[0].givenName ? policy?.employees[0].givenName : ''} ${
        policy?.employees && policy?.employees[0].middleName ? policy?.employees[0].middleName : ''
      } ${policy?.employees && policy?.employees[0].familyName ? policy?.employees[0].familyName : ''}`
        .trim()
        .replace(/  +/g, ' '),
      claimDate: moment(dateState.claimDate).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
      admissionDate:
        lbu === 'SG' || lbu === 'PH' || lbu === 'MM'
          ? null
          : moment(dateState.admissionDate).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
      dischargeDate:
        lbu === 'SG' || lbu === 'PH' || lbu === 'MM'
          ? null
          : moment(dateState.dischargeDate).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
      currency: lbu === 'SG' ? 'SGD' : lbu === 'TH' ? 'THB' : undefined,
      paymentOptions: lbu === 'SG' || lbu === 'PH' ? PAYMENT_OPTIONS && PAYMENT_OPTIONS[0].id : undefined
    });
    logFirebaseEventScreen('eb_make_claim', {
      feature: 'MakeClaim',
      journey: 'make_claim',
      stage: 'claim_type',
      screen_id: 'SCR_EB_CLAIM_CTS',
      screen_name: 'ClaimTypeScreen'
    });
  }, []);

  useEffect(() => {
    // for MM BankName is textfield
    if (lbu !== 'MM') {
      if (banks && banks.length > 0) {
        setBank({
          ...bank,
          bank: banks[0]
        });
        // comment this one due to need to clear the data
        // setBankDetailSelected(banks[0]);
      }
    } else {
      setBank({
        ...bank,
        bank: { ...bank.bank, name: '' }
      });
    }
  }, []);

  useEffect(() => {
    /*
    For PACS:
    Show reason field when claim type is inpatient or maternity
    Disable diagnosis filed when claim type is health screen or outpatient dental or maternity
    */
    if (lbu === 'SG') {
      setDisableDiagnosis(false);
      if (
        claimSelected?.id === 'health-screening' ||
        claimSelected?.id === 'outpatient-dental' ||
        claimSelected?.id === 'maternity'
      ) {
        setDisableDiagnosis(true);
        setDiagnosis(null);
      }
    }
    if (lbu === 'TH') {
      setHospital(null);
      setDiagnosis(null);
    }
    setDateState({
      claimDate: new Date(),
      accidentDate: null,
      admissionDate: lbu === 'SG' || lbu === 'PH' || lbu === 'MM' ? null : new Date(),
      dischargeDate: lbu === 'SG' || lbu === 'PH' || lbu === 'MM' ? null : new Date()
    });
    setListReason(claimSelected?.reasons || []);
    setDetailReason(null);
  }, [claimSelected]);

  useEffect(() => {
    // call api getPulseUserConfig once changes the Life Assured
    if (selectedDependant?.policyNumber) {
      getPulseUserConfig(selectedDependant?.policyNumber);
      getHospitalOrReason(selectedDependant?.policyNumber, '');
    }
  }, [selectedDependant]);

  // Reset default claim type when list claim type is changed
  useEffect(() => {
    setClaimSelected(
      items.filter((item) => item.isDefault).length > 0 ? items.filter((item) => item.isDefault)[0] : items[0]
    );
  }, [claimTypes]);

  const onClaimTypeSelect = (claimTypeOption: ClaimTypeOption): void => {
    // If current claim type same with selecting option then just hide dropdown.
    if (claimTypeOption !== claimSelected) {
      setClaimSelected(claimTypeOption);
      const { id } = claimTypeOption;
      const dates = updateClaimDates(id);
      setDatesRequired(dates);
      const originalRequired = getOriginalRequired(lbu, id, '').length > 0;
      updateClaim({
        ...claim,
        claimType: id,
        claimSubType: '',
        originalRequired,
        clinic: undefined,
        clinicName: undefined,
        hospital: undefined,
        hospitalName: undefined,
        diagnosis: undefined,
        diagnosisDescription: undefined,
        hospitalizationReason:
          (lbu === 'SG' && claimTypeOption.id !== 'inpatient' && claimTypeOption.id !== 'maternity') || lbu === 'TH'
            ? undefined
            : claim.hospitalizationReason,
        accidentDate: null,
        admissionDate: lbu === 'SG' || lbu === 'PH' ? null : moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
        dischargeDate: lbu === 'SG' || lbu === 'PH' ? null : moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
      });
    }
  };

  const onSelectReason = (item: Reason): void => {
    setDetailReason(item);

    updateClaim({
      ...claim,
      hospitalizationReason: item.id
    });
    if (selectedDependant?.policyNumber) {
      const label = item.id.charAt(0).toUpperCase() + item.id.slice(1);
      getHospitalOrReason(selectedDependant.policyNumber, label);
    }
  };

  useEffect(() => {
    if (lbu === 'TH') {
      setDiagnosis(null);
    }
  }, [detailReason]);
  const onSelectDiagnosis = (item: any): void => {
    setDiagnosis(item);
    setTextSearch('');
    updateClaim({
      ...claim,
      diagnosis: item.code,
      diagnosisDescription: lbu === 'TH' ? item.label : undefined
    });
  };
  const onSelectClinic = (item: any): void => {
    setClinic(item);
    setTextClinicSearch('');
    updateClaim({
      ...claim,
      clinic: item.value,
      clinicName: item.label
    });
  };

  const onSelectHospital = (item: any): void => {
    setHospital(item);
    setTextHospitalSearch('');
    updateClaim({
      ...claim,
      hospital: item.code,
      hospitalName: item.label
    });
  };
  const onSelectCurrency = (item: any): void => {
    setCurrency(item);
    updateClaim({
      ...claim,
      currency: item.value
    });
  };

  const filterDataDiagnosis = useMemo(() => {
    const diagnosesList = DIAGNOSES?.filter((item) => {
      const diagOfReason = !item.reason || item.reason.toLowerCase() === detailReason?.id.toLowerCase();
      return (
        item.label !== '' && item.label.toString().toUpperCase().includes(textSearch.toUpperCase()) && diagOfReason
      );
    });
    return diagnosesList;
  }, [DIAGNOSES, textSearch, detailReason]);
  const filterDataClinic = () => {
    return clinics.filter((item) => item.label.toUpperCase().includes(textClinicSearch.toUpperCase()));
  };
  const filterDataHospital = useMemo(() => {
    const list = HOSPITALS?.filter((item) => {
      return item.label.toString().toUpperCase().includes(textHospitalSearch.toUpperCase());
    });
    return list;
  }, [textHospitalSearch, HOSPITALS]);

  const onSearchDiagnosis = (text: string): void => {
    setTextSearch(text);
  };
  const onSearchClinic = (text: string): void => {
    setTextClinicSearch(text);
  };
  const onSearchHospital = (text: string): void => {
    setTextHospitalSearch(text);
  };
  const onSelectBankDetail = (currentBank: Bank): void => {
    setBankDetailSelected(currentBank);
    setBank({
      ...bank,
      bank: currentBank
    });
  };

  const validAdmissionDate = (accidentDate: null | Date, admissionDate: null | Date, dischargeDate: null | Date) => {
    const validAdmissionDate = accidentDate
      ? !moment(admissionDate).isAfter(dischargeDate, 'day') && !moment(accidentDate).isAfter(admissionDate, 'day')
      : !moment(admissionDate).isAfter(dischargeDate, 'day');
    if (!validAdmissionDate) {
      setCurrentDateError('admissionDate');
    }
    setDischargeDateValidationError(false);
    setAccidentDateValidationError(false);
    setAdmissionDateValidationError(!validAdmissionDate);
    return validAdmissionDate;
  };
  const validationDate = (date: Date, origin: string) => {
    switch (origin) {
      case 'accidentDate':
        const validAccidentDate = !moment(date).isAfter(dateState.admissionDate, 'day');
        if (!validAccidentDate) {
          setCurrentDateError('accidentDate');
          setAdmissionDateValidationError(false);
          setDischargeDateValidationError(false);
        } else {
          validAdmissionDate(date, dateState.admissionDate, dateState.dischargeDate);
        }
        setAccidentDateValidationError(!validAccidentDate);
        return validAccidentDate;
      case 'admissionDate':
        return validAdmissionDate(dateState.accidentDate, date, dateState.dischargeDate);
      case 'dischargeDate':
        const validDischargeDate = !moment(dateState.admissionDate).isAfter(date, 'day');
        if (!validDischargeDate) {
          setCurrentDateError('dischargeDate');
          setAdmissionDateValidationError(false);
          setAccidentDateValidationError(false);
        } else {
          validAdmissionDate(dateState.accidentDate, dateState.admissionDate, date);
        }
        setDischargeDateValidationError(!validDischargeDate);
        return validDischargeDate;
      default:
        return true;
    }
  };

  const errorMessage: string | undefined = accidentDateValidationError
    ? lbu === 'SG'
      ? t('CLAIMS__CLAIM_VISIT_DATE_ERROR_INVALID')
      : t('CLAIMS__CLAIM_ACCIDENT_DATE_ERROR_INVALID')
    : admissionDateValidationError
    ? t('CLAIMS__CLAIM_ADMISSION_DATE_ERROR_INVALID')
    : dischargeDateValidationError
    ? t('CLAIMS__CLAIM_DISCHARGE_DATE_ERROR_INVALID')
    : '';

  const updateDate = (date: Date, origin: string) => {
    validationDate(date, origin);
    setDateState({ ...dateState, [origin]: date });
    updateClaim({
      ...claim,
      [origin]: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
    });
  };

  const updateDependant = (item: LifeAssuredOption) => {
    setSelectedDependant(item);
    if (item.id !== 'you') {
      updateClaim({
        ...claim,
        claimantName: item.name,
        lifeAssuredCertificateNo: item.policyNumber,
        dependant: {
          id: item.id
        }
      });
    } else {
      updateClaim({
        ...claim,
        claimantName: item.name,
        lifeAssuredCertificateNo: item.policyNumber,
        dependant: null
      });
    }
  };

  const onSelectBank = (bank: BankAccount): void => {
    if (lbu === 'TH') {
      setBankSelected(bank.accountNumber || '');
      updateClaim({
        ...claim,
        bankAccount: {
          accountNumber: bank.accountNumber,
          accountHolderName: bank.accountHolderName,
          bank: {
            name: bank.bank.name,
            code: bank.bank.code,
            branchCode: bank.bank.branchCode
          },
          isNew: bank.isNew
        }
      });
    } else {
      setBankSelected(bank.id || '');
      updateClaim({
        ...claim,
        bankAccount: {
          id: bank.id,
          isNew: bank.isNew
        }
      });
    }
  };

  // Remove bank account
  const onRemoveBank = (bank: BankAccount): void => {
    confirm({
      title: t('BANK_ACCOUNT__CONFIRM_REMOVE__TITLE'),
      buttonText: t('BANK_ACCOUNT__CONFIRM_REMOVE__BUTTON'),
      destructive: true,
      onConfirm: (): void => {
        removeBankAccountProcess(bank);
      }
    });
  };
  const onSubStepBack =
    subTypes &&
    ((): void => {
      updateClaim({
        ...claim,
        claimType: '',
        claimSubType: ''
      });
    });
  // Merge main applicant with dependants
  const filterDependant = (): LifeAssuredOption[] => {
    const result: LifeAssuredOption[] = [];
    result.push({
      id: 'you',
      name: `${policy?.employees && policy?.employees[0].givenName ? policy?.employees[0].givenName : ''} ${
        policy?.employees && policy?.employees[0].middleName ? policy?.employees[0].middleName : ''
      } ${policy?.employees && policy?.employees[0].familyName ? policy?.employees[0].familyName : ''}`
        .trim()
        .replace(/  +/g, ' '),
      dob: policy?.dobEmployee,
      policyNumber: policy?.certificateNo
    });
    dependants &&
      dependants
        .sort(function (a, b) {
          return new Date(a.dateOfBirth!).valueOf() - new Date(b.dateOfBirth!).valueOf();
        })
        .map((item) => {
          result.push({
            id: item.id,
            name: `${item.givenName ? item.givenName : ''} ${item.middleName ? item.middleName : ''} ${
              item.familyName ? item.familyName : ''
            }`
              .trim()
              .replace(/  +/g, ' '),
            dob: item.dateOfBirth,
            policyNumber: item.dependantPolicyNumber
          });
        });
    return result;
  };

  const errorAccountNameMessage: string | undefined = accountNamelValidationError
    ? t('CLAIMS__CLAIM_ACCOUNT_NAME_ERROR_INVALID')
    : '';
  const errorAccountNumberMessage: string | undefined = accountNumberValidationError
    ? t('CLAIMS__CLAIM_ACCOUNT_NUMBER_ERROR_INVALID')
    : '';
  const errorBankMessage: string | undefined = bankValidationError ? t('CLAIMS__CLAIM_BANK_ERROR_INVALID') : '';
  const errorBranchNameMessage: string | undefined = branchNameValidationError
    ? t('CLAIMS__CLAIM_BRANCH_NAME_ERROR_INVALID')
    : '';
  const errorBranchAddressMessage: string | undefined = branchAddressValidationError
    ? t('CLAIMS__CLAIM_BRANCH_ADDRESS_ERROR_INVALID')
    : '';

  const clearAddBankError = () => {
    setAccountNameValidationError(false);
    setAccountNumberValidationError(false);
    setBankValidationError(false);
    setBranchNameValidationError(false);
    setBranchAddressValidationError(false);
  };

  // Validate bank account's infor before call api
  const validateBankAccount = () => {
    const validAccountName = bank.accountHolderName !== '' && bank.accountHolderName;
    setAccountNameValidationError(!validAccountName);
    const validBank = lbu === 'MM' ? bank.bank.name !== '' : bankDetailSelected;
    setBankValidationError(!validBank);
    const validAccountNumber = bank.accountNumber !== '' && bank.accountNumber;
    setAccountNumberValidationError(!validAccountNumber);
    const validBranchName = lbu === 'MM' ? (bank.branchName && bank.branchName !== '' ? true : false) : true;
    setBranchNameValidationError(!validBranchName);
    const validBranchAddress = lbu === 'MM' ? (bank.branchAddress && bank.branchAddress !== '' ? true : false) : true;
    setBranchAddressValidationError(!validBranchAddress);
    if (validAccountName && validBank && validAccountNumber && validBranchName && validBranchAddress) {
      setShowAddBank(false);
      if (lbu !== 'TH') {
        const params = {
          bank: lbu === 'MM' ? { name: bank.bank.name } : bank.bank,
          accountNumber: bank.accountNumber,
          accountHolderName: bank.accountHolderName,
          branchName: bank.branchName,
          branchAddress: bank.branchAddress,
          policyNumber: policy?.certificateNo
        };
        addBankAccountProcess(params);
      } else {
        addBankAccountSuccess({ ...bank, isNew: true });
        onSelectBank({ ...bank, isNew: true });
      }
      setBank(initBankAccount);
      setBankDetailSelected(null);
    }
  };
  // To define enable/disable next button
  // Need to check all fields required
  const validNextDisable = () => {
    return (
      (bankSelected === '' && paymentOption?.isRequiredBankAccount) ||
      !selectedDependant ||
      errorMessage !== '' ||
      ((claimSelected?.id === 'inpatient' || claimSelected?.id === 'maternity') && !detailReason) ||
      (lbu !== 'SG' && !detailReason) ||
      (lbu === 'SG' &&
        claimSelected?.id !== 'outpatient-dental' &&
        claimSelected?.id !== 'health-screening' &&
        claimSelected?.id !== 'maternity' &&
        !diagnosis) ||
      (lbu === 'SG' && claimSelected?.id === 'inpatient' && !clinic) ||
      (requiredRemark && detailOfReasonText === '') ||
      (lbu === 'SG' && !claimAmount) ||
      (lbu === 'TH' && (!claimAmount || !diagnosis || !hospital)) ||
      invalidDate
    );
  };

  const switchValueDate = (dateType: string) => {
    switch (dateType) {
      case 'claimDate':
        return dateState.claimDate;
      case 'accidentDate':
        return dateState.accidentDate;
      case 'admissionDate':
        return dateState.admissionDate;
      case 'dischargeDate':
        return dateState.dischargeDate;
      default:
        return null;
    }
  };

  const countDecimals = (amount: string) => {
    if (Math.floor(Number(amount).valueOf()) === Number(amount).valueOf()) return 0;
    return amount.split('.')[1].length || 0;
  };

  const validateInput = (text) => {
    if (text === '') return true;
    if (text.includes(' ')) return false;
    if (text[0] === '.' || text[0] < '1' || text[0] > '9') return false;
    const dotCount = (text.match(/\./g) || []).length;
    if (dotCount > 1) return false;
    if (text.includes('.')) {
      const parts = text.split('.');
      if (parts[1] && parts[1].length > 2) return false;
    }
    return !isNaN(text.replace('.', ''));
  };

  const noteClaim =
    lbu === 'PH'
      ? tLBU('CLAIMS__CLAIM_NOTE', {
          fallbackValue:
            'You may also file or submit your claims request via email (policy.admin@prulifeuk.com.ph) or to any PRU Life U.K. branch near you. Claims forms are available for download at our website: www.prulifeuk.com.ph'
        })
      : '';

  return (
    <MakeClaimView
      note={noteClaim}
      title={''}
      currentRoute={routes.Step1Screen}
      nextDisabled={validNextDisable()}
      navigation={navigation}
      onSubStepBack={onSubStepBack}
    >
      <DropdownWithLable
        testID="Step1Screen_LifeAssured"
        selectedItem={selectedDependant}
        items={filterDependant()}
        onPressItem={(item) => {
          // Check if current LifeAssured same with selecting option then just hide dropdown.
          if (selectedDependant?.policyNumber !== item.policyNumber) {
            updateDependant(item);
          }
        }}
        title={t('CLAIMS__CLAIM_DETAILS_LIFE_ASSURED')}
        style={{
          margin: 20
        }}
        required
      />
      <DropdownWithLable
        testID="Step1Screen_ClaimType"
        selectedItem={claimSelected}
        items={items}
        onPressItem={onClaimTypeSelect}
        title={t('CLAIMS__CLAIM_TYPE__TITLE_NEW')}
        style={{
          margin: 20
        }}
        required
      />
      <View style={{ margin: 20 }}>
        <PruText color="black" weight="bold">
          {t('CLAIMS__CLAIM_DETAILS')}
        </PruText>
        {listReason && listReason.length > 0 && (
          <DropdownWithLable
            testID="Step1Screen_Reason"
            selectedItem={detailReason}
            items={itemsReason}
            onPressItem={onSelectReason}
            title={lbu === 'SG' || lbu === 'MM' ? 'Cause of Claim' : t('CLAIMS__CLAIM_DETAILS_REASON_HOSPITALIZATION')}
            style={{
              marginVertical: 20
            }}
            required
          />
        )}
        {(lbu === 'SG' || lbu === 'TH') && (
          <>
            {lbu === 'SG' ? (
              <DropdownWithLable
                testID="Step1Screen_Clinic"
                selectedItem={clinic}
                items={filterDataClinic()}
                onPressItem={onSelectClinic}
                title={'Name of Provider/ Clinic *'}
                textSearch={textClinicSearch}
                showSearchBox
                onTextSearch={onSearchClinic}
                style={{
                  marginVertical: 20
                }}
              />
            ) : (
              <DropdownWithLable
                testID="Step1Screen_Hospital"
                selectedItem={hospital}
                items={filterDataHospital}
                onPressItem={onSelectHospital}
                title={t('CLAIMS__CLAIM_DETAILS_HOSPITAL')}
                textSearch={textHospitalSearch}
                showSearchBox
                onTextSearch={onSearchHospital}
                style={{
                  marginVertical: 20
                }}
                required
              />
            )}
            {!disableDiagnosis && (
              <DropdownWithLable
                testID="Step1Screen_Diagnosis"
                selectedItem={diagnosis}
                items={filterDataDiagnosis}
                onPressItem={onSelectDiagnosis}
                title={t('CLAIMS__CLAIM_DETAILS_DIAGNOSIS')}
                textSearch={textSearch}
                showSearchBox
                required={!disableDiagnosis}
                onTextSearch={onSearchDiagnosis}
                disable={disableDiagnosis}
                style={{
                  marginVertical: 20
                }}
              />
            )}
          </>
        )}
        {(lbu === 'SG' || lbu === 'PH' || lbu === 'MM') && (
          <TextInputWithLabel
            testID="Step1Screen_Remark"
            origin="detailOfReasonText"
            onTextChange={(value, origin) => {
              setDetailOfReasonText(value);
              updateClaim({
                ...claim,
                remark: value
              });
            }}
            label={tLBU('CLAIMS__SUBMITTED__REMARK', { fallbackValue: 'Remarks' })}
            value={detailOfReasonText}
            required={requiredRemark}
            maxLength={200}
            numberLine={3}
            multipleLine
            hint={
              requiredRemark
                ? detailReason?.id === 'accident'
                  ? 'Describe how the accident happen and the extent of injuries sustained'
                  : detailReason?.id === 'illness'
                  ? 'Describe the illness'
                  : ''
                : ''
            }
          />
        )}
        <FlatList
          data={datesRequired}
          numColumns={2}
          keyExtractor={(item): string => item.dateType}
          renderItem={({ item, index }): ReactElement => (
            <DatePicker
              key={index}
              origin={item.dateType}
              readOnly={item.readOnly}
              value={switchValueDate(item.dateType)}
              onSelectDate={(date, origin) => {
                updateDate(date, origin);
              }}
              label={`${t(`${item.title}`)} ${!item.isOptional ? '*' : ''}`}
              style={{ flex: 1, marginTop: 10, marginRight: index % 2 === 0 ? 10 : 0 }}
              errorMessage={errorMessage}
              currentDateError={currentDateError}
            />
          )}
        />
        {(lbu === 'SG' || lbu === 'TH') && (
          <>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TextInputWithLabel
                testID="Step1Screen_Amount"
                origin="claimAmount"
                onTextChange={(value, origin) => {
                  let valueRep = value.replace(/,/g, '');
                  if (validateInput(valueRep) && countDecimals(valueRep) <= 2) {
                    setClaimAmount(valueRep);
                    updateClaim({
                      ...claim,
                      claimAmount: Number(valueRep)
                    });
                  }
                }}
                label={lbu === 'SG' ? 'Claim amount' : t('CLAIMS__CLAIM_TOTAL_AMOUNT')}
                value={claimAmount + ''}
                keyboardType={Platform.OS === 'android' ? 'numeric' : 'decimal-pad'}
                required={true}
                numberLine={1}
                multipleLine={false}
                style={{ flex: 2, marginRight: 10 }}
              />
              {lbu === 'SG' && (
                <DropdownWithLable
                  selectedItem={currency}
                  items={currencies}
                  onPressItem={onSelectCurrency}
                  title={'Currency'}
                  style={{
                    flex: 1
                  }}
                />
              )}
              {lbu === 'TH' && (
                <View style={{ flex: 1 }}>
                  <PruText>{t('CLAIMS__CLAIM_CURRENCY')}</PruText>
                  <View
                    style={{
                      borderColor: 'grey',
                      borderWidth: 0.5,
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 10
                    }}
                  >
                    <PruText>{t('CLAIMS__CLAIM_CURRENCY_BAHT')}</PruText>
                  </View>
                </View>
              )}
            </View>
            {lbu === 'TH' && <PruText color="red">{t('CLAIMS__CLAIM_TOTAL_AMOUNT_WARNING')}</PruText>}
          </>
        )}
        {PAYMENT_OPTIONS?.length > 1 && (
          <SwitchRadio
            style={{ marginVertical: 20 }}
            title={t('CLAIMS__CLAIM_BANK_TITLE')}
            items={PAYMENT_OPTIONS}
            onPressItem={(item) => {
              setPaymentOption(item);
              if (!item.isRequiredBankAccount) {
                setBankSelected('');
              }
              updateClaim({
                ...claim,
                paymentOptions: item.id,
                bankAccount: item.isRequiredBankAccount ? claim.bankAccount : undefined
              });
              logFirebaseEventClick('eb_make_claim', {
                feature: 'MakeClaim',
                journey: 'make_claim',
                stage: 'claim_type',
                screen_id: 'SCR_EB_CLAIM_CTS',
                screen_name: 'ClaimTypeScreen',
                object_id: 'RAD_EB_CLAIM_PAYMENTHOD',
                method: item.id
              });
            }}
            selected={paymentOption}
            column={2}
          />
        )}
        {paymentOption?.isRequiredBankAccount && (
          <>
            <PruText marginTop={10} color="black">
              {t('CLAIMS__CLAIM_BANK_SELECT_BANK_LABEL')}
            </PruText>
            {loadingBank && bankAccounts && !bankAccounts.length ? (
              <BankAccountListLoader testID="BankAccountsScreen__Loader" />
            ) : (
              <BankAccountList
                onRemove={onRemoveBank}
                onSelect={onSelectBank}
                bankSelected={bankSelected}
                data={bankAccounts}
                testID="BankAccountsScreen__List"
              />
            )}
            {(lbu !== 'SG' || bankAccounts?.length === 0) && (
              <TouchableOpacity
                testID="BTN_ADD_BANK_ACCOUNT"
                style={{
                  flexDirection: 'row',
                  borderColor: '#68737A',
                  borderWidth: 0.5,
                  padding: 20,
                  borderRadius: 5,
                  marginTop: 10
                }}
                onPress={() => {
                  setShowAddBank(true);
                  logFirebaseEventClick('eb_make_claim', {
                    feature: 'MakeClaim',
                    journey: 'make_claim',
                    stage: 'claim_type',
                    screen_id: 'SCR_EB_CLAIM_CTS',
                    screen_name: 'ClaimTypeScreen',
                    object_id: 'BTN_EB_CLAIM_ADDBANK'
                  });
                }}
              >
                <View style={{ flex: 1, justifyContent: 'center' }}>
                  <PruText>
                    {tLBU('CLAIMS__CLAIM_BANK_ADD_BANK_BUTTON', {
                      fallbackValue: t('CLAIMS__CLAIM_BANK_ADD_BANK_BUTTON')
                    })}
                  </PruText>
                </View>
                <Icon name="circle-with-plus" size={30} color="red" />
              </TouchableOpacity>
            )}
          </>
        )}
        {showAddBank && paymentOption?.isRequiredBankAccount && (
          <View>
            <TextInputWithLabel
              origin="accountHolderName"
              onTextChange={(value, origin) => {
                setBank({ ...bank, [origin]: value });
              }}
              label={lbu === 'SG' ? t('CLAIMS__CLAIM_BANK_ACCOUNT_HOLDER_NAME') : t('CLAIMS__CLAIM_BANK_ACCOUNT_NAME')}
              value={bank.accountHolderName || ''}
              errorMessage={errorAccountNameMessage}
              required
            />
            {lbu === 'MM' ? (
              <TextInputWithLabel
                origin="bank"
                onTextChange={(value, origin) => {
                  setBank({ ...bank, [origin]: { ...bank.bank, name: value } });
                }}
                label={tLBU('CLAIMS__CLAIM_BANK_BANK_NAME', { fallbackValue: t('CLAIMS__CLAIM_BANK_BANK_NAME') })}
                value={bank.bank.name || ''}
                errorMessage={errorBankMessage}
                required
              />
            ) : (
              <DropdownWithLable
                selectedItem={bankDetailSelected}
                items={banks}
                onPressItem={onSelectBankDetail}
                title={t('CLAIMS__CLAIM_BANK_BANK_NAME')}
                style={{
                  marginVertical: 20
                }}
                errorMessage={errorBankMessage}
                required
              />
            )}
            {lbu === 'MM' && (
              <>
                <TextInputWithLabel
                  origin="branchName"
                  onTextChange={(value, origin) => {
                    setBank({ ...bank, [origin]: value });
                  }}
                  label={t('CLAIMS__CLAIM_BANK_BRANCH_NAME')}
                  value={bank.branchName || ''}
                  required
                  errorMessage={errorBranchNameMessage}
                />
                <TextInputWithLabel
                  origin="branchAddress"
                  onTextChange={(value, origin) => {
                    setBank({ ...bank, [origin]: value });
                  }}
                  label={t('CLAIMS__CLAIM_BANK_BRANCH_ADDRESS')}
                  value={bank.branchAddress || ''}
                  required
                  errorMessage={errorBranchAddressMessage}
                />
              </>
            )}
            <TextInputWithLabel
              origin="accountNumber"
              onTextChange={(value, origin) => {
                setBank({ ...bank, [origin]: value });
              }}
              label={tLBU('CLAIMS__CLAIM_BANK_ACCOUNT_NUMBER', {
                fallbackValue: t('CLAIMS__CLAIM_BANK_ACCOUNT_NUMBER')
              })}
              value={bank.accountNumber || ''}
              required
              errorMessage={errorAccountNumberMessage}
              keyboardType="number-pad"
            />
            <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
              <PruButton
                disabled={false}
                style={{ marginRight: 10 }}
                paddingVertical={16}
                paddingHorizontal={64}
                onPress={() => {
                  setShowAddBank(false);
                  clearAddBankError();
                }}
                testID="CancelAddBank"
              >
                {t('BUTTON__CANCEL')}
              </PruButton>
              <PruButton
                disabled={!allowAddBank}
                style={!allowAddBank ? { backgroundColor: 'grey', borderColor: 'grey' } : {}}
                paddingVertical={16}
                paddingHorizontal={64}
                onPress={() => validateBankAccount()}
                testID="AddBank"
              >
                {t('BUTTON__SAVE')}
              </PruButton>
            </View>
          </View>
        )}
      </View>
    </MakeClaimView>
  );
};

export default Step1Screen;
