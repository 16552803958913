import styled from 'styled-components/native';

import { blue, white } from '../../assets/colors';
import { getShadowStyles } from '../../assets/mixins';

interface StyledProps {
  selected?: boolean;
}

const shadow = getShadowStyles({ offset: '' });

const getBorderColor = ({ selected }: StyledProps): string => (selected ? blue : 'transparent');

export const StyledTile = styled.TouchableHighlight`
  padding: 24px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: ${white};
  border: solid 3px ${getBorderColor};
  ${shadow}
`;
