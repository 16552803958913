import React, { useState, useEffect } from 'react';
import { Platform, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { goToTelemedicine } from '../../../goToWithParams';

import { StackNavigationProp } from '../../../types/navigation';
import { EntitlementsMap, LandingFeatures } from '../../../types/entitlements';
import { UserCountryCode, PulseConfig } from '../../../types/user';
import { getNavigate } from '../../../utils/navigation';
import * as routes from '../../../routes';

import MakeClaimSVG from '../../../assets/svgs/tiles/MakeClaim.svg';
import ReviewClaimSVG from '../../../assets/svgs/tiles/ReviewClaim.svg';
import RewardsSVG from '../../../assets/svgs/tiles/Rewards.svg';
import MyPolicySVG from '../../../assets/svgs/tiles/MyPolicy.svg';
import { PruButton, PruText } from '@ebsme/pulse-rn-components';
import HospitalSvg from '../../../assets/svgs/tiles/Hospital.svg';
import SquareTile from '../../../components/SquareTile';
import LayoutBox from '../../../components/LayoutBox';
import DropdownWithLable from '../../../components/DropdownWithLable';

import { TilesContainer, LongTilesContainer } from './LandingScreenTilesStyles';
import LongTile from '../../../components/LongTile';
import { Cogwheel } from '../../../assets/svgs/icons';
import { getInitialRoute } from '../../MakeClaimScreens/routesUtility';
import { ClaimState } from '../../../types/claim';
import MultiActionableTile from '../../../components/MultiActionableTile';
import { openURL } from '../../../utils/platform';
import { getValue } from '../../../utils/object';
import { LBU } from '../../../config';
import { useLBUTranslation } from '../../../hooks/useLBUTranslation';
import { Policy } from '../../../types/policy';
import TermsAndConditions from '../../Support/TermsAndConditions';
import Header from '../../../components/Header';
import {
  EVENT_ANALYTICS,
  OBJECT_ID_CLICK,
  logFirebaseEventClick,
  logFirebaseEventScreen
} from '../../../utils/analytics';

interface Props {
  navigation: StackNavigationProp;
  lbu: UserCountryCode;
  lbuTiles: LandingFeatures[];
  entitlements: EntitlementsMap;
  setClaimFilter?: (claimFilter?: ClaimState) => void;
  selectPolicy?: (id: string) => void;
  policies?: Policy[];
  back2PulseAction?: (value: boolean) => void;
  clearRefreshToken: () => void;
  clearLastPulseUser: () => void;
  clearPolicy: () => void;
  clearCorporateUser: () => void;
  hasDependantRole?: boolean | undefined;
  pulseConfig?: PulseConfig;
  policy: Policy;
}

const LandingScreenTiles: React.FC<Props> = ({
  navigation,
  lbu = 'TH',
  lbuTiles = [],
  policies,
  setClaimFilter,
  selectPolicy,
  back2PulseAction,
  clearRefreshToken,
  clearLastPulseUser,
  clearPolicy,
  clearCorporateUser,
  hasDependantRole,
  pulseConfig,
  policy
}) => {
  const { t } = useTranslation();
  const { tLBU } = useLBUTranslation(lbu);
  const [policyOptions, setPolicyOptions] = useState([]);
  const [policySelected, setPolicySelected] = useState();
  const [termVisible, setTermVisible] = useState(false);
  const [tileEntitlements, setTileEntitlements] = useState<LandingFeatures[]>([]);

  const [tHowCan, tWeHelp] = t('LANDING__HOW_CAN_WE_HELP');

  // TODO: Generify & extract merged entitlements & LBU features
  // const tileEntitlements = lbu === 'SG' ? intersection(lbuTiles, entitlements.LANDING || []) : lbuTiles;

  const onPolicySelect = (item: any) => {
    logFirebaseEventClick(EVENT_ANALYTICS.policy, {
      feature: 'Policy',
      journey: 'policy',
      stage: 'policy_start',
      screen_id: 'SCR_EB_LANDING_LANDS',
      screen_name: 'LandingScreen',
      object_id: OBJECT_ID_CLICK.change_policy
    });
    setPolicySelected(item);
    if (selectPolicy) {
      selectPolicy(item.id);
    }
  };

  useEffect(() => {
    if (policy) {
      // get current role
      const currentEmp = policy.employees && policy.employees.length > 0 && policy.employees[0];
      const currentRole =
        currentEmp && currentEmp.employeeRelation
          ? currentEmp.employeeRelation === 'MAIN INSURED'
            ? 'MainApplicant'
            : 'Dependant'
          : 'MainApplicant';
      if (pulseConfig?.ACCESS_CONTROL_LIST && currentRole) {
        const features = pulseConfig?.ACCESS_CONTROL_LIST.Role_list.find((item) => item.Role === currentRole)?.Features;
        if (features && features.length > 0) {
          setTileEntitlements(features);
        }
      } else {
        setTileEntitlements(lbuTiles);
      }
    }
  }, [policy]);

  useEffect(() => {
    if (policies && policies.length > 0) {
      const result: any = policies.map((policy) => {
        return {
          id: policy.certificateNo,
          label: `${lbu === 'PH' ? policy.policyNumber : policy.certificateNo}`
        };
      });
      setPolicyOptions(result);
      setPolicySelected(result[0]);
      logFirebaseEventScreen(EVENT_ANALYTICS.landing, {
        feature: 'Home',
        journey: 'N/A',
        stage: 'N/A',
        screen_id: 'SCR_EB_LANDING_LANDS',
        screen_name: 'LandingScreen'
      });
    }
  }, [policies]);

  return (
    <>
      {Platform.OS !== 'web' && (
        <View style={{ marginTop: -20 }}>
          <Header onPressBack={() => back2PulseAction && back2PulseAction(true)} backText={t('BUTTON__BACK_TO_HOME')} />
        </View>
      )}

      <DropdownWithLable
        selectedItem={policySelected}
        items={policyOptions}
        onPressItem={onPolicySelect}
        title={tLBU('CLAIMS__POLICY_NUMBER__TITLE_NEW', { fallbackValue: t('CLAIMS__POLICY_NUMBER__TITLE_NEW') })}
        style={{
          margin: 20,
          marginTop: 0
        }}
      />
      <LayoutBox paddingHorizontal={24} marginTop={24} marginBottom={12}>
        <PruText size={20} color="black" weight="thin">
          <PruText weight="bold">{tHowCan}</PruText> {tWeHelp}
        </PruText>
      </LayoutBox>
      <TilesContainer>
        {tileEntitlements.includes('MY_POLICY') && (
          <SquareTile
            SvgImage={MyPolicySVG}
            title={tLBU('LANDING__MY_POLICY_CARD_NEW', { fallbackValue: t('LANDING__MY_POLICY_NEW') })}
            description={tLBU('LANDING__MY_POLICY_DESCRIPTION', { fallbackValue: t('LANDING__MY_POLICY_DESCRIPTION') })}
            onPress={() => {
              logFirebaseEventClick(EVENT_ANALYTICS.policy, {
                feature: 'Policy',
                journey: 'policy',
                stage: 'policy_start',
                screen_id: 'SCR_EB_LANDING_LANDS',
                screen_name: 'LandingScreen',
                object_id: OBJECT_ID_CLICK.tiles_policy
              });

              getNavigate(navigation.navigate, routes.MyPolicy)();
            }}
          />
        )}
        {tileEntitlements.includes('MAKE_CLAIM') && (
          <SquareTile
            SvgImage={MakeClaimSVG}
            title={tLBU('LANDING__MAKE_A_CLAIM', { fallbackValue: t('LANDING__MAKE_A_CLAIM') })}
            description={tLBU('LANDING__MAKE_A_CLAIM_DESCRIPTION', {
              fallbackValue: t('LANDING__MAKE_A_CLAIM_DESCRIPTION')
            })}
            onPress={() => {
              logFirebaseEventClick(EVENT_ANALYTICS.make_claim, {
                feature: 'MakeClaim',
                journey: 'make_claim',
                stage: 'claim_start',
                screen_id: 'SCR_EB_LANDING_LANDS',
                screen_name: 'LandingScreen',
                object_id: OBJECT_ID_CLICK.tiles_claim
              });

              getNavigate(navigation.navigate, getInitialRoute(lbu))();
            }}
          />
        )}
        {tileEntitlements.includes('REVIEW_CLAIM') && (
          <SquareTile
            SvgImage={ReviewClaimSVG}
            title={tLBU('LANDING__REVIEW_A_CLAIM', { fallbackValue: t('LANDING__REVIEW_A_CLAIM') })}
            description={tLBU('LANDING__REVIEW_A_CLAIM_DESCRIPTION', {
              fallbackValue: t('LANDING__REVIEW_A_CLAIM_DESCRIPTION')
            })}
            onPress={(): void => {
              logFirebaseEventClick(EVENT_ANALYTICS.review_claim, {
                feature: 'ReviewClaim',
                journey: 'review_claim',
                stage: 'review_start',
                screen_id: 'SCR_EB_LANDING_LANDS',
                screen_name: 'LandingScreen',
                object_id: OBJECT_ID_CLICK.tiles_review
              });

              if (setClaimFilter) {
                setClaimFilter();
              }
              getNavigate(navigation.navigate, routes.ReviewClaim)();
            }}
          />
        )}
        {tileEntitlements.includes('E_CARD') && (
          <SquareTile
            SvgImage={MakeClaimSVG}
            title={tLBU('LANDING__E_CARD', { fallbackValue: t('LANDING__E_CARD') })}
            description={tLBU('LANDING__E_CARD_DESCRIPTION', { fallbackValue: t('LANDING__E_CARD_DESCRIPTION') })}
            onPress={getNavigate(navigation.navigate, routes.ECard)}
          />
        )}
        {tileEntitlements.includes('REWARDS') && (
          <SquareTile
            SvgImage={RewardsSVG}
            title={tLBU('LANDING__REWARDS', { fallbackValue: t('LANDING__REWARDS') })}
            description={tLBU('LANDING__REWARDS_DESCRIPTION', { fallbackValue: t('LANDING__REWARDS_DESCRIPTION') })}
            onPress={getNavigate(navigation.navigate, routes.Rewards)}
          />
        )}
        {tileEntitlements.includes('SG_SUPPORT') && (
          <SquareTile
            SvgImage={MakeClaimSVG}
            title={tLBU('LANDING__HELP_CENTER', { fallbackValue: t('LANDING__HELP_CENTER') })}
            description={tLBU('LANDING__HELP_CENTER__DESCRIPTION', {
              fallbackValue: t('LANDING__HELP_CENTER__DESCRIPTION')
            })}
            onPress={getNavigate(navigation.navigate, routes.ClaimsSupport)}
          />
        )}
        {Platform.OS !== 'web' && tileEntitlements.includes('SEE_DOCTOR') && (
          <SquareTile
            isTeleconsultation
            title={tLBU('LANDING__SEE_DOCTOR', { fallbackValue: t('LANDING__SEE_DOCTOR') })}
            description={tLBU('LANDING__SEE_DOCTOR_DESCRIPTION', {
              fallbackValue: t('LANDING__SEE_DOCTOR_DESCRIPTION')
            })}
            onPress={(): void => {
              // navigation.navigate(routes.SeeDoctor);
              goToTelemedicine();
            }}
          />
        )}
        {tileEntitlements.includes('HOSPITAL') && (
          <SquareTile
            SvgImage={HospitalSvg}
            title={tLBU('LANDING__CLINIC_LOCATOR', {
              fallbackValue: 'View my Panel Clinic'
            })}
            description={tLBU('LANDING__CLINIC_LOCATOR_DESCRIPTION', {
              fallbackValue: 'View hospital and clinic near you'
            })}
            onPress={() => {
              logFirebaseEventClick(EVENT_ANALYTICS.clinic_locator, {
                feature: 'ClinicLocator',
                journey: 'clinic_locator',
                stage: 'clinic_start',
                screen_id: 'SCR_EB_LANDING_LANDS',
                screen_name: 'LandingScreen',
                object_id: OBJECT_ID_CLICK.tiles_clinic
              });
              getNavigate(navigation.navigate, routes.FindHospital)();
            }}
          />
        )}
        {tileEntitlements.includes('LETTER_OF_GUARANTEE') && (
          <SquareTile
            SvgImage={MakeClaimSVG}
            title={tLBU('LANDING__LETTER_OF_GUARANTEE', { fallbackValue: t('LANDING__LETTER_OF_GUARANTEE') })}
            description={tLBU('LANDING__LETTER_OF_GUARANTEE__DESCRIPTION', {
              fallbackValue: t('LANDING__LETTER_OF_GUARANTEE__DESCRIPTION')
            })}
            onPress={(): void => {
              navigation.navigate(routes.LetterOfGuarantee);
            }}
          />
        )}
        {tileEntitlements.includes('BANK_ACCOUNT') && (
          <SquareTile
            SvgImage={RewardsSVG}
            title={tLBU('LANDING__BANK_ACCOUNT', { fallbackValue: t('LANDING__BANK_ACCOUNT') })}
            description={tLBU('LANDING__BANK_ACCOUNT_DESCRIPTION', {
              fallbackValue: t('LANDING__BANK_ACCOUNT_DESCRIPTION')
            })}
            onPress={getNavigate(navigation.navigate, routes.BankAccount)}
          />
        )}
      </TilesContainer>
      {tileEntitlements.includes('FEATURE_TILES_MY') && (
        <LongTilesContainer>
          <LongTile
            SvgImage={MyPolicySVG}
            svgStyle={{ minWidth: 120 }}
            title={tLBU('LANDING__MY_POLICY', { fallbackValue: t('LANDING__MY_POLICY') })}
            description={tLBU('LANDING__MY_POLICY_DESCRIPTION', { fallbackValue: t('LANDING__MY_POLICY_DESCRIPTION') })}
            onPress={(): void => {
              navigation.navigate(routes.MyPolicy);
            }}
          />
        </LongTilesContainer>
      )}
      {tileEntitlements.includes('SUPPORT') && (
        <LongTile
          style={{ marginHorizontal: 24, marginBottom: 48 }}
          SvgImage={Cogwheel}
          title={tLBU('LANDING__CONTACT_SUPPORT', { fallbackValue: t('LANDING__CONTACT_SUPPORT') })}
          description={tLBU('LANDING__CONTACT_SUPPORT_DESCRIPTION', {
            fallbackValue: t('LANDING__CONTACT_SUPPORT_DESCRIPTION')
          })}
          onPress={(): void => {
            logFirebaseEventClick(EVENT_ANALYTICS.help, {
              feature: 'GetHelp',
              journey: 'get_help',
              stage: 'help_start',
              screen_id: 'SCR_EB_LANDING_LANDS',
              screen_name: 'LandingScreen',
              object_id: OBJECT_ID_CLICK.tiles_help
            });
            navigation.navigate(routes.Support);
          }}
        />
      )}
      {tileEntitlements.includes('AGREEMENT') && (
        <MultiActionableTile
          style={{ marginHorizontal: 24, marginBottom: 48 }}
          options={[
            {
              label: tLBU('LANDING__TERMS', { fallbackValue: t('LANDING__TERMS') }),
              onPress: (): void => {
                setTermVisible(true);
              }
            },
            {
              label: tLBU('LANDING__PRIVACY', { fallbackValue: t('LANDING__PRIVACY') }),
              onPress: (): void => {
                openURL(getValue(LBU, [lbu, 'GENERAL', 'privacyUrl']));
              }
            }
          ]}
        />
      )}
      {tileEntitlements.includes('TERMS_OF_USE') && (
        <MultiActionableTile
          style={{ marginHorizontal: 24, marginBottom: 48 }}
          options={[
            {
              label: tLBU('LANDING__TERMS', { fallbackValue: t('LANDING__TERMS') }),
              onPress: (): void => {
                setTermVisible(true);
              }
            }
          ]}
        />
      )}
      {termVisible && (
        <TermsAndConditions
          testID="TermsAndConditionsModal"
          onClose={(): void => setTermVisible(false)}
          userCountryCode={lbu}
        />
      )}
      {Platform.OS === 'web' && (
        <PruButton
          onPress={(): void => {
            clearRefreshToken();
            clearLastPulseUser();
            clearPolicy();
            clearCorporateUser();
          }}
          marginHorizontal={4}
        >
          {t('LANDING__LOG_OUT')}
        </PruButton>
      )}
    </>
  );
};

export default LandingScreenTiles;
