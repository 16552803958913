import AsyncStorage from '@react-native-async-storage/async-storage';

export const getItem = async (key: string) => {
    try {
        let result = await AsyncStorage.getItem(key);
        return result ? JSON.parse(result) : null;
    }
    catch (e) {
        console.log(e);
    }
}

export const setItem = async (key: string, value: any) => {

    try {
        const item = JSON.stringify(value);

        return await AsyncStorage.setItem(key, item);
    } catch (e) {
        console.log(e);
    }
}