import { connect } from 'react-redux';

import { RootState } from '../../../store';
import {
  getCorporateUser,
  getFetchingCorporateUser,
  getRegisteringCorporateUser,
  getRegisterCorporateUserError,
  getCorporateUserEmail,
  getCorporateUserPhone,
  getCorporateUserNationalId,
  getPulseConfig,
  getFetchingPulseConfig
} from '../../../reducers/CorporateUserReducer/selectors';
import { fetchCorpUser, registerCorpUser, fetchPulseConfig } from '../../../reducers/CorporateUserReducer/actions';
import { back2PulseAction } from '../../../reducers/SessionReducer/actions';
import { setSDKLocale } from '../../../reducers/SDKReducer/actions';
import withSDKLoader from '../../../hocs/withSDKLoader/withSDKLoader';

import OnboardingEmailScreen from './OnboardingEmailScreen';
import { getPulseUser, getUserCountryCode } from '../../../reducers/PulseUserReducer/selectors';

const OnboardingEmailScreenContainer = connect(
  (state: RootState) => ({
    pulseUser: getPulseUser(state),
    email: getCorporateUserEmail(state),
    phone: getCorporateUserPhone(state),
    nationalId: getCorporateUserNationalId(state),
    corporateUser: getCorporateUser(state),
    userCountryCode: getUserCountryCode(state),
    fetchingCorporateUser: getFetchingCorporateUser(state),
    fetchCorporateUserError: getFetchingCorporateUser(state),
    registeringCorporateUser: getRegisteringCorporateUser(state),
    registerCorporateUserError: getRegisterCorporateUserError(state),
    pulseConfig: getPulseConfig(state),
    fetchingPulseConfig: getFetchingPulseConfig(state)
  }),
  { fetchCorpUser, registerCorpUser, back2PulseAction, fetchPulseConfig, setSDKLocale }
)(
  // @ts-ignore
  withSDKLoader(OnboardingEmailScreen)
);

export default OnboardingEmailScreenContainer;
