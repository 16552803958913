import React from 'react';
import { TouchableOpacityProps } from 'react-native';

import { chevronRightLarge } from '../../assets/images/icons';

import { Arrow, Button } from './FloatingButtonStyles';

import Icon from 'react-native-vector-icons/MaterialIcons';

interface Props extends TouchableOpacityProps {
  disabled?: boolean;
}

const FloatingButton: React.FC<Props> = ({ disabled, ...buttonProps }) => {
  return (
    <Button testID="FloatingButton" disabled={disabled} accessible accessibilityRole="button" {...buttonProps}>
      <Icon name='navigate-next' size={30} color="white"/>
    </Button>
  );
};

export default FloatingButton;
