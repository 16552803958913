import { PulseUser } from '../../types/user';

import { CLEAR_LAST_PULSE_USER, PulseUserAction, SET_LAST_PULSE_USER, SET_PULSE_USER_PROFILE } from './actions';

export type { PulseUserAction };

type UserState = {
  pulseUser?: PulseUser;
  lastPulseUser?: PulseUser;
};

export const initialState: UserState = {
  pulseUser: undefined,
  lastPulseUser: undefined
};

const PulseUserReducer = (state: UserState = initialState, action: PulseUserAction): UserState => {
  switch (action.type) {
    case SET_PULSE_USER_PROFILE:
      return {
        ...state,
        pulseUser: action.payload
      };

    case SET_LAST_PULSE_USER:
      return {
        ...state,
        lastPulseUser: action.payload
      };

    case CLEAR_LAST_PULSE_USER:
      return {
        ...state,
        lastPulseUser: undefined
      };

    default:
      return state;
  }
};

export default PulseUserReducer;
