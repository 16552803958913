import React from 'react';

import { StyledWhitespace } from './WhitespaceStyles';

interface Props {
  height: number;
}

const Whitespace: React.FC<Props> = ({ height }) => {
  return <StyledWhitespace height={height} />;
};

export default Whitespace;
