import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { withNavigation, NavigationInjectedProps } from 'react-navigation';
import FastImage from '../../components/FastImage';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { BankAccount } from '../../types/bankAccount';
import { PruText, PruCard } from '@ebsme/pulse-rn-components';
import { formatBankAccountNumber } from '../../utils/bankUtil';
import { grey } from '../../assets/colors';

export interface ClinicItemProps extends NavigationInjectedProps {
  item: BankAccount;
  testID?: string;
  onSelect?: (bank: BankAccount) => void;
  onRemove?: (bank: BankAccount) => void;
  bankSelected?: string;
}

const BankAccountListItem: React.FC<ClinicItemProps> = ({ testID, item, bankSelected, onSelect, onRemove }) => {
  const [showImagePlaceholder, setShowImagePlaceholder] = useState(true);
  const { bank, accountNumber } = item;
  const { logo, fullName, name } = bank;

  return (
    <TouchableOpacity onLongPress={() => onRemove && onRemove(item)} onPress={() => onSelect && onSelect(item)}>
      <PruCard
        testID={testID}
        style={{ flexDirection: 'row', alignItems: 'center', marginHorizontal: 18, marginBottom: 16, marginTop: 6 }}
      >
        <View>
          {showImagePlaceholder && (
            <View
              testID="BankAccountListItem__LogoPlaceholder"
              style={{
                position: 'absolute',
                width: 80,
                height: 80,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Icon name="account-balance" color={grey} size={40} />
            </View>
          )}
          <FastImage
            testID="BankAccountListItem__Logo"
            onLoad={(): void => {
              setShowImagePlaceholder(false);
            }}
            style={{
              width: 80,
              height: 80,
              marginEnd: 12,
              borderWidth: 0,
              borderColor: grey,
              borderRadius: 12
            }}
            source={{ uri: logo }}
            resizeMode={FastImage?.resizeMode?.contain || 'contain'}
          />
        </View>
        <View style={{ flex: 1 }}>
          {/* {isDefault && (
            <View style={{ marginBottom: 8, flexDirection: 'row' }}>
              <PruText size={10} color="green" marginRight={12} weight="bold">
                ✓{' '}
              </PruText>
              <PruText size={10} color="black">
                {t('BANK_ACCOUNT__DEFAULT')}
              </PruText>
            </View>
          )} */}
          <PruText weight="bold" color="black">
            {fullName || name}
          </PruText>
          {accountNumber && (
            <PruText marginTop={6} size={20} weight="bold" color="black">
              {formatBankAccountNumber(accountNumber, true)}
            </PruText>
          )}
        </View>
        <View>
          <Icon
            name={
              bankSelected && (bankSelected === item.id || bankSelected === item.accountNumber)
                ? 'radio-button-on'
                : 'radio-button-off'
            }
            size={30}
          />
        </View>
      </PruCard>
    </TouchableOpacity>
  );
};

export default withNavigation(BankAccountListItem);
