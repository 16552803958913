import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { StackNavigationProp } from '../../types/navigation';
import Screen from '../../components/Screen';
import { Container } from './LetterOfGuaranteeStyles';
import PickerField from '../../components/PickerField';
import { patients } from '../../__mocks__/patientsMocks';
import { PruText } from '@ebsme/pulse-rn-components';
import LogRequestList from '../../components/LogRequestList';
import { LogRequest } from '../../types/logRequest';
import LogRequestListLoader from '../../components/LogRequestList/LogRequestListLoader';

type Props = {
  navigation: StackNavigationProp;
  logRequests: LogRequest[];
  loading: boolean;
  fetchError?: string;
  fetchLogRequests: () => void;
};

const LogHistoryScreen: React.FC<Props> = ({ navigation, logRequests, loading, fetchError, fetchLogRequests }) => {
  const { t } = useTranslation();
  const [patientID, setPatientID] = useState('');
  const [data, setData] = useState(logRequests);

  const patientsOptions = patients.map(({ id, name }) => ({ value: id, label: name }));

  useEffect(() => {
    fetchLogRequests();
  }, [fetchLogRequests]);

  useEffect(() => {
    setData(logRequests);
  }, [logRequests]);

  const onPatientValueChange = useCallback(
    (value: unknown) => {
      setPatientID(value as string);
      setData(value ? logRequests.filter((log) => log.patient.id === value) : logRequests);
    },
    [logRequests]
  );

  return (
    <Screen
      subHeading={t('LETTER_OF_GUARANTEE__LOG_HISTORY__DESCRIPTION')}
      headerPaddingHorizontal={24}
      onPressBack={(): void => {
        navigation.goBack();
      }}
    >
      <Container>
        <PickerField
          label={t('LETTER_OF_GUARANTEE__REQUEST_LOG__SELECT_PATIENT')}
          placeholder={t('LETTER_OF_GUARANTEE__REQUEST_LOG__SELECT_PATIENT')}
          value={patientID}
          items={patientsOptions}
          onValueChange={onPatientValueChange}
        />
      </Container>
      {fetchError && (
        <PruText testID="FetchError" style={{ margin: 24 }} size={12} weight="semiBold" color="darkRed">
          {fetchError}
        </PruText>
      )}
      {!loading && logRequests.length === 0 && !fetchError && (
        <PruText testID="EmptyMessage" style={{ margin: 24 }} size={12} weight="semiBold">
          {t('LETTER_OF_GUARANTEE__LOG_HISTORY__EMPTY')}
        </PruText>
      )}
      {loading ? <LogRequestListLoader testID="ListLoader" /> : <LogRequestList data={data} />}
    </Screen>
  );
};

export default LogHistoryScreen;
