import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { StackNavigationProp } from '../../types/navigation';
import Screen from '../../components/Screen';
import { PruButton, PruInput, PruMessageModal } from '@ebsme/pulse-rn-components';
import { Container } from './BankAccountStyles';
import PickerField from '../../components/PickerField';
import { bankDisplayName } from '../../utils/bankUtil';
import { Bank } from '../../types/bank';
import { isIOS } from '../../utils/platform';

interface NavigationProps extends StackNavigationProp {
  state: {
    params: {};
  };
}

type Props = {
  navigation: NavigationProps;
  banks: Bank[];
  fetchBanks: () => void;
};

const BankAccountCreateScreen: React.FC<Props> = ({ navigation, banks, fetchBanks }) => {
  const { t } = useTranslation();
  const [accountNumber, setAccountNumber] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [bankId, setBankId] = useState();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const bankItems = banks.map((bank) => ({
    label: bankDisplayName(bank),
    value: bank.id
  }));

  const onAccountHolderNameChange = useCallback((value) => {
    setAccountHolderName(value.replace(/[^a-zA-Z\s]/gi, ''));
  }, []);

  const onAccountNumberChange = useCallback((value) => {
    setAccountNumber(value.replace(/\D/g, ''));
  }, []);

  const onBankValueChange = useCallback((value) => {
    setBankId(value);
  }, []);

  useEffect(() => {
    fetchBanks();
  }, [fetchBanks]);
  return (
    <Screen
      heading={t('BANK_ACCOUNT__ACCOUNT_INFORMATION')}
      headerPaddingHorizontal={24}
      onPressBack={(): void => {
        navigation.goBack();
      }}
    >
      <Container>
        <PruInput
          testID="AccountHolderName"
          value={accountHolderName}
          onChangeText={onAccountHolderNameChange}
          label={t('BANK_ACCOUNT__ACCOUNT_HOLDER_NAME')}
          keyboardType={isIOS() ? 'default' : 'visible-password'}
          autoCapitalize="characters"
          autoCorrect={false}
          placeholder={t('BANK_ACCOUNT__ACCOUNT_HOLDER_NAME__HINT')}
        />
        <PruInput
          testID="AccountNumber"
          value={accountNumber}
          onChangeText={onAccountNumberChange}
          label={t('BANK_ACCOUNT__ACCOUNT_NUMBER')}
          keyboardType="number-pad"
          containerStyle={{ marginTop: 24 }}
          maxLength={15}
          placeholder="1234567890"
        />
        <PickerField
          label={t('BANK_ACCOUNT__BANK_NAME')}
          placeholder={t('BANK_ACCOUNT__BANK_NAME__HINT')}
          style={{ marginTop: 24 }}
          value={bankId}
          items={bankItems}
          onOpen={(): void => {
            if (!bankItems.length) {
              fetchBanks();
            }
          }}
          onValueChange={onBankValueChange}
        />
      </Container>
      <PruButton
        style={{ margin: 24 }}
        onPress={(): void => {
          setErrorMessage(t('ERROR__REQUEST_ERROR'));
        }}
      >
        {t('BANK_ACCOUNT__ADD')}
      </PruButton>
      <PruMessageModal
        isVisible={Boolean(errorMessage)}
        title={t('ERROR__OOPS')}
        message={errorMessage}
        buttonText={t('BUTTON__OK')}
        onPress={(): void => {
          setErrorMessage(undefined);
        }}
      />
    </Screen>
  );
};

export default BankAccountCreateScreen;
