import { connect } from 'react-redux';

import { RootState } from '../../store';
import { getLoading, getECareCards } from '../../reducers/MyPolicyReducer/selectors';

import ECardScreen from './ECardScreen';

export default connect(
  (state: RootState) => ({
    loading: getLoading(state),
    eCareCards: getECareCards(state)
  }),
  null
)(ECardScreen);
