import React, { ReactElement } from 'react';
import { View, TouchableOpacity, ViewStyle, Text, FlatList } from 'react-native';
import PruText, { TextColor } from '@ebsme/pulse-rn-components/PruText';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { PaymentOption } from '../../types/claim';


interface SwitchRadioProps {
  onPressItem: (item: PaymentOption) => void;
  items: PaymentOption[];
  title: string;
  selected?: PaymentOption;
  style?: ViewStyle;
  color?: TextColor;
  column?: number;
}

const SwitchRadio: React.FC<SwitchRadioProps> = ({
  onPressItem,
  items = [],
  title = '',
  selected,
  style,
  color = 'black',
  column = 1
}) => {
  return (
    <View style={[style]}>
      <PruText weight="bold" color={color}>
        {title}
      </PruText>
      <FlatList
        testID={"Radio"}
        data={items}
        numColumns={column}
        keyExtractor={(item: PaymentOption): string => item.id}
        renderItem={({ item }): ReactElement =>
          <TouchableOpacity
            testID={item.id}
            key={item.id}
            onPress={() => {
              onPressItem(item);
            }}
            style={{
              flex: 1,
              flexDirection: 'row',
              borderColor: '#68737A',
              borderWidth: 0.5,
              paddingHorizontal: 20,
              paddingVertical: 20,
              borderRadius: 5,
              marginTop: 10,
              marginRight: 2,
              alignItems: 'center'
            }}
          >
            <View style={{ flex: 1 }}>
              {item.label && <Text style={{}}>{item.label}</Text>}
            </View>
            <Icon name={selected && selected.id === item.id ? 'radio-button-on' : 'radio-button-off'} size={30} />
          </TouchableOpacity>
        }
      />
    </View>
  );
};

export default SwitchRadio;
