import { isEmpty } from 'ramda';
import decodeJWT from 'jwt-decode';

export const jwtDecode = <T extends {}>(token: string): T | undefined => {
  try {
    return decodeJWT(token);
  } catch (err) {
    console.warn('Error parsing JWT', err);

    return undefined;
  }
};

export const isValidToken = (token: string): boolean => {
  if (isEmpty(token)) {
    return false;
  }
  const { exp } = jwtDecode(token) || {};
  if (isNaN(exp)) {
    return false;
  }
  return Date.now() / 1000 < exp;
};
