import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import Pdf from '../../components/Pdf';
import { PruText } from '@ebsme/pulse-rn-components';

import { StackNavigationProp } from '../../types/navigation';
import Screen from '../../components/Screen';

interface NavigationProps extends StackNavigationProp {
  state: {
    params: {
      uri: string;
    };
  };
}

type Props = {
  navigation: NavigationProps;
};

const ViewPdfScreen: React.FC<Props> = ({ navigation }) => {
  const source = { uri: navigation.state?.params.uri, cache: true };
  const styles = StyleSheet.create({
    pdf: {
      flex: 1
    }
  });
  const [fetchError, setFetchError] = useState(null);

  return (
    <Screen
      headingPosition="none"
      containerType="View"
      onPressBack={(): void => {
        navigation.goBack();
      }}
    >
      {fetchError && (
        <PruText testID="FetchError" style={{ margin: 24 }} size={12} weight="semiBold" color="darkRed">
          {fetchError}
        </PruText>
      )}
      <Pdf
        source={source}
        onError={(error): void => {
          //@ts-ignore
          setFetchError(error.message);
        }}
        style={styles.pdf}
      />
    </Screen>
  );
};

export default ViewPdfScreen;
