import gql from 'graphql-tag';

export const getClaimsForEmployeePolicyQuery = gql`
  query getClaimsForEmployeePolicy($certificateNo: String) {
    getClaimsForEmployeePolicy(certificateNo: $certificateNo) {
      claimType

      originalRequired

      claimDate

      claimNumber

      claimantName

      policyId

      employeeId

      documents {
        docType
        fileName
        fileBlobURL
        fileType
      }

      lbuClaimReference

      claimState

      claimSubType

      employeePolicyNumber

      lifeAssuredCertificateNo

      bankAccount {
        id
      }
    }
  }
`;

export const saveClaimMutation = gql`
  mutation SaveClaim($claim: ClaimInput) {
    saveClaim(claim: $claim) {
      claimType
      claimSubType
      originalRequired
      claimState
      claimDate
      claimNumber
      policyId
      lbuClaimReference
      documents {
        docType
        fileName
        fileBlobURL
        fileType
      }
    }
  }
`;

export const submitClaimMutation = gql`
  mutation SubmitClaim ($claim: ClaimInput) {
    submitClaim (claim: $claim) {
        claimType
        originalRequired
        claimDate
        claimNumber
        claimantName
        policyId
        employeeId
        lbuClaimReference
        claimState
        claimSubType
        employeePolicyNumber
    }
  }
`;

export const registerPulseUserMutation = gql`
  mutation registerPulseUser($input: PulseRegistrationPayload!) {
    registerPulseUser(input: $input) {
      authCodeRef
    }
  }
`;

export const getRefreshTokenForAuthCodeMutation = gql`
  mutation getRefreshTokenForAuthCode($input: AuthCodePayload!) {
    getRefreshTokenForAuthCode(input: $input) {
      token
    }
  }
`;
export const fetchPolicyAndProductsQuerry = gql`
  query GetPolicyAndProducts($policyNumber: String) {
    getPolicyAndProducts(certificate: $policyNumber) {
      policyId
      planCode
      products {
        name {
          EN
          TH
        }
        effectiveDate
        entryId
        benefitsList {
          id
          name {
            EN
            TH
          }
          entryId
          type
          value
          children {
            id
            name {
              EN
              TH
            }
            entryId
            type
            value
          }
        }
      }
    }
  }
`;
