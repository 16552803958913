import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as routes from '../../routes';
import { StackNavigationProp } from '../../types/navigation';
import Screen from '../../components/Screen';
import { PruButton } from '@ebsme/pulse-rn-components';
import { BankAccount } from '../../types/bankAccount';
import BankAccountList from '../../components/BankAccountList';
import BankAccountListLoader from '../../components/BankAccountList/BankAccountListLoader';
import ShadedContainer from '../../components/ShadedContainer';
import MessageCard from '../../components/MessageCard';

interface NavigationProps extends StackNavigationProp {
  state: {
    params: {};
  };
}

type Props = {
  navigation: NavigationProps;
  bankAccounts: BankAccount[];
  loading: boolean;
  fetchError?: string;
  fetchBankAccounts: () => void;
};

const BankAccountsScreen: React.FC<Props> = ({ navigation, bankAccounts, loading, fetchError, fetchBankAccounts }) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchBankAccounts();
  }, [fetchBankAccounts]);

  return (
    <Screen
      heading={t('BANK_ACCOUNT__TITLE')}
      subHeading={t('BANK_ACCOUNT__DESCRIPTION')}
      headerPaddingHorizontal={24}
      onPressBack={(): void => {
        navigation.navigate(routes.Landing);
      }}
    >
      <ShadedContainer>
        {fetchError && (
          <MessageCard
            level="warning"
            style={{ margin: 24 }}
            testID="BankAccountsScreen__ErrorMessage"
            title={t('ERROR__OOPS')}
            message={fetchError}
          />
        )}
        {bankAccounts.length === 0 && !loading && !fetchError && (
          <MessageCard
            style={{ margin: 24 }}
            testID="BankAccountsScreen__EmptyMessage"
            message={t('BANK_ACCOUNT__NO_BANK_ACCOUNT')}
          />
        )}
        {loading && !bankAccounts.length ? (
          <BankAccountListLoader testID="BankAccountsScreen__Loader" />
        ) : (
          <BankAccountList data={bankAccounts} testID="BankAccountsScreen__List" />
        )}
      </ShadedContainer>

      <PruButton
        style={{ marginVertical: 24 }}
        onPress={(): void => {
          navigation.navigate(routes.BankAccountCreate);
        }}
      >
        {t('BANK_ACCOUNT__ADD_BANK_ACCOUNT')}
      </PruButton>
    </Screen>
  );
};

export default BankAccountsScreen;
