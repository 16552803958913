import React, { useState, ReactElement, useEffect } from 'react';
import { View } from 'react-native';
import { Card } from 'react-native-elements';
import { StackNavigationProp } from '../../../types/navigation';
import { UserCountryCode } from '../../../types/user';
import ReviewClaimView from '../ReviewClaimView';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Claim, ClaimDocument, ClaimDocumentGroup as ClaimDocumentGroupType } from '../../../types/claim';
import {
  getClaimTypes,
  getClaimDocuments,
  getDocumentList,
  getDateFormat,
  getClaimSubTypes,
  getClaimTypeOption
} from '../../../utils/claim';

import ClaimMetaItem from '../../../components/Claim/ClaimMetaItem';
import ClaimDocumentGroup from '../../../components/Claim/ClaimDocumentGroup';
import DocumentView from '../../../components/Claim/DocumentView';
import ClaimConfirmationView from '../../../components/Claim/ClaimConfirmationView';
import { LBU } from '../../../config';
import ClaimDetailSubmitAdditional from './ClaimDetailSubmitAdditional/ClaimDetailSubmitAdditional';
import { getObjValueByPath } from '../../../utils/object';
import ClaimPaymentInfoView from '../../../components/Claim/ClaimPaymentInfoView';
import { EVENT_ANALYTICS, logFirebaseEventScreen } from '../../../utils/analytics';

export interface Props {
  lbu: UserCountryCode;
  navigation: StackNavigationProp;
  claimDetail: Claim;
  policyId: string;
}

export const renderClaimDocumentGroups = (
  claimDocuments: ClaimDocumentGroupType[],
  setSelectedItem?: (document: ClaimDocument) => void
): ReactElement[] => {
  return claimDocuments.map((document) => (
    <ClaimDocumentGroup key={document.docType} document={document} onDocumentView={setSelectedItem} />
  ));
};

const ClaimDetailScreen: React.FC<Props> = ({ lbu, claimDetail, navigation }) => {
  const { t } = useTranslation();
  const { showOriginalReceiptRequired, showAdditionalClaimInformation, showAdditionalDocumentsContactUs } =
    getObjValueByPath(LBU, `${lbu}.CLAIM_VIEW_CONFIG`, {
      showOriginalReceiptRequired: false,
      showAdditionalClaimInformation: false,
      showAdditionalDocumentsContactUs: false
    });
  useEffect(() => {
    logFirebaseEventScreen(EVENT_ANALYTICS.review_claim, {
      feature: 'ReviewClaim',
      journey: 'review_claim',
      stage: 'review_detail',
      screen_id: 'SCR_EB_REVIEW_CDS',
      screen_name: 'ClaimDetailScreen',
      status: claimDetail.claimState,
      claim_type: claimDetail.claimType
    });
  }, []);
  const {
    claimType,
    claimSubType,
    claimDate,
    documents,
    originalRequired,
    lbuClaimReference,
    bankAccount,
    lifeAssuredCertificateNo
  } = claimDetail;
  const claimNumber = claimDetail.claimNumber as string;
  const claimNumberToDisplay = lbuClaimReference || claimNumber;
  const [selectedItem, setSelectedItem] = useState<ClaimDocument | undefined | null>(null);

  const claimDocuments: ClaimDocumentGroupType[] = getClaimDocuments(lbu, claimType, claimSubType, bankAccount)
    .map((dType) => {
      const { docType, title, fileName, helpText } = getDocumentList(dType);
      const filteredDocuments = documents.filter(({ docType: dcType }) => dcType === docType);
      return {
        docType,
        title,
        fileName,
        helpText,
        documents: filteredDocuments
      };
    })
    .filter((document) => document.documents.length > 0);

  const { label } = getClaimTypeOption(lbu, claimType || '') || getClaimTypes(claimType);
  const { label: claimSubTypeLabel } = getClaimSubTypes(claimType, claimSubType);
  const onDocumentClose = (): void => setSelectedItem(null);
  const dateFormat = getDateFormat(lbu);
  return (
    <ReviewClaimView navigation={navigation} backVisible scrollView>
      {selectedItem ? <DocumentView document={selectedItem} onClose={onDocumentClose} /> : null}
      <ClaimMetaItem
        label={t('REVIEW_CLAIMS__CLAIM_TYPE')}
        value={t(label)}
        secondaryValue={claimSubType && t(claimSubTypeLabel)}
        textSize={24}
      />
      <ClaimMetaItem label={t('CLAIMS__POLICY_NUMBER__TITLE')} value={lifeAssuredCertificateNo || ''} />
      <ClaimMetaItem label={t('REVIEW_CLAIMS__CLAIM_DATE')} value={moment(claimDate).format(dateFormat) || ''} />
      {showOriginalReceiptRequired && (
        <ClaimMetaItem label={t('REVIEW_CLAIMS__RECEIPT_REQUIRED')} value={originalRequired ? 'Yes' : 'No'} />
      )}
      <ClaimMetaItem label={t('REVIEW_CLAIMS__CLAIM_NUMBER')} value={claimNumberToDisplay} />
      <Card
        containerStyle={{
          margin: 0,
          marginBottom: 20,
          elevation: 15,
          shadowOpacity: 0.1,
          shadowRadius: 15,
          shadowColor: '#ccc',
          shadowOffset: {
            width: 0,
            height: 9
          }
        }}
      >
        <ClaimMetaItem label="" value={t('REVIEW_CLAIMS__DOCUMENTATION')} />
        <View
          style={{
            marginLeft: 10,
            marginRight: 10
          }}
        >
          {renderClaimDocumentGroups(claimDocuments, setSelectedItem)}
        </View>

        {showAdditionalClaimInformation && <ClaimPaymentInfoView claim={claimDetail} />}
        {showAdditionalDocumentsContactUs && <ClaimDetailSubmitAdditional lbu={lbu} claimId={claimNumberToDisplay} />}

        <ClaimConfirmationView claimNumber={claimNumberToDisplay} />
      </Card>
    </ReviewClaimView>
  );
};

export default ClaimDetailScreen;
