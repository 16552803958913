import { isEmpty } from 'ramda';
// @ts-ignore
import { ProductCataloguePLUK, Benefit } from '@ebsme/product-catalogue';
import { ProductBenefit } from '../../../types/product';
import { CellType, MyPolicyRenderHelperBase, MyPolicyRenderObject, MyPolicyRenderHelper } from './MyPolicyRenderHelper';
import { Policy } from '../../../types/policy';
import { formatBenefitValuePH } from './../MyPolicyUtils';

export class MyPolicyRenderHelperPH extends MyPolicyRenderHelperBase implements MyPolicyRenderHelper {
  constructor(policy: Policy) {
    super(new ProductCataloguePLUK(), policy);
  }

  private static planNameLocalizeMap: Record<string, string> = {
    GPA: 'MYPOLICY__GPA',
    GTL: 'MYPOLICY__GTL',
    COMBO_GOLD: 'MYPOLICY__COMBO_GOLD'
  };

  private static idNameMap: Record<string, string> = { '2.1': 'GPA.AMR', '2.2': 'GPA.HIB' };

  private getBenefitValueString(benefit: ProductBenefit): string {
    const value = this.productCatalogue.getBenefitValueString(
      this.productCatalogue.getBenefitValue(benefit.id, this.policy.tierId) ?? benefit.value,
      { decimalPlaces: 0 }
    );
    return formatBenefitValuePH(value);
  }

  private recursivelyMapBenefit(benefit: ProductBenefit): MyPolicyRenderObject[] {
    const rows: MyPolicyRenderObject[] = [];
    const isTitleRow = benefit.level === 2;
    if (isTitleRow) {
      rows.push({
        type: CellType.ExpandableCard,
        key: benefit.id,
        title: benefit.getTitle(),
        label: benefit.getSummary(),
        value: this.getBenefitValueString(benefit),
        highlighted: true
      });
    } else if (benefit.isCollapsible && !!benefit.children) {
      const getChildrenPolicyObject = (benefit: ProductBenefit, i: number): MyPolicyRenderObject => ({
        type: CellType.ItemRow,
        key: benefit.id,
        title: '',
        label: benefit.getTitle(),
        value: this.getBenefitValueString(benefit),
        flex: true,
        highlighted: i % 2 != 0
      });
      rows.push({
        type: CellType.ExpandableCard,
        key: benefit.id,
        title: benefit.getTitle(),
        label: '',
        children: benefit.children.map((e, i) => getChildrenPolicyObject(new Benefit(e), i))
      });
      return rows; // stop recursive
    } else {
      // bold row
      rows.push({
        type: CellType.ExpandableCard,
        title: benefit.getTitle(),
        key: benefit.id,
        label: this.getBenefitValueString(benefit),
        boldColor: 'blue',
        highlighted: true
      });
    }

    benefit.showSectionTitle &&
      rows.push({ type: CellType.SectionTitle, key: benefit.id, title: '', label: benefit.getSectionTitle() });

    if (!!benefit.children) {
      return [
        ...benefit.children
          .map((e) => new Benefit(e))
          .reduce((rows: MyPolicyRenderObject[], e) => [...rows, ...this.recursivelyMapBenefit(e)], rows)
      ];
    }
    return rows;
  }

  private mapTextView = (text: string): MyPolicyRenderObject => ({
    type: CellType.PlanTitle,
    key: text,
    title: '',
    label: text
  });

  mapAllRows(): MyPolicyRenderObject[] {
    return this.productCatalogue.product.plans
      .filter((plan) => this.policy.plan?.id === plan.id)
      .reduce((rows: MyPolicyRenderObject[], plan) => {
        MyPolicyRenderHelperPH.planNameLocalizeMap[plan.name] &&
          rows.push(this.mapTextView(MyPolicyRenderHelperPH.planNameLocalizeMap[plan.name]));

        rows = plan.benefits
          .map((e) => new Benefit(e))
          .reduce((rows, benefit) => {
            return [...rows, ...this.recursivelyMapBenefit(benefit)];
          }, rows);

        !isEmpty(this.policy.plan?.optionalPlans) && rows.push(this.mapTextView('MYPOLICY__OPTIONAL__PLAN'));
        rows = this.policy.plan?.optionalPlans
          .map(({ id }) => new Benefit({ id: `${MyPolicyRenderHelperPH.idNameMap[`${id}`]}` }))
          .reduce((rows, benefit) => {
            return [...rows, ...this.recursivelyMapBenefit(benefit)];
          }, rows);
        return rows;
      }, []);
  }
}
