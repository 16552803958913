import React, { FunctionComponent, ReactElement } from 'react';
import { FlatList } from 'react-native';

import LogRequestListItem from './LogRequestListItem';
import { LogRequest } from '../../types/logRequest';

interface Props {
  data: LogRequest[];
  ListHeaderComponent?: FunctionComponent;
}

const LogList: React.FC<Props> = ({ ListHeaderComponent, data }) => {
  return (
    <FlatList
      data={data}
      ListHeaderComponent={ListHeaderComponent}
      keyExtractor={(item: LogRequest): string => item.ref}
      renderItem={({ item }): ReactElement => <LogRequestListItem item={item} />}
    />
  );
};

export default LogList;
