import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from './routes';

import RewardsScreenContainer from './RewardsScreenContainer';
import PDFViewScreen from './PDFViewScreen/PDFViewScreen';

const routeConfigMap = {
  [routes.Rewards]: {
    screen: RewardsScreenContainer
  },
  [routes.PDFViewScreen]: {
    screen: PDFViewScreen
  }
};

const RewardsNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.Rewards,
    headerMode: 'none'
  })
);

export default RewardsNavigator;
