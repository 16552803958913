import styled from 'styled-components/native';
import { white } from '../../../assets/colors';
import { getShadowStyles } from '../../../assets/mixins';

const cardShadow = getShadowStyles();

export const Card = styled.View`
  padding: 18px;
  border-radius: 10px;
  background-color: ${white};
  ${cardShadow}
`;

export const LinkView = styled.View`
  align-items: center;
  justify-content: center;
  margin-vertical: 10px;
`;
