import app_ebsme_pulse from './app_ebsme_pulse.json';
import global_pacs from './global_pacs.json';
import global_plt from './global_plt.json';
import global_pluk from './global_pluk.json';
import global_pmli from './global_pmli.json';
import module_product_catalogue_pamb from './module_product_catalogue_pamb.json';
import module_product_catalogue_pluk from './module_product_catalogue_pluk.json';
import module_product_catalogue_thailand from './module_product_catalogue_thailand.json';

const EnResource = {
  app_ebsme_pulse,
  global_pacs,
  global_plt,
  global_pluk,
  global_pmli,
  module_product_catalogue_pamb,
  module_product_catalogue_pluk,
  module_product_catalogue_thailand
};

export default EnResource;
