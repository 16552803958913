import { Trans } from '../components/MyPolicy/MyPolicyRenderHelper/MyPolicyRenderHelper';

export const translateLabel = (label: Trans | undefined, locale: string): string | undefined => {
  let s: string | undefined = '';
  if (locale === 'th') {
    s = label?.TH;
  } else {
    s = label?.EN;
  }
  return s || '';
};
