import styled from 'styled-components/native';

import { darkRed, lightGrey } from '../../assets/colors';

export const Container = styled.KeyboardAvoidingView`
  flex: 0 1;
  flex-basis: 100%;
  height: 100%;
  background-color: ${lightGrey};
`;

export const Header = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
  background-color: ${darkRed};
`;

export const HeaderButton = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

export const BackIcon = styled.Image`
  width: 10px;
  height: 14px;
  resize-mode: contain;
  margin-right: 12px;
`;

export const ContentScroll = styled.ScrollView`
  flex: 0 1;
  flex-basis: 100%;
  height: 100%;
  padding-bottom: 40px;
  background-color: ${lightGrey};
`;
