import styled from 'styled-components/native';

import * as colors from '../../assets/colors';

export const BackIconContainer = styled.View`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin-right: 8px;
  background-color: ${colors.red};
`;
