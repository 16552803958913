import { Platform } from 'react-native';
import { getPulseUserId, getUserCountryCode } from '../reducers/PulseUserReducer/selectors';
import { getCorporateUserNationalId } from '../reducers/CorporateUserReducer/selectors';

import store, { RootState } from '../store';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties as setUserPropertiesLib } from 'firebase/analytics';
import Config from '../components/Config';
import { Event_Data } from '../types/analytics';
import { getPolicies } from '../reducers/MyPolicyReducer/selectors';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: Config.EB_FIREBASE_API_KEY,
  authDomain: Config.EB_FIREBASE_AUTH_DOMAIN,
  databaseURL: Config.EB_FIREBASE_DATABASE_URL,
  projectId: Config.EB_FIREBASE_PROJECT_ID,
  storageBucket: Config.EB_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: Config.EB_FIREBASE_MESSAGING_SENDER_ID,
  appId: Config.EB_FIREBASE_APP_ID,
  measurementId: Config.EB_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logFirebaseEvent = (eventName: string, eventData: any) => {
  const rootState: any = store.getState();
  const lbu = getUserCountryCode(rootState) || 'TH';
  const policies = getPolicies(rootState);
  const employees = policies ? policies[0]?.employees : null;
  const eb_user_id = employees ? employees[0]?.id : null;
  try {
    if (eventName) {
      const event_source = getEventSoure(lbu);
      // const app_bundle_id = DeviceInfo.getBundleId();
      logEvent(analytics, eventName, {
        ...eventData,
        timestamp: new Date().toString(),
        module: 'EB',
        event_source,
        eb_user_id: eb_user_id || null,
        eb_user_lbu: lbu
      });
    }
  } catch (e) {
    console.log('logFirebaseEvent error', e);
  }
};
export const logFirebaseEventClick = (eventName: string, eventData: Event_Data) => {
  const eventSdk: Event_Data = {
    ...eventData,
    event_type: 'Click'
  };
  logFirebaseEvent(eventName, eventSdk);
};
export const logFirebaseEventBackend = (eventName: string, eventData: Event_Data) => {
  const eventSdk: Event_Data = {
    ...eventData,
    event_type: 'Backend'
  };
  logFirebaseEvent(eventName, eventSdk);
};
export const logFirebaseEventScreen = (eventName: string, eventData: Event_Data) => {
  const eventSdk: Event_Data = {
    ...eventData,
    event_type: 'Screen'
  };
  logFirebaseEvent(eventName, eventSdk);
};

export const setUserProperties = (data: any) => {
  try {
    if (data) {
      setUserPropertiesLib(analytics, data);
    }
  } catch (e) {
    console.log('firebase error', e);
  }
};

const getEventSoure = (lbu: string) => {
  return `OnePulse-${lbu}-${Platform.OS}`;
};

export const EVENT_ANALYTICS = {
  landing: 'eb_landing',
  login: 'eb_login',
  policy: 'eb_policy',
  make_claim: 'eb_make_claim',
  review_claim: 'eb_review_claim',
  clinic_locator: 'eb_clinic_locator',
  help: 'eb_help',
  card: 'eb_card'
};

export const OBJECT_ID_CLICK = {
  back2pule: 'BTN_EB_HOME_LANDS_BACK2PULSE',
  login_submit: 'BTN_EB_LOGIN_OES_SUBMIT',
  rememberme: 'CB_EB_LOGIN_OES_REMEMBER',
  resend: 'BTN_EB_LOGIN_OVS_RESEND',
  tiles_policy: 'TLE_EB_POLICY_LANDS_POLICY',
  tiles_claim: 'TLE_EB_MC_LANDS_MAKEC',
  tiles_review: 'TLE_EB_REVIEW_LANDS_REVIEW',
  tiles_clinic: 'TLE_EB_CL_LANDS_CLINIC',
  tiles_help: 'TLE_EB_HELP_LANDS_HELP',
  tiles_card: 'BNR_EB_CARD_LANDS_ECARD',
  tiles_card_swipe: 'BNR_EB_CARD_LANDS_SWIPEE',
  change_policy: 'DRP_EB_POLICY_LANDS_PLDR'
};
