import React, { RefObject, useState, useRef, useEffect, useCallback } from 'react';
import { View, ScrollView, TouchableOpacity, BackHandler, Keyboard } from 'react-native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import * as routes from '../../routes';
import { StackNavigationProp } from '../../types/navigation';
import {
  resetClaim,
  saveClaimForProcess,
  setError,
  setInitialClaim,
  updateClaim
} from '../../reducers/MakeClaimReducer/actions';
import { fetchClaims } from '../../reducers/ReviewClaimReducer/actions';
import { PruConfirmationModal, PruText } from '@ebsme/pulse-rn-components';
import ProgressBar from '../../components/ProgressBar';
import FloatingButton from '../../components/FloatingButton';
import BackIcon from '../../components/BackIcon';

import Screen from '../../components/Screen';
import { Container } from './MakeClaimScreensStyles';
import MakeClaimViewSaveButton from './MakeClaimViewSaveButton';
import { RootState } from '../../store';
import { getClaim, getError, getLoading, hasChanged, isDraftClaim } from '../../reducers/MakeClaimReducer/selectors';
import { getPolicyId, getPolicy } from '../../reducers/MyPolicyReducer/selectors';
import { Claim, ClaimState } from '../../types/claim';
import { getFeatureToggle } from '../../config/selector';
import { FeatureToggle } from '../../types/featureToggle';
import { formatAmountValue } from '../../utils/claim';
import { getClaimStep } from './routesUtility';
import { getUserCountryCode } from '../../reducers/PulseUserReducer/selectors';
import { UserCountryCode } from '../../types/user';
import { Policy } from '../../types/policy';

interface Props {
  children: React.ReactNode;
  headTitle?: string;
  title: string;
  lbu?: UserCountryCode;
  currentRoute: string;
  backVisible?: boolean;
  nextDisabled?: boolean;
  navigation: StackNavigationProp;
  loading?: boolean;
  error?: string | null;
  setError: (error: string) => void;
  resetClaim: (claimState?: ClaimState) => void;
  scrollRef?: RefObject<ScrollView | null>;
  onSubStepBack?: () => void;
  featureToggle: FeatureToggle;
  policy?: Policy;
  policyId: string;
  claim: Claim;
  enableSave?: boolean;
  saveClaimForProcess: (partialClaim: Claim, callback: (claim?: Claim) => void) => void;
  updateClaim: (claim: Claim) => void;
  setInitialClaim: (claim: Claim | null) => void;
  fetchClaims: (certificateNo: string) => void;
  hasChanged: boolean;
  isDraftClaim: boolean;
  note?: string;
}

const MakeClaimView: React.FC<Props> = ({
  children,
  headTitle,
  title,
  note,
  lbu,
  currentRoute,
  backVisible,
  nextDisabled,
  navigation,
  resetClaim,
  onSubStepBack,
  loading,
  error,
  setError,
  featureToggle,
  policy,
  policyId,
  claim,
  enableSave,
  saveClaimForProcess,
  updateClaim,
  setInitialClaim,
  fetchClaims,
  hasChanged,
  isDraftClaim
}) => {
  const { t } = useTranslation();

  const { totalSteps, currentStep, nextRoute } = getClaimStep(
    lbu ?? 'PH',
    currentRoute,
    claim && {
      documentsRequired: claim.originalRequired
    }
  );

  const scrollRefObj = useRef<ScrollView>();

  const [showSaveDraftConfirmModal, setShowSaveDraftConfirmModal] = useState(false);
  const [showSaveDraftSuccessModal, setShowSaveDraftSuccessModal] = useState(false);
  const [isKeyboardShowing, setIsKeyboardShowing] = useState(false);

  const _keyboardDidShow = (): void => {
    setIsKeyboardShowing(true);
  };

  const _keyboardDidHide = (): void => {
    setIsKeyboardShowing(false);
  };

  useEffect(() => {
    const keyboardDidShow = Keyboard.addListener('keyboardDidShow', _keyboardDidShow);
    const keyboardDidHide = Keyboard.addListener('keyboardDidHide', _keyboardDidHide);

    // cleanup function
    return (): void => {
      keyboardDidShow.remove();
      keyboardDidHide.remove();
    };
  }, []);

  const handleReturnNavigation = useCallback((): void => {
    if (isDraftClaim) {
      navigation.navigate(routes.ClaimListScreen);
      fetchClaims(policy?.certificateNo || '');
    } else {
      navigation.navigate(routes.Landing);
    }
    resetClaim();
  }, [fetchClaims, isDraftClaim, navigation, resetClaim]);

  const onPressCancel = useCallback((): void => {
    if (enableSave && featureToggle.enabledSaveDraft && hasChanged) {
      setShowSaveDraftConfirmModal(true);
    } else {
      handleReturnNavigation();
    }
  }, [enableSave, featureToggle.enabledSaveDraft, handleReturnNavigation, hasChanged]);

  const onPressBack = useCallback((): void => {
    if (currentStep === 1) {
      onPressCancel();
    }
    // if (currentStep === 2) {
    //   isDraftClaim ? resetClaim('DRAFT') : resetClaim();
    // }
    navigation.goBack();
  }, [currentStep, isDraftClaim, navigation, onPressCancel, resetClaim]);

  useEffect(() => {
    const backAction = (): boolean => {
      onPressBack();
      return true;
    };
    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);
    return (): void => backHandler.remove();
  }, [onPressBack, resetClaim]);

  const saveClaim = (): void => {
    const amount = claim && claim.amount && formatAmountValue(claim.amount);
    const claimPayload = {
      ...claim,
      amount,
      uiState: navigation?.state?.routeName,
      policyId,
      documents:
        (claim &&
          claim.documents.map(({ docType, fileBlobURL, fileName, fileType }) => ({
            docType,
            fileBlobURL,
            fileName,
            fileType
          }))) ||
        []
    };
    saveClaimForProcess(claimPayload, (claim) => {
      setShowSaveDraftConfirmModal(false);
      if (claim) {
        updateClaim(claim);
        setInitialClaim(claim);
        setShowSaveDraftSuccessModal(true);
      }
    });
  };

  const [MAKE, A_CLAIM] = t('CLAIMS__MAKE_A_CLAIM');

  const curryDismissKeyboard = (next: () => void) => (): void => {
    Keyboard.dismiss();
    next();
  };

  return (
    <Container>
      <Screen
        scrollRef={scrollRefObj}
        headerPaddingHorizontal={24}
        headingPosition={'none'}
        containerType="KeyboardAwareScrollView"
        containerProps={{ enableOnAndroid: true, extraScrollHeight: 100, keyboardShouldPersistTaps: 'handled' }}
        EndComponent={
          enableSave && featureToggle.enabledSaveDraft ? (
            <MakeClaimViewSaveButton disabled={loading} onPress={curryDismissKeyboard(saveClaim)} />
          ) : null
        }
        onPressBack={backVisible ? curryDismissKeyboard(onPressBack) : undefined}
        onPressCancel={curryDismissKeyboard(onPressCancel)}
      >
        {headTitle ? (
          <PruText color="black" size={28} weight="bold" marginLeft={20}>
            {headTitle}
          </PruText>
        ) : (
          <PruText color="black" size={28} weight="thin" marginLeft={20}>
            <PruText weight="bold">{MAKE}</PruText> {A_CLAIM}
          </PruText>
        )}

        <ProgressBar steps={totalSteps} currentStep={currentStep} />
        {onSubStepBack ? (
          <TouchableOpacity
            onPress={curryDismissKeyboard(onSubStepBack)}
            style={{ marginLeft: 20, marginBottom: 20, alignSelf: 'flex-start' }}
          >
            <View testID="MakeClaimView__SubStepBackButton" style={{ flexDirection: 'row' }}>
              <BackIcon />
              <PruText color="black" size={18} weight="bold" marginLeft={8}>
                {title}
              </PruText>
            </View>
          </TouchableOpacity>
        ) : !!title ? (
          <PruText color="black" size={18} weight="bold" marginLeft={20} marginBottom={20}>
            {title}
          </PruText>
        ) : null}
        {!!note && (
          <PruText color="black" size={14} marginLeft={20} marginBottom={20} marginRight={20}>
            {note}
          </PruText>
        )}
        {children}

        <PruConfirmationModal
          isVisible={showSaveDraftConfirmModal}
          title={t('CLAIMS__SAVE__TITLE')}
          message={t('CLAIMS__SAVE__MESSAGE')}
          confirmText={t('BUTTON__YES')}
          cancelText={t('BUTTON__NO')}
          onConfirm={(): void => {
            setShowSaveDraftConfirmModal(false);
            resetClaim();
            handleReturnNavigation();
          }}
          onCancel={(): void => {
            setShowSaveDraftConfirmModal(false);
          }}
        />
        <PruConfirmationModal
          isVisible={showSaveDraftSuccessModal}
          title={t('CLAIMS__SAVE__SUCCESS')}
          message={t('CLAIMS__SAVE__SUCCESS__MESSAGE')}
          confirmText={t('BUTTON__OK')}
          onConfirm={(): void => {
            setShowSaveDraftSuccessModal(false);
          }}
        />
        <PruConfirmationModal
          isVisible={!!error}
          title={t(`ERRORS.TITLE`)}
          message={t(`ERRORS.${error}`)}
          confirmText={t('BUTTON__OK')}
          onConfirm={(): void => {
            setError('');
          }}
        />
      </Screen>

      {currentStep < totalSteps && !loading && !isKeyboardShowing && nextRoute ? (
        <FloatingButton
          testID="MakeClaimView__FloatingButton"
          disabled={nextDisabled}
          onPress={curryDismissKeyboard((): void => {
            navigation.navigate(nextRoute);
          })}
          accessibilityLabel={t('BUTTON__NEXT')}
          accessibilityHint="Go to the next Make Claim page"
        />
      ) : null}
    </Container>
  );
};

const MakeClaimViewContainer = connect(
  (state: RootState) => ({
    lbu: getUserCountryCode(state),
    policyId: getPolicyId(state),
    claim: getClaim(state),
    hasChanged: hasChanged(state),
    policy: getPolicy(state),
    loading: getLoading(state),
    error: getError(state),
    featureToggle: getFeatureToggle(state),
    isDraftClaim: isDraftClaim(state)
  }),
  { resetClaim, saveClaimForProcess, updateClaim, setInitialClaim, setError, fetchClaims }
)(MakeClaimView);

export default MakeClaimViewContainer;
