import React from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { StackNavigationProp } from '../../../types/navigation';
import { PruText } from '@ebsme/pulse-rn-components';
import Screen from '../../../components/Screen';
import * as routes from '../../../routes';

interface Props {
  navigation: StackNavigationProp<
    {},
    {
      input: string;
      type: string;
    }
  >;
}
const OnboardingForgetConfirmScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();
  const inputValue = navigation.getParam('input');
  const type = navigation.getParam('type');
  return (
    <Screen
      heading={''}
      headerPaddingHorizontal={24}
      onPressCancel={(): boolean => navigation.navigate(routes.OnboardingEmail)}
    >
      <View style={{ paddingHorizontal: 24 }}>
        <PruText size={24} weight="bold">
          {t('FORGOT_CREDENTIALS_SENT_MESSAGE', { type: type == 'EMAIL' ? 'email address' : 'Mobile number' })}
        </PruText>
        <PruText size={24} weight="bold">
          {inputValue}
        </PruText>
        <PruText size={24} weight="bold">
          {t('FORGOT_CREDENTIALS_PLEASE_CHECK')}
        </PruText>
        <View style={{ height: 4, backgroundColor: '#36B37E', marginTop: 20 }} />
      </View>
    </Screen>
  );
};

export default OnboardingForgetConfirmScreen;
