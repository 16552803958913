import { connect } from 'react-redux';

import { RootState } from '../../store';
import { getSDKError, getSDKException, getSDKLoading } from '../../reducers/SDKReducer/selectors';
import { setSDKError } from './../../reducers/SDKReducer/actions';

import LoadingOverlay from './LoadingOverlay';

const LoadingOverlayContainer = connect(
  (state: RootState) => ({
    sdkLoading: getSDKLoading(state),
    sdkError: getSDKError(state),
    sdkException: getSDKException(state)
  }),
  { setSDKError }
)(LoadingOverlay);

export default LoadingOverlayContainer;
