import {
  FETCH_CLINICS_START,
  FETCH_CLINICS_SUCCESS,
  FETCH_CLINICS_FAILED,
  ClinicsAction,
  CLEAR_CLINICS
} from './actions';
import { Clinic, ClinicNearBy } from '../../types/clinic';

export type { ClinicsAction };

type ClinicsState = {
  clinicNearBy: ClinicNearBy;
  fetchError?: string;
  loading: boolean;
};

export const initialState: ClinicsState = {
  clinicNearBy: { clinics: [], totalPage: '0', total: '0', currentPage: '0', pageSize: '0' },
  fetchError: undefined,
  loading: false
};

const mapClinic = (newData: ClinicNearBy, oldData: ClinicNearBy) => {
  if (newData.currentPage == '0') {
    return newData;
  } else {
    return { ...newData, clinics: [...oldData.clinics, ...newData.clinics] };
  }
};
const ClinicsReducer = (state: ClinicsState = initialState, action: ClinicsAction): ClinicsState => {
  switch (action.type) {
    case FETCH_CLINICS_START:
      return {
        ...state,
        loading: true,
        fetchError: undefined
      };

    case FETCH_CLINICS_SUCCESS:
      return {
        ...state,
        clinicNearBy: mapClinic(action.payload, state.clinicNearBy),
        loading: false
      };
    case FETCH_CLINICS_FAILED:
      return {
        ...state,
        loading: false,
        fetchError: action.payload
      };
    case CLEAR_CLINICS:
      return initialState;
    default:
      return state;
  }
};

export default ClinicsReducer;
