import { RootState } from '../../store';
import { Action, Dispatch } from 'redux';

import { Policy, ECareCard } from '../../types/policy';
import { RegisterCorpUserAction } from '../CorporateUserReducer/types';
import { getUserCountryCode } from '../PulseUserReducer/selectors';
import { hasDependantRole } from '../CorporateUserReducer/selectors';
import { apiPolicyToPolicy } from './adapters';
import {
  fetchPolicy,
  fetchECareCardApi,
  fetchPolicyAndProductsApi,
  fetchDependantsApi,
  fetchPulseUserConfig,
  getAllEmployeeDetails,
  fetchHospitalOrReason
} from './api';
import { Dependant, HospitalAndReason, PulseUserConfig } from '../../types/claim';
import { setUserProperties } from '../../utils/analytics';
import { getSDKLocale } from '../SDKReducer/selectors';

export const FETCH_MY_POLICY_START = 'MyPolicy/FETCH_MY_POLICY_START';
export const FETCH_MY_POLICY_SUCCESS = 'MyPolicy/FETCH_MY_POLICY_SUCCESS';
export const FETCH_MY_POLICY_FAILED = 'MyPolicy/FETCH_MY_POLICY_FAILED';
export const FETCH_POLICY_DETAIL_START = 'MyPolicy/FETCH_POLICY_DETAIL_START';
export const FETCH_POLICY_DETAIL_SUCCESS = 'MyPolicy/FETCH_POLICY_DETAIL_SUCCESS';
export const FETCH_POLICY_DETAIL_FAILED = 'MyPolicy/FETCH_POLICY_DETAIL_FAILED';

export const FETCH_POLICY_DEPENDANT_START = 'MyPolicy/FETCH_POLICY_DEPENDANT_START';
export const FETCH_POLICY_DEPENDANT_SUCCESS = 'MyPolicy/FETCH_POLICY_DEPENDANT_SUCCESS';
export const FETCH_POLICY_DEPENDANT_FAILED = 'MyPolicy/FETCH_POLICY_DEPENDANT_FAILED';

export const FETCH_ECARE_CARD_START = 'MyPolicy/FETCH_ECARE_CARD_START';
export const FETCH_ECARE_CARD_SUCCESS = 'MyPolicy/FETCH_ECARE_CARD_SUCCESS';
export const FETCH_ECARE_CARD_FAILED = 'MyPolicy/FETCH_ECARE_CARD_FAILED';
export const CLEAR_POLICY = 'MyPolicy/CLEAR_POLICY';
export const SELECT_POLICY = 'MyPolicy/SELECT_POLICY';

export const FETCH_DEPENDANT_START = 'MyPolicy/FETCH_DEPENDANT_START';
export const FETCH_DEPENDANT_SUCCESS = 'MyPolicy/FETCH_DEPENDANT_SUCCESS';
export const FETCH_DEPENDANT_FAILED = 'MyPolicy/FETCH_DEPENDANT_FAILED';

// Get Pulse User Config Actions
export const GET_PULSE_USER_CONFIG_START = 'MyPolicy/GET_PULSE_USER_CONFIG_START';
export const GET_PULSE_USER_CONFIG_SUCCESS = 'MyPolicy/GET_PULSE_USER_CONFIG_SUCCESS';
export const GET_PULSE_USER_CONFIG_FAILED = 'MyPolicy/GET_PULSE_USER_CONFIG_FAILED';

export const GET_HOSPITAL_AND_REASON_START = 'MyPolicy/GET_HOSPITAL_AND_REASON_START';
export const GET_HOSPITAL_AND_REASON_SUCCESS = 'MyPolicy/GET_HOSPITAL_AND_REASON_SUCCESS';
export const GET_HOSPITAL_AND_REASON_FAILED = 'MyPolicy/GET_HOSPITAL_AND_REASON_FAILED';

interface FetchMyPolicyStart extends Action {
  type: typeof FETCH_MY_POLICY_START;
}

interface FetchMyPolicySuccess extends Action {
  type: typeof FETCH_MY_POLICY_SUCCESS;
  payload: Policy[];
}

interface FetchMyPolicyFailed extends Action {
  type: typeof FETCH_MY_POLICY_FAILED;
  payload: string;
}

interface FetchPolicyDetailStart extends Action {
  type: typeof FETCH_POLICY_DETAIL_START;
}

interface FetchPolicyDetailSuccess extends Action {
  type: typeof FETCH_POLICY_DETAIL_SUCCESS;
  payload: any;
}

interface FetchPolicyDetailFailed extends Action {
  type: typeof FETCH_POLICY_DETAIL_FAILED;
  payload: string;
}
interface FetchPolicyDependantStart extends Action {
  type: typeof FETCH_POLICY_DEPENDANT_START;
}

interface FetchPolicyDependantSuccess extends Action {
  type: typeof FETCH_POLICY_DEPENDANT_SUCCESS;
  payload: any;
}

interface FetchPolicyDependantFailed extends Action {
  type: typeof FETCH_POLICY_DEPENDANT_FAILED;
  payload: string;
}

interface FetchECareCardStart extends Action {
  type: typeof FETCH_ECARE_CARD_START;
}

interface FetchECareCardSuccess extends Action {
  type: typeof FETCH_ECARE_CARD_SUCCESS;
  payload: ECareCard[];
}

interface FetchECareCardFailed extends Action {
  type: typeof FETCH_ECARE_CARD_FAILED;
  payload: string;
}

interface ClearPolicy extends Action {
  type: typeof CLEAR_POLICY;
}

interface SelectPolicy extends Action {
  type: typeof SELECT_POLICY;
  payload: string;
}

interface GetPulseUserConfigStart extends Action {
  type: typeof GET_PULSE_USER_CONFIG_START;
}

interface GetPulseUserConfigSuccess extends Action {
  type: typeof GET_PULSE_USER_CONFIG_SUCCESS;
  payload: PulseUserConfig;
}

interface GetPulseUserConfigFailed extends Action {
  type: typeof GET_PULSE_USER_CONFIG_FAILED;
  payload: string;
}

interface GetHosptialOrReasonConfigStart extends Action {
  type: typeof GET_HOSPITAL_AND_REASON_START;
}

interface GetHosptialOrReasonConfigSuccess extends Action {
  type: typeof GET_HOSPITAL_AND_REASON_SUCCESS;
  payload: HospitalAndReason;
}

interface GetHosptialOrReasonConfigFailed extends Action {
  type: typeof GET_HOSPITAL_AND_REASON_FAILED;
  payload: string;
}

export const getPulseUserConfigStart = (): GetPulseUserConfigStart => ({ type: GET_PULSE_USER_CONFIG_START });

export const getPulseUserConfigSuccess = (pulseUserConfig: PulseUserConfig): GetPulseUserConfigSuccess => ({
  type: GET_PULSE_USER_CONFIG_SUCCESS,
  payload: pulseUserConfig
});

export const getPulseUserConfigFailed = (fetchError: string): GetPulseUserConfigFailed => ({
  type: GET_PULSE_USER_CONFIG_FAILED,
  payload: fetchError
});

export const getHosptialOrReasonConfigStart = (): GetHosptialOrReasonConfigStart => ({
  type: GET_HOSPITAL_AND_REASON_START
});

export const getHosptialOrReasonConfigSuccess = (
  hospitalAndReasons: HospitalAndReason
): GetHosptialOrReasonConfigSuccess => ({
  type: GET_HOSPITAL_AND_REASON_SUCCESS,
  payload: hospitalAndReasons
});

export const getHosptialOrReasonConfigFailed = (fetchError: string): GetHosptialOrReasonConfigFailed => ({
  type: GET_HOSPITAL_AND_REASON_FAILED,
  payload: fetchError
});

export const fetchMyPolicyStart = (): FetchMyPolicyStart => ({ type: FETCH_MY_POLICY_START });

export const fetchMyPolicySuccess = (policies: Policy[]): FetchMyPolicySuccess => ({
  type: FETCH_MY_POLICY_SUCCESS,
  payload: policies
});

export const fetchMyPolicyFailed = (fetchError: string): FetchMyPolicyFailed => ({
  type: FETCH_MY_POLICY_FAILED,
  payload: fetchError
});

export const fetchPolicyDetailStart = (): FetchPolicyDetailStart => ({ type: FETCH_POLICY_DETAIL_START });

export const fetchPolicyDetailSuccess = (res: any): FetchPolicyDetailSuccess => ({
  type: FETCH_POLICY_DETAIL_SUCCESS,
  payload: res
});

export const fetchPolicyDetailFailed = (fetchError: string): FetchPolicyDetailFailed => ({
  type: FETCH_POLICY_DETAIL_FAILED,
  payload: fetchError
});

export const fetchPolicyDependantStart = (): FetchPolicyDependantStart => ({ type: FETCH_POLICY_DEPENDANT_START });

export const fetchPolicyDependantSuccess = (res: any): FetchPolicyDependantSuccess => ({
  type: FETCH_POLICY_DEPENDANT_SUCCESS,
  payload: res
});

export const fetchPolicyDependantFailed = (fetchError: string): FetchPolicyDependantFailed => ({
  type: FETCH_POLICY_DEPENDANT_FAILED,
  payload: fetchError
});

export const fetchECareCardStart = (): FetchECareCardStart => ({ type: FETCH_ECARE_CARD_START });

export const fetchECareCardSuccess = (eCareCards: ECareCard[]): FetchECareCardSuccess => ({
  type: FETCH_ECARE_CARD_SUCCESS,
  payload: eCareCards
});

export const fetchECareCardFailed = (fetchError: string): FetchECareCardFailed => ({
  type: FETCH_ECARE_CARD_FAILED,
  payload: fetchError
});

export const clearPolicy = (): ClearPolicy => ({
  type: CLEAR_POLICY
});

export const selectPolicy = (id: string): SelectPolicy => ({
  type: SELECT_POLICY,
  payload: id
});

export interface FetchDependantStartAction extends Action {
  type: typeof FETCH_DEPENDANT_START;
}

export const fetchDependantStart = (): FetchDependantStartAction => ({
  type: FETCH_DEPENDANT_START
});

export interface FetchDependantSuccessAction extends Action {
  type: typeof FETCH_DEPENDANT_SUCCESS;
  payload: Dependant[];
}

export const fetchDependantSuccess = (dependants: Dependant[]): FetchDependantSuccessAction => ({
  type: FETCH_DEPENDANT_SUCCESS,
  payload: dependants
});

export interface FetchDependantFailedAction extends Action {
  type: typeof FETCH_DEPENDANT_FAILED;
  payload: string;
}

export const fetchDependantFailed = (fetchError: string): FetchDependantFailedAction => ({
  type: FETCH_DEPENDANT_FAILED,
  payload: fetchError
});

export type MyPolicyAction =
  | FetchMyPolicyStart
  | FetchMyPolicySuccess
  | FetchMyPolicyFailed
  | ClearPolicy
  | FetchECareCardStart
  | FetchECareCardSuccess
  | FetchECareCardFailed
  | SelectPolicy
  | FetchPolicyDetailStart
  | FetchPolicyDetailSuccess
  | FetchPolicyDetailFailed
  | FetchDependantStartAction
  | FetchDependantSuccessAction
  | FetchDependantFailedAction
  | GetPulseUserConfigStart
  | GetPulseUserConfigSuccess
  | GetPulseUserConfigFailed
  | GetHosptialOrReasonConfigStart
  | GetHosptialOrReasonConfigSuccess
  | GetHosptialOrReasonConfigFailed
  | FetchPolicyDependantStart
  | FetchPolicyDependantSuccess
  | FetchPolicyDependantFailed;
export const getPulseUserConfig =
  (certificate: string) =>
  async (dispatch: Dispatch<MyPolicyAction>): Promise<void> => {
    dispatch(getPulseUserConfigStart());
    try {
      const pulseUserConfig = await fetchPulseUserConfig(certificate);
      if (pulseUserConfig?.data?.getPulseUserConfig) {
        dispatch(getPulseUserConfigSuccess(pulseUserConfig.data.getPulseUserConfig));
      } else {
        dispatch(getPulseUserConfigFailed('No Config found in API ressponse'));
      }
    } catch (e) {
      dispatch(getPulseUserConfigFailed(e.message));
    }
  };

export const getHospitalOrReason =
  (certificate: string, reason: string) =>
  async (dispatch: Dispatch<MyPolicyAction>): Promise<void> => {
    dispatch(getHosptialOrReasonConfigStart());
    try {
      const hospitalAndReasons = await fetchHospitalOrReason(certificate, reason);
      if (hospitalAndReasons?.data?.getHospitalOrReason) {
        dispatch(getHosptialOrReasonConfigSuccess(hospitalAndReasons.data.getHospitalOrReason));
      } else {
        dispatch(getHosptialOrReasonConfigFailed('No Config found in API ressponse'));
      }
    } catch (e) {
      dispatch(getHosptialOrReasonConfigFailed(e.message));
    }
  };

export const fetchMyPolicy =
  () =>
  async (dispatch: Dispatch<MyPolicyAction | RegisterCorpUserAction>, getState: () => RootState): Promise<void> => {
    dispatch(fetchMyPolicyStart());
    const countryCode = getUserCountryCode(getState()) ?? 'PH';
    try {
      const apiPolicy = hasDependantRole(getState())
        ? await getAllEmployeeDetails(countryCode)
        : await fetchPolicy({ countryCode });
      const policies = apiPolicyToPolicy({ apiPolicy, countryCode });

      if (policies && policies.length > 0) {
        const employees = policies[0]?.employees;
        if (employees && employees[0] && employees[0].id) {
          const locale = getSDKLocale(getState()) ?? 'TH';
          setUserProperties({
            user_lbu: countryCode,
            user_id: employees[0].id,
            country: countryCode,
            language: locale.toUpperCase()
          });
        }
      }

      dispatch(fetchMyPolicySuccess(policies));
    } catch (e) {
      dispatch(fetchMyPolicyFailed(e.message));
    }
  };

export const fetchPolicyDetail =
  (policyNumber: string) =>
  async (dispatch: Dispatch<MyPolicyAction>, getState: () => RootState): Promise<void> => {
    dispatch(fetchPolicyDetailStart());
    try {
      const countryCode = getUserCountryCode(getState()) ?? 'PH';

      const apiPolicyDetail = await fetchPolicyAndProductsApi(policyNumber, countryCode);

      dispatch(fetchPolicyDetailSuccess(apiPolicyDetail));
    } catch (e) {
      dispatch(fetchPolicyDetailFailed(e.message));
    }
  };

export const fetchPolicyDependant =
  (policyNumber: string) =>
  async (dispatch: Dispatch<MyPolicyAction>, getState: () => RootState): Promise<void> => {
    dispatch(fetchPolicyDependantStart());
    try {
      const countryCode = getUserCountryCode(getState()) ?? 'PH';

      const apiPolicyDetail = await fetchPolicyAndProductsApi(policyNumber, countryCode);

      dispatch(fetchPolicyDependantSuccess(apiPolicyDetail));
    } catch (e) {
      dispatch(fetchPolicyDependantFailed(e.message));
    }
  };

export const fetchECareCard =
  (certificate: string, language: string) =>
  async (dispatch: Dispatch<MyPolicyAction>, getState: () => RootState): Promise<void> => {
    dispatch(fetchECareCardStart());
    try {
      const eCareCardRes = await fetchECareCardApi(certificate, language);
      dispatch(fetchECareCardSuccess(eCareCardRes.data.getEcareCardImg));
    } catch (e) {
      dispatch(fetchECareCardFailed(e.message));
    }
  };

export const fetchDependants =
  (id: string) =>
  async (dispatch: Dispatch<MyPolicyAction>): Promise<void> => {
    dispatch(fetchDependantStart());
    try {
      const response = await fetchDependantsApi(id);
      dispatch(fetchDependantSuccess(response.data.dependants));
    } catch (error) {
      dispatch(fetchDependantFailed(error.message));
    }
  };
