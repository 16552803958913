import { showLocation } from 'react-native-map-link';
import { Coordinate } from '../types/coordinate';

export const openMap = (
  t: Function,
  { latitude, longitude }: Coordinate,
  title?: string
): Promise<string | null | undefined> => {
  return showLocation({
    latitude,
    longitude,
    title,
    googleForceLatLon: true,
    alwaysIncludeGoogle: true,
    dialogTitle: t('OPEN_MAP__TITLE'), // optional (default: 'Open in Maps')
    dialogMessage: t('OPEN_MAP__MESSAGE'), // optional (default: 'What app would you like to use?')
    cancelText: t('OPEN_MAP__CANCEL') // optional (default: 'Cancel')
  });
};
