import styled from 'styled-components/native';
import { getShadowStyles } from '../../assets/mixins';

import { lightGrey, white } from '../../assets/colors';

export const Container = styled.SafeAreaView`
  flex: 0 1;
  flex-basis: 100%;
  height: 100%;
  background-color: ${lightGrey};
`;

export const Content = styled.ScrollView`
  flex: 0 1;
  flex-basis: 100%;
  height: 100%;
  padding-bottom: 100px;
  padding-top: 24px;
  background-color: ${lightGrey};
`;

export const HeaderButtonContainer = styled.View`
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  padding: 8px 24px;
  background-color: ${white};
  ${getShadowStyles()}
`;
