import React, { useState, useEffect } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { StackNavigationProp } from '../../../types/navigation';
import { PruText, PruInput, PruButton, colors } from '@ebsme/pulse-rn-components';
import Screen from '../../../components/Screen';
import { ButtonContainer } from './OnboardingForgetScreenStyles';
import { ActionCallback } from '../../../types/utils';
import * as routes from '../../../routes';

interface Props {
  navigation: StackNavigationProp;
  forgetCredentials: (input: string, type: string, callback: ActionCallback) => void;
  setSDKLoading: (loading: boolean) => void;
  requestingForgetCredentials: boolean;
  requestForgetError?: boolean;
}
const OnboardingForgetScreen: React.FC<Props> = ({
  navigation,
  forgetCredentials,
  setSDKLoading,
  requestingForgetCredentials
}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [type, setType] = useState('EMAIL');
  const [validValue, setValidValue] = useState(false);
  const [error, setError] = useState(false);

  const handleForget = (): void => {
    setError(false);
    forgetCredentials(inputValue, type, (success) => {
      if (success) {
        // navigation.navigate(routes.OnboardingForgetConfirm, {
        //   input: inputValue,
        //   type: type
        // });
        navigation.goBack();
      } else {
        // setError(true);
        navigation.goBack();
      }
    });
  };

  useEffect(() => {
    if ('EMAIL' === type) {
      setValidValue(/^\S+@\S+\.\S+$/.test(inputValue));
    } else {
      return setValidValue(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(inputValue));
    }
  }, [inputValue]);

  useEffect(() => {
    setSDKLoading(requestingForgetCredentials);
  }, [setSDKLoading, requestingForgetCredentials]);

  return (
    <Screen
      heading={t('FORGOT_CREDENTIALS')}
      subHeading={t('FORGOT_CREDENTIALS_DES')}
      headerPaddingHorizontal={24}
      onPressBack={(): void => {
        navigation.goBack();
      }}
    >
      <View style={{ paddingHorizontal: 24 }}>
        <View style={{ flexDirection: 'row', height: 36 }}>
          <TouchableOpacity
            testID="ForgetCredentialEmailTab"
            style={{ flex: 1 }}
            onPress={() => {
              if ('PHONE' === type) {
                setType('EMAIL');
                setInputValue('');
                setError(false);
              }
            }}
          >
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
              <PruText size={16}>{t('FORGOT_CREDENTIALS_EMAIL')}</PruText>
              <View
                style={{ height: 4, width: '70%', backgroundColor: type === 'EMAIL' ? 'red' : 'transparent' }}
              ></View>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            testID="ForgetCredentialPhoneTab"
            style={{ flex: 1 }}
            onPress={() => {
              if ('EMAIL' === type) {
                setType('PHONE');
                setInputValue('');
                setError(false);
              }
            }}
          >
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
              <PruText size={16}>{t('FORGOT_CREDENTIALS_PHONE')}</PruText>
              <View
                style={{ height: 4, width: '70%', backgroundColor: type === 'EMAIL' ? 'transparent' : 'red' }}
              ></View>
            </View>
          </TouchableOpacity>
        </View>
        <PruInput
          testID="forgetInput"
          value={inputValue}
          onChangeText={(text) => {
            setInputValue(text);
          }}
          label={type === 'EMAIL' ? `${t('FORGOT_CREDENTIALS_EMAIL_INPUT')}` : `${t('FORGOT_CREDENTIALS_PHONE_INPUT')}`}
          errorMessage={
            error ? t('FORGOT_CREDENTIALS_ERROR', { type: type === 'EMAIL' ? 'email address' : 'phone number' }) : ''
          }
          keyboardType={type === 'EMAIL' ? 'email-address' : 'numeric'}
          autoCapitalize="none"
          containerStyle={{ marginTop: 20 }}
        />
        <ButtonContainer>
          <PruButton
            testID="ForgetButton"
            onPress={handleForget}
            disabled={!validValue || requestingForgetCredentials}
            style={!validValue ? { backgroundColor: colors.grey, borderColor: colors.grey } : {}}
          >
            {t('FORGOT_CREDENTIALS_BUTTON')}
          </PruButton>
        </ButtonContainer>
      </View>
    </Screen>
  );
};

export default OnboardingForgetScreen;
