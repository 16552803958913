import React from 'react';
import { Text, View, Image } from 'react-native';
import ECardLineItem from '../ECardLineItem';
import { ECard } from '../../../types/ecard';

export interface Props {
  data: ECard;
}

const ECardFront: React.FC<Props> = ({ data }) => {
  const { company, policy, name, effectiveDate, benefit } = data;
  return (
    <View
      testID="ECardFront"
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: 12,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#b9b9b9'
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 10
        }}
      >
        <Image
          style={{
            width: 150,
            height: 24
          }}
          source={require('../../../assets/images/pru_logo.png')}
        />
      </View>
      <ECardLineItem label="Company" value={company} />
      <ECardLineItem label="Policy" value={policy} />
      <ECardLineItem label="Name" value={name} />
      <ECardLineItem label="Effective Date" value={effectiveDate} />
      <ECardLineItem label="Benefit" value={benefit} />
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 10
        }}
      >
        <Text
          style={{
            fontSize: 12,
            marginRight: 20
          }}
        >
          Powered by
        </Text>

        <Image
          style={{
            width: 30,
            height: 30
          }}
          source={require('../../../assets/images/mhc_logo.png')}
        />
      </View>
    </View>
  );
};

export default ECardFront;
