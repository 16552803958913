import React from 'react';
import { ViewProps } from 'react-native';
import { PruText, PruCard, PruButton } from '@ebsme/pulse-rn-components';
import { Warning, InformationCircleOutline } from '../../assets/svgs/icons';

export const Level = {
  info: 'info',
  warning: 'warning'
};

export type LevelType = keyof typeof Level;

interface Props extends ViewProps {
  level?: LevelType;
  title?: string;
  message?: string;
  buttonText?: string;
  buttonOnPress?: () => void;
  testID?: string;
}
const IconMap = new Map<string, React.FC>([
  [Level.info, InformationCircleOutline],
  [Level.warning, Warning]
]);

const MessageCard: React.FC<Props> = ({ title, message, testID, style, level, buttonText, buttonOnPress }) => {
  const MessageIcon = IconMap.get(level || Level.info);

  return (
    <PruCard
      testID={testID}
      style={[{ alignItems: 'center', minWidth: 200, paddingTop: 24, paddingBottom: 24 }, style]}
    >
      {/* 
      // @ts-ignore*/}
      <MessageIcon width="24" height="24" />

      {!!title && (
        <PruText testID="InfoCard__Title" marginTop={16} size={16} weight="bold" color="black">
          {title}
        </PruText>
      )}
      {!!message && (
        <PruText testID="InfoCard__Message" marginTop={16} color="black" align="center">
          {message}
        </PruText>
      )}
      {!!buttonText && buttonOnPress && (
        <PruButton style={{ marginVertical: 24, zIndex: 100 }} onPress={buttonOnPress}>
          {buttonText}
        </PruButton>
      )}
    </PruCard>
  );
};

export default MessageCard;
