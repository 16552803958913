import React, { useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import EbSmeApp from '../app/EbSmeApp';
import { OTPTYPE } from '../app/types/user';
import useCachedResources from './useCachedResources';
import Config from '../app/components/Config';

const WebContainer: React.FC = (props) => {
  if (Platform.OS === 'web') {
    return (
      <View style={{ flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
        <View style={{ maxWidth: 480, flex: 1 }}>{props.children}</View>
      </View>
    );
  }
  return <>{props.children}</>;
};

export default function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${Config.EB_SME_SDK_GOOGLE_MAP_KEY}&language=en&region=EN&libraries=places`;
    document.head.append(script);
  }, []);
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  }
  return (
    <WebContainer>
      <EbSmeApp
        locale="th"
        userProfile={{
          id: 'pusleUserId',
          device: 'device',
          phone: 'phone',
          nationalId: 'nationalId',
          email: 'email',
          firstName: 'firstName',
          surName: 'surName',
          countryCode: 'TH',
          dob: 'dob',
          gender: 'gender',
          otpType: OTPTYPE.EMAIL,
          loginTimestamp: -1,
          deviceId: 'deviceId',
          appBundle: 'web.pusle'
        }}
        onException={(e) => {
          // crashlytics().recordError(e);
        }}
        back2Pulse={() => {}}
        goToWithParams={(params) => {}}
      />
    </WebContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
