import { Action, Dispatch } from 'redux';

import { Reward } from './type';
import { fetchRewards } from './api';
import { RootState } from '../../store';
import { isEmpty, last } from 'ramda';
import { getEmployeeId } from '../SessionReducer/selectors';

export const FETCH_REWARD_START = 'Reward/FETCH_REWARD_START';
export const FETCH_REWARD_SUCCESS = 'Reward/FETCH_REWARD_SUCCESS';
export const FETCH_REWARD_FAILED = 'Reward/FETCH_REWARD_FAILED';

interface FetchRewardStart extends Action {
  type: typeof FETCH_REWARD_START;
}

interface FetchRewardSuccess extends Action {
  type: typeof FETCH_REWARD_SUCCESS;
  payload?: Reward;
}

interface FetchRewardFailed extends Action {
  type: typeof FETCH_REWARD_FAILED;
  payload: string;
}

export const fetchRewardStart = (): FetchRewardStart => ({ type: FETCH_REWARD_START });

export const fetchRewardSuccess = (reward?: Reward): FetchRewardSuccess => ({
  type: FETCH_REWARD_SUCCESS,
  payload: reward
});

export const fetchRewardFailed = (fetchError: string): FetchRewardFailed => ({
  type: FETCH_REWARD_FAILED,
  payload: fetchError
});

export type RewardAction = FetchRewardStart | FetchRewardSuccess | FetchRewardFailed;

export const fetchReward = () => async (dispatch: Dispatch<RewardAction>, getState: () => RootState): Promise<void> => {
  dispatch(fetchRewardStart());

  const employeeId = getEmployeeId(getState()) ?? '';

  if (isEmpty(employeeId)) {
    return;
  }
  try {
    const {
      data: { getCampaignStartedEvents }
    } = await fetchRewards(employeeId);

    dispatch(fetchRewardSuccess(last(getCampaignStartedEvents)));
  } catch (e) {
    dispatch(fetchRewardFailed(e.message));
  }
};
