import React from 'react';
import SvgAnimatedLinearGradient from 'react-native-svg-animated-linear-gradient';
import { Rect } from 'react-native-svg';

import { PruCard } from '@ebsme/pulse-rn-components';

const ClinicListItemLoader: React.FC = () => (
  <PruCard style={{ marginHorizontal: 18, marginBottom: 18, marginTop: 6 }}>
    <SvgAnimatedLinearGradient width={400} height={90} duration={1000}>
      <Rect x="1" y="1" rx="4" ry="4" width="240" height="8" />
      <Rect x="260" y="1" rx="4" ry="4" width="80" height="8" />
      <Rect x="1" y="28" rx="7" ry="7" width="300" height="14" />
      <Rect x="1" y="60" rx="15" ry="15" width="30" height="30" />
    </SvgAnimatedLinearGradient>
  </PruCard>
);

export default ClinicListItemLoader;
