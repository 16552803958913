import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import Pdf from '../../../components/Pdf';

import { StackNavigationProp } from '../../../types/navigation';
import { PruText } from '@ebsme/pulse-rn-components';
import Screen from '../../../components/Screen';

import { DownloadFileProps, downloadPDFFile } from '../../../utils/downloadFile';
import { useTranslation } from 'react-i18next';
import { sharePdf } from '../../../utils/shareUtil';
import { ShareSocialOutline } from '../../../assets/svgs/icons';
import IconListTile from '../../../components/IconListTile';
import { Alert } from 'react-native';
import { View } from 'react-native';

interface NavigationProps extends StackNavigationProp {
  state: {
    params: {
      uri: string;
      enableShare: boolean;
    };
  };
}

type Props = {
  navigation: NavigationProps;
};

const PDFViewScreen: React.FC<Props> = ({ navigation }) => {
  const styles = StyleSheet.create({
    pdf: {
      flex: 1
    }
  });

  const [fetchPDFData, setFetchPDFData] = useState<DownloadFileProps>({});
  const { uri: pdfUrlData, enableShare } = navigation.state?.params || {};
  const { t } = useTranslation();
  useEffect(() => {
    downloadPDFFile(pdfUrlData)
      .then((data) => setFetchPDFData(data))
      .catch((e) => setFetchPDFData({ error: e }));
  }, [pdfUrlData]);

  return (
    <Screen
      headingPosition="none"
      containerType="View"
      onPressBack={(): void => {
        navigation.goBack();
      }}
    >
      {enableShare && (
        <View style={{ paddingHorizontal: 30 }}>
          <IconListTile
            showArrow={false}
            title="Download"
            icon={<ShareSocialOutline width="25" height="25" style={{ marginRight: 24 }} />}
            onPress={(): void => {
              if (pdfUrlData) {
                sharePdf(pdfUrlData, `Rewards`);
              } else {
                Alert.alert('Rewards PDF is not available.');
              }
            }}
          />
        </View>
      )}
      {!fetchPDFData.error && !fetchPDFData.data && (
        <PruText testID="FetchLoading" style={{ margin: 24 }} size={12} weight="semiBold" color="darkRed">
          {t('REWARDS_PDF__FETCHING')}
        </PruText>
      )}
      {fetchPDFData.error && (
        <PruText testID="FetchError" style={{ margin: 24 }} size={12} weight="semiBold" color="darkRed">
          {fetchPDFData.error}
        </PruText>
      )}
      {fetchPDFData.data && (
        <Pdf
          source={{
            uri: fetchPDFData.data
          }}
          onError={(error): void => {
            setFetchPDFData({ error: JSON.stringify(error) });
          }}
          style={styles.pdf}
        />
      )}
    </Screen>
  );
};

export default PDFViewScreen;
