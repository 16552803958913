import React, { ReactElement } from 'react';
import { Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { ClaimDocumentGroup as ClaimDocumentGroupType, ClaimDocument as ClaimDocumentType } from '../../../types/claim';
import ClaimDocument from '../ClaimDocument';

interface Props {
  document: ClaimDocumentGroupType;
  onDocumentView?: (document: ClaimDocumentType) => void;
}

export const renderClaimDocuments = (
  documents: ClaimDocumentType[],
  onDocumentView?: (document: ClaimDocumentType) => void
): ReactElement[] => {
  return documents.map((doc: ClaimDocumentType, index: number) => (
    <ClaimDocument key={index} document={doc} onDocumentView={onDocumentView} />
  ));
};
const ClaimDocumentGroup: React.FC<Props> = ({ document, onDocumentView }) => {
  const { t } = useTranslation();

  const { title, documents } = document;
  return (
    <View
      testID={title}
      style={{
        marginTop: 5,
        marginBottom: 10
      }}
    >
      <Text
        style={{
          fontSize: 15,
          fontWeight: 'bold',
          marginBottom: 10
        }}
      >
        {t(title)}
      </Text>
      <>{renderClaimDocuments(documents, onDocumentView)}</>
    </View>
  );
};

export default ClaimDocumentGroup;
