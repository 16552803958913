import React from 'react';
import { connect } from 'react-redux';

import { UserCountryCode } from '../../types/user';
import { RootState } from '../../store';
import { getUserCountryCode } from '../../reducers/PulseUserReducer/selectors';

type ReactComponent<P> = React.ComponentType<P> | React.FC<P>;

type Config<P> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in UserCountryCode]: any;
};

type StateToProps<Payload> = (state: RootState) => Payload;

interface DispatchToProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface WrappedComponentProps {
  lbu: UserCountryCode;
}

// Props that the child component needs to have passed by the parent, not from redux store
type OuterProps<P, InjectedProps> = Omit<Omit<P, keyof WrappedComponentProps>, keyof InjectedProps>;

export default function withLBUSwitcher<P, Payload = {}>(
  configOrComponent: Config<P> | ReactComponent<P>,
  stateToProps?: StateToProps<Payload>,
  actionDispatcher?: DispatchToProps
): React.ComponentType<OuterProps<P, ReturnType<StateToProps<Payload>>>> {
  const LBUComponentSwitcher: React.FC<WrappedComponentProps & P> = ({ lbu, ...props }) => {
    const Component = typeof configOrComponent === 'function' ? configOrComponent : configOrComponent[lbu];

    return React.createElement(Component, { lbu, ...props } as P);
  };

  //@ts-ignore
  return connect((state: RootState) => {
    return {
      lbu: getUserCountryCode(state),
      ...(stateToProps ? stateToProps(state) : {})
    };
  }, actionDispatcher)(
    // @ts-ignore
    LBUComponentSwitcher
  ) as React.ComponentType<OuterProps<P, ReturnType<StateToProps<Payload>>>>;
}
