import {
  SDKAction,
  SET_SDK_LOADING,
  SET_I18N_READY,
  SET_SDK_ERROR,
  SET_SDK_EXCEPTION,
  SET_SDK_LOCALE
} from './actions';

export type { SDKAction };
import { Locale } from './../../types/sdk';

export type SDKState = {
  i18nReady: boolean;
  sdkLoading: boolean;
  sdkError: string;
  sdkException: Error | null;
  locale: Locale;
};

export const initialState: SDKState = {
  i18nReady: false,
  sdkLoading: false,
  sdkError: '',
  sdkException: null,
  locale: 'th'
};

const SDKReducer = (state: SDKState = initialState, action: SDKAction): SDKState => {
  switch (action.type) {
    case SET_I18N_READY:
      return {
        ...state,
        i18nReady: action.payload
      };

    case SET_SDK_LOADING:
      return {
        ...state,
        sdkLoading: action.payload
      };
    case SET_SDK_ERROR:
      return {
        ...state,
        sdkError: action.payload
      };
    case SET_SDK_EXCEPTION:
      return {
        ...state,
        sdkException: action.payload
      };
    case SET_SDK_LOCALE:
      return {
        ...state,
        locale: action.payload
      };
    default:
      return state;
  }
};

export default SDKReducer;
