import gql from 'graphql-tag';

import apiClient from '../../utils/apiClient';
import { Clinic, ClinicNearBy } from '../../types/clinic';
import { getValue } from '../../utils/object';
import { LBU } from '../../config';

export interface ClinicsRequest {
  location: {
    lat: string;
    lng: string;
  };
  certificateNo: string | undefined;
  pageNumber: string;
  pageSize: string;
}

interface ClinicsResponse {
  data: {
    getClinicNearBy: ClinicNearBy;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchClinicsApi = (
  getClinicNearByPayload: ClinicsRequest,
  countryCode: string
): Promise<ClinicsResponse> =>
  apiClient.query({
    query: getValue(LBU, [countryCode, 'GRAPHQL', 'getClinicNearBy']),
    variables: {
      getClinicNearByPayload
    }
  });
