import { UNBOOKMARK_CLINIC, BOOKMARK_CLINIC, PreferencesAction, SET_CURRENT_LOCATION } from './actions';
import { Clinic } from '../../types/clinic';
import { Coordinate } from '../../types/coordinate';

export type { PreferencesAction };

type PreferencesState = {
  bookmarkedClinics: Clinic[];
  currentLocation?: Coordinate;
};

export const initialState: PreferencesState = {
  bookmarkedClinics: [],
  currentLocation: undefined
};

const PreferencesReducer = (state: PreferencesState = initialState, action: PreferencesAction): PreferencesState => {
  switch (action.type) {
    case BOOKMARK_CLINIC: {
      const removeExistedIfAny = state.bookmarkedClinics.filter((item) => item.code !== action.payload.code);
      return {
        ...state,
        bookmarkedClinics: [{ ...action.payload, bookmarked: true }, ...removeExistedIfAny]
      };
    }

    case UNBOOKMARK_CLINIC:
      return {
        ...state,
        bookmarkedClinics: state.bookmarkedClinics.filter((item) => item.code !== action.payload.code)
      };

    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload
      };

    default:
      return state;
  }
};

export default PreferencesReducer;
