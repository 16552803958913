import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ImageBackground, ImageResizeMode, ViewProps } from 'react-native';
import { Icon } from 'react-native-elements';

import { fetchDocBase64 } from '../../reducers/ReviewClaimReducer/api';
import { getFormattedBase64FileSize } from '../../utils/file';
import { ClaimDocument } from '../../types/claim';
import { compressFile, readFileFromCacheDir, writeFileToCacheDir } from '../../utils/cacheImageFiles';
import { red } from '../../assets/colors';

interface Props extends ViewProps {
  doc: ClaimDocument;
  policyId: string;
  onLoadedSizeImage?: (size: string) => void;
  compressed?: boolean;
  resizeMode?: ImageResizeMode;
}

const DocumentImageView: React.FC<Props> = ({
  doc: { fileBlobURL: filePath, fileType },
  policyId,
  onLoadedSizeImage,
  compressed,
  ...props
}) => {
  const [fileContent, setFileContent] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (!filePath) {
        setFileContent(undefined);
        return;
      }

      try {
        // if has cached data
        let base64Data = await readFileFromCacheDir(filePath);
        if (!base64Data) {
          //if don't have cached data fetch from api
          base64Data = await fetchDocBase64({ policyId, filePath });
          await writeFileToCacheDir({ base64Data: base64Data, filePath });
        }
        const compressedFile = await compressFile(base64Data, filePath, compressed);
        setFileContent(compressedFile);
        if (onLoadedSizeImage) {
          onLoadedSizeImage(getFormattedBase64FileSize(base64Data));
        }
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [compressed, filePath, onLoadedSizeImage, policyId]);

  return !error ? (
    fileContent ? (
      <ImageBackground {...props} resizeMode="contain" source={{ uri: `data:${fileType};base64,${fileContent}` }} />
    ) : (
      <ActivityIndicator {...props} size="small" color={red} />
    )
  ) : (
    <Icon name="file-image-o" type="font-awesome" size={20} color="#D3DADD" />
  );
};

export default DocumentImageView;
