import React, { ReactElement, useState } from 'react';
import { Divider } from 'react-native-elements';
import { useTranslation } from 'react-i18next';
import { path } from 'ramda';
import { PulseLogoGrey, PRULifeUK, PulseTHLogo, PrudentialLogo, PulsePMLILogo } from '../../../assets/svgs/brand';
import { openURL } from '../../../utils/platform';
import { getSupportInfo } from '../../../utils/claim';
import { BackgroundContainer, Content, ContentContainer, LogoContainer, ScreenContainer, LanguageContainer, LocaleDropdownItem, LanguageButton } from './OnboardingViewStyles';
import Header from '../../../components/Header';
import ActionableText from '../../../components/ActionableText';
import { UserCountryCode } from '../../../types/user';
import { PruText } from '@ebsme/pulse-rn-components';
import { Locale } from '../../../types/sdk';
import Icon from 'react-native-vector-icons/MaterialIcons';

export interface Props {
  lbu: UserCountryCode;
  background: React.ReactNode;
  children: React.ReactNode;
  headerVisible?: boolean;
  onPressBack?: () => void;
  back2Home?: boolean;
  showLanguge?: boolean;
  updateLocale?: (locale: Locale) => void;
}

const lbuLogo = (lbu: UserCountryCode): ReactElement | undefined => {
  const LOGO_CONFIG = {
    TH: <PulseTHLogo />,
    PH: <PRULifeUK />,
    SG: <PrudentialLogo />,
    MY: <PrudentialLogo />,
    MM: <PrudentialLogo />
  };
  return path([lbu], LOGO_CONFIG);
};

const OnboardingView: React.FC<Props> = ({ lbu, background, children, onPressBack, headerVisible = true, back2Home = false, showLanguge = false, updateLocale }) => {
  const { t } = useTranslation();
  const SUPPORT_INFO = getSupportInfo(lbu);
  const [showLangDrop, setShowLangDrop] = useState(false);
  return (
    <ScreenContainer>
      <ContentContainer showsVerticalScrollIndicator={true}>
        {headerVisible ? <Header onPressBack={onPressBack} backText={back2Home ? t('BUTTON__BACK_TO_HOME') : undefined} /> : null}
        <Content>
          {showLanguge && 
            <LanguageButton onPress={() => setShowLangDrop(true)}>
              <Icon name="language" size={20} color="red" />
            </LanguageButton>
          }
          {
            showLangDrop &&
            <LanguageContainer>
              <LocaleDropdownItem underlayColor="#fff" onPress={() => {
                  updateLocale('en');
                  setShowLangDrop(false);
              }}>
                <PruText>English</PruText>
              </LocaleDropdownItem>
              <LocaleDropdownItem underlayColor="#fff" onPress={() => {
                  updateLocale('th');
                  setShowLangDrop(false);
              }}>
                <PruText>ภาษาไทย</PruText>
              </LocaleDropdownItem>
            </LanguageContainer>
          }
          <LogoContainer>
            <PulseLogoGrey />
          </LogoContainer>
          <LogoContainer>{lbuLogo(lbu)}</LogoContainer>
          {children}
        </Content>
        <Divider style={{ backgroundColor: '#D3DADD', marginTop: 20, marginLeft: 50, marginRight: 50 }} />
        <ActionableText
          testID="contactSupport"
          showArrow={false}
          color="darkGrey"
          style={{
            justifyContent: 'center',
            marginTop: 20,
            marginBottom: 20
          }}
          onPress={(): void => {
            openURL(SUPPORT_INFO.contactSupport);
          }}
        >
          {t('ONBOARDING__CONTACT_SUPPORT')}
        </ActionableText>
        <BackgroundContainer testID="OnboardingView__Background">{background}</BackgroundContainer>
      </ContentContainer>
    </ScreenContainer>
  );
};

export default OnboardingView;
