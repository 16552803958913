import { RootState } from '../../../store';
import ClaimTypeScreen from './ClaimTypeScreen';
import { updateClaim } from '../../../reducers/MakeClaimReducer/actions';
import { getLoading, getClaim } from '../../../reducers/MakeClaimReducer/selectors';
import { getPolicy } from '../../../reducers/MyPolicyReducer/selectors';
import withLBUSwitcher from '../../../hocs/withLBUSwitcher';

const LBU_CONFIG = {
  SG: ClaimTypeScreen,
  TH: ClaimTypeScreen,
  PH: ClaimTypeScreen,
  MY: null
};

export default withLBUSwitcher(
  LBU_CONFIG,
  (state: RootState) => {
    return {
      loading: getLoading(state),
      policy: getPolicy(state),
      claim: getClaim(state)
    };
  },
  { updateClaim }
);
