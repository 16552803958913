import React from 'react';
import { TouchableHighlight, ViewStyle } from 'react-native';

import { lightGrey } from '../../assets/colors';

import { PruText } from '@ebsme/pulse-rn-components';

interface Props {
  children: React.ReactNode;
  onPress?: () => void;
  style?: ViewStyle;
  testID?: string;
}

const LinkText: React.FC<Props> = ({ children, onPress, style, testID }) => {
  return (
    <TouchableHighlight
      accessible
      accessibilityRole="button"
      onPress={onPress}
      disabled={!onPress}
      underlayColor={lightGrey}
      style={style}
    >
      <PruText testID={testID} weight="bold" color="blue" underline>
        {children}
      </PruText>
    </TouchableHighlight>
  );
};

export default LinkText;
