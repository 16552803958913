import { Diagnosis } from './diagnosis';
import { Hospital } from './hospital';
import { Patient } from './patient';

export enum LogRequestStatus {
  DRAFT = 0,
  PENDING_REVIEW = 1,
  APPROVED = 2,
  REJECTED = 3
}

export interface LogQuestion {
  key: string;
  answer: boolean;
}

export interface LogRequest {
  ref: string;
  status: LogRequestStatus;
  statusText: string;
  downloadUrl?: string;
  rejectedReason?: string;
  patient: Patient;
  hospital: Hospital;
  diagnosis: Diagnosis;
  symptomDate: string;
  symptomDescription: string;
  admissionDate: string;
  submissionDate: string;
  questions: LogQuestion[];
}
