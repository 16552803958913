import React, { useState } from 'react';
import { View, TouchableOpacity, ViewStyle, Text } from 'react-native';
import PruText, { TextColor } from '@ebsme/pulse-rn-components/PruText';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import DatePicker from './DatePickerBase';
import { Button } from 'react-native-elements';
import Modal from 'react-native-modal';
import { PruErrorMessage } from '@ebsme/pulse-rn-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface DatePickerProps {
  onSelectDate: (date: Date, origin: string) => void;
  origin: string;
  label: string;
  style?: ViewStyle;
  color?: TextColor;
  readOnly?: boolean;
  value?: Date | null;
  errorMessage?: string;
  currentDateError?: string;
}

const DatePickerComponent: React.FC<DatePickerProps> = ({
  onSelectDate,
  origin = '',
  label = '',
  style,
  readOnly,
  value,
  errorMessage,
  currentDateError
}) => {
  const [visitDate, setVisitDate] = useState(new Date());
  const [visitDateOverlay, setVisitDateOverlay] = useState(false);

  const { t } = useTranslation();
  return (
    <View style={[style]}>
      <PruText testID={origin}>{label}</PruText>
      <TouchableOpacity
        disabled={readOnly}
        onPress={() => {
          setVisitDateOverlay(true);
        }}
        style={{
          flexDirection: 'row',
          borderColor: '#68737A',
          borderWidth: 0.5,
          padding: 10,
          borderRadius: 5,
          marginVertical: 5
        }}
      >
        <Text testID={`${origin}_Value`} style={{ flex: 1, color: 'grey' }}>
          {value ? moment(visitDate).format('YYYY-MM-DD') : `--${t('CLAIMS__CLAIM_BANK_SELECT_LABEL')}--`}
        </Text>
        <FontAwesomeIcon name="calendar" size={20} color={readOnly ? 'grey' : 'red'} />
      </TouchableOpacity>
      {currentDateError === origin && <PruErrorMessage errorMessage={errorMessage} testID={`${origin}_error`} />}
      <Modal
        isVisible={visitDateOverlay}
        backdropTransitionOutTiming={0}
        style={{ margin: 0, justifyContent: 'flex-end' }}
        useNativeDriver
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            backgroundColor: 'white'
          }}
        >
          <DatePicker mode="date" date={visitDate} onDateChange={setVisitDate} maximumDate={new Date()} />
          <Button
            title="Done"
            containerStyle={{
              alignSelf: 'center',
              minWidth: 100,
              margin: 0,
              marginStart: 0,
              marginEnd: 0,
              marginTop: 20,
              marginBottom: 10
            }}
            titleStyle={{
              color: '#FFFFFF',
              fontSize: 15
            }}
            buttonStyle={{
              padding: 10,
              backgroundColor: '#ED1B2E',
              borderRadius: 30
            }}
            onPress={(): void => {
              setVisitDateOverlay(false);
              onSelectDate(visitDate, origin);
            }}
          />
        </View>
      </Modal>
    </View>
  );
};

export default DatePickerComponent;
