import { connect } from 'react-redux';

import { RootState } from '../../../store';

import OnboardingForgetScreen from './OnboardingForgetScreen';
import withSDKLoader from '../../../hocs/withSDKLoader/withSDKLoader';

import { forgetCredentials } from '../../../reducers/CorporateUserReducer/actions';
import {
  getRequestingForgetCredentials,
  getRequestForgetError
} from '../../../reducers/CorporateUserReducer/selectors';

const OnboardingForgetScreenContainer = connect(
  (state: RootState) => ({
    requestingForgetCredentials: getRequestingForgetCredentials(state),
    requestForgetError: getRequestForgetError(state)
  }),
  { forgetCredentials }
)(withSDKLoader(OnboardingForgetScreen));

export default OnboardingForgetScreenContainer;
