import React from 'react';
// import { View } from 'react-native';
import { Background, Bar } from './ProgressBarStyles';

interface Props {
  steps: number;
  currentStep: number;
}

const ProgressBar: React.FC<Props> = ({ steps, currentStep }) => {
  // const pColor = currentStep < steps ? '#ED1B2E' : '#36B37E';
  return (
    <Background>
      <Bar testID="ProgressBar__Bar" steps={steps} currentStep={currentStep} />
    </Background>
    // <View
    //   testID="ProgressBar__Bar"
    //   style={{
    //     display: 'flex',
    //     flexDirection: 'row',
    //     justifyContent: 'center',
    //     alignContent: 'center',
    //     alignItems: 'center',
    //     backgroundColor: '#ffffff',
    //     borderWidth: 1,
    //     borderRadius: 5,
    //     height: 70,
    //     padding: 20,
    //     paddingBottom: 10,
    //     paddingTop: 30,
    //     margin: 20,
    //     marginTop: 20,
    //     marginBottom: 20,
    //     borderColor: '#ffffff',
    //     elevation: 10,
    //     shadowOpacity: 0.1,
    //     shadowRadius: 15,
    //     shadowColor: '#ccc',
    //     shadowOffset: {
    //       width: 0,
    //       height: 9
    //     }
    //   }}
    // >
    //   {[...Array(steps).keys()].map((i) => (
    //     <View
    //       key={i}
    //       style={{
    //         flex: 1,
    //         height: 5,
    //         margin: 10,
    //         backgroundColor: '#DDDDDD',
    //         borderRadius: 3
    //       }}
    //     >
    //       {i < currentStep ? (
    //         <View
    //           style={{
    //             width: `${i === -1 ? '50' : '100'}%`,
    //             height: '100%',
    //             backgroundColor: pColor,
    //             borderRadius: 5
    //           }}
    //         />
    //       ) : null}
    //     </View>
    //   ))}
    // </View>
  );
};

export default ProgressBar;
