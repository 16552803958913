import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from '../../routes';

import RequestLogScreen from './RequestLogScreen';
import LogHistoryScreenContainer from './LogHistoryScreenContainer';
import LogDetailsScreen from './LogDetailsScreen';
import RequestLogSuccessScreen from './RequestLogSuccessScreen';
import RequestLogDraftSuccessScreen from './RequestLogDraftSuccessScreen';
import ViewPdfScreen from '../Common/ViewPdfScreen';

const routeConfigMap = {
  [routes.RequestLog]: RequestLogScreen,
  [routes.LogHistory]: LogHistoryScreenContainer,
  [routes.LogDetails]: LogDetailsScreen,
  [routes.ViewPdfLog]: ViewPdfScreen,
  [routes.RequestLogSuccess]: RequestLogSuccessScreen,
  [routes.RequestLogDraftSuccess]: RequestLogDraftSuccessScreen
};

const LetterOfGuaranteeNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.RequestLog,
    headerMode: 'none'
  })
);

export default LetterOfGuaranteeNavigator;
