import React, { MutableRefObject } from 'react';
import { ScrollView, View } from 'react-native';
import Header from '../Header';
import { BackgroundColor as HeaderColor } from '../Header/HeaderStyles';
import { PruText } from '@ebsme/pulse-rn-components';
import LayoutBox from '../LayoutBox';
import { LayoutBoxProps, LayoutBoxType } from '../LayoutBox/LayoutBox';

interface Props {
  heading?: React.ReactNode;
  headingPosition?: 'header' | 'body' | 'none';
  headingColor?: HeaderColor;
  containerType?: LayoutBoxType;
  containerProps?: LayoutBoxProps;
  subHeading?: React.ReactNode;
  paddingHorizontal?: number;
  headerPaddingHorizontal?: number;
  children: React.ReactNode;
  onPressBack?: () => void;
  onPressCancel?: () => void;
  EndComponent?: React.ReactNode;
  scrollRef?: MutableRefObject<ScrollView | undefined>;
}

const Screen: React.FC<Props> = ({
  heading,
  headingPosition = 'body',
  headingColor = 'transparent',
  containerType = 'ScrollView',
  containerProps,
  subHeading,
  paddingHorizontal,
  headerPaddingHorizontal,
  children,
  onPressBack,
  onPressCancel,
  scrollRef,
  EndComponent
}) => {
  return (
    <LayoutBox scrollRef={scrollRef} type={containerType} backgroundColor="lightGrey" {...containerProps}>
      <Header
        title={headingPosition === 'header' ? (heading as string) : undefined}
        backgroundColor={headingColor}
        onPressBack={onPressBack}
        onPressCancel={onPressCancel}
        EndComponent={EndComponent}
      />
      <LayoutBox testID="Screen__Content" paddingHorizontal={paddingHorizontal} flex={1}>
        {headingPosition === 'body' && (
          <View style={{ marginBottom: 24, marginHorizontal: headerPaddingHorizontal }}>
            <PruText testID="Screen__BodyHeading" size={28} color="black" weight="bold">
              {heading}
            </PruText>
            {subHeading && (
              <PruText testID="Screen__BodySubHeading" color="black" marginTop={12}>
                {subHeading}
              </PruText>
            )}
          </View>
        )}

        {children}
      </LayoutBox>
    </LayoutBox>
  );
};

export default Screen;
