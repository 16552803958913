import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'react-native-vector-icons/Ionicons';

import { StackNavigationProp } from '../../../types/navigation';

import Screen from '../../../components/Screen';
import { PruInput } from '@ebsme/pulse-rn-components';
import FAQList from '../../../components/FAQList';
import ShadedContainer from '../../../components/ShadedContainer';

import { SearchContainer } from './ClaimsSupportFAQScreenStyles';
import { faqs } from './ClaimsSupportFAQData';
import { red } from '../../../assets/colors';

interface Props {
  navigation: StackNavigationProp;
}

const ClaimsSupportFAQScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Screen
      heading={t('CLAIMSSUPPORT__FAQ__TITLE')}
      headerPaddingHorizontal={24}
      onPressBack={(): void => {
        navigation.goBack();
      }}
    >
      <SearchContainer>
        <PruInput
          icon={<Icon name="ios-search" size={24} color={red} />}
          value={searchTerm}
          placeholder={t('CLAIMSSUPPORT__FAQ__SEARCH')}
          onChangeText={setSearchTerm}
        />
      </SearchContainer>
      <ShadedContainer style={{ padding: 24 }}>
        <FAQList faqs={faqs} searchTerm={searchTerm} />
      </ShadedContainer>
    </Screen>
  );
};

export default ClaimsSupportFAQScreen;
