import { Action } from 'redux';
import { Locale } from './../../types/sdk';

export const SET_I18N_READY = 'SDK/SET_I18N_READY';
export const SET_SDK_LOADING = 'SDK/SET_SDK_LOADING';
export const SET_SDK_ERROR = 'SDK/SET_SDK_ERROR';
export const SET_SDK_EXCEPTION = 'SDK/SET_SDK_EXCEPTION';
export const SET_SDK_LOCALE = 'SDK/SET_SDK_LOCALE';

interface SetI18nReady extends Action {
  type: typeof SET_I18N_READY;
  payload: boolean;
}

interface SetSDKLoading extends Action {
  type: typeof SET_SDK_LOADING;
  payload: boolean;
}

interface SetSDKError extends Action {
  type: typeof SET_SDK_ERROR;
  payload: string;
}

export interface SetSDKException extends Action {
  type: typeof SET_SDK_EXCEPTION;
  payload: Error;
}

interface SetSDKLocale extends Action {
  type: typeof SET_SDK_LOCALE;
  payload: Locale;
}

export type SDKAction = SetI18nReady | SetSDKLoading | SetSDKError | SetSDKException | SetSDKLocale;

export const setI18nReady = (i18nReady: boolean): SetI18nReady => ({
  type: SET_I18N_READY,
  payload: i18nReady
});

export const setSDKLoading = (loading: boolean): SetSDKLoading => ({
  type: SET_SDK_LOADING,
  payload: loading
});

export const setSDKError = (error: string): SetSDKError => ({
  type: SET_SDK_ERROR,
  payload: error
});

export const setSDKException = (error: Error): SetSDKException => ({
  type: SET_SDK_EXCEPTION,
  payload: error
});

export const setSDKLocale = (locale: Locale): SetSDKLocale => ({
  type: SET_SDK_LOCALE,
  payload: locale
});
