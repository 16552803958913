import React, { SVGProps } from 'react';
import { Image } from 'react-native';
import { lightGrey } from '../../assets/colors';

import { PruText } from '@ebsme/pulse-rn-components';
import { ArrowIcon, Card, Container, SvgContainer } from './SquareTileStyles';

import Icon from 'react-native-vector-icons/MaterialIcons';

import { tele } from '../../assets/images/icons';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SvgImage?: React.FC<SVGProps<any>>;
  title: string;
  description: string;
  onPress: () => void;
  isTeleconsultation?: boolean;
}

const SquareTile: React.FC<Props> = ({ SvgImage, title, description, onPress, isTeleconsultation }) => {
  return (
    <Container
      accessible
      accessibilityLabel={`${title}; ${description}`}
      accessibilityHint={description}
      accessibilityRole="button"
      onPress={onPress}
      underlayColor={lightGrey}
    >
      <Card>
        <SvgContainer>
          {
            SvgImage && <SvgImage height={70} />
          }
          {
            isTeleconsultation && <Image style={{width: 65, height: 70}} source={tele}/>
          }
        </SvgContainer>
        <PruText testID={title} accessibilityLabel={title} accessibilityRole="text" marginBottom={4} weight="bold">
          {title}
        </PruText>
        <PruText testID={description} accessibilityLabel={description} accessibilityRole="text" weight="thin">
          {description}
        </PruText>
        <ArrowIcon>
          <Icon name='navigate-next' size={20} color="white" />
        </ArrowIcon>
      </Card>
    </Container>
  );
};

export default SquareTile;
