import { getValue } from './../../utils/object';
import gql from 'graphql-tag';
import { APIPolicy, ECareCard } from '../../types/policy';
import { Dependant, HospitalAndReason, PulseUserConfig } from '../../types/claim';
import apiClient from '../../utils/apiClient';
import { UserCountryCode } from '../../types/user';
import { LBU } from '../../config';

interface PolicyResponse {
  data: {
    getPolicyDetailsForEmployee: APIPolicy[];
  };
}

interface AllEmployeeDetailsResponse {
  data: {
    getAllEmployeeDetails: APIPolicy[];
  };
}

interface ECareCardResponse {
  data: {
    getEcareCardImg: ECareCard[];
  };
}

export const fetchPolicy = async ({ countryCode }: { countryCode: UserCountryCode }): Promise<APIPolicy[]> => {
  const res: PolicyResponse = await apiClient.query({
    query: getValue(LBU, [countryCode, 'GRAPHQL', 'fetchPolicyQuery'])
  });

  const apiPolicy = res?.data?.getPolicyDetailsForEmployee;

  if (!apiPolicy) {
    throw new Error('No policy data found in API response');
  }
  return apiPolicy;
};

export const getAllEmployeeDetails = async (countryCode: UserCountryCode): Promise<APIPolicy[]> => {
  const res: AllEmployeeDetailsResponse = await apiClient.query({
    query: getValue(LBU, [countryCode, 'GRAPHQL', 'getAllEmployeeDetails'])
  });
  const apiPolicy = res?.data?.getAllEmployeeDetails;
  console.log('apiPolicy', JSON.stringify(apiPolicy));
  if (!apiPolicy) {
    throw new Error('No policy data found in API response');
  }
  return apiPolicy;
};

interface PulseUserConfigResponse {
  data: {
    getPulseUserConfig: PulseUserConfig;
  };
}

interface HospitalAndReasonResponse {
  data: {
    getHospitalOrReason: HospitalAndReason;
  };
}

export const fetchPulseUserConfig = (certificate: string): Promise<PulseUserConfigResponse> =>
  apiClient.query({
    query: gql`
      query getPulseUserConfig($certificateNo: String) {
        getPulseUserConfig(certificateNo: $certificateNo)
      }
    `,
    variables: {
      certificateNo: certificate
    }
  });

export const fetchHospitalOrReason = (
  certificate: string,
  reasonOfHospitalization: string
): Promise<HospitalAndReasonResponse> =>
  apiClient.query({
    query: gql`
      query getHospitalOrReason($certificateNo: String, $reasonOfHospitalization: String) {
        getHospitalOrReason(certificateNo: $certificateNo, reasonOfHospitalization: $reasonOfHospitalization)
      }
    `,
    variables: {
      certificateNo: certificate,
      reasonOfHospitalization: reasonOfHospitalization
    }
  });

interface PolicyDetailResponse {
  data: {
    getPolicyAndProducts: any;
  };
}

export const fetchPolicyAndProductsApi = async (
  policyNumber: string,
  countryCode: string
): Promise<PolicyDetailResponse> => {
  const res: PolicyDetailResponse = await apiClient.query({
    query: getValue(LBU, [countryCode, 'GRAPHQL', 'fetchPolicyAndProductsQuerry']),
    variables: {
      policyNumber
    }
  });
  return res;
};

export const fetchECareCardApi = (certificate: string, language: string): Promise<ECareCardResponse> => {
  if (language === 'THAI') {
    return apiClient.query({
      query: gql`
        {
          getEcareCardImg(certificate: "${certificate}", language:THAI){
            seq
            userName
            cardImagesBase64
          }
        }
        `
    });
  } else {
    return apiClient.query({
      query: gql`
        {
          getEcareCardImg(certificate: "${certificate}", language:ENG){
            seq
            userName
            cardImagesBase64
          }
        }
        `
    });
  }
};

interface DependantsResponse {
  data: {
    dependants: Dependant[];
  };
}

export const fetchDependantsApi = (id: string): Promise<DependantsResponse> =>
  apiClient.query({
    query: gql`
      query dependants($policyNumber: String) {
        dependants(policyNumber: $policyNumber) {
          id
          dateOfBirth
          givenName
          middleName
          gender
          familyName
          nationality
          passport
          nationalId
          dependantPolicyNumber
          address {
            address1
            address2
            city
            postCode
            region
            country
          }
          maritalStatus
          relationshipType
          relationshipToEmployee
        }
      }
    `,
    variables: {
      policyNumber: id
    }
  });
