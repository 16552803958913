import React from 'react';
import { View } from 'react-native';

import LogRequestListItemLoader from './LogRequestListItemLoader';

interface Props {
  testID?: string;
}

const LogRequestListLoader: React.FC<Props> = ({ testID }) => {
  return (
    <View testID={testID}>
      {Array(3)
        .fill(null)
        .map((_, index) => (
          <LogRequestListItemLoader key={index} />
        ))}
    </View>
  );
};

export default LogRequestListLoader;
