import {
  FETCH_LOG_REQUESTS_SUCCESS,
  FETCH_LOG_REQUESTS_FAILED,
  FETCH_LOG_REQUESTS_START,
  LetterOfGuaranteeAction
} from './actions';
import { LogRequest } from '../../types/logRequest';

export type { LetterOfGuaranteeAction };

type LetterOfGuaranteeState = {
  logRequests: LogRequest[];
  logRequestsFetchError?: string;
  logRequestsLoading: boolean;
};

export const initialState: LetterOfGuaranteeState = {
  logRequests: [],
  logRequestsFetchError: undefined,
  logRequestsLoading: false
};

const LetterOfGuaranteeReducer = (
  state: LetterOfGuaranteeState = initialState,
  action: LetterOfGuaranteeAction
): LetterOfGuaranteeState => {
  switch (action.type) {
    case FETCH_LOG_REQUESTS_START:
      return {
        ...state,
        logRequestsLoading: true,
        logRequestsFetchError: undefined
      };

    case FETCH_LOG_REQUESTS_SUCCESS:
      return {
        ...state,
        logRequests: action.payload,
        logRequestsLoading: false
      };
    case FETCH_LOG_REQUESTS_FAILED:
      return {
        ...state,
        logRequestsLoading: false,
        logRequestsFetchError: action.payload
      };

    default:
      return state;
  }
};

export default LetterOfGuaranteeReducer;
