import React from 'react';
import { useTranslation } from 'react-i18next';

import * as routes from '../../../routes';
import { StackNavigationProp } from '../../../types/navigation';
import ClaimNextStepView from '../../../components/Claim/ClaimNextStepView';
import MakeClaimView from '../MakeClaimView';
import { UserCountryCode } from '../../../types/user';

interface Props {
  navigation: StackNavigationProp<
    {},
    {
      claimNumber: string;
      documentsRequired: string[];
      lbu: UserCountryCode
    }
  >;
  onClose?: () => void;
}

const NextStepScreen: React.FC<Props> = ({ navigation, onClose }) => {
  const { t } = useTranslation();

  const claimNumber = navigation.getParam('claimNumber');
  const documentsRequired = navigation.getParam('documentsRequired');
  const lbu = navigation.getParam('lbu');

  return (
    <MakeClaimView
      headTitle={t('CLAIMS__NEXT_STEPS__TITLE')}
      title={t('CLAIMS__SUBMITTED__TITLE')}
      currentRoute={routes.NextStepScreen}
      nextDisabled={false}
      navigation={navigation}
    >
      <ClaimNextStepView claimNumber={claimNumber} onClose={onClose} documentsRequired={documentsRequired} lbu={lbu}/>
    </MakeClaimView>
  );
};

export default NextStepScreen;
