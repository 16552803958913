export const clearCacheDir = async (): Promise<void> => {
};

export const writeFileToCacheDir = async ({
  base64Data,
  filePath
}: {
  base64Data: string;
  filePath: string;
}): Promise<void> => {
};
export const getFilePath = (filePath: string) => {
  return dirPath + filePath;
};
export const readFileFromCacheDir = async (filePath: string): Promise<string | undefined> => {
  return undefined
};

export const compressFile = async (oriFile: string, filePath: string, compressed?: boolean): Promise<string> => {
  return oriFile
};
