import React, { useMemo } from 'react';
import { View } from 'react-native';
import { PruText } from '@ebsme/pulse-rn-components';
import LinearGradient from 'react-native-linear-gradient';
import { Card } from '../../ExpandableCard/ExpandableCardStyles';
import { BenefitItem } from '../MyPolicyRenderHelper/MyPolicyRenderHelper';
import { translateLabel } from '../../../utils/translate';
import { IconBenefitDental, IconBenefitOpd, IconBenefitLab } from '../../../assets/svgs/icons';

export interface Props {
  item: BenefitItem;
  locale: string;
}
const COLORS_HIGH = ['#4AE69A', '#18BCAC'];
const COLORS_MEDIUM = ['#FFC51C', '#FF8628'];
const COLORS_LOW = ['#FF8967', '#FF2236'];

const getColors = (p: number) => {
  if (p > 0.7) {
    return COLORS_HIGH;
  } else if (p <= 0.4) {
    return COLORS_LOW;
  } else return COLORS_MEDIUM;
};
const ItemRemainingBenefit = ({ item, locale }: Props) => {
  const IconBenefit = useMemo(() => {
    if (item.benefitType == 'OPD LAB') {
      return IconBenefitLab;
    } else if (item.benefitType === 'DENTAL') {
      return IconBenefitDental;
    } else {
      return IconBenefitOpd;
    }
  }, [item.benefitType]);
  const TEXT_BENEFIT_AMOUNT = `${item.coverageValue ? item.coverageValue : ''} ${item.valueUnit ? item.valueUnit : ''}`;
  const TEXT_BENEFIT_COVERAGE = `${translateLabel(item.leftStr, locale)} ${item.remainingValue} ${translateLabel(
    item.remainingUnit,
    locale
  )}`;
  const isZeroValuePercent = item.stackValue == '0';
  const percent = isZeroValuePercent
    ? 0
    : parseFloat(item.remainingValue.replace(/,/g, '')) / parseFloat(item.stackValue.replace(/,/g, '')) || 0;
  return (
    <Card>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <IconBenefit height={48} width={48} />
        <View style={{ marginLeft: 12, flex: 1 }}>
          <PruText style={{ color: '#20365A', fontWeight: '700', fontSize: 14, lineHeight: 25 }} numberOfLines={4}>
            {translateLabel(item.name, locale)}
          </PruText>
        </View>
      </View>
      <View style={{ paddingHorizontal: 10 }}>
        <PruText style={{ color: '#4386E5', fontSize: 18, fontWeight: 'bold', marginTop: 8 }}>
          {TEXT_BENEFIT_AMOUNT}
        </PruText>
        <View
          style={{
            width: '100%',
            height: 16,
            backgroundColor: 'rgb(237,237,237)',
            borderRadius: 8,
            marginTop: 11,
            marginBottom: 6
          }}
        >
          <LinearGradient
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            colors={getColors(percent)}
            style={{ height: 16, width: `${percent * 100}%`, borderRadius: 8 }}
          ></LinearGradient>
        </View>
        <PruText style={{ color: '#4386E5', fontSize: 16 }}>{TEXT_BENEFIT_COVERAGE}</PruText>
      </View>
    </Card>
  );
};

export default ItemRemainingBenefit;
