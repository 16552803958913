import { SetSDKException } from './../SDKReducer/actions';
import { CorporateUser, PulseConfig } from '../../types/user';

export const FETCH_CORP_USER_START = 'CorporateUser/FETCH_CORP_USER_START';
export const FETCH_CORP_USER_SUCCESS = 'CorporateUser/FETCH_CORP_USER_SUCCESS';
export const FETCH_CORP_USER_FAILED = 'CorporateUser/FETCH_CORP_USER_FAILED';
export const FETCH_CORP_USER_NOT_FOUND = 'CorporateUser/FETCH_CORP_USER_NOT_FOUND';

export const REGISTER_CORP_USER_START = 'CorporateUser/REGISTER_CORP_USER_START';
export const REGISTER_CORP_USER_SUCCESS = 'CorporateUser/REGISTER_CORP_USER_SUCCESS';
export const REGISTER_CORP_USER_FAILED = 'CorporateUser/REGISTER_CORP_USER_FAILED';
export const UPDATE_CORP_USER = 'CorporateUser/UPDATE_CORP_USER';

export const FORGET_CREDENTIALS_START = 'CorporateUser/FORGET_CREDENTIALS_START';
export const FORGET_CREDENTIALS_SUCCESS = 'CorporateUser/FORGET_CREDENTIALS_SUCCESS';
export const FORGET_CREDENTIALS_FAILED = 'CorporateUser/FORGET_CREDENTIALS_FAILED';

export const CLEAR_REGISTER_ERROR = 'CorporateUser/CLEAR_REGISTER_ERROR';

export const CLEAR_CORPORATE_USER = 'CorporateUser/CLEAR_CORPORATE_USER';

export const FETCH_PULSE_CONFIG_START = 'Pulse/FETCH_PULSE_CONFIG_START';
export const FETCH_PULSE_CONFIG_SUCCESS = 'Pulse/FETCH_PULSE_CONFIG_SUCCESS';
export const FETCH_PULSE_CONFIG_FAILED = 'Pulse/FETCH_PULSE_CONFIG_FAILED';

export interface FetchCorpUserStart {
  type: typeof FETCH_CORP_USER_START;
}

export interface FetchCorpUserSuccess {
  type: typeof FETCH_CORP_USER_SUCCESS;
  payload: CorporateUser;
}

export interface FetchCorpUserFailed {
  type: typeof FETCH_CORP_USER_FAILED;
  payload: string;
}

export interface FetchCorpUserNotFound {
  type: typeof FETCH_CORP_USER_NOT_FOUND;
}

export type FetchCorpUserAction =
  | FetchCorpUserStart
  | FetchCorpUserSuccess
  | FetchCorpUserFailed
  | FetchCorpUserNotFound;

export interface RegisterCorpUserStart {
  type: typeof REGISTER_CORP_USER_START;
}

export interface RegisterCorpUserSuccess {
  type: typeof REGISTER_CORP_USER_SUCCESS;
  payload: string;
}

export interface RegisterCorpUserFailed {
  type: typeof REGISTER_CORP_USER_FAILED;
  payload: string;
}

export interface UpdateCorpUser {
  type: typeof UPDATE_CORP_USER;
  payload: Partial<CorporateUser>;
}

export type RegisterCorpUserAction =
  | RegisterCorpUserStart
  | RegisterCorpUserSuccess
  | RegisterCorpUserFailed
  | UpdateCorpUser
  | SetSDKException;

export interface ForgetCredentialsStart {
  type: typeof FORGET_CREDENTIALS_START;
}

export interface ForgetCredentialsSuccess {
  type: typeof FORGET_CREDENTIALS_SUCCESS;
  payload: boolean;
}

export interface ForgetCredentialsFailed {
  type: typeof FORGET_CREDENTIALS_FAILED;
  payload: boolean;
}

export type ForgetCredentialsAction = ForgetCredentialsStart | ForgetCredentialsSuccess | ForgetCredentialsFailed;
export interface ClearCorporateUser {
  type: typeof CLEAR_CORPORATE_USER;
}

export interface ClearRegisterError {
  type: typeof CLEAR_REGISTER_ERROR;
}

export interface FetchPulseConfigStart {
  type: typeof FETCH_PULSE_CONFIG_START;
}

export interface FetchPulseConfigSuccess {
  type: typeof FETCH_PULSE_CONFIG_SUCCESS;
  payload: PulseConfig;
}

export interface FetchPulseConfigFailed {
  type: typeof FETCH_PULSE_CONFIG_FAILED;
  payload: string;
}

export type FetchPulseConfigAction = FetchPulseConfigStart | FetchPulseConfigSuccess | FetchPulseConfigFailed;

export type CorporateUserAction =
  | FetchCorpUserAction
  | RegisterCorpUserAction
  | ClearCorporateUser
  | ClearRegisterError
  | FetchPulseConfigAction
  | ForgetCredentialsAction;
