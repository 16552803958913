import { Alert } from 'react-native';
import { useTranslation } from 'react-i18next';

interface ConfirmProps {
  title: string;
  message?: string;
  destructive?: boolean;
  buttonText?: string;
  onConfirm: Function;
}

export const useConfirmAlert = (): { confirm: (options: ConfirmProps) => void } => {
  const { t } = useTranslation();

  const confirm = ({ title, message, destructive, buttonText, onConfirm }: ConfirmProps): void => {
    Alert.alert(title, message, [
      {
        text: 'Cancel',
        style: 'cancel'
      },
      {
        text: buttonText || t('ALERT__CONFIRM'),
        onPress: (): void => onConfirm(),
        style: destructive ? 'destructive' : 'default'
      }
    ]);
  };

  return { confirm };
};
