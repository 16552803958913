/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, ReactElement, useCallback, useEffect } from 'react';

import { View, Platform, TouchableOpacity } from 'react-native';
import ImagePicker, { Image } from 'react-native-image-crop-picker';
import DocumentPicker from 'react-native-document-picker';
import RNFS from '../../../components/RNFS';

import * as routes from '../../../routes';
import { StackNavigationProp } from '../../../types/navigation';

import MakeClaimView from '../MakeClaimView';
import ClaimDocumentCard from '../../../components/Claim/ClaimDocumentCard';
import ClaimBottomMenu, { ADD_MENU_ITEMS, EDIT_MENU_ITEMS, MenuItem } from '../../../components/Claim/ClaimBottomMenu';
import DocumentView from '../../../components/Claim/DocumentView';

import { getClaimDocuments, getDocumentList } from '../../../utils/claim';
import { getObjValue } from '../../../utils/object';
import { Claim, ClaimDocument, ClaimDocumentGroup } from '../../../types/claim';

import { useTranslation } from 'react-i18next';
import { useLBUTranslation } from '../../../hooks/useLBUTranslation';
import { PruConfirmationModal, PruText } from '@ebsme/pulse-rn-components';
import { UserCountryCode } from '../../../types/user';

import Icon from 'react-native-vector-icons/Ionicons';

import FinalSummaryScreenDeclaration from '../FinalSummaryScreen/FinalSummaryScreenDeclaration';
import FilePicker, { FilePickerService } from './FilePicker';
import * as Rx from 'rxjs/operators';

import { logFirebaseEventScreen, logFirebaseEventClick } from '../../../utils/analytics';

export interface Props {
  lbu: UserCountryCode;
  navigation: StackNavigationProp;
  loading: boolean;
  mandatoryDocument?: { [key: string]: boolean };
  documentError: { [key: string]: string };
  policyId: string;
  claim: Claim;
  setSDKError: (error: string) => void;
  updateClaim?: (payload: Claim) => void;
  deleteDocumentAction: (payload: ClaimDocument) => void;
  uploadDocument?: (payload: ClaimDocument, currentClaimDocument: ClaimDocument | null) => void;
}

type BottomSheetProps = {
  visible: boolean;
  editItem: ClaimDocument | null;
  editIndex?: number;
  items: MenuItem[];
};

export const renderClaimDocumentCard = (
  claimDocuments: ClaimDocumentGroup[],
  documentError: { [key: string]: string },
  onAddFileDocument: (docType: string) => void,
  onEditFileDocument: (docObj: ClaimDocument, docIndex: number) => void,
  setPreviewingDoc: (document: ClaimDocument) => void
): ReactElement[] => {
  return claimDocuments.map(({ docType, title, fileName, helpText, documents, isOptional }, index) => (
    <ClaimDocumentCard
      key={docType}
      index={index + 1}
      claimGroup={{ docType, title, fileName, helpText, documents, isOptional }}
      onAddFile={onAddFileDocument}
      onEditFile={onEditFileDocument}
      onDocumentView={setPreviewingDoc}
      error={getObjValue(documentError, docType)}
    />
  ));
};

interface DocumentPickerResponse {
  uri: string;
  fileCopyUri: string | null;
  copyError?: string;
  type: string | null;
  name: string;
  size: number | null;
  content?: string;
}

const DocumentScreen: React.FC<Props> = ({
  lbu,
  navigation,
  claim,
  policyId,
  deleteDocumentAction,
  documentError,
  mandatoryDocument = {},
  setSDKError,
  uploadDocument = (): void => undefined
}) => {
  const { t } = useTranslation();
  const { tLBU } = useLBUTranslation(lbu);

  const { documents, claimType, claimSubType, bankAccount } = claim;
  const bottomSheetDefault: BottomSheetProps = {
    visible: false,
    editIndex: -1,
    editItem: null,
    items: []
  };

  const [bottomSheet, setBottomSheet] = useState<BottomSheetProps>(bottomSheetDefault);
  const [bottomSheetHidden, setBottomSheetHidden] = useState<boolean>(true);
  const [bottomAction, setBottomAction] = useState<string | null>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const [selectedDocument, setSelectedDocument] = useState<string | null>(null);
  const [previewingDoc, setPreviewingDoc] = useState<ClaimDocument | undefined | null>(null);
  const [declarationVisible, setDeclarationVisible] = useState(false);

  const [documentIncrement, setDocumentIncrement] = useState<{
    [key: string]: number;
  }>({});

  const [checkedConsent, setCheckedConsent] = useState<boolean>(false);

  const claimDocuments: ClaimDocumentGroup[] = getClaimDocuments(lbu, claimType, claimSubType, bankAccount).map(
    (dType) => {
      const { docType, title, fileName, helpText, isOptional } = getDocumentList(dType);
      const filteredDocuments = documents.filter(({ docType: dcType }) => dcType === docType);
      return {
        docType,
        title,
        fileName,
        helpText,
        isOptional,
        documents: filteredDocuments
      };
    }
  );

  const nextDisabled =
    claimDocuments.filter(({ documents = [], docType, isOptional }) => {
      return (!isOptional || mandatoryDocument[docType]) && documents.length === 0;
    }).length > 0 ||
    (!checkedConsent && lbu !== 'SG');

  const getIncrementValue = useCallback(
    (documentKey = ''): number => {
      return getObjValue(documentIncrement, documentKey, 0);
    },
    [documentIncrement]
  );

  const onPickerResponse = useCallback(
    (image: { data?: string | null; mime?: string | null }, replace?: boolean): void => {
      claimDocuments.forEach((it) => {
        if (selectedDocument === it.docType) {
          let document;
          const previousDocument = bottomSheet.editItem;

          if (replace) {
            document = {
              ...bottomSheet.editItem,
              fileContent: `${image.data}`,
              fileType: `${image.mime}`
            };
            it.documents[bottomSheet.editIndex || 0] = document;
          } else {
            document = {
              docType: selectedDocument,
              fileName: `${it.fileName} ${getIncrementValue(selectedDocument) || it.documents.length + 1}`,
              fileContent: `${image.data}`,
              fileType: `${image.mime}`
            };
            it.documents = [...it.documents, document];
            setDocumentIncrement({
              [selectedDocument]: (getIncrementValue(selectedDocument) || it.documents.length) + 1
            });
          }

          uploadDocument(
            {
              fileName: document.fileName,
              fileContent: document.fileContent,
              fileType: document.fileType,
              docType: document.docType,
              policyId
            },
            previousDocument
          );
        }
      });
    },
    [bottomSheet, claimDocuments, getIncrementValue, policyId, selectedDocument, uploadDocument]
  );

  const onDocumentPickerResponse = useCallback(
    (file: DocumentPickerResponse, replace?: boolean): void => {
      claimDocuments.forEach((it) => {
        if (selectedDocument === it.docType) {
          let document;
          const previousDocument = bottomSheet.editItem;

          if (replace) {
            document = {
              ...bottomSheet.editItem,
              fileContent: `${file.content}`,
              fileType: `${file.type}`
            };
            it.documents[bottomSheet.editIndex || 0] = document;
          } else {
            document = {
              docType: selectedDocument,
              fileName: `${it.fileName} ${getIncrementValue(selectedDocument) || it.documents.length + 1}`,
              fileContent: `${file.content}`,
              fileType: `${file.type}`
            };
            it.documents = [...it.documents, document];
            setDocumentIncrement({
              [selectedDocument]: (getIncrementValue(selectedDocument) || it.documents.length) + 1
            });
          }

          uploadDocument(
            {
              fileName: document.fileName,
              fileContent: document.fileContent,
              fileType: document.fileType,
              docType: document.docType,
              policyId
            },
            previousDocument
          );
        }
      });
    },
    [bottomSheet, claimDocuments, getIncrementValue, policyId, selectedDocument, uploadDocument]
  );

  useEffect(() => {
    logFirebaseEventScreen('eb_make_claim', {
      feature: 'MakeClaim',
      journey: 'make_claim',
      stage: 'upload_doc',
      screen_id: 'SCR_EB_CLAIM_DOCS',
      screen_name: 'DocumentScreen'
    });
  }, []);

  useEffect(() => {
    /**
     * Only show the image picker or camera when the bottom sheet is fully hidden
     */
    if (bottomAction && bottomSheetHidden) {
      if (Platform.OS === 'web') {
        // web
        // FilePickerService.onClick.next();
        // FilePickerService.onChange.pipe(Rx.first()).subscribe((image) => {
        //   console.log('onChange', image);
        //   setBottomAction(null);
        //   if (image) {
        //     onPickerResponse(image, ['reTake', 'reUpload', 'reUploadPdf'].includes(bottomAction));
        //   }
        // });
        return;
      }
      if (['takePhoto', 'reTake'].includes(bottomAction)) {
        ImagePicker.openCamera({
          width: 300,
          height: 400,
          cropping: false,
          includeBase64: true,
          waitAnimationEnd: false
        })
          .then((image: Image | Image[]) => {
            setBottomAction(null);
            const imageData: Image = Array.isArray(image) ? image[0] : image;
            onPickerResponse(imageData, bottomAction === 'reTake');
          })
          .catch(() => {
            setBottomAction(null);
          });
      } else if (['uploadPhoto', 'reUpload'].includes(bottomAction)) {
        ImagePicker.openPicker({
          width: 300,
          height: 400,
          cropping: false,
          includeBase64: true,
          waitAnimationEnd: false,
          mediaType: 'photo'
        })
          .then((image: Image | Image[]) => {
            setBottomAction(null);
            const imageData: Image = Array.isArray(image) ? image[0] : image;
            onPickerResponse(imageData, bottomAction === 'reUpload');
          })
          .catch(() => {
            setBottomAction(null);
          });
      } else if (['uploadPdf', 'reUploadPdf'].includes(bottomAction)) {
        DocumentPicker.pick({
          type: [DocumentPicker.types.pdf, DocumentPicker.types.doc, DocumentPicker.types.docx]
        })
          .then(async (res: DocumentPickerResponse) => {
            const path = Platform.select({
              ios: decodeURIComponent(res.uri),
              android: res.uri
            }) as string;
            const content = await RNFS.readFile(path, 'base64');
            return {
              ...res,
              content
            };
          })
          .then((res: DocumentPickerResponse) => {
            setBottomAction(null);
            onDocumentPickerResponse(res, bottomAction === 'reUploadPdf');
          })
          .catch((error) => {
            if (!DocumentPicker.isCancel(error)) {
              setSDKError(error.message);
              setBottomAction(null);
            }
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomAction, bottomSheetHidden]);

  const onAddFile = (docType: string): void => {
    setBottomSheet({
      visible: true,
      editIndex: -1,
      editItem: null,
      items: ADD_MENU_ITEMS
    });
    setBottomSheetHidden(false);
    setSelectedDocument(docType);
  };

  const onEditFile = (doc: ClaimDocument, docIndex: number): void => {
    setBottomSheet({
      visible: true,
      editIndex: docIndex,
      editItem: doc,
      items: EDIT_MENU_ITEMS
    });
    setBottomSheetHidden(false);
    setSelectedDocument(doc?.docType || null);
  };

  const onDeleteFile = (): void => {
    claimDocuments.forEach((it) => {
      if (selectedDocument === it.docType) {
        it.documents = [...it.documents.filter((d, index) => index === bottomSheet.editIndex)];
        deleteDocumentAction(it.documents[0]);
      }
    });
  };

  const onMenuAction = (item: MenuItem): void => {
    if (['takePhoto', 'reTake', 'uploadPhoto', 'reUpload', 'uploadPdf', 'reUploadPdf'].includes(item.id)) {
      // web
      FilePickerService.onClick.next();
      FilePickerService.onChange.pipe(Rx.first()).subscribe((image) => {
        console.log('onChange', image);
        setBottomAction(null);
        if (image) {
          onPickerResponse(image, ['reTake', 'reUpload', 'reUploadPdf'].includes(item.id));
        }
      });
      setBottomSheet({
        ...bottomSheet,
        visible: false
      });
      setBottomAction(item.id);
    } else if (item.id === 'deleteFile') {
      setBottomSheet({
        ...bottomSheet,
        visible: false
      });
      setDeleteModal(true);
    }
    logFirebaseEventClick('eb_make_claim', {
      feature: 'MakeClaim',
      journey: 'make_claim',
      stage: 'upload_doc',
      screen_id: 'SCR_EB_CLAIM_DOCS',
      screen_name: 'DocumentScreen',
      object_id: 'BTN_EB_CLAIM_DOCS',
      method: item.id
    });
  };
  const onDeleteModalConfirm = (): void => {
    setDeleteModal(false);
    onDeleteFile();
  };
  const onDocumentClose = (): void => setPreviewingDoc(null);
  return (
    <MakeClaimView
      title={t('CLAIMS__DOCUMENTS__TITLE')}
      currentRoute={routes.DocumentScreen}
      nextDisabled={nextDisabled}
      navigation={navigation}
      enableSave
      backVisible
    >
      {previewingDoc ? <DocumentView document={previewingDoc} onClose={onDocumentClose} /> : null}
      {declarationVisible && (
        <FinalSummaryScreenDeclaration testID="DeclarationModal" onClose={(): void => setDeclarationVisible(false)} />
      )}
      <ClaimBottomMenu
        visible={bottomSheet.visible}
        items={bottomSheet.items}
        onMenuAction={onMenuAction}
        onModalHide={(): void => setBottomSheetHidden(true)}
        onCancel={(): void => {
          setBottomSheet({
            ...bottomSheet,
            visible: false
          });
        }}
      />
      <PruConfirmationModal
        isVisible={deleteModal && bottomSheetHidden}
        title={t('CLAIMS__DOCUMENTS__DELETE_CONFIRMATION')}
        confirmText={t('CLAIMS__DOCUMENTS__CONFIRM_BUTTON')}
        cancelText={t('BUTTON__CANCEL')}
        onConfirm={(): void => {
          onDeleteModalConfirm();
        }}
        onCancel={(): void => {
          setDeleteModal(false);
        }}
      />

      {/* <Text style={{ marginLeft: 20, marginRight: 20, marginBottom: 20, color: '#68737A', fontSize: 16 }}>
        {t('CLAIMS__DOCUMENTS__INSTRUCTION')}
      </Text> */}
      <View
        style={{
          marginTop: 20,
          marginBottom: 30
        }}
      >
        <PruText size={15} color="darkGrey" style={{ marginHorizontal: 20 }}>
          {t('CLAIMS__DOCUMENTS__NOTE')}
        </PruText>
        {renderClaimDocumentCard(claimDocuments, documentError, onAddFile, onEditFile, setPreviewingDoc)}
      </View>
      {lbu !== 'SG' && (
        <View
          style={{
            flexDirection: 'row',
            margin: 20,
            marginBottom: 70,
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1
          }}
        >
          <TouchableOpacity
            style={{
              width: 30,
              height: 30,
              borderColor: 'black',
              borderWidth: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
            onPress={(): void => {
              setCheckedConsent(!checkedConsent);
              logFirebaseEventClick('eb_make_claim', {
                feature: 'MakeClaim',
                journey: 'make_claim',
                stage: 'upload_doc',
                screen_id: 'SCR_EB_CLAIM_DOCS',
                screen_name: 'DocumentScreen',
                object_id: 'CB_EB_CLAIM_CONSENT'
              });
            }}
          >
            {checkedConsent && <Icon name="checkmark" size={25} color="red" />}
          </TouchableOpacity>
          <View style={{ marginLeft: 20, flex: 1 }}>
            <PruText color="darkGrey">
              {tLBU('CLAIMS__CONSENT_MESSAGE', { fallbackValue: t('CLAIMS__CONSENT_MESSAGE') })}
            </PruText>
            {['TH', 'PH'].includes(lbu) && (
              <TouchableOpacity
                style={{ marginTop: 10 }}
                onPress={() => {
                  if (lbu === 'TH') {
                    navigation.navigate(routes.ConsentScreen);
                  } else {
                    setDeclarationVisible(true);
                  }
                  logFirebaseEventClick('eb_make_claim', {
                    feature: 'MakeClaim',
                    journey: 'make_claim',
                    stage: 'upload_doc',
                    screen_id: 'SCR_EB_CLAIM_DOCS',
                    screen_name: 'DocumentScreen',
                    object_id: 'LNK_EB_CLAIM_CONSENT'
                  });
                }}
              >
                <PruText color="red" style={{ textDecorationLine: 'underline' }}>
                  {tLBU('CLAIMS__CONSENT_LINK', { fallbackValue: t('CLAIMS__CONSENT_LINK') })}
                </PruText>
              </TouchableOpacity>
            )}
          </View>
        </View>
      )}
      <FilePicker />
    </MakeClaimView>
  );
};

export default DocumentScreen;
