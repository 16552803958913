import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer, BaseReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

import SDKReducer from './reducers/SDKReducer';
import MakeClaimReducer from './reducers/MakeClaimReducer';
import MyPolicyReducer from './reducers/MyPolicyReducer';
import RewardReducer from './reducers/RewardReducer';
import ReviewClaimReducer from './reducers/ReviewClaimReducer';
import CorporateUserReducer from './reducers/CorporateUserReducer';
import PulseUserReducer from './reducers/PulseUserReducer';
import ClinicsReducer from './reducers/ClinicsReducer';
import PreferencesReducer from './reducers/PreferencesReducer';
import LetterOfGuaranteeReducer from './reducers/LetterOfGuaranteeReducer';
import BankAccountReducer from './reducers/BankAccountReducer';
import SessionReducer from './reducers/SessionReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

import { SDKState, SDKAction } from './reducers/SDKReducer/index';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['Preferences', 'Session', 'PulseUser', 'CorporateUser']
};

const sdkPersistConfig = {
  key: 'SDK',
  storage: AsyncStorage,
  whitelist: ['locale']
};

const reducers = {
  SDK: persistReducer(sdkPersistConfig, SDKReducer as BaseReducer<SDKState, SDKAction>),
  MakeClaim: MakeClaimReducer,
  ReviewClaim: ReviewClaimReducer,
  MyPolicy: MyPolicyReducer,
  Reward: RewardReducer,
  CorporateUser: CorporateUserReducer,
  PulseUser: PulseUserReducer,
  Clinics: ClinicsReducer,
  Preferences: PreferencesReducer,
  BankAccount: BankAccountReducer,
  LetterOfGuarantee: LetterOfGuaranteeReducer,
  Session: SessionReducer
};

const rootReducer = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, rootReducer as never); //TODO: Replace never with proper typing
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
export const persistor = persistStore(store);

export default store;

export type RootState = ReturnType<typeof rootReducer>;
