import React from 'react';
import { StyleSheet, TouchableOpacity, ViewProps } from 'react-native';
import { PruText, PruCard, PruIcon, colors } from '@ebsme/pulse-rn-components';

interface ActionOption {
  label: string;
  onPress: () => void;
}

interface Props extends ViewProps {
  options: ActionOption[];
}

const MultiActionableTile: React.FC<Props> = ({ options, style }) => {
  const styles = StyleSheet.create({
    tile: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 16,
      borderTopColor: colors.grey
    }
  });
  return (
    <PruCard padded={false} style={style}>
      {options.map((option, index) => (
        <TouchableOpacity
          key={option.label}
          accessible={false}
          accessibilityLabel={option.label}
          onPress={option.onPress}
          style={[styles.tile, { borderTopWidth: index === 0 ? 0 : 1 }]}
        >
          <PruText color="black">{option.label}</PruText>
          <PruIcon name="arrow-forward-sharp" color={colors.red} size={18} />
        </TouchableOpacity>
      ))}
    </PruCard>
  );
};

export default MultiActionableTile;
