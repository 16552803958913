import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Policy } from '../../types/policy';
import { StackNavigationProp } from '../../types/navigation';
import { getNavigate } from '../../utils/navigation';
import * as routes from '../../routes';

import { PruText } from '@ebsme/pulse-rn-components';

import MyPolicy from '../../components/MyPolicy';

import Screen from '../../components/Screen';
import { getObjValueByPath } from '../../utils/object';
import { LBU } from '../../config';
import { useLBUTranslation } from '../../hooks/useLBUTranslation';
import { Locale } from '../../types/sdk';
import { Dependant } from '../../types/claim';

import { logFirebaseEventScreen, logFirebaseEventClick } from '../../utils/analytics';
import MyPolicyScreenSG from './MyPolicyScreenSG';
import ModalSelectDependant from '../../components/ModalSelectDependant';

interface Props {
  navigation: StackNavigationProp<
    {},
    {
      dependant: Dependant
    }
  >;
  fetchingPolicy?: boolean;
  policy?: Policy;
  policyDetail?: any;
  fetchError?: string;
  lbu?: string;
  locale: Locale;
  dependants: Dependant[];
  fetchPolicyDetail: (policyNumber: string) => void;
  setSDKLoading: (loading: boolean) => void;
}

const MyPolicyScreen: React.FC<Props> = ({
  navigation,
  fetchingPolicy = false,
  policy,
  policyDetail,
  fetchError,
  setSDKLoading,
  fetchPolicyDetail,
  lbu,
  locale,
  dependants
}) => {
  const [selected, setSelected] = useState('');
  const dependantSelect: Dependant = navigation.getParam('dependant');

  const { t } = useTranslation();
  const { tLBU } = useLBUTranslation(lbu);
  const { isShowDependantView } = getObjValueByPath(LBU, `${lbu}.MY_POLICY`);
  useEffect(() => {
    if (policy && policy.certificateNo) {
      fetchPolicyDetail(dependantSelect?.dependantPolicyNumber || policy.certificateNo);
    }
    logFirebaseEventScreen('eb_policy', {
      feature: 'Policy',
      journey: 'policy',
      stage: 'policy_list',
      screen_id: 'SCR_EB_POLICY_MPS',
      screen_name: 'MyPolicyScreen'
    });
  }, [fetchPolicyDetail]);

  useEffect(() => {
    setSDKLoading(fetchingPolicy);
  }, [setSDKLoading, fetchingPolicy]);

  const selectDependant = (item: string) => {
    if (item === 'select') {
      fetchPolicyDetail(policy?.certificateNo || '');
      logFirebaseEventClick('eb_policy', {
        feature: 'Policy',
        journey: 'policy',
        stage: 'policy_list',
        screen_id: 'SCR_EB_POLICY_MPS',
        screen_name: 'MyPolicyScreen',
        object_id: 'DRP_EB_POLICY_DEP',
        applicant: 'main'
      });
    } else {
      fetchPolicyDetail(item);
      logFirebaseEventClick('eb_policy', {
        feature: 'Policy',
        journey: 'policy',
        stage: 'policy_list',
        screen_id: 'SCR_EB_POLICY_MPS',
        screen_name: 'MyPolicyScreen',
        object_id: 'DRP_EB_POLICY_DEP',
        applicant: 'dependant'
      });
    }
    setSelected(item);
  };

  let content: React.ReactNode = null;

  switch (true) {
    case fetchingPolicy:
      content = (
        <PruText size={24} marginTop={40} marginBottom={40} align="center" testID="MyPolicyScreen__Loading">
          {t('MYPOLICY__FETCHING')}
        </PruText>
      );
      break;

    case Boolean(fetchError):
      content = (
        <PruText size={20} marginTop={40} marginBottom={40} testID="MyPolicyScreen__Error">
          Error: {fetchError}
        </PruText>
      );
      break;

    case Boolean(policy):
      content = (
        <MyPolicy
          // @ts-ignore
          policy={policy as Policy}
          locale={locale}
          policyDetail={policyDetail}
          dependants={dependants}
          isShowDependantView={isShowDependantView}
          lBUCode={lbu}
          selectDependant={selectDependant}
          selected={selected}
        />
      );

      break;

    default:
      break;
  }

  // if (lbu === 'SG') {
  //   const isShowDependant = isShowDependantView && dependants && dependants.length > 0;
  //   return (
  //     <MyPolicyScreenSG
  //       isShowDependant={isShowDependant}
  //       onPressCancel={getNavigate(navigation.navigate, routes.Landing)}
  //       dependants={dependants}
  //       navigation={navigation}
  //     >
  //       {content}
  //     </MyPolicyScreenSG>
  //   );
  // }
  return (
    <Screen
      heading={
        <>
          <PruText weight="bold">
            {tLBU('LANDING__MY_POLICY_NEW', { fallbackValue: t('LANDING__MY_POLICY_NEW') })}
          </PruText>
        </>
      }
      subHeading={lbu === 'SG' && <PruText color="red">*For more details, please refer to your HR.</PruText>}
      paddingHorizontal={24}
      onPressCancel={getNavigate(navigation.navigate, routes.Landing)}
    >
      {content}
    </Screen>
  );
};

export default MyPolicyScreen;
