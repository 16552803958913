import styled from 'styled-components/native';

import { red } from '../../assets/colors';

export const ArrowIcon = styled.View`
  position: absolute;
  bottom: -14px;
  right: 18px;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: ${red};
  align-items: center;
  justify-content: center;
`;

export const ArrowIconChevron = styled.Image`
  width: 5px;
  height: 10px;
  resize-mode: cover;
`;
