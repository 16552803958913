import React from 'react';
import SvgAnimatedLinearGradient from 'react-native-svg-animated-linear-gradient';
import { Rect } from 'react-native-svg';

import { PruCard } from '@ebsme/pulse-rn-components';

const ClinicListItemLoader: React.FC = () => (
  <PruCard style={{ marginHorizontal: 18, marginBottom: 18, marginTop: 6 }}>
    <SvgAnimatedLinearGradient width={400} height={120} duration={1000}>
      <Rect x="1" y="1" rx="7" ry="7" width="274" height="14" />
      <Rect x="1" y="32" rx="4" ry="4" width="336" height="8" />
      <Rect x="1" y="48" rx="4" ry="4" width="178" height="8" />
      <Rect x="1" y="74" rx="4" ry="4" width="162" height="8" />
      <Rect x="1" y="91" rx="4" ry="4" width="113" height="8" />
      <Rect x="272" y="72" rx="15" ry="15" width="80" height="30" />
    </SvgAnimatedLinearGradient>
  </PruCard>
);

export default ClinicListItemLoader;
