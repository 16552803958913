import { FeatureToggle } from '../../types/featureToggle';
import { GeneralConfig } from '../../types/generalConfig';
import { LBUConfig } from '../../types/lbuConfig';
import { SupportInfo } from '../../types/supportInfo';
import { ClaimTypeOption, ClaimDocumentConfig, ClaimViewConfig, PaymentOption, ClaimDateConfig } from './../../types/claim';
import * as GRAPHQL from './my/graphql';
import * as REQUEST_PICKER from './my/requestPicker';
import { NATIONALIDCHECK } from './my/nationalId';

const CLAIM_TYPES: ClaimTypeOption[] = [
  {
    id: 'ipd',
    label: 'IPD',
    documents: ['doc1', 'doc2', 'doc3', 'doc4'],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    originalRequired: true,
    isDefault: false
  },
  {
    id: 'opd',
    label: 'OPD',
    documents: ['doc1', 'doc2', 'doc3', 'doc4'],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    originalRequired: true,
    isDefault: true
  }
];

const PAYMENT_OPTIONS: PaymentOption[] = [
  {
    id: 'giro',
    label: 'Giro',
    isRequiredBankAccount: true
  }
]

const DATES: ClaimDateConfig = {
  claimDate: {
    dateType: 'claimDate',
    title: 'CLAIMS__CLAIM_DETAILS_REQUEST_DATE',
    isOptional: false,
    readOnly: true
  },
  accidentDate: {
    dateType: 'accidentDate',
    title: 'CLAIMS__CLAIM_DETAILS_DATE_OF_ACCIDENT',
    isOptional: true,
    readOnly: false
  },
  admissionDate: {
    dateType: 'admissionDate',
    title: 'CLAIMS__CLAIM_DETAILS_DATE_OF_ADMISSION',
    isOptional: false,
    readOnly: false
  },
  dischargeDate: {
    dateType: 'dischargeDate',
    title: 'CLAIMS__CLAIM_DETAILS_DATE_OF_DISCHARGE',
    isOptional: false,
    readOnly: false
  },
};

const CLAIM_VIEW_CONFIG: ClaimViewConfig = {
  showOriginalReceiptRequired: true
};

const DOCUMENTS: ClaimDocumentConfig = {};

const SUPPORT_INFO: SupportInfo = {
  emails: [],
  phones: [],
  submitTicketUrl: 'https://support-eb-prudential.atlassian.net/servicedesk/customer/portal/3',
  serviceStatusUrl: '',
  applicationHelpUrl: 'https://support-pamb-prudential.atlassian.net/wiki/spaces/MEBMAKB/overview'
};

// const API_URL = {
//   uat: 'https://uat-api.eb.prudential.com.my/graphql/pulse-app/graphiql',
//   prod: 'https://api.eb.prudential.com.my/graphql/pulse-app/graphiql'
// };

const API_URL = {
  dev: 'https://uat-api.eb.prudential.co.th/graphql/pulse-app',
  uat: 'https://uat-api.eb.prudential.co.th/graphql/pulse-app',
  prod: 'https://api.eb.prudential.co.th/graphql/pulse-app'
};

const FEATURE_TOGGLE: FeatureToggle = {
  enabledSaveDraft: false
};

const MY_POLICY = {
  dateFormat: 'DD/MM/YYYY',
  lBUCode: 'PAMB',
  isShowDependantView: false
};

const GENERAL: GeneralConfig = {
  globalNamespace: 'global_pamb' //TODO: doesn't exist yet
};

export const MY: LBUConfig = {
  API_URL,
  CLAIM_TYPES,
  CLAIM_VIEW_CONFIG,
  DOCUMENTS,
  SUPPORT_INFO,
  FEATURE_TOGGLE,
  MY_POLICY,
  GRAPHQL,
  REQUEST_PICKER,
  GENERAL,
  NATIONALIDCHECK,
  PAYMENT_OPTIONS,
  DATES
};
