import styled from 'styled-components/native';

import { black, red, white } from '../../assets/colors';
import { getFontFamilyStyle, getShadowStyles } from '../../assets/mixins';

interface StyledProps {
  filled?: boolean;
  error?: boolean;
}

const shadow = getShadowStyles({
  elevation: 10
});

const getContainerBorderStyle = ({ error }: StyledProps): string => (error ? `border: solid 1px ${red}` : '');

export const Container = styled.View`
  width: 100%;
  border-radius: 10px;
  padding: 8px 12px;
  background-color: ${white};
  ${shadow}
  ${getContainerBorderStyle}
`;

const inputFontFamilyStyle = getFontFamilyStyle({ weight: 'normal' });

export const StyledInput = styled.TextInput`
  width: 100%;
  text-align-vertical: top;
  font-size: 18px;
  flex: 1 0;
  color: ${black};
  ${inputFontFamilyStyle}
`;

export const ErrorContainer = styled.View`
  flex-direction: row;
  margin-top: 16px;
`;

export const ErrorMessageContainer = styled.View`
  flex: 1 1;
  margin-left: 8px;
`;
