import { Action } from 'redux';

import { PulseUser } from '../../types/user';

export const SET_PULSE_USER_PROFILE = 'PulseUser/SET_PULSE_USER_PROFILE';
export const SET_LAST_PULSE_USER = 'PulseUser/SET_LAST_PULSE_USER';
export const CLEAR_LAST_PULSE_USER = 'PulseUser/CLEAR_LAST_PULSE_USER';

interface SetPulseUser extends Action {
  type: typeof SET_PULSE_USER_PROFILE;
  payload: PulseUser;
}

interface SetLastPulseUser extends Action {
  type: typeof SET_LAST_PULSE_USER;
  payload: PulseUser;
}

interface ClearLastPulseUser extends Action {
  type: typeof CLEAR_LAST_PULSE_USER;
}

export type PulseUserAction = SetPulseUser | SetLastPulseUser | ClearLastPulseUser;

export const setPulseUser = (pulseUser: PulseUser): SetPulseUser => ({
  type: SET_PULSE_USER_PROFILE,
  payload: pulseUser
});

export const setLastPulseUser = (pulseUser: PulseUser): SetLastPulseUser => ({
  type: SET_LAST_PULSE_USER,
  payload: pulseUser
});

export const clearLastPulseUser = (): ClearLastPulseUser => ({
  type: CLEAR_LAST_PULSE_USER
});
