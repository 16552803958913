import React, { ReactElement } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Card, Badge, Button, Icon } from 'react-native-elements';
import { useTranslation } from 'react-i18next';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';

import { ClaimDocument as ClaimDocumentType, ClaimDocumentGroup } from '../../../types/claim';
import ClaimDocument from '../ClaimDocument';
import { black } from '../../../assets/colors';
import { isProd, isUat } from '../../../config/selector';
import { PruText } from '@ebsme/pulse-rn-components';

export interface Props {
  index: number;
  claimGroup: ClaimDocumentGroup;
  onDocumentView: (document: ClaimDocumentType) => void;
  onAddFile: (docType: string) => void;
  onEditFile: (doc: ClaimDocumentType, index: number) => void;
  error?: string;
}

export const renderClaimDocuments = (
  documents: ClaimDocumentType[],
  onDocumentView: (document: ClaimDocumentType) => void,
  onEditFile: (doc: ClaimDocumentType, index: number) => void
): ReactElement[] => {
  return documents.map((doc, index) => (
    <ClaimDocument
      key={index}
      document={doc}
      onDocumentView={onDocumentView}
      onEditFile={(document: ClaimDocumentType): void => onEditFile(document, index)}
    />
  ));
};

const ClaimDocumentCard: React.FC<Props> = ({ index, claimGroup, onDocumentView, onAddFile, onEditFile, error }) => {
  const { t } = useTranslation();

  const { docType, title, helpText, documents, isOptional } = claimGroup;
  return (
    <View testID={title}>
      <Card
        containerStyle={{
          margin: 20,
          marginBottom: 30,
          marginTop: 10,
          borderRadius: 10,
          borderColor: '#ffffff',
          elevation: 10,
          shadowOpacity: 0.1,
          shadowRadius: 5,
          shadowColor: black,
          shadowOffset: {
            width: 2,
            height: 9
          }
        }}
      >
        {documents.some((doc) => doc.fileName) ? (
          <Icon
            reverse
            name="done"
            color="#fff"
            containerStyle={{
              position: 'absolute',
              top: -40,
              right: 0,
              width: 40,
              height: 40,
              borderRadius: 20,
              backgroundColor: '#36B37E'
            }}
            iconStyle={{
              marginTop: 5
            }}
          />
        ) : title ? (
          <Badge
            value={index}
            containerStyle={{
              position: 'absolute',
              backgroundColor: '#20365A',
              borderRadius: 20,
              height: 40,
              width: 40,
              padding: 5,
              paddingBottom: 5,
              paddingTop: 5,
              alignItems: 'center',
              top: -40,
              right: 0
            }}
            textStyle={{
              fontWeight: 'bold',
              fontSize: 14
            }}
            badgeStyle={{
              backgroundColor: 'transparent',
              borderWidth: 0,
              marginTop: 3
            }}
          />
        ) : null}
        <PruText color="darkGrey" size={16} weight="bold" marginBottom={10}>
          {`${t(title)} ${!isOptional ? '*' : ''}`}
        </PruText>

        {helpText ? (
          <View style={{ flexDirection: 'row' }}>
            <MaterialIcon name="info-outline" color="#68737A" size={15} style={{ marginTop: 2 }} />
            <PruText color="darkGrey" size={15} marginLeft={5} marginRight={10} marginBottom={20}>
              {t(helpText)}
            </PruText>
          </View>
        ) : null}

        {documents.length > 0 ? (
          <>
            {renderClaimDocuments(documents, onDocumentView, onEditFile)}
            <TouchableOpacity testID="AddAnotherDocumentTrigger" onPress={(): void => onAddFile(docType)}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignContent: 'center',
                  alignItems: 'center',
                  padding: 5,
                  marginBottom: 4,
                  marginTop: 4,
                  backgroundColor: '#fff'
                }}
              >
                <Icon
                  name="add"
                  size={32}
                  color="#68737A"
                  containerStyle={{
                    borderWidth: 1,
                    borderRadius: 30,
                    borderColor: '#E6EAEE',
                    marginRight: 20
                  }}
                />
                <PruText size={14} weight="bold">
                  {t('CLAIMS__DOCUMENTS__ANOTHER_FILE')}
                </PruText>
              </View>
            </TouchableOpacity>
          </>
        ) : (
          <>
            <Button
              raised
              testID="AddDocumentTrigger"
              title={t('CLAIMS__DOCUMENTS__ADD_FILE')}
              containerStyle={{
                margin: 0,
                marginStart: 0,
                marginEnd: 0,
                marginBottom: 10
              }}
              titleStyle={{
                color: '#68737A',
                fontSize: 15
              }}
              buttonStyle={{
                margin: 5,
                backgroundColor: '#ffffff',
                borderRadius: 5
              }}
              onPress={(): void => onAddFile(docType)}
            />
          </>
        )}

        {Boolean(error) && (
          <PruText color="red" size={12} marginBottom={20}>
            {(isProd() || isUat()) ? t('ERROR__UPLOAD') : error}
          </PruText>
        )}
      </Card>
    </View>
  );
};

export default ClaimDocumentCard;
