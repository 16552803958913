import { SDKAction, setSDKError } from './../SDKReducer/actions';
import { Action, Dispatch } from 'redux';
import { fetchAllClaims } from './api';
import { Claim, ClaimState } from '../../types/claim';
import { getValue } from '../../utils/object';
import { path } from 'ramda';
import moment from 'moment';
import { RootState } from '../../store';
import { getUserCountryCode } from '../PulseUserReducer/selectors';

// import { RootState } from '../../store';
// import { getEmployeeId } from '../SessionReducer/selectors';

export const SET_CLAIM_CURRENT_INDEX = 'ReviewClaim/SET_CLAIM_CURRENT_INDEX';
export const FETCH_CLAIMS_START = 'ReviewClaim/FETCH_CLAIMS_START';
export const FETCH_CLAIMS_SUCCESS = 'ReviewClaim/FETCH_CLAIMS_SUCCESS';
export const FETCH_CLAIMS_FAILED = 'ReviewClaim/FETCH_CLAIMS_FAILED';

export const CLEAR_CLAIMS = 'ReviewClaim/CLEAR_CLAIMS';
export const SET_CLAIM_FILTER = 'ReviewClaim/SET_CLAIM_FILTER';

interface SetClaimDetailAction extends Action {
  type: typeof SET_CLAIM_CURRENT_INDEX;
  payload: string;
}

interface SetClaimFilter extends Action {
  type: typeof SET_CLAIM_FILTER;
  payload?: ClaimState;
}

interface FetchClaimsStart extends Action {
  type: typeof FETCH_CLAIMS_START;
}

interface FetchClaimsSuccess extends Action {
  type: typeof FETCH_CLAIMS_SUCCESS;
  payload: Claim[];
}

interface FetchClaimsFailed extends Action {
  type: typeof FETCH_CLAIMS_FAILED;
  payload: string;
}

interface ClearClaims extends Action {
  type: typeof CLEAR_CLAIMS;
}

export type ReviewClaimAction =
  | SetClaimDetailAction
  | FetchClaimsStart
  | FetchClaimsSuccess
  | FetchClaimsFailed
  | ClearClaims
  | SetClaimFilter
  | SDKAction;

export const setClaimDetailDispatcher = (payload: Claim): SetClaimDetailAction => ({
  type: SET_CLAIM_CURRENT_INDEX,
  payload: payload.claimNumber
});

export const setClaimFilter = (payload?: ClaimState): SetClaimFilter => ({
  type: SET_CLAIM_FILTER,
  payload
});

export const fetchClaimsStart = (): FetchClaimsStart => ({ type: FETCH_CLAIMS_START });

export const fetchClaimsSuccess = (payload: Claim[]): FetchClaimsSuccess => ({
  type: FETCH_CLAIMS_SUCCESS,
  payload
});

export const fetchClaimsFailed = (payload: string): FetchClaimsFailed => ({
  type: FETCH_CLAIMS_FAILED,
  payload
});

export const clearClaims = (): ClearClaims => ({
  type: CLEAR_CLAIMS
});

export const fetchClaims =
  (certificateNo: string) =>
  async (dispatch: Dispatch<ReviewClaimAction>, getState: () => RootState): Promise<void> => {
    dispatch(fetchClaimsStart());

    const lbu = getUserCountryCode(getState()) ?? 'PH';
    // const employeeId = getEmployeeId(getState()) ?? '';

    try {
      const res = await fetchAllClaims(lbu, certificateNo);

      const claims = path<Claim[]>(['data', lbu === 'TH' ? 'getClaimsHistory' : 'getClaimsForEmployeePolicy'], res);

      // const userClaims = claims?.filter((claim) => claim.employeeId === employeeId);
      let sortedClaims = (claims ?? []).sort(function (a, b) {
        return moment(b.claimDate).diff(a.claimDate);
      });

      if (lbu === 'TH') {
        sortedClaims = sortedClaims.map((claim, index) => ({
          ...claim,
          claimNumber: index.toString(),
          claimState: claim.claimStatus
        }));
      }
      dispatch(fetchClaimsSuccess(sortedClaims));
    } catch (e) {
      const errorMessage = getValue(e, 'networkError.result.errors.0.message', e.message);
      dispatch(fetchClaimsFailed(errorMessage));
      dispatch(setSDKError(errorMessage));
    }
  };

export const setClaimDetail =
  (document: Claim) =>
  async (dispatch: Dispatch<ReviewClaimAction>): Promise<void> => {
    dispatch(setClaimDetailDispatcher(document));
  };
