import React, { useState } from 'react';
import { View, Modal, Text, TouchableOpacity } from 'react-native';
import { Icon, Button } from 'react-native-elements';
import DatePicker from '../../../components/DatePicker/DatePickerBase';
import { format } from 'date-fns';

import * as routes from '../../../routes';
import { StackNavigationProp } from '../../../types/navigation';
import { Claim } from '../../../types/claim';

import { PruInput } from '@ebsme/pulse-rn-components';
import SwitchList from '../../../components/SwitchList';
import MakeClaimView from '../MakeClaimView';
// import { useTranslation } from 'react-i18next';
import { CLAIMANT_LIST, CLAIM_CAUSE_LIST } from '../../../utils/claim';

export interface Props {
  navigation: StackNavigationProp;
  claim: Claim;
  updateClaim?: (claim: Claim) => void;
}

const ClaimDetailScreen: React.FC<Props> = ({
  navigation,
  claim
  // updateClaim = (): void => undefined
}) => {
  // const { t } = useTranslation();
  const { claimType } = claim;
  const [claimant, setClaimant] = useState('');
  const [claimantVisible, setClaimantVisible] = useState(false);
  const onClaimantSelect = (item: { id: string; label: string }): void => {
    setClaimant(item.id);
    setClaimantVisible(false);
  };

  const [claimCause, setClaimCause] = useState('Illness');
  const [claimCauseOverlay, setClaimCauseOverlay] = useState(false);
  const onClaimCauseSelection = (item: { id: string; label: string }): void => {
    setClaimCause(item.id);
    setClaimCauseOverlay(false);
  };

  const [visitDate, setVisitDate] = useState(new Date());
  const [visitDateOverlay, setVisitDateOverlay] = useState(false);

  const [description, setDescription] = useState('');
  const [symptom, setSymptom] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [remarks, setRemarks] = useState('');

  return (
    <MakeClaimView
      title={`Claim Details`}
      currentRoute={routes.ClaimInfoScreen}
      nextDisabled={!claimType}
      navigation={navigation}
      backVisible
    >
      <View
        style={{
          display: 'flex',
          margin: 20
        }}
      >
        <View
          style={{
            display: 'flex'
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              marginBottom: 10
            }}
          >
            Claimant
          </Text>
          <TouchableOpacity onPress={(): void => setClaimantVisible(true)}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                padding: 10,
                marginBottom: 10,
                marginTop: 10,
                backgroundColor: '#fff',
                borderRadius: 5,
                borderWidth: 1,
                borderColor: '#E6EAEE'
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flex: 3,
                  flexDirection: 'column',
                  marginLeft: 10
                }}
              >
                <Text
                  style={{
                    fontSize: 15,
                    fontWeight: 'bold',
                    marginBottom: 5
                  }}
                >
                  {claimant || 'Select Claimant'}
                </Text>
              </View>
              <Icon size={32} color="#68737A" name="arrow-drop-down" />
            </View>
          </TouchableOpacity>
          <View>
            <Modal visible={claimantVisible}>
              <SwitchList items={CLAIMANT_LIST} selectedItem={claimant} onPress={onClaimantSelect} />
            </Modal>
          </View>
        </View>

        <View
          style={{
            display: 'flex'
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              marginBottom: 10
            }}
          >
            Date of visit
          </Text>
          <TouchableOpacity onPress={(): void => setVisitDateOverlay(true)}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                padding: 10,
                marginBottom: 10,
                marginTop: 10,
                backgroundColor: '#fff',
                borderRadius: 5,
                borderWidth: 1,
                borderColor: '#E6EAEE'
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flex: 3,
                  flexDirection: 'column',
                  marginLeft: 10
                }}
              >
                <Text
                  style={{
                    fontSize: 15,
                    fontWeight: 'bold',
                    marginBottom: 5
                  }}
                >
                  {format(visitDate, 'dd/MM/yyyy')}
                </Text>
              </View>
              <Icon size={26} color="#68737A" name="calendar" type="font-awesome" />
            </View>
          </TouchableOpacity>
          <Modal visible={visitDateOverlay}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                marginTop: 20
              }}
            >
              <DatePicker mode="date" date={visitDate} onDateChange={setVisitDate} />
              <Button
                title="Done"
                containerStyle={{
                  alignSelf: 'center',
                  minWidth: 100,
                  margin: 0,
                  marginStart: 0,
                  marginEnd: 0,
                  marginTop: 20,
                  marginBottom: 10
                }}
                titleStyle={{
                  color: '#FFFFFF',
                  fontSize: 15
                }}
                buttonStyle={{
                  padding: 10,
                  backgroundColor: '#ED1B2E',
                  borderRadius: 30
                }}
                onPress={(): void => setVisitDateOverlay(false)}
              />
            </View>
          </Modal>
        </View>

        <View
          style={{
            display: 'flex'
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              marginBottom: 10
            }}
          >
            Cause of claim
          </Text>
          <TouchableOpacity onPress={(): void => setClaimCauseOverlay(true)}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                padding: 10,
                marginBottom: 10,
                marginTop: 10,
                backgroundColor: '#fff',
                borderRadius: 5,
                borderWidth: 1,
                borderColor: '#E6EAEE'
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flex: 3,
                  flexDirection: 'column',
                  marginLeft: 10
                }}
              >
                <Text
                  style={{
                    fontSize: 15,
                    fontWeight: 'bold',
                    marginBottom: 5
                  }}
                >
                  {claimCause}
                </Text>
              </View>
              <Icon size={32} color="#68737A" name="arrow-drop-down" />
            </View>
          </TouchableOpacity>
          <View>
            <Modal visible={claimCauseOverlay}>
              <SwitchList items={CLAIM_CAUSE_LIST} selectedItem={claimCause} onPress={onClaimCauseSelection} />
            </Modal>
          </View>
        </View>

        <View
          style={{
            display: 'flex'
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              marginBottom: 10
            }}
          >
            Describe the illness
          </Text>
          <View
            style={{
              marginBottom: 20
            }}
          >
            <PruInput value={description} placeholder={`Description`} onChangeText={setDescription} numberOfLines={3} />
          </View>
        </View>

        <View
          style={{
            display: 'flex'
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              marginBottom: 10
            }}
          >
            Describe when you first experienced your symptoms
          </Text>
          <View
            style={{
              marginBottom: 20
            }}
          >
            <PruInput value={symptom} placeholder={`Description`} onChangeText={setSymptom} numberOfLines={3} />
          </View>
        </View>

        <View
          style={{
            display: 'flex'
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              marginBottom: 10
            }}
          >
            Diagnosis
          </Text>
          <View
            style={{
              marginBottom: 20
            }}
          >
            <PruInput value={diagnosis} placeholder={`Diagnosis`} onChangeText={setDiagnosis} />
          </View>
        </View>

        <View
          style={{
            display: 'flex'
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              marginBottom: 10
            }}
          >
            Remarks
          </Text>
          <View
            style={{
              marginBottom: 100
            }}
          >
            <PruInput value={remarks} placeholder={`Remarks`} onChangeText={setRemarks} />
          </View>
        </View>
      </View>
    </MakeClaimView>
  );
};

export default ClaimDetailScreen;
