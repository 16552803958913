import { RootState } from '../../store';
import { Claim, ClaimState } from '../../types/claim';

export const getLoading = (state: RootState): boolean => state.ReviewClaim.loading;

export const getClaimDetail = (state: RootState): Claim =>
  state.ReviewClaim.claims[state.ReviewClaim.currentSelectedClaimIndex];

export const getClaims = (state: RootState): Claim[] => Object.values(state.ReviewClaim.claims);

export const getClaimFilter = (state: RootState): ClaimState | undefined => state.ReviewClaim.claimFilter;

export const getFetchError = (state: RootState): string | undefined => state.ReviewClaim.error;
