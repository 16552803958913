import React, { useEffect } from 'react';
import Config from '../../components/Config';

import { PulseUser } from '../../types/user';
import { Locale } from '../../types/sdk';
import i18n, { initI18n } from '../../i18n';
import i18next from 'i18next';
import localeLocal from '../../i18n/locale.json';

interface Props {
  children: React.ReactNode;
  locale?: Locale;
  i18nReady?: boolean;
  pulseUser?: PulseUser;
  storedPulseUser?: PulseUser;
  setI18nReady: (ready: boolean) => void;
  setPulseUser: (pulseUser: PulseUser) => void;
  setSDKLoading: (ready: boolean) => void;
  setSDKLocale: (locale: Locale) => void;
  clearRefreshToken: () => void;
}

/**
 * Hyrdates the local redux store with data provided by the host application
 */
const StoreHydrator: React.FC<Props> = ({
  children,
  locale = 'en',
  i18nReady,
  pulseUser,
  storedPulseUser,
  setI18nReady,
  setPulseUser,
  setSDKLoading,
  clearRefreshToken,
  setSDKLocale
}) => {
  // Update i18n locale when it changes
  useEffect(() => {
    if (i18nReady) {
      i18n.changeLanguage(locale);
    }
    setSDKLocale(locale);
  }, [i18nReady, locale]);

  // Initialise the common i18n instance if not already created
  useEffect(() => {
    if (!i18nReady) {
      initI18n({
        initialLocale: locale,
        apiKey: Config.EB_SME_SDK_LOCIZE_API_KEY,
        projectId: Config.EB_SME_SDK_LOCIZE_PROJECT_KEY
      })
        .then(() => {
          i18next.addResourceBundle('en', 'app_ebsme_pulse', localeLocal.en, true, false);

          setI18nReady(true);
        })
        .catch((err) => {
          i18next.addResourceBundle('en', 'app_ebsme_pulse', localeLocal.en, true, false);

          console.warn('i18n initialisation error:', err);
        });
    }
  }, [i18nReady, locale, setI18nReady]);

  // Hydrate store with pulseUser from host app
  useEffect(() => {
    if (pulseUser) {
      //If currentPulseUser stored in perist db then we need check and clear out session if user is different before set new value for pulseUser in state
      if (storedPulseUser && storedPulseUser.loginTimestamp != pulseUser.loginTimestamp) {
        clearRefreshToken();
      }

      setPulseUser(pulseUser);
    }
  }, [clearRefreshToken, storedPulseUser, pulseUser, setPulseUser]);

  // Show the loader when i18n isn't ready
  useEffect(() => {
    setSDKLoading(!i18nReady);
  }, [setSDKLoading, i18nReady]);

  if (!i18nReady) {
    return null;
  }

  return <>{children}</>;
};

export default StoreHydrator;
