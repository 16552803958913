import styled from 'styled-components/native';

import { getShadowStyles } from '../../assets/mixins';

const cardShadow = getShadowStyles();

export const Card = styled.View`
  width: 100%;
  padding: 16px;
  margin: 7px 0;
  background: #ffffff;
  border-radius: 8px;
  ${cardShadow};
`;

export const CardHeader = styled.TouchableOpacity`
  flex-direction: row;
`;

export const CardHeaderLeft = styled.View`
  flex: 1 0;
`;

export const CardHeaderRight = styled.View`
  margin-left: 10px;
`;

export const CardBody = styled.View`
  width: 100%;
`;

export const Separator = styled.View`
  height: 0.5px;
  background-color: #68737a;
  margin: 15px 0 0 0;
`;

export const CardHeaderTitle = styled.Text`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #20365a;
  margin-bottom: 4px;
`;

export const CollapseIcon = styled.View`
  width: 24px;
  height: 24px;
  background: #1b365d;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
`;

export const CollapseIconImage = styled.Image`
  resize-mode: center;
  width: 12px;
  height: 12px;
`;
