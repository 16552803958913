import React from 'react';
import { Alert, View, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import * as routes from '../../routes';
import { StackNavigationProp } from '../../types/navigation';
import Screen from '../../components/Screen';
import { getNavigate } from '../../utils/navigation';
import { PruCard, PruText } from '@ebsme/pulse-rn-components';
import StatusText from '../../components/StatusText';
import { EyeOutline, ShareSocialOutline } from '../../assets/svgs/icons';
import IconListTile from '../../components/IconListTile';
import { LogRequest } from '../../types/logRequest';
import ShadedContainer from '../../components/ShadedContainer';
import { sharePdf } from '../../utils/shareUtil';

type FieldProps = {
  title: string;
  value: string;
  style?: ViewStyle;
};

const Row: React.FC<FieldProps> = ({ title, value, style }) => {
  return (
    <View style={[{ flexDirection: 'row' }, style]}>
      <PruText style={{ flex: 1 }}>{title}</PruText>
      <PruText weight="bold" color="black" style={{ flex: 1, textAlign: 'right' }}>
        {value}
      </PruText>
    </View>
  );
};

const Column: React.FC<FieldProps> = ({ title, value, style }) => {
  return (
    <PruCard style={style}>
      <PruText style={{ flex: 1 }} color="black">
        {title}
      </PruText>
      <PruText weight="bold" color="black">
        {value}
      </PruText>
    </PruCard>
  );
};

interface NavigationProp extends StackNavigationProp {
  state: {
    params: {
      item: LogRequest;
    };
  };
}

type Props = {
  navigation: NavigationProp;
};

const LogDetailsScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();
  const {
    ref,
    submissionDate,
    status,
    statusText,
    symptomDescription,
    patient,
    hospital,
    diagnosis,
    symptomDate,
    admissionDate,
    questions,
    downloadUrl
  } = navigation.state.params?.item || {};

  return (
    <Screen heading={hospital.name} headerPaddingHorizontal={24} onPressBack={(): boolean => navigation.goBack()}>
      <ShadedContainer style={{ marginBottom: 48 }}>
        <View style={{ flexDirection: 'row', marginTop: 24 }}>
          <Column
            title={t('LETTER_OF_GUARANTEE__LOG_DETAILS__LOG_REF_NO')}
            value={ref}
            style={{ marginStart: 24, marginEnd: 12, marginVertical: 8, flex: 1, minHeight: 100 }}
          />
          <Column
            title={t('LETTER_OF_GUARANTEE__LOG_DETAILS__LOG_SUBMISSION_DATE')}
            value={submissionDate && format(new Date(symptomDate), 'dd/MM/yyyy')}
            style={{ marginEnd: 24, marginStart: 12, marginVertical: 8, flex: 1, minHeight: 100 }}
          />
        </View>
        <PruCard style={{ flexDirection: 'row', marginHorizontal: 24, marginVertical: 8 }}>
          <PruText color="black" style={{ flex: 1 }}>
            {t('LETTER_OF_GUARANTEE__LOG_DETAILS__STATUS')}
          </PruText>
          <StatusText status={status} statusText={statusText} />
        </PruCard>
        <PruCard style={{ marginHorizontal: 24, marginVertical: 8 }}>
          <Row title={t('LETTER_OF_GUARANTEE__LOG_DETAILS__PATIENT_NAME')} value={patient.name} />
          <Row title={t('LETTER_OF_GUARANTEE__LOG_DETAILS__HOSPITAL')} value={hospital.name} style={{ marginTop: 8 }} />
          <Row
            title={t('LETTER_OF_GUARANTEE__REQUEST_LOG__SYMPTOMS_STARTED_DATE')}
            value={symptomDate && format(new Date(symptomDate), 'dd/MM/yyyy')}
            style={{ alignItems: 'flex-end', marginTop: 8 }}
          />
          <Row
            title={t('LETTER_OF_GUARANTEE__REQUEST_LOG__ADMISSION_DATE')}
            value={symptomDate && format(new Date(admissionDate), 'dd/MM/yyyy')}
            style={{ alignItems: 'flex-end', marginTop: 8 }}
          />
        </PruCard>
        <Column
          title={t('LETTER_OF_GUARANTEE__REQUEST_LOG__DIAGNOSIS')}
          value={diagnosis.name}
          style={{ marginHorizontal: 24, marginVertical: 8 }}
        />
        <Column
          title={t('LETTER_OF_GUARANTEE__REQUEST_LOG__SYMPTOMS_DESCRIPTION')}
          value={symptomDescription}
          style={{ marginHorizontal: 24, marginVertical: 8 }}
        />
        {Boolean(questions.length) && (
          <PruCard style={{ marginHorizontal: 24, marginVertical: 8 }}>
            {questions.map(({ key, answer }) => (
              <Row
                key={key}
                title={t('LETTER_OF_GUARANTEE__REQUEST_LOG__' + key)}
                value={answer ? t('OPTION__YES') : t('OPTION__NO')}
                style={{ alignItems: 'flex-end', marginTop: 8 }}
              />
            ))}
          </PruCard>
        )}

        <View style={{ marginTop: 16, marginBottom: 16, marginHorizontal: 24 }}>
          <IconListTile
            showArrow={false}
            title={t('LETTER_OF_GUARANTEE__LOG_DETAILS__VIEW_DOCUMENT')}
            icon={<EyeOutline width="30" height="30" style={{ marginRight: 24 }} />}
            onPress={(): void => {
              if (downloadUrl) {
                getNavigate(navigation.navigate, routes.ViewPdfLog, { uri: downloadUrl })();
              } else {
                Alert.alert('Letter of Guarantee is not available.');
              }
            }}
          />
          <IconListTile
            showArrow={false}
            title={t('LETTER_OF_GUARANTEE__LOG_DETAILS__SHARE_DOCUMENT')}
            icon={<ShareSocialOutline width="30" height="30" style={{ marginRight: 24 }} />}
            onPress={(): void => {
              if (downloadUrl) {
                sharePdf(downloadUrl, `LetterOfGuarantee_${ref}`);
              } else {
                Alert.alert('Letter of Guarantee is not available.');
              }
            }}
          />
        </View>
      </ShadedContainer>
    </Screen>
  );
};

export default LogDetailsScreen;
