import { MaritalStatus } from '../../types/employee';
import { CorporateUser, PulseConfig } from '../../types/user';
import {
  CLEAR_CORPORATE_USER,
  CorporateUserAction,
  FETCH_CORP_USER_FAILED,
  FETCH_CORP_USER_NOT_FOUND,
  FETCH_CORP_USER_START,
  FETCH_CORP_USER_SUCCESS,
  REGISTER_CORP_USER_FAILED,
  REGISTER_CORP_USER_START,
  REGISTER_CORP_USER_SUCCESS,
  UPDATE_CORP_USER,
  CLEAR_REGISTER_ERROR,
  FETCH_PULSE_CONFIG_START,
  FETCH_PULSE_CONFIG_SUCCESS,
  FETCH_PULSE_CONFIG_FAILED,
  FORGET_CREDENTIALS_START,
  FORGET_CREDENTIALS_SUCCESS,
  FORGET_CREDENTIALS_FAILED
} from './types';

export type { CorporateUserAction };

interface OnboardingState {
  email: string;
  phone: string;
  nationalId: string;
  corporateUser?: CorporateUser;
  authCodeRef?: string;
  fetchingCorporateUser: boolean;
  corporateUserNotFound: boolean;
  fetchCorporateUserError?: string;
  registeringCorporateUser: boolean;
  registerCorporateUserError?: string;
  maritalStatus?: MaritalStatus;
  pulseConfig?: PulseConfig;
  loadingPulseConfig: boolean;
  fetchPulseConfigError?: string;
  requestingForgetCredentials: boolean;
  requestForgetCredentailsError?: boolean;
}

export const initialState: OnboardingState = {
  email: '',
  phone: '',
  nationalId: '',
  corporateUser: undefined,
  authCodeRef: undefined,
  fetchingCorporateUser: false,
  corporateUserNotFound: false,
  registeringCorporateUser: false,
  pulseConfig: undefined,
  loadingPulseConfig: false,
  requestingForgetCredentials: false,
  requestForgetCredentailsError: false
};

const CorporateUserReducer = (state: OnboardingState = initialState, action: CorporateUserAction): OnboardingState => {
  switch (action.type) {
    case FETCH_CORP_USER_START:
      return {
        ...state,
        fetchingCorporateUser: true,
        fetchCorporateUserError: undefined
      };

    case FETCH_CORP_USER_SUCCESS:
      return {
        ...state,
        fetchingCorporateUser: false,
        corporateUser: action.payload
      };

    case FETCH_CORP_USER_FAILED:
      return {
        ...state,
        fetchingCorporateUser: false,
        fetchCorporateUserError: action.payload
      };

    case FETCH_CORP_USER_NOT_FOUND:
      return {
        ...state,
        fetchingCorporateUser: false,
        corporateUserNotFound: true
      };

    case REGISTER_CORP_USER_START:
      return {
        ...state,
        registeringCorporateUser: true,
        registerCorporateUserError: undefined
      };

    case REGISTER_CORP_USER_SUCCESS:
      return {
        ...state,
        registeringCorporateUser: false,
        authCodeRef: action.payload
      };

    case REGISTER_CORP_USER_FAILED:
      return {
        ...state,
        registeringCorporateUser: false,
        registerCorporateUserError: action.payload
      };

    case UPDATE_CORP_USER:
      return {
        ...state,
        ...action.payload
      };

    case CLEAR_CORPORATE_USER:
      return {
        ...initialState,
        email: state.email
      };
    case CLEAR_REGISTER_ERROR:
      return {
        ...state,
        registerCorporateUserError: undefined
      };
    case FETCH_PULSE_CONFIG_START:
      return {
        ...state,
        loadingPulseConfig: true
      };
    case FETCH_PULSE_CONFIG_SUCCESS:
      return {
        ...state,
        loadingPulseConfig: false,
        pulseConfig: action.payload
      };
    case FETCH_PULSE_CONFIG_FAILED:
      return {
        ...state,
        loadingPulseConfig: false,
        fetchPulseConfigError: action.payload
      };
    case FORGET_CREDENTIALS_START:
      return {
        ...state,
        requestingForgetCredentials: true,
        requestForgetCredentailsError: undefined
      };
    case FORGET_CREDENTIALS_SUCCESS:
      return {
        ...state,
        requestingForgetCredentials: false,
        requestForgetCredentailsError: undefined
      };
    case FORGET_CREDENTIALS_FAILED:
      return {
        ...state,
        requestingForgetCredentials: false,
        requestForgetCredentailsError: action.payload
      };
    default:
      return state;
  }
};

export default CorporateUserReducer;
