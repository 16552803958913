import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';

import * as routes from './routes';

import ClaimListScreen from './ClaimListScreen';
import ClaimDetailScreen from './ClaimDetailScreen';

const routeConfigMap = {
  [routes.ClaimListScreen]: {
    screen: ClaimListScreen
  },
  [routes.ClaimDetailScreen]: {
    screen: ClaimDetailScreen
  }
};

const ReviewClaimNavigator = createAppContainer(
  createStackNavigator(routeConfigMap, {
    initialRouteName: routes.ClaimListScreen,
    headerMode: 'none'
  })
);

export default ReviewClaimNavigator;
