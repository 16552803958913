import React, { useEffect } from 'react';

import { StyleSheet, Image, Dimensions, Platform } from 'react-native';

import * as routes from '../../routes';
import { StackNavigationProp } from '../../types/navigation';
import { getNavigate } from '../../utils/navigation';

import Screen from '../../components/Screen';
import { ECareCard } from '../../types/policy';
import Swiper from '../../components/Swiper';
import { EVENT_ANALYTICS, logFirebaseEventScreen } from '../../utils/analytics';

const screenWidth = Dimensions.get('screen').width;
const imageWidth = Platform.OS === 'web' ? Math.min(480, screenWidth) : screenWidth;

interface Props {
  navigation: StackNavigationProp;
  eCareCards?: ECareCard[];
}

const styles = StyleSheet.create({
  wrapper: {},
  cardView: {
    marginRight: 20,
    marginLeft: 20
  },
  title: {
    fontSize: 18
  }
});

const ECardScreen: React.FC<Props> = ({ navigation, eCareCards }) => {
  useEffect(() => {
    logFirebaseEventScreen(EVENT_ANALYTICS.card, {
      feature: 'EcareCard',
      journey: 'ecare_card',
      stage: 'card_view',
      screen_id: 'SCR_EB_CARD_ECS',
      screen_name: 'ECardScreen'
    });
  }, []);
  return (
    <Screen
      heading={null}
      headingPosition="none"
      paddingHorizontal={0}
      onPressBack={getNavigate(navigation.navigate, routes.Landing)}
    >
      <Swiper
        items={eCareCards}
        renderItem={(item, index) => (
          <Image
            resizeMode="stretch"
            key={item.seq}
            style={{ width: imageWidth, height: 300 }}
            source={{ uri: `data:image/png;base64,${item.cardImagesBase64}` }}
          ></Image>
        )}
      />
    </Screen>
  );
};
export default ECardScreen;
