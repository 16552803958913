import { equals } from 'ramda';
import { RootState } from '../../store';
import { Claim, ClaimState, Consent } from '../../types/claim';

export const getClaim = (state: RootState): Claim => state.MakeClaim.claim;
export const getClaimState = (state: RootState): ClaimState | undefined => state?.MakeClaim?.claim?.claimState;
export const getLoading = (state: RootState): boolean => state.MakeClaim.loading;
export const getDocumentError = (state: RootState): { [key: string]: string } => state.MakeClaim.documentError;
export const getError = (state: RootState): string | null | undefined => state.MakeClaim.error;
export const getInitialClaim = (state: RootState): Claim | null => state.MakeClaim.initialClaim;
export const hasChanged = (state: RootState): boolean => !equals(getClaim(state), getInitialClaim(state));
export const isDraftClaim = (state: RootState): boolean => getClaimState(state) === 'DRAFT';
export const getConsents = (state: RootState): Consent[] | undefined | null => state.MakeClaim.consents;
