import { RootState } from '../../store';
import { RefreshTokenPayload } from '../../types/session';
import { isEmpty } from 'ramda';
import { jwtDecode } from '../../utils/jwt';

export const getRefreshToken = (state: RootState): string | undefined => state.Session.refreshToken;

export const getTokenTimestamp = (state: RootState): Date | undefined => state.Session.tokenTimestamp;

export const getValidatingOTP = (state: RootState): boolean => state.Session.validatingOTP;

export const getValidateOTPError = (state: RootState): string | undefined => state.Session.validateOTPError;

export const back2PulseValue = (state: RootState): boolean | undefined => state.Session.back2Pulse;

export const getFetchingRefreshToken = (state: RootState): boolean => state.Session.fetchingRefreshToken;

export const getFetchRefreshTokenError = (state: RootState): string | undefined => state.Session.fetchRefreshTokenError;

export const getRefreshTokenPayload = (state: RootState): RefreshTokenPayload | undefined => {
  const token = getRefreshToken(state) || '';

  if (isEmpty(token)) {
    return undefined;
  }

  return jwtDecode<RefreshTokenPayload>(token);
};

export const getEmployeeId = (state: RootState): string | undefined => {
  const { userID } = getRefreshTokenPayload(state) || {};

  return userID;
};

export const getEmployeeEmail = (state: RootState): string | undefined => {
  const { mail } = getRefreshTokenPayload(state) || {};

  return mail;
};
