import { RootState } from '../../../store';
import Step1Screen from './Step1Screen';
import { updateClaim } from '../../../reducers/MakeClaimReducer/actions';
import { fetchDependants, getHospitalOrReason, getPulseUserConfig } from '../../../reducers/MyPolicyReducer/actions';
import {
  fetchBankAccounts,
  addBankAccountProcess,
  fetchBanks,
  removeBankAccountProcess,
  addBankAccountSuccess
} from '../../../reducers/BankAccountReducer/actions';
import {
  getBankAccountsLoading,
  getBankAccountsFetchError,
  getBankAccounts,
  getBanks
} from '../../../reducers/BankAccountReducer/selectors';
import { getLoading, getClaim } from '../../../reducers/MakeClaimReducer/selectors';
import {
  getPolicy,
  getPolicyId,
  getDependants,
  getClaimTypes,
  getHospitalAndReasons
} from '../../../reducers/MyPolicyReducer/selectors';
import { getPulseUser } from '../../../reducers/PulseUserReducer/selectors';
import withLBUSwitcher from '../../../hocs/withLBUSwitcher';
import { getSDKLocale } from '../../../reducers/SDKReducer/selectors';

const LBU_CONFIG = {
  SG: Step1Screen,
  TH: Step1Screen,
  PH: Step1Screen,
  MY: Step1Screen,
  MM: Step1Screen
};

export default withLBUSwitcher(
  LBU_CONFIG,
  (state: RootState) => {
    return {
      loading: getLoading(state),
      policy: getPolicy(state),
      policyId: getPolicyId(state),
      claim: getClaim(state),
      banks: getBanks(state),
      pulseUser: getPulseUser(state),
      dependants: getDependants(state),
      bankAccounts: getBankAccounts(state),
      loadingBank: getBankAccountsLoading(state),
      fetchError: getBankAccountsFetchError(state),
      claimTypes: getClaimTypes(state),
      locale: getSDKLocale(state),
      hospitalAndReasonsList: getHospitalAndReasons(state)
    };
  },
  {
    updateClaim,
    fetchBankAccounts,
    addBankAccountProcess,
    fetchBanks,
    fetchDependants,
    removeBankAccountProcess,
    getPulseUserConfig,
    getHospitalOrReason,
    addBankAccountSuccess
  }
);
