import React, { useEffect } from 'react';
import { StackNavigationProp } from '../../../types/navigation';
import { UserCountryCode, PulseConfig } from '../../../types/user';
import ReviewClaimView from '../ReviewClaimView';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Claim } from '../../../types/claim';
import { getDateFormat, isShowDetailReason } from '../../../utils/claim';

import ClaimMetaItem from '../../../components/Claim/ClaimMetaItem';
import { EVENT_ANALYTICS, logFirebaseEventScreen } from '../../../utils/analytics';
import { useLBUTranslation } from '../../../hooks/useLBUTranslation';

export interface Props {
  lbu: UserCountryCode;
  navigation: StackNavigationProp;
  claimDetail: Claim;
  policyId: string;
  pulseConfig?: PulseConfig;
}

const ClaimDetailTHScreen: React.FC<Props> = ({ lbu, claimDetail, navigation, pulseConfig }) => {
  const { t } = useTranslation();
  const { tLBU } = useLBUTranslation(lbu);

  useEffect(() => {
    logFirebaseEventScreen(EVENT_ANALYTICS.review_claim, {
      feature: 'ReviewClaim',
      journey: 'review_claim',
      stage: 'review_detail',
      screen_id: 'SCR_EB_REVIEW_CDS',
      screen_name: 'ClaimDetailScreen',
      status: claimDetail.claimState,
      claim_type: claimDetail.claimType
    });
  }, []);
  const { claimType, claimDate, lbuClaimReference, policyNumber, incidentContent, paidAmount, insuredName } =
    claimDetail;

  const dateFormat = getDateFormat(lbu);

  const label_claim_type =
    claimType == 'MEDICAL'
      ? tLBU('CLAIM_TYPE_LABEL.OPD', { fallbackValue: 'OPD' })
      : tLBU('CLAIM_TYPE_LABEL.IPD', { fallbackValue: 'IPD' });
  const reason_hospital = tLBU(`CLAIM_REASON_HOSPITALIZATION.${incidentContent?.toUpperCase()}`, {
    fallbackValue: ' '
  });
  return (
    <ReviewClaimView navigation={navigation} backVisible scrollView>
      <ClaimMetaItem
        label={t('REVIEW_CLAIMS__CLAIM_TYPE')}
        value={isShowDetailReason(incidentContent) ? `${label_claim_type} / ${reason_hospital}` : label_claim_type}
        textSize={24}
      />
      {pulseConfig?.FEATURE_TOGGLE.showPolicyNo && (
        <ClaimMetaItem label={t('CLAIMS__POLICY_NUMBER__TITLE')} value={policyNumber || ''} />
      )}
      {pulseConfig?.FEATURE_TOGGLE.showInsuredName && (
        <ClaimMetaItem label={tLBU('CLAIMS__INSURED_NAME__TITLE', { fallbackValue: ' ' })} value={insuredName || ''} />
      )}
      <ClaimMetaItem label={t('REVIEW_CLAIMS__CLAIM_DATE')} value={moment(claimDate).format(dateFormat) || ''} />
      <ClaimMetaItem
        label={tLBU('CLAIMS__PAID_AMOUNT__TITLE', { fallbackValue: ' ' })}
        value={paidAmount?.toLocaleString('en-US') || ''}
      />

      <ClaimMetaItem label={t('REVIEW_CLAIMS__CLAIM_NUMBER')} value={lbuClaimReference || ''} />
    </ReviewClaimView>
  );
};

export default ClaimDetailTHScreen;
