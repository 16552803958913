import React, { FunctionComponent, ReactElement } from 'react';
import { FlatList } from 'react-native';

import BankAccountListItem from './BankAccountListItem';
import { BankAccount } from '../../types/bankAccount';

interface Props {
  data: BankAccount[];
  ListHeaderComponent?: FunctionComponent;
  testID?: string;
  onSelect?: (bank: BankAccount) => void;
  onRemove?: (bank: BankAccount) => void;
  bankSelected?: string;
}

const BankAccountList: React.FC<Props> = ({ testID, ListHeaderComponent, data, bankSelected, onSelect, onRemove }) => {
  return (
    <FlatList
      testID={testID}
      data={data}
      ListHeaderComponent={ListHeaderComponent}
      keyExtractor={(item: BankAccount): string => item.id || item.accountNumber || ''}
      renderItem={({ item }): ReactElement => (
        <BankAccountListItem
          key={item.id || item.accountNumber}
          item={item}
          bankSelected={bankSelected}
          onSelect={onSelect}
          onRemove={onRemove}
        />
      )}
    />
  );
};

export default BankAccountList;
