import { FeatureToggle } from '../../types/featureToggle';
import { GeneralConfig } from '../../types/generalConfig';
import { LBUConfig } from '../../types/lbuConfig';
import { SupportInfo } from '../../types/supportInfo';
import {
  ClaimTypeOption,
  ClaimDocumentConfig,
  ClaimViewConfig,
  PaymentOption,
  ClaimDateConfig
} from './../../types/claim';
import * as GRAPHQL from './mm/graphql';
import * as REQUEST_PICKER from './mm/requestPicker';
import { NATIONALIDCHECK } from './mm/nationalId';

const CLAIM_TYPES: ClaimTypeOption[] = [
  {
    id: 'death-claim',
    label: 'Death Claim',
    documents: [
      'medicalCertificateResults',
      'policyCertificate',
      'beneficiaryCard',
      'policyHolderCard',
      'policeReport',
      'deceasedNRIC'
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    groupPlans: ['S00403'],
    originalRequired: false,
    isDefault: true
  },
  {
    id: 'accident',
    label: 'Accident',
    documents: [
      'medicalCertificateResults',
      'policyCertificate',
      'beneficiaryCard',
      'policyHolderCard',
      'policeReport',
      'deceasedNRIC'
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    groupPlans: ['S00403'],
    originalRequired: false,
    isDefault: false
  },
  {
    id: 'tpd',
    label: 'TPD',
    documents: [
      'medicalCertificateResults',
      'policyCertificate',
      'beneficiaryCard',
      'policyHolderCard',
      'policeReport',
      'deceasedNRIC'
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    groupPlans: ['S00403'],
    originalRequired: false,
    isDefault: false
  },
  {
    id: 'disability',
    label: 'Disability',
    documents: [
      'medicalCertificateResults',
      'policyCertificate',
      'beneficiaryCard',
      'policyHolderCard',
      'policeReport',
      'deceasedNRIC'
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    groupPlans: ['S00403'],
    originalRequired: false,
    isDefault: false
  },
  {
    id: 'hospitalization',
    label: 'Hospitalization',
    documents: [
      'medicalCertificateResults',
      'policyCertificate',
      'beneficiaryCard',
      'policyHolderCard',
      'policeReport',
      'deceasedNRIC'
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'injury',
        label: 'Injury'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    groupPlans: ['S00484'],
    originalRequired: false,
    isDefault: false
  },
  {
    id: 'outpatient',
    label: 'Outpatient',
    documents: [
      'medicalCertificateResults',
      'policyCertificate',
      'beneficiaryCard',
      'policyHolderCard',
      'policeReport',
      'deceasedNRIC'
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    groupPlans: ['S00484'],
    originalRequired: false,
    isDefault: false
  },
  {
    id: 'miscarriage',
    label: 'Miscarriage',
    documents: [
      'medicalCertificateResults',
      'policyCertificate',
      'beneficiaryCard',
      'policyHolderCard',
      'policeReport',
      'deceasedNRIC'
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    groupPlans: ['S00484'],
    originalRequired: false,
    isDefault: false
  },
  {
    id: 'surgery',
    label: 'Surgery',
    documents: [
      'medicalCertificateResults',
      'policyCertificate',
      'beneficiaryCard',
      'policyHolderCard',
      'policeReport',
      'deceasedNRIC'
    ],
    dates: ['claimDate', 'accidentDate', 'admissionDate', 'dischargeDate'],
    reasons: [
      {
        id: 'accident',
        label: 'Accident'
      },
      {
        id: 'illness',
        label: 'Illness'
      },
      {
        id: 'others',
        label: 'Others'
      }
    ],
    groupPlans: ['S00484'],
    originalRequired: false,
    isDefault: false
  }
];

const PAYMENT_OPTIONS: PaymentOption[] = [
  {
    id: 'giro',
    label: 'Giro',
    isRequiredBankAccount: true
  }
];

const DATES: ClaimDateConfig = {
  claimDate: {
    dateType: 'claimDate',
    title: 'CLAIMS__CLAIM_DETAILS_REQUEST_DATE',
    isOptional: false,
    readOnly: true
  },
  accidentDate: {
    dateType: 'accidentDate',
    title: 'CLAIMS__CLAIM_DETAILS_DATE_OF_ACCIDENT',
    isOptional: false,
    readOnly: false
  },
  admissionDate: {
    dateType: 'admissionDate',
    title: 'CLAIMS__CLAIM_DETAILS_DATE_OF_ADMISSION',
    isOptional: false,
    readOnly: false
  },
  dischargeDate: {
    dateType: 'dischargeDate',
    title: 'CLAIMS__CLAIM_DETAILS_DATE_OF_DISCHARGE',
    isOptional: false,
    readOnly: false
  }
};

const CLAIM_VIEW_CONFIG: ClaimViewConfig = {
  showOriginalReceiptRequired: true
};

const DOCUMENTS: ClaimDocumentConfig = {
  doc1: {
    docType: 'doc1',
    title: 'CLAIMS__DOCUMENTS__1',
    fileName: 'Original receipt and Billing statement',
    helpText: '',
    isOptional: false,
    originalRequired: false
  },
  doc2: {
    docType: 'doc2',
    title: 'CLAIMS__DOCUMENTS__2',
    fileName: 'Medical certificate',
    helpText: '',
    isOptional: false,
    originalRequired: false
  },
  doc3: {
    docType: 'doc3',
    title: 'CLAIMS__DOCUMENTS__3',
    fileName: 'Lab X-ray',
    helpText: '',
    isOptional: false,
    originalRequired: false
  },
  doc4: {
    docType: 'doc4',
    title: 'CLAIMS__DOCUMENTS__4',
    fileName: 'Other supporting documents',
    helpText: '',
    isOptional: true,
    originalRequired: false
  },
  doc5: {
    docType: 'doc5',
    title: 'CLAIMS__DOCUMENTS__5',
    fileName: 'Bank pass book',
    helpText: '',
    isOptional: false,
    originalRequired: false
  },
  medicalCertificate: {
    docType: 'medicalCertificate',
    title: 'CLAIMS__DOCUMENTS__MEDICAL_CERT',
    fileName: 'Medical Certificate',
    helpText: '',
    originalRequired: false
  },
  medicalExpense: {
    docType: 'medicalExpense',
    title: 'CLAIMS__DOCUMENTS__MEDICAL_EXPENSE',
    fileName: 'Medical Expense',
    helpText: '',
    originalRequired: true
  },
  medicalReceipt: {
    docType: 'medicalReceipt',
    title: 'CLAIMS__DOCUMENTS__MEDICAL_RECEIPT',
    fileName: 'Medical Receipt',
    helpText: '',
    originalRequired: true
  },
  medicalResult: {
    docType: 'medicalResult',
    title: 'CLAIMS__DOCUMENTS__MEDICAL_RESULT',
    fileName: 'Medical Result',
    helpText: '',
    originalRequired: false
  },
  bankTransfer: {
    docType: 'bankTransfer',
    title: 'CLAIMS__DOCUMENTS__BANK_TRANSFER',
    fileName: 'Book Bank',
    helpText: 'CLAIMS__DOCUMENTS__BANK_TRANSFER__HELPTEXT',
    originalRequired: false
  }
};

const SUPPORT_INFO: SupportInfo = {
  emails: ['claims@prudential.com.mm'],
  phones: ['(+95) 977 011 0010'],
  submitTicketUrl: '',
  serviceStatusUrl: '',
  applicationHelpUrl: 'https://www.prudential.com.mm/en/',
  contactSupport: 'https://www.prudential.com.mm/en/'
};

const API_URL = {
  dev: 'https://uat-api.eb.prudential.com.mm/graphql/pulse-app',
  uat: 'https://uat-api.eb.prudential.com.mm/graphql/pulse-app',
  prod: 'https://api.eb.prudential.com.mm/graphql/pulse-app'
};

const FEATURE_TOGGLE: FeatureToggle = {
  enabledSaveDraft: false
};

const MY_POLICY = {
  dateFormat: 'DD/MM/YYYY',
  lBUCode: 'MM',
  isShowDependantView: true
};

const GENERAL: GeneralConfig = {
  globalNamespace: 'global_pmli' //TODO: doesn't exist yet
};

export const MM: LBUConfig = {
  API_URL,
  CLAIM_TYPES,
  CLAIM_VIEW_CONFIG,
  DOCUMENTS,
  SUPPORT_INFO,
  FEATURE_TOGGLE,
  MY_POLICY,
  GRAPHQL,
  REQUEST_PICKER,
  GENERAL,
  NATIONALIDCHECK,
  PAYMENT_OPTIONS,
  DATES
};
