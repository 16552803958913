import { TH } from './TH';
import { PH } from './PH';
import { SG } from './SG';
import { MY } from './MY';
import { MM } from './MM';
import { UserCountryCode } from '../../types/user';
import { LBUConfig } from '../../types/lbuConfig';

export const LBU: {
  [key in UserCountryCode]: LBUConfig;
} = {
  MY,
  TH,
  PH,
  SG,
  MM
};
