/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ActivityIndicator,
  FlatList,
  FlatListProps,
  ListRenderItemInfo,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleProp,
  View,
  ViewStyle,
  Platform,
  Keyboard,
  Text,
  Image,
  SafeAreaView,
  Dimensions
} from 'react-native';
import React, { ReactElement, useRef } from 'react';

const { height, width } = Dimensions.get('window');
const screenHeight = height;
const screenWidth = width;
const loadingMoreWhenMarginBottom = screenHeight / 3;
const loadingMoreWhenMarginRight = screenWidth / 2;

/**
 * ScrollView listen onEndReach
 */
const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: NativeScrollEvent): boolean => {
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - loadingMoreWhenMarginBottom;
};

const isCloseToRight = ({ layoutMeasurement, contentOffset, contentSize }: NativeScrollEvent): boolean => {
  return layoutMeasurement.width + contentOffset.x >= contentSize.width - loadingMoreWhenMarginRight;
};

const loaderVertical: StyleProp<ViewStyle> = {
  alignItems: 'center',
  marginTop: 10
};

interface Props<T> extends Partial<FlatListProps<T>> {
  data: T[];
  renderItem: (info: ListRenderItemInfo<T>) => React.ReactElement | null;
  onEndReachedCustom?: () => void;
  onPullToRefresh?: () => void;
  isShowLoadMore?: boolean;
  refreshing?: boolean;
  keyExtractor: (item: T, index: number) => string;
  dismissOnDrag?: boolean;
}

function FlatListLoadMore<T>(props: Props<T>): ReactElement {
  const renderKeyExtractor = (item: any, index: any) => (item.id ? item.id.toString() : index.toString());
  const isDuringScroll = useRef(false);
  const onMomentumScrollBegin = (): void => {
    isDuringScroll.current = true;
  };

  const handleOnScroll = ({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>): void => {
    if (props.onEndReachedCustom && isCloseToBottom(nativeEvent) && isDuringScroll.current) {
      const currentOffset = nativeEvent.contentOffset.y;
      const direction = currentOffset > 0 ? 'down' : 'up';
      if ((!props.refreshing || !props.isShowLoadMore) && direction === 'down') props.onEndReachedCustom();
      isDuringScroll.current = false;
    }
  };

  const renderFooter = () => {
    return (
      <View style={loaderVertical}>
        <ActivityIndicator size={'small'} color={'gray'} />
      </View>
    );
  };

  const keyboardDismissProp =
    Platform.OS === 'ios' ? { keyboardDismissMode: 'on-drag' } : { onScrollBeginDrag: Keyboard.dismiss };

  const renderItemSeparatorComponent = () => {
    return <View style={{ height: 10 }} />;
  };
  const renderEmptyComponent = () => {
    return null;
  };
  return (
    <FlatList
      {...props}
      data={props.data}
      extraData={props.data}
      keyExtractor={renderKeyExtractor}
      onEndReachedThreshold={0.5}
      scrollEventThrottle={400}
      // onScroll={handleOnScroll}
      onRefresh={props.onPullToRefresh}
      // onMomentumScrollBegin={onMomentumScrollBegin}
      onEndReached={props.onEndReachedCustom}
      ListFooterComponent={props.isShowLoadMore ? renderFooter : undefined}
      // {...(props.dismissOnDrag ? keyboardDismissProp : {})}
      // ItemSeparatorComponent={renderItemSeparatorComponent}
      // ListEmptyComponent={renderEmptyComponent}
    />
  );
}

export default FlatListLoadMore;
