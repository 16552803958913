import React, { SVGProps } from 'react';
import { TouchableHighlight, View, ViewProps } from 'react-native';

import { lightGrey } from '../../assets/colors';

import { PruText, PruCard } from '@ebsme/pulse-rn-components';

interface Props extends ViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SvgImage: React.FC<SVGProps<any>>;
  title?: string;
  description?: string;
  EndComponent?: React.ReactNode;
  onPress?: () => void;
}

const ActionableTile: React.FC<Props> = ({ SvgImage, EndComponent, title, description, onPress, style, testID }) => {
  return (
    <TouchableHighlight
      accessible={false}
      accessibilityLabel={title}
      accessibilityHint={description}
      accessibilityRole="button"
      onPress={onPress}
      disabled={!onPress}
      underlayColor={lightGrey}
      style={style}
      testID={testID}
    >
      <PruCard
        style={{
          flexDirection: 'row',
          padding: 18,
          borderRadius: 10,
          alignItems: 'center'
        }}
      >
        <SvgImage style={{ marginRight: 16 }} />
        <View style={{ flex: 1 }}>
          <PruText testID={`${testID}_Title`} marginBottom={4} weight="bold">
            {title}
          </PruText>
          {!!description && (
            <PruText testID={`${testID}_Description`} weight="thin">
              {description}
            </PruText>
          )}
        </View>
        {EndComponent}
      </PruCard>
    </TouchableHighlight>
  );
};

export default ActionableTile;
