import { ProductCatalogueInstance } from '../../../types/product';

import { Policy } from '../../../types/policy';
import { BoldColorOptions } from '../MyPolicyItem/MyPolicyItemStyles';

export enum CellType {
  ExpandableCard,
  ItemRow,
  PlanTitle,
  SectionTitle,
  DependantView,
  Benefit
}

export interface Trans {
  EN: string;
  TH: string;
}

export interface MyPolicyRenderObject {
  type: CellType;
  key: string;
  title?: Trans;
  subtitle?: string;
  label?: Trans;
  value?: string;
  highlighted?: boolean;
  boldColor?: BoldColorOptions;
  leadingNumber?: string;
  flex?: boolean;
  children?: MyPolicyRenderObject[];
  currency?: string;
  remainingItem?: BenefitItem;
}

export interface BenefitItem {
  name: Trans;
  stackValue: string;
  benefitType: string;
  coverageValue: string;
  valueUnit: string;
  leftStr: Trans;
  remainingValue: string;
  remainingUnit: Trans;
}

export interface MyPolicyRenderHelper {
  productCatalogue: ProductCatalogueInstance;
  policy: Policy;
  mapDependantView(): MyPolicyRenderObject;
  mapAllRows(): MyPolicyRenderObject[];
}

export class MyPolicyRenderHelperBase {
  productCatalogue: ProductCatalogueInstance;
  policy: Policy;

  constructor(productCatalogue: ProductCatalogueInstance, policy: Policy) {
    if (new.target === MyPolicyRenderHelperBase) {
      throw new TypeError('Cannot construct MyPolicyRenderHelperBase instances directly');
    }
    this.policy = policy;
    this.productCatalogue = productCatalogue;
  }

  mapDependantView = (): MyPolicyRenderObject => ({
    type: CellType.DependantView,
    key: 'MyPolicyCheckedItemKey',
    label: {
      EN: 'Dependants Included',
      TH: ''
    },
    highlighted: this.policy.allowDependants
  });
}
