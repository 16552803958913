const dataDiagnosis = [
  {
    code: 'R10',
    label: 'Abdominal and pelvic pain'
  },
  {
    code: 'R03',
    label: 'Abnormal blood-pressure reading, without diagnosis'
  },
  {
    code: 'R83',
    label: 'Abnormal findings in cerebrospinal fluid'
  },
  {
    code: 'R85',
    label: 'Abnormal findings in specimens from digestive organs and abdominal cavity'
  },
  {
    code: 'R87',
    label: 'Abnormal findings in specimens from female genital organs'
  },
  {
    code: 'R86',
    label: 'Abnormal findings in specimens from male genital organs'
  },
  {
    code: 'R89',
    label: 'Abnormal findings in specimens from other organs, systems and tissues'
  },
  {
    code: 'R84',
    label: 'Abnormal findings in specimens from respiratory organs and thorax'
  },
  {
    code: 'O28',
    label: 'Abnormal findings on antenatal screening of mother'
  },
  {
    code: 'R92',
    label: 'Abnormal findings on diagnostic imaging of breast'
  },
  {
    code: 'R90',
    label: 'Abnormal findings on diagnostic imaging of central nervous system'
  },
  {
    code: 'R91',
    label: 'Abnormal findings on diagnostic imaging of lung'
  },
  {
    code: 'R93',
    label: 'Abnormal findings on diagnostic imaging of other body structures'
  },
  {
    code: 'R25',
    label: 'Abnormal involuntary movements'
  },
  {
    code: 'R94',
    label: 'Abnormal results of function studies'
  },
  {
    code: 'R74',
    label: 'Abnormal serum enzyme levels'
  },
  {
    code: 'R06',
    label: 'Abnormalities of breathing'
  },
  {
    code: 'O62',
    label: 'Abnormalities of forces of labour'
  },
  {
    code: 'R26',
    label: 'Abnormalities of gait and mobility'
  },
  {
    code: 'R00',
    label: 'Abnormalities of heart beat'
  },
  {
    code: 'R71',
    label: 'Abnormality of red blood cells'
  },
  {
    code: 'R72',
    label: 'Abnormality of white blood cells, not elsewhere classified'
  },
  {
    code: 'K61',
    label: 'Abscess of anal and rectal regions'
  },
  {
    code: 'J85',
    label: 'Abscess of lung and mediastinum'
  },
  {
    code: 'N91',
    label: 'Absent, scanty and rare menstruation'
  },
  {
    code: 'L83',
    label: 'Acanthosis nigricans'
  },
  {
    code: 'V93',
    label: 'Accident on board watercraft without accident to watercraft, not causing drowning and submersion'
  },
  {
    code: 'V96',
    label: 'Accident to nonpowered aircraft causing injury to occupant'
  },
  {
    code: 'V95',
    label: 'Accident to powered aircraft causing injury to occupant'
  },
  {
    code: 'V90',
    label: 'Accident to watercraft causing drowning and submersion'
  },
  {
    code: 'V91',
    label: 'Accident to watercraft causing other injury'
  },
  {
    code: 'X45',
    label: 'Accidental poisoning by and exposure to alcohol'
  },
  {
    code: 'X41',
    label:
      'Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified'
  },
  {
    code: 'X42',
    label:
      'Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified'
  },
  {
    code: 'X40',
    label: 'Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics'
  },
  {
    code: 'X46',
    label: 'Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours'
  },
  {
    code: 'X49',
    label: 'Accidental poisoning by and exposure to other and unspecified chemicals and noxious substances'
  },
  {
    code: 'X44',
    label: 'Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances'
  },
  {
    code: 'X43',
    label: 'Accidental poisoning by and exposure to other drugs acting on the autonomic nervous system'
  },
  {
    code: 'X47',
    label: 'Accidental poisoning by and exposure to other gases and vapours'
  },
  {
    code: 'X48',
    label: 'Accidental poisoning by and exposure to pesticides'
  },
  {
    code: 'W75',
    label: 'Accidental suffocation and strangulation in bed'
  },
  {
    code: 'L70',
    label: 'Acne'
  },
  {
    code: 'Z89',
    label: 'Acquired absence of limb'
  },
  {
    code: 'Z90',
    label: 'Acquired absence of organs, not elsewhere classified'
  },
  {
    code: 'M20',
    label: 'Acquired deformities of fingers and toes'
  },
  {
    code: 'D59',
    label: 'Acquired haemolytic anaemia'
  },
  {
    code: 'D60',
    label: 'Acquired pure red cell aplasia [erythroblastopenia]'
  },
  {
    code: 'U57',
    label: 'Acrobatic sports'
  },
  {
    code: 'A42',
    label: 'Actinomycosis'
  },
  {
    code: 'I33',
    label: 'Acute and subacute endocarditis'
  },
  {
    code: 'F23',
    label: 'Acute and transient psychotic disorders'
  },
  {
    code: 'K35',
    label: 'Acute appendicitis'
  },
  {
    code: 'J21',
    label: 'Acute bronchiolitis'
  },
  {
    code: 'J20',
    label: 'Acute bronchitis'
  },
  {
    code: 'B15',
    label: 'Acute hepatitis A'
  },
  {
    code: 'B16',
    label: 'Acute hepatitis B'
  },
  {
    code: 'N17',
    label: 'Acute kidney failure'
  },
  {
    code: 'J04',
    label: 'Acute laryngitis and tracheitis'
  },
  {
    code: 'L04',
    label: 'Acute lymphadenitis'
  },
  {
    code: 'I21',
    label: 'Acute myocardial infarction'
  },
  {
    code: 'I40',
    label: 'Acute myocarditis'
  },
  {
    code: 'J00',
    label: 'Acute nasopharyngitis [common cold]'
  },
  {
    code: 'N00',
    label: 'Acute nephritic syndrome'
  },
  {
    code: 'J05',
    label: 'Acute obstructive laryngitis [croup] and epiglottitis'
  },
  {
    code: 'K85',
    label: 'Acute pancreatitis'
  },
  {
    code: 'I30',
    label: 'Acute pericarditis'
  },
  {
    code: 'J02',
    label: 'Acute pharyngitis'
  },
  {
    code: 'A80',
    label: 'Acute poliomyelitis'
  },
  {
    code: 'D62',
    label: 'Acute posthaemorrhagic anaemia'
  },
  {
    code: 'J01',
    label: 'Acute sinusitis'
  },
  {
    code: 'J03',
    label: 'Acute tonsillitis'
  },
  {
    code: 'N10',
    label: 'Acute tubulo-interstitial nephritis'
  },
  {
    code: 'J06',
    label: 'Acute upper respiratory infections of multiple and unspecified sites'
  },
  {
    code: 'Z45',
    label: 'Adjustment and management of implanted device'
  },
  {
    code: 'E25',
    label: 'Adrenogenital disorders'
  },
  {
    code: 'M83',
    label: 'Adult osteomalacia'
  },
  {
    code: 'J80',
    label: 'Adult respiratory distress syndrome'
  },
  {
    code: 'U64',
    label: 'Adventure sports'
  },
  {
    code: 'T78',
    label: 'Adverse effects, not elsewhere classified'
  },
  {
    code: 'U68',
    label: 'Aero sports'
  },
  {
    code: 'U58',
    label: 'Aesthetic activities'
  },
  {
    code: 'B56',
    label: 'African trypanosomiasis'
  },
  {
    code: 'Y55',
    label:
      'Agents primarily acting on smooth and skeletal muscles and the respiratory system causing adverse effects in therapeutic use'
  },
  {
    code: 'Y44',
    label: 'Agents primarily affecting blood constituents causing adverse effects in therapeutic use'
  },
  {
    code: 'Y52',
    label: 'Agents primarily affecting the cardiovascular system causing adverse effects in therapeutic use'
  },
  {
    code: 'Y53',
    label: 'Agents primarily affecting the gastrointestinal system causing adverse effects in therapeutic use'
  },
  {
    code: 'Y54',
    label:
      'Agents primarily affecting water-balance and mineral and uric acid metabolism causing adverse effects in therapeutic use'
  },
  {
    code: 'D70',
    label: 'Agranulocytosis'
  },
  {
    code: 'P25',
    label: 'Air leak syndrome originating in the perinatal period'
  },
  {
    code: 'J66',
    label: 'Airway disease due to specific organic dust'
  },
  {
    code: 'K70',
    label: 'Alcoholic liver disease'
  },
  {
    code: 'L23',
    label: 'Allergic contact dermatitis'
  },
  {
    code: 'L63',
    label: 'Alopecia areata'
  },
  {
    code: 'G30',
    label: "Alzheimer's disease"
  },
  {
    code: 'A06',
    label: 'Amoebiasis'
  },
  {
    code: 'E85',
    label: 'Amyloidosis'
  },
  {
    code: 'D55',
    label: 'Anaemia due to enzyme disorders'
  },
  {
    code: 'D63',
    label: 'Anaemia in chronic diseases classified elsewhere'
  },
  {
    code: 'Y70',
    label: 'Anaesthesiology devices associated with misadventures'
  },
  {
    code: 'Y48',
    label: 'Anaesthetics and therapeutic gases causing adverse effects in therapeutic use'
  },
  {
    code: 'Y45',
    label: 'Analgesics, antipyretics and anti-inflammatory drugs causing adverse effects in therapeutic use'
  },
  {
    code: 'L64',
    label: 'Androgenic alopecia'
  },
  {
    code: 'Q00',
    label: 'Anencephaly and similar malformations'
  },
  {
    code: 'I20',
    label: 'Angina pectoris'
  },
  {
    code: 'V80',
    label: 'Animal-rider or occupant of animal-drawn vehicle injured in transport accident'
  },
  {
    code: 'M45',
    label: 'Ankylosing spondylitis'
  },
  {
    code: 'A60',
    label: 'Anogenital herpesviral [herpes simplex] infection'
  },
  {
    code: 'Q11',
    label: 'Anophthalmos, microphthalmos and macrophthalmos'
  },
  {
    code: 'Z36',
    label: 'Antenatal screening'
  },
  {
    code: 'O46',
    label: 'Antepartum haemorrhage, not elsewhere classified'
  },
  {
    code: 'A22',
    label: 'Anthrax'
  },
  {
    code: 'Y46',
    label: 'Antiepileptics and antiparkinsonism drugs causing adverse effects in therapeutic use'
  },
  {
    code: 'R34',
    label: 'Anuria and oliguria'
  },
  {
    code: 'I71',
    label: 'Aortic aneurysm and dissection'
  },
  {
    code: 'L75',
    label: 'Apocrine sweat disorders'
  },
  {
    code: 'A96',
    label: 'Arenaviral haemorrhagic fever'
  },
  {
    code: 'I74',
    label: 'Arterial embolism and thrombosis'
  },
  {
    code: 'M14',
    label: 'Arthropathies in other diseases classified elsewhere'
  },
  {
    code: 'M18',
    label: 'Arthrosis of first carpometacarpal joint'
  },
  {
    code: 'Z93',
    label: 'Artificial opening status'
  },
  {
    code: 'B77',
    label: 'Ascariasis'
  },
  {
    code: 'R18',
    label: 'Ascites'
  },
  {
    code: 'E54',
    label: 'Ascorbic acid deficiency'
  },
  {
    code: 'B44',
    label: 'Aspergillosis'
  },
  {
    code: 'T71',
    label: 'Asphyxiation'
  },
  {
    code: 'Y00',
    label: 'Assault by blunt object'
  },
  {
    code: 'Y04',
    label: 'Assault by bodily force'
  },
  {
    code: 'X86',
    label: 'Assault by corrosive substance'
  },
  {
    code: 'Y03',
    label: 'Assault by crashing of motor vehicle'
  },
  {
    code: 'X92',
    label: 'Assault by drowning and submersion'
  },
  {
    code: 'X85',
    label: 'Assault by drugs, medicaments and biological substances'
  },
  {
    code: 'X96',
    label: 'Assault by explosive material'
  },
  {
    code: 'X88',
    label: 'Assault by gases and vapours'
  },
  {
    code: 'X93',
    label: 'Assault by handgun discharge'
  },
  {
    code: 'X91',
    label: 'Assault by hanging, strangulation and suffocation'
  },
  {
    code: 'X95',
    label: 'Assault by other and unspecified firearm discharge'
  },
  {
    code: 'X89',
    label: 'Assault by other specified chemicals and noxious substances'
  },
  {
    code: 'Y08',
    label: 'Assault by other specified means'
  },
  {
    code: 'X87',
    label: 'Assault by pesticides'
  },
  {
    code: 'Y01',
    label: 'Assault by pushing from high place'
  },
  {
    code: 'Y02',
    label: 'Assault by pushing or placing victim before moving object'
  },
  {
    code: 'X99',
    label: 'Assault by sharp object'
  },
  {
    code: 'X97',
    label: 'Assault by smoke, fire and flames'
  },
  {
    code: 'X98',
    label: 'Assault by steam, hot vapours and hot objects'
  },
  {
    code: 'X90',
    label: 'Assault by unspecified chemical or noxious substance'
  },
  {
    code: 'Y09',
    label: 'Assault by unspecified means'
  },
  {
    code: 'J45',
    label: 'Asthma'
  },
  {
    code: 'Z21',
    label: 'Asymptomatic human immunodeficiency virus [HIV] infection status'
  },
  {
    code: 'I70',
    label: 'Atherosclerosis'
  },
  {
    code: 'L20',
    label: 'Atopic dermatitis'
  },
  {
    code: 'I48',
    label: 'Atrial fibrillation and flutter'
  },
  {
    code: 'I44',
    label: 'Atrioventricular and left bundle-branch block'
  },
  {
    code: 'L90',
    label: 'Atrophic disorders of skin'
  },
  {
    code: 'Z43',
    label: 'Attention to artificial openings'
  },
  {
    code: 'A81',
    label: 'Atypical virus infections of central nervous system'
  },
  {
    code: 'Z06',
    label: 'Bacterial agents resistant to antibiotics'
  },
  {
    code: 'A49',
    label: 'Bacterial infection of unspecified site'
  },
  {
    code: 'G00',
    label: 'Bacterial meningitis, not elsewhere classified'
  },
  {
    code: 'J15',
    label: 'Bacterial pneumonia, not elsewhere classified'
  },
  {
    code: 'P36',
    label: 'Bacterial sepsis of newborn'
  },
  {
    code: 'Y58',
    label: 'Bacterial vaccines causing adverse effects in therapeutic use'
  },
  {
    code: 'Q95',
    label: 'Balanced rearrangements and structural markers, not elsewhere classified'
  },
  {
    code: 'A44',
    label: 'Bartonellosis'
  },
  {
    code: 'D17',
    label: 'Benign lipomatous neoplasm'
  },
  {
    code: 'N60',
    label: 'Benign mammary dysplasia'
  },
  {
    code: 'D16',
    label: 'Benign neoplasm of bone and articular cartilage'
  },
  {
    code: 'D33',
    label: 'Benign neoplasm of brain and other parts of central nervous system'
  },
  {
    code: 'D24',
    label: 'Benign neoplasm of breast'
  },
  {
    code: 'D12',
    label: 'Benign neoplasm of colon, rectum, anus and anal canal'
  },
  {
    code: 'D31',
    label: 'Benign neoplasm of eye and adnexa'
  },
  {
    code: 'D11',
    label: 'Benign neoplasm of major salivary glands'
  },
  {
    code: 'D29',
    label: 'Benign neoplasm of male genital organs'
  },
  {
    code: 'D32',
    label: 'Benign neoplasm of meninges'
  },
  {
    code: 'D19',
    label: 'Benign neoplasm of mesothelial tissue'
  },
  {
    code: 'D14',
    label: 'Benign neoplasm of middle ear and respiratory system'
  },
  {
    code: 'D10',
    label: 'Benign neoplasm of mouth and pharynx'
  },
  {
    code: 'D13',
    label: 'Benign neoplasm of other and ill-defined parts of digestive system'
  },
  {
    code: 'D35',
    label: 'Benign neoplasm of other and unspecified endocrine glands'
  },
  {
    code: 'D28',
    label: 'Benign neoplasm of other and unspecified female genital organs'
  },
  {
    code: 'D15',
    label: 'Benign neoplasm of other and unspecified intrathoracic organs'
  },
  {
    code: 'D36',
    label: 'Benign neoplasm of other and unspecified sites'
  },
  {
    code: 'D27',
    label: 'Benign neoplasm of ovary'
  },
  {
    code: 'D20',
    label: 'Benign neoplasm of soft tissue of retroperitoneum and peritoneum'
  },
  {
    code: 'D34',
    label: 'Benign neoplasm of thyroid gland'
  },
  {
    code: 'D30',
    label: 'Benign neoplasm of urinary organs'
  },
  {
    code: 'M99',
    label: 'Biomechanical lesions, not elsewhere classified'
  },
  {
    code: 'F31',
    label: 'Bipolar affective disorder'
  },
  {
    code: 'P21',
    label: 'Birth asphyxia'
  },
  {
    code: 'P14',
    label: 'Birth trauma to peripheral nervous system'
  },
  {
    code: 'P12',
    label: 'Birth trauma to scalp'
  },
  {
    code: 'P13',
    label: 'Birth trauma to skeleton'
  },
  {
    code: 'W53',
    label: 'Bitten by rat'
  },
  {
    code: 'W59',
    label: 'Bitten or crushed by other reptiles'
  },
  {
    code: 'W58',
    label: 'Bitten or struck by crocodile or alligator'
  },
  {
    code: 'W54',
    label: 'Bitten or struck by dog'
  },
  {
    code: 'W55',
    label: 'Bitten or struck by other mammals'
  },
  {
    code: 'W57',
    label: 'Bitten or stung by nonvenomous insect and other nonvenomous arthropods'
  },
  {
    code: 'N33',
    label: 'Bladder disorders in diseases classified elsewhere'
  },
  {
    code: 'B40',
    label: 'Blastomycosis'
  },
  {
    code: 'U53',
    label: 'Boating sports'
  },
  {
    code: 'J47',
    label: 'Bronchiectasis'
  },
  {
    code: 'J40',
    label: 'Bronchitis, not specified as acute or chronic'
  },
  {
    code: 'A23',
    label: 'Brucellosis'
  },
  {
    code: 'L14',
    label: 'Bullous disorders in diseases classified elsewhere'
  },
  {
    code: 'T30',
    label: 'Burn body region unspecified'
  },
  {
    code: 'T25',
    label: 'Burn of ankle and foot'
  },
  {
    code: 'T26',
    label: 'Burn of eye and adnexa'
  },
  {
    code: 'T20',
    label: 'Burn of head and neck'
  },
  {
    code: 'T24',
    label: 'Burn of hip and lower limb, except ankle and foot'
  },
  {
    code: 'T28',
    label: 'Burn of other internal organs'
  },
  {
    code: 'T27',
    label: 'Burn of respiratory tract'
  },
  {
    code: 'T22',
    label: 'Burn of shoulder and upper limb, except wrist and hand'
  },
  {
    code: 'T21',
    label: 'Burn of trunk'
  },
  {
    code: 'T23',
    label: 'Burn of wrist and hand'
  },
  {
    code: 'T31',
    label: 'Burns classified according to extent of body surface involved'
  },
  {
    code: 'T29',
    label: 'Burns of multiple body regions'
  },
  {
    code: 'V73',
    label: 'Bus occupant injured in collision with car, pick-up truck or van'
  },
  {
    code: 'V77',
    label: 'Bus occupant injured in collision with fixed or stationary object'
  },
  {
    code: 'V74',
    label: 'Bus occupant injured in collision with heavy transport vehicle or bus'
  },
  {
    code: 'V76',
    label: 'Bus occupant injured in collision with other nonmotor vehicle'
  },
  {
    code: 'V71',
    label: 'Bus occupant injured in collision with pedal cycle'
  },
  {
    code: 'V70',
    label: 'Bus occupant injured in collision with pedestrian or animal'
  },
  {
    code: 'V75',
    label: 'Bus occupant injured in collision with railway train or railway vehicle'
  },
  {
    code: 'V72',
    label: 'Bus occupant injured in collision with two- or three-wheeled motor vehicle'
  },
  {
    code: 'V78',
    label: 'Bus occupant injured in noncollision transport accident'
  },
  {
    code: 'V79',
    label: 'Bus occupant injured in other and unspecified transport accidents'
  },
  {
    code: 'R64',
    label: 'Cachexia'
  },
  {
    code: 'M61',
    label: 'Calcification and ossification of muscle'
  },
  {
    code: 'N20',
    label: 'Calculus of kidney and ureter'
  },
  {
    code: 'N21',
    label: 'Calculus of lower urinary tract'
  },
  {
    code: 'N22',
    label: 'Calculus of urinary tract in diseases classified elsewhere'
  },
  {
    code: 'B37',
    label: 'Candidiasis'
  },
  {
    code: 'V43',
    label: 'Car occupant injured in collision with car, pick-up truck or van'
  },
  {
    code: 'V47',
    label: 'Car occupant injured in collision with fixed or stationary object'
  },
  {
    code: 'V44',
    label: 'Car occupant injured in collision with heavy transport vehicle or bus'
  },
  {
    code: 'V46',
    label: 'Car occupant injured in collision with other nonmotor vehicle'
  },
  {
    code: 'V41',
    label: 'Car occupant injured in collision with pedal cycle'
  },
  {
    code: 'V40',
    label: 'Car occupant injured in collision with pedestrian or animal'
  },
  {
    code: 'V45',
    label: 'Car occupant injured in collision with railway train or railway vehicle'
  },
  {
    code: 'V42',
    label: 'Car occupant injured in collision with two- or three-wheeled motor vehicle'
  },
  {
    code: 'V48',
    label: 'Car occupant injured in noncollision transport accident'
  },
  {
    code: 'V49',
    label: 'Car occupant injured in other and unspecified transport accidents'
  },
  {
    code: 'D05',
    label: 'Carcinoma in situ of breast'
  },
  {
    code: 'D06',
    label: 'Carcinoma in situ of cervix uteri'
  },
  {
    code: 'D02',
    label: 'Carcinoma in situ of middle ear and respiratory system'
  },
  {
    code: 'D00',
    label: 'Carcinoma in situ of oral cavity, oesophagus and stomach'
  },
  {
    code: 'D01',
    label: 'Carcinoma in situ of other and unspecified digestive organs'
  },
  {
    code: 'D07',
    label: 'Carcinoma in situ of other and unspecified genital organs'
  },
  {
    code: 'D09',
    label: 'Carcinoma in situ of other and unspecified sites'
  },
  {
    code: 'D04',
    label: 'Carcinoma in situ of skin'
  },
  {
    code: 'I46',
    label: 'Cardiac arrest'
  },
  {
    code: 'R01',
    label: 'Cardiac murmurs and other cardiac sounds'
  },
  {
    code: 'I42',
    label: 'Cardiomyopathy'
  },
  {
    code: 'I43',
    label: 'Cardiomyopathy in diseases classified elsewhere'
  },
  {
    code: 'Y71',
    label: 'Cardiovascular devices associated with misadventures'
  },
  {
    code: 'P29',
    label: 'Cardiovascular disorders originating in the perinatal period'
  },
  {
    code: 'Z49',
    label: 'Care involving dialysis'
  },
  {
    code: 'Z50',
    label: 'Care involving use of rehabilitation procedures'
  },
  {
    code: 'Z22',
    label: 'Carrier of infectious disease'
  },
  {
    code: 'H28',
    label: 'Cataract and other disorders of lens in diseases classified elsewhere'
  },
  {
    code: 'W23',
    label: 'Caught, crushed, jammed or pinched in or between objects'
  },
  {
    code: 'L03',
    label: 'Cellulitis'
  },
  {
    code: 'Y50',
    label: 'Central nervous system stimulants, not elsewhere classified causing adverse effects in therapeutic use'
  },
  {
    code: 'I63',
    label: 'Cerebral infarction'
  },
  {
    code: 'G80',
    label: 'Cerebral palsy'
  },
  {
    code: 'I68',
    label: 'Cerebrovascular disorders in diseases classified elsewhere'
  },
  {
    code: 'I23',
    label: 'Certain current complications following acute myocardial infarction'
  },
  {
    code: 'D76',
    label: 'Certain diseases involving lymphoreticular tissue and reticulohistiocytic system'
  },
  {
    code: 'T79',
    label: 'Certain early complications of trauma, not elsewhere classified'
  },
  {
    code: 'M50',
    label: 'Cervical disc disorders'
  },
  {
    code: 'B57',
    label: "Chagas' disease"
  },
  {
    code: 'A57',
    label: 'Chancroid'
  },
  {
    code: 'A70',
    label: 'Chlamydia psittaci infection'
  },
  {
    code: 'A55',
    label: 'Chlamydial lymphogranuloma (venereum)'
  },
  {
    code: 'K81',
    label: 'Cholecystitis'
  },
  {
    code: 'K80',
    label: 'Cholelithiasis'
  },
  {
    code: 'A00',
    label: 'Cholera'
  },
  {
    code: 'H71',
    label: 'Cholesteatoma of middle ear'
  },
  {
    code: 'H32',
    label: 'Chorioretinal disorders in diseases classified elsewhere'
  },
  {
    code: 'H30',
    label: 'Chorioretinal inflammation'
  },
  {
    code: 'B43',
    label: 'Chromomycosis and phaeomycotic abscess'
  },
  {
    code: 'J35',
    label: 'Chronic diseases of tonsils and adenoids'
  },
  {
    code: 'K73',
    label: 'Chronic hepatitis, not elsewhere classified'
  },
  {
    code: 'I25',
    label: 'Chronic ischaemic heart disease'
  },
  {
    code: 'N18',
    label: 'Chronic kidney disease'
  },
  {
    code: 'J37',
    label: 'Chronic laryngitis and laryngotracheitis'
  },
  {
    code: 'N03',
    label: 'Chronic nephritic syndrome'
  },
  {
    code: 'P27',
    label: 'Chronic respiratory disease originating in the perinatal period'
  },
  {
    code: 'J31',
    label: 'Chronic rhinitis, nasopharyngitis and pharyngitis'
  },
  {
    code: 'J32',
    label: 'Chronic sinusitis'
  },
  {
    code: 'N11',
    label: 'Chronic tubulo-interstitial nephritis'
  },
  {
    code: 'B18',
    label: 'Chronic viral hepatitis'
  },
  {
    code: 'L66',
    label: 'Cicatricial alopecia [scarring hair loss]'
  },
  {
    code: 'Q36',
    label: 'Cleft lip'
  },
  {
    code: 'Q35',
    label: 'Cleft palate'
  },
  {
    code: 'Q37',
    label: 'Cleft palate with cleft lip'
  },
  {
    code: 'J60',
    label: "Coalworker's pneumoconiosis"
  },
  {
    code: 'B38',
    label: 'Coccidioidomycosis'
  },
  {
    code: 'U61',
    label: 'Combative sports'
  },
  {
    code: 'D81',
    label: 'Combined immunodeficiencies'
  },
  {
    code: 'D83',
    label: 'Common variable immunodeficiency'
  },
  {
    code: 'I51',
    label: 'Complications and ill-defined descriptions of heart disease'
  },
  {
    code: 'N98',
    label: 'Complications associated with artificial fertilisation'
  },
  {
    code: 'O08',
    label: 'Complications following abortion and ectopic and molar pregnancy'
  },
  {
    code: 'T80',
    label: 'Complications following infusion, transfusion and therapeutic injection'
  },
  {
    code: 'O74',
    label: 'Complications of anaesthesia during labour and delivery'
  },
  {
    code: 'O29',
    label: 'Complications of anaesthesia during pregnancy'
  },
  {
    code: 'O89',
    label: 'Complications of anaesthesia during the puerperium'
  },
  {
    code: 'T82',
    label: 'Complications of cardiac and vascular prosthetic devices, implants and grafts'
  },
  {
    code: 'T83',
    label: 'Complications of genitourinary prosthetic devices, implants and grafts'
  },
  {
    code: 'T84',
    label: 'Complications of internal orthopaedic prosthetic devices, implants and grafts'
  },
  {
    code: 'T85',
    label: 'Complications of other internal prosthetic devices, implants and grafts'
  },
  {
    code: 'T81',
    label: 'Complications of procedures, not elsewhere classified'
  },
  {
    code: 'O90',
    label: 'Complications of the puerperium, not elsewhere classified'
  },
  {
    code: 'T87',
    label: 'Complications peculiar to reattachment and amputation'
  },
  {
    code: 'O31',
    label: 'Complications specific to multiple gestation'
  },
  {
    code: 'F91',
    label: 'Conduct disorders'
  },
  {
    code: 'H90',
    label: 'Conductive and sensorineural hearing loss'
  },
  {
    code: 'W81',
    label: 'Confined to or trapped in a low-oxygen environment'
  },
  {
    code: 'Q42',
    label: 'Congenital absence, atresia and stenosis of large intestine'
  },
  {
    code: 'Q41',
    label: 'Congenital absence, atresia and stenosis of small intestine'
  },
  {
    code: 'Q66',
    label: 'Congenital deformities of feet'
  },
  {
    code: 'Q65',
    label: 'Congenital deformities of hip'
  },
  {
    code: 'Q03',
    label: 'Congenital hydrocephalus'
  },
  {
    code: 'Q80',
    label: 'Congenital ichthyosis'
  },
  {
    code: 'E00',
    label: 'Congenital iodine-deficiency syndrome'
  },
  {
    code: 'Q12',
    label: 'Congenital lens malformations'
  },
  {
    code: 'Q86',
    label: 'Congenital malformation syndromes due to known exogenous causes, not elsewhere classified'
  },
  {
    code: 'Q13',
    label: 'Congenital malformations of anterior segment of eye'
  },
  {
    code: 'Q23',
    label: 'Congenital malformations of aortic and mitral valves'
  },
  {
    code: 'Q83',
    label: 'Congenital malformations of breast'
  },
  {
    code: 'Q20',
    label: 'Congenital malformations of cardiac chambers and connections'
  },
  {
    code: 'Q21',
    label: 'Congenital malformations of cardiac septa'
  },
  {
    code: 'Q16',
    label: 'Congenital malformations of ear causing impairment of hearing'
  },
  {
    code: 'Q10',
    label: 'Congenital malformations of eyelid, lacrimal apparatus and orbit'
  },
  {
    code: 'Q44',
    label: 'Congenital malformations of gallbladder, bile ducts and liver'
  },
  {
    code: 'Q25',
    label: 'Congenital malformations of great arteries'
  },
  {
    code: 'Q26',
    label: 'Congenital malformations of great veins'
  },
  {
    code: 'Q31',
    label: 'Congenital malformations of larynx'
  },
  {
    code: 'Q33',
    label: 'Congenital malformations of lung'
  },
  {
    code: 'Q30',
    label: 'Congenital malformations of nose'
  },
  {
    code: 'Q39',
    label: 'Congenital malformations of oesophagus'
  },
  {
    code: 'Q50',
    label: 'Congenital malformations of ovaries, fallopian tubes and broad ligaments'
  },
  {
    code: 'Q14',
    label: 'Congenital malformations of posterior segment of eye'
  },
  {
    code: 'Q22',
    label: 'Congenital malformations of pulmonary and tricuspid valves'
  },
  {
    code: 'Q76',
    label: 'Congenital malformations of spine and bony thorax'
  },
  {
    code: 'Q79',
    label: 'Congenital malformations of the musculoskeletal system, not elsewhere classified'
  },
  {
    code: 'Q32',
    label: 'Congenital malformations of trachea and bronchus'
  },
  {
    code: 'Q51',
    label: 'Congenital malformations of uterus and cervix'
  },
  {
    code: 'Q67',
    label: 'Congenital musculoskeletal deformities of head, face, spine and chest'
  },
  {
    code: 'Q62',
    label: 'Congenital obstructive defects of renal pelvis and congenital malformations of ureter'
  },
  {
    code: 'P23',
    label: 'Congenital pneumonia'
  },
  {
    code: 'A50',
    label: 'Congenital syphilis'
  },
  {
    code: 'P35',
    label: 'Congenital viral diseases'
  },
  {
    code: 'H10',
    label: 'Conjunctivitis'
  },
  {
    code: 'W30',
    label: 'Contact with agricultural machinery'
  },
  {
    code: 'Z20',
    label: 'Contact with and exposure to communicable diseases'
  },
  {
    code: 'W61',
    label: 'Contact with bird'
  },
  {
    code: 'Y29',
    label: 'Contact with blunt object, undetermined intent'
  },
  {
    code: 'X24',
    label: 'Contact with centipedes and venomous millipedes (tropical)'
  },
  {
    code: 'Y25',
    label: 'Contact with explosive material, undetermined intent'
  },
  {
    code: 'X23',
    label: 'Contact with hornets, wasps and bees'
  },
  {
    code: 'X14',
    label: 'Contact with hot air and gases'
  },
  {
    code: 'X10',
    label: 'Contact with hot drinks, food, fats and cooking oils'
  },
  {
    code: 'X17',
    label: 'Contact with hot engines, machinery and tools'
  },
  {
    code: 'X16',
    label: 'Contact with hot heating appliances, radiators and pipes'
  },
  {
    code: 'X15',
    label: 'Contact with hot household appliances'
  },
  {
    code: 'X11',
    label: 'Contact with hot tap-water'
  },
  {
    code: 'W46',
    label: 'Contact with hypodermic needle'
  },
  {
    code: 'W26',
    label: 'Contact with knife, sword or dagger'
  },
  {
    code: 'W24',
    label: 'Contact with lifting and transmission devices, not elsewhere classified'
  },
  {
    code: 'W56',
    label: 'Contact with marine animal'
  },
  {
    code: 'W27',
    label: 'Contact with nonpowered hand tool'
  },
  {
    code: 'X19',
    label: 'Contact with other and unspecified heat and hot substances'
  },
  {
    code: 'W31',
    label: 'Contact with other and unspecified machinery'
  },
  {
    code: 'X12',
    label: 'Contact with other hot fluids'
  },
  {
    code: 'X18',
    label: 'Contact with other hot metals'
  },
  {
    code: 'W29',
    label: 'Contact with other powered hand tools and household machinery'
  },
  {
    code: 'X27',
    label: 'Contact with other specified venomous animals'
  },
  {
    code: 'X28',
    label: 'Contact with other specified venomous plants'
  },
  {
    code: 'X25',
    label: 'Contact with other venomous arthropods'
  },
  {
    code: 'W60',
    label: 'Contact with plant thorns and spines and sharp leaves'
  },
  {
    code: 'W28',
    label: 'Contact with powered lawnmower'
  },
  {
    code: 'X22',
    label: 'Contact with scorpions'
  },
  {
    code: 'W25',
    label: 'Contact with sharp glass'
  },
  {
    code: 'Y28',
    label: 'Contact with sharp object, undetermined intent'
  },
  {
    code: 'X21',
    label: 'Contact with spiders'
  },
  {
    code: 'X13',
    label: 'Contact with steam and hot vapours'
  },
  {
    code: 'Y27',
    label: 'Contact with steam, hot vapours and hot objects, undetermined intent'
  },
  {
    code: 'X29',
    label: 'Contact with unspecified venomous animal or plant'
  },
  {
    code: 'X26',
    label: 'Contact with venomous marine animals and plants'
  },
  {
    code: 'X20',
    label: 'Contact with venomous snakes and lizards'
  },
  {
    code: 'Y64',
    label: 'Contaminated medical or biological substances'
  },
  {
    code: 'Z30',
    label: 'Contraceptive management'
  },
  {
    code: 'Z54',
    label: 'Convalescence'
  },
  {
    code: 'P90',
    label: 'Convulsions of newborn'
  },
  {
    code: 'R56',
    label: 'Convulsions, not elsewhere classified'
  },
  {
    code: 'H17',
    label: 'Corneal scars and opacities'
  },
  {
    code: 'L84',
    label: 'Corns and callosities'
  },
  {
    code: 'R05',
    label: 'Cough'
  },
  {
    code: 'Z70',
    label: 'Counselling related to sexual attitude, behaviour and orientation'
  },
  {
    code: 'M16',
    label: 'Coxarthrosis [arthrosis of hip]'
  },
  {
    code: 'G53',
    label: 'Cranial nerve disorders in diseases classified elsewhere'
  },
  {
    code: 'Y32',
    label: 'Crashing of motor vehicle, undetermined intent'
  },
  {
    code: 'K50',
    label: "Crohn's disease [regional enteritis]"
  },
  {
    code: 'W52',
    label: 'Crushed, pushed or stepped on by crowd or human stampede'
  },
  {
    code: 'T04',
    label: 'Crushing injuries involving multiple body regions'
  },
  {
    code: 'S38',
    label: 'Crushing injury and traumatic amputation of part of abdomen, lower back and pelvis'
  },
  {
    code: 'S97',
    label: 'Crushing injury of ankle and foot'
  },
  {
    code: 'S57',
    label: 'Crushing injury of forearm'
  },
  {
    code: 'S07',
    label: 'Crushing injury of head'
  },
  {
    code: 'S77',
    label: 'Crushing injury of hip and thigh'
  },
  {
    code: 'S87',
    label: 'Crushing injury of lower leg'
  },
  {
    code: 'S17',
    label: 'Crushing injury of neck'
  },
  {
    code: 'S47',
    label: 'Crushing injury of shoulder and upper arm'
  },
  {
    code: 'S28',
    label: 'Crushing injury of thorax and traumatic amputation of part of thorax'
  },
  {
    code: 'S67',
    label: 'Crushing injury of wrist and hand'
  },
  {
    code: 'B45',
    label: 'Cryptococcosis'
  },
  {
    code: 'E24',
    label: "Cushing's syndrome"
  },
  {
    code: 'L02',
    label: 'Cutaneous abscess, furuncle and carbuncle'
  },
  {
    code: 'E84',
    label: 'Cystic fibrosis'
  },
  {
    code: 'Q61',
    label: 'Cystic kidney disease'
  },
  {
    code: 'B69',
    label: 'Cysticercosis'
  },
  {
    code: 'N30',
    label: 'Cystitis'
  },
  {
    code: 'K09',
    label: 'Cysts of oral region, not elsewhere classified'
  },
  {
    code: 'B25',
    label: 'Cytomegaloviral disease'
  },
  {
    code: 'O96',
    label: 'Death from any obstetric cause occurring more than 42 days but less than one year after delivery'
  },
  {
    code: 'O97',
    label: 'Death from sequelae of direct obstetric causes'
  },
  {
    code: 'L89',
    label: 'Decubitus ulcer and pressure area'
  },
  {
    code: 'E53',
    label: 'Deficiency of other B group vitamins'
  },
  {
    code: 'E61',
    label: 'Deficiency of other nutrient elements'
  },
  {
    code: 'F05',
    label: 'Delirium, not induced by alcohol and other psychoactive substances'
  },
  {
    code: 'F00',
    label: "Dementia in Alzheimer's disease (G30.-+)"
  },
  {
    code: 'F02',
    label: 'Dementia in other diseases classified elsewhere'
  },
  {
    code: 'A90',
    label: 'Dengue fever [classical dengue]'
  },
  {
    code: 'A91',
    label: 'Dengue haemorrhagic fever'
  },
  {
    code: 'K02',
    label: 'Dental caries'
  },
  {
    code: 'K07',
    label: 'Dentofacial anomalies [including malocclusion]'
  },
  {
    code: 'Z99',
    label: 'Dependence on enabling machines and devices, not elsewhere classified'
  },
  {
    code: 'F32',
    label: 'Depressive episode'
  },
  {
    code: 'L27',
    label: 'Dermatitis due to substances taken internally'
  },
  {
    code: 'B35',
    label: 'Dermatophytosis'
  },
  {
    code: 'M33',
    label: 'Dermatopolymyositis'
  },
  {
    code: 'O24',
    label: 'Diabetes mellitus in pregnancy'
  },
  {
    code: 'L22',
    label: 'Diaper [napkin] dermatitis'
  },
  {
    code: 'K44',
    label: 'Diaphragmatic hernia'
  },
  {
    code: 'E58',
    label: 'Dietary calcium deficiency'
  },
  {
    code: 'E59',
    label: 'Dietary selenium deficiency'
  },
  {
    code: 'E60',
    label: 'Dietary zinc deficiency'
  },
  {
    code: 'C83',
    label: 'Diffuse non-Hodgkin lymphoma'
  },
  {
    code: 'A36',
    label: 'Diphtheria'
  },
  {
    code: 'B70',
    label: 'Diphyllobothriasis and sparganosis'
  },
  {
    code: 'M01',
    label: 'Direct infections of joint in infectious and parasitic diseases classified elsewhere'
  },
  {
    code: 'W34',
    label: 'Discharge from other and unspecified firearms'
  },
  {
    code: 'W39',
    label: 'Discharge of firework'
  },
  {
    code: 'N75',
    label: "Diseases of Bartholin's gland"
  },
  {
    code: 'I78',
    label: 'Diseases of capillaries'
  },
  {
    code: 'K04',
    label: 'Diseases of pulp and periapical tissues'
  },
  {
    code: 'K11',
    label: 'Diseases of salivary glands'
  },
  {
    code: 'D73',
    label: 'Diseases of spleen'
  },
  {
    code: 'E32',
    label: 'Diseases of thymus'
  },
  {
    code: 'K14',
    label: 'Diseases of tongue'
  },
  {
    code: 'J38',
    label: 'Diseases of vocal cords and larynx, not elsewhere classified'
  },
  {
    code: 'S73',
    label: 'Dislocation, sprain and strain of joint and ligaments of hip'
  },
  {
    code: 'S93',
    label: 'Dislocation, sprain and strain of joints and ligaments at ankle and foot level'
  },
  {
    code: 'S13',
    label: 'Dislocation, sprain and strain of joints and ligaments at neck level'
  },
  {
    code: 'S63',
    label: 'Dislocation, sprain and strain of joints and ligaments at wrist and hand level'
  },
  {
    code: 'S53',
    label: 'Dislocation, sprain and strain of joints and ligaments of elbow'
  },
  {
    code: 'S03',
    label: 'Dislocation, sprain and strain of joints and ligaments of head'
  },
  {
    code: 'S83',
    label: 'Dislocation, sprain and strain of joints and ligaments of knee'
  },
  {
    code: 'S33',
    label: 'Dislocation, sprain and strain of joints and ligaments of lumbar spine and pelvis'
  },
  {
    code: 'S43',
    label: 'Dislocation, sprain and strain of joints and ligaments of shoulder girdle'
  },
  {
    code: 'S23',
    label: 'Dislocation, sprain and strain of joints and ligaments of thorax'
  },
  {
    code: 'T03',
    label: 'Dislocations, sprains and strains involving multiple body regions'
  },
  {
    code: 'E70',
    label: 'Disorders of aromatic amino-acid metabolism'
  },
  {
    code: 'I79',
    label: 'Disorders of arteries, arterioles and capillaries in diseases classified elsewhere'
  },
  {
    code: 'G90',
    label: 'Disorders of autonomic nervous system'
  },
  {
    code: 'E71',
    label: 'Disorders of branched-chain amino-acid metabolism and fatty-acid metabolism'
  },
  {
    code: 'H13',
    label: 'Disorders of conjunctiva in diseases classified elsewhere'
  },
  {
    code: 'M84',
    label: 'Disorders of continuity of bone'
  },
  {
    code: 'E35',
    label: 'Disorders of endocrine glands in diseases classified elsewhere'
  },
  {
    code: 'H62',
    label: 'Disorders of external ear in diseases classified elsewhere'
  },
  {
    code: 'H03',
    label: 'Disorders of eyelid in diseases classified elsewhere'
  },
  {
    code: 'K87',
    label: 'Disorders of gallbladder, biliary tract and pancreas in diseases classified elsewhere'
  },
  {
    code: 'H44',
    label: 'Disorders of globe'
  },
  {
    code: 'E77',
    label: 'Disorders of glycoprotein metabolism'
  },
  {
    code: 'E76',
    label: 'Disorders of glycosaminoglycan metabolism'
  },
  {
    code: 'H22',
    label: 'Disorders of iris and ciliary body in diseases classified elsewhere'
  },
  {
    code: 'H04',
    label: 'Disorders of lacrimal system'
  },
  {
    code: 'H06',
    label: 'Disorders of lacrimal system and orbit in diseases classified elsewhere'
  },
  {
    code: 'E78',
    label: 'Disorders of lipoprotein metabolism and other lipidaemias'
  },
  {
    code: 'N51',
    label: 'Disorders of male genital organs in diseases classified elsewhere'
  },
  {
    code: 'E83',
    label: 'Disorders of mineral metabolism'
  },
  {
    code: 'M63',
    label: 'Disorders of muscle in diseases classified elsewhere'
  },
  {
    code: 'P94',
    label: 'Disorders of muscle tone of newborn'
  },
  {
    code: 'G73',
    label: 'Disorders of myoneural junction and muscle in diseases classified elsewhere'
  },
  {
    code: 'K23',
    label: 'Disorders of oesophagus in diseases classified elsewhere'
  },
  {
    code: 'H48',
    label: 'Disorders of optic [2nd] nerve and visual pathways in diseases classified elsewhere'
  },
  {
    code: 'H05',
    label: 'Disorders of orbit'
  },
  {
    code: 'G52',
    label: 'Disorders of other cranial nerves'
  },
  {
    code: 'K93',
    label: 'Disorders of other digestive organs in diseases classified elsewhere'
  },
  {
    code: 'M22',
    label: 'Disorders of patella'
  },
  {
    code: 'K67',
    label: 'Disorders of peritoneum in infectious diseases classified elsewhere'
  },
  {
    code: 'E80',
    label: 'Disorders of porphyrin and bilirubin metabolism'
  },
  {
    code: 'E30',
    label: 'Disorders of puberty, not elsewhere classified'
  },
  {
    code: 'E79',
    label: 'Disorders of purine and pyrimidine metabolism'
  },
  {
    code: 'H52',
    label: 'Disorders of refraction and accommodation'
  },
  {
    code: 'H15',
    label: 'Disorders of sclera'
  },
  {
    code: 'H19',
    label: 'Disorders of sclera and cornea in diseases classified elsewhere'
  },
  {
    code: 'F65',
    label: 'Disorders of sexual preference'
  },
  {
    code: 'F94',
    label: 'Disorders of social functioning with onset specific to childhood and adolescence'
  },
  {
    code: 'E75',
    label: 'Disorders of sphingolipid metabolism and other lipid storage disorders'
  },
  {
    code: 'M68',
    label: 'Disorders of synovium and tendon in diseases classified elsewhere'
  },
  {
    code: 'K00',
    label: 'Disorders of tooth development and eruption'
  },
  {
    code: 'G50',
    label: 'Disorders of trigeminal nerve'
  },
  {
    code: 'H81',
    label: 'Disorders of vestibular function'
  },
  {
    code: 'H43',
    label: 'Disorders of vitreous body'
  },
  {
    code: 'H45',
    label: 'Disorders of vitreous body and globe in diseases classified elsewhere'
  },
  {
    code: 'P08',
    label: 'Disorders related to long gestation and high birth weight'
  },
  {
    code: 'P07',
    label: 'Disorders related to short gestation and low birth weight, not elsewhere classified'
  },
  {
    code: 'N25',
    label: 'Disorders resulting from impaired renal tubular function'
  },
  {
    code: 'D65',
    label: 'Disseminated intravascular coagulation [defibrination syndrome]'
  },
  {
    code: 'P60',
    label: 'Disseminated intravascular coagulation of fetus and newborn'
  },
  {
    code: 'F44',
    label: 'Dissociative [conversion] disorders'
  },
  {
    code: 'R20',
    label: 'Disturbances of skin sensation'
  },
  {
    code: 'R43',
    label: 'Disturbances of smell and taste'
  },
  {
    code: 'K57',
    label: 'Diverticular disease of intestine'
  },
  {
    code: 'W16',
    label: 'Diving or jumping into water causing injury other than drowning or submersion'
  },
  {
    code: 'R42',
    label: 'Dizziness and giddiness'
  },
  {
    code: 'Z52',
    label: 'Donors of organs and tissues'
  },
  {
    code: 'M54',
    label: 'Dorsalgia'
  },
  {
    code: 'Q90',
    label: "Down's syndrome"
  },
  {
    code: 'B72',
    label: 'Dracunculiasis'
  },
  {
    code: 'W66',
    label: 'Drowning and submersion following fall into bath-tub'
  },
  {
    code: 'W70',
    label: 'Drowning and submersion following fall into natural water'
  },
  {
    code: 'W68',
    label: 'Drowning and submersion following fall into swimming-pool'
  },
  {
    code: 'W65',
    label: 'Drowning and submersion while in bath-tub'
  },
  {
    code: 'W69',
    label: 'Drowning and submersion while in natural water'
  },
  {
    code: 'W67',
    label: 'Drowning and submersion while in swimming-pool'
  },
  {
    code: 'Y21',
    label: 'Drowning and submersion, undetermined intent'
  },
  {
    code: 'N14',
    label: 'Drug- and heavy-metal-induced tubulo-interstitial and tubular conditions'
  },
  {
    code: 'Y51',
    label: 'Drugs primarily affecting the autonomic nervous system causing adverse effects in therapeutic use'
  },
  {
    code: 'K26',
    label: 'Duodenal ulcer'
  },
  {
    code: 'O09',
    label: 'Duration of pregnancy'
  },
  {
    code: 'R48',
    label: 'Dyslexia and other symbolic dysfunctions, not elsewhere classified'
  },
  {
    code: 'K30',
    label: 'Dyspepsia'
  },
  {
    code: 'R13',
    label: 'Dysphagia'
  },
  {
    code: 'N87',
    label: 'Dysplasia of cervix uteri'
  },
  {
    code: 'G24',
    label: 'Dystonia'
  },
  {
    code: 'A51',
    label: 'Early syphilis'
  },
  {
    code: 'F50',
    label: 'Eating disorders'
  },
  {
    code: 'L74',
    label: 'Eccrine sweat disorders'
  },
  {
    code: 'B67',
    label: 'Echinococcosis'
  },
  {
    code: 'O15',
    label: 'Eclampsia'
  },
  {
    code: 'O00',
    label: 'Ectopic pregnancy'
  },
  {
    code: 'Q91',
    label: "Edwards' syndrome and Patau's syndrome"
  },
  {
    code: 'T70',
    label: 'Effects of air pressure and water pressure'
  },
  {
    code: 'T67',
    label: 'Effects of heat and light'
  },
  {
    code: 'T73',
    label: 'Effects of other deprivation'
  },
  {
    code: 'T75',
    label: 'Effects of other external causes'
  },
  {
    code: 'R73',
    label: 'Elevated blood glucose level'
  },
  {
    code: 'R70',
    label: 'Elevated erythrocyte sedimentation rate and abnormality of plasma viscosity'
  },
  {
    code: 'K01',
    label: 'Embedded and impacted teeth'
  },
  {
    code: 'F93',
    label: 'Emotional disorders with onset specific to childhood'
  },
  {
    code: 'J43',
    label: 'Emphysema'
  },
  {
    code: 'G04',
    label: 'Encephalitis, myelitis and encephalomyelitis'
  },
  {
    code: 'G05',
    label: 'Encephalitis, myelitis and encephalomyelitis in diseases classified elsewhere'
  },
  {
    code: 'Q01',
    label: 'Encephalocele'
  },
  {
    code: 'I39',
    label: 'Endocarditis and heart valve disorders in diseases classified elsewhere'
  },
  {
    code: 'I38',
    label: 'Endocarditis, valve unspecified'
  },
  {
    code: 'N80',
    label: 'Endometriosis'
  },
  {
    code: 'F62',
    label: 'Enduring personality changes, not attributable to brain damage and disease'
  },
  {
    code: 'R59',
    label: 'Enlarged lymph nodes'
  },
  {
    code: 'B80',
    label: 'Enterobiasis'
  },
  {
    code: 'M76',
    label: 'Enthesopathies of lower limb, excluding foot'
  },
  {
    code: 'Y97',
    label: 'Environmental-pollution-related condition'
  },
  {
    code: 'Q81',
    label: 'Epidermolysis bullosa'
  },
  {
    code: 'G40',
    label: 'Epilepsy'
  },
  {
    code: 'U63',
    label: 'Equestrian activities'
  },
  {
    code: 'N86',
    label: 'Erosion and ectropion of cervix uteri'
  },
  {
    code: 'A46',
    label: 'Erysipelas'
  },
  {
    code: 'A26',
    label: 'Erysipeloid'
  },
  {
    code: 'L54',
    label: 'Erythema in diseases classified elsewhere'
  },
  {
    code: 'L51',
    label: 'Erythema multiforme'
  },
  {
    code: 'L52',
    label: 'Erythema nodosum'
  },
  {
    code: 'I10',
    label: 'Essential (primary) hypertension'
  },
  {
    code: 'H68',
    label: 'Eustachian salpingitis and obstruction'
  },
  {
    code: 'Y90',
    label: 'Evidence of alcohol involvement determined by blood alcohol level'
  },
  {
    code: 'Y91',
    label: 'Evidence of alcohol involvement determined by level of intoxication'
  },
  {
    code: 'Z02',
    label: 'Examination and encounter for administrative purposes'
  },
  {
    code: 'Z04',
    label: 'Examination and observation for other reasons'
  },
  {
    code: 'O21',
    label: 'Excessive vomiting in pregnancy'
  },
  {
    code: 'N92',
    label: 'Excessive, frequent and irregular menstruation'
  },
  {
    code: 'L26',
    label: 'Exfoliative dermatitis'
  },
  {
    code: 'W35',
    label: 'Explosion and rupture of boiler'
  },
  {
    code: 'W36',
    label: 'Explosion and rupture of gas cylinder'
  },
  {
    code: 'W38',
    label: 'Explosion and rupture of other specified pressurised devices'
  },
  {
    code: 'W37',
    label: 'Explosion and rupture of pressurised tyre, pipe or hose'
  },
  {
    code: 'W40',
    label: 'Explosion of other materials'
  },
  {
    code: 'X02',
    label: 'Exposure to controlled fire in building or structure'
  },
  {
    code: 'X03',
    label: 'Exposure to controlled fire, not in building or structure'
  },
  {
    code: 'W85',
    label: 'Exposure to electric transmission lines'
  },
  {
    code: 'W93',
    label: 'Exposure to excessive cold of man-made origin'
  },
  {
    code: 'W92',
    label: 'Exposure to excessive heat of man-made origin'
  },
  {
    code: 'X31',
    label: 'Exposure to excessive natural cold'
  },
  {
    code: 'X30',
    label: 'Exposure to excessive natural heat'
  },
  {
    code: 'W94',
    label: 'Exposure to high and low air pressure and changes in air pressure'
  },
  {
    code: 'W41',
    label: 'Exposure to high-pressure jet'
  },
  {
    code: 'X04',
    label: 'Exposure to ignition of highly flammable material'
  },
  {
    code: 'X05',
    label: 'Exposure to ignition or melting of nightwear'
  },
  {
    code: 'X06',
    label: 'Exposure to ignition or melting of other clothing and apparel'
  },
  {
    code: 'W88',
    label: 'Exposure to ionising radiation'
  },
  {
    code: 'W89',
    label: 'Exposure to man-made visible and ultraviolet light'
  },
  {
    code: 'W42',
    label: 'Exposure to noise'
  },
  {
    code: 'W64',
    label: 'Exposure to other and unspecified animate mechanical forces'
  },
  {
    code: 'X39',
    label: 'Exposure to other and unspecified forces of nature'
  },
  {
    code: 'W49',
    label: 'Exposure to other and unspecified inanimate mechanical forces'
  },
  {
    code: 'W99',
    label: 'Exposure to other and unspecified man-made environmental factors'
  },
  {
    code: 'W90',
    label: 'Exposure to other nonionising radiation'
  },
  {
    code: 'W86',
    label: 'Exposure to other specified electric current'
  },
  {
    code: 'X58',
    label: 'Exposure to other specified factors'
  },
  {
    code: 'X08',
    label: 'Exposure to other specified smoke, fire and flames'
  },
  {
    code: 'Y26',
    label: 'Exposure to smoke, fire and flames, undetermined intent'
  },
  {
    code: 'X32',
    label: 'Exposure to sunlight'
  },
  {
    code: 'X00',
    label: 'Exposure to uncontrolled fire in building or structure'
  },
  {
    code: 'X01',
    label: 'Exposure to uncontrolled fire, not in building or structure'
  },
  {
    code: 'W87',
    label: 'Exposure to unspecified electric current'
  },
  {
    code: 'X59',
    label: 'Exposure to unspecified factor'
  },
  {
    code: 'X09',
    label: 'Exposure to unspecified smoke, fire and flames'
  },
  {
    code: 'W91',
    label: 'Exposure to unspecified type of radiation'
  },
  {
    code: 'W43',
    label: 'Exposure to vibration'
  },
  {
    code: 'G26',
    label: 'Extrapyramidal and movement disorders in diseases classified elsewhere'
  },
  {
    code: 'G51',
    label: 'Facial nerve disorders'
  },
  {
    code: 'R15',
    label: 'Faecal incontinence'
  },
  {
    code: 'O07',
    label: 'Failed attempted abortion'
  },
  {
    code: 'O61',
    label: 'Failed induction of labour'
  },
  {
    code: 'T86',
    label: 'Failure and rejection of transplanted organs and tissues'
  },
  {
    code: 'Y63',
    label: 'Failure in dosage during surgical and medical care'
  },
  {
    code: 'Y62',
    label: 'Failure of sterile precautions during surgical and medical care'
  },
  {
    code: 'W15',
    label: 'Fall from cliff'
  },
  {
    code: 'W14',
    label: 'Fall from tree'
  },
  {
    code: 'W13',
    label: 'Fall from, out of or through building or structure'
  },
  {
    code: 'W06',
    label: 'Fall involving bed'
  },
  {
    code: 'W07',
    label: 'Fall involving chair'
  },
  {
    code: 'W02',
    label: 'Fall involving ice-skates, skis, roller-skates, skateboards, scooters and other pedestrian conveyances'
  },
  {
    code: 'W08',
    label: 'Fall involving other furniture'
  },
  {
    code: 'W09',
    label: 'Fall involving playground equipment'
  },
  {
    code: 'W05',
    label: 'Fall involving wheelchair'
  },
  {
    code: 'W11',
    label: 'Fall on and from ladder'
  },
  {
    code: 'W12',
    label: 'Fall on and from scaffolding'
  },
  {
    code: 'W10',
    label: 'Fall on and from stairs and steps'
  },
  {
    code: 'W01',
    label: 'Fall on same level from slipping, tripping and stumbling'
  },
  {
    code: 'W00',
    label: 'Fall on same level involving ice and snow'
  },
  {
    code: 'W04',
    label: 'Fall while being carried or supported by other persons'
  },
  {
    code: 'Y30',
    label: 'Falling, jumping or pushed from a high place, undetermined intent'
  },
  {
    code: 'Y31',
    label: 'Falling, lying or running before or into moving object, undetermined intent'
  },
  {
    code: 'O47',
    label: 'False labour'
  },
  {
    code: 'Z82',
    label: 'Family history of certain disabilities and chronic diseases leading to disablement'
  },
  {
    code: 'Z80',
    label: 'Family history of malignant neoplasm'
  },
  {
    code: 'Z81',
    label: 'Family history of mental and behavioural disorders'
  },
  {
    code: 'Z84',
    label: 'Family history of other conditions'
  },
  {
    code: 'Z83',
    label: 'Family history of other specific disorders'
  },
  {
    code: 'P92',
    label: 'Feeding problems of newborn'
  },
  {
    code: 'N81',
    label: 'Female genital prolapse'
  },
  {
    code: 'N97',
    label: 'Female infertility'
  },
  {
    code: 'N74',
    label: 'Female pelvic inflammatory disorders in diseases classified elsewhere'
  },
  {
    code: 'K41',
    label: 'Femoral hernia'
  },
  {
    code: 'P50',
    label: 'Fetal blood loss'
  },
  {
    code: 'P95',
    label: 'Fetal death of unspecified cause'
  },
  {
    code: 'P02',
    label: 'Fetus and newborn affected by complications of placenta, cord and membranes'
  },
  {
    code: 'P01',
    label: 'Fetus and newborn affected by maternal complications of pregnancy'
  },
  {
    code: 'P00',
    label: 'Fetus and newborn affected by maternal conditions that may be unrelated to present pregnancy'
  },
  {
    code: 'P04',
    label: 'Fetus and newborn affected by noxious influences transmitted via placenta or breast milk'
  },
  {
    code: 'P03',
    label: 'Fetus and newborn affected by other complications of labour and delivery'
  },
  {
    code: 'R50',
    label: 'Fever of other and unknown origin'
  },
  {
    code: 'M72',
    label: 'Fibroblastic disorders'
  },
  {
    code: 'K74',
    label: 'Fibrosis and cirrhosis of liver'
  },
  {
    code: 'B74',
    label: 'Filariasis'
  },
  {
    code: 'R78',
    label: 'Findings of drugs and other substances, not normally found in blood'
  },
  {
    code: 'K60',
    label: 'Fissure and fistula of anal and rectal regions'
  },
  {
    code: 'N82',
    label: 'Fistulae involving female genital tract'
  },
  {
    code: 'Z44',
    label: 'Fitting and adjustment of external prosthetic device'
  },
  {
    code: 'Z46',
    label: 'Fitting and adjustment of other devices'
  },
  {
    code: 'R14',
    label: 'Flatulence and related conditions'
  },
  {
    code: 'D52',
    label: 'Folate deficiency anaemia'
  },
  {
    code: 'C82',
    label: 'Follicular [nodular] non-Hodgkin lymphoma'
  },
  {
    code: 'L72',
    label: 'Follicular cysts of skin and subcutaneous tissue'
  },
  {
    code: 'Z42',
    label: 'Follow-up care involving plastic surgery'
  },
  {
    code: 'Z09',
    label: 'Follow-up examination after treatment for conditions other than malignant neoplasms'
  },
  {
    code: 'Z08',
    label: 'Follow-up examination after treatment for malignant neoplasms'
  },
  {
    code: 'W44',
    label: 'Foreign body entering into or through eye or natural orifice'
  },
  {
    code: 'T18',
    label: 'Foreign body in alimentary tract'
  },
  {
    code: 'T16',
    label: 'Foreign body in ear'
  },
  {
    code: 'T19',
    label: 'Foreign body in genitourinary tract'
  },
  {
    code: 'T17',
    label: 'Foreign body in respiratory tract'
  },
  {
    code: 'T15',
    label: 'Foreign body on external eye'
  },
  {
    code: 'W45',
    label: 'Foreign body or object entering through skin'
  },
  {
    code: 'Y61',
    label: 'Foreign object accidentally left in body during surgical and medical care'
  },
  {
    code: 'S62',
    label: 'Fracture at wrist and hand level'
  },
  {
    code: 'S72',
    label: 'Fracture of femur'
  },
  {
    code: 'S92',
    label: 'Fracture of foot, except ankle'
  },
  {
    code: 'S52',
    label: 'Fracture of forearm'
  },
  {
    code: 'S82',
    label: 'Fracture of lower leg, including ankle'
  },
  {
    code: 'T12',
    label: 'Fracture of lower limb, level unspecified'
  },
  {
    code: 'S32',
    label: 'Fracture of lumbar spine and pelvis'
  },
  {
    code: 'S12',
    label: 'Fracture of neck'
  },
  {
    code: 'S22',
    label: 'Fracture of rib(s), sternum and thoracic spine'
  },
  {
    code: 'S42',
    label: 'Fracture of shoulder and upper arm'
  },
  {
    code: 'S02',
    label: 'Fracture of skull and facial bones'
  },
  {
    code: 'T08',
    label: 'Fracture of spine, level unspecified'
  },
  {
    code: 'T10',
    label: 'Fracture of upper limb, level unspecified'
  },
  {
    code: 'T02',
    label: 'Fractures involving multiple body regions'
  },
  {
    code: 'T35',
    label: 'Frostbite involving multiple body regions and unspecified frostbite'
  },
  {
    code: 'T34',
    label: 'Frostbite with tissue necrosis'
  },
  {
    code: 'D71',
    label: 'Functional disorders of polymorphonuclear neutrophils'
  },
  {
    code: 'R02',
    label: 'Gangrene, not elsewhere classified'
  },
  {
    code: 'K25',
    label: 'Gastric ulcer'
  },
  {
    code: 'K29',
    label: 'Gastritis and duodenitis'
  },
  {
    code: 'K21',
    label: 'Gastro-oesophageal reflux disease'
  },
  {
    code: 'Y73',
    label: 'Gastroenterology and urology devices associated with misadventures'
  },
  {
    code: 'K28',
    label: 'Gastrojejunal ulcer'
  },
  {
    code: 'F64',
    label: 'Gender identity disorders'
  },
  {
    code: 'Y81',
    label: 'General and plastic-surgery devices associated with misadventures'
  },
  {
    code: 'Z00',
    label: 'General examination and investigation of persons without complaint or reported diagnosis'
  },
  {
    code: 'Y74',
    label: 'General hospital and personal-use devices associated with misadventures'
  },
  {
    code: 'O14',
    label: 'Gestational [pregnancy-induced] hypertension with significant proteinuria'
  },
  {
    code: 'O13',
    label: 'Gestational [pregnancy-induced] hypertension without significant proteinuria'
  },
  {
    code: 'O12',
    label: 'Gestational [pregnancy-induced] oedema and proteinuria without hypertension'
  },
  {
    code: 'K05',
    label: 'Gingivitis and periodontal diseases'
  },
  {
    code: 'A24',
    label: 'Glanders and melioidosis'
  },
  {
    code: 'H40',
    label: 'Glaucoma'
  },
  {
    code: 'H42',
    label: 'Glaucoma in diseases classified elsewhere'
  },
  {
    code: 'N08',
    label: 'Glomerular disorders in diseases classified elsewhere'
  },
  {
    code: 'R81',
    label: 'Glycosuria'
  },
  {
    code: 'M17',
    label: 'Gonarthrosis [arthrosis of knee]'
  },
  {
    code: 'A54',
    label: 'Gonococcal infection'
  },
  {
    code: 'M10',
    label: 'Gout'
  },
  {
    code: 'A58',
    label: 'Granuloma inguinale'
  },
  {
    code: 'L92',
    label: 'Granulomatous disorders of skin and subcutaneous tissue'
  },
  {
    code: 'F63',
    label: 'Habit and impulse disorders'
  },
  {
    code: 'N96',
    label: 'Habitual aborter'
  },
  {
    code: 'D18',
    label: 'Haemangioma and lymphangioma'
  },
  {
    code: 'R04',
    label: 'Haemorrhage from respiratory passages'
  },
  {
    code: 'O20',
    label: 'Haemorrhage in early pregnancy'
  },
  {
    code: 'R58',
    label: 'Haemorrhage, not elsewhere classified'
  },
  {
    code: 'P53',
    label: 'Haemorrhagic disease of fetus and newborn'
  },
  {
    code: 'I84',
    label: 'Haemorrhoids'
  },
  {
    code: 'L67',
    label: 'Hair colour and hair shaft abnormalities'
  },
  {
    code: 'W32',
    label: 'Handgun discharge'
  },
  {
    code: 'Y22',
    label: 'Handgun discharge, undetermined intent'
  },
  {
    code: 'Y20',
    label: 'Hanging, strangulation and suffocation, undetermined intent'
  },
  {
    code: 'F55',
    label: 'Harmful use of nondependence-producing substances'
  },
  {
    code: 'R51',
    label: 'Headache'
  },
  {
    code: 'I50',
    label: 'Heart failure'
  },
  {
    code: 'R12',
    label: 'Heartburn'
  },
  {
    code: 'G81',
    label: 'Hemiplegia'
  },
  {
    code: 'K72',
    label: 'Hepatic failure, not elsewhere classified'
  },
  {
    code: 'R16',
    label: 'Hepatomegaly and splenomegaly, not elsewhere classified'
  },
  {
    code: 'G60',
    label: 'Hereditary and idiopathic neuropathy'
  },
  {
    code: 'G11',
    label: 'Hereditary ataxia'
  },
  {
    code: 'D67',
    label: 'Hereditary factor IX deficiency'
  },
  {
    code: 'D66',
    label: 'Hereditary factor VIII deficiency'
  },
  {
    code: 'N07',
    label: 'Hereditary nephropathy, not elsewhere classified'
  },
  {
    code: 'B00',
    label: 'Herpesviral [herpes simplex] infections'
  },
  {
    code: 'B39',
    label: 'Histoplasmosis'
  },
  {
    code: 'W50',
    label: 'Hit, struck, kicked, twisted, bitten or scratched by another person'
  },
  {
    code: 'C81',
    label: 'Hodgkin lymphoma'
  },
  {
    code: 'B76',
    label: 'Hookworm diseases'
  },
  {
    code: 'H00',
    label: 'Hordeolum and chalazion'
  },
  {
    code: 'Y42',
    label:
      'Hormones and their synthetic substitutes and antagonists, not elsewhere classified causing adverse effects in therapeutic use'
  },
  {
    code: 'B20',
    label: 'Human immunodeficiency virus [HIV] disease resulting in infectious and parasitic diseases'
  },
  {
    code: 'B21',
    label: 'Human immunodeficiency virus [HIV] disease resulting in malignant neoplasms'
  },
  {
    code: 'B23',
    label: 'Human immunodeficiency virus [HIV] disease resulting in other conditions'
  },
  {
    code: 'B22',
    label: 'Human immunodeficiency virus [HIV] disease resulting in other specified diseases'
  },
  {
    code: 'G10',
    label: "Huntington's disease"
  },
  {
    code: 'O01',
    label: 'Hydatidiform mole'
  },
  {
    code: 'N43',
    label: 'Hydrocele and spermatocele'
  },
  {
    code: 'G91',
    label: 'Hydrocephalus'
  },
  {
    code: 'P56',
    label: 'Hydrops fetalis due to haemolytic disease'
  },
  {
    code: 'E26',
    label: 'Hyperaldosteronism'
  },
  {
    code: 'E22',
    label: 'Hyperfunction of pituitary gland'
  },
  {
    code: 'R61',
    label: 'Hyperhidrosis'
  },
  {
    code: 'F90',
    label: 'Hyperkinetic disorders'
  },
  {
    code: 'E21',
    label: 'Hyperparathyroidism and other disorders of parathyroid gland'
  },
  {
    code: 'N40',
    label: 'Hyperplasia of prostate'
  },
  {
    code: 'J67',
    label: 'Hypersensitivity pneumonitis due to organic dust'
  },
  {
    code: 'I13',
    label: 'Hypertensive heart and kidney disease'
  },
  {
    code: 'I11',
    label: 'Hypertensive heart disease'
  },
  {
    code: 'I12',
    label: 'Hypertensive kidney disease'
  },
  {
    code: 'L68',
    label: 'Hypertrichosis'
  },
  {
    code: 'L91',
    label: 'Hypertrophic disorders of skin'
  },
  {
    code: 'N62',
    label: 'Hypertrophy of breast'
  },
  {
    code: 'E23',
    label: 'Hypofunction and other disorders of pituitary gland'
  },
  {
    code: 'E20',
    label: 'Hypoparathyroidism'
  },
  {
    code: 'Q54',
    label: 'Hypospadias'
  },
  {
    code: 'I95',
    label: 'Hypotension'
  },
  {
    code: 'T68',
    label: 'Hypothermia'
  },
  {
    code: 'P80',
    label: 'Hypothermia of newborn'
  },
  {
    code: 'U55',
    label: 'Ice and snow sports'
  },
  {
    code: 'P55',
    label: 'Immune haemolytic disease of fetus and newborn'
  },
  {
    code: 'Z28',
    label: 'Immunisation not carried out'
  },
  {
    code: 'D82',
    label: 'Immunodeficiency associated with other major defects'
  },
  {
    code: 'D80',
    label: 'Immunodeficiency with predominantly antibody defects'
  },
  {
    code: 'E09',
    label: 'Impaired glucose regulation'
  },
  {
    code: 'L01',
    label: 'Impetigo'
  },
  {
    code: 'Q56',
    label: 'Indeterminate sex and pseudohermaphroditism'
  },
  {
    code: 'U56',
    label: 'Individual athletic activities'
  },
  {
    code: 'U54',
    label: 'Individual water sports'
  },
  {
    code: 'F24',
    label: 'Induced delusional disorder'
  },
  {
    code: 'A31',
    label: 'Infection due to other mycobacteria'
  },
  {
    code: 'O91',
    label: 'Infections of breast associated with childbirth'
  },
  {
    code: 'O23',
    label: 'Infections of genitourinary tract in pregnancy'
  },
  {
    code: 'B27',
    label: 'Infectious mononucleosis'
  },
  {
    code: 'N72',
    label: 'Inflammatory disease of cervix uteri'
  },
  {
    code: 'N71',
    label: 'Inflammatory disease of uterus, except cervix'
  },
  {
    code: 'N41',
    label: 'Inflammatory diseases of prostate'
  },
  {
    code: 'N61',
    label: 'Inflammatory disorders of breast'
  },
  {
    code: 'N49',
    label: 'Inflammatory disorders of male genital organs, not elsewhere classified'
  },
  {
    code: 'G61',
    label: 'Inflammatory polyneuropathy'
  },
  {
    code: 'J09',
    label: 'Influenza due to identified avian influenza virus'
  },
  {
    code: 'J10',
    label: 'Influenza due to other identified influenza virus'
  },
  {
    code: 'J11',
    label: 'Influenza, virus not identified'
  },
  {
    code: 'K40',
    label: 'Inguinal hernia'
  },
  {
    code: 'W79',
    label: 'Inhalation and ingestion of food causing obstruction of respiratory tract'
  },
  {
    code: 'W80',
    label: 'Inhalation and ingestion of other objects causing obstruction of respiratory tract'
  },
  {
    code: 'W78',
    label: 'Inhalation of gastric contents'
  },
  {
    code: 'S35',
    label: 'Injury of blood vessels at abdomen, lower back and pelvis level'
  },
  {
    code: 'S95',
    label: 'Injury of blood vessels at ankle and foot level'
  },
  {
    code: 'S55',
    label: 'Injury of blood vessels at forearm level'
  },
  {
    code: 'S75',
    label: 'Injury of blood vessels at hip and thigh level'
  },
  {
    code: 'S85',
    label: 'Injury of blood vessels at lower leg level'
  },
  {
    code: 'S15',
    label: 'Injury of blood vessels at neck level'
  },
  {
    code: 'S45',
    label: 'Injury of blood vessels at shoulder and upper arm level'
  },
  {
    code: 'S65',
    label: 'Injury of blood vessels at wrist and hand level'
  },
  {
    code: 'S25',
    label: 'Injury of blood vessels of thorax'
  },
  {
    code: 'S04',
    label: 'Injury of cranial nerves'
  },
  {
    code: 'S05',
    label: 'Injury of eye and orbit'
  },
  {
    code: 'S26',
    label: 'Injury of heart'
  },
  {
    code: 'S36',
    label: 'Injury of intra-abdominal organs'
  },
  {
    code: 'S96',
    label: 'Injury of muscle and tendon at ankle and foot level'
  },
  {
    code: 'S56',
    label: 'Injury of muscle and tendon at forearm level'
  },
  {
    code: 'S76',
    label: 'Injury of muscle and tendon at hip and thigh level'
  },
  {
    code: 'S86',
    label: 'Injury of muscle and tendon at lower leg level'
  },
  {
    code: 'S16',
    label: 'Injury of muscle and tendon at neck level'
  },
  {
    code: 'S46',
    label: 'Injury of muscle and tendon at shoulder and upper arm level'
  },
  {
    code: 'S66',
    label: 'Injury of muscle and tendon at wrist and hand level'
  },
  {
    code: 'S34',
    label: 'Injury of nerves and lumbar spinal cord at abdomen, lower back and pelvis level'
  },
  {
    code: 'S14',
    label: 'Injury of nerves and spinal cord at neck level'
  },
  {
    code: 'S24',
    label: 'Injury of nerves and spinal cord at thorax level'
  },
  {
    code: 'S94',
    label: 'Injury of nerves at ankle and foot level'
  },
  {
    code: 'S54',
    label: 'Injury of nerves at forearm level'
  },
  {
    code: 'S74',
    label: 'Injury of nerves at hip and thigh level'
  },
  {
    code: 'S84',
    label: 'Injury of nerves at lower leg level'
  },
  {
    code: 'S44',
    label: 'Injury of nerves at shoulder and upper arm level'
  },
  {
    code: 'S64',
    label: 'Injury of nerves at wrist and hand level'
  },
  {
    code: 'S27',
    label: 'Injury of other and unspecified intrathoracic organs'
  },
  {
    code: 'T14',
    label: 'Injury of unspecified body region'
  },
  {
    code: 'S37',
    label: 'Injury of urinary and pelvic organs'
  },
  {
    code: 'X79',
    label: 'Intentional self-harm by blunt object'
  },
  {
    code: 'X82',
    label: 'Intentional self-harm by crashing of motor vehicle'
  },
  {
    code: 'X71',
    label: 'Intentional self-harm by drowning and submersion'
  },
  {
    code: 'X75',
    label: 'Intentional self-harm by explosive material'
  },
  {
    code: 'X72',
    label: 'Intentional self-harm by handgun discharge'
  },
  {
    code: 'X70',
    label: 'Intentional self-harm by hanging, strangulation and suffocation'
  },
  {
    code: 'X80',
    label: 'Intentional self-harm by jumping from a high place'
  },
  {
    code: 'X81',
    label: 'Intentional self-harm by jumping or lying before moving object'
  },
  {
    code: 'X74',
    label: 'Intentional self-harm by other and unspecified firearm discharge'
  },
  {
    code: 'X83',
    label: 'Intentional self-harm by other specified means'
  },
  {
    code: 'X78',
    label: 'Intentional self-harm by sharp object'
  },
  {
    code: 'X76',
    label: 'Intentional self-harm by smoke, fire and flames'
  },
  {
    code: 'X77',
    label: 'Intentional self-harm by steam, hot vapours and hot objects'
  },
  {
    code: 'X84',
    label: 'Intentional self-harm by unspecified means'
  },
  {
    code: 'X65',
    label: 'Intentional self-poisoning by and exposure to alcohol'
  },
  {
    code: 'X61',
    label:
      'Intentional self-poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified'
  },
  {
    code: 'X62',
    label:
      'Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified'
  },
  {
    code: 'X60',
    label: 'Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics'
  },
  {
    code: 'X66',
    label:
      'Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours'
  },
  {
    code: 'X69',
    label: 'Intentional self-poisoning by and exposure to other and unspecified chemicals and noxious substances'
  },
  {
    code: 'X64',
    label:
      'Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances'
  },
  {
    code: 'X63',
    label: 'Intentional self-poisoning by and exposure to other drugs acting on the autonomic nervous system'
  },
  {
    code: 'X67',
    label: 'Intentional self-poisoning by and exposure to other gases and vapours'
  },
  {
    code: 'X68',
    label: 'Intentional self-poisoning by and exposure to pesticides'
  },
  {
    code: 'M23',
    label: 'Internal derangement of knee'
  },
  {
    code: 'K90',
    label: 'Intestinal malabsorption'
  },
  {
    code: 'I61',
    label: 'Intracerebral haemorrhage'
  },
  {
    code: 'G06',
    label: 'Intracranial and intraspinal abscess and granuloma'
  },
  {
    code: 'G07',
    label: 'Intracranial and intraspinal abscess and granuloma in diseases classified elsewhere'
  },
  {
    code: 'G08',
    label: 'Intracranial and intraspinal phlebitis and thrombophlebitis'
  },
  {
    code: 'S06',
    label: 'Intracranial injury'
  },
  {
    code: 'P10',
    label: 'Intracranial laceration and haemorrhage due to birth trauma'
  },
  {
    code: 'P52',
    label: 'Intracranial nontraumatic haemorrhage of fetus and newborn'
  },
  {
    code: 'P20',
    label: 'Intrauterine hypoxia'
  },
  {
    code: 'E01',
    label: 'Iodine-deficiency-related thyroid disorders and allied conditions'
  },
  {
    code: 'H20',
    label: 'Iridocyclitis'
  },
  {
    code: 'D50',
    label: 'Iron deficiency anaemia'
  },
  {
    code: 'K58',
    label: 'Irritable bowel syndrome'
  },
  {
    code: 'L24',
    label: 'Irritant contact dermatitis'
  },
  {
    code: 'R80',
    label: 'Isolated proteinuria'
  },
  {
    code: 'N06',
    label: 'Isolated proteinuria with specified morphological lesion'
  },
  {
    code: 'M08',
    label: 'Juvenile arthritis'
  },
  {
    code: 'M09',
    label: 'Juvenile arthritis in diseases classified elsewhere'
  },
  {
    code: 'M91',
    label: 'Juvenile osteochondrosis of hip and pelvis'
  },
  {
    code: 'C46',
    label: 'Kaposi sarcoma'
  },
  {
    code: 'H16',
    label: 'Keratitis'
  },
  {
    code: 'L86',
    label: 'Keratoderma in diseases classified elsewhere'
  },
  {
    code: 'P57',
    label: 'Kernicterus'
  },
  {
    code: 'E40',
    label: 'Kwashiorkor'
  },
  {
    code: 'M40',
    label: 'Kyphosis and lordosis'
  },
  {
    code: 'R75',
    label: 'Laboratory evidence of human immunodeficiency virus [HIV]'
  },
  {
    code: 'O64',
    label: 'Labour and delivery affected by malposition and malpresentation of fetus'
  },
  {
    code: 'O65',
    label: 'Labour and delivery affected by maternal pelvic abnormality'
  },
  {
    code: 'O68',
    label: 'Labour and delivery complicated by fetal stress [distress]'
  },
  {
    code: 'O67',
    label: 'Labour and delivery complicated by intrapartum haemorrhage, not elsewhere classified'
  },
  {
    code: 'O69',
    label: 'Labour and delivery complicated by umbilical cord complications'
  },
  {
    code: 'R62',
    label: 'Lack of expected normal physiological development'
  },
  {
    code: 'X53',
    label: 'Lack of food'
  },
  {
    code: 'X54',
    label: 'Lack of water'
  },
  {
    code: 'E73',
    label: 'Lactose intolerance'
  },
  {
    code: 'A52',
    label: 'Late syphilis'
  },
  {
    code: 'Y35',
    label: 'Legal intervention'
  },
  {
    code: 'D25',
    label: 'Leiomyoma of uterus'
  },
  {
    code: 'B55',
    label: 'Leishmaniasis'
  },
  {
    code: 'U72',
    label: 'Leisure activity, not elsewhere classified'
  },
  {
    code: 'A30',
    label: "Leprosy [Hansen's disease]"
  },
  {
    code: 'A27',
    label: 'Leptospirosis'
  },
  {
    code: 'C95',
    label: 'Leukaemia of unspecified cell type'
  },
  {
    code: 'L43',
    label: 'Lichen planus'
  },
  {
    code: 'L28',
    label: 'Lichen simplex chronicus and prurigo'
  },
  {
    code: 'Y98',
    label: 'Lifestyle-related condition'
  },
  {
    code: 'A32',
    label: 'Listeriosis'
  },
  {
    code: 'Z38',
    label: 'Liveborn infants according to place of birth'
  },
  {
    code: 'K77',
    label: 'Liver disorders in diseases classified elsewhere'
  },
  {
    code: 'E65',
    label: 'Localised adiposity'
  },
  {
    code: 'R22',
    label: 'Localised swelling, mass and lump of skin and subcutaneous tissue'
  },
  {
    code: 'O63',
    label: 'Long labour'
  },
  {
    code: 'L93',
    label: 'Lupus erythematosus'
  },
  {
    code: 'C91',
    label: 'Lymphoid leukaemia'
  },
  {
    code: 'R53',
    label: 'Malaise and fatigue'
  },
  {
    code: 'N46',
    label: 'Male infertility'
  },
  {
    code: 'C88',
    label: 'Malignant immunoproliferative diseases'
  },
  {
    code: 'C43',
    label: 'Malignant melanoma of skin'
  },
  {
    code: 'C31',
    label: 'Malignant neoplasm of accessory sinuses'
  },
  {
    code: 'C74',
    label: 'Malignant neoplasm of adrenal gland'
  },
  {
    code: 'C21',
    label: 'Malignant neoplasm of anus and anal canal'
  },
  {
    code: 'C01',
    label: 'Malignant neoplasm of base of tongue'
  },
  {
    code: 'C67',
    label: 'Malignant neoplasm of bladder'
  },
  {
    code: 'C40',
    label: 'Malignant neoplasm of bone and articular cartilage of limbs'
  },
  {
    code: 'C41',
    label: 'Malignant neoplasm of bone and articular cartilage of other and unspecified sites'
  },
  {
    code: 'C71',
    label: 'Malignant neoplasm of brain'
  },
  {
    code: 'C50',
    label: 'Malignant neoplasm of breast'
  },
  {
    code: 'C34',
    label: 'Malignant neoplasm of bronchus and lung'
  },
  {
    code: 'C53',
    label: 'Malignant neoplasm of cervix uteri'
  },
  {
    code: 'C18',
    label: 'Malignant neoplasm of colon'
  },
  {
    code: 'C54',
    label: 'Malignant neoplasm of corpus uteri'
  },
  {
    code: 'C69',
    label: 'Malignant neoplasm of eye and adnexa'
  },
  {
    code: 'C04',
    label: 'Malignant neoplasm of floor of mouth'
  },
  {
    code: 'C23',
    label: 'Malignant neoplasm of gallbladder'
  },
  {
    code: 'C03',
    label: 'Malignant neoplasm of gum'
  },
  {
    code: 'C38',
    label: 'Malignant neoplasm of heart, mediastinum and pleura'
  },
  {
    code: 'C13',
    label: 'Malignant neoplasm of hypopharynx'
  },
  {
    code: 'C64',
    label: 'Malignant neoplasm of kidney, except renal pelvis'
  },
  {
    code: 'C32',
    label: 'Malignant neoplasm of larynx'
  },
  {
    code: 'C00',
    label: 'Malignant neoplasm of lip'
  },
  {
    code: 'C22',
    label: 'Malignant neoplasm of liver and intrahepatic bile ducts'
  },
  {
    code: 'C48',
    label: 'Malignant neoplasm of malignant neoplasm of retroperitoneum and peritoneum'
  },
  {
    code: 'C70',
    label: 'Malignant neoplasm of meninges'
  },
  {
    code: 'C30',
    label: 'Malignant neoplasm of nasal cavity and middle ear'
  },
  {
    code: 'C11',
    label: 'Malignant neoplasm of nasopharynx'
  },
  {
    code: 'C15',
    label: 'Malignant neoplasm of oesophagus'
  },
  {
    code: 'C10',
    label: 'Malignant neoplasm of oropharynx'
  },
  {
    code: 'C26',
    label: 'Malignant neoplasm of other and ill-defined digestive organs'
  },
  {
    code: 'C76',
    label: 'Malignant neoplasm of other and ill-defined sites'
  },
  {
    code: 'C14',
    label: 'Malignant neoplasm of other and ill-defined sites in the lip, oral cavity and pharynx'
  },
  {
    code: 'C39',
    label: 'Malignant neoplasm of other and ill-defined sites in the respiratory system and intrathoracic organs'
  },
  {
    code: 'C57',
    label: 'Malignant neoplasm of other and unspecified female genital organs'
  },
  {
    code: 'C08',
    label: 'Malignant neoplasm of other and unspecified major salivary glands'
  },
  {
    code: 'C63',
    label: 'Malignant neoplasm of other and unspecified male genital organs'
  },
  {
    code: 'C24',
    label: 'Malignant neoplasm of other and unspecified parts of biliary tract'
  },
  {
    code: 'C06',
    label: 'Malignant neoplasm of other and unspecified parts of mouth'
  },
  {
    code: 'C02',
    label: 'Malignant neoplasm of other and unspecified parts of tongue'
  },
  {
    code: 'C68',
    label: 'Malignant neoplasm of other and unspecified urinary organs'
  },
  {
    code: 'C49',
    label: 'Malignant neoplasm of other connective and soft tissue'
  },
  {
    code: 'C75',
    label: 'Malignant neoplasm of other endocrine glands and related structures'
  },
  {
    code: 'C56',
    label: 'Malignant neoplasm of ovary'
  },
  {
    code: 'C05',
    label: 'Malignant neoplasm of palate'
  },
  {
    code: 'C25',
    label: 'Malignant neoplasm of pancreas'
  },
  {
    code: 'C07',
    label: 'Malignant neoplasm of parotid gland'
  },
  {
    code: 'C60',
    label: 'Malignant neoplasm of penis'
  },
  {
    code: 'C47',
    label: 'Malignant neoplasm of peripheral nerves and autonomic nervous system'
  },
  {
    code: 'C58',
    label: 'Malignant neoplasm of placenta'
  },
  {
    code: 'C61',
    label: 'Malignant neoplasm of prostate'
  },
  {
    code: 'C12',
    label: 'Malignant neoplasm of pyriform sinus'
  },
  {
    code: 'C19',
    label: 'Malignant neoplasm of rectosigmoid junction'
  },
  {
    code: 'C20',
    label: 'Malignant neoplasm of rectum'
  },
  {
    code: 'C65',
    label: 'Malignant neoplasm of renal pelvis'
  },
  {
    code: 'C17',
    label: 'Malignant neoplasm of small intestine'
  },
  {
    code: 'C72',
    label: 'Malignant neoplasm of spinal cord, cranial nerves and other parts of central nervous system'
  },
  {
    code: 'C16',
    label: 'Malignant neoplasm of stomach'
  },
  {
    code: 'C62',
    label: 'Malignant neoplasm of testis'
  },
  {
    code: 'C37',
    label: 'Malignant neoplasm of thymus'
  },
  {
    code: 'C73',
    label: 'Malignant neoplasm of thyroid gland'
  },
  {
    code: 'C09',
    label: 'Malignant neoplasm of tonsil'
  },
  {
    code: 'C33',
    label: 'Malignant neoplasm of trachea'
  },
  {
    code: 'C66',
    label: 'Malignant neoplasm of ureter'
  },
  {
    code: 'C55',
    label: 'Malignant neoplasm of uterus, part unspecified'
  },
  {
    code: 'C52',
    label: 'Malignant neoplasm of vagina'
  },
  {
    code: 'C51',
    label: 'Malignant neoplasm of vulva'
  },
  {
    code: 'C80',
    label: 'Malignant neoplasm without specification of site'
  },
  {
    code: 'O25',
    label: 'Malnutrition in pregnancy'
  },
  {
    code: 'T74',
    label: 'Maltreatment syndromes'
  },
  {
    code: 'F30',
    label: 'Manic episode'
  },
  {
    code: 'E42',
    label: 'Marasmic kwashiorkor'
  },
  {
    code: 'H70',
    label: 'Mastoiditis and related conditions'
  },
  {
    code: 'O34',
    label: 'Maternal care for known or suspected abnormality of pelvic organs'
  },
  {
    code: 'O33',
    label: 'Maternal care for known or suspected disproportion'
  },
  {
    code: 'O35',
    label: 'Maternal care for known or suspected fetal abnormality and damage'
  },
  {
    code: 'O32',
    label: 'Maternal care for known or suspected malpresentation of fetus'
  },
  {
    code: 'O26',
    label: 'Maternal care for other conditions predominantly related to pregnancy'
  },
  {
    code: 'O36',
    label: 'Maternal care for other known or suspected fetal problems'
  },
  {
    code: 'O98',
    label:
      'Maternal infectious and parasitic diseases classifiable elsewhere but complicating pregnancy, childbirth and the puerperium'
  },
  {
    code: 'B05',
    label: 'Measles'
  },
  {
    code: 'P75',
    label: 'Meconium ileus in cystic fibrosis (E84.1+)'
  },
  {
    code: 'O04',
    label: 'Medical abortion'
  },
  {
    code: 'Z03',
    label: 'Medical observation and evaluation for suspected diseases and conditions'
  },
  {
    code: 'D22',
    label: 'Melanocytic naevi'
  },
  {
    code: 'D03',
    label: 'Melanoma in situ'
  },
  {
    code: 'G03',
    label: 'Meningitis due to other and unspecified causes'
  },
  {
    code: 'G01',
    label: 'Meningitis in bacterial diseases classified elsewhere'
  },
  {
    code: 'G02',
    label: 'Meningitis in other infectious and parasitic diseases classified elsewhere'
  },
  {
    code: 'A39',
    label: 'Meningococcal infection'
  },
  {
    code: 'N95',
    label: 'Menopausal and other perimenopausal disorders'
  },
  {
    code: 'F53',
    label: 'Mental and behavioural disorders associated with the puerperium, not elsewhere classified'
  },
  {
    code: 'F19',
    label: 'Mental and behavioural disorders due to multiple drug use and use of other psychoactive substances'
  },
  {
    code: 'F10',
    label: 'Mental and behavioural disorders due to use of alcohol'
  },
  {
    code: 'F12',
    label: 'Mental and behavioural disorders due to use of cannabinoids'
  },
  {
    code: 'F14',
    label: 'Mental and behavioural disorders due to use of cocaine'
  },
  {
    code: 'F16',
    label: 'Mental and behavioural disorders due to use of hallucinogens'
  },
  {
    code: 'F11',
    label: 'Mental and behavioural disorders due to use of opioids'
  },
  {
    code: 'F15',
    label: 'Mental and behavioural disorders due to use of other stimulants, including caffeine'
  },
  {
    code: 'F13',
    label: 'Mental and behavioural disorders due to use of sedatives or hypnotics'
  },
  {
    code: 'F17',
    label: 'Mental and behavioural disorders due to use of tobacco'
  },
  {
    code: 'F18',
    label: 'Mental and behavioural disorders due to use of volatile solvents'
  },
  {
    code: 'F99',
    label: 'Mental disorder, not otherwise specified'
  },
  {
    code: 'C45',
    label: 'Mesothelioma'
  },
  {
    code: 'D74',
    label: 'Methaemoglobinaemia'
  },
  {
    code: 'Q02',
    label: 'Microcephaly'
  },
  {
    code: 'G43',
    label: 'Migraine'
  },
  {
    code: 'F70',
    label: 'Mild mental retardation'
  },
  {
    code: 'A19',
    label: 'Miliary tuberculosis'
  },
  {
    code: 'F61',
    label: 'Mixed and other personality disorders'
  },
  {
    code: 'F92',
    label: 'Mixed disorders of conduct and emotions'
  },
  {
    code: 'F83',
    label: 'Mixed specific developmental disorders'
  },
  {
    code: 'F71',
    label: 'Moderate mental retardation'
  },
  {
    code: 'B04',
    label: 'Monkeypox'
  },
  {
    code: 'C93',
    label: 'Monocytic leukaemia'
  },
  {
    code: 'G57',
    label: 'Mononeuropathies of lower limb'
  },
  {
    code: 'G56',
    label: 'Mononeuropathies of upper limb'
  },
  {
    code: 'G59',
    label: 'Mononeuropathy in diseases classified elsewhere'
  },
  {
    code: 'Q93',
    label: 'Monosomies and deletions from the autosomes, not elsewhere classified'
  },
  {
    code: 'A83',
    label: 'Mosquito-borne viral encephalitis'
  },
  {
    code: 'V89',
    label: 'Motor- or nonmotor-vehicle accident, type of vehicle unspecified'
  },
  {
    code: 'V23',
    label: 'Motorcycle rider injured in collision with car, pick-up truck or van'
  },
  {
    code: 'V27',
    label: 'Motorcycle rider injured in collision with fixed or stationary object'
  },
  {
    code: 'V24',
    label: 'Motorcycle rider injured in collision with heavy transport vehicle or bus'
  },
  {
    code: 'V26',
    label: 'Motorcycle rider injured in collision with other nonmotor vehicle'
  },
  {
    code: 'V21',
    label: 'Motorcycle rider injured in collision with pedal cycle'
  },
  {
    code: 'V20',
    label: 'Motorcycle rider injured in collision with pedestrian or animal'
  },
  {
    code: 'V25',
    label: 'Motorcycle rider injured in collision with railway train or railway vehicle'
  },
  {
    code: 'V22',
    label: 'Motorcycle rider injured in collision with two- or three-wheeled motor vehicle'
  },
  {
    code: 'V28',
    label: 'Motorcycle rider injured in noncollision transport accident'
  },
  {
    code: 'V29',
    label: 'Motorcycle rider injured in other and unspecified transport accidents'
  },
  {
    code: 'U67',
    label: 'Multidiscipline sports'
  },
  {
    code: 'O30',
    label: 'Multiple gestation'
  },
  {
    code: 'C90',
    label: 'Multiple myeloma and malignant plasma cell neoplasms'
  },
  {
    code: 'G35',
    label: 'Multiple sclerosis'
  },
  {
    code: 'I08',
    label: 'Multiple valve diseases'
  },
  {
    code: 'B26',
    label: 'Mumps'
  },
  {
    code: 'G70',
    label: 'Myasthenia gravis and other myoneural disorders'
  },
  {
    code: 'B47',
    label: 'Mycetoma'
  },
  {
    code: 'D46',
    label: 'Myelodysplastic syndromes'
  },
  {
    code: 'C92',
    label: 'Myeloid leukaemia'
  },
  {
    code: 'B87',
    label: 'Myiasis'
  },
  {
    code: 'I41',
    label: 'Myocarditis in diseases classified elsewhere'
  },
  {
    code: 'M60',
    label: 'Myositis'
  },
  {
    code: 'L60',
    label: 'Nail disorders'
  },
  {
    code: 'L62',
    label: 'Nail disorders in diseases classified elsewhere'
  },
  {
    code: 'J33',
    label: 'Nasal polyp'
  },
  {
    code: 'R11',
    label: 'Nausea and vomiting'
  },
  {
    code: 'P77',
    label: 'Necrotising enterocolitis of fetus and newborn'
  },
  {
    code: 'Z24',
    label: 'Need for immunisation against certain single viral diseases'
  },
  {
    code: 'Z27',
    label: 'Need for immunisation against combinations of infectious diseases'
  },
  {
    code: 'Z26',
    label: 'Need for immunisation against other single infectious diseases'
  },
  {
    code: 'Z25',
    label: 'Need for immunisation against other single viral diseases'
  },
  {
    code: 'Z23',
    label: 'Need for immunisation against single bacterial diseases'
  },
  {
    code: 'V07',
    label: 'Need for isolation and other prophylactic measures'
  },
  {
    code: 'Z29',
    label: 'Need for other prophylactic measures'
  },
  {
    code: 'Y06',
    label: 'Neglect and abandonment'
  },
  {
    code: 'P24',
    label: 'Neonatal aspiration syndromes'
  },
  {
    code: 'P58',
    label: 'Neonatal jaundice due to other excessive haemolysis'
  },
  {
    code: 'P59',
    label: 'Neonatal jaundice from other and unspecified causes'
  },
  {
    code: 'D43',
    label: 'Neoplasm of uncertain or unknown behaviour of brain and central nervous system'
  },
  {
    code: 'D44',
    label: 'Neoplasm of uncertain or unknown behaviour of endocrine glands'
  },
  {
    code: 'D39',
    label: 'Neoplasm of uncertain or unknown behaviour of female genital organs'
  },
  {
    code: 'D40',
    label: 'Neoplasm of uncertain or unknown behaviour of male genital organs'
  },
  {
    code: 'D42',
    label: 'Neoplasm of uncertain or unknown behaviour of meninges'
  },
  {
    code: 'D38',
    label: 'Neoplasm of uncertain or unknown behaviour of middle ear and respiratory and intrathoracic organs'
  },
  {
    code: 'D37',
    label: 'Neoplasm of uncertain or unknown behaviour of oral cavity and digestive organs'
  },
  {
    code: 'D48',
    label: 'Neoplasm of uncertain or unknown behaviour of other and unspecified sites'
  },
  {
    code: 'D41',
    label: 'Neoplasm of uncertain or unknown behaviour of urinary organs'
  },
  {
    code: 'N04',
    label: 'Nephrotic syndrome'
  },
  {
    code: 'G55',
    label: 'Nerve root and plexus compressions in diseases classified elsewhere'
  },
  {
    code: 'G54',
    label: 'Nerve root and plexus disorders'
  },
  {
    code: 'Y75',
    label: 'Neurological devices associated with misadventures'
  },
  {
    code: 'N31',
    label: 'Neuromuscular dysfunction of bladder, not elsewhere classified'
  },
  {
    code: 'E52',
    label: 'Niacin deficiency [pellagra]'
  },
  {
    code: 'A43',
    label: 'Nocardiosis'
  },
  {
    code: 'Y66',
    label: 'Nonadministration of surgical and medical care'
  },
  {
    code: 'E15',
    label: 'Nondiabetic hypoglycaemic coma'
  },
  {
    code: 'N83',
    label: 'Noninflammatory disorders of ovary, fallopian tube and broad ligament'
  },
  {
    code: 'F51',
    label: 'Nonorganic sleep disorders'
  },
  {
    code: 'I35',
    label: 'Nonrheumatic aortic valve disorders'
  },
  {
    code: 'I34',
    label: 'Nonrheumatic mitral valve disorders'
  },
  {
    code: 'I36',
    label: 'Nonrheumatic tricuspid valve disorders'
  },
  {
    code: 'I88',
    label: 'Nonspecific lymphadenitis'
  },
  {
    code: 'H65',
    label: 'Nonsuppurative otitis media'
  },
  {
    code: 'V88',
    label: "Nontraffic accident of specified type but victim's mode of transport unknown"
  },
  {
    code: 'A65',
    label: 'Nonvenereal syphilis'
  },
  {
    code: 'Y95',
    label: 'Nosocomial condition'
  },
  {
    code: 'E41',
    label: 'Nutritional marasmus'
  },
  {
    code: 'H55',
    label: 'Nystagmus and other irregular eye movements'
  },
  {
    code: 'E66',
    label: 'Obesity'
  },
  {
    code: 'F42',
    label: 'Obsessive-compulsive disorder'
  },
  {
    code: 'Y76',
    label: 'Obstetric and gynaecological devices associated with misadventures'
  },
  {
    code: 'O95',
    label: 'Obstetric death of unspecified cause'
  },
  {
    code: 'O88',
    label: 'Obstetric embolism'
  },
  {
    code: 'A34',
    label: 'Obstetrical tetanus'
  },
  {
    code: 'N13',
    label: 'Obstructive and reflux uropathy'
  },
  {
    code: 'I66',
    label: 'Occlusion and stenosis of cerebral arteries, not resulting in cerebral infarction'
  },
  {
    code: 'I65',
    label: 'Occlusion and stenosis of precerebral arteries, not resulting in cerebral infarction'
  },
  {
    code: 'V63',
    label: 'Occupant of heavy transport vehicle injured in collision with car, pick-up truck or van'
  },
  {
    code: 'V67',
    label: 'Occupant of heavy transport vehicle injured in collision with fixed or stationary object'
  },
  {
    code: 'V64',
    label: 'Occupant of heavy transport vehicle injured in collision with heavy transport vehicle or bus'
  },
  {
    code: 'V66',
    label: 'Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle'
  },
  {
    code: 'V61',
    label: 'Occupant of heavy transport vehicle injured in collision with pedal cycle'
  },
  {
    code: 'V60',
    label: 'Occupant of heavy transport vehicle injured in collision with pedestrian or animal'
  },
  {
    code: 'V65',
    label: 'Occupant of heavy transport vehicle injured in collision with railway train or railway vehicle'
  },
  {
    code: 'V62',
    label: 'Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle'
  },
  {
    code: 'V68',
    label: 'Occupant of heavy transport vehicle injured in noncollision transport accident'
  },
  {
    code: 'V69',
    label: 'Occupant of heavy transport vehicle injured in other and unspecified transport accidents'
  },
  {
    code: 'V53',
    label: 'Occupant of pick-up truck or van injured in collision with car, pick-up truck or van'
  },
  {
    code: 'V57',
    label: 'Occupant of pick-up truck or van injured in collision with fixed or stationary object'
  },
  {
    code: 'V54',
    label: 'Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus'
  },
  {
    code: 'V56',
    label: 'Occupant of pick-up truck or van injured in collision with other nonmotor vehicle'
  },
  {
    code: 'V51',
    label: 'Occupant of pick-up truck or van injured in collision with pedal cycle'
  },
  {
    code: 'V50',
    label: 'Occupant of pick-up truck or van injured in collision with pedestrian or animal'
  },
  {
    code: 'V55',
    label: 'Occupant of pick-up truck or van injured in collision with railway train or railway vehicle'
  },
  {
    code: 'V52',
    label: 'Occupant of pick-up truck or van injured in collision with two- or three-wheeled motor vehicle'
  },
  {
    code: 'V58',
    label: 'Occupant of pick-up truck or van injured in noncollision transport accident'
  },
  {
    code: 'V59',
    label: 'Occupant of pick-up truck or van injured in other and unspecified transport accidents'
  },
  {
    code: 'V81',
    label: 'Occupant of railway train or railway vehicle injured in transport accident'
  },
  {
    code: 'V86',
    label:
      'Occupant of special all-terrain or other motor vehicle designed primarily for off-road use, injured in transport accident'
  },
  {
    code: 'V85',
    label: 'Occupant of special construction vehicle injured in transport accident'
  },
  {
    code: 'V84',
    label: 'Occupant of special vehicle mainly used in agriculture injured in transport accident'
  },
  {
    code: 'V83',
    label: 'Occupant of special vehicle mainly used on industrial premises injured in transport accident'
  },
  {
    code: 'V82',
    label: 'Occupant of streetcar injured in transport accident'
  },
  {
    code: 'V33',
    label: 'Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van'
  },
  {
    code: 'V37',
    label: 'Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object'
  },
  {
    code: 'V34',
    label: 'Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus'
  },
  {
    code: 'V36',
    label: 'Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle'
  },
  {
    code: 'V31',
    label: 'Occupant of three-wheeled motor vehicle injured in collision with pedal cycle'
  },
  {
    code: 'V30',
    label: 'Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal'
  },
  {
    code: 'V35',
    label: 'Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle'
  },
  {
    code: 'V32',
    label: 'Occupant of three-wheeled motor vehicle injured in collision with two- or three-wheeled motor vehicle'
  },
  {
    code: 'V38',
    label: 'Occupant of three-wheeled motor vehicle injured in noncollision transport accident'
  },
  {
    code: 'V39',
    label: 'Occupant of three-wheeled motor vehicle injured in other and unspecified transport accidents'
  },
  {
    code: 'Z57',
    label: 'Occupational exposure to risk-factors'
  },
  {
    code: 'R60',
    label: 'Oedema, not elsewhere classified'
  },
  {
    code: 'I85',
    label: 'Oesophageal varices'
  },
  {
    code: 'K20',
    label: 'Oesophagitis'
  },
  {
    code: 'P38',
    label: 'Omphalitis of newborn with or without mild haemorrhage'
  },
  {
    code: 'B73',
    label: 'Onchocerciasis'
  },
  {
    code: 'S31',
    label: 'Open wound of abdomen, lower back and pelvis'
  },
  {
    code: 'S91',
    label: 'Open wound of ankle and foot'
  },
  {
    code: 'S51',
    label: 'Open wound of forearm'
  },
  {
    code: 'S01',
    label: 'Open wound of head'
  },
  {
    code: 'S71',
    label: 'Open wound of hip and thigh'
  },
  {
    code: 'S81',
    label: 'Open wound of lower leg'
  },
  {
    code: 'S11',
    label: 'Open wound of neck'
  },
  {
    code: 'S41',
    label: 'Open wound of shoulder and upper arm'
  },
  {
    code: 'S21',
    label: 'Open wound of thorax'
  },
  {
    code: 'S61',
    label: 'Open wound of wrist and hand'
  },
  {
    code: 'T01',
    label: 'Open wounds involving multiple body regions'
  },
  {
    code: 'Y36',
    label: 'Operations of war'
  },
  {
    code: 'Y77',
    label: 'Ophthalmic devices associated with misadventures'
  },
  {
    code: 'H46',
    label: 'Optic neuritis'
  },
  {
    code: 'N45',
    label: 'Orchitis and epididymitis'
  },
  {
    code: 'F04',
    label: 'Organic amnesic syndrome, not induced by alcohol and other psychoactive substances'
  },
  {
    code: 'Y79',
    label: 'Orthopaedic devices associated with misadventures'
  },
  {
    code: 'Q77',
    label: 'Osteochondrodysplasia with defects of growth of tubular bones and spine'
  },
  {
    code: 'M86',
    label: 'Osteomyelitis'
  },
  {
    code: 'M87',
    label: 'Osteonecrosis'
  },
  {
    code: 'M90',
    label: 'Osteopathies in diseases classified elsewhere'
  },
  {
    code: 'M82',
    label: 'Osteoporosis in diseases classified elsewhere'
  },
  {
    code: 'M80',
    label: 'Osteoporosis with pathological fracture'
  },
  {
    code: 'M81',
    label: 'Osteoporosis without pathological fracture'
  },
  {
    code: 'H92',
    label: 'Otalgia and effusion of ear'
  },
  {
    code: 'K45',
    label: 'Other abdominal hernia'
  },
  {
    code: 'R82',
    label: 'Other abnormal findings in urine'
  },
  {
    code: 'R79',
    label: 'Other abnormal findings of blood chemistry'
  },
  {
    code: 'R76',
    label: 'Other abnormal immunological findings in serum'
  },
  {
    code: 'O02',
    label: 'Other abnormal products of conception'
  },
  {
    code: 'N93',
    label: 'Other abnormal uterine and vaginal bleeding'
  },
  {
    code: 'R77',
    label: 'Other abnormalities of plasma proteins'
  },
  {
    code: 'O05',
    label: 'Other abortion'
  },
  {
    code: 'L11',
    label: 'Other acantholytic disorders'
  },
  {
    code: 'W76',
    label: 'Other accidental hanging and strangulation'
  },
  {
    code: 'M21',
    label: 'Other acquired deformities of limbs'
  },
  {
    code: 'M95',
    label: 'Other acquired deformities of musculoskeletal system and connective tissue'
  },
  {
    code: 'U73',
    label: 'Other activity'
  },
  {
    code: 'G36',
    label: 'Other acute disseminated demyelination'
  },
  {
    code: 'I24',
    label: 'Other acute ischaemic heart diseases'
  },
  {
    code: 'L56',
    label: 'Other acute skin changes due to ultraviolet radiation'
  },
  {
    code: 'B17',
    label: 'Other acute viral hepatitis'
  },
  {
    code: 'D64',
    label: 'Other anaemias'
  },
  {
    code: 'S79',
    label: 'Other and specified injuries of hip and thigh'
  },
  {
    code: 'I99',
    label: 'Other and unspecified disorders of circulatory system'
  },
  {
    code: 'Y57',
    label: 'Other and unspecified drugs and medicaments causing adverse effects in therapeutic use'
  },
  {
    code: 'Y24',
    label: 'Other and unspecified firearm discharge, undetermined intent'
  },
  {
    code: 'B99',
    label: 'Other and unspecified infectious diseases'
  },
  {
    code: 'S39',
    label: 'Other and unspecified injuries of abdomen, lower back and pelvis'
  },
  {
    code: 'S99',
    label: 'Other and unspecified injuries of ankle and foot'
  },
  {
    code: 'S59',
    label: 'Other and unspecified injuries of forearm'
  },
  {
    code: 'S09',
    label: 'Other and unspecified injuries of head'
  },
  {
    code: 'S89',
    label: 'Other and unspecified injuries of lower leg'
  },
  {
    code: 'S19',
    label: 'Other and unspecified injuries of neck'
  },
  {
    code: 'S49',
    label: 'Other and unspecified injuries of shoulder and upper arm'
  },
  {
    code: 'S29',
    label: 'Other and unspecified injuries of thorax'
  },
  {
    code: 'S69',
    label: 'Other and unspecified injuries of wrist and hand'
  },
  {
    code: 'C96',
    label: 'Other and unspecified malignant neoplasms of lymphoid, haematopoietic and related tissue'
  },
  {
    code: 'Y82',
    label: 'Other and unspecified medical devices associated with misadventures'
  },
  {
    code: 'A53',
    label: 'Other and unspecified syphilis'
  },
  {
    code: 'C85',
    label: 'Other and unspecified types of non-Hodgkin lymphoma'
  },
  {
    code: 'Y59',
    label: 'Other and unspecified vaccines and biological substances causing adverse effects in therapeutic use'
  },
  {
    code: 'V94',
    label: 'Other and unspecified water transport accidents'
  },
  {
    code: 'I72',
    label: 'Other aneurysm'
  },
  {
    code: 'F41',
    label: 'Other anxiety disorders'
  },
  {
    code: 'D61',
    label: 'Other aplastic anaemias'
  },
  {
    code: 'K36',
    label: 'Other appendicitis'
  },
  {
    code: 'M13',
    label: 'Other arthritis'
  },
  {
    code: 'A93',
    label: 'Other arthropod-borne viral fevers, not elsewhere classified'
  },
  {
    code: 'M19',
    label: 'Other arthrosis'
  },
  {
    code: 'B96',
    label: 'Other bacterial agents as the cause of diseases classified to other chapters'
  },
  {
    code: 'A48',
    label: 'Other bacterial diseases, not elsewhere classified'
  },
  {
    code: 'A05',
    label: 'Other bacterial food-borne intoxications, not elsewhere classified'
  },
  {
    code: 'A04',
    label: 'Other bacterial intestinal infections'
  },
  {
    code: 'F98',
    label: 'Other behavioural and emotional disorders with onset usually occurring in childhood and adolescence'
  },
  {
    code: 'D21',
    label: 'Other benign neoplasms of connective and other soft tissue'
  },
  {
    code: 'D23',
    label: 'Other benign neoplasms of skin'
  },
  {
    code: 'D26',
    label: 'Other benign neoplasms of uterus'
  },
  {
    code: 'P15',
    label: 'Other birth trauma'
  },
  {
    code: 'P11',
    label: 'Other birth trauma to central nervous system'
  },
  {
    code: 'L13',
    label: 'Other bullous disorders'
  },
  {
    code: 'M71',
    label: 'Other bursopathies'
  },
  {
    code: 'I49',
    label: 'Other cardiac arrhythmias'
  },
  {
    code: 'H26',
    label: 'Other cataract'
  },
  {
    code: 'I67',
    label: 'Other cerebrovascular diseases'
  },
  {
    code: 'B71',
    label: 'Other cestode infections'
  },
  {
    code: 'Q99',
    label: 'Other chromosome abnormalities, not elsewhere classified'
  },
  {
    code: 'J44',
    label: 'Other chronic obstructive pulmonary disease'
  },
  {
    code: 'D68',
    label: 'Other coagulation defects'
  },
  {
    code: 'O75',
    label: 'Other complications of labour and delivery, not elsewhere classified'
  },
  {
    code: 'T88',
    label: 'Other complications of surgical and medical care, not elsewhere classified'
  },
  {
    code: 'P83',
    label: 'Other conditions of integument specific to fetus and newborn'
  },
  {
    code: 'P96',
    label: 'Other conditions originating in the perinatal period'
  },
  {
    code: 'I45',
    label: 'Other conduction disorders'
  },
  {
    code: 'P37',
    label: 'Other congenital infectious and parasitic diseases'
  },
  {
    code: 'Q04',
    label: 'Other congenital malformations of brain'
  },
  {
    code: 'Q28',
    label: 'Other congenital malformations of circulatory system'
  },
  {
    code: 'Q45',
    label: 'Other congenital malformations of digestive system'
  },
  {
    code: 'Q17',
    label: 'Other congenital malformations of ear'
  },
  {
    code: 'Q15',
    label: 'Other congenital malformations of eye'
  },
  {
    code: 'Q18',
    label: 'Other congenital malformations of face and neck'
  },
  {
    code: 'Q52',
    label: 'Other congenital malformations of female genitalia'
  },
  {
    code: 'Q24',
    label: 'Other congenital malformations of heart'
  },
  {
    code: 'Q84',
    label: 'Other congenital malformations of integument'
  },
  {
    code: 'Q43',
    label: 'Other congenital malformations of intestine'
  },
  {
    code: 'Q63',
    label: 'Other congenital malformations of kidney'
  },
  {
    code: 'Q74',
    label: 'Other congenital malformations of limb(s)'
  },
  {
    code: 'Q55',
    label: 'Other congenital malformations of male genital organs'
  },
  {
    code: 'Q07',
    label: 'Other congenital malformations of nervous system'
  },
  {
    code: 'Q27',
    label: 'Other congenital malformations of peripheral vascular system'
  },
  {
    code: 'Q34',
    label: 'Other congenital malformations of respiratory system'
  },
  {
    code: 'Q82',
    label: 'Other congenital malformations of skin'
  },
  {
    code: 'Q75',
    label: 'Other congenital malformations of skull and face bones'
  },
  {
    code: 'Q06',
    label: 'Other congenital malformations of spinal cord'
  },
  {
    code: 'Q38',
    label: 'Other congenital malformations of tongue, mouth and pharynx'
  },
  {
    code: 'Q40',
    label: 'Other congenital malformations of upper alimentary tract'
  },
  {
    code: 'Q64',
    label: 'Other congenital malformations of urinary system'
  },
  {
    code: 'Q89',
    label: 'Other congenital malformations, not elsewhere classified'
  },
  {
    code: 'Q68',
    label: 'Other congenital musculoskeletal deformities'
  },
  {
    code: 'M11',
    label: 'Other crystal arthropathies'
  },
  {
    code: 'M43',
    label: 'Other deforming dorsopathies'
  },
  {
    code: 'G23',
    label: 'Other degenerative diseases of basal ganglia'
  },
  {
    code: 'G31',
    label: 'Other degenerative diseases of nervous system, not elsewhere classified'
  },
  {
    code: 'G32',
    label: 'Other degenerative disorders of nervous system in diseases classified elsewhere'
  },
  {
    code: 'G37',
    label: 'Other demyelinating diseases of central nervous system'
  },
  {
    code: 'L30',
    label: 'Other dermatitis'
  },
  {
    code: 'A74',
    label: 'Other diseases caused by chlamydiae'
  },
  {
    code: 'K62',
    label: 'Other diseases of anus and rectum'
  },
  {
    code: 'K38',
    label: 'Other diseases of appendix'
  },
  {
    code: 'K83',
    label: 'Other diseases of biliary tract'
  },
  {
    code: 'D75',
    label: 'Other diseases of blood and blood-forming organs'
  },
  {
    code: 'K92',
    label: 'Other diseases of digestive system'
  },
  {
    code: 'K82',
    label: 'Other diseases of gallbladder'
  },
  {
    code: 'K03',
    label: 'Other diseases of hard tissues of teeth'
  },
  {
    code: 'H83',
    label: 'Other diseases of inner ear'
  },
  {
    code: 'K63',
    label: 'Other diseases of intestine'
  },
  {
    code: 'K10',
    label: 'Other diseases of jaws'
  },
  {
    code: 'K13',
    label: 'Other diseases of lip and oral mucosa'
  },
  {
    code: 'K76',
    label: 'Other diseases of liver'
  },
  {
    code: 'K22',
    label: 'Other diseases of oesophagus'
  },
  {
    code: 'K86',
    label: 'Other diseases of pancreas'
  },
  {
    code: 'I31',
    label: 'Other diseases of pericardium'
  },
  {
    code: 'I28',
    label: 'Other diseases of pulmonary vessels'
  },
  {
    code: 'G95',
    label: 'Other diseases of spinal cord'
  },
  {
    code: 'K31',
    label: 'Other diseases of stomach and duodenum'
  },
  {
    code: 'J39',
    label: 'Other diseases of upper respiratory tract'
  },
  {
    code: 'D89',
    label: 'Other disorders involving the immune mechanism, not elsewhere classified'
  },
  {
    code: 'E27',
    label: 'Other disorders of adrenal gland'
  },
  {
    code: 'F68',
    label: 'Other disorders of adult personality and behaviour'
  },
  {
    code: 'E72',
    label: 'Other disorders of amino-acid metabolism'
  },
  {
    code: 'O41',
    label: 'Other disorders of amniotic fluid and membranes'
  },
  {
    code: 'I77',
    label: 'Other disorders of arteries and arterioles'
  },
  {
    code: 'H51',
    label: 'Other disorders of binocular movement'
  },
  {
    code: 'N32',
    label: 'Other disorders of bladder'
  },
  {
    code: 'D77',
    label: 'Other disorders of blood and blood-forming organs in diseases classified elsewhere'
  },
  {
    code: 'M89',
    label: 'Other disorders of bone'
  },
  {
    code: 'M85',
    label: 'Other disorders of bone density and structure'
  },
  {
    code: 'G93',
    label: 'Other disorders of brain'
  },
  {
    code: 'G94',
    label: 'Other disorders of brain in diseases classified elsewhere'
  },
  {
    code: 'N64',
    label: 'Other disorders of breast'
  },
  {
    code: 'O92',
    label: 'Other disorders of breast and lactation associated with childbirth'
  },
  {
    code: 'E74',
    label: 'Other disorders of carbohydrate metabolism'
  },
  {
    code: 'M94',
    label: 'Other disorders of cartilage'
  },
  {
    code: 'G96',
    label: 'Other disorders of central nervous system'
  },
  {
    code: 'H31',
    label: 'Other disorders of choroid'
  },
  {
    code: 'I98',
    label: 'Other disorders of circulatory system in diseases classified elsewhere'
  },
  {
    code: 'H11',
    label: 'Other disorders of conjunctiva'
  },
  {
    code: 'H18',
    label: 'Other disorders of cornea'
  },
  {
    code: 'H94',
    label: 'Other disorders of ear in diseases classified elsewhere'
  },
  {
    code: 'H93',
    label: 'Other disorders of ear, not elsewhere classified'
  },
  {
    code: 'H69',
    label: 'Other disorders of Eustachian tube'
  },
  {
    code: 'H61',
    label: 'Other disorders of external ear'
  },
  {
    code: 'H57',
    label: 'Other disorders of eye and adnexa'
  },
  {
    code: 'H58',
    label: 'Other disorders of eye and adnexa in diseases classified elsewhere'
  },
  {
    code: 'H02',
    label: 'Other disorders of eyelid'
  },
  {
    code: 'E87',
    label: 'Other disorders of fluid, electrolyte and acid-base balance'
  },
  {
    code: 'K06',
    label: 'Other disorders of gingiva and edentulous alveolar ridge'
  },
  {
    code: 'H21',
    label: 'Other disorders of iris and ciliary body'
  },
  {
    code: 'N29',
    label: 'Other disorders of kidney and ureter in diseases classified elsewhere'
  },
  {
    code: 'N28',
    label: 'Other disorders of kidney and ureter, not elsewhere classified'
  },
  {
    code: 'H27',
    label: 'Other disorders of lens'
  },
  {
    code: 'N50',
    label: 'Other disorders of male genital organs'
  },
  {
    code: 'H74',
    label: 'Other disorders of middle ear and mastoid'
  },
  {
    code: 'H75',
    label: 'Other disorders of middle ear and mastoid in diseases classified elsewhere'
  },
  {
    code: 'M62',
    label: 'Other disorders of muscle'
  },
  {
    code: 'G99',
    label: 'Other disorders of nervous system in diseases classified elsewhere'
  },
  {
    code: 'G98',
    label: 'Other disorders of nervous system, not elsewhere classified'
  },
  {
    code: 'J34',
    label: 'Other disorders of nose and nasal sinuses'
  },
  {
    code: 'H47',
    label: 'Other disorders of optic [2nd] nerve and visual pathways'
  },
  {
    code: 'E16',
    label: 'Other disorders of pancreatic internal secretion'
  },
  {
    code: 'N48',
    label: 'Other disorders of penis'
  },
  {
    code: 'G64',
    label: 'Other disorders of peripheral nervous system'
  },
  {
    code: 'K66',
    label: 'Other disorders of peritoneum'
  },
  {
    code: 'L81',
    label: 'Other disorders of pigmentation'
  },
  {
    code: 'N42',
    label: 'Other disorders of prostate'
  },
  {
    code: 'F88',
    label: 'Other disorders of psychological development'
  },
  {
    code: 'L99',
    label: 'Other disorders of skin and subcutaneous tissue in diseases classified elsewhere'
  },
  {
    code: 'L59',
    label: 'Other disorders of skin and subcutaneous tissue related to radiation'
  },
  {
    code: 'L98',
    label: 'Other disorders of skin and subcutaneous tissue, not elsewhere classified'
  },
  {
    code: 'M67',
    label: 'Other disorders of synovium and tendon'
  },
  {
    code: 'K08',
    label: 'Other disorders of teeth and supporting structures'
  },
  {
    code: 'E07',
    label: 'Other disorders of thyroid'
  },
  {
    code: 'H73',
    label: 'Other disorders of tympanic membrane'
  },
  {
    code: 'N36',
    label: 'Other disorders of urethra'
  },
  {
    code: 'N39',
    label: 'Other disorders of urinary system'
  },
  {
    code: 'I87',
    label: 'Other disorders of veins'
  },
  {
    code: 'D72',
    label: 'Other disorders of white blood cells'
  },
  {
    code: 'P91',
    label: 'Other disturbances of cerebral status of newborn'
  },
  {
    code: 'P81',
    label: 'Other disturbances of temperature regulation of newborn'
  },
  {
    code: 'M53',
    label: 'Other dorsopathies, not elsewhere classified'
  },
  {
    code: 'T69',
    label: 'Other effects of reduced temperature'
  },
  {
    code: 'E34',
    label: 'Other endocrine disorders'
  },
  {
    code: 'M77',
    label: 'Other enthesopathies'
  },
  {
    code: 'L85',
    label: 'Other epidermal thickening'
  },
  {
    code: 'L53',
    label: 'Other erythematous conditions'
  },
  {
    code: 'G25',
    label: 'Other extrapyramidal and movement disorders'
  },
  {
    code: 'O66',
    label: 'Other factors affecting labour and delivery'
  },
  {
    code: 'W17',
    label: 'Other fall from one level to another'
  },
  {
    code: 'W18',
    label: 'Other fall on same level'
  },
  {
    code: 'W03',
    label: 'Other fall on same level due to collision with, or pushing by, another person'
  },
  {
    code: 'N73',
    label: 'Other female pelvic inflammatory diseases'
  },
  {
    code: 'B66',
    label: 'Other fluke infections'
  },
  {
    code: 'L73',
    label: 'Other follicular disorders'
  },
  {
    code: 'K59',
    label: 'Other functional intestinal disorders'
  },
  {
    code: 'A09',
    label: 'Other gastroenteritis and colitis of infectious and unspecified origin'
  },
  {
    code: 'R68',
    label: 'Other general symptoms and signs'
  },
  {
    code: 'G44',
    label: 'Other headache syndromes'
  },
  {
    code: 'H91',
    label: 'Other hearing loss'
  },
  {
    code: 'I52',
    label: 'Other heart disorders in diseases classified elsewhere'
  },
  {
    code: 'B83',
    label: 'Other helminthiases'
  },
  {
    code: 'D58',
    label: 'Other hereditary haemolytic anaemias'
  },
  {
    code: 'E67',
    label: 'Other hyperalimentation'
  },
  {
    code: 'E03',
    label: 'Other hypothyroidism'
  },
  {
    code: 'R99',
    label: 'Other ill-defined and unspecified causes of mortality'
  },
  {
    code: 'D84',
    label: 'Other immunodeficiencies'
  },
  {
    code: 'P39',
    label: 'Other infections specific to the perinatal period'
  },
  {
    code: 'B88',
    label: 'Other infestations'
  },
  {
    code: 'H01',
    label: 'Other inflammation of eyelid'
  },
  {
    code: 'N76',
    label: 'Other inflammation of vagina and vulva'
  },
  {
    code: 'K75',
    label: 'Other inflammatory liver diseases'
  },
  {
    code: 'M46',
    label: 'Other inflammatory spondylopathies'
  },
  {
    code: 'T06',
    label: 'Other injuries involving multiple body regions, not elsewhere classified'
  },
  {
    code: 'T13',
    label: 'Other injuries of lower limb, level unspecified'
  },
  {
    code: 'T09',
    label: 'Other injuries of spine and trunk, level unspecified'
  },
  {
    code: 'T11',
    label: 'Other injuries of upper limb, level unspecified'
  },
  {
    code: 'J84',
    label: 'Other interstitial pulmonary diseases'
  },
  {
    code: 'M51',
    label: 'Other intervertebral disc disorders'
  },
  {
    code: 'B81',
    label: 'Other intestinal helminthiases, not elsewhere classified'
  },
  {
    code: 'P76',
    label: 'Other intestinal obstruction of newborn'
  },
  {
    code: 'M25',
    label: 'Other joint disorders, not elsewhere classified'
  },
  {
    code: 'M92',
    label: 'Other juvenile osteochondrosis'
  },
  {
    code: 'R27',
    label: 'Other lack of coordination'
  },
  {
    code: 'C94',
    label: 'Other leukaemias of specified cell type'
  },
  {
    code: 'L08',
    label: 'Other local infections of skin and subcutaneous tissue'
  },
  {
    code: 'L94',
    label: 'Other localised connective tissue disorders'
  },
  {
    code: 'C44',
    label: 'Other malignant neoplasms of skin'
  },
  {
    code: 'Y07',
    label: 'Other maltreatment syndromes'
  },
  {
    code: 'O99',
    label: 'Other maternal diseases classifiable elsewhere but complicating pregnancy, childbirth and the puerperium'
  },
  {
    code: 'Z51',
    label: 'Other medical care'
  },
  {
    code: 'Y84',
    label:
      'Other medical procedures as the cause of abnormal reaction of the patient, or of later complication, without mention of misadventure at the time of the procedure'
  },
  {
    code: 'F06',
    label: 'Other mental disorders due to brain damage and dysfunction and to physical disease'
  },
  {
    code: 'F78',
    label: 'Other mental retardation'
  },
  {
    code: 'E88',
    label: 'Other metabolic disorders'
  },
  {
    code: 'Y65',
    label: 'Other misadventures during surgical and medical care'
  },
  {
    code: 'G58',
    label: 'Other mononeuropathies'
  },
  {
    code: 'F38',
    label: 'Other mood [affective] disorders'
  },
  {
    code: 'A92',
    label: 'Other mosquito-borne viral fevers'
  },
  {
    code: 'B48',
    label: 'Other mycoses, not elsewhere classified'
  },
  {
    code: 'G72',
    label: 'Other myopathies'
  },
  {
    code: 'M31',
    label: 'Other necrotising vasculopathies'
  },
  {
    code: 'P54',
    label: 'Other neonatal haemorrhages'
  },
  {
    code: 'D47',
    label: 'Other neoplasms of uncertain or unknown behaviour of lymphoid, haematopoietic and related tissue'
  },
  {
    code: 'F48',
    label: 'Other neurotic disorders'
  },
  {
    code: 'I89',
    label: 'Other noninfective disorders of lymphatic vessels and lymph nodes'
  },
  {
    code: 'K52',
    label: 'Other noninfective gastroenteritis and colitis'
  },
  {
    code: 'N88',
    label: 'Other noninflammatory disorders of cervix uteri'
  },
  {
    code: 'N85',
    label: 'Other noninflammatory disorders of uterus, except cervix'
  },
  {
    code: 'N89',
    label: 'Other noninflammatory disorders of vagina'
  },
  {
    code: 'N90',
    label: 'Other noninflammatory disorders of vulva and perineum'
  },
  {
    code: 'F28',
    label: 'Other nonorganic psychotic disorders'
  },
  {
    code: 'L65',
    label: 'Other nonscarring hair loss'
  },
  {
    code: 'E04',
    label: 'Other nontoxic goitre'
  },
  {
    code: 'I62',
    label: 'Other nontraumatic intracranial haemorrhage'
  },
  {
    code: 'D53',
    label: 'Other nutritional anaemias'
  },
  {
    code: 'E63',
    label: 'Other nutritional deficiencies'
  },
  {
    code: 'O71',
    label: 'Other obstetric trauma'
  },
  {
    code: 'Z47',
    label: 'Other orthopaedic follow-up care'
  },
  {
    code: 'Q78',
    label: 'Other osteochondrodysplasias'
  },
  {
    code: 'M93',
    label: 'Other osteochondropathies'
  },
  {
    code: 'L44',
    label: 'Other papulosquamous disorders'
  },
  {
    code: 'G83',
    label: 'Other paralytic syndromes'
  },
  {
    code: 'B53',
    label: 'Other parasitologically confirmed malaria'
  },
  {
    code: 'P78',
    label: 'Other perinatal digestive system disorders'
  },
  {
    code: 'P61',
    label: 'Other perinatal haematological disorders'
  },
  {
    code: 'I73',
    label: 'Other peripheral vascular diseases'
  },
  {
    code: 'J94',
    label: 'Other pleural conditions'
  },
  {
    code: 'G62',
    label: 'Other polyneuropathies'
  },
  {
    code: 'Z98',
    label: 'Other postprocedural states'
  },
  {
    code: 'A63',
    label: 'Other predominantly sexually transmitted diseases, not elsewhere classified'
  },
  {
    code: 'Z63',
    label: 'Other problems related to primary support group, including family circumstances'
  },
  {
    code: 'Z62',
    label: 'Other problems related to upbringing'
  },
  {
    code: 'B60',
    label: 'Other protozoal diseases, not elsewhere classified'
  },
  {
    code: 'A07',
    label: 'Other protozoal intestinal diseases'
  },
  {
    code: 'O86',
    label: 'Other puerperal infections'
  },
  {
    code: 'I27',
    label: 'Other pulmonary heart diseases'
  },
  {
    code: 'N15',
    label: 'Other renal tubulo-interstitial diseases'
  },
  {
    code: 'P28',
    label: 'Other respiratory conditions originating in the perinatal period'
  },
  {
    code: 'J98',
    label: 'Other respiratory disorders'
  },
  {
    code: 'H35',
    label: 'Other retinal disorders'
  },
  {
    code: 'I09',
    label: 'Other rheumatic heart diseases'
  },
  {
    code: 'M06',
    label: 'Other rheumatoid arthritis'
  },
  {
    code: 'A79',
    label: 'Other rickettsioses'
  },
  {
    code: 'A02',
    label: 'Other salmonella infections'
  },
  {
    code: 'U69',
    label: 'Other school-related recreational activities'
  },
  {
    code: 'A41',
    label: 'Other sepsis'
  },
  {
    code: 'Q97',
    label: 'Other sex chromosome abnormalities, female phenotype, not elsewhere classified'
  },
  {
    code: 'Q98',
    label: 'Other sex chromosome abnormalities, male phenotype, not elsewhere classified'
  },
  {
    code: 'A56',
    label: 'Other sexually transmitted chlamydial diseases'
  },
  {
    code: 'R23',
    label: 'Other skin changes'
  },
  {
    code: 'M79',
    label: 'Other soft tissue disorders, not elsewhere classified'
  },
  {
    code: 'Z01',
    label: 'Other special examinations and investigations of persons without complaint or reported diagnosis'
  },
  {
    code: 'M12',
    label: 'Other specific arthropathies'
  },
  {
    code: 'M24',
    label: 'Other specific joint derangements'
  },
  {
    code: 'V97',
    label: 'Other specified air transport accidents'
  },
  {
    code: 'T89',
    label: 'Other specified complications of trauma'
  },
  {
    code: 'Q87',
    label: 'Other specified congenital malformation syndromes affecting multiple systems'
  },
  {
    code: 'E13',
    label: 'Other specified diabetes mellitus'
  },
  {
    code: 'W73',
    label: 'Other specified drowning and submersion'
  },
  {
    code: 'Y33',
    label: 'Other specified events, undetermined intent'
  },
  {
    code: 'U70',
    label: 'Other specified sport and exercise activity'
  },
  {
    code: 'W83',
    label: 'Other specified threats to breathing'
  },
  {
    code: 'V98',
    label: 'Other specified transport accidents'
  },
  {
    code: 'A69',
    label: 'Other spirochaetal infections'
  },
  {
    code: 'M48',
    label: 'Other spondylopathies'
  },
  {
    code: 'H50',
    label: 'Other strabismus'
  },
  {
    code: 'R96',
    label: 'Other sudden death, cause unknown'
  },
  {
    code: 'B36',
    label: 'Other superficial mycoses'
  },
  {
    code: 'Z48',
    label: 'Other surgical follow-up care'
  },
  {
    code: 'R41',
    label: 'Other symptoms and signs involving cognitive functions and awareness'
  },
  {
    code: 'R44',
    label: 'Other symptoms and signs involving general sensations and perceptions'
  },
  {
    code: 'R09',
    label: 'Other symptoms and signs involving the circulatory and respiratory systems'
  },
  {
    code: 'R19',
    label: 'Other symptoms and signs involving the digestive system and abdomen'
  },
  {
    code: 'R29',
    label: 'Other symptoms and signs involving the nervous and musculoskeletal systems'
  },
  {
    code: 'R39',
    label: 'Other symptoms and signs involving the urinary system'
  },
  {
    code: 'Y41',
    label: 'Other systemic anti-infectives and antiparasitics causing adverse effects in therapeutic use'
  },
  {
    code: 'M35',
    label: 'Other systemic involvement of connective tissue'
  },
  {
    code: 'A35',
    label: 'Other tetanus'
  },
  {
    code: 'P74',
    label: 'Other transitory neonatal electrolyte and metabolic disturbances'
  },
  {
    code: 'P72',
    label: 'Other transitory neonatal endocrine disorders'
  },
  {
    code: 'Q92',
    label: 'Other trisomies and partial trisomies of the autosomes, not elsewhere classified'
  },
  {
    code: 'I82',
    label: 'Other venous embolism and thrombosis'
  },
  {
    code: 'B33',
    label: 'Other viral diseases, not elsewhere classified'
  },
  {
    code: 'A85',
    label: 'Other viral encephalitis, not elsewhere classified'
  },
  {
    code: 'A98',
    label: 'Other viral haemorrhagic fevers, not elsewhere classified'
  },
  {
    code: 'B08',
    label: 'Other viral infections characterised by skin and mucous membrane lesions, not elsewhere classified'
  },
  {
    code: 'A88',
    label: 'Other viral infections of central nervous system, not elsewhere classified'
  },
  {
    code: 'E56',
    label: 'Other vitamin deficiencies'
  },
  {
    code: 'A28',
    label: 'Other zoonotic bacterial diseases, not elsewhere classified'
  },
  {
    code: 'H60',
    label: 'Otitis externa'
  },
  {
    code: 'H67',
    label: 'Otitis media in diseases classified elsewhere'
  },
  {
    code: 'Y72',
    label: 'Otorhinolaryngological devices associated with misadventures'
  },
  {
    code: 'H80',
    label: 'Otosclerosis'
  },
  {
    code: 'Z37',
    label: 'Outcome of delivery'
  },
  {
    code: 'E28',
    label: 'Ovarian dysfunction'
  },
  {
    code: 'X50',
    label: 'Overexertion and strenuous or repetitive movements'
  },
  {
    code: 'M88',
    label: "Paget's disease of bone [osteitis deformans]"
  },
  {
    code: 'N94',
    label: 'Pain and other conditions associated with female genital organs and menstrual cycle'
  },
  {
    code: 'R30',
    label: 'Pain associated with micturition'
  },
  {
    code: 'R07',
    label: 'Pain in throat and chest'
  },
  {
    code: 'R52',
    label: 'Pain, not elsewhere classified'
  },
  {
    code: 'L45',
    label: 'Papulosquamous disorders in diseases classified elsewhere'
  },
  {
    code: 'B41',
    label: 'Paracoccidioidomycosis'
  },
  {
    code: 'K56',
    label: 'Paralytic ileus and intestinal obstruction without hernia'
  },
  {
    code: 'H49',
    label: 'Paralytic strabismus'
  },
  {
    code: 'G82',
    label: 'Paraplegia and tetraplegia'
  },
  {
    code: 'L41',
    label: 'Parapsoriasis'
  },
  {
    code: 'G20',
    label: "Parkinson's disease"
  },
  {
    code: 'G22',
    label: 'Parkinsonism in diseases classified elsewhere'
  },
  {
    code: 'I47',
    label: 'Paroxysmal tachycardia'
  },
  {
    code: 'V13',
    label: 'Pedal cyclist injured in collision with car, pick-up truck or van'
  },
  {
    code: 'V17',
    label: 'Pedal cyclist injured in collision with fixed or stationary object'
  },
  {
    code: 'V14',
    label: 'Pedal cyclist injured in collision with heavy transport vehicle or bus'
  },
  {
    code: 'V16',
    label: 'Pedal cyclist injured in collision with other nonmotor vehicle'
  },
  {
    code: 'V11',
    label: 'Pedal cyclist injured in collision with other pedal cycle'
  },
  {
    code: 'V10',
    label: 'Pedal cyclist injured in collision with pedestrian or animal'
  },
  {
    code: 'V15',
    label: 'Pedal cyclist injured in collision with railway train or railway vehicle'
  },
  {
    code: 'V12',
    label: 'Pedal cyclist injured in collision with two- or three-wheeled motor vehicle'
  },
  {
    code: 'V18',
    label: 'Pedal cyclist injured in noncollision transport accident'
  },
  {
    code: 'V19',
    label: 'Pedal cyclist injured in other and unspecified transport accidents'
  },
  {
    code: 'V03',
    label: 'Pedestrian injured in collision with car, pick-up truck or van'
  },
  {
    code: 'V04',
    label: 'Pedestrian injured in collision with heavy transport vehicle or bus'
  },
  {
    code: 'V06',
    label: 'Pedestrian injured in collision with other nonmotor vehicle'
  },
  {
    code: 'V01',
    label: 'Pedestrian injured in collision with pedal cycle'
  },
  {
    code: 'V00',
    label: 'Pedestrian injured in collision with pedestrian conveyance'
  },
  {
    code: 'V05',
    label: 'Pedestrian injured in collision with railway train or railway vehicle'
  },
  {
    code: 'V02',
    label: 'Pedestrian injured in collision with two- or three-wheeled motor vehicle'
  },
  {
    code: 'V09',
    label: 'Pedestrian injured in other and unspecified transport accidents'
  },
  {
    code: 'B85',
    label: 'Pediculosis and phthiriasis'
  },
  {
    code: 'L12',
    label: 'Pemphigoid'
  },
  {
    code: 'L10',
    label: 'Pemphigus'
  },
  {
    code: 'K27',
    label: 'Peptic ulcer, site unspecified'
  },
  {
    code: 'H72',
    label: 'Perforation of tympanic membrane'
  },
  {
    code: 'I32',
    label: 'Pericarditis in diseases classified elsewhere'
  },
  {
    code: 'O70',
    label: 'Perineal laceration during delivery'
  },
  {
    code: 'C84',
    label: 'Peripheral and cutaneous T-cell lymphomas'
  },
  {
    code: 'K65',
    label: 'Peritonitis'
  },
  {
    code: 'J36',
    label: 'Peritonsillar abscess'
  },
  {
    code: 'F22',
    label: 'Persistent delusional disorders'
  },
  {
    code: 'F34',
    label: 'Persistent mood [affective] disorders'
  },
  {
    code: 'Z88',
    label: 'Personal history of allergy to drugs, medicaments and biological substances'
  },
  {
    code: 'Z86',
    label: 'Personal history of certain other diseases'
  },
  {
    code: 'Z85',
    label: 'Personal history of malignant neoplasm'
  },
  {
    code: 'Z92',
    label: 'Personal history of medical treatment'
  },
  {
    code: 'Z87',
    label: 'Personal history of other diseases and conditions'
  },
  {
    code: 'Z91',
    label: 'Personal history of risk-factors, not elsewhere classified'
  },
  {
    code: 'F07',
    label: 'Personality and behavioural disorders due to brain disease, damage and dysfunction'
  },
  {
    code: 'Z71',
    label: 'Persons encountering health services for other counselling and medical advice, not elsewhere classified'
  },
  {
    code: 'Z53',
    label: 'Persons encountering health services for specific procedures, not carried out'
  },
  {
    code: 'Z76',
    label: 'Persons encountering health services in other circumstances'
  },
  {
    code: 'F84',
    label: 'Pervasive developmental disorders'
  },
  {
    code: 'Q85',
    label: 'Phakomatoses, not elsewhere classified'
  },
  {
    code: 'I80',
    label: 'Phlebitis and thrombophlebitis'
  },
  {
    code: 'F40',
    label: 'Phobic anxiety disorders'
  },
  {
    code: 'Y80',
    label: 'Physical medicine devices associated with misadventures'
  },
  {
    code: 'L05',
    label: 'Pilonidal cyst'
  },
  {
    code: 'A67',
    label: 'Pinta [carate]'
  },
  {
    code: 'L42',
    label: 'Pityriasis rosea'
  },
  {
    code: 'Y92',
    label: 'Place of occurrence'
  },
  {
    code: 'O44',
    label: 'Placenta praevia'
  },
  {
    code: 'O43',
    label: 'Placental disorders'
  },
  {
    code: 'A20',
    label: 'Plague'
  },
  {
    code: 'B50',
    label: 'Plasmodium falciparum malaria'
  },
  {
    code: 'B52',
    label: 'Plasmodium malariae malaria'
  },
  {
    code: 'B51',
    label: 'Plasmodium vivax malaria'
  },
  {
    code: 'J91',
    label: 'Pleural effusion in conditions classified elsewhere'
  },
  {
    code: 'J90',
    label: 'Pleural effusion, not elsewhere classified'
  },
  {
    code: 'J92',
    label: 'Pleural plaque'
  },
  {
    code: 'J65',
    label: 'Pneumoconiosis associated with tuberculosis'
  },
  {
    code: 'J61',
    label: 'Pneumoconiosis due to asbestos and other mineral fibres'
  },
  {
    code: 'J62',
    label: 'Pneumoconiosis due to dust containing silica'
  },
  {
    code: 'J63',
    label: 'Pneumoconiosis due to other inorganic dusts'
  },
  {
    code: 'B59',
    label: 'Pneumocystosis (J17.3*)'
  },
  {
    code: 'J14',
    label: 'Pneumonia due to Haemophilus influenzae'
  },
  {
    code: 'J16',
    label: 'Pneumonia due to other infectious organisms, not elsewhere classified'
  },
  {
    code: 'J13',
    label: 'Pneumonia due to Streptococcus pneumoniae'
  },
  {
    code: 'J17',
    label: 'Pneumonia in diseases classified elsewhere'
  },
  {
    code: 'J18',
    label: 'Pneumonia, organism unspecified'
  },
  {
    code: 'J69',
    label: 'Pneumonitis due to solids and liquids'
  },
  {
    code: 'J93',
    label: 'Pneumothorax'
  },
  {
    code: 'T48',
    label: 'Poisoning by agents primarily acting on smooth and skeletal muscles and the respiratory system'
  },
  {
    code: 'T46',
    label: 'Poisoning by agents primarily affecting the cardiovascular system'
  },
  {
    code: 'T47',
    label: 'Poisoning by agents primarily affecting the gastrointestinal system'
  },
  {
    code: 'T41',
    label: 'Poisoning by anaesthetics and therapeutic gases'
  },
  {
    code: 'Y15',
    label: 'Poisoning by and exposure to alcohol, undetermined intent'
  },
  {
    code: 'Y11',
    label:
      'Poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified, undetermined intent'
  },
  {
    code: 'Y12',
    label:
      'Poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified, undetermined intent'
  },
  {
    code: 'Y10',
    label: 'Poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics, undetermined intent'
  },
  {
    code: 'Y16',
    label:
      'Poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent'
  },
  {
    code: 'Y19',
    label: 'Poisoning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent'
  },
  {
    code: 'Y14',
    label:
      'Poisoning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent'
  },
  {
    code: 'Y13',
    label: 'Poisoning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent'
  },
  {
    code: 'Y17',
    label: 'Poisoning by and exposure to other gases and vapours, undetermined intent'
  },
  {
    code: 'Y18',
    label: 'Poisoning by and exposure to pesticides, undetermined intent'
  },
  {
    code: 'T42',
    label: 'Poisoning by antiepileptic, sedative-hypnotic and antiparkinsonism drugs'
  },
  {
    code: 'T50',
    label: 'Poisoning by diuretics and other and unspecified drugs, medicaments and biological substances'
  },
  {
    code: 'T44',
    label: 'Poisoning by drugs primarily affecting the autonomic nervous system'
  },
  {
    code: 'T38',
    label: 'Poisoning by hormones and their synthetic substitutes and antagonists, not elsewhere classified'
  },
  {
    code: 'T40',
    label: 'Poisoning by narcotics and psychodysleptics [hallucinogens]'
  },
  {
    code: 'T39',
    label: 'Poisoning by nonopioid analgesics, antipyretics and antirheumatics'
  },
  {
    code: 'T37',
    label: 'Poisoning by other systemic anti-infectives and antiparasitics'
  },
  {
    code: 'T45',
    label: 'Poisoning by primarily systemic and haematological agents, not elsewhere classified'
  },
  {
    code: 'T43',
    label: 'Poisoning by psychotropic drugs, not elsewhere classified'
  },
  {
    code: 'T36',
    label: 'Poisoning by systemic antibiotics'
  },
  {
    code: 'T49',
    label:
      'Poisoning by topical agents primarily affecting skin and mucous membrane and by ophthalmological, otorhinolaryngological and dental drugs'
  },
  {
    code: 'M30',
    label: 'Polyarteritis nodosa and related conditions'
  },
  {
    code: 'M15',
    label: 'Polyarthrosis'
  },
  {
    code: 'D45',
    label: 'Polycythaemia vera'
  },
  {
    code: 'Q69',
    label: 'Polydactyly'
  },
  {
    code: 'E31',
    label: 'Polyglandular dysfunction'
  },
  {
    code: 'O40',
    label: 'Polyhydramnios'
  },
  {
    code: 'G63',
    label: 'Polyneuropathy in diseases classified elsewhere'
  },
  {
    code: 'N84',
    label: 'Polyp of female genital tract'
  },
  {
    code: 'R35',
    label: 'Polyuria'
  },
  {
    code: 'I81',
    label: 'Portal vein thrombosis'
  },
  {
    code: 'M03',
    label: 'Postinfective and reactive arthropathies in diseases classified elsewhere'
  },
  {
    code: 'Z39',
    label: 'Postpartum care and examination'
  },
  {
    code: 'O72',
    label: 'Postpartum haemorrhage'
  },
  {
    code: 'I97',
    label: 'Postprocedural disorders of circulatory system, not elsewhere classified'
  },
  {
    code: 'K91',
    label: 'Postprocedural disorders of digestive system, not elsewhere classified'
  },
  {
    code: 'H95',
    label: 'Postprocedural disorders of ear and mastoid process, not elsewhere classified'
  },
  {
    code: 'H59',
    label: 'Postprocedural disorders of eye and adnexa, not elsewhere classified'
  },
  {
    code: 'N99',
    label: 'Postprocedural disorders of genitourinary system, not elsewhere classified'
  },
  {
    code: 'G97',
    label: 'Postprocedural disorders of nervous system, not elsewhere classified'
  },
  {
    code: 'E89',
    label: 'Postprocedural endocrine and metabolic disorders, not elsewhere classified'
  },
  {
    code: 'M96',
    label: 'Postprocedural musculoskeletal disorders, not elsewhere classified'
  },
  {
    code: 'J95',
    label: 'Postprocedural respiratory disorders, not elsewhere classified'
  },
  {
    code: 'U62',
    label: 'Power sports'
  },
  {
    code: 'O10',
    label: 'Pre-existing hypertension complicating pregnancy, childbirth and the puerperium'
  },
  {
    code: 'O11',
    label: 'Pre-existing hypertensive disorder with superimposed proteinuria'
  },
  {
    code: 'Z32',
    label: 'Pregnancy examination and test'
  },
  {
    code: 'Z33',
    label: 'Pregnant state, incidental'
  },
  {
    code: 'O42',
    label: 'Premature rupture of membranes'
  },
  {
    code: 'O45',
    label: 'Premature separation of placenta [abruptio placentae]'
  },
  {
    code: 'Z95',
    label: 'Presence of cardiac and vascular implants and grafts'
  },
  {
    code: 'Z97',
    label: 'Presence of other devices'
  },
  {
    code: 'Z96',
    label: 'Presence of other functional implants'
  },
  {
    code: 'O60',
    label: 'Preterm labour and delivery'
  },
  {
    code: 'Y43',
    label: 'Primarily systemic agents causing adverse effects in therapeutic use'
  },
  {
    code: 'G71',
    label: 'Primary disorders of muscles'
  },
  {
    code: 'Z74',
    label: 'Problems related to care-provider dependency'
  },
  {
    code: 'Z64',
    label: 'Problems related to certain psychosocial circumstances'
  },
  {
    code: 'Z55',
    label: 'Problems related to education and literacy'
  },
  {
    code: 'Z56',
    label: 'Problems related to employment and unemployment'
  },
  {
    code: 'Z59',
    label: 'Problems related to housing and economic circumstances'
  },
  {
    code: 'Z73',
    label: 'Problems related to life-management difficulty'
  },
  {
    code: 'Z72',
    label: 'Problems related to lifestyle'
  },
  {
    code: 'Z75',
    label: 'Problems related to medical facilities and other health care'
  },
  {
    code: 'Z61',
    label: 'Problems related to negative life events in childhood'
  },
  {
    code: 'Z65',
    label: 'Problems related to other psychosocial circumstances'
  },
  {
    code: 'Z58',
    label: 'Problems related to physical environment'
  },
  {
    code: 'Z60',
    label: 'Problems related to social environment'
  },
  {
    code: 'Z41',
    label: 'Procedures for purposes other than remedying health state'
  },
  {
    code: 'Z31',
    label: 'Procreative management'
  },
  {
    code: 'F73',
    label: 'Profound mental retardation'
  },
  {
    code: 'O48',
    label: 'Prolonged pregnancy'
  },
  {
    code: 'X52',
    label: 'Prolonged stay in weightless environment'
  },
  {
    code: 'Z40',
    label: 'Prophylactic surgery'
  },
  {
    code: 'E44',
    label: 'Protein-energy malnutrition of moderate and mild degree'
  },
  {
    code: 'L29',
    label: 'Pruritus'
  },
  {
    code: 'L40',
    label: 'Psoriasis'
  },
  {
    code: 'M07',
    label: 'Psoriatic and enteropathic arthropathies'
  },
  {
    code: 'F66',
    label: 'Psychological and behavioural disorders associated with sexual development and orientation'
  },
  {
    code: 'F54',
    label: 'Psychological and behavioural factors associated with disorders or diseases classified elsewhere'
  },
  {
    code: 'Y49',
    label: 'Psychotropic drugs, not elsewhere classified causing adverse effects in therapeutic use'
  },
  {
    code: 'O85',
    label: 'Puerperal sepsis'
  },
  {
    code: 'I26',
    label: 'Pulmonary embolism'
  },
  {
    code: 'J82',
    label: 'Pulmonary eosinophilia, not elsewhere classified'
  },
  {
    code: 'P26',
    label: 'Pulmonary haemorrhage originating in the perinatal period'
  },
  {
    code: 'J81',
    label: 'Pulmonary oedema'
  },
  {
    code: 'I37',
    label: 'Pulmonary valve disorders'
  },
  {
    code: 'D69',
    label: 'Purpura and other haemorrhagic conditions'
  },
  {
    code: 'L88',
    label: 'Pyoderma gangrenosum'
  },
  {
    code: 'M00',
    label: 'Pyogenic arthritis'
  },
  {
    code: 'J86',
    label: 'Pyothorax'
  },
  {
    code: 'A78',
    label: 'Q fever'
  },
  {
    code: 'A82',
    label: 'Rabies'
  },
  {
    code: 'U59',
    label: 'Racquet sports'
  },
  {
    code: 'L58',
    label: 'Radiodermatitis'
  },
  {
    code: 'Y78',
    label: 'Radiological devices associated with misadventures'
  },
  {
    code: 'N01',
    label: 'Rapidly progressive nephritic syndrome'
  },
  {
    code: 'R21',
    label: 'Rash and other nonspecific skin eruption'
  },
  {
    code: 'A25',
    label: 'Rat-bite fevers'
  },
  {
    code: 'F43',
    label: 'Reaction to severe stress, and adjustment disorders'
  },
  {
    code: 'P93',
    label: 'Reactions and intoxications due to drugs administered to fetus and newborn'
  },
  {
    code: 'M02',
    label: 'Reactive arthropathies'
  },
  {
    code: 'N02',
    label: 'Recurrent and persistent haematuria'
  },
  {
    code: 'F33',
    label: 'Recurrent depressive disorder'
  },
  {
    code: 'Q72',
    label: 'Reduction defects of lower limb'
  },
  {
    code: 'Q73',
    label: 'Reduction defects of unspecified limb'
  },
  {
    code: 'Q71',
    label: 'Reduction defects of upper limb'
  },
  {
    code: 'N47',
    label: 'Redundant prepuce, phimosis and paraphimosis'
  },
  {
    code: 'A68',
    label: 'Relapsing fevers'
  },
  {
    code: 'Q60',
    label: 'Renal agenesis and other reduction defects of kidney'
  },
  {
    code: 'N16',
    label: 'Renal tubulo-interstitial disorders in diseases classified elsewhere'
  },
  {
    code: 'J68',
    label: 'Respiratory conditions due to inhalation of chemicals, gases, fumes and vapours'
  },
  {
    code: 'J70',
    label: 'Respiratory conditions due to other external agents'
  },
  {
    code: 'J99',
    label: 'Respiratory disorders in diseases classified elsewhere'
  },
  {
    code: 'P22',
    label: 'Respiratory distress of newborn'
  },
  {
    code: 'J96',
    label: 'Respiratory failure, not elsewhere classified'
  },
  {
    code: 'A15',
    label: 'Respiratory tuberculosis, bacteriologically and histologically confirmed'
  },
  {
    code: 'A16',
    label: 'Respiratory tuberculosis, not confirmed bacteriologically or histologically'
  },
  {
    code: 'O73',
    label: 'Retained placenta and membranes, without haemorrhage'
  },
  {
    code: 'E45',
    label: 'Retarded development following protein-energy malnutrition'
  },
  {
    code: 'R33',
    label: 'Retention of urine'
  },
  {
    code: 'H33',
    label: 'Retinal detachments and breaks'
  },
  {
    code: 'H36',
    label: 'Retinal disorders in diseases classified elsewhere'
  },
  {
    code: 'H34',
    label: 'Retinal vascular occlusions'
  },
  {
    code: 'I06',
    label: 'Rheumatic aortic valve diseases'
  },
  {
    code: 'I02',
    label: 'Rheumatic chorea'
  },
  {
    code: 'I01',
    label: 'Rheumatic fever with heart involvement'
  },
  {
    code: 'I00',
    label: 'Rheumatic fever without mention of heart involvement'
  },
  {
    code: 'I05',
    label: 'Rheumatic mitral valve diseases'
  },
  {
    code: 'I07',
    label: 'Rheumatic tricuspid valve diseases'
  },
  {
    code: 'L71',
    label: 'Rosacea'
  },
  {
    code: 'Z10',
    label: 'Routine general health check-up of defined subpopulation'
  },
  {
    code: 'B06',
    label: 'Rubella [German measles]'
  },
  {
    code: 'N70',
    label: 'Salpingitis and oophoritis'
  },
  {
    code: 'D86',
    label: 'Sarcoidosis'
  },
  {
    code: 'B86',
    label: 'Scabies'
  },
  {
    code: 'A38',
    label: 'Scarlet fever'
  },
  {
    code: 'B65',
    label: 'Schistosomiasis [bilharziasis]'
  },
  {
    code: 'F25',
    label: 'Schizoaffective disorders'
  },
  {
    code: 'F20',
    label: 'Schizophrenia'
  },
  {
    code: 'F21',
    label: 'Schizotypal disorder'
  },
  {
    code: 'M41',
    label: 'Scoliosis'
  },
  {
    code: 'L21',
    label: 'Seborrhoeic dermatitis'
  },
  {
    code: 'L82',
    label: 'Seborrhoeic keratosis'
  },
  {
    code: 'C77',
    label: 'Secondary and unspecified malignant neoplasm of lymph nodes'
  },
  {
    code: 'I15',
    label: 'Secondary hypertension'
  },
  {
    code: 'C79',
    label: 'Secondary malignant neoplasm of other sites'
  },
  {
    code: 'C78',
    label: 'Secondary malignant neoplasm of respiratory and digestive organs'
  },
  {
    code: 'G21',
    label: 'Secondary parkinsonism'
  },
  {
    code: 'Y47',
    label: 'Sedatives, hypnotics and antianxiety drugs causing adverse effects in therapeutic use'
  },
  {
    code: 'H25',
    label: 'Senile cataract'
  },
  {
    code: 'R54',
    label: 'Senility'
  },
  {
    code: 'T95',
    label: 'Sequelae of burns and frostbite'
  },
  {
    code: 'I69',
    label: 'Sequelae of cerebrovascular disease'
  },
  {
    code: 'O94',
    label: 'Sequelae of complication of pregnancy, childbirth and the puerperium'
  },
  {
    code: 'E68',
    label: 'Sequelae of hyperalimentation'
  },
  {
    code: 'G09',
    label: 'Sequelae of inflammatory diseases of central nervous system'
  },
  {
    code: 'T94',
    label: 'Sequelae of injuries involving multiple and unspecified body regions'
  },
  {
    code: 'T90',
    label: 'Sequelae of injuries of head'
  },
  {
    code: 'T93',
    label: 'Sequelae of injuries of lower limb'
  },
  {
    code: 'T91',
    label: 'Sequelae of injuries of neck and trunk'
  },
  {
    code: 'T92',
    label: 'Sequelae of injuries of upper limb'
  },
  {
    code: 'Y87',
    label: 'Sequelae of intentional self-harm, assault and events of undetermined intent'
  },
  {
    code: 'B92',
    label: 'Sequelae of leprosy'
  },
  {
    code: 'E64',
    label: 'Sequelae of malnutrition and other nutritional deficiencies'
  },
  {
    code: 'Y86',
    label: 'Sequelae of other accidents'
  },
  {
    code: 'T98',
    label: 'Sequelae of other and unspecified effects of external causes'
  },
  {
    code: 'B94',
    label: 'Sequelae of other and unspecified infectious and parasitic diseases'
  },
  {
    code: 'Y89',
    label: 'Sequelae of other external causes'
  },
  {
    code: 'T96',
    label: 'Sequelae of poisoning by drugs, medicaments and biological substances'
  },
  {
    code: 'B91',
    label: 'Sequelae of poliomyelitis'
  },
  {
    code: 'T97',
    label: 'Sequelae of toxic effects of substances chiefly nonmedicinal as to source'
  },
  {
    code: 'Y85',
    label: 'Sequelae of transport accidents'
  },
  {
    code: 'B90',
    label: 'Sequelae of tuberculosis'
  },
  {
    code: 'Y88',
    label: 'Sequelae with surgical and medical care as external cause'
  },
  {
    code: 'M05',
    label: 'Seropositive rheumatoid arthritis'
  },
  {
    code: 'U04',
    label: 'Severe acute respiratory syndrome [SARS]'
  },
  {
    code: 'F72',
    label: 'Severe mental retardation'
  },
  {
    code: 'Y05',
    label: 'Sexual assault by bodily force'
  },
  {
    code: 'F52',
    label: 'Sexual dysfunction, not caused by organic disorder or disease'
  },
  {
    code: 'A03',
    label: 'Shigellosis'
  },
  {
    code: 'R57',
    label: 'Shock, not elsewhere classified'
  },
  {
    code: 'M75',
    label: 'Shoulder lesions'
  },
  {
    code: 'D57',
    label: 'Sickle-cell disorders'
  },
  {
    code: 'J41',
    label: 'Simple and mucopurulent chronic bronchitis'
  },
  {
    code: 'O82',
    label: 'Single delivery by caesarean section'
  },
  {
    code: 'O81',
    label: 'Single delivery by forceps and vacuum extractor'
  },
  {
    code: 'O80',
    label: 'Single spontaneous delivery'
  },
  {
    code: 'L57',
    label: 'Skin changes due to chronic exposure to nonionising radiation'
  },
  {
    code: 'G47',
    label: 'Sleep disorders'
  },
  {
    code: 'P05',
    label: 'Slow fetal growth and fetal malnutrition'
  },
  {
    code: 'N27',
    label: 'Small kidney of unknown cause'
  },
  {
    code: 'B03',
    label: 'Smallpox'
  },
  {
    code: 'M73',
    label: 'Soft tissue disorders in diseases classified elsewhere'
  },
  {
    code: 'M70',
    label: 'Soft tissue disorders related to use, overuse and pressure'
  },
  {
    code: 'F45',
    label: 'Somatoform disorders'
  },
  {
    code: 'R40',
    label: 'Somnolence, stupor and coma'
  },
  {
    code: 'Z11',
    label: 'Special screening examination for infectious and parasitic diseases'
  },
  {
    code: 'Z12',
    label: 'Special screening examination for neoplasms'
  },
  {
    code: 'Z13',
    label: 'Special screening examination for other diseases and disorders'
  },
  {
    code: 'F82',
    label: 'Specific developmental disorder of motor function'
  },
  {
    code: 'F81',
    label: 'Specific developmental disorders of scholastic skills'
  },
  {
    code: 'F80',
    label: 'Specific developmental disorders of speech and language'
  },
  {
    code: 'F60',
    label: 'Specific personality disorders'
  },
  {
    code: 'R47',
    label: 'Speech disturbances, not elsewhere classified'
  },
  {
    code: 'Q05',
    label: 'Spina bifida'
  },
  {
    code: 'G12',
    label: 'Spinal muscular atrophy and related syndromes'
  },
  {
    code: 'M42',
    label: 'Spinal osteochondrosis'
  },
  {
    code: 'M49',
    label: 'Spondylopathies in diseases classified elsewhere'
  },
  {
    code: 'M47',
    label: 'Spondylosis'
  },
  {
    code: 'O03',
    label: 'Spontaneous abortion'
  },
  {
    code: 'M66',
    label: 'Spontaneous rupture of synovium and tendon'
  },
  {
    code: 'B42',
    label: 'Sporotrichosis'
  },
  {
    code: 'A77',
    label: 'Spotted fever [tick-borne rickettsioses]'
  },
  {
    code: 'L00',
    label: 'Staphylococcal scalded skin syndrome'
  },
  {
    code: 'J46',
    label: 'Status asthmaticus'
  },
  {
    code: 'G41',
    label: 'Status epilepticus'
  },
  {
    code: 'K12',
    label: 'Stomatitis and related lesions'
  },
  {
    code: 'A40',
    label: 'Streptococcal sepsis'
  },
  {
    code: 'B95',
    label: 'Streptococcus and staphylococcus as the cause of diseases classified to other chapters'
  },
  {
    code: 'W51',
    label: 'Striking against or bumped into by another person'
  },
  {
    code: 'W22',
    label: 'Striking against or struck by other objects'
  },
  {
    code: 'W21',
    label: 'Striking against or struck by sports equipment'
  },
  {
    code: 'I64',
    label: 'Stroke, not specified as haemorrhage or infarction'
  },
  {
    code: 'B78',
    label: 'Strongyloidiasis'
  },
  {
    code: 'W20',
    label: 'Struck by thrown, projected or falling object'
  },
  {
    code: 'I60',
    label: 'Subarachnoid haemorrhage'
  },
  {
    code: 'E02',
    label: 'Subclinical iodine-deficiency hypothyroidism'
  },
  {
    code: 'I22',
    label: 'Subsequent myocardial infarction'
  },
  {
    code: 'R95',
    label: 'Sudden infant death syndrome'
  },
  {
    code: 'L55',
    label: 'Sunburn'
  },
  {
    code: 'T33',
    label: 'Superficial frostbite'
  },
  {
    code: 'T00',
    label: 'Superficial injuries involving multiple body regions'
  },
  {
    code: 'S30',
    label: 'Superficial injury of abdomen, lower back and pelvis'
  },
  {
    code: 'S90',
    label: 'Superficial injury of ankle and foot'
  },
  {
    code: 'S50',
    label: 'Superficial injury of forearm'
  },
  {
    code: 'S00',
    label: 'Superficial injury of head'
  },
  {
    code: 'S70',
    label: 'Superficial injury of hip and thigh'
  },
  {
    code: 'S80',
    label: 'Superficial injury of lower leg'
  },
  {
    code: 'S10',
    label: 'Superficial injury of neck'
  },
  {
    code: 'S40',
    label: 'Superficial injury of shoulder and upper arm'
  },
  {
    code: 'S20',
    label: 'Superficial injury of thorax'
  },
  {
    code: 'S60',
    label: 'Superficial injury of wrist and hand'
  },
  {
    code: 'Z35',
    label: 'Supervision of high-risk pregnancy'
  },
  {
    code: 'Z34',
    label: 'Supervision of normal pregnancy'
  },
  {
    code: 'H66',
    label: 'Suppurative and unspecified otitis media'
  },
  {
    code: 'Y83',
    label:
      'Surgical operation and other surgical procedures as the cause of abnormal reaction of the patient, or of later complication, without mention of misadventure at the time of the procedure'
  },
  {
    code: 'R63',
    label: 'Symptoms and signs concerning food and fluid intake'
  },
  {
    code: 'R46',
    label: 'Symptoms and signs involving appearance and behaviour'
  },
  {
    code: 'R45',
    label: 'Symptoms and signs involving emotional state'
  },
  {
    code: 'R55',
    label: 'Syncope and collapse'
  },
  {
    code: 'Q70',
    label: 'Syndactyly'
  },
  {
    code: 'M65',
    label: 'Synovitis and tenosynovitis'
  },
  {
    code: 'Y40',
    label: 'Systemic antibiotics causing adverse effects in therapeutic use'
  },
  {
    code: 'G13',
    label: 'Systemic atrophies primarily affecting central nervous system in diseases classified elsewhere'
  },
  {
    code: 'M36',
    label: 'Systemic disorders of connective tissue in diseases classified elsewhere'
  },
  {
    code: 'M32',
    label: 'Systemic lupus erythematosus'
  },
  {
    code: 'M34',
    label: 'Systemic sclerosis'
  },
  {
    code: 'B68',
    label: 'Taeniasis'
  },
  {
    code: 'U60',
    label: 'Target and precision sports'
  },
  {
    code: 'U50',
    label: 'Team ball sports'
  },
  {
    code: 'U51',
    label: 'Team bat or stick sports'
  },
  {
    code: 'U52',
    label: 'Team water sports'
  },
  {
    code: 'E29',
    label: 'Testicular dysfunction'
  },
  {
    code: 'A33',
    label: 'Tetanus neonatorum'
  },
  {
    code: 'D56',
    label: 'Thalassaemia'
  },
  {
    code: 'E51',
    label: 'Thiamine deficiency'
  },
  {
    code: 'W77',
    label: 'Threat to breathing due to cave-in, falling earth and other substances'
  },
  {
    code: 'E06',
    label: 'Thyroiditis'
  },
  {
    code: 'E05',
    label: 'Thyrotoxicosis [hyperthyroidism]'
  },
  {
    code: 'F95',
    label: 'Tic disorders'
  },
  {
    code: 'A84',
    label: 'Tick-borne viral encephalitis'
  },
  {
    code: 'Y56',
    label:
      'Topical agents primarily affecting skin and mucous membrane and ophthalmological, otorhinolaryngological and dental drugs causing adverse effects in therapeutic use'
  },
  {
    code: 'N44',
    label: 'Torsion of testis'
  },
  {
    code: 'T64',
    label: 'Toxic effect of aflatoxin and other mycotoxin food contaminants'
  },
  {
    code: 'T51',
    label: 'Toxic effect of alcohol'
  },
  {
    code: 'T58',
    label: 'Toxic effect of carbon monoxide'
  },
  {
    code: 'T63',
    label: 'Toxic effect of contact with venomous animals'
  },
  {
    code: 'T54',
    label: 'Toxic effect of corrosive substances'
  },
  {
    code: 'T53',
    label: 'Toxic effect of halogen derivatives of aliphatic and aromatic hydrocarbons'
  },
  {
    code: 'T56',
    label: 'Toxic effect of metals'
  },
  {
    code: 'T61',
    label: 'Toxic effect of noxious substances eaten as seafood'
  },
  {
    code: 'T52',
    label: 'Toxic effect of organic solvents'
  },
  {
    code: 'T65',
    label: 'Toxic effect of other and unspecified substances'
  },
  {
    code: 'T59',
    label: 'Toxic effect of other gases, fumes and vapours'
  },
  {
    code: 'T57',
    label: 'Toxic effect of other inorganic substances'
  },
  {
    code: 'T62',
    label: 'Toxic effect of other noxious substances eaten as food'
  },
  {
    code: 'T60',
    label: 'Toxic effect of pesticides'
  },
  {
    code: 'T55',
    label: 'Toxic effect of soaps and detergents'
  },
  {
    code: 'G92',
    label: 'Toxic encephalopathy'
  },
  {
    code: 'K71',
    label: 'Toxic liver disease'
  },
  {
    code: 'B58',
    label: 'Toxoplasmosis'
  },
  {
    code: 'A71',
    label: 'Trachoma'
  },
  {
    code: 'V87',
    label: "Traffic accident of specified type but victim's mode of transport unknown"
  },
  {
    code: 'L87',
    label: 'Transepidermal elimination disorders'
  },
  {
    code: 'G45',
    label: 'Transient cerebral ischaemic attacks and related syndromes'
  },
  {
    code: 'P70',
    label: 'Transitory disorders of carbohydrate metabolism specific to fetus and newborn'
  },
  {
    code: 'P71',
    label: 'Transitory neonatal disorders of calcium and magnesium metabolism'
  },
  {
    code: 'Z94',
    label: 'Transplanted organ and tissue status'
  },
  {
    code: 'S18',
    label: 'Traumatic amputation at neck level'
  },
  {
    code: 'S98',
    label: 'Traumatic amputation of ankle and foot'
  },
  {
    code: 'S58',
    label: 'Traumatic amputation of forearm'
  },
  {
    code: 'S78',
    label: 'Traumatic amputation of hip and thigh'
  },
  {
    code: 'S88',
    label: 'Traumatic amputation of lower leg'
  },
  {
    code: 'S08',
    label: 'Traumatic amputation of part of head'
  },
  {
    code: 'S48',
    label: 'Traumatic amputation of shoulder and upper arm'
  },
  {
    code: 'S68',
    label: 'Traumatic amputation of wrist and hand'
  },
  {
    code: 'T05',
    label: 'Traumatic amputations involving multiple body regions'
  },
  {
    code: 'X51',
    label: 'Travel and motion'
  },
  {
    code: 'B75',
    label: 'Trichinellosis'
  },
  {
    code: 'A59',
    label: 'Trichomoniasis'
  },
  {
    code: 'B79',
    label: 'Trichuriasis'
  },
  {
    code: 'A17',
    label: 'Tuberculosis of nervous system'
  },
  {
    code: 'A18',
    label: 'Tuberculosis of other organs'
  },
  {
    code: 'N12',
    label: 'Tubulo-interstitial nephritis, not specified as acute or chronic'
  },
  {
    code: 'A21',
    label: 'Tularaemia'
  },
  {
    code: 'Q96',
    label: "Turner's syndrome"
  },
  {
    code: 'E10',
    label: 'Type 1 diabetes mellitus'
  },
  {
    code: 'E11',
    label: 'Type 2 diabetes mellitus'
  },
  {
    code: 'A01',
    label: 'Typhoid and paratyphoid fevers'
  },
  {
    code: 'A75',
    label: 'Typhus fever'
  },
  {
    code: 'L97',
    label: 'Ulcer of lower limb, not elsewhere classified'
  },
  {
    code: 'K51',
    label: 'Ulcerative colitis'
  },
  {
    code: 'P51',
    label: 'Umbilical haemorrhage of newborn'
  },
  {
    code: 'K42',
    label: 'Umbilical hernia'
  },
  {
    code: 'R98',
    label: 'Unattended death'
  },
  {
    code: 'Q53',
    label: 'Undescended testicle'
  },
  {
    code: 'Y60',
    label: 'Unintentional cut, puncture, perforation or haemorrhage during surgical and medical care'
  },
  {
    code: 'R69',
    label: 'Unknown and unspecified causes of morbidity'
  },
  {
    code: 'K46',
    label: 'Unspecified abdominal hernia'
  },
  {
    code: 'O06',
    label: 'Unspecified abortion'
  },
  {
    code: 'J22',
    label: 'Unspecified acute lower respiratory infection'
  },
  {
    code: 'K37',
    label: 'Unspecified appendicitis'
  },
  {
    code: 'A94',
    label: 'Unspecified arthropod-borne viral fever'
  },
  {
    code: 'F59',
    label: 'Unspecified behavioural syndromes associated with physiological disturbances and physical factors'
  },
  {
    code: 'J42',
    label: 'Unspecified chronic bronchitis'
  },
  {
    code: 'L25',
    label: 'Unspecified contact dermatitis'
  },
  {
    code: 'N26',
    label: 'Unspecified contracted kidney'
  },
  {
    code: 'F03',
    label: 'Unspecified dementia'
  },
  {
    code: 'E14',
    label: 'Unspecified diabetes mellitus'
  },
  {
    code: 'F69',
    label: 'Unspecified disorder of adult personality and behaviour'
  },
  {
    code: 'F89',
    label: 'Unspecified disorder of psychological development'
  },
  {
    code: 'W74',
    label: 'Unspecified drowning and submersion'
  },
  {
    code: 'T66',
    label: 'Unspecified effects of radiation'
  },
  {
    code: 'Y34',
    label: 'Unspecified event, undetermined intent'
  },
  {
    code: 'W19',
    label: 'Unspecified fall'
  },
  {
    code: 'R31',
    label: 'Unspecified haematuria'
  },
  {
    code: 'B24',
    label: 'Unspecified human immunodeficiency virus [HIV] disease'
  },
  {
    code: 'B82',
    label: 'Unspecified intestinal parasitism'
  },
  {
    code: 'R17',
    label: 'Unspecified jaundice'
  },
  {
    code: 'N19',
    label: 'Unspecified kidney failure'
  },
  {
    code: 'N63',
    label: 'Unspecified lump in breast'
  },
  {
    code: 'B54',
    label: 'Unspecified malaria'
  },
  {
    code: 'O16',
    label: 'Unspecified maternal hypertension'
  },
  {
    code: 'F79',
    label: 'Unspecified mental retardation'
  },
  {
    code: 'Y69',
    label: 'Unspecified misadventure during surgical and medical care'
  },
  {
    code: 'F39',
    label: 'Unspecified mood [affective] disorder'
  },
  {
    code: 'T07',
    label: 'Unspecified multiple injuries'
  },
  {
    code: 'B49',
    label: 'Unspecified mycosis'
  },
  {
    code: 'N05',
    label: 'Unspecified nephritic syndrome'
  },
  {
    code: 'F29',
    label: 'Unspecified nonorganic psychosis'
  },
  {
    code: 'F09',
    label: 'Unspecified organic or symptomatic mental disorder'
  },
  {
    code: 'B89',
    label: 'Unspecified parasitic disease'
  },
  {
    code: 'J64',
    label: 'Unspecified pneumoconiosis'
  },
  {
    code: 'X57',
    label: 'Unspecified privation'
  },
  {
    code: 'E46',
    label: 'Unspecified protein-energy malnutrition'
  },
  {
    code: 'B64',
    label: 'Unspecified protozoal disease'
  },
  {
    code: 'N23',
    label: 'Unspecified renal colic'
  },
  {
    code: 'E43',
    label: 'Unspecified severe protein-energy malnutrition'
  },
  {
    code: 'A64',
    label: 'Unspecified sexually transmitted disease'
  },
  {
    code: 'U71',
    label: 'Unspecified sport and exercise activity'
  },
  {
    code: 'W84',
    label: 'Unspecified threat to breathing'
  },
  {
    code: 'V99',
    label: 'Unspecified transport accident'
  },
  {
    code: 'R32',
    label: 'Unspecified urinary incontinence'
  },
  {
    code: 'A86',
    label: 'Unspecified viral encephalitis'
  },
  {
    code: 'A99',
    label: 'Unspecified viral haemorrhagic fever'
  },
  {
    code: 'B19',
    label: 'Unspecified viral hepatitis'
  },
  {
    code: 'B09',
    label: 'Unspecified viral infection characterised by skin and mucous membrane lesions'
  },
  {
    code: 'A89',
    label: 'Unspecified viral infection of central nervous system'
  },
  {
    code: 'R36',
    label: 'Urethral discharge'
  },
  {
    code: 'N37',
    label: 'Urethral disorders in diseases classified elsewhere'
  },
  {
    code: 'N35',
    label: 'Urethral stricture'
  },
  {
    code: 'N34',
    label: 'Urethritis and urethral syndrome'
  },
  {
    code: 'L50',
    label: 'Urticaria'
  },
  {
    code: 'B01',
    label: 'Varicella [chickenpox]'
  },
  {
    code: 'I83',
    label: 'Varicose veins of lower extremities'
  },
  {
    code: 'I86',
    label: 'Varicose veins of other sites'
  },
  {
    code: 'F01',
    label: 'Vascular dementia'
  },
  {
    code: 'K55',
    label: 'Vascular disorders of intestine'
  },
  {
    code: 'G46',
    label: 'Vascular syndromes of brain in cerebrovascular diseases (I60-I67+)'
  },
  {
    code: 'L95',
    label: 'Vasculitis limited to skin, not elsewhere classified'
  },
  {
    code: 'J30',
    label: 'Vasomotor and allergic rhinitis'
  },
  {
    code: 'O22',
    label: 'Venous complications in pregnancy'
  },
  {
    code: 'O87',
    label: 'Venous complications in the puerperium'
  },
  {
    code: 'K43',
    label: 'Ventral hernia'
  },
  {
    code: 'X36',
    label: 'Victim of avalanche, landslide and other earth movements'
  },
  {
    code: 'X37',
    label: 'Victim of cataclysmic storm'
  },
  {
    code: 'X34',
    label: 'Victim of earthquake'
  },
  {
    code: 'X38',
    label: 'Victim of flood'
  },
  {
    code: 'X33',
    label: 'Victim of lightning'
  },
  {
    code: 'X35',
    label: 'Victim of volcanic eruption'
  },
  {
    code: 'B97',
    label: 'Viral agents as the cause of diseases classified to other chapters'
  },
  {
    code: 'A08',
    label: 'Viral and other specified intestinal infections'
  },
  {
    code: 'B30',
    label: 'Viral conjunctivitis'
  },
  {
    code: 'B34',
    label: 'Viral infection of unspecified site'
  },
  {
    code: 'A87',
    label: 'Viral meningitis'
  },
  {
    code: 'J12',
    label: 'Viral pneumonia, not elsewhere classified'
  },
  {
    code: 'B07',
    label: 'Viral warts'
  },
  {
    code: 'H53',
    label: 'Visual disturbances'
  },
  {
    code: 'H54',
    label: 'Visual impairment including binocular or monocular blindness'
  },
  {
    code: 'E50',
    label: 'Vitamin A deficiency'
  },
  {
    code: 'D51',
    label: 'Vitamin B12 deficiency anaemia'
  },
  {
    code: 'E55',
    label: 'Vitamin D deficiency'
  },
  {
    code: 'L80',
    label: 'Vitiligo'
  },
  {
    code: 'R49',
    label: 'Voice disturbances'
  },
  {
    code: 'E86',
    label: 'Volume depletion'
  },
  {
    code: 'N77',
    label: 'Vulvovaginal ulceration and inflammation in diseases classified elsewhere'
  },
  {
    code: 'V92',
    label: 'Water-transport-related drowning and submersion without accident to watercraft'
  },
  {
    code: 'U65',
    label: 'Wheeled motor sports'
  },
  {
    code: 'U66',
    label: 'Wheeled nonmotored sports'
  },
  {
    code: 'A37',
    label: 'Whooping cough'
  },
  {
    code: 'Y96',
    label: 'Work-related condition'
  },
  {
    code: 'A66',
    label: 'Yaws'
  },
  {
    code: 'A95',
    label: 'Yellow fever'
  },
  {
    code: 'B02',
    label: 'Zoster [herpes zoster]'
  },
  {
    code: 'B46',
    label: 'Zygomycosis'
  },
  {
    code: '780',
    label: 'Others'
  }
];

export default dataDiagnosis;
