import { NavigationActions, StackActions } from 'react-navigation';

import { StackNavigationProp } from '../types/navigation';

type Navigate = (route: string, params?: {}) => boolean;
type NavigateCallback = () => void;

export function getNavigate(navigate: Navigate, route: string, params?: {}): NavigateCallback {
  return (): void => {
    if (params) {
      navigate(route, params);
    } else {
      navigate(route);
    }
  };
}

export const replaceNavigationStack = (navigation: StackNavigationProp, route: string, params?: {}): void => {
  const actionToDispatch = StackActions.reset({
    index: 0,
    key: null,
    actions: [NavigationActions.navigate({ routeName: route, params })]
  });

  navigation.dispatch(actionToDispatch);
};
