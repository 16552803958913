import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import Share from '../../../components/Share';

import ECardFront from '../ECardFront';
import ECardBack from '../ECardBack';
import { ECard } from '../../../types/ecard';

export interface Props {
  data: ECard;
}

const ECardView: React.FC<Props> = ({ data }) => {
  const [toggleCard, setToggleCard] = useState(false);
  const shareECard = async (): Promise<void> => {
    await Share.open({
      url: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAVkAAACSCAMAAADYdEkqAAAAsVBMVEX'
    });
  };

  return (
    <View testID="ECardView">
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}
      >
        <TouchableOpacity testID="toggleCardTrigger" onPress={(): void => setToggleCard(!toggleCard)}>
          <Icon name="repeat" size={24} color="#68737A" />
        </TouchableOpacity>
      </View>
      {toggleCard ? <ECardBack /> : <ECardFront data={data} />}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}
      >
        <TouchableOpacity testID="shareCard" onPress={shareECard}>
          <Icon name="share" size={24} color="#68737A" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ECardView;
