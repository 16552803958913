import { useTranslation } from 'react-i18next';
import { LBU } from '../config';
import { getValue } from '../utils/object';

interface TranslationProps {
  fallbackValue?: string;
  keyExtractor?: (key: string) => string;
}

export const useLBUTranslation = (
  countryCode?: string
): { tLBU: (key: string, options: TranslationProps) => string } => {
  if (!countryCode) {
    throw new Error('Country code must be present.');
  }
  const locizeNamespace: string = getValue(LBU, [countryCode, 'GENERAL', 'globalNamespace'], '');

  const { t, i18n } = useTranslation();
  const { t: tGlobal, i18n: i18nGlobal } = useTranslation(locizeNamespace);

  const tLBU = (key: string, options?: TranslationProps): string => {
    const fallbackValue = options && options.fallbackValue ? options.fallbackValue : key;
    const locizeKey = options && options.keyExtractor ? options.keyExtractor(key) : key;
    const fullKey = `${locizeNamespace}:${locizeKey}`;
    return i18nGlobal.exists(fullKey) ? tGlobal(locizeKey) : i18n.exists(fullKey) ? t(locizeKey) : fallbackValue;
  };

  return { tLBU };
};
