import { Dispatch } from 'redux';
import { path } from 'ramda';

import { ActionCallback } from '../../types/utils';
import * as api from './api';
import { RootState } from '../../store';
import { getPulseUser, getUserCountryCode } from '../PulseUserReducer/selectors';
import RNSInfo from '../../components/SensitiveInfo';
import {
  CLEAR_REFRESH_TOKEN,
  BACK_TO_PULSE,
  ClearRefreshToken,
  BackToPulse,
  FETCH_REFRESH_TOKEN_FAILED,
  FETCH_REFRESH_TOKEN_START,
  FETCH_REFRESH_TOKEN_SUCCESS,
  FetchRefreshTokenFailed,
  FetchRefreshTokenStart,
  FetchRefreshTokenSuccess,
  SessionAction,
  SET_REFRESH_TOKEN,
  SetRefreshToken,
  VALIDATE_OTP_FAILED,
  VALIDATE_OTP_START,
  VALIDATE_OTP_SUCCESS,
  ValidateOTPFailed,
  ValidateOTPStart,
  ValidateOTPSuccess,
  CLEAR_VALIDATE_EEROR,
  ClearValidateError
} from './types';
import { setItem } from '../../utils/storage';
import { Credential } from '../../types/user';
import { decryptUser, encryptString } from '../../utils/encrypt';

export const setRefreshToken = (refreshToken: string): SetRefreshToken => ({
  type: SET_REFRESH_TOKEN,
  payload: refreshToken
});

export const clearRefreshToken = (): ClearRefreshToken => ({
  type: CLEAR_REFRESH_TOKEN
});

export const back2PulseAction = (value: boolean): BackToPulse => ({
  type: BACK_TO_PULSE,
  payload: value
});

export const clearValidateError = (): ClearValidateError => ({
  type: CLEAR_VALIDATE_EEROR
});

export const validateOTPStart = (): ValidateOTPStart => ({ type: VALIDATE_OTP_START });

export const validateOTPSuccess = (): ValidateOTPSuccess => ({
  type: VALIDATE_OTP_SUCCESS
});

export const validateOTPFailed = (validateOTPError: string): ValidateOTPFailed => ({
  type: VALIDATE_OTP_FAILED,
  payload: validateOTPError
});
const checkLoginSameAccount = async (currentLoginId: string) => {
  try {
    const data = await RNSInfo.getItem('user_credentials', {
      sharedPreferencesName: 'sdkSharedPrefs',
      keychainService: 'sdkKeychain'
    });

    if (data !== undefined && data !== null) {
      const res: Credential = decryptUser(JSON.parse(data));
      if (res.nationalId !== currentLoginId) {
        await setItem('ACTIVATED_BIOMETRIC', false);
      } else {
        console.log('keep ACTIVATED_BIOMETRIC state');
      }
    } else {
      await setItem('ACTIVATED_BIOMETRIC', false);
    }
  } catch (err) {
    await setItem('ACTIVATED_BIOMETRIC', false);
  }
};

export const validateOTP =
  (authCodeRef: string, otp: string, currentLoginId: string, rememberMe: boolean, callback: ActionCallback) =>
  async (dispatch: Dispatch<SessionAction>, getState: () => RootState): Promise<void> => {
    dispatch(validateOTPStart());
    const countryCode = getUserCountryCode(getState()) ?? 'PH';
    try {
      const { data } = await api.validateOTP(authCodeRef, otp, countryCode);

      const refreshToken: string = path(['getRefreshTokenForAuthCode', 'token'], data) || '';
      const softToken: string | undefined = path(['getRefreshTokenForAuthCode', 'softToken'], data);
      if (softToken) {
        await RNSInfo.setItem('softToken', encryptString(softToken), {
          sharedPreferencesName: 'sdkSharedPrefs',
          keychainService: 'sdkKeychain'
        });
      }

      await setItem('REMEMBER_ME', rememberMe);
      await checkLoginSameAccount(currentLoginId);

      if (!refreshToken) {
        throw new Error('Failed to validate OTP');
      }

      dispatch(validateOTPSuccess());
      dispatch(setRefreshToken(refreshToken));

      callback(true);
    } catch (err) {
      dispatch(validateOTPFailed(err.message));

      callback(false);
    }
  };

export const fetchRefreshTokenStart = (): FetchRefreshTokenStart => ({
  type: FETCH_REFRESH_TOKEN_START
});

export const fetchRefreshTokenSuccess = (): FetchRefreshTokenSuccess => ({
  type: FETCH_REFRESH_TOKEN_SUCCESS
});

export const fetchRefreshTokenFailed = (error: string): FetchRefreshTokenFailed => ({
  type: FETCH_REFRESH_TOKEN_FAILED,
  payload: error
});

export const fetchRefreshToken =
  (callback: ActionCallback) =>
  async (dispatch: Dispatch<SessionAction>): Promise<void> => {
    dispatch(fetchRefreshTokenStart());

    try {
      const { data } = await api.renewRefreshToken();

      const refreshToken: string = path(['renewRefreshToken', 'token'], data) || '';

      if (!refreshToken) {
        throw new Error('Failed to renew session');
      }

      dispatch(fetchRefreshTokenSuccess());
      dispatch(setRefreshToken(refreshToken));

      callback(true);
    } catch (err) {
      dispatch(fetchRefreshTokenFailed(err.message));

      callback(false);
    }
  };
