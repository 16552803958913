export const saveClaim = [
  'claimType',
  'claimSubType',
  'claimDate',
  'accidentDate',
  'admissionDate',
  'dischargeDate',
  'claimNumber',
  'originalRequired',
  'policyId',
  'certificateNo',
  'documents',
  'coveragePerson',
  'incurredDate',
  'amount',
  'hospitalizationReason',
  'dependant',
  'bankAccount',
  'receiptsCurrency',
  'remark',
  'paymentOptions',
  'clinic',
  'clinicName',
  'currency',
  'claimAmount',
  'diagnosis',
  'hospital',
  'hospitalName',
  'diagnosisDescription',
  'lifeAssuredCertificateNo'
];

export const addBankAccount = [
  'bank',
  'accountNumber',
  'accountHolderName',
  'policyNumber',
  'branchName',
  'branchAddress'
];

export const registerPulseUser = [
  'profileId',
  'deviceId',
  'email',
  'phone',
  'nationalId',
  'acceptConsent'
]
