interface PermissionType {
  location: 'location';
}

export const PERMISSION_TYPE: PermissionType = {
  location: 'location'
};

export const checkPermission = async (type: keyof PermissionType): Promise<boolean> => {
  throw new Error('checkPermission: not implement for web');
};

export const check = async (type: any): Promise<boolean> => {
  throw new Error('check: not implement for web');
};

export const request = async (type: any): Promise<boolean> => {
  throw new Error('request: not implement for web');
};

export const PERMISSIONS = {};
export const RESULTS = {};
