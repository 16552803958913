import { connect } from 'react-redux';

import { RootState } from '../../store';
import { getFetchError, getLoading, getReward } from '../../reducers/RewardReducer/selectors';

import { fetchReward } from '../../reducers/RewardReducer/actions';

import RewardsScreen from './RewardsScreen';

export default connect(
  (state: RootState) => ({
    fetchingReward: getLoading(state),
    reward: getReward(state),
    fetchError: getFetchError(state)
  }),
  { fetchReward }
)(RewardsScreen);
