import React, { CSSProperties, SVGProps } from 'react';
import { TouchableHighlight, View, ViewProps } from 'react-native';

import { chevronRight } from '../../assets/images/icons';
import { lightGrey } from '../../assets/colors';

import { PruText, PruCard } from '@ebsme/pulse-rn-components';
import { ArrowIcon, ArrowIconChevron } from './LongTileStyles';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface Props extends ViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SvgImage: React.FC<SVGProps<SVGSVGElement>>;
  svgStyle?: CSSProperties;
  title: string;
  description: string;
  onPress: () => void;
}

const LongTile: React.FC<Props> = ({ SvgImage, svgStyle, title, description, onPress, style, testID }) => {
  return (
    <TouchableHighlight
      accessible={false}
      accessibilityLabel={title}
      accessibilityHint={description}
      accessibilityRole="button"
      onPress={onPress}
      underlayColor={lightGrey}
      style={style}
      testID={testID}
    >
      <PruCard
        style={{
          flexDirection: 'row',
          padding: 18,
          borderRadius: 10,
          alignItems: 'center'
        }}
      >
        <SvgImage style={{ marginRight: 16, ...svgStyle }} />
        <View style={{ flex: 1 }}>
          <PruText testID={`${testID}_TITLE`} marginBottom={4} weight="bold">
            {title}
          </PruText>
          <PruText testID={`${testID}_DESCRIPTION`} weight="thin">
            {description}
          </PruText>
        </View>
        <ArrowIcon>
          <Icon name='navigate-next' size={20} color="white" />
        </ArrowIcon>
      </PruCard>
    </TouchableHighlight>
  );
};

export default LongTile;
