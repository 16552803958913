import styled from 'styled-components/native';
import { darkBlue, white, black } from '../../../assets/colors';

export const Container = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
`;

export const FilterButton = styled.TouchableOpacity<{ active: boolean }>`
  display: flex;
  align-content: center;
  justify-content: center;
  min-width: 100px;
  padding: 16px 20px;
  margin-right: 20px;
  border-radius: 50px;
  background-color: ${white};
  text-align: center;
  ${({ active }): string => (active ? `background-color: ${darkBlue};` : '')}
`;

export const Text = styled.Text<{ active: boolean }>`
  align-self: center;
  font-weight: 600;
  font-size: 14px;
  font-style: italic;
  color: ${black};
  ${({ active }): string => (active ? `color: ${white}` : '')}
`;
