import React from 'react';

import { chevronRight } from '../../assets/images/icons';

import { ArrowIcon, ArrowIconChevron } from './FloatingArrowStyles';

import Icon from 'react-native-vector-icons/MaterialIcons';

const FloatingArrow: React.FC = () => {
  return (
    <ArrowIcon>
      <Icon name='navigate-next' size={20} color="white" />
    </ArrowIcon>
  );
};

export default FloatingArrow;
