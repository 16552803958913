import { getObjValue } from './object';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Field = string | number | any[] | { [key: string]: Field };

export interface SearchableArray {
  [key: string]: Field;
}

type SearchCriteria<T> = Partial<Record<keyof T, string>>;

interface SearchOptions {
  minLength?: number;
}

export function searchArray<Obj extends SearchableArray>(
  arr: Obj[] = [],
  searchCriteria: SearchCriteria<Obj> = {},
  searchOptions: SearchOptions = {}
): Obj[] {
  if (!searchCriteria || Object.keys(searchCriteria).length === 0) {
    return arr;
  }

  const { minLength = 2 } = searchOptions;

  return arr.filter((entry) => {
    let hasMatch = false;
    let skippedSearch = true;

    Object.keys(searchCriteria).forEach((option) => {
      if (hasMatch) {
        return;
      }

      const rawSearchTerm = getObjValue(searchCriteria, option, '');
      const searchTerm = rawSearchTerm.toLowerCase();

      if (searchTerm.length < minLength) {
        return;
      }

      skippedSearch = false;

      const field = getObjValue(entry, option, '');
      const text = JSON.stringify(field);

      if (!searchTerm || text.toLowerCase().includes(searchTerm)) {
        hasMatch = true;
      }
    });

    return hasMatch || skippedSearch;
  });
}
