import React, { useEffect } from 'react';
import { Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import * as routes from '../../../routes';
import { StackNavigationProp } from '../../../types/navigation';
import MakeClaimView from '../MakeClaimView';
import ClaimConfirmationView from '../../../components/Claim/ClaimConfirmationView';
import { useLBUTranslation } from '../../../hooks/useLBUTranslation';
import { logFirebaseEventScreen } from '../../../utils/analytics';

interface Props {
  navigation: StackNavigationProp<
    {},
    {
      claimNumber: string;
      lbu?: string;
    }
  >;
}

const ConfirmationScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();
  const claimNumber = navigation.getParam('claimNumber');
  const lbu = navigation.getParam('lbu');
  const { tLBU } = useLBUTranslation(lbu);

  useEffect(() => {
    logFirebaseEventScreen('eb_make_claim', {
      feature: 'MakeClaim',
      journey: 'make_claim',
      stage: 'claim_confirm',
      screen_id: 'SCR_EB_CLAIM_CFS',
      screen_name: 'ConfirmationScreen'
    });
  }, []);

  return (
    <MakeClaimView
      headTitle={t('CLAIMS__CONFIRMATION_TITLE')}
      title={''}
      currentRoute={routes.ConfirmationScreen}
      nextDisabled={false}
      navigation={navigation}
    >
      {lbu === 'SG' ? (
        <>
          <Text
            style={{
              fontSize: 16,
              marginBottom: 30,
              marginLeft: 20,
              marginRight: 20
            }}
          >
            The company will review the claim within 10 to 14 working days. If additional documents or information are
            required, the company will contact you.
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginLeft: 20,
              marginRight: 20
            }}
          >
            We may request to verify the scanned hospital/doctor’s bills submitted to us via this portal against the
            original final bills. By your submission of the scanned copy of the original final hospital/doctor’s bills
            to us via Pulse, you agree to:
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginLeft: 20,
              marginRight: 20
            }}
          >
            a) keep the original final hospital/doctor’s bill for a period of 1 year from the date of submission, and
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginLeft: 20,
              marginRight: 20
            }}
          >
            b) reimburse to us any claim proceeds that had been paid out to you if the original final hospital/doctor’s
            bills cannot be produced by you to support your claim during this period.
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginLeft: 20,
              marginRight: 20
            }}
          >
            (We reserve the right to request for further documents or apply for further medical reports upon our receipt
            of your claim submission.)
          </Text>
        </>
      ) : (
        <Text
          style={{
            fontSize: 16,
            marginBottom: 30,
            marginLeft: 20,
            marginRight: 20
          }}
        >
          {tLBU('CLAIMS__CONFIRMATION_MESSAGE', { fallbackValue: t('CLAIMS__CONFIRMATION_MESSAGE') })}
        </Text>
      )}
      <ClaimConfirmationView
        claimNumber={claimNumber}
      // onClose={(): void => {
      //   navigation.navigate(routes.Landing);
      // }}
      />
    </MakeClaimView>
  );
};

export default ConfirmationScreen;
