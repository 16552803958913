import { RootState } from '../../../store';
import { resetClaim, submitClaimForProcess } from '../../../reducers/MakeClaimReducer/actions';
import { getLoading, getClaim, getError } from '../../../reducers/MakeClaimReducer/selectors';
import { getPolicyId } from '../../../reducers/MyPolicyReducer/selectors';
import withLBUSwitcher from '../../../hocs/withLBUSwitcher';

import FinalSummaryScreen from './FinalSummaryScreen';
import { getSDKLocale } from '../../../reducers/SDKReducer/selectors';

const LBU_CONFIG = {
  SG: FinalSummaryScreen,
  TH: FinalSummaryScreen,
  PH: FinalSummaryScreen,
  MY: null,
  MM: FinalSummaryScreen
};

export default withLBUSwitcher(
  LBU_CONFIG,
  (state: RootState) => ({
    loading: getLoading(state),
    claim: getClaim(state),
    policyId: getPolicyId(state),
    error: getError(state),
    locale: getSDKLocale(state)
  }),
  { submitClaimForProcess, resetClaim }
);
