import React from 'react';
import { View, ViewProps } from 'react-native';
import { withNavigation } from 'react-navigation';
import { useTranslation } from 'react-i18next';

import { PruButton, PruCard } from '@ebsme/pulse-rn-components';
import ShadedContainer from '../ShadedContainer';
import { PulseLogo } from '../../assets/svgs/brand';

export interface ConfirmationViewProps extends ViewProps {
  children: React.ReactNode;
  onConfirm: () => void;
}

const ConfirmationView: React.FC<ConfirmationViewProps> = ({ children, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <View>
      <ShadedContainer inverse>
        <PruCard style={{ minHeight: 300, justifyContent: 'center', margin: 24, marginTop: -60, alignItems: 'center' }}>
          <PulseLogo width="200" height="120" />
          {children}
        </PruCard>
      </ShadedContainer>
      <PruButton style={{ marginVertical: 24 }} onPress={onConfirm}>
        {t('BUTTON__OK')}
      </PruButton>
    </View>
  );
};

export default withNavigation(ConfirmationView);
