import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WebView, WebViewMessageEvent } from 'react-native-webview';

import { StackNavigationProp } from '../../types/navigation';
import * as routes from '../../routes';
import { PruInput, PruText } from '@ebsme/pulse-rn-components';
import Screen from '../../components/Screen';
import { Reward } from '../../reducers/RewardReducer/type';

import { getNavigate } from '../../utils/navigation';
import Icon from 'react-native-vector-icons/Ionicons';
import { Image, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import HospitalIcon from '../../components/Hospital/HospitalIcon';
import { ic_directions, ic_recommend } from '../../assets/images/icons';
import { openMapDirection } from '../../utils/hospitalUtil';
import MapView, { PROVIDER_GOOGLE, Marker } from '../../components/MapView';

type Props = {
  navigation: StackNavigationProp;
};
const geListMarker = (dataFilter: any) => {
  return dataFilter.map((item: any) => ({
    latitude: item.origin?.latitude,
    longitude: item.origin?.longitude,
    id: item.id
  }));
};

const ListMapHospitalScreen: React.FC<Props> = ({ navigation }) => {
  const { t, i18n } = useTranslation();
  const refMapView = React.createRef<MapView>();
  const listCurrentMarker = geListMarker(navigation.getParam('dataFilter'));
  const [showMap, setShowMap] = useState(false);

  const onPressBack = (): void => {
    navigation.goBack();
  };
  useEffect(() => {
    const id = setTimeout(() => setShowMap(true), 800);
    return (): void => {
      clearTimeout(id);
    };
  }, [showMap]);
  return (
    <Screen headingPosition="header" containerType="View" headerPaddingHorizontal={100} onPressBack={onPressBack}>
      {showMap && (
        <MapView
          ref={refMapView}
          provider={PROVIDER_GOOGLE} // remove if not using Google Maps
          style={{ flex: 1 }}
          showsUserLocation={true}
          // region={{
          //   latitude: listCurrentMarker[0].latitude,
          //   longitude: listCurrentMarker[0].longitude,
          //   latitudeDelta: 0.015,
          //   longitudeDelta: 0.0121
          // }}
          onMapReady={() => {
            refMapView.current?.fitToCoordinates(listCurrentMarker, {
              edgePadding: {
                right: 30,
                bottom: 30,
                left: 30,
                top: 30
              },
              animated: true
            });
          }}
        >
          {listCurrentMarker.map((p: any) => {
            const latitude = p.latitude;
            const longitude = p.longitude;
            const region = { latitude, longitude };
            return (
              <Marker key={p.id} tracksViewChanges={false} coordinate={region} anchor={{ x: 0.5, y: 0.5 }}></Marker>
            );
          })}
        </MapView>
      )}
    </Screen>
  );
};

export default ListMapHospitalScreen;
